define("experience-studio/routes/permissions-hub/invitations", ["exports", "experience-studio/mixins/model-actions"], function (_exports, _modelActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelActions.default, {
    model: function model() {
      return this.store.findAll('invitation');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('invitation', this.store.createRecord('invitation', {
        email: '',
        permission: null
      }));
      controller.set('permissionsHubController', this.controllerFor('permissions-hub'));
    },
    actions: {
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.get('searchKeyword'));
      },
      deselectModelListItems: function deselectModelListItems() {
        this._super('permissionsHubController.selectedEntities');
      },
      didSentInvitation: function didSentInvitation(model) {
        this.send('showNotification', {
          type: 'confirm',
          message: "Sent invitation to ".concat(model.get('email'), "!")
        });
        this.controller.set('invitation', this.store.createRecord('invitation', {
          email: '',
          permission: null
        }));
      }
    }
  });
  _exports.default = _default;
});