define("experience-studio/templates/components/selected-locations-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XFQTTM8N",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"map-group\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"map-container\"],[12],[2,\"\\n        \"],[11,\"div\"],[24,0,\"map-subtitle\"],[4,[38,4],[[32,0],\"viewInfo\"],null],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"title-text\"],[12],[2,\"Selected Locations Map\"],[13],[2,\"\\n          \"],[10,\"div\"],[15,0,[31,[\"map-dropdown-icon \",[30,[36,3],[[35,5],\"is-view-map\"],null]]]],[12],[2,\"\\n            \"],[10,\"a\"],[14,6,\"\"],[12],[2,\"\\n              \"],[1,[30,[36,6],[\"chevron\"],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,3],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[15,\"src\",[31,[[30,[36,3],[[35,2],[35,1],[35,0]],null]]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mapImageSelectedUrl\",\"mapImageSelectedCircleUrl\",\"isApproximateAreaAttribute\",\"if\",\"action\",\"isViewSelectedMaps\",\"svg-jar\"]}",
    "moduleName": "experience-studio/templates/components/selected-locations-map.hbs"
  });
  _exports.default = _default;
});