define("experience-studio/templates/components/title-description-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UKcgOgJK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"label\"],[12],[1,[34,0]],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,7],null,[[\"options\",\"onchange\",\"selected\",\"searchEnabled\",\"disabled\",\"placeholder\",\"placeholderComponent\",\"selectedItemComponent\",\"optionsComponent\",\"dropdownClass\",\"verticalPosition\"],[[35,6],[30,[36,5],[[32,0],\"selectOption\"],null],[35,4],false,[35,3],[35,2],\"title-description-select-placeholder\",\"title-description-select-option\",\"title-description-select-option\",\"title-description-select\",\"below\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"placeholder\",\"disabled\",\"selection\",\"action\",\"items\",\"power-select\"]}",
    "moduleName": "experience-studio/templates/components/title-description-select.hbs"
  });
  _exports.default = _default;
});