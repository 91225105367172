define("experience-studio/mixins/prevent-reload-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    willReload: function willReload() {
      window.onbeforeunload = function () {
        return 'Are you sure you want to reload the page?';
      };
    },
    willDestroyElement: function willDestroyElement() {
      window.onbeforeunload = null;
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});