define("experience-studio/models/context-historical-query", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    expiry: _emberData.default.attr('number', {
      defaultValue: -1
    }),
    function: _emberData.default.attr('string', {
      defaultValue: 'count'
    }),
    plugin: _emberData.default.attr(),
    timeRange: _emberData.default.attr(),
    where: _emberData.default.attr()
  });
  _exports.default = _default;
});