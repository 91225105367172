define("experience-studio/controllers/experiences-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    sortBy: 'createdAt',
    sortOrder: 'desc',
    groupId: '',
    searchKeyword: '',
    page: 1,
    recordsPerPage: 12,
    data: '',
    queryParams: [{
      data: 'data',
      groupId: 'groupId',
      sortBy: 'sortBy',
      sortOrder: 'sortOrder',
      searchKeyword: 'search',
      page: 'page',
      recordsPerPage: 'recordsPerPage'
    }],
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    filteredModel: Ember.computed('isRefreshFilterModel', 'data', 'model', function () {
      var data = this.utility.atob(this.data);
      if (!data) {
        data = {
          groupId: this.groupId,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          searchKeyword: this.searchKeyword,
          page: this.page,
          recordsPerPage: this.recordsPerPage
        };
      }
      data = this.utility.btoa(data);
      return this.store.query('experience', {
        data: data
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    }),
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Grouping',
      icon: 'th-large',
      subActions: [Ember.Object.create({
        label: 'Add to existing Groups',
        action: 'showAddGroupRecordsModal'
      }), Ember.Object.create({
        label: 'Remove from Groups',
        action: 'showRemoveGroupRecordsModal'
      })]
    }), Ember.Object.create({
      label: 'Labels',
      icon: 'tag',
      subActions: [Ember.Object.create({
        label: 'Add Labels',
        action: 'showAddLabelRecordsModal',
        subActions: []
      }), Ember.Object.create({
        label: 'Remove Labels',
        action: 'showRemoveLabelRecordsModal',
        subActions: [],
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('labels.length', 0)) {
            return 'These items have no labels to remove.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Activation',
      icon: 'power',
      subActions: [Ember.Object.create({
        label: 'Set as Active',
        action: 'showActivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isActive', true)) {
            return 'At least one item has to be Inactive.';
          }
          if (selectedEntities.isEvery('computedRuleObjects.firstObject.isDraft', true)) {
            return 'Cannot activate experiences which are associated to a draft rule';
          }
        }
      }), Ember.Object.create({
        label: 'Set as Inactive',
        action: 'showDeactivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isActive', false)) {
            return 'At least one item has to be Active.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: [],
      hasDivider: true
    })]),
    filterState: Object.freeze([Ember.Object.create({
      title: 'Status',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-checkbox-select',
        id: 'status',
        isDisabled: false,
        options: [Ember.Object.create({
          isSelected: false,
          value: true,
          label: 'Active',
          property: 'isActive'
        }), Ember.Object.create({
          isSelected: false,
          value: false,
          label: 'Inactive',
          property: 'isActive'
        })]
      })]
    }), Ember.Object.create({
      title: 'Date',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Created',
        id: 'created',
        isDisabled: false,
        options: [Ember.Object.create({
          value: undefined,
          property: 'createdAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'createdAtTo'
        })]
      })]
    }), Ember.Object.create({
      title: '',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-model-select',
        label: '',
        id: 'label',
        isDisabled: false,
        options: [Ember.Object.create({
          modelName: 'label',
          // used to look up model in store
          modelRepresentation: ['name', 'value'],
          // used to retrieve id or value to be saved in param
          value: undefined,
          label: 'Labels',
          property: 'labels'
        })]
      })]
    })]),
    filterParams: Object.freeze([Ember.Object.create({
      id: 'isActive',
      component: 'filter-pill-active',
      value: null
    }), Ember.Object.create({
      id: 'createdAtTo',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'createdAtFrom',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'labels',
      component: 'filter-pill-label',
      value: null
    })])
  });
  _exports.default = _default;
});