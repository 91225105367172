define("experience-studio/adapters/analytics-export", ["exports", "experience-studio/adapters/application", "jquery"], function (_exports, _application, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = _application.default.extend({
    namespace: 'analytics/analytics-export',
    export: function _export(dateRange, dataTypes, dataFormat, email) {
      var _this = this;
      if (dateRange.startTime && dateRange.endTime && dataTypes.length && dataFormat && email) {
        var url = this.buildURL('exports');
        var data = _objectSpread(_objectSpread({}, dateRange), {}, {
          dataTypes: dataTypes,
          email: email,
          dataFormat: dataFormat,
          logLevel: 'info'
        });
        return new Promise(function (resolve, reject) {
          _jquery.default.ajax({
            headers: _this.headers,
            type: 'POST',
            url: url,
            data: JSON.stringify(data)
          }).done(function (res) {
            resolve(res);
          }).fail(function (jqXHR) {
            reject(jqXHR);
          });
        });
      }
    }
  });
  _exports.default = _default;
});