define("experience-studio/templates/components/model-select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+zxScRWA",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,3],null,[[\"options\",\"onchange\",\"dropdownClass\",\"triggerComponent\",\"beforeOptionsComponent\",\"searchField\",\"closeOnSelect\",\"preventScroll\"],[[35,2],[30,[36,1],[[32,0],\"selectModel\"],null],\"model-select-dropdown\",\"model-select-dropdown--trigger\",\"power-select-custom-search\",\"name\",false,true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"custom-control custom-checkbox\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"type\",\"class\",\"checked\"],[\"checkbox\",\"custom-control-input permission-input\",[32,1,[\"dropdownSelected\"]]]]]],[2,\"\\n    \"],[10,\"label\"],[14,0,\"custom-control-label\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"input\",\"action\",\"model\",\"power-select\"]}",
    "moduleName": "experience-studio/templates/components/model-select-dropdown.hbs"
  });
  _exports.default = _default;
});