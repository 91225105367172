define("experience-studio/components/dynamic-attribute-input", ["exports", "experience-studio/config/environment", "experience-studio/helpers/dynamic-attribute-rtf-serializer", "experience-studio/helpers/blacklist-sites"], function (_exports, _environment, _dynamicAttributeRtfSerializer, _blacklistSites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    classNames: ['dynamic-attribute'],
    isModal: false,
    range: null,
    isEditDynamic: false,
    currentTarget: null,
    pasteTriggered: false,
    defaultValue: '',
    keyPressedValue: '',
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      var defaultLanguage = this.languages.findBy('isDefault', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      } else if (defaultLanguage) {
        languageCode = defaultLanguage.get('id');
      } else {
        languageCode = this.languages[0].get('id');
      }
      return languageCode;
    }),
    defaultLanguage: Ember.computed('languages.@each.isDefault', function () {
      return this.languages.findBy('isDefault', true);
    }),
    isRichTextEnabled: Ember.computed('userPreferences.preferences.[]', 'field', function () {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      var isRTFEnabled = isNonTdOrUsEnv || this.userPreferences.getValue('richTextFormatting');
      return isRTFEnabled && this.field && this.field.isRichTextEnabled && (0, _blacklistSites.blacklistSites)();
    }),
    dynamicAttrPluginAttrsMap: Ember.computed('plugins.content.[].attributes', function () {
      if (!(this.plugins && this.plugins.content && this.plugins.content.length)) return [];
      var pluginAttrMap = {};
      this.plugins.content.forEach(function (plugin) {
        if (plugin._record) {
          var attributes = plugin._record.attributes;
          for (var attrKey in attributes) {
            if (attributes.hasOwnProperty(attrKey)) {
              pluginAttrMap[attributes[attrKey].uid] = "".concat(plugin._record.name, " / ").concat(attributes[attrKey].name);
            }
          }
        }
      });
      return pluginAttrMap;
    }),
    didUpdateDynamicAttributeValue: Ember.observer('dynamicAtrributeValue.value', function () {
      if (this.dynamicAtrributeValue.value && this.dynamicAtrributeValue.value.closest('.dynamic-editable-block') == this.element.getElementsByClassName('dynamic-editable-block')[0]) {
        this.dynamicAtrributeValue.value.className = 'dynamic-attr';
        this.set('isEditDynamic', true);
        this.set('isModal', true);
        this.set('contentPlugin', this.labelToOption(this.dynamicAtrributeValue.value.getElementsByClassName('dynamic-attr__attr')[0].innerText));
        this.set('defaultValue', this.dynamicAtrributeValue.value.getElementsByClassName('dynamic-attr__default-value')[0] ? this.dynamicAtrributeValue.value.getElementsByClassName('dynamic-attr__default-value')[0].innerText : '');
      }
    }),
    didUpdateDynamicAttributeDelete: Ember.observer('dynamicAtrributeValue.delete', function () {
      if (this.dynamicAtrributeValue.delete && this.dynamicAtrributeValue.delete.closest('.dynamic-editable-block') == this.element.getElementsByClassName('dynamic-editable-block')[0]) {
        this.setAndCheckFieldValue(this, this.dynamicAtrributeValue.delete.closest('.dynamic-editable-block'));
        this.set('dynamicAtrributeValue.delete', '');
      }
    }),
    didUpdateLanguage: Ember.observer('languages.@each.isSelected', function () {
      var _this = this;
      if (this.field && this.field.componentName === 'content-tmp-edit-text-locale') {
        var dynamicEditableElement = document.getElementById(this.elementId).getElementsByClassName('dynamic-editable-block')[0];
        dynamicEditableElement.innerHTML = (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(this.field.value[this.selectedLanguageCode] ? this.field.value[this.selectedLanguageCode] : '', this.dynamicAttrPluginAttrsMap, this.isRichTextEnabled);
        var _iterator = _createForOfIteratorHelper(dynamicEditableElement.getElementsByClassName('dynamic-attr')),
          _step;
        try {
          var _loop = function _loop() {
            block = _step.value;
            var selfThis = _this;
            block.addEventListener('contextmenu', function (ev) {
              ev.preventDefault();
              selfThis.updateDynamicAttribute(ev.currentTarget);
              return false;
            }, false);
          };
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var block;
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      var attributes = [];
      if (this.plugins) {
        this.plugins.forEach(function (plugin) {
          var pluginAttributes = plugin.get('attributes');
          var keys = window.Object.keys(pluginAttributes);
          if (pluginAttributes && !Ember.isEmpty(keys)) {
            keys.forEach(function (key) {
              var pluginAttribute = pluginAttributes.get(key);
              attributes.pushObject(Ember.Object.create({
                label: "".concat(plugin.get('name')),
                pluginAttribute: "".concat(pluginAttribute.get('name')),
                value: "".concat(pluginAttribute.get('uid')),
                valueType: "".concat(pluginAttribute.get('valueType'))
              }));
            });
          }
        });
      }
      this.set('contentPluginList', attributes);
      if (this.field && this.field.value) {
        var dynamicEditableElement = document.getElementById(this.elementId).getElementsByClassName('dynamic-editable-block')[0];
        if (this.field.componentName === 'content-tmp-edit-text-locale') {
          dynamicEditableElement.innerHTML = (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(this.field.value[this.selectedLanguageCode] ? this.field.value[this.selectedLanguageCode] : '', this.dynamicAttrPluginAttrsMap, this.isRichTextEnabled);
        } else {
          dynamicEditableElement.innerHTML = (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(this.field.value, this.dynamicAttrPluginAttrsMap, this.isRichTextEnabled);
        }
        var _iterator2 = _createForOfIteratorHelper(dynamicEditableElement.getElementsByClassName('dynamic-attr')),
          _step2;
        try {
          var _loop2 = function _loop2() {
            block = _step2.value;
            var selfThis = _this2;
            block.addEventListener('contextmenu', function (ev) {
              ev.preventDefault();
              selfThis.updateDynamicAttribute(ev.currentTarget);
              return false;
            }, false);
          };
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var block;
            _loop2();
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    },
    keyPressSpan: function keyPressSpan(self, evt) {
      if (evt.which === 13 && !self.isTextarea) {
        evt.preventDefault();
      }
      self.keyPressedValue = String.fromCharCode(evt.keyCode);
    },
    pasteSpan: function pasteSpan(self) {
      self.set('pasteTriggered', true);
    },
    keyUpSpan: function keyUpSpan(self, evt) {
      var _iterator3 = _createForOfIteratorHelper(evt.target.getElementsByClassName('dynamic-attr')),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var block = _step3.value;
          block.className = 'dynamic-attr';
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      if (navigator.userAgent.indexOf("Firefox") > 1) {
        var sel = window.getSelection();
        var range = sel.getRangeAt(0);
        if (range.startContainer.parentElement.className === 'dynamic-attr__default-value' || range.startContainer.parentElement.className === 'dynamic-attr__attr') {
          range.deleteContents();
          var wrapperElement = range.startContainer.parentElement.parentElement.parentElement;
          if (evt.keyCode === 8 || evt.keyCode === 46) {
            wrapperElement.removeChild(wrapperElement.lastChild);
          } else {
            wrapperElement.insertBefore(document.createTextNode(self.keyPressedValue), null);
          }
        }
        self.keyPressedValue = '';
      }
      if (self.field) {
        if (self.get('pasteTriggered')) {
          (function () {
            var elementWithoutBr = evt.target.getElementsByTagName('br');
            if (elementWithoutBr.length) {
              for (var index = elementWithoutBr.length - 1; index >= 0; index--) {
                if (self.field.isTextarea) {
                  if (elementWithoutBr[index].className.includes('Apple-interchange-newline')) {
                    elementWithoutBr[index].parentElement.removeChild(elementWithoutBr[index]);
                  }
                } else {
                  elementWithoutBr[index].parentElement.removeChild(elementWithoutBr[index]);
                }
              }
            }
            var childElements = evt.target.children;
            for (var _index = 0; _index < childElements.length; _index++) {
              self.wrapSpanElement(childElements, _index);
              if (!childElements[_index].classList.contains('dynamic-attr')) {
                var innerChildElements = childElements[_index].children;
                for (var innerIndex = 0; innerIndex < innerChildElements.length; innerIndex++) {
                  self.wrapSpanElement(innerChildElements, innerIndex);
                }
              }
              var quoteElements = childElements[_index].getElementsByClassName('dynamic-attr__default-value');
              for (var qIndex = 0; qIndex < quoteElements.length; qIndex++) {
                if (quoteElements[qIndex].innerText.trim() === '') {
                  quoteElements[qIndex].remove();
                }
              }
            }
            var contentMenuElement = evt.target.getElementsByClassName('dynamic-attr');
            var _loop3 = function _loop3(_index2) {
              contentMenuElement[_index2].addEventListener('contextmenu', function (ev) {
                ev.preventDefault();
                self.updateDynamicAttribute(ev.currentTarget);
                contentMenuElement[_index2].className = 'dynamic-attr';
                return false;
              }, false);
            };
            for (var _index2 = 0; _index2 < contentMenuElement.length; _index2++) {
              _loop3(_index2);
            }
            self.set('pasteTriggered', false);
          })();
        }
        self.setAndCheckFieldValue(self, evt.target);
      }
    },
    wrapSpanElement: function wrapSpanElement(childElements, index) {
      if (childElements[index].classList.contains('dynamic-attr__attr')) {
        var el = childElements[index];
        var wrapper = document.createElement('span');
        wrapper.setAttribute('class', 'dynamic-attr');
        wrapper.setAttribute('title', 'right click to edit');
        wrapper.setAttribute('contenteditable', false);
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
        if (childElements[index + 1].classList.contains('dynamic-attr__default-value')) {
          wrapper.append(childElements[index + 1]);
        }
      }
    },
    replaceDivWithPTag: function replaceDivWithPTag(html) {
      var divWrapper = _toConsumableArray(html.querySelectorAll('div'));
      for (var i = divWrapper.length - 1; i >= 0; --i) {
        var wrapper = divWrapper[i];
        if (wrapper.childNodes.length) {
          var pWrapper = document.createElement('p');
          pWrapper.className = 'dynamic-attr-p';
          for (var j = 0; j <= wrapper.childNodes.length - 1;) {
            pWrapper.append(wrapper.childNodes[j]);
          }
          wrapper.parentNode.insertBefore(pWrapper, wrapper);
        }
        wrapper.remove();
      }
      return html;
    },
    setAndCheckFieldValue: function setAndCheckFieldValue(self, element) {
      if (self.field.componentName === 'content-tmp-edit-text-locale') {
        if (!self.get('field.value')) {
          self.set('field.value', {});
        }
        if (!(self.field.isTextarea || self.field.inputUI === 'textarea')) {
          var elementWithoutBr = element.getElementsByTagName('br');
          if (elementWithoutBr.length) {
            for (var index = elementWithoutBr.length - 1; index >= 0; index--) {
              elementWithoutBr[index].parentElement.removeChild(elementWithoutBr[index]);
            }
          }
        }
        self.replaceDivWithPTag(element);
        self.set("field.value.".concat(self.selectedLanguageCode), (0, _dynamicAttributeRtfSerializer.serializeDynamicAttributes)(element.innerHTML, self.isRichTextEnabled, self.field.isTextarea));
        self.set('field.isValid', true);
        self.set('field.errorMessages', []);
        if (self.get('field.isRequired')) {
          var fieldText = self.get("field.value.".concat(self.defaultLanguage.id));
          if (fieldText) {
            var domElement = document.createElement('div');
            domElement.innerHTML = self.get("field.value.".concat(self.defaultLanguage.id));
            fieldText = domElement.innerText;
          }
          if (Ember.isEmpty(fieldText)) {
            self.set('field.isValid', false);
            self.field.errorMessages.pushObject("You must populate the ".concat(self.defaultLanguage.name, " field"));
          }
        }
      } else {
        self.set('field.value', (0, _dynamicAttributeRtfSerializer.serializeDynamicAttributes)(element.innerHTML, self.isRichTextEnabled, self.field.isTextarea));
      }
    },
    labelToOption: function labelToOption(value1) {
      var spiltValue = value1.split('/');
      var isDefaultValue = spiltValue[1].includes('"');
      var secondValue = isDefaultValue ? spiltValue[1].split('"')[0] : spiltValue[1];
      this.set('defaultValue', isDefaultValue ? spiltValue[1].split('"')[1] : '');
      var asd = this.contentPluginList.find(function (_ref) {
        var label = _ref.label,
          pluginAttribute = _ref.pluginAttribute;
        return label == spiltValue[0].trim() && pluginAttribute == secondValue.trim();
      });
      return asd;
    },
    labelAdd: function labelAdd(self, event) {
      self.set('currentTarget', null);
      if (window.getSelection) {
        var sel = window.getSelection();
        if (sel && sel.focusNode && sel.focusNode.parentElement.nextElementSibling === event.target) {
          if (sel.getRangeAt && sel.rangeCount) {
            self.set('range', sel.getRangeAt(0));
          }
        } else if (sel && sel.focusNode && sel.focusNode.parentElement && (sel.focusNode.parentElement.className === 'dynamic-attr-wrapper' || sel.focusNode.parentElement.className === 'dynamic-attr-p') && sel.focusNode.parentElement.parentElement.nextElementSibling === event.target) {
          if (sel.getRangeAt && sel.rangeCount) {
            self.set('range', sel.getRangeAt(0));
          }
        } else {
          self.set('currentTarget', event.target.previousElementSibling);
        }
      } else if (document.selection && document.selection.type != "Control") {
        document.selection.createRange().pasteHTML('html');
      }
      self.set('isEditDynamic', false);
      self.set('defaultValue', '');
      self.set('isModal', true);
    },
    tokenElement: function tokenElement(self, value, defaultValue, id, isCurrentTarget) {
      var outerSpan = document.createElement("span");
      var spanWrapper = document.createElement("span");
      spanWrapper.setAttribute('class', 'dynamic-attr-wrapper');
      var spanAttr = document.createElement("span");
      spanAttr.setAttribute('class', 'dynamic-attr');
      spanAttr.setAttribute('title', 'right click to edit');
      spanAttr.setAttribute('contenteditable', false);
      spanAttr.addEventListener('contextmenu', function (ev) {
        ev.preventDefault();
        self.updateDynamicAttribute(ev.currentTarget);
        spanAttr.className = 'dynamic-attr';
        return false;
      }, false);
      var spanTitle = document.createElement("span");
      spanTitle.setAttribute('class', 'dynamic-attr__attr');
      spanTitle.setAttribute('data-mce-dynamic-attribute', id);
      spanTitle.innerHTML = value;
      spanAttr.append(spanTitle);
      if (defaultValue) {
        var qDefault = document.createElement("q");
        qDefault.setAttribute('class', 'dynamic-attr__default-value');
        qDefault.innerHTML = defaultValue;
        spanAttr.append(qDefault);
      }
      spanWrapper.append(spanAttr);
      outerSpan.append(spanWrapper);
      return isCurrentTarget ? spanWrapper : outerSpan;
    },
    setCursor: function setCursor(mainElement, currentElement) {
      var setCursorPosition = document.createRange();
      var selection = window.getSelection();
      var elementStart = null;
      var cursorPosition = 1;
      if (this.isEditDynamic) {
        elementStart = currentElement;
      } else {
        if (mainElement === currentElement) {
          if (mainElement.childNodes.length) {
            elementStart = mainElement.childNodes[mainElement.childNodes.length - 1];
          } else {
            elementStart = mainElement;
          }
        } else {
          for (var index = 0; index < mainElement.childNodes.length; index++) {
            if (mainElement.childNodes[index] === currentElement) {
              if (this.isEditDynamic) {
                elementStart = mainElement.childNodes[index];
              } else {
                elementStart = currentElement.nodeName === '#text' ? mainElement.childNodes[index + 2] : mainElement.childNodes[index];
                cursorPosition = 0;
              }
              break;
            } else if (mainElement.childNodes[index].hasChildNodes(currentElement)) {
              for (var innerIndex = 0; innerIndex < mainElement.childNodes[index].childNodes.length; innerIndex++) {
                if (mainElement.childNodes[index].childNodes[innerIndex] === currentElement) {
                  if (this.isEditDynamic) {
                    elementStart = mainElement.childNodes[index].childNodes[innerIndex];
                  } else {
                    elementStart = currentElement.nodeName === '#text' ? mainElement.childNodes[index].childNodes[innerIndex + 2] : mainElement.childNodes[index].childNodes[innerIndex];
                    cursorPosition = 0;
                  }
                }
              }
            }
          }
        }
      }
      if (elementStart) {
        elementStart = elementStart.parentElement.className === 'dynamic-attr-wrapper' ? elementStart.parentElement : elementStart;
        setCursorPosition.setStart(elementStart, cursorPosition);
      }
      setCursorPosition.collapse(true);
      selection.removeAllRanges();
      selection.addRange(setCursorPosition);
      mainElement.focus();
    },
    actions: {
      updateData: function updateData(value, defaultValue, id) {
        var currentElement = this.element.getElementsByClassName('dynamic-editable-block')[0];
        var self = this;
        if (this.isEditDynamic) {
          var tokenList = currentElement.getElementsByClassName('dynamic-attr');
          var _iterator4 = _createForOfIteratorHelper(tokenList),
            _step4;
          try {
            for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
              var element = _step4.value;
              if (element == this.dynamicAtrributeValue.value) {
                var edittedToken = this.dynamicAtrributeValue.value;
                edittedToken.getElementsByClassName('dynamic-attr__attr')[0].innerText = value;
                edittedToken.getElementsByClassName('dynamic-attr__attr')[0].setAttribute("data-mce-dynamic-attribute", id);
                if (edittedToken.getElementsByClassName('dynamic-attr__default-value')[0]) {
                  if (defaultValue) {
                    edittedToken.getElementsByClassName('dynamic-attr__default-value')[0].innerText = defaultValue ? defaultValue : '';
                  } else {
                    edittedToken.removeChild(edittedToken.getElementsByClassName('dynamic-attr__default-value')[0]);
                  }
                } else if (defaultValue) {
                  var qDefault = document.createElement("q");
                  qDefault.setAttribute('class', 'dynamic-attr__default-value');
                  qDefault.innerHTML = defaultValue;
                  edittedToken.append(qDefault);
                }
              }
            }
          } catch (err) {
            _iterator4.e(err);
          } finally {
            _iterator4.f();
          }
        } else {
          if (this.currentTarget) {
            this.currentTarget.append(this.tokenElement(self, value, defaultValue, id, true));
            var elementWidth = this.currentTarget.offsetWidth;
            var elementScrollWidth = this.currentTarget.scrollWidth;
            this.currentTarget.scrollLeft = elementScrollWidth - elementWidth;
          } else {
            this.range.deleteContents();
            var ptag = this.tokenElement(self, value, defaultValue, id, false);
            var frag = document.createDocumentFragment(),
              node;
            while (node = ptag.firstChild) {
              frag.appendChild(node);
            }
            this.range.insertNode(frag);
          }
        }
        this.setAndCheckFieldValue(this, currentElement);
        if (this.isEditDynamic) {
          this.setCursor(currentElement, this.dynamicAtrributeValue.value);
        } else {
          if (this.currentTarget) {
            this.setCursor(currentElement, this.currentTarget);
          } else {
            this.setCursor(currentElement, this.range.startContainer);
          }
        }
      }
    }
  });
  _exports.default = _default;
});