define("experience-studio/components/av5-info-card-base", ["exports", "experience-studio/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Serves as a base component that makes a request to the server, and makes
    a promise available to child components.
  
    Usages:
  
      <Av5InfoCardBase  />
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-info-card-base'],
    currentQueryParams: null,
    model: null,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    av5AnalyticsStore: Ember.inject.service(),
    // COMPUTED ------------------------------------------------------------------
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    // FUNCTIONS -----------------------------------------------------------------
    fetchAll: function fetchAll(requests) {
      var _this = this;
      var requestArray = [];
      requests.forEach(function (request) {
        requestArray.pushObject(_this.fetchData(request));
      });
      return Ember.RSVP.allSettled(requestArray);
    },
    fetchData: function fetchData(data) {
      var options = {};
      options.isProduction = data.isProduction;
      options.isCached = data.isCached;
      options.endpoint = data.endpoint;
      options.domain = data.domain;
      options.from = data.from;
      options.to = data.to;
      options.activeTo = data.activeTo;
      options.activeFrom = data.activeFrom;
      options.activeDays = data.activeDays;
      options.type = 'GET';
      options.queryParams = data.queryParams;
      options.interval = data.interval || 'day', options.headers = {
        'X-Authorization': this.session.token
      };
      if (options.isCached) {
        var cachedValue = this.av5AnalyticsStore.checkCache(this.av5AnalyticsStore.generateKey(options));
        if (cachedValue) {
          if (cachedValue.then) {
            // unresolved promise, just return the promise
            return cachedValue;
          } else {
            // already existing entry
            return new Promise(function (resolve) {
              resolve(cachedValue);
            });
          }
        } else {
          // create cache entry
          var retPromise = this.makeAPIRequest(options, data);
          this.av5AnalyticsStore.storeCache(this.av5AnalyticsStore.generateKey(options), retPromise);
          return retPromise;
        }
      }
      // default behaviour
      return this.makeAPIRequest(options, data);
    },
    thenCallback: function thenCallback(data, request) {
      // update cache
      if (request.isCached) {
        this.av5AnalyticsStore.storeCache(this.av5AnalyticsStore.generateKey(request), data);
      }
      return data;
    },
    catchCallback: function catchCallback(data) {
      return data;
    },
    makeAPIRequest: function makeAPIRequest(options) {
      var _this2 = this;
      if (!options.isProduction) {
        if (!options.endpoint.includes('timeline/active')) {
          if (Ember.isEmpty(options.from) && Ember.isEmpty(options.to)) {
            options.url = "".concat(_environment.default.APP.mirageUrl, "/").concat(options.domain, "/").concat(options.endpoint);
          } else if (options.endpoint === 'analytics/users/totalActive') {
            options.url = "".concat(_environment.default.APP.mirageUrl, "/").concat(options.domain, "/").concat(options.endpoint, "?createdAt=").concat(options.from / 1000);
          } else {
            options.url = "".concat(_environment.default.APP.mirageUrl, "/").concat(options.domain, "/").concat(options.endpoint, "?activeFrom=").concat(options.from, "&activeTo=").concat(options.to, "&interval=").concat(options.interval);
          }
        } else {
          var str = "".concat(options.endpoint, "?activeDays=").concat(options.activeDays, "&lastFrom=").concat(options.from, "&lastTo=").concat(options.to, "&aggregate=").concat(options.interval, "&interval=day");
          options.url = "".concat(_environment.default.APP.mirageUrl, "/").concat(options.domain, "/").concat(str);
        }
      } else {
        if (!options.endpoint.includes('timeline/active')) {
          if (Ember.isEmpty(options.from) && Ember.isEmpty(options.to)) {
            options.url = "".concat(_environment.default.APP.hostUrl, "/").concat(options.domain, "/").concat(options.endpoint);
          } else if (options.endpoint === 'analytics/users/totalActive') {
            options.url = "".concat(_environment.default.APP.hostUrl, "/").concat(options.domain, "/").concat(options.endpoint, "?createdAt=").concat(options.from / 1000);
          } else if (options.endpoint === 'devices/totalEngaged') {
            options.url = "".concat(_environment.default.APP.hostUrl, "/").concat(options.domain, "/").concat(options.endpoint, "?createdAt=").concat(options.from / 1000);
          } else {
            options.url = "".concat(_environment.default.APP.hostUrl, "/").concat(options.domain, "/").concat(options.endpoint, "?startTime=").concat(options.from, "&endTime=").concat(options.to, "&interval=").concat(options.interval);
          }
        } else {
          var _str = "".concat(options.endpoint, "?activeDays=").concat(options.activeDays, "&lastFrom=").concat(options.from, "&lastTo=").concat(options.to, "&aggregate=").concat(options.interval, "&interval=day");
          options.url = "".concat(_environment.default.APP.hostUrl, "/").concat(options.domain, "/").concat(_str);
        }
      }
      if (!Ember.isEmpty(options.queryParams)) {
        options.url = "".concat(options.url, "?");
        var keys = [];
        var queryStings = [];
        for (var key in options.queryParams) {
          if (options.queryParams.hasOwnProperty(key)) {
            keys.pushObject(key);
          }
        }
        keys.forEach(function (key) {
          queryStings.pushObject("".concat(key, "=").concat(options.queryParams[key]));
        });
        options.url = options.url + queryStings.join('&');
      }
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax(options).done(function (response) {
          resolve(_this2.thenCallback(response, options));
        }).catch(function (response) {
          reject(_this2.catchCallback(response));
        });
      });
    },
    checkErrors: function checkErrors(resArr) {
      return resArr.reduce(function (accumulator, current) {
        return accumulator | current.state === 'rejected';
      }, false);
    },
    // currently not used, potential future use
    aggregateErrors: function aggregateErrors(resArr) {
      var errCodes = [];
      resArr.forEach(function (res) {
        if (res.state === 'rejected') {
          errCodes.pushObject(res.reason.status);
        }
      });
      return errCodes.join(', ');
    },
    getLastError: function getLastError(resArr) {
      var err;
      resArr.forEach(function (res) {
        if (res.state === 'rejected') {
          err = res.reason;
        }
      });
      return err;
    } // ACTIONS -------------------------------------------------------------------
  });
  _exports.default = _default;
});