define("experience-studio/templates/components/content-tmp-edit-media-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "APj4df54",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"container clearfix\"],[12],[2,\"\\n      \"],[10,\"img\"],[15,\"src\",[34,5,[\"defaultOption\",\"defaultImage\"]]],[14,0,\"default-image\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"alert alert-info configuration-alert-box\"],[14,\"role\",\"alert\"],[12],[2,\"\\n          \"],[1,[30,[36,6],[\"exclamation-triangle\"],null]],[2,\"\\n          This default image is not configurable.\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"value\",\"requirements\",\"defaultTransform\"],[[35,0],[35,3],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"value\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"media-field\",\"defaultTransform\",\"requirements\",\"image-field\",\"field\",\"svg-jar\",\"isImageDisabled\",\"if\",\"isImage\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-media-url.hbs"
  });
  _exports.default = _default;
});