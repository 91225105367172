define("experience-studio/templates/components/model-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KoH84SoY",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,15],[[30,[36,14],[[30,[36,14],[[35,13]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,12],[[35,11]],[[\"customAction\",\"didClick\",\"didDoubleClick\",\"disableIfBelongsToExperience\",\"model\",\"modelName\",\"showModal\",\"showPriority\",\"sortingData\",\"removeProcessedItem\",\"deleteUploadedFile\",\"processFile\"],[[30,[36,2],[[32,0],\"customAction\"],null],[30,[36,2],[[32,0],\"didClick\"],null],[30,[36,2],[[32,0],\"didDoubleClick\"],null],[35,10],[32,1],[35,0],[30,[36,2],[[32,0],\"showModal\"],null],[35,9],[35,8],\"removeProcessedItem\",\"deleteUploadedFile\",\"processFile\"]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"no-model-message no-model-message-list-item\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"graphic no-search-results\"],[12],[13],[2,\"\\n      No \"],[1,[34,5]],[2,\" found for \"],[10,\"strong\"],[12],[1,[34,6]],[13],[2,\".\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"graphic \",[34,0]]]],[12],[13],[2,\"\\n      \"],[10,\"h4\"],[12],[1,[34,1]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"createModelListItem\"],null],[12],[1,[34,3]],[13],[2,\" \"],[1,[34,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modelName\",\"emptyStateTitle\",\"action\",\"emptyStateLinkText\",\"emptyStateText\",\"pluralizedModelName\",\"searchKeyword\",\"if\",\"sortingData\",\"showPriority\",\"disableIfBelongsToExperience\",\"componentName\",\"component\",\"modelSham\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/model-list.hbs"
  });
  _exports.default = _default;
});