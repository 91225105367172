define("experience-studio/components/localized-text-dynamic", ["exports", "experience-studio/mixins/localized-input-text-component"], function (_exports, _localizedInputTextComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_localizedInputTextComponent.default, {
    classNames: ['localized-text-dynamic'],
    languages: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    model: null,
    property: '',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.languages.filterBy('isDefault', true).objectAt(0).set('isSelected', true);
    },
    value: Ember.computed('model', 'languages.@each.isSelected', 'property', function () {
      return this.get("model.".concat(this.selectedLanguageCode, ".").concat(this.property));
    }),
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    actions: {
      selectLanguage: function selectLanguage(language) {
        this.languages.setEach('isSelected', false);
        language.set('isSelected', true);
      }
    }
  });
  _exports.default = _default;
});