define("experience-studio/templates/components/priority-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hSDWbupc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"diamond\"],null]],[2,\" \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"diamond\"],null]],[2,\" N/A\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"priority\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/priority-indicator.hbs"
  });
  _exports.default = _default;
});