define("experience-studio/services/error-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    extractMessage: function extractMessage(data) {
      var message;
      if (data.errors && !Ember.isEmpty(data.errors)) {
        message = data.errors.objectAt(0).detail;
      } else if (data.responseJSON && data.responseJSON.error && data.responseJSON.error.exceptionMessage) {
        message = data.responseJSON.error.exceptionMessage;
      } else if (data.responseJSON && data.responseJSON.exceptionMessage) {
        message = data.responseJSON.exceptionMessage;
      } else if (data.responseJSON && data.responseJSON.message) {
        message = data.responseJSON.message;
      } else if (data.responseText) {
        message = data.responseText;
      } else if (data.message) {
        message = data.message;
      } else if (typeof data === 'string' || data instanceof String) {
        message = data;
      } else {
        message = 'An unknown error has occurred. If the problem persists, ';
        message += 'please contact an administrator.';
      }
      return message;
    },
    extractStatus: function extractStatus(data) {
      var status;
      if (data.errors && !Ember.isEmpty(data.errors)) {
        status = data.errors.objectAt(0).status;
      } else if (data.status) {
        status = data.status;
      } else {
        status = -1;
      }
      return parseInt(status);
    },
    parse: function parse(data) {
      var message = this.extractMessage(data);
      var status = this.extractStatus(data);

      // This error was added as a short-term solution to return a more meaningful error.
      if (message.indexOf('UserID cannot be changed in a user scope production Rule') != -1) {
        message = this.intl.t('errors.invalid.permission.not_modifiable');
      }
      if (status === 401 || status === 403 || status === 500 && message === 'Failed to get all Context Plugins') {
        message = this.intl.t('errors.invalid.permission.not_authorized');
      }
      return message;
    }
  });
  _exports.default = _default;
});