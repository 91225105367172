define("experience-studio/components/info-sidebar-rule-composition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar-rule-composition'],
    model: null,
    store: Ember.inject.service(),
    complexHistoricalPreview: Ember.computed('model', function () {
      var _this = this;
      var result = [];
      var literals = [];
      if (!Ember.isEmpty(this.model.andLiterals)) {
        literals = this.model.andLiterals;
      } else if (!Ember.isEmpty(this.model.orLiterals)) {
        literals = this.model.orLiterals;
      }
      if (!Ember.isEmpty(literals)) {
        var predicateResultVariable = '';
        literals.forEach(function (item) {
          var first = '';
          var second = Ember.Object.create({
            predicate: item.predicate.name
          });
          var third = Ember.Object.create({
            value: item.predicate.arguments[1]
          });
          if (item.predicate.arguments[0] !== predicateResultVariable) {
            first = Ember.Object.create({
              value: item.predicate.arguments[0]
            });
          }
          if (first && first.value.indexOf('ctxhistory') >= 0) {
            var id = first.value.split('.').pop();
            first.set('record', _this.store.findRecord('context-historical-query', id));
          }
          if (third && third.value.indexOf('ctxhistory') >= 0) {
            var _id = third.value.split('.').pop();
            third.set('record', _this.store.findRecord('context-historical-query', _id));
          }
          if (second.predicate) {
            second.set('predicate', {
              'eq': 'is equal to',
              'neq': 'is not equal to',
              'ls': 'is less than',
              'lseq': 'is less than or equal to',
              'gr': 'is greater than',
              'greq': 'is greater than or equal to',
              'add': 'plus',
              'div': 'divided by',
              'mod': 'mod',
              'multiply': 'multiply by',
              'power': 'power',
              'subtract': 'minus'
            }[second.predicate]);
          }
          result.pushObject([first, second, third]);
          predicateResultVariable = item.predicate.arguments[2];
        });
      }
      return result;
    }),
    isTriggered: Ember.computed('pushObject.type', function () {
      return this.pushObject && this.pushObject.type === 'triggered';
    }),
    andBlocks: Ember.computed('model', function () {
      if (this.get('model.id')) {
        return this.get('model.body.andBlocks');
      } else {
        return this.get('model.andBlocks');
      }
    }),
    andLiterals: Ember.computed('model', function () {
      if (this.get('model.id')) {
        return this.get('model.body.andLiterals');
      } else {
        return this.get('model.andLiterals');
      }
    }),
    orBlocks: Ember.computed('model', function () {
      if (this.get('model.id')) {
        return this.get('model.body.orBlocks');
      } else {
        return this.get('model.orBlocks');
      }
    }),
    orLiterals: Ember.computed('model', function () {
      if (this.get('model.id')) {
        return this.get('model.body.orLiterals');
      } else {
        return this.get('model.orLiterals');
      }
    })
  });
  _exports.default = _default;
});