define("experience-studio/routes/content-hub/content-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      var isCallingFromTemplatedExp = this._router.currentURL;
      if (isCallingFromTemplatedExp.indexOf('create-v2') !== -1) {
        this.transitionTo('content-hub.content-template.create-v2');
      } else if (model.get('isConciergeTemplate')) {
        this.transitionTo('content-hub.content-template.create');
      } else {
        if (model.get('type').toLowerCase().indexOf('survey') >= 0) {
          this.transitionTo('content-hub.content-template.form.create');
        } else {
          this.transitionTo('content-hub.content-template.create');
        }
      }
    }
  });
  _exports.default = _default;
});