define("experience-studio/templates/components/main-header-info-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Z4laeZBx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,15],null,[[\"changeGroupPriorities\",\"data\",\"deselectModelListItems\",\"didChangeTab\",\"didClickInfoButton\",\"didUpdateSearchKeyword\",\"dispatchAction\",\"groupId\",\"groups\",\"hasGroups\",\"isInfoSidebarOpen\",\"isLoadingAffectedRules\",\"isModelItemSelected\",\"isRouteListView\",\"isSearchFormHidden\",\"mainHeaderTabs\",\"searchKeyword\",\"selectedGroup\",\"showInfoButton\",\"showManageGroupModalDialog\",\"title\",\"updateQueryParams\"],[\"changeGroupPriorities\",[35,14],\"deselectModelListItems\",\"didChangeTab\",\"toggleInfoSidebar\",\"didUpdateSearchKeyword\",\"dispatchAction\",[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],\"showManageGroupModalDialog\",[35,1],[30,[36,0],[[32,0],\"updateQueryParams\"],null]]]]],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"content--info-sidebar--container \",[30,[36,16],[[35,10],\"is-info-sidebar-open\"],null]]]],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"content-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,20],null,[[\"close\",\"deselectModelListItems\",\"isInfoSidebarOpen\",\"languages\",\"selectedEntities\",\"title\"],[\"toggleInfoSidebar\",\"deselectModelListItems\",[35,10],[35,19],[35,18],[35,17]]]]],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"headerTitle\",\"showInfoButton\",\"selectedGroup\",\"searchKeyword\",\"mainHeaderTabs\",\"isSearchFormHidden\",\"isRouteListView\",\"isModelItemSelected\",\"isLoadingAffectedRules\",\"isInfoSidebarOpen\",\"hasGroups\",\"groups\",\"groupId\",\"data\",\"main-header\",\"if\",\"sideBarTitle\",\"selectedEntities\",\"languages\",\"info-sidebar\"]}",
    "moduleName": "experience-studio/templates/components/main-header-info-sidebar.hbs"
  });
  _exports.default = _default;
});