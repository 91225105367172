define("experience-studio/templates/components/content-tmp-edit-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ngdi6dgI",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,12]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,8],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,10],null,[[\"className\",\"placeholder\",\"value\",\"isRichText\",\"maxLength\",\"key\",\"idName\",\"plugins\",\"field\",\"languages\",\"didFocusIn\",\"updateDynamicAttribute\",\"dynamicAtrributeValue\"],[\"field input-field data-field\",[35,0,[\"placeholder\"]],[35,0,[\"value\"]],[35,0,[\"isRichTextEnabled\"]],[35,0,[\"maxLength\"]],[35,0,[\"key\"]],\"textarea\",[35,5],[35,0],[35,4],[30,[36,3],[[32,0],\"focusIn\"],null],[30,[36,3],[[32,0],\"updateDynamicAttribute\"],null],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,9],null,[[\"class\",\"placeholder\",\"value\",\"rows\"],[\"form-control\",[35,0,[\"placeholder\"]],[35,0,[\"value\"]],\"4\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"field\",\"plugins\",\"languages\",\"updateDynamicAttribute\",\"dynamicAtrributeValue\"],[[35,0],[35,5],[35,4],[30,[36,3],[[32,0],\"updateDynamicAttribute\"],null],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"value\",\"placeholder\",\"maxlength\",\"focus-out\"],[\"text\",\"field input-field data-field\",[35,0,[\"value\"]],[35,0,[\"placeholder\"]],[35,0,[\"maxLength\"]],\"trimValue\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"field\",\"input\",\"dynamicAtrributeValue\",\"action\",\"languages\",\"plugins\",\"dynamic-attribute-input\",\"isDynamicAttributeFeatureEnabled\",\"if\",\"textarea\",\"rich-text-input\",\"isTextAreaWithFeature\",\"isTextArea\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-text.hbs"
  });
  _exports.default = _default;
});