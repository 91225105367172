define("experience-studio/components/context-hub-attribute", ["exports", "experience-studio/utils/nearby-location"], function (_exports, _nearbyLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['context-hub-attribute'],
    locationErrorMessage: null,
    isRefreshFilterModel: false,
    locationModel: null,
    model: null,
    selectedCompositionGroup: null,
    selectedLocationLabels: null,
    userPreferences: Ember.inject.service(),
    displayOption: null,
    mathOperators: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      label: 'Add',
      text: 'plus',
      value: '+'
    }), Ember.Object.create({
      label: 'Subtract',
      text: 'minus',
      value: '-'
    }), Ember.Object.create({
      label: 'Multiply',
      text: 'multiplied by',
      value: '*'
    }), Ember.Object.create({
      label: 'Divide',
      text: 'divided by',
      value: '/'
    }), Ember.Object.create({
      label: 'Exponent',
      text: 'to the power',
      value: '^'
    }), Ember.Object.create({
      label: 'Modulo',
      text: 'modulo',
      value: '%'
    })],
    attributes: Ember.computed('model', function () {
      var attributes = [];
      var attributesObj = this.get('model.originalModel.attributes');
      for (var property in attributesObj) {
        if (attributesObj.hasOwnProperty(property)) {
          var attribute = attributesObj[property];
          this.setupAttribute(attribute);
          attributes.pushObject(attribute);
        }
      }
      return attributes;
    }),
    boolValueTypeOptions: Ember.computed('model', 'attributes', function () {
      for (var attr in this.attributes) {
        if (this.attributes[attr].id === this.model.selectedAttribute.id) {
          return this.attributes[attr].comparisonPredicates;
        }
      }
      return [];
    }),
    selectedBoolValueTypeOptions: Ember.computed('model.value', 'boolValueTypeOptions', function () {
      var _this = this;
      return this.boolValueTypeOptions.find(function (type) {
        return type.boolValue === _this.model.value;
      }) || null;
    }),
    attributesToCompare: Ember.computed('model.selectedAttribute', function () {
      var attributes = [];
      var plugins = this.plugins;
      var selectedAttribute = this.get('model.selectedAttribute');
      if (!Ember.isEmpty(plugins) && selectedAttribute) {
        plugins.forEach(function (plugin) {
          var pluginAttributes = plugin.get('attributes');
          var keys = window.Object.keys(pluginAttributes);
          if (pluginAttributes && !Ember.isEmpty(keys)) {
            keys.forEach(function (key) {
              var pluginAttribute = pluginAttributes.get(key);
              if (pluginAttribute.get('uid') !== selectedAttribute.get('uid') && pluginAttribute.get('valueType') === selectedAttribute.get('valueType')) {
                attributes.pushObject(Ember.Object.create({
                  label: "".concat(plugin.get('name'), " - ").concat(pluginAttribute.get('name')),
                  value: pluginAttribute.get('uid')
                }));
              }
            });
          }
        });
      }
      return attributes;
    }),
    isLocationAttribute: Ember.computed('model.originalModel.uid', function () {
      return this.get('model.originalModel.uid').indexOf('ctx.flybits.area') >= 0;
    }),
    parametersCompostionItem: Ember.computed('model.selectedAttribute', function () {
      var parameters = [];
      if (this.model && this.model.parameters) {
        parameters = this.model.parameters;
      } else if (this.model && this.model.selectedAttribute && Array.isArray(this.model.selectedAttribute.parameters)) {
        this.model.selectedAttribute.parameters.forEach(function (item) {
          parameters.pushObject(Ember.Object.create(JSON.parse(JSON.stringify(item))));
        });
        this.model.parameters = parameters;
      }
      return parameters;
    }),
    didSelectedAttribute: Ember.observer('model.selectedAttribute', function () {
      var selectedAttribute = this.get('model.selectedAttribute');
      if (selectedAttribute) {
        selectedAttribute.set('isSelected', true);
      }
    }),
    didUpdateCaseSensitive: Ember.observer('model.isCaseSensitive', function () {
      var model = this.model;
      var predicates = this.getPredicates(model.get('selectedAttribute'));
      model.set('comparisonPredicate', null);
      model.set('selectedAttribute.comparisonPredicates', predicates);
    }),
    didUpdateValues: Ember.observer('model.attributeToCompare', 'model.comparisonPredicate', 'model.isCaseSensitive', 'model.selectedAttribute', 'model.value', function () {
      var model = this.model;
      var comparisonPredicateLabel = model.get('comparisonPredicate.label');
      var description = null;
      var selectedAttribute = model.get('selectedAttribute');
      if (selectedAttribute) {
        if (comparisonPredicateLabel) {
          description = "".concat(selectedAttribute.get('name'), " ").concat(comparisonPredicateLabel);
        }
        if (model.get('attributeToCompare')) {
          model.set('value', model.get('attributeToCompare.label'));
        }
        if (model.get('value')) {
          var value = model.get('value');
          if (model.get('isCaseSensitive')) {
            value = "".concat(value, " (Case Sensitive)");
          }
          description = "".concat(description, " ").concat(value);
        }
        model.set('description', description);
      }
    }),
    observeIsCompareAttribute: Ember.observer('model.isCompareAttribute', function () {
      var model = this.model;
      if (!model.get('isCompareAttribute')) {
        model.setProperties({
          attributeToCompare: null,
          description: null,
          value: null
        });
      }
    }),
    observeIsExpression: Ember.observer('model.isExpression', function () {
      var model = this.model;
      if (model.get('isExpression')) {
        var expressionItems = model.get('expressionItems');
        expressionItems.clear();
        expressionItems.pushObject(Ember.Object.create({
          attribute: model.get('selectedAttribute'),
          isRoot: true,
          operator: null
        }));
      } else {
        model.set('expression', null);
      }
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var attributesToCompare = self.get('attributesToCompare');
        var model = self.get('model');
        if (model.originalModel.uid === 'ctx.flybits.location') {
          model.set('comparisonPredicate', null);
          model.set('selectedAttribute', self.attributes.objectAt(0));
          model.set('comparisonPredicate', self.attributes.objectAt(0).comparisonPredicates.filterBy('label', 'is').objectAt(0));
        }
        if (!Ember.isEmpty(attributesToCompare)) {
          var filteredItems = attributesToCompare.filterBy('value', model.get('value'));
          if (!Ember.isEmpty(filteredItems)) {
            model.set('attributeToCompare', filteredItems.get('firstObject'));
          }
        }
      });
    },
    getPredicates: function getPredicates(attribute) {
      var predicates = [];
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          break;
        case '[]string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Ember.Object.create({
              label: 'is',
              value: 'stringArrayIncludes'
            }));
          } else {
            predicates.pushObject(Ember.Object.create({
              label: 'is',
              value: 'stringArrayIncludes'
            }));
          }
          break;
        case 'bool':
          predicates.pushObject(Ember.Object.create({
            label: 'Yes',
            operator: '=',
            value: 'boolEq',
            boolValue: true
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'No',
            operator: '!=',
            value: 'boolNeq',
            boolValue: false
          }));
          break;
        case 'float':
        case 'int':
          predicates.pushObject(Ember.Object.create({
            label: 'is',
            value: 'eq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'is not',
            value: 'neq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'is less than',
            value: 'ls'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'is less than or equal to',
            value: 'lseq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'is greater than',
            value: 'gr'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'is greater than or equal to',
            value: 'greq'
          }));

          // historical conditions for Sum
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that are equal to',
            operator: '=',
            value: 'has_eq_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that are not equal to',
            operator: '=',
            value: 'has_not_eq_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have been greater than',
            operator: '>',
            value: 'has_gr_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have not been greater than',
            operator: '>',
            value: 'has_not_gr_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have been less than',
            operator: '<',
            value: 'has_ls_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have not been less than',
            operator: '<',
            value: 'has_not_ls_sum'
          }));
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          break;
        case 'string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Ember.Object.create({
              label: 'is',
              value: 'stringEq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'is not',
              value: 'stringNeq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'contains',
              value: 'stringContains'
            }));
          } else {
            predicates.pushObject(Ember.Object.create({
              label: 'is',
              value: 'stringFoldEq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'is not',
              value: 'stringFoldNeq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'contains',
              value: 'stringFoldContains'
            }));
          }
          break;
      }
      return predicates;
    },
    setupAttribute: function setupAttribute(attribute) {
      attribute.setProperties({
        comparisonPredicates: this.getPredicates(attribute),
        isCaseSensitiveVisible: false,
        isSupported: false
      });
      if (attribute.uid.indexOf('inArea') >= 0) {
        attribute.set('isInArea', true);
      } else if ((0, _nearbyLocation.inLabelLocation)(attribute.uid)) {
        attribute.set('IsInLocationWithLabel', true);
      }
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          attribute.setProperties({
            isArrayType: true,
            isSupported: false
          });
          break;
        case '[]string':
          attribute.setProperties({
            isArrayType: true,
            isCaseSensitiveVisible: false,
            isSupported: true
          });
          break;
        case 'bool':
          attribute.setProperties({
            isSupported: true,
            isYesNoType: true
          });
          break;
        case 'float':
        case 'int':
          if (attribute.isTimeContext) {
            attribute.setProperties({
              isDateTimeType: true,
              isSupported: true
            });
          } else {
            attribute.setProperties({
              isNumberType: true,
              isSupported: true
            });
          }
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          attribute.setProperties({
            isHashMapType: true,
            isSupported: false
          });
          break;
        case 'string':
          attribute.setProperties({
            isCaseSensitiveVisible: true,
            isSupported: true,
            isTextType: true
          });
          break;
      }
    },
    actions: {
      addLocation: function addLocation() {
        this.set('isAddLocation', true);
      },
      cancelLocation: function cancelLocation() {
        this.set('isAddLocation', false);
      },
      saveLocation: function saveLocation(location) {
        if (this.model.selectedAttribute) {
          this.model.selectedAttribute.setProperties({
            location: location,
            value: location.id
          });
        }
        this.set('isAddLocation', false);
      },
      didUpdateAttributeLocation: function didUpdateAttributeLocation(location) {
        this.model.selectedAttribute.setProperties({
          location: location,
          value: location.id
        });
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      addExpressionItem: function addExpressionItem(operator) {
        var expressionItems = this.get('model.expressionItems');
        if (expressionItems) {
          expressionItems.pushObject(Ember.Object.create({
            operator: operator,
            attribute: null
          }));
        }
      },
      selectValue: function selectValue(model, variable, selection) {
        model.set(variable, selection);
      },
      selectBoolValue: function selectBoolValue(model, selection) {
        model.set('comparisonPredicate', selection);
        model.set('value', selection.get('boolValue'));
      },
      selectPreconfiguredValue: function selectPreconfiguredValue(model, param) {
        if (model.selectedAttribute.isYesNoType) {
          model.set('comparisonPredicate', Ember.Object.create({
            value: 'boolEq',
            label: "".concat(param.value)
          }));
        } else {
          model.set('value', param.value);
        }
        this.set('displayOption', param);
      },
      selectParameter: function selectParameter(item, param) {
        if (item.isShowDisplayName) {
          item.set('value', param.value);
          this.set('displayOption', param);
        } else {
          item.set('value', param);
        }
      },
      removeExpressionItem: function removeExpressionItem(item) {
        var expressionItems = this.get('model.expressionItems');
        if (!Ember.isEmpty(expressionItems)) {
          expressionItems.removeObject(item);
        }
      },
      selectAttribute: function selectAttribute() {
        var model = this.model;
        model.set('selectedAttribute.isSelected', false);
        this.set('displayOption', null);
        model.setProperties({
          comparisonPredicate: null,
          description: null,
          selectedAttribute: null,
          attributeToCompare: null,
          value: null,
          isCaseSensitive: false,
          isCompareAttribute: false
        });
      }
    }
  });
  _exports.default = _default;
});