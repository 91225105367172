define("experience-studio/components/model-list-item-v2", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-list-item-v2 list-unstyled'],
    tagName: 'ul',
    highlightThresholdSeconds: 15,
    showPriority: false,
    isDropDownVisible: false,
    click: function click(event) {
      if (this.$(event.target).parents('.dropdown').length <= 0 && this.$(event.target).parents('footer').length <= 0) {
        if (this.$(event.target).parents('.checkbox-option').length > 0 && this.$(event.target).parents('.custom-radio').length <= 0) {
          this.send('multiSelect');
        } else {
          this.send('select');
        }
      }
    },
    doubleClick: function doubleClick(event) {
      if (this.isDoubleClick && (!this.get('model.isSelected') || this.$(event.target).parents('.dropdown').length <= 0 && this.$(event.target).parents('footer').length <= 0)) {
        this.send('edit');
      }
    },
    recentlyModified: Ember.computed('model', 'model.{modifiedAt,createdAt}', function () {
      var timeThreshold = window.moment(Date.now()).subtract(this.highlightThresholdSeconds, 'seconds');
      var createdTime = window.moment(this.get('model.modifiedAt') || this.get('model.createdAt'));
      return createdTime.isAfter(timeThreshold);
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.initializeTooltips();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.uninitializeTooltips();
      (0, _jquery.default)(document).off('click.experienceModelList');
    },
    initializeTooltips: function initializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    },
    actions: {
      delete: function _delete() {
        this.send('dispatchAction', 'delete', this.model); // eslint-disable-line ember/closure-actions
      },
      deselect: function deselect() {
        this.send('dispatchAction', 'deselect'); // eslint-disable-line ember/closure-actions
      },
      dispatchAction: function dispatchAction(name, model, params) {
        this.sendAction('dispatchAction', name, model, params); // eslint-disable-line ember/closure-actions
      },
      edit: function edit() {
        this.send('dispatchAction', 'edit', this.model, {
          queryParams: {}
        }); // eslint-disable-line ember/closure-actions
      },
      info: function info() {
        this.send('dispatchAction', 'show-info', this.model, {}); // eslint-disable-line ember/closure-actions
      },
      priority: function priority(action, parent) {
        this.send('dispatchAction', 'priority', this.model, {
          action: action,
          parent: parent
        }); // eslint-disable-line ember/closure-actions
      },
      select: function select() {
        this.send('dispatchAction', 'select', this.model, {}); // eslint-disable-line ember/closure-actions
      },
      multiSelect: function multiSelect() {
        this.send('dispatchAction', 'select', [this.model], {}); // eslint-disable-line ember/closure-actions
      },
      toggleProperty: function toggleProperty(property) {
        var model = this.model;
        var value = model.get(property);
        if (!model.get('isSaving')) {
          model.toggleProperty(property);
          model.save().catch(function () {
            model.set(property, value);
          });
        }
      }
    }
  });
  _exports.default = _default;
});