define("experience-studio/components/model-list-item-experience-v2", ["exports", "jquery", "experience-studio/components/model-list-item-v2"], function (_exports, _jquery, _modelListItemV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItemV.default.extend({
    classNames: ['model-list-item-experience-v2'],
    classNameBindings: ['showMoreContent', 'showLessContent'],
    showMoreContent: false,
    isDropdownOverflowLeft: false,
    showLessContent: Ember.computed.not('showMoreContent'),
    showContentPriority: true,
    showContextPriority: false,
    scrollThreshold: 30,
    doubleClick: function doubleClick(event) {
      if (this.$(event.target).parents('.internal-model-list-container').length <= 0) {
        this._super.apply(this, arguments);
      }
    },
    isCountPlural: Ember.computed('model.computedGroups', function () {
      var numGroups = this.get('model.computedGroups.length');
      return numGroups > 1 || numGroups === 0;
    }),
    isMoreContent: Ember.computed('model.computedContents', function () {
      return this.get('model.computedContents.length') > 2;
    }),
    numMoreContent: Ember.computed('isMoreContent', function () {
      return this.get('model.computedContents.length') - 2;
    }),
    click: function click(event) {
      if (!this.$(event.target).closest(this.$('.model-list-item-v2')).length) {
        this._super.apply(this, arguments);
      }
    },
    calculateDistanceFromTop: function calculateDistanceFromTop(element) {
      return element.offset().top - element.parent().offset().top - element.parent().scrollTop();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(document).on('click.experienceModelList', function (event) {
        if (_this.showMoreContent && !(0, _jquery.default)(event.target).closest(_this.$()).length && (0, _jquery.default)(event.target).closest('.content-container').length) {
          _this.send('showLessContent');
        }
      });
    },
    actions: {
      activation: function activation() {
        this.sendAction('dispatchAction', 'activation', this.model); // eslint-disable-line ember/closure-actions
      },
      edit: function edit() {
        this.sendAction('dispatchAction', 'edit', this.model, {
          // eslint-disable-line ember/closure-actions
          queryParams: {
            model_id: this.get('model.id'),
            parent_id: this.get('group.id'),
            // clear other query parameters
            search: null,
            sortBy: null,
            sortOrder: null
          }
        });
      },
      manageGroups: function manageGroups() {
        this.sendAction('dispatchAction', 'manage-groups', this.model); // eslint-disable-line ember/closure-actions
      },
      setDropdownPosition: function setDropdownPosition() {
        var container = (0, _jquery.default)('.content-container')[0];
        var scrollWidth = container.scrollWidth;
        var clientWidth = container.clientWidth;
        var isOverflowLeft = scrollWidth > clientWidth; // eslint-disable-line ember/closure-actions
        this.set('isDropdownOverflowLeft', isOverflowLeft);
      },
      resetDropdownPosition: function resetDropdownPosition() {
        this.set('isDropdownOverflowLeft', false);
      },
      showMoreContent: function showMoreContent() {
        var container = this.$('.internal-model-list-container');
        var destination = this.$('.internal-content-list');
        // Calculate distance to scroll to
        var distanceFromTop = this.calculateDistanceFromTop(destination);
        if (!this.showMoreContent) {
          // toggle in css overflow scroll
          this.send('deselect');
          this.set('showMoreContent', true);
          container.animate({
            scrollTop: distanceFromTop
          }, 300);
        }
      },
      showLessContent: function showLessContent() {
        // Remove event listeners made during showMoreContent
        var container = this.$('.internal-model-list-container');
        // Scroll to top
        container.animate({
          scrollTop: 0
        }, 300, 'swing');
        this.set('showMoreContent', false);
      }
    }
  });
  _exports.default = _default;
});