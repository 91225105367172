define("experience-studio/components/search-form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['search-form-group', 'form-group'],
    placeholder: 'Search',
    debounceTime: 300,
    value: '',
    keyCode: '',
    handleOnInput: function handleOnInput() {
      var keyword = (this.value || '').toLowerCase();
      this.sendAction('onInput', keyword, this.keyCode); // eslint-disable-line ember/closure-actions
      this.sendAction('onTyping', false); // eslint-disable-line ember/closure-actions
    },

    actions: {
      clearSearch: function clearSearch() {
        if (event.key === 'Enter') {
          this.send('cancel');
        }
      },
      cancel: function cancel() {
        this.set('value', '');
        this.handleOnInput();
        document.getElementById(this.elementId).getElementsByClassName('search-field')[0].focus();
      },
      onInput: function onInput(value, evt) {
        this.set('keyCode', evt.keyCode);
        this.sendAction('onTyping', true); // eslint-disable-line ember/closure-actions
        Ember.run.debounce(this, this.handleOnInput, this.debounceTime);
      }
    }
  });
  _exports.default = _default;
});