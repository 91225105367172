define("experience-studio/components/model-list-sort-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-list-sort-dropdown', 'dropdown'],
    classNameBindings: ['sortProperties.length::is-hidden'],
    current: null,
    enableSearch: false,
    sortedModel: null,
    model: null,
    generateSortObserver: Ember.observer('current.isAscending', 'model.[]', 'sortProperties.@each.isSorted', function () {
      var _this = this;
      if (!this.isRemote) {
        var sortValue = null;
        var isAscending = false;
        var sortProperty = this.current;
        var valueA = null;
        var valueB = null;
        var order = 0;
        var isDate = false;
        if (sortProperty) {
          isAscending = sortProperty.get('isAscending');
          sortValue = sortProperty.get('sortValue');
        } else {
          isAscending = true;
          sortValue = 'modifiedAt';
        }
        if (sortValue.indexOf('At') > -1) {
          isDate = true;
        }
        var model = this.model;
        if (!Ember.isEmpty(model)) {
          model = this.model.toArray().sort(function (a, b) {
            valueA = a.get(sortValue);
            valueB = b.get(sortValue);
            if (isDate && valueA && valueB) {
              valueA = window.moment(valueA);
            }
            if (Ember.isNone(valueA)) {
              order = 1;
            } else if (Ember.isNone(valueB)) {
              order = -1;
            } else if (_this.isAfter(valueA, valueB)) {
              order = -1;
            } else if (_this.isBefore(valueA, valueB)) {
              order = 1;
            } else if (_this.isEqual(valueA, valueB)) {
              order = 0;
            }
            if (isAscending && !Ember.isNone(valueA) && !Ember.isNone(valueB)) {
              order = order * -1;
            }
            // reverse order for priority index as highest number is the lowest priority
            if (sortValue === 'priorityIndex') {
              order = order * -1;
            }
            return order;
          });
        }
        this.sendAction('updateSortedModel', model); // eslint-disable-line ember/closure-actions
      }
    }),
    isAfter: function isAfter(valueA, valueB) {
      if (window.moment.isMoment(valueA)) {
        return valueA.isAfter(valueB);
      } else if (typeof valueA === 'string') {
        return valueA.toLowerCase() < valueB.toLowerCase();
      } else if (typeof valueA === 'number') {
        return valueA < valueB;
      } else if (typeof valueA === 'boolean') {
        return !valueA && valueB;
      } else {
        return false;
      }
    },
    isBefore: function isBefore(valueA, valueB) {
      if (window.moment.isMoment(valueA)) {
        return valueA.isBefore(valueB);
      } else if (typeof valueA === 'string') {
        return valueA.toLowerCase() > valueB.toLowerCase();
      } else if (typeof valueA === 'number') {
        return valueA > valueB;
      } else if (typeof valueA === 'boolean') {
        return valueA && !valueB;
      } else {
        return false;
      }
    },
    isEqual: function isEqual(valueA, valueB) {
      if (window.moment.isMoment(valueA)) {
        return valueA.isSame(valueB);
      } else if (typeof valueA === 'string') {
        return valueA.toLowerCase() === valueB.toLowerCase();
      } else if (typeof valueA === 'number') {
        return valueA === valueB;
      } else if (typeof valueA === 'boolean') {
        return valueA === valueB;
      } else {
        return false;
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('isRemote', Ember.isNone(this.model));
      this.send('sort');
    },
    actions: {
      sort: function sort(item) {
        var sortProperties = this.sortProperties;
        var sortBy = this.sortBy;
        var isRemote = this.isRemote;
        var isLoading = false;
        if (!item) {
          if (sortBy) {
            item = sortProperties.findBy('sortValue', sortBy);
          } else {
            item = sortProperties.findBy('isSorted', true);
          }
          isLoading = true;
        }
        var isAscending = item.get('isAscending');
        var current = this.current;
        sortProperties.setEach('isSorted', false);
        item.setProperties({
          isAscending: this.sortOrder ? this.sortOrder === 'asc' : false,
          isSorted: true
        });
        if (item === current) {
          item.set('isAscending', !isAscending);
        }
        this.setProperties({
          current: item,
          sortBy: item.get('sortValue'),
          sortOrder: item.get('isAscending') ? 'asc' : 'desc'
        });
        if (!isLoading && isRemote) {
          this.sendAction('aggregateModel', this.modelName, this.modelVar); // eslint-disable-line ember/closure-actions
        }
      },
      toggleOrder: function toggleOrder(item, order) {
        var isRemote = this.isRemote;
        item.set('isAscending', order === 'asc');
        this.set('sortOrder', order);
        if (isRemote) {
          this.sendAction('aggregateModel', this.modelName, this.modelVar); // eslint-disable-line ember/closure-actions
        }
      }
    }
  });
  _exports.default = _default;
});