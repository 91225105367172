define("experience-studio/templates/components/info-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MOctU9RF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"title\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"close\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n      \"],[1,[30,[36,9],[\"times\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"component-container\"],[12],[2,\"\\n\"],[6,[37,4],[[35,10,[\"isReloading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"reloading-message\"],[12],[2,\"\\n        Loading...\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,8],[[35,7]],[[\"model\",\"selectedEntities\",\"languages\"],[[35,6],[35,0],[35,5]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"no-content\"],[12],[1,[35,0,[\"length\"]]],[2,\" \"],[1,[34,1]],[2,\"s selected.\"],[13],[2,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn btn-link btn-clear\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"clear\"],null],[12],[2,\"\\n          Clear Selection\\n        \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"no-content\"],[12],[2,\"Select an item.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedEntities\",\"titleSham\",\"action\",\"multipleSelected\",\"if\",\"languages\",\"modelSham\",\"componentName\",\"component\",\"svg-jar\",\"model\"]}",
    "moduleName": "experience-studio/templates/components/info-sidebar.hbs"
  });
  _exports.default = _default;
});