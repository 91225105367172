define("experience-studio/mixins/infinite-scroll-search-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    generateModelProperties: function generateModelProperties(model, modelVar) {
      return Ember.Object.create({
        modelName: model.modelName,
        modelVar: modelVar,
        page: 1,
        isLoading: false,
        isTyping: false,
        canLoadModels: model.get('length') < model.get('meta.pagination.total'),
        recordsPerPage: 15,
        totalRecords: model.get('meta.pagination.total'),
        searchKeyword: ''
      });
    },
    actions: {
      handleSearch: function handleSearch(properties) {
        var controller = this.controller;
        if (properties) {
          properties.setProperties({
            page: 1,
            isLoading: true
          });
          this.store.query(properties.get('modelName'), {
            search: properties.get('searchKeyword'),
            page: properties.get('page'),
            recordsPerPage: properties.get('recordsPerPage')
          }).then(function (records) {
            controller.set(properties.get('modelVar'), records);
            properties.setProperties({
              totalRecords: records.meta.pagination.total,
              isLoading: false,
              canLoadModels: controller.get(properties.get('modelVar')).get('length') < properties.get('totalRecords')
            });
          });
        }
        // TODO: Handle catch error and set is laoding false and error attribute
      },
      loadModels: function loadModels(properties) {
        var controller = this.controller;
        if (controller.get(properties.get('modelVar')).get('length') < properties.get('totalRecords')) {
          properties.setProperties({
            page: properties.get('page') + 1,
            isLoading: true
          });
          this.store.query(properties.get('modelName'), {
            search: properties.get('searchKeyword'),
            page: properties.get('page'),
            recordsPerPage: properties.get('recordsPerPage')
          }).then(function (records) {
            var fetchedRecords = controller.get(properties.get('modelVar')).toArray().pushObjects(records.toArray());
            controller.set(properties.get('modelVar'), fetchedRecords);
            properties.setProperties({
              totalRecords: records.meta.pagination.total,
              isLoading: false,
              canLoadModels: controller.get(properties.get('modelVar')).get('length') < properties.get('totalRecords')
            });
          });
          // TODO: Handle catch error and set is laoding false and error attribute
        }
      }
    }
  });
  _exports.default = _default;
});