define("experience-studio/templates/components/av5-pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r9Q6Tr1A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pie-chart\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "experience-studio/templates/components/av5-pie-chart.hbs"
  });
  _exports.default = _default;
});