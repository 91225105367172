define("experience-studio/components/filter-pill-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['filter-pill'],
    tagName: '',
    value: Ember.computed('model.filters.[]', function () {
      return this.model.filters.reduce(function (prev, filters) {
        return prev.concat(filters.value);
      }, []).uniqBy('id');
    }),
    actions: {
      clearPill: function clearPill(pill) {
        if (this.didUpdateQueryParams && this.model.filters.length) {
          var me = (this.model.filters.findBy('id', 'userIdMe') || {}).value;
          var users = (this.model.filters.findBy('id', 'userIds') || {}).value;
          if (!Ember.isEmpty(users)) {
            users = users.rejectBy('id', pill.id);
            users = users.length ? users : null;
          }
          if (!Ember.isEmpty(me) && me.id === pill.id) {
            me = null;
          }
          this.didUpdateQueryParams({
            'userIds': users,
            'userIdMe': me
          });
        }
      }
    }
  });
  _exports.default = _default;
});