define("experience-studio/components/manage-prioritization-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['manage-prioritization-modal-dialog'],
    errorMessage: '',
    isSaving: false,
    model: null,
    utility: Ember.inject.service(),
    dropdownData: Ember.computed('modelSham.[]', function () {
      var data = [];
      var model = this.modelSham;
      if (!Ember.isEmpty(model)) {
        var length = model.length;
        for (var i = 1; i <= length; i++) {
          data.pushObject(Ember.Object.create({
            label: i,
            value: i
          }));
        }
      }
      return data;
    }),
    isLoading: Ember.computed('modelSham.{[],isFulfilled}', 'parentModels.isFulfilled', function () {
      var action = this.model.action;
      if (action) {
        if (action !== 'CONTENT_INSTANCE' && action !== 'GROUP_EXPERIENCE') {
          return !this.modelSham.isFulfilled;
        } else if (this.parentModels.isFulfilled !== undefined) {
          return !this.parentModels.isFulfilled;
        } else {
          return false;
        }
      }
      return true;
    }),
    parent: Ember.computed('parentModels.isFulfilled', function () {
      return this.model.selectedExperience || this.model.selectedGroup || this.get('parentModels.firstObject');
    }),
    parentModels: Ember.computed('model.action', function () {
      switch (this.model.action) {
        case 'CONTENT_INSTANCE':
          return this.model.experiences;
        case 'GROUP_EXPERIENCE':
          return this.model.groups;
        default:
          return null;
      }
    }),
    modelSham: Ember.computed('model.action', 'parent', function () {
      var action = this.model.action || '';
      var items = [];
      if (action.indexOf('CONTENT_INSTANCE') >= 0) {
        items = this.parent.computedContents;
      } else if (action.indexOf('GROUP_EXPERIENCE') >= 0) {
        items = this.parent.computedExperiences;
      } else if (action.indexOf('EXPERIENCE') >= 0) {
        items = this.model.experiences;
      } else if (action.indexOf('GROUP') >= 0) {
        items = this.model.groups;
      }
      return items;
    }),
    sortedModel: Ember.computed('modelSham.@each.{priorityIndex,selectedPriority}', 'parent', function () {
      var action = this.model.action || '';
      var items = [];
      var model = this.modelSham;
      var contents = [];
      if (this.parent) {
        contents = this.parent.contents || this.parent.experiences;
      }
      if (!Ember.isEmpty(model)) {
        model.forEach(function (item) {
          if (action.indexOf('CONTENT_INSTANCE') >= 0 || action.indexOf('GROUP_EXPERIENCE') >= 0) {
            if (Ember.isNone(item.selectedPriority)) {
              var id = item.id;
              var content = contents.filterBy('id', id).firstObject;
              if (content) {
                item.set('selectedPriority', Ember.Object.create({
                  label: content.index,
                  value: content.index,
                  initial: content.index
                }));
              }
            }
          } else {
            if (Ember.isNone(item.selectedPriority)) {
              item.set('selectedPriority', Ember.Object.create({
                label: item.priorityIndex,
                value: item.priorityIndex,
                initial: item.priorityIndex
              }));
            }
          }
        });
        items = model.toArray().sort(function (itemA, itemB) {
          var valueA = itemA.selectedPriority.value;
          var valueB = itemB.selectedPriority.value;
          if (Ember.isNone(valueA)) {
            return 1;
          } else if (Ember.isNone(valueB)) {
            return -1;
          } else if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return items;
    }),
    getRequestData: function getRequestData(item) {
      var model = this.model;
      var action = model.action || '';
      var data = {
        contentId: '00000000-0000-0000-0000-000000000000',
        experienceId: '00000000-0000-0000-0000-000000000000',
        groupId: '00000000-0000-0000-0000-000000000000',
        index: item.selectedPriority.value
      };
      if (!Ember.isNone(model.selectedExperience)) {
        data.experienceId = model.selectedExperience.id;
      }
      if (!Ember.isNone(model.selectedGroup)) {
        data.groupId = model.selectedGroup.id;
      }
      var key;
      if (action.indexOf('CONTENT_INSTANCE') >= 0) {
        key = 'contentId';
      } else if (action.indexOf('GROUP_EXPERIENCE') >= 0) {
        key = 'experienceId';
      } else if (action.indexOf('EXPERIENCE') >= 0) {
        key = 'experienceId';
      } else if (action.indexOf('GROUP') >= 0) {
        key = 'groupId';
      } else {
        key = 'entityId';
      }
      data[key] = item.id;
      return data;
    },
    actions: {
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      close: function close() {
        var model = this.sortedModel;
        if (!Ember.isEmpty(model)) {
          model.setEach('selectedPriority', null);
        }
        this._super.apply(this, arguments);
      },
      save: function save() {
        var data = [];
        var self = this;
        this.sortedModel.forEach(function (item) {
          item.set('priorityIndex', item.selectedPriority.value);
          if (item.selectedPriority.value !== item.selectedPriority.initial) {
            data.pushObject(self.getRequestData(item));
          }
        });
        if (Ember.isEmpty(data)) {
          this.send('close');
        } else {
          this.setProperties({
            errorMessage: null,
            isSaving: true
          });
          this.utility.makeAjaxRequest({
            data: data,
            endpoint: 'kernel/experiences/index',
            type: 'POST'
          }).then(function () {
            self.send('close');
            self.sendAction('refresh');
            self.sendAction('showNotification', {
              message: 'Successfully updated priorities',
              type: 'confirm'
            });
          }).catch(function (response) {
            self.setProperties({
              errorMessage: self.utility.parseError(response),
              isSaving: false
            });
          });
        }
      },
      selectPriority: function selectPriority(item, value) {
        this.send('updatePriority', value, item);
      },
      updatePriority: function updatePriority(selectedPriority, targetItem) {
        if (selectedPriority) {
          var destinationIndex = selectedPriority.value - 1;
          var i = 0;
          var items = this.sortedModel;
          var itemsLength = items.length;
          var originIndex = items.indexOf(targetItem);
          var untouchedIndex = 0;
          if (originIndex < destinationIndex) {
            untouchedIndex = destinationIndex + 1;
            for (i = originIndex + 1; i <= destinationIndex; i++) {
              items.objectAt(i).setProperties({
                selectedPriority: Ember.Object.create({
                  label: i,
                  value: i
                })
              });
            }
          } else if (originIndex > destinationIndex) {
            untouchedIndex = originIndex + 1;
            for (i = originIndex - 1; i >= destinationIndex; i--) {
              items.objectAt(i).setProperties({
                selectedPriority: Ember.Object.create({
                  label: i + 2,
                  value: i + 2
                })
              });
            }
          }
          targetItem.setProperties({
            selectedPriority: Ember.Object.create({
              label: destinationIndex + 1,
              value: destinationIndex + 1
            })
          });
          for (i = untouchedIndex; i < itemsLength; i++) {
            var priorityIndex = items.objectAt(i).selectedPriority.value;
            if (Ember.isNone(priorityIndex) || priorityIndex > itemsLength) {
              items.objectAt(i).setProperties({
                selectedPriority: Ember.Object.create({
                  label: i + 1,
                  value: i + 1
                })
              });
            }
          }
        }
      }
    }
  });
  _exports.default = _default;
});