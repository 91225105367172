define("experience-studio/components/file-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{file-browser
      files=files
      typeFilter="csv,zip"
    }}
  
  */
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['file-browser'],
    classNameBindings: ['selected.length:has-selected'],
    multiSelect: false,
    isFetchingMore: false,
    selected: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    files: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    limit: 10,
    offset: 0,
    total: -1,
    recordsPerPage: 10,
    page: 1,
    extensions: Ember.computed('typeFilter', function () {
      if (this.typeFilter) {
        return this.typeFilter.split(',').map(function (ext) {
          return ".".concat(ext);
        }).join(',');
      }
      return;
    }),
    didInsertElement: function didInsertElement() {
      var view = this;
      this.set('files', []);
      this.send('getNextPage', function () {
        view.send('checkPaging');
      });
      this.$('.files-wrap').on('scroll.fbrowse', function () {
        window.requestAnimationFrame(function () {
          view.send('checkPaging');
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.files-wrap').off('.fbrowse');
    },
    actions: {
      checkPaging: function checkPaging() {
        var contentHeight = this.$('.files-wrap .inner-content-wrap').outerHeight();
        var parent = this.$('.files-wrap');
        if (parent.scrollTop() + parent.outerHeight() >= contentHeight - 50) {
          this.send('getNextPage');
        }
      },
      getNextPage: function getNextPage(callback) {
        var _this = this;
        if (this.isFetchingMore) {
          return;
        }
        this.set('isFetchingMore', true);
        if (this.total === -1 || this.recordsPerPage * this.page < this.total) {
          var page = this.page;
          if (this.total !== -1) {
            this.set('page', ++page);
          }
          var queryObj = {
            page: page,
            recordsPerPage: this.recordsPerPage
          };
          if (this.extensions) {
            queryObj.extensions = this.extensions;
          }
          this.store.query('file', queryObj).then(function (files) {
            var meta = files.get('meta.pagination');
            _this.set('total', meta.total);
            _this.files.pushObjects(files.toArray());
          }).catch(function () {}).then(function () {
            _this.set('isFetchingMore', false);
            if (callback) {
              callback();
            }
          });
        } else {
          this.set('isFetchingMore', false);
        }
      },
      selectToggle: function selectToggle(file) {
        var existingFile = this.selected.find(function (item) {
          return item.get('id') === file.get('id');
        });
        if (!existingFile) {
          if (!this.multiSelect) {
            this.selected.clear();
          }
          this.selected.pushObject(file);
        } else {
          this.selected.removeObject(file);
        }
      },
      selectAll: function selectAll() {
        this.selected.clear();
        this.selected.pushObjects(this.files);
      },
      deselectAll: function deselectAll() {
        this.selected.clear();
      }
    }
  });
  _exports.default = _default;
});