define("experience-studio/mixins/pagination-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    page: 1,
    queryParams: ['recordsPerPage', 'page']
  });
  _exports.default = _default;
});