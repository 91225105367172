define("experience-studio/components/push-messages-new--subscription-select", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-messages-new--subscription-select'],
    hasSingleSelection: false,
    developerPortalUrl: _environment.default.APP.developerPortalUrl,
    noneSelected: Ember.computed('subscriptions.@each.isSelected', function () {
      return this.subscriptions.every(function (subscription) {
        return !subscription.isSelected;
      });
    })
  });
  _exports.default = _default;
});