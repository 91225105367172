define("experience-studio/components/content-instance-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-instance-selector'],
    selectedContentSham: Ember.computed('selectedContent', function () {
      return [this.selectedContent];
    }),
    actions: {
      selectContent: function selectContent(model) {
        this.onSelectContent(model);
        this.sendAction('selectContent', model); // eslint-disable-line ember/closure-actions
      },
      deselectContent: function deselectContent() {
        this.onSelectContent(null);
        this.sendAction('deselectContent'); // eslint-disable-line ember/closure-actions
      },
      showContentSelectModal: function showContentSelectModal() {
        this.set('isContentSelectModalOpen', true);
      },
      handleSearch: function handleSearch(keyword, modelName, propertiesVar) {
        this.sendAction('handleSearch', keyword, modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels(modelName, propertiesVar) {
        this.sendAction('loadModels', modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});