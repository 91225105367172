define("experience-studio/templates/components/push-messages-create--code-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+f1Zo314",
    "block": "{\"symbols\":[],\"statements\":[[10,\"pre\"],[12],[10,\"code\"],[12],[1,[34,0]],[13],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"payloadJSON\"]}",
    "moduleName": "experience-studio/templates/components/push-messages-create--code-preview.hbs"
  });
  _exports.default = _default;
});