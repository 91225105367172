define("experience-studio/routes/context-hub/context-data-upload", ["exports", "experience-studio/config/environment", "experience-studio/mixins/prevent-reload-route"], function (_exports, _environment, _preventReloadRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_preventReloadRoute.default, {
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      return store.query('context-data-upload', {
        page: params.page,
        recordsPerPage: params.recordsPerPage || 48
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        currentFile: null,
        isDoneRefreshing: false,
        isPreventReload: false,
        isProcessed: false,
        isProcessImmediately: false,
        isShowProcessAction: false,
        isStartProcessing: false,
        isStartUploading: false,
        isUploading: false,
        page: 1,
        pagination: Ember.Object.create(model.meta.pagination),
        paginationLoading: false,
        processedContextFiles: model,
        uploadError: null,
        uploadSuccess: false,
        primaryMessage: 'You are still uploading your context file. If you leave now the upload will be stopped.',
        secondaryMessage: 'Are you sure you want to continue.'
      }), this._super(controller, model); // jshint ignore:line
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        Ember.run.cancel(controller.timeOutId);
      }
    },
    startFileProcessing: function startFileProcessing(model) {
      var adapter = this.store.adapterFor('context-data-upload');
      var self = this;
      return adapter.processFile(this.store, model.constructor, model).then(function () {
        self.send('pollServer');
      });
    },
    actions: {
      deleteUploadedFile: function deleteUploadedFile(model) {
        var message = 'Are you sure you want to clear this entry?';
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'confirmRemoveProcessedItem',
          primaryMessage: message,
          data: model,
          secondaryMessage: 'You can\'t undo this action.',
          title: 'Confirm Action'
        }));
      },
      deselectModelListItems: function deselectModelListItems() {},
      handleAction: function handleAction(action, model) {
        switch (action) {
          case 'delete':
            this.send('deleteUploadedFile', model);
            break;
          case 'process':
            model.set('isProcessingInProgress', true);
            this.send('processFile', model);
        }
      },
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.searchKeyword);
      },
      removeUploadStatusSection: function removeUploadStatusSection() {
        var controller = this.controller;
        controller.setProperties({
          isStartUploading: false,
          isStartProcessing: false,
          isUploading: false,
          uploadSuccess: false,
          uploadError: null
        });
        controller.set('isPreventReload', controller.isUploading);
      },
      requestPageChange: function requestPageChange() {
        var _this = this;
        this.controller.set('paginationLoading', true);
        this.store.query('context-data-upload', {
          page: this.controller.get('page'),
          offset: 3,
          recordsPerPage: this.controller.get('model.meta.pagination.limit') || 48
        }).then(function (result) {
          _this.send('pollServer');
          _this.controller.set('paginationLoading', false);
          _this.controller.set('processedContextFiles', result);
        });
      },
      processFile: function processFile(model) {
        var self = this;
        var controller = this.controller;
        var filesInProcess = controller.processedContextFiles.filterBy('isProcessingInProgress', true);
        if (filesInProcess.length > 1) {
          self.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: 'You cannot process more than one file at a time.',
            title: 'Invalid Action'
          }));
        } else {
          self.startFileProcessing(model).then(function () {
            self.send('showNotification', Ember.Object.create({
              type: 'success',
              message: 'Processing started.'
            }));
          }).catch(function (response) {
            self.send('showNotification', Ember.Object.create({
              type: 'alert',
              message: self.get('utility').parseError(response)
            }));
          });
        }
      },
      pollServer: function pollServer() {
        var _this2 = this;
        var controller = this.controller;
        var self = this;
        var id;
        this.store.query('context-data-upload', {
          page: this.controller.get('page'),
          offset: 3,
          recordsPerPage: this.controller.get('model.meta.pagination.limit') || 48
        }).then(function (response) {
          controller.set('processedContextFiles', response);
          if (controller.isProcessImmediately) {
            var record = controller.processedContextFiles.filterBy('fileID', controller.get('uploadedFile.id')).get('firstObject');
            record.set('isProcessingInProgress', true);
            self.send('processFile', record);
            controller.set('isProcessImmediately', false);
          } else {
            if (!Ember.isEmpty(response.filterBy('status', 'PROCESSING'))) {
              id = Ember.run.later(_this2, function () {
                self.send('pollServer');
              }, 3000);
            } else {
              controller.processedContextFiles.setEach('isProcessingInProgress', false);
            }
          }
        });
        controller.set('timeOutId', id);
      },
      removeProcessedItem: function removeProcessedItem(model) {
        var message = 'Are you sure you want to delete the processed file?';
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'confirmRemoveProcessedItem',
          primaryMessage: message,
          data: model,
          secondaryMessage: 'You can\'t undo this action.',
          title: 'Confirm Action'
        }));
      },
      confirmRemoveProcessedItem: function confirmRemoveProcessedItem(data) {
        var model = data.data;
        var self = this;
        model.destroyRecord().then(function () {
          self.send('hideModal');
          self.send('showNotification', Ember.Object.create({
            type: 'success',
            message: 'File entry deleted successfully.'
          }));
          self.refresh();
        }).catch(function (response) {
          self.send('hideModal');
          self.send('showNotification', Ember.Object.create({
            type: 'alert',
            message: self.get('utility').parseError(response)
          }));
        });
      },
      uploadContextData: function uploadContextData(isProcessImmediately) {
        var _this3 = this;
        var controller = this.controller;
        var callback = "".concat(window.location.origin, "/noop.html");
        var jwt = this.get('session.token');
        var url = "".concat(_environment.default.APP.hostUrl, "/kernel/file-manager/files/upload?callback=").concat(callback);
        controller.set('isProcessImmediately', isProcessImmediately);
        controller.setProperties({
          isProcessed: false,
          isStartProcessing: false,
          isUploading: true,
          progressCount: 0,
          uploadError: null,
          uploadSuccess: false
        });
        var fileCheckDeferred = Ember.RSVP.defer();
        window.allUpload({
          acceptStr: '.csv',
          targetURL: url,
          fileParamName: 'data',
          formArgs: {
            'X-Authorization': jwt,
            'isPrivate': true,
            'tags': JSON.stringify(['context-data'])
          },
          isResponseInQueryString: true,
          onfilechange: function onfilechange(value) {
            if (value) {
              var ext = value.split('.').pop().toLowerCase();
              if (ext !== 'csv') {
                fileCheckDeferred.reject({
                  msg: 'Invalid file type.  Only .csv files are accepted.'
                });
              } else {
                var fileName = value.split('\\').pop();
                controller.setProperties({
                  isStartUploading: true,
                  isUploading: true,
                  fileName: fileName
                });
                controller.set('isPreventReload', controller.isUploading);
                fileCheckDeferred.resolve();
              }
            } else {
              fileCheckDeferred.reject({
                msg: 'Missing file'
              });
            }
          }
        }).then(function (response) {
          controller.setProperties({
            isStartUploading: false,
            isUploading: false,
            uploadSuccess: true,
            isShowProcessAction: true
          });
          var id;
          controller.set('page', 1);
          controller.set('isPreventReload', controller.isUploading);
          var file = JSON.parse(response.response)[0];
          if (!controller.isProcessImmediately) {
            id = Ember.run.later(_this3, function () {
              _this3.send('pollServer');
            }, 1000);
          } else {
            controller.set('uploadedFile', file);
            id = Ember.run.later(_this3, function () {
              _this3.send('pollServer');
            }, 1000);
          }
          controller.set('timeOutId', id);
        }).catch(function () {
          var message = '';
          message += 'An error occured while uploading the file. ';
          message += 'Please try again.';
          controller.set('uploadError', message);
          controller.set('isUploading', false);
          controller.set('isPreventReload', controller.isUploading);
          _this3.send('showNotification', Ember.Object.create({
            type: 'alert',
            message: _this3.get('utility').parseError(message)
          }));
        });
      }
    }
  });
  _exports.default = _default;
});