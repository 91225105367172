define("experience-studio/components/context-data-uploader", ["exports", "experience-studio/mixins/prevent-reload-component"], function (_exports, _preventReloadComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_preventReloadComponent.default, {
    classNames: ['context-data-uploader'],
    fileName: '',
    isProcessImmediately: false,
    isProcessed: false,
    isShowProcessAction: false,
    isStartProcessing: false,
    isStartUploading: false,
    isUploading: false,
    isPreventReload: false,
    paginationLoading: false,
    model: null,
    processingCompletedAt: Ember.computed(function () {
      return window.moment.duration(1, 's').humanize();
    }),
    isDoneUploading: Ember.observer('isStartUploading', function () {
      if (!this.isStartUploading) {
        window.onbeforeunload = null;
      } else {
        this.willReload();
      }
    }),
    progressWidth: Ember.computed('progressCount', function () {
      return this.progressCount;
    }),
    didInsertElement: function didInsertElement() {
      var processingFiles = this.model.filterBy('status', 'PROCESSING');
      if (!Ember.isEmpty(processingFiles)) {
        this.sendAction('pollServer'); // eslint-disable-line ember/closure-actions
      }

      this._super.apply(this, arguments);
    },
    actions: {
      deleteUploadedFile: function deleteUploadedFile(item) {
        this.sendAction('deleteUploadedFile', item); // eslint-disable-line ember/closure-actions
      },
      dispatchAction: function dispatchAction(name, model, params) {
        this.sendAction('dispatchAction', name, model, params); // eslint-disable-line ember/closure-actions
      },
      processFile: function processFile(item) {
        item.set('isProcessingInProgress', true);
        this.sendAction('processFile', item); // eslint-disable-line ember/closure-actions
      },
      remove: function remove() {
        this.sendAction('removeUploadStatusSection'); // eslint-disable-line ember/closure-actions
      },
      uploadContextData: function uploadContextData() {
        this.sendAction('uploadContextData', this.isProcessImmediately); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});