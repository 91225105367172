define("experience-studio/components/push-messages-summary-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-messages-summary-preview'],
    isDevice: true,
    isCode: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var model = this.model;
      model.set('isDevicePreview', true);
    },
    payloadJSON: Ember.computed('model.{customFields,message.en,title.en}', function () {
      var data = '';
      var payload = Ember.Object.create();
      payload.set('action', 'custom');
      payload.set('title', this.get('model.title').en);
      payload.set('alert', this.get('model.message').en);
      payload.set('body', this.get('model.customFields'));
      try {
        data = JSON.stringify(payload, null, 2);
      } catch (exception) {
        /* TODO: Handle exception */
      }
      return data;
    }),
    actions: {
      togglePreview: function togglePreview(previewType) {
        if (previewType === 'device') {
          this.set('isDevice', true);
          this.set('isCode', false);
        } else {
          this.set('isDevice', false);
          this.set('isCode', true);
        }
        this.sendAction('togglePreview', previewType); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});