define("experience-studio/controllers/groups-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    data: '',
    queryParams: [{
      data: 'data'
    }],
    filteredModel: Ember.computed('isRefreshFilterModel', 'data', function () {
      return this.store.query('group', {
        data: this.data
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    }),
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Associate',
      icon: 'th-large',
      subActions: [Ember.Object.create({
        label: 'Associate to Experience',
        action: 'showAssociateRecordsModal',
        subActions: []
      }), Ember.Object.create({
        label: 'Disassociate from Experience',
        action: 'showDisassociateRecordsModal',
        subActions: []
      })]
    }), Ember.Object.create({
      label: 'Duplicate',
      icon: 'clone',
      action: 'showDuplicateRecordsModal',
      subActions: []
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: [],
      hasDivider: true
    })]),
    filterState: Object.freeze([Ember.Object.create({
      title: 'Date',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Created',
        id: 'created',
        isDisabled: false,
        options: [Ember.Object.create({
          value: undefined,
          property: 'createdAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'createdAtTo'
        })]
      })]
    })]),
    filterParams: Object.freeze([Ember.Object.create({
      id: 'createdAtTo',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'createdAtFrom',
      component: 'filter-pill-created-date',
      value: null
    })])
  });
  _exports.default = _default;
});