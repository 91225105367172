define("experience-studio/adapters/plugin", ["exports", "experience-studio/config/environment", "experience-studio/adapters/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'context/ctxschema/context',
    findAll: function findAll() {
      return this._super.apply(this, arguments).then(function (plugins) {
        if (plugins && plugins.length) {
          var locationPlugin = plugins.findBy('uid', 'ctx.sdk.location');
          var nearByAreaPlugin = plugins.findBy('uid', 'ctx.flybits.nearByArea');
          if (locationPlugin) {
            plugins.splice(plugins.indexOf(locationPlugin), 1);
          }
          plugins = plugins.sortBy('category');
          if (_environment.default.environment.includes('production') && nearByAreaPlugin) {
            plugins.splice(plugins.indexOf(nearByAreaPlugin), 1);
          }
        }
        return plugins;
      });
    },
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('context/plugins', 'contextplugins');
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url.replace('context/plugins', "contextplugins?limit=".concat(this.paginationLimit));
    }
  });
  _exports.default = _default;
});