define("experience-studio/templates/communications-hub/triggered-emails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Xf3w+3n9",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"didClick\",\"keyword\",\"model\",\"dispatchAction\"],[\"selectModelListItems\",[35,2],[35,1,[\"triggeredEmails\"]],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"handleAction\",\"model\",\"searchKeyword\",\"communications-hub-email-table\"]}",
    "moduleName": "experience-studio/templates/communications-hub/triggered-emails.hbs"
  });
  _exports.default = _default;
});