define("experience-studio/components/confirm-duplicate-records-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['batch-actions-modal'],
    selectedRecords: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        selectedRecords: [],
        headers: [Ember.Object.create({
          name: 'Name',
          class: 'name-col'
        })]
      });
    },
    confirmModalTitle: Ember.computed('model.pluralEntityName', function () {
      return "Duplicate ".concat(this.model.pluralEntityName);
    }),
    searchPlaceholder: Ember.computed('model.pluralEntityName', function () {
      return "Search ".concat(this.model.pluralEntityName);
    }),
    isActionDisabled: Ember.computed('selectedRecords.[]', function () {
      return this.selectedRecords.length === 0;
    }),
    actions: {
      duplicate: function duplicate() {
        var records = this.selectedRecords;
        this.performAction({
          action: 'duplicateRecords',
          singularEntityName: this.model.singularEntityName,
          pluralEntityName: this.model.pluralEntityName,
          confirmModalTitle: this.confirmModalTitle,
          records: records
        });
        this.send('close');
      },
      onSelectRecords: function onSelectRecords(records) {
        var _this = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this.set('selectedRecords', records);
        });
      }
    }
  });
  _exports.default = _default;
});