define("experience-studio/components/route-model-filter-dropdown-date-range-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown-date-range-select'],
    fromDate: null,
    toDate: null,
    isEnabled: true,
    isDisabled: Ember.computed.not('isEnabled'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.updateDatesFromQueryParameters();
    },
    actions: {
      onFromDateChange: function onFromDateChange(date, dateString) {
        var fromProperty = this.section.options[0].property;
        var queryObject = {};
        queryObject[fromProperty] = dateString;
        if (this.didUpdateQueryParams) {
          this.didUpdateQueryParams(queryObject);
        }
      },
      onToDateChange: function onToDateChange(date, dateString) {
        var toProperty = this.section.options[1].property;
        var queryObject = {};
        queryObject[toProperty] = dateString;
        if (this.didUpdateQueryParams) {
          this.didUpdateQueryParams(queryObject);
        }
      },
      toggleEnabled: function toggleEnabled() {
        this.toggleProperty('isEnabled');
        var fromProperty = this.section.options[0].property;
        var toProperty = this.section.options[1].property;
        if (!this.isEnabled && (this.fromDate || this.toDate)) {
          this.setProperties({
            fromDate: null,
            toDate: null
          });
          var queryObject = {};
          queryObject[fromProperty] = null;
          queryObject[toProperty] = null;
          if (this.didUpdateQueryParams) {
            this.didUpdateQueryParams(queryObject);
          }
        } else if (this.isEnabled) {
          var fromDate = window.moment(new Date()).hour(0).minute(0).toDate();
          var toDate = window.moment(new Date()).hour(23).minute(59).toDate();
          this.setProperties({
            fromDate: fromDate,
            toDate: toDate
          });
          var _queryObject = {};
          _queryObject[fromProperty] = window.moment(fromDate).unix();
          _queryObject[toProperty] = window.moment(toDate).unix();
          if (this.didUpdateQueryParams) {
            this.didUpdateQueryParams(_queryObject);
          }
        }
      }
    },
    updateDatesFromQueryParameters: function updateDatesFromQueryParameters() {
      if (this.queryParameters && this.section) {
        var fromDate = this.queryParameters[this.section.options[0].property];
        var toDate = this.queryParameters[this.section.options[1].property];
        this.set('fromDate', window.moment.unix(fromDate).toDate());
        this.set('toDate', window.moment.unix(toDate).toDate());
        this.set('isEnabled', !Ember.isEmpty(fromDate) || !Ember.isEmpty(toDate));
      }
    }
  });
  _exports.default = _default;
});