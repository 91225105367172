define("experience-studio/components/localized-input", ["exports", "experience-studio/mixins/localized-input-text-component", "jquery", "experience-studio/config/environment", "experience-studio/helpers/blacklist-sites"], function (_exports, _localizedInputTextComponent, _jquery, _environment, _blacklistSites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_localizedInputTextComponent.default, {
    classNames: ['localized-input'],
    isRequired: false,
    isTextarea: false,
    label: '',
    placeholder: '',
    userPreferences: Ember.inject.service(),
    formClass: 'form-control',
    didSelectLanguage: Ember.observer('languages.@each.isSelected', function () {
      var language = this.languages.findBy('isSelected', true);
      this.set('formClass', language.direction === 'rtl' ? 'form-control rtl' : 'form-control');
      this.set('isRtl', language.direction === 'rtl');
    }),
    isRtl: Ember.computed('languages.@each.isSelected', function () {
      var language = this.languages.findBy('isSelected', true);
      return (language === null || language === void 0 ? void 0 : language.direction) === 'rtl';
    }),
    actionValueDisplayName: Ember.computed('linkValue', function () {
      switch (this.linkValue) {
        case 'mailto:UNIVERSAL_ACTION':
          return 'Email';
        case 'concierge:UNIVERSAL_ACTION':
          return 'Concierge';
        case 'details:UNIVERSAL_ACTION':
          return 'Details';
        case 'web:UNIVERSAL_ACTION':
          return 'Web Link (Opens in-app browser)';
        case 'browser:UNIVERSAL_ACTION':
          return 'Web Link (Opens external browser)';
        case 'app:UNIVERSAL_ACTION':
          return 'Application Deep Link';
        case 'tel:UNIVERSAL_ACTION':
          return 'Phone Number';
        case 'universalLink:UNIVERSAL_ACTION':
          return 'External Application';
        default:
          return 'Web Link';
      }
    }),
    iosValue: Ember.computed('languages.@each.isSelected', 'ios', function () {
      var language = this.languages.findBy('isSelected', true);
      return language ? this.field.ios[language.id] : '';
    }),
    androidValue: Ember.computed('languages.@each.isSelected', 'android', function () {
      var language = this.languages.findBy('isSelected', true);
      return language ? this.field.android[language.id] : '';
    }),
    isRichTextEnabled: Ember.computed('userPreferences.preferences.[]', 'isRichText', function () {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      var isRTFEnabled = isNonTdOrUsEnv || this.userPreferences.getValue('richTextFormatting');
      return isRTFEnabled && this.isRichText && (0, _blacklistSites.blacklistSites)();
    }),
    isDynamicAttributeFeatureEnabled: Ember.computed('userPreferences.preferences.[]', 'field', function () {
      return this.field && this.field.isDynamicAttributeEnabled;
    }),
    isTextareaRichTextEnabled: Ember.computed('isRichTextEnabled', 'isTextarea', function () {
      return this.isRichTextEnabled && this.isTextarea;
    }),
    isTextAreaWithFeature: Ember.computed('isRichTextEnabled', 'isDynamicAttributeFeatureEnabled', function () {
      return this.isRichTextEnabled || this.isDynamicAttributeFeatureEnabled;
    }),
    isEmailAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'mailto:UNIVERSAL_ACTION';
    }),
    isConciergeAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'concierge:UNIVERSAL_ACTION';
    }),
    isTelAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'tel:UNIVERSAL_ACTION';
    }),
    isUniversalLinkAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'universalLink:UNIVERSAL_ACTION';
    }),
    isAppAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'app:UNIVERSAL_ACTION';
    }),
    isWebAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'web:UNIVERSAL_ACTION';
    }),
    isDetailsAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'details:UNIVERSAL_ACTION';
    }),
    isBrowserAction: Ember.computed('linkValue', function () {
      return this.linkValue === 'browser:UNIVERSAL_ACTION';
    }),
    observeIsSkipUrlValidation: Ember.observer('isSkipUrlValidation', function () {
      this.field.isSkipUrlValidation = this.isSkipUrlValidation;
    }),
    actions: {
      keyAction: function keyAction(inputVal, evt) {
        this.sendAction('keyDown', evt); // eslint-disable-line ember/closure-actions
      },
      focusIn: function focusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      },
      keyUp: function keyUp(inputVal, evt) {
        this.sendAction('keyUp', evt); // eslint-disable-line ember/closure-actions
      },
      trimValue: function trimValue(inputVal, evt) {
        this.set('value', inputVal.trim());
        this.sendAction('keyUp', evt); // eslint-disable-line ember/closure-actions
      },
      trimIosValue: function trimIosValue(inputVal, evt) {
        var language = this.languages.findBy('isSelected', true);
        var iosVal = this.field.ios;
        iosVal[language.id] = inputVal.trim();
        this.set('field.ios', iosVal);
        this.set('value', 'ios='.concat(encodeURIComponent(inputVal.trim()), '&android=', encodeURIComponent(this.androidValue)));
        this.sendAction('keyUp', evt); // eslint-disable-line ember/closure-actions
      },
      trimAndroidValue: function trimAndroidValue(inputVal, evt) {
        var language = this.languages.findBy('isSelected', true);
        var androidVal = this.field.android;
        androidVal[language.id] = inputVal.trim();
        this.set('field.android', androidVal);
        this.set('value', 'ios='.concat(encodeURIComponent(this.iosValue), '&android=', encodeURIComponent(inputVal.trim())));
        this.sendAction('keyUp', evt); // eslint-disable-line ember/closure-actions
      },

      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)('.label-tooltip').tooltip({
        trigger: 'hover'
      });
      var language = this.languages.findBy('isDefault', true);
      this.isSkipUrlValidation = false;
      if (language) {
        this.send('selectLanguage', language);
      }
    }
  });
  _exports.default = _default;
});