define("experience-studio/mixins/permissions", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    storage: Ember.inject.service(),
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    EntityRepositoryModel: Ember.Object.extend({
      entities: [],
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      role: null,
      init: function init() {
        this._super.apply(this, arguments);
        this.setEntities(this.role.get('entities'));
      },
      setEntities: function setEntities(presets) {
        var _this = this;
        presets.forEach(function (preset) {
          var entityToSet = _this.entities.find(function (entity) {
            return entity.get('type').toLowerCase() === preset.type.toLowerCase();
          });
          if (entityToSet) {
            var actionToSet = entityToSet.get('actions').find(function (action) {
              return action.get('action') === preset.action.toUpperCase();
            });
            if (actionToSet) {
              actionToSet.set('isSelected', true);
            }
          }
        });
      },
      setInstances: function setInstances() {
        var _iterator = _createForOfIteratorHelper(this.entities),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var entity = _step.value;
            if (entity.get('isSomeSelected')) {
              var instanceIds = [];
              var _iterator2 = _createForOfIteratorHelper(this.role.get('entities')),
                _step2;
              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var roleEntity = _step2.value;
                  if (roleEntity.type.toLowerCase() === entity.get('type').toLowerCase() && instanceIds.indexOf(roleEntity.id) < 0) {
                    instanceIds.push(roleEntity.id);
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
              entity.selectInstances(instanceIds);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    }),
    EntityModel: Ember.Object.extend({
      actions: [],
      instances: [],
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      modelName: '',
      name: '',
      virtualName: '',
      instancesList: Ember.computed('instances.@each.isSelected', function () {
        var instances = this.instances;
        var virtualInstances = instances.filterBy('isVirtual', true);
        if (virtualInstances.some(function (virtualInstance) {
          return virtualInstance.isSelected;
        })) {
          return virtualInstances;
        } else {
          return instances;
        }
      }),
      instancesSelected: Ember.computed('numInstancesSelected', function () {
        return this.instancesList.filterBy('isSelected', true);
      }),
      instancesSelectedIndicator: Ember.computed('isSomeInstancesSelected', function () {
        if (this.isSomeInstancesSelected) {
          return "".concat(this.numInstancesSelected, " selected");
        } else {
          return 'None selected';
        }
      }),
      isAllSelected: Ember.computed('actions.@each.isSelected', function () {
        return this.numSelected === this.actions.length;
      }),
      isSelected: Ember.computed('actions.@each.isSelected', function () {
        return this.actions.every(function (action) {
          return action.get('isSelected');
        });
      }),
      isSomeInstancesSelected: Ember.computed('numInstancesSelected', function () {
        return this.numInstancesSelected > 0;
      }),
      isSomeSelected: Ember.computed('actions.@each.isSelected', function () {
        return this.numSelected > 0;
      }),
      numInstancesSelected: Ember.computed('instancesList', function () {
        return this.instancesList.filterBy('isSelected').length;
      }),
      numSelected: Ember.computed('actions.@each.isSelected', function () {
        return this.actions.filterBy('isSelected').length;
      }),
      selectInstances: function selectInstances(roleInstances) {
        var _iterator3 = _createForOfIteratorHelper(this.instances),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var instance = _step3.value;
            if (roleInstances.indexOf(instance.get('id')) > -1) {
              instance.set('isSelected', true);
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      },
      clearSelections: function clearSelections() {
        this.actions.forEach(function (action) {
          return action.set('isSelected', false);
        });
      }
    }),
    ActionModel: Ember.Object.extend({
      action: '',
      isSelected: false,
      name: ''
    }),
    permissionPreset: Ember.Object.extend({
      entities: [],
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      isSelected: false,
      name: ''
    }),
    getUserLevel: function getUserLevel() {
      var _this2 = this;
      var levelDeferred = Ember.RSVP.defer();
      this.session.get('user').then(function (user) {
        var token = _this2.storage.getItem('token');
        if (user && token) {
          _this2.getUserInfoRequest(user.get('id')).then(function (userInfo) {
            levelDeferred.resolve(userInfo.level);
          });
        } else {
          levelDeferred.resolve(0);
        }
      }).catch(function () {
        levelDeferred.resolve(0);
      });
      return levelDeferred.promise;
    },
    getUserInfoRequest: function getUserInfoRequest(userId) {
      var applicationAdapter = this.store.adapterFor('application');
      var headers = applicationAdapter.get('headers');
      var url = "".concat(applicationAdapter.urlForFindAll(), "/sso/permissions/userinfo/").concat(userId);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          dataType: 'json',
          headers: headers,
          type: 'GET',
          url: url
        }).done(function (response) {
          return resolve(response);
        }).fail(function (jqXHR) {
          return reject(jqXHR);
        });
      });
    },
    updateUserInfoRequest: function updateUserInfoRequest(user) {
      var applicationAdapter = this.store.adapterFor('application');
      var headers = applicationAdapter.get('headers');
      var url = "".concat(applicationAdapter.urlForFindAll(), "/sso/permissions/userinfo");
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          contentType: 'application/json',
          data: JSON.stringify(user),
          dataType: 'json',
          headers: headers,
          type: 'PUT',
          url: url
        }).done(function (response) {
          return resolve(response);
        }).fail(function (jqXHR) {
          return reject(jqXHR);
        });
      });
    },
    getUsersAccessLevelTitle: function getUsersAccessLevelTitle(level) {
      var levelDeferred = Ember.RSVP.defer();
      var store = this.store;
      store.findAll('access-level').then(function (accessLevels) {
        accessLevels.sortBy('level');
        var _iterator4 = _createForOfIteratorHelper(accessLevels.toArray()),
          _step4;
        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var accessLevel = _step4.value;
            if (level <= accessLevel.get('level')) {
              levelDeferred.resolve(accessLevel.get('name'));
            }
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
        return levelDeferred.resolve('Unknown');
      }).catch(function () {
        levelDeferred.reject();
      });
      return levelDeferred.promise;
    },
    getUsersAccessLevelTitleWithLevels: function getUsersAccessLevelTitleWithLevels(accessLevelInt, accessLevelInstances) {
      /* TODO refactor methods requiring above function to use function bellow */

      accessLevelInstances.sortBy('level');
      var _iterator5 = _createForOfIteratorHelper(accessLevelInstances.toArray()),
        _step5;
      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var accessLevelInstance = _step5.value;
          if (accessLevelInt <= accessLevelInstance.get('level')) {
            return accessLevelInstance.get('name');
          }
        }
      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }
      return 'Unknown';
    },
    permissionPresets: Ember.computed(function () {
      return [this.permissionPreset.create({
        name: 'Guest Access Level',
        type: 'preset',
        entities: [{
          'type': 'contentInstance',
          'action': 'GET'
        }, {
          'type': 'contentTemplate',
          'action': 'GET'
        }, {
          'type': 'dataImport',
          'action': 'GET'
        }, {
          'type': 'experience',
          'action': 'GET'
        }, {
          'type': 'group',
          'action': 'GET'
        }, {
          'type': 'push',
          'action': 'GET'
        }, {
          'type': 'rule',
          'action': 'GET'
        }]
      }), this.permissionPreset.create({
        name: 'Creator Access Level',
        type: 'preset',
        entities: [{
          'type': 'contentInstance',
          'action': 'CREATE'
        }, {
          'type': 'contentInstance',
          'action': 'GET'
        }, {
          'type': 'contentInstance',
          'action': 'UPDATE'
        }, {
          'type': 'contentTemplate',
          'action': 'GET'
        }, {
          'type': 'dataImport',
          'action': 'GET'
        }, {
          'type': 'experience',
          'action': 'CREATE'
        }, {
          'type': 'experience',
          'action': 'GET'
        }, {
          'type': 'experience',
          'action': 'UPDATE'
        }, {
          'type': 'group',
          'action': 'GET'
        }, {
          'type': 'push',
          'action': 'CREATE'
        }, {
          'type': 'push',
          'action': 'GET'
        }, {
          'type': 'push',
          'action': 'UPDATE'
        }, {
          'type': 'rule',
          'action': 'CREATE'
        }, {
          'type': 'rule',
          'action': 'GET'
        }, {
          'type': 'rule',
          'action': 'UPDATE'
        }]
      }), this.permissionPreset.create({
        name: 'Admin Access Level',
        type: 'preset',
        entities: [{
          'type': 'contentInstance',
          'action': 'CREATE'
        }, {
          'type': 'contentInstance',
          'action': 'DELETE'
        }, {
          'type': 'contentInstance',
          'action': 'GET'
        }, {
          'type': 'contentInstance',
          'action': 'UPDATE'
        }, {
          'type': 'contentTemplate',
          'action': 'GET'
        }, {
          'type': 'dataImport',
          'action': 'GET'
        }, {
          'type': 'experience',
          'action': 'ACTIVATE'
        }, {
          'type': 'experience',
          'action': 'CREATE'
        }, {
          'type': 'experience',
          'action': 'DELETE'
        }, {
          'type': 'experience',
          'action': 'GET'
        }, {
          'type': 'experience',
          'action': 'UPDATE'
        }, {
          'type': 'group',
          'action': 'CREATE'
        }, {
          'type': 'group',
          'action': 'DELETE'
        }, {
          'type': 'group',
          'action': 'GET'
        }, {
          'type': 'group',
          'action': 'UPDATE'
        }, {
          'type': 'push',
          'action': 'ACTIVATE'
        }, {
          'type': 'push',
          'action': 'CREATE'
        }, {
          'type': 'push',
          'action': 'DELETE'
        }, {
          'type': 'push',
          'action': 'GET'
        }, {
          'type': 'push',
          'action': 'UPDATE'
        }, {
          'type': 'rule',
          'action': 'CREATE'
        }, {
          'type': 'rule',
          'action': 'DELETE'
        }, {
          'type': 'rule',
          'action': 'GET'
        }, {
          'type': 'rule',
          'action': 'UPDATE'
        }]
      }), this.permissionPreset.create({
        name: 'Owner Access Level',
        type: 'preset',
        entities: [{
          'type': 'contentInstance',
          'action': 'CREATE'
        }, {
          'type': 'contentInstance',
          'action': 'DELETE'
        }, {
          'type': 'contentInstance',
          'action': 'GET'
        }, {
          'type': 'contentInstance',
          'action': 'UPDATE'
        }, {
          'type': 'contentTemplate',
          'action': 'ACTIVATE'
        }, {
          'type': 'contentTemplate',
          'action': 'CREATE'
        }, {
          'type': 'contentTemplate',
          'action': 'DELETE'
        }, {
          'type': 'contentTemplate',
          'action': 'GET'
        }, {
          'type': 'contentTemplate',
          'action': 'UPDATE'
        }, {
          'type': 'dataImport',
          'action': 'CREATE'
        }, {
          'type': 'dataImport',
          'action': 'DELETE'
        }, {
          'type': 'dataImport',
          'action': 'GET'
        }, {
          'type': 'dataImport',
          'action': 'UPDATE'
        }, {
          'type': 'experience',
          'action': 'ACTIVATE'
        }, {
          'type': 'experience',
          'action': 'CREATE'
        }, {
          'type': 'experience',
          'action': 'DELETE'
        }, {
          'type': 'experience',
          'action': 'GET'
        }, {
          'type': 'experience',
          'action': 'UPDATE'
        }, {
          'type': 'group',
          'action': 'CREATE'
        }, {
          'type': 'group',
          'action': 'DELETE'
        }, {
          'type': 'group',
          'action': 'GET'
        }, {
          'type': 'group',
          'action': 'UPDATE'
        }, {
          'type': 'push',
          'action': 'ACTIVATE'
        }, {
          'type': 'push',
          'action': 'CREATE'
        }, {
          'type': 'push',
          'action': 'DELETE'
        }, {
          'type': 'push',
          'action': 'GET'
        }, {
          'type': 'push',
          'action': 'UPDATE'
        }, {
          'type': 'rule',
          'action': 'CREATE'
        }, {
          'type': 'rule',
          'action': 'DELETE'
        }, {
          'type': 'rule',
          'action': 'GET'
        }, {
          'type': 'rule',
          'action': 'UPDATE'
        }]
      })];
    }),
    presetEntities: function presetEntities(entities, presets) {
      presets.forEach(function (preset) {
        var entityToSet = entities.find(function (entity) {
          return entity.get('type') === preset.type;
        });
        if (entityToSet) {
          var actionToSet = entityToSet.get('actions').find(function (action) {
            return action.get('action') === preset.action;
          });
          if (actionToSet) {
            actionToSet.set('isSelected', true);
          }
        }
      });
    },
    unsetEntities: function unsetEntities(entities) {
      entities.forEach(function (entity) {
        entity.get('actions').forEach(function (action) {
          return action.set('isSelected', false);
        });
      });
    },
    generateEntities: function generateEntities() {
      return [this.EntityModel.create({
        modelName: 'experience',
        name: 'Experiences',
        type: 'experience',
        virtualName: 'experience',
        actions: [this.ActionModel.create({
          action: 'GET',
          name: 'Get Experiences',
          type: 'action'
        }), this.ActionModel.create({
          action: 'CREATE',
          name: 'Create Experiences',
          type: 'action'
        }), this.ActionModel.create({
          action: 'UPDATE',
          name: 'Edit Experiences',
          type: 'action'
        }), this.ActionModel.create({
          action: 'DELETE',
          name: 'Delete Experiences',
          type: 'action'
        }), this.ActionModel.create({
          action: 'ACTIVATE',
          name: 'Activate/Deactivate Experiences',
          type: 'action'
        })]
      }), this.EntityModel.create({
        modelName: 'content-instance',
        name: 'Content Instance',
        type: 'contentInstance',
        virtualName: 'contentInstance',
        actions: [this.ActionModel.create({
          action: 'GET',
          name: 'Get Content Instance',
          type: 'action'
        }), this.ActionModel.create({
          action: 'CREATE',
          name: 'Create Content Instance',
          type: 'action'
        }), this.ActionModel.create({
          action: 'UPDATE',
          name: 'Edit Content Instance',
          type: 'action'
        }), this.ActionModel.create({
          action: 'DELETE',
          name: 'Delete Content Instance',
          type: 'action'
        })]
      }), this.EntityModel.create({
        modelName: 'rule',
        name: 'Context Rules',
        type: 'rule',
        virtualName: 'rule',
        actions: [this.ActionModel.create({
          action: 'GET',
          name: 'Get Context Rules',
          type: 'action'
        }), this.ActionModel.create({
          action: 'CREATE',
          name: 'Create Context Rule',
          type: 'action'
        }), this.ActionModel.create({
          action: 'UPDATE',
          name: 'Edit Context Rule',
          type: 'action'
        }), this.ActionModel.create({
          action: 'DELETE',
          name: 'Delete Context Rule',
          type: 'action'
        })]
      }), this.EntityModel.create({
        modelName: 'push-message',
        name: 'Push Notifications',
        type: 'push',
        virtualName: 'push',
        actions: [this.ActionModel.create({
          action: 'GET',
          name: 'Get Push Notifications',
          type: 'action'
        }), this.ActionModel.create({
          action: 'CREATE',
          name: 'Create Push Notifications',
          type: 'action'
        }), this.ActionModel.create({
          action: 'UPDATE',
          name: 'Edit Push Notifications',
          type: 'action'
        }), this.ActionModel.create({
          action: 'DELETE',
          name: 'Delete Push Notifications',
          type: 'action'
        }), this.ActionModel.create({
          action: 'ACTIVATE',
          name: 'Activate/Deactivate Push Notifications',
          type: 'action'
        })]
      }), this.EntityModel.create({
        modelName: 'group',
        name: 'Groups',
        type: 'group',
        virtualName: 'group',
        actions: [this.ActionModel.create({
          action: 'GET',
          name: 'Get Group',
          type: 'action'
        }), this.ActionModel.create({
          action: 'CREATE',
          name: 'Create Group',
          type: 'action'
        }), this.ActionModel.create({
          action: 'UPDATE',
          name: 'Edit Group',
          type: 'action'
        }), this.ActionModel.create({
          action: 'DELETE',
          name: 'Delete Group',
          type: 'action'
        })]
      }), this.EntityModel.create({
        modelName: 'content-template',
        name: 'Content Template',
        type: 'contentTemplate',
        virtualName: 'contentTemplate',
        actions: [this.ActionModel.create({
          action: 'GET',
          name: 'Get Content Template',
          type: 'action'
        }), this.ActionModel.create({
          action: 'CREATE',
          name: 'Create Content Template',
          type: 'action'
        }), this.ActionModel.create({
          action: 'UPDATE',
          name: 'Edit Content Template',
          type: 'action'
        }), this.ActionModel.create({
          action: 'DELETE',
          name: 'Delete Content Template',
          type: 'action'
        }), this.ActionModel.create({
          action: 'ACTIVATE',
          name: 'Activate/Deactivate Content Template',
          type: 'action'
        })]
      }), this.EntityModel.create({
        name: 'Data Importing',
        type: 'dataImport',
        virtualName: 'dataImport',
        actions: [this.ActionModel.create({
          action: 'GET',
          name: 'Get Data Import',
          type: 'action'
        }), this.ActionModel.create({
          action: 'CREATE',
          name: 'Create Data Import',
          type: 'action'
        }), this.ActionModel.create({
          action: 'UPDATE',
          name: 'Edit Data Import',
          type: 'action'
        }), this.ActionModel.create({
          action: 'DELETE',
          name: 'Delete Data Import',
          type: 'action'
        })]
      })];
    }
  });
  _exports.default = _default;
});