define("experience-studio/components/av5-info-chart-container", ["exports", "experience-studio/components/av5-info-card-base", "experience-studio/mixins/analytics-data", "jquery"], function (_exports, _av5InfoCardBase, _analyticsData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Serves as a container for a single analytics chart
  
    Usages:
  
      <Av5ChartContainer
        title=title
        subtitle=subtitle
        requests=requests
      />
  */
  var _default = _av5InfoCardBase.default.extend(_analyticsData.default, {
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-info-chart-container'],
    utility: Ember.inject.service(),
    areaStyle: null,
    charts: null,
    currentEndPoint: '',
    currentOption: null,
    dateRange: null,
    defaultDuration: null,
    displayFromDate: null,
    displayToDate: null,
    isDropdown: false,
    isLoading: false,
    isOpted: false,
    isSelected: false,
    model: null,
    requests: null,
    selectedDateRange: null,
    selectedOption: null,
    sharedDateRange: null,
    title: '',
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.set('chartIntervals', [Ember.Object.create({
        isSelected: true,
        label: 'Day',
        name: 'day'
      }), Ember.Object.create({
        isSelected: false,
        label: 'Week',
        name: 'week'
      }), Ember.Object.create({
        isSelected: false,
        label: 'Month',
        name: 'month'
      })]);
      this.setProperties({
        currentEndPoint: this.requests.objectAt(0).endpoint,
        dateRange: this.dateRangeConstructor(),
        selectedInterval: this.chartIntervals.filterBy('name', 'day').objectAt(0)
      });
      if (!this.selectedOption) {
        this.dateRange.objectAt(0).set('isSelected', true);
        this.set('selectedOption', this.dateRange.objectAt(0));
      }
      if (this.isDropdown) {
        this.setProperties({
          dropdownParams: this.requests.objectAt(0),
          durations: [1, 7, 30, 60, 90],
          defaultDuration: 1
        });
        this.dropdownParams.set('activeDays', this.defaultDuration);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.tooltip) {
        (0, _jquery.default)(this.element).find('.title').tooltip({
          title: this.tooltip,
          template: this.utility.generateStandardTooltipMarkup(this.classNames.join(' '))
        });
      }
      this.set('isLoading', true);
    },
    // COMPUTED ------------------------------------------------------------------
    modelSham: Ember.computed('model.[]', function () {
      return this.model;
    }),
    intervalsClass: Ember.computed('title', function () {
      return "intervals-section".concat(this.title ? " ".concat(this.title.toLocaleLowerCase().split(' ').join('-'), "-intervals") : '');
    }),
    engagedDropdownClass: Ember.computed('title', function () {
      return this.title ? "".concat(this.title.toLocaleLowerCase().split(' ').join('-'), "-engaged-dropdown") : '';
    }),
    dateDropdownClass: Ember.computed('title', function () {
      return this.title ? "".concat(this.title.toLocaleLowerCase().split(' ').join('-'), "-date-dropdown") : '';
    }),
    isCustom: Ember.computed('selectedOption.name', function () {
      return this.selectedOption.name.toLowerCase() === 'custom';
    }),
    // OBSERVERS -----------------------------------------------------------------
    // FUNCTIONS -----------------------------------------------------------------
    dateRangeConstructor: function dateRangeConstructor() {
      var durations = [7, 30, 90];
      var option = null;
      var currentDayStartTime = window.moment().subtract(1, 'days').endOf('day').unix() * 1000;
      return durations.map(function (duration) {
        option = Ember.Object.create({
          name: "Last ".concat(duration, " days"),
          isSelected: false,
          to: currentDayStartTime,
          from: window.moment().subtract(duration, 'days').startOf('day').unix() * 1000
        });
        return option;
      });
    },
    resolveResponse: function resolveResponse(res) {
      if (res && res.data) {
        this.set('model', res.data);
      } else {
        this.set('hasNetworkError', true);
      }
      this.set('isLoading', false);
    },
    rejectResponse: function rejectResponse(err) {
      if (!this.isDestroyed) {
        this.setProperties({
          hasNetworkError: true,
          isLoading: false,
          errorCode: err.status
        });
      }
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      changeActiveUsersDateRange: function changeActiveUsersDateRange() {
        var _this = this;
        var selectedInterval = this.selectedInterval;
        this.setProperties({
          hasNetworkError: false,
          isLoading: true
        });
        this.setupActiveUsersRequests(selectedInterval, this.selectedOption);
        this.fetchData(this.requests.objectAt(0)).then(function (res) {
          _this.resolveResponse(res);
        }).catch(function (err) {
          _this.rejectResponse(err);
        });
      },
      selectRange: function selectRange(opt) {
        var _this2 = this;
        var selectedInterval = this.selectedInterval;
        var from = window.moment(opt.from);
        var to = window.moment(opt.to);
        var selectedOptionFromDate = from.format('ddd, MMM DD, YYYY');
        var selectedOptionToDate = to.format('ddd, MMM DD, YYYY');
        this.setProperties({
          isLoading: true,
          hasNetworkError: false,
          displayFromDate: selectedOptionFromDate,
          displayToDate: selectedOptionToDate
        });
        this.set('selectedDateRange', Ember.Object.create({
          from: opt.from,
          to: opt.to
        }));
        if (this.requests.objectAt(0).endpoint.includes('timeline/active')) {
          this.setupActiveUsersRequests(selectedInterval, opt);
        }
        if (this.requests.objectAt(0).endpoint.includes('timeline/total')) {
          this.setupTotalUsersRequests(selectedInterval, opt);
        }
        this.fetchData(this.requests.objectAt(0)).then(function (res) {
          _this2.resolveResponse(res);
        }).catch(function (err) {
          _this2.rejectResponse(err);
        });
      },
      setChartInterval: function setChartInterval(item) {
        this.chartIntervals.setEach('isSelected', false);
        item.set('isSelected', true);
        this.set('selectedInterval', item);
        this.requests.objectAt(0).set('interval', item.name);
        this.send('selectRange', this.selectedOption, this.currentEndPoint);
      },
      refresh: function refresh() {
        window.location.reload(true);
      }
    }
  });
  _exports.default = _default;
});