define("experience-studio/templates/components/route-model-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aHxS+n/e",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form search-form\"],[12],[2,\"\\n    \"],[10,\"form\"],[14,0,\"form-inline\"],[14,\"onsubmit\",\"return false\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"onInput\",\"value\"],[\"didUpdateSearchKeyword\",[35,0]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"searchKeyword\",\"search-form-group\"]}",
    "moduleName": "experience-studio/templates/components/route-model-search.hbs"
  });
  _exports.default = _default;
});