define("experience-studio/routes/communications-hub/triggered-emails/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    utility: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        contentInstances: store.query('content-instance', {
          type: 'email'
        }),
        groups: store.findAll('group'),
        languages: store.queryRecord('project-config', {
          setting: 'language'
        }),
        rules: store.findAll('rule'),
        triggeredEmail: store.createRecord('triggered-email')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('selectedContentInstancesCount', Ember.computed('model.contentInstances.@each.isSelected', function () {
        var records = this.get('model.contentInstances');
        var recordsLength = 0;
        if (!Ember.isEmpty(records)) {
          recordsLength = records.filterBy('isSelected').length;
        }
        return recordsLength;
      }));
      controller.set('selectedRulesCount', Ember.computed('model.rules.@each.isSelected', function () {
        var records = this.get('model.rules');
        var recordsLength = 0;
        if (!Ember.isEmpty(records)) {
          recordsLength = records.filterBy('isSelected').length;
        }
        return recordsLength;
      }));
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('communications-hub');
      },
      deselectModelListItems: function deselectModelListItems(modelName) {
        var models;
        if (modelName === 'content-instance') {
          models = this.controller.get('model.contentInstances');
        } else if (modelName === 'rule') {
          models = this.controller.get('model.rules');
        }
        if (!Ember.isEmpty(models)) {
          models.setEach('isSelected', false);
        }
      },
      resetSearchKeyword: function resetSearchKeyword(searchKeywordPropertyName) {
        this.controller.set(searchKeywordPropertyName, '');
      },
      save: function save() {
        var controller = this.controller;
        var model = controller.get('model.triggeredEmail');
        var selectedContentInstance = controller.get('model.contentInstances').filterBy('isSelected');
        var selectedRule = controller.get('model.rules').filterBy('isSelected');
        var self = this;
        if (!Ember.isEmpty(selectedRule)) {
          model.set('ruleId', selectedRule.objectAt(0).get('id'));
        } else {
          model.set('ruleId', null);
        }
        if (!Ember.isEmpty(selectedContentInstance)) {
          model.set('contentId', selectedContentInstance.objectAt(0).get('id'));
        } else {
          model.set('contentId', null);
        }
        model.save().then(function () {
          self.transitionTo('communications-hub');
          Ember.run.scheduleOnce('afterRender', self, function () {
            self.send('showNotification', {
              type: 'confirm',
              message: "Created \"".concat(model.get('name'), "\" Triggered Email")
            });
          });
        }).catch(function (response) {
          self.controller.set('errorMessage', self.get('utility').parseError(response));
        });
      },
      selectModelListItems: function selectModelListItems(model, modelName) {
        var models;
        if (modelName === 'content-instance') {
          models = this.controller.get('model.contentInstances');
        } else if (modelName === 'rule') {
          models = this.controller.get('model.rules');
        }
        if (!Ember.isEmpty(models)) {
          if (!model.get('isSelected')) {
            models.setEach('isSelected', false);
          }
          model.toggleProperty('isSelected');
        }
      },
      validate: function validate(isActive) {
        var controller = this.controller;
        var errorMessage;
        var model = controller.get('model.triggeredEmail');
        var selectedContentInstance = controller.get('model.contentInstances').filterBy('isSelected');
        var selectedRule = controller.get('model.rules').filterBy('isSelected');
        var validation = Ember.Object.create();
        model.set('isActive', isActive);
        if (Ember.isEmpty(model.get('name'))) {
          errorMessage = 'Enter name.';
          validation.set('name', errorMessage);
        } else if (Ember.isEmpty(selectedRule)) {
          errorMessage = 'Select Rule.';
          validation.set('name', errorMessage);
        } else if (Ember.isEmpty(selectedContentInstance)) {
          errorMessage = 'Select Content.';
          validation.set('name', errorMessage);
        }
        controller.setProperties({
          errorMessage: errorMessage,
          validation: validation
        });
        if (!errorMessage) {
          this.send('save');
        }
      },
      willTransition: function willTransition() {
        var controller = this.controller;
        var model = controller.get('model.triggeredEmail');
        this.send('deselectModelListItems', 'content-instance');
        this.send('deselectModelListItems', 'rule');
        if (!model.get('isSaving') && model.get('isNew')) {
          model.deleteRecord();
        }
        controller.setProperties({
          contentSearchKeyword: '',
          errorMessage: '',
          model: null,
          ruleSearchKeyword: '',
          validation: null
        });
        return true;
      }
    }
  });
  _exports.default = _default;
});