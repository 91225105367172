define("experience-studio/components/main-header-info-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{#main-header-info-sidebar
      didUpdateSearchKeyword='search'
      groups=model.groups
  
      Hello, world!
  
    {{/main-header-info-sidebar}}
  
  */
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    classNames: ['main-header-info-sidebar'],
    groups: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    isInfoSidebarOpen: false,
    isLoadingAffectedRules: false,
    showInfoButton: true,
    didInsertElement: function didInsertElement() {
      var _this = this;
      var self = this;
      this._super.apply(this, arguments);
      this.$('.content--info-sidebar--container').on("click.".concat(this.elementId), function (event) {
        if (self.$(event.target).parents('.info-sidebar').length <= 0 && self.$(event.target).parents('.route-container').length <= 0) {
          if (_this.selectedEntities.filterBy('isSelected').length > 1 && _this.performAction) {
            if (_this.userPreferences.getValue('batchActionBarDeselectAll')) {
              _this.performAction({
                action: 'showModal',
                modalName: 'confirmation-modal-dialog-v2',
                title: 'Deselect All',
                primaryMessage: 'Are you sure you want to deselect all?',
                primaryAction: {
                  action: 'deselectModelListItems'
                }
              });
            } else {
              self.sendAction('deselectModelListItems');
            }
          } else {
            self.sendAction('deselectModelListItems');
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$('.content--info-sidebar--container').off();
    },
    actions: {
      changeGroupPriorities: function changeGroupPriorities(action) {
        this.sendAction('changeGroupPriorities', action); // eslint-disable-line ember/closure-actions
      },
      showManageGroupModalDialog: function showManageGroupModalDialog() {
        this.sendAction('showManageGroupModalDialog'); // eslint-disable-line ember/closure-actions
      },
      deselectModelListItems: function deselectModelListItems() {
        this.sendAction('deselectModelListItems'); // eslint-disable-line ember/closure-actions
      },
      didChangeTab: function didChangeTab(tab) {
        this.sendAction('didChangeTab', tab); // eslint-disable-line ember/closure-actions
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(options) {
        this.sendAction('didUpdateSearchKeyword', options); // eslint-disable-line ember/closure-actions
      },
      dispatchAction: function dispatchAction(action, model, params) {
        this.sendAction('dispatchAction', action, model, params); // eslint-disable-line ember/closure-actions
      },
      toggleInfoSidebar: function toggleInfoSidebar() {
        this.toggleProperty('isInfoSidebarOpen'); // eslint-disable-line
      },
      updateQueryParams: function updateQueryParams(data) {
        if (this.updateQueryParams) {
          this.updateQueryParams(data);
        }
      }
    }
  });
  _exports.default = _default;
});