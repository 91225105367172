define("experience-studio/mixins/route-model-table-actions-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    userPreferences: Ember.inject.service(),
    actions: {
      confirmDeleteRecord: function confirmDeleteRecord(params) {
        var record = params.record;
        var message;
        var modelName;
        message = 'Are you sure you want to delete ';
        modelName = Ember.String.classify(record.constructor.modelName);
        modelName = modelName.replace(/([A-Z])/g, ' $1').trim();
        var secondaryMessage = 'You can\'t undo this action';
        if (modelName.toLowerCase().indexOf('content') >= 0) {
          modelName = 'Content';
        }
        message += "\"".concat(record.get('name'), "\" ").concat(modelName, "?");
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog-v2',
          primaryActionName: 'deleteRecord',
          primaryMessage: message,
          secondaryMessage: secondaryMessage,
          record: record,
          modelName: modelName,
          title: 'Confirm Action',
          primaryAction: {
            action: 'deleteRecord',
            record: record
          }
        }));
      },
      createRecord: function createRecord(params) {
        this.transitionTo(params.route, {
          queryParams: {
            data: ''
          }
        });
      },
      deleteRecord: function deleteRecord(params) {
        var _this = this;
        var record = params.record;
        record.destroyRecord().then(function () {
          _this.send('hideModal');
          if (_this.refreshModels) {
            _this.refreshModels(record);
          }
          Ember.run.scheduleOnce('afterRender', _this, function () {
            _this.send('showNotification', {
              type: 'confirm',
              message: 'Deleted successfully!'
            });
          });
          _this.send('deselectModelListItems');
          if (record.constructor.modelName.toLowerCase() === 'rule') {
            var id = record.get('id');
            if (record.stringRepresentation.indexOf('ctxhistory.query.result') >= 0) {
              record.stringRepresentation.split(',').filter(function (plugin) {
                return plugin.split('result.')[1];
              }).forEach(function (plugin) {
                var hsQueryid = plugin.split('result.')[1];
                _this.store.findRecord('context-historical-query', hsQueryid).then(function (hsRecord) {
                  hsRecord.destroyRecord();
                });
              });
            }
            _this.store.findAll('rule').then(function (rules) {
              rules.forEach(function (item) {
                if (item.get('affectedBy') && !Ember.isEmpty(item.get('affectedBy'))) {
                  if (item.get('affectedBy').includes(id)) {
                    _this.store.unloadRecord(item);
                  }
                }
              });
            });
            _this.store.unloadAll('experience');
          }
        }).catch(function (response) {
          record.rollbackAttributes();
          var message = _this.get('utility').parseError(response);
          _this.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: message,
            title: 'Error',
            type: 'danger'
          }));
        });
      },
      deselectModelListItems: function deselectModelListItems() {
        var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var selectedEntitiesPath = params.selectedEntitiesPath || 'selectedEntities';
        var selectedEntities = this.controller.get(selectedEntitiesPath);
        if (selectedEntities) {
          selectedEntities.setEach('isSelected', false);
        }
        this.controller.set(selectedEntitiesPath, []);
      },
      gotoAnalytics: function gotoAnalytics(params) {
        var record = params.record;
        var modelName = record.constructor.modelName;
        var routeMap = {
          'content-instance': 'analytics-v2.content-instance',
          'rule': 'analytics-v2.context-rule',
          'push-message': 'analytics-v2.push-notification'
        };
        var route = routeMap[modelName];
        this.transitionTo(route, record);
      },
      selectModelListItems: function selectModelListItems(params) {
        var record = params.record;
        var selectedEntitiesPath = params.selectedEntitiesPath || 'selectedEntities';
        // If is multi select an array is sent
        if (Ember.isArray(record)) {
          // Convert promise to mutable array
          if (record.toArray) {
            record = record.toArray();
          }
          if (record.isAny('isSelected')) {
            record.setEach('isSelected', false);
            this.controller.get(selectedEntitiesPath).removeObjects(record);
          } else {
            record.setEach('isSelected', true);
            this.controller.get(selectedEntitiesPath).pushObjects(record);
          }
          // If a single record its single selected
        } else {
          if (record.isSelected) {
            this.send('deselectModelListItems', {
              selectedEntitiesPath: selectedEntitiesPath
            });
            this.controller.get(selectedEntitiesPath).removeObject(record);
          } else {
            this.send('deselectModelListItems', {
              selectedEntitiesPath: selectedEntitiesPath
            });
            record.set('isSelected', true);
            this.controller.get(selectedEntitiesPath).pushObject(record);
          }
        }
      },
      editRecord: function editRecord(params) {
        if (params.record.constructor.modelName === 'push-message' && params.record.isActive && !params.record.isBroadcast) {
          var message = '';
          message += 'Deactivate Push Notification to edit Push Notification.';
          this.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: message,
            title: 'Error',
            type: 'danger'
          }));
          return;
        }
        var id = params.record && params.record.id ? params.record.get('id') : params.record;
        this.transitionTo(params.route, id, {
          queryParams: {
            data: ''
          }
        });
      },
      performModelAction: function performModelAction(params) {
        var action = params.action;
        delete params.action;
        this.send(action, params);
      },
      viewInfo: function viewInfo(params) {
        var controller = params.controller ? this.controller.get(params.controller) : this.controller;
        // toggle is true
        if (params.toggle && controller.selectedEntities.length === 1 && controller.selectedEntities[0] === params.record) {
          controller.toggleProperty('isInfoSidebarOpen');
        } else {
          controller.set('isInfoSidebarOpen', true);
        }
        if (params.record.isSelected) {
          params.record.set('isSelected', false);
        }
        this.send('selectModelListItems', params);
      }
    }
  });
  _exports.default = _default;
});