define("experience-studio/components/manage-role-modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['manage-role-modal-dialog'],
    permissions: null,
    selectedUsers: null,
    utility: Ember.inject.service(),
    permissionWithModels: Ember.computed('permissions.@each.{model,isSelected}', function () {
      return this.permissions.filter(function (permission) {
        return !Ember.isEmpty(permission.model) && permission.isSelected;
      });
    }),
    generatePermission: function generatePermission(params) {
      var permission = Ember.Object.create({
        name: params.name,
        modelName: params.modelName,
        type: params.type,
        isVirtualId: params.isVirtualId,
        selectedCount: 0,
        model: null,
        selectedModels: [],
        isSelected: false,
        options: [Ember.Object.create({
          name: 'Get',
          action: 'get',
          isSelected: false
        }), Ember.Object.create({
          name: 'Create',
          action: 'create',
          isSelected: false
        }), Ember.Object.create({
          name: 'Edit',
          action: 'update',
          isSelected: false
        }), Ember.Object.create({
          name: 'Delete',
          action: 'delete',
          isSelected: false
        })]
      });
      if (params.type === 'experience') {
        permission.options.push(Ember.Object.create({
          name: 'Activate',
          action: 'activate',
          isSelected: false
        }));
      }
      return permission;
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedUsers', []);
      this.set('permissions', [this.generatePermission({
        name: 'Experiences',
        modelName: 'experience',
        type: 'experience',
        isVirtualId: true
      }), this.generatePermission({
        name: 'Groups',
        modelName: 'group',
        type: 'group',
        isVirtualId: true
      }), this.generatePermission({
        name: 'Content',
        modelName: 'content-instance',
        type: 'contentinstance',
        isVirtualId: true
      }), this.generatePermission({
        name: 'Context Rules',
        modelName: 'rule',
        type: 'rule',
        isVirtualId: true
      }), this.generatePermission({
        name: 'Push Messages',
        modelName: 'push-message',
        type: 'push',
        isVirtualId: true
      })]);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this.model.role.isNew) {
        // preset users
        this.model.role.userIds.forEach(function (userId) {
          var user = _this.model.users.findBy('id', userId);
          user.set('dropdownSelected', true);
          _this.selectedUsers.push(user);
        });

        // select permission via selecting options
        this.model.role.entities.forEach(function (entity) {
          var permission = _this.permissions.findBy('type', entity.type);
          var option = permission.options.findBy('action', entity.action);
          option.set('isSelected', true);
        });

        // Load models for each permission
        this.set('isLoading', true);
        this.performFetchEnitities().then(function (permissionModels) {
          permissionModels.forEach(function (model) {
            _this.permissions.findBy('modelName', model.modelName).set('model', model);
          });
          // Select models for each permission
          _this.model.role.entities.uniqBy('id').forEach(function (entity) {
            var permission = _this.permissions.findBy('type', entity.type);
            var item = permission.model.findBy('id', entity.id);
            if (!Ember.isEmpty(item)) {
              item.set('dropdownSelected', true);
              permission.selectedModels.push(item);
            }
          });
          _this.set('isLoading', false);
        }).catch(function (jqXHR) {
          _this.set('errorMessage', _this.utility.parseError(jqXHR));
          _this.set('isLoading', false);
        });
      }
    },
    countPermissions: function countPermissions() {
      var count = 0;
      this.permissions.forEach(function (permission) {
        count += permission.selectedCount;
      });
      return count;
    },
    performFetchEnitities: function performFetchEnitities() {
      var defferedPermissions = Ember.RSVP.defer();
      var emptyPermissions = this.permissions.filter(function (permission) {
        return !permission.model && permission.isSelected;
      }).mapBy('modelName');
      this.sendAction('fetchEntities', emptyPermissions, defferedPermissions); // eslint-disable-line ember/closure-actions

      return defferedPermissions.promise;
    },
    actions: {
      cancel: function cancel() {
        var model = this.model.role;
        if (!model.get('isSaving') && model.get('isNew')) {
          model.deleteRecord();
        }
        this.send('close');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      close: function close() {
        this.model.users.setEach('dropdownSelected', false);
        this.permissions.forEach(function (permission) {
          if (!Ember.isEmpty(permission.model)) {
            permission.model.setEach('dropdownSelected', false);
          }
        });
        this.set('model', null);
      },
      fetchEntities: function fetchEntities() {
        var _this2 = this;
        if (this.countPermissions() > 0) {
          this.set('isLoading', true);
          this.set('errorMessage', '');
          this.performFetchEnitities().then(function (permissionModels) {
            permissionModels.forEach(function (model) {
              _this2.permissions.findBy('modelName', model.modelName).set('model', model);
            });
            _this2.set('isLoading', false);
          }).catch(function (jqXHR) {
            // do something
            _this2.set('errorMessage', _this2.utility.parseError(jqXHR));
            _this2.set('isLoading', false);
          });
        }
      },
      save: function save() {
        var _this3 = this;
        var model = this.model.role;
        var isNew = model.isNew;
        model.set('userIds', this.selectedUsers.mapBy('id'));
        var entities = [];
        this.permissions.filterBy('isSelected', true).forEach(function (permission) {
          permission.selectedModels.forEach(function (model) {
            permission.options.filterBy('isSelected', true).forEach(function (option) {
              entities.pushObject(Ember.Object.create({
                name: model.name,
                id: model.id,
                type: permission.type,
                action: option.action,
                isVirtualId: permission.isVirtualId
              }));
            });
          });
        });
        model.set('entities', entities);
        model.save().then(function () {
          _this3.send('close');
          _this3.sendAction('refresh'); // eslint-disable-line ember/closure-actions
          var message = '';
          if (isNew) {
            message = "Successfully created \"".concat(model.get('name'), "\" Role");
          } else {
            message = "Changes made to \"".concat(model.get('name'), "\" Role");
          }
          _this3.sendAction('showNotification', {
            // eslint-disable-line ember/closure-actions
            type: 'confirm',
            message: message
          });
        }).catch(function (jqXHR) {
          _this3.set('errorMessage', _this3.utility.parseError(jqXHR));
        });
      },
      updateSelectedUsers: function updateSelectedUsers(selectedUsers) {
        this.set('selectedUsers', selectedUsers);
      },
      validate: function validate() {
        // check if name exists
        if (!Ember.isEmpty(this.model.role.name)) {
          this.send('save');
        } else {
          this.set('errorMessage', 'Name is required.');
          // show error
        }
      }
    }
  });
  _exports.default = _default;
});