define("experience-studio/services/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    getCookie: function getCookie(key) {
      var match = document.cookie.match(new RegExp("(^| )".concat(key, "=([^;]+)")));
      if (match) return match[2];
    },
    clear: function clear() {
      localStorage.clear();
    },
    getItem: function getItem(key) {
      var item = localStorage.getItem(key);
      if (key === 'token') {
        var cookieToken = this.getCookie('token');
        return item || cookieToken;
      }
      return item;
    },
    getBoolean: function getBoolean(key) {
      var value = this.getItem(key);
      if (value && value === 'true') {
        return true;
      } else {
        return false;
      }
    },
    removeItem: function removeItem(key) {
      if (key === 'token') {
        document.cookie = "token=";
      }
      localStorage.removeItem(key);
    },
    setItem: function setItem(key, value) {
      if (key === 'token') {
        document.cookie = "token=".concat(value);
      }
      localStorage.setItem(key, value);
    },
    setItems: function setItems(object) {
      for (var property in object) {
        if (object.hasOwnProperty(property)) {
          var value = null;
          if (object[property]) {
            value = object[property];
          }
          this.setItem(property, value);
        }
      }
    }
  });
  _exports.default = _default;
});