define("experience-studio/components/content-form-survey-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-form-survey-results'],
    model: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    languages: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    selectLang: {},
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    didInsertElement: function didInsertElement() {
      this.languages.forEach(function (item) {
        return item.set('isSelected', false);
      });
      this.languages.filterBy('isDefault', true).objectAt(0).set('isSelected', true);
      var setSelectedLang = this.languages.filterBy('isDefault', true).objectAt(0);
      this.send('selectLang', setSelectedLang);
    },
    didSelectLanguage: Ember.observer('languages.@each.isSelected', function () {
      Ember.run.debounce(this, this.setSelectedLang, 100);
    }),
    setSelectedLang: function setSelectedLang() {
      var model = this.model;
      var self = this;
      this.set('selectLang', this.languages.filterBy('isSelected', true).objectAt(0));
      model.forEach(function (item) {
        if (!item.localizations.get(self.get('selectLang.id'))) {
          item.localizations.set(self.get('selectLang.id'), Ember.Object.create({
            question: ''
          }));
          item.set('q', item.localizations.get(self.get('selectLang.id')));
        } else {
          item.set('q', item.localizations.get(self.get('selectLang.id')));
        }
      });
      if (this.get('selectLang.id')) {
        model.forEach(function (item) {
          if (!Ember.isEmpty(item.choices)) {
            var arr = item.choices.map(function (choice) {
              choice = Ember.Object.create(choice);
              for (var key in choice.get('localizations')) {
                if (choice.get('localizations').hasOwnProperty(key)) {
                  if (key === self.get('selectLang.id')) {
                    choice.set('value', choice.get('localizations')[key].get('value'));
                    if (!Ember.isEmpty(choice.get('value'))) {
                      choice.set('votes', choice.get('votes'));
                      choice.set('barWidth', "".concat(choice.get('votes') * 10, "%"));
                    }
                  }
                }
              }
              return Ember.Object.create(choice);
            });
            item.set('choices', arr);
          }
        });
      }
    },
    actions: {
      selectLang: function selectLang(item) {
        this.languages.filterBy('isSelected', true).objectAt(0).set('isSelected', false);
        this.languages.filterBy('name', item.get('name')).objectAt(0).set('isSelected', true);
      }
    }
  });
  _exports.default = _default;
});