define("experience-studio/components/content-tmp-edit-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-number
      field=field
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-number'],
    valueProxy: Ember.computed('field.value', {
      get: function get() {
        return this.get('field.value');
      },
      set: function set(key, value) {
        if (!isNaN(value) && value !== '' && value !== null) {
          var proxyNum = +value;
          this.set('field.value', proxyNum);
        } else {
          this.set('field.value', value);
        }
        return value;
      }
    }),
    isNumber: Ember.computed('field.value', function () {
      return !isNaN(this.get('field.value'));
    })
  });
  _exports.default = _default;
});