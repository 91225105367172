define("experience-studio/components/av5-double-pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Renders an Analytics Double Pie Chart
  
    Usages:
  
      <av5-double-pie-chart
        chartData=chartData
      />
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-double-pie-chart'],
    chart: null,
    showLegend: false,
    // COMPUTED ------------------------------------------------------------------
    innerPieData: Ember.computed('chartData.[]', function () {
      if (this.chartData && this.chartData[0] && !this.chartData[0].filter(function (data) {
        return data.value > 0;
      }).length) {
        return [{
          value: 1,
          itemStyle: {
            color: '#EBEDF8'
          }
        }].concat(this.chartData[0]);
      }
      return this.chartData[0];
    }),
    outerPieData: Ember.computed('chartData.[]', function () {
      if (this.chartData && this.chartData[1] && !this.chartData[1].filter(function (data) {
        return data.value > 0;
      }).length) {
        return [{
          value: 0,
          itemStyle: {
            color: '#EBEDF8'
          }
        }];
      }
      return this.chartData[1];
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.chart && this.chart.setOption({
        tooltip: {
          trigger: 'item',
          hideDelay: 50,
          confine: true,
          backgroundColor: 'white',
          borderRadius: 8,
          borderWidth: 1,
          borderColor: ['rgba(210, 210, 210, 0.88)'],
          shadowBlur: 10,
          textStyle: {
            color: 'black'
          },
          formatter: function formatter(params) {
            if (!params.name) return null;
            return "\n            <div style=\"font-size: 14px; font-family: 'Source Sans Pro'; padding: 4px 8px; \">\n              <div style=\"margin-bottom: 4px;\">\n                ".concat(params.data && params.data.category ? "".concat(params.data.category, ": ") : '', "\n                ").concat(params.name.toLocaleString(), "\n              </div>\n              <span style=\"font-weight: bold;\">\n                ").concat(params.value, " users (").concat(params.percent, "% of total users)\n              </span>\n            </div>\n          ");
          }
        },
        legend: {
          orient: 'vertical',
          selectedMode: false,
          bottom: 10,
          height: '40px',
          itemHeight: 7,
          itemWidth: 22,
          data: this.chartLegendData
        },
        grid: {
          top: '0'
        },
        series: this.chartData ? [{
          name: 'Opt-in',
          type: 'pie',
          radius: [0, '50%'],
          label: {
            formatter: ''
          },
          labelLine: {
            show: false
          },
          silent: this.chartData[0].filter(function (data) {
            return data.value > 0;
          }).length === 0,
          hoverOffset: 0,
          selectedMode: false,
          center: ['50%', 100],
          cursor: this.chartData[0].filter(function (data) {
            return data.value > 0;
          }).length ? 'pointer' : 'default',
          emphasis: {
            labelLine: {
              show: false
            }
          },
          data: this.innerPieData
        }, {
          name: 'Permissions',
          type: 'pie',
          radius: ['60%', '75%'],
          label: {
            formatter: ''
          },
          labelLine: {
            show: false
          },
          silent: this.chartData[1].filter(function (data) {
            return data.value > 0;
          }).length === 0,
          hoverOffset: 0,
          selectedMode: false,
          bottom: '50%',
          height: '100%',
          center: ['50%', 100],
          cursor: this.chartData[1].filter(function (data) {
            return data.value > 0;
          }).length ? 'pointer' : 'default',
          emphasis: {
            labelLine: {
              show: false
            }
          },
          data: this.outerPieData
        }] : {
          type: 'pie'
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var chartElement = this.element.querySelector('.double-pie-chart');
      var chart = window.echarts.init(chartElement);
      this.set('chart', chart);
      window.addEventListener('resize', function () {
        _this.chart.resize();
      });
    } // FUNCTIONS -----------------------------------------------------------------
    // ACTIONS -------------------------------------------------------------------
  });
  _exports.default = _default;
});