define("experience-studio/components/route-model-filter-dropdown-multi-checkbox-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown-multi-checkbox-select', 'dropdown-item', 'dropdown-submenu'],
    selectedOptions: null,
    determineSelection: function determineSelection() {
      var _this = this;
      if (this.queryParameters && this.section) {
        var selectedOptions = [];
        this.section.options.forEach(function (option) {
          if (_this.queryParameters[option.property] === option.value) {
            selectedOptions.pushObject(option);
            option.set('isSelected', true);
          } else {
            option.set('isSelected', false);
          }
        });
        this.set('selectedOptions', selectedOptions);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.determineSelection();
    },
    actions: {
      toggleSelection: function toggleSelection(option) {
        if (this.didUpdateQueryParams) {
          var queryObject = {};
          var value = option.value;
          if (option.value === this.queryParameters[option.property]) {
            value = null;
          }
          queryObject[option.property] = value;
          this.didUpdateQueryParams(queryObject);
        }
      }
    }
  });
  _exports.default = _default;
});