define("experience-studio/components/model-list-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{model-list-pagination
      _isHidden: true,
      page: null,
      pagination: null
    }}
  
  */
  var _default = Ember.Component.extend({
    classNameBindings: ['isHidden'],
    classNames: ['model-list-pagination'],
    isHidden: Ember.computed.lte('pages.length', 1),
    page: null,
    model: null,
    pages: Ember.computed('page', 'model.[]', function () {
      var numberOfPages = 0;
      var page = +this.page || 1;
      var pages = [];
      var pagination = this.get('model.meta.pagination');
      var _limit = pagination.get('limit');
      if (pagination) {
        // if limit is 0, skip exec, prevent infinity

        if (_limit > 0) {
          numberOfPages = Math.ceil(pagination.get('total') / _limit);
        }
        for (var i = 1; i <= numberOfPages; i++) {
          pages.pushObject(Ember.Object.create({
            isActive: i === page,
            value: i
          }));
        }
      }
      return pages;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var $pagination = this.$('.pagination');
      var offset = $pagination.find('.active').offset();
      var width = $pagination.outerWidth();
      if (offset && width) {
        $pagination.scrollLeft(offset.left - width);
      }
    },
    actions: {
      changePage: function changePage(page) {
        var value;
        if (page === 'next') {
          value = this.page + 1;
        } else if (page === 'previous') {
          value = this.page - 1;
        } else {
          value = page.get('value');
        }
        if (value < 1) {
          value = 1;
        } else if (value > this.get('pages.length')) {
          value = this.get('pages.length');
        }
        this.set('page', value);
        this.sendAction('requestPageChange', value); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});