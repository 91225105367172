define("experience-studio/templates/components/context-locked-form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7BCA24zC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-check\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"form-check-label\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"checked\"],[\"checkbox\",\"form-check-input\",[35,0]]]]],[2,\"\\n    Context-Locked\\n\\n    \"],[10,\"button\"],[14,0,\"btn svg-icon-container\"],[14,\"data-toggle\",\"popover\"],[14,\"data-trigger\",\"focus hover\"],[14,\"data-placement\",\"right\"],[14,\"data-content\",\"Only users who fulfill a Context Rule's conditions will be able to see this content (in an Experience).\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"question-circle\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"input\",\"svg-jar\"]}",
    "moduleName": "experience-studio/templates/components/context-locked-form-group.hbs"
  });
  _exports.default = _default;
});