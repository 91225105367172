define("experience-studio/templates/components/model-list-item-permission-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IpoclNIb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"inner \",[30,[36,3],[[35,2,[\"isSelected\"]],\"is-selected\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-text\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"title no-subtitle\"],[15,\"title\",[31,[[34,2,[\"name\"]]]]],[12],[1,[35,2,[\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"header-option more-option\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"dropdown dropdown-more\"],[12],[2,\"\\n          \"],[10,\"button\"],[14,0,\"btn btn-more dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"ellipsis-v\"],null]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[12],[2,\"\\n            \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"dropdown-item\"],[4,[38,1],[[32,0],\"info\"],null],[12],[2,\"\\n              \"],[1,[30,[36,0],[\"info-circle\"],null]],[2,\" View Info\\n            \"],[13],[2,\"\\n            \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"dropdown-item\"],[4,[38,1],[[32,0],\"edit\"],null],[12],[2,\"\\n              \"],[1,[30,[36,0],[\"pen\"],null]],[2,\" Edit\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"dropdown-divider\"],[12],[13],[2,\"\\n            \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"dropdown-item\"],[4,[38,1],[[32,0],\"delete\"],null],[12],[2,\"\\n              \"],[1,[30,[36,0],[\"trash\"],null]],[2,\" Delete\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"action\",\"model\",\"if\",\"showActions\"]}",
    "moduleName": "experience-studio/templates/components/model-list-item-permission-role.hbs"
  });
  _exports.default = _default;
});