define("experience-studio/templates/components/av5-pagination-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9A5Y8E7n",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"-\"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  —\\n\"]],\"parameters\":[]}]]],[2,\"of\\n\"],[1,[34,2]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentPageStart\",\"currentPageEnd\",\"totalRecords\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/av5-pagination-info.hbs"
  });
  _exports.default = _default;
});