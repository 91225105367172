define("experience-studio/components/file-browser-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{file-browser-item
      file=file
      selectedFiles=selectedFiles
    }}
  
  */

  var typeIconMap = {
    'PDF': 'file-pdf',
    'YouTube': 'youtube-play',
    'CSV': 'file-text',
    'DOC': 'file-word',
    'DOCX': 'file-word',
    'MP4': 'file-video',
    'MPG': 'file-video',
    'MPEG': 'file-video',
    'MOV': 'file-video',
    'XLS': 'file-excel',
    'XLSX': 'file-excel',
    'PPT': 'file-powerpoint',
    'PPTX': 'file-powerpoint',
    'ZIP': 'file-archive',
    'RAR': 'file-archive',
    '7ZIP': 'file-archive'
  };
  var _default = Ember.Component.extend({
    classNames: ['file-browser-item'],
    classNameBindings: ['isSelected:selected'],
    fileIcon: Ember.computed('file.fileExt', function () {
      if (typeof this.get('file.fileExt') === 'string') {
        return typeIconMap[this.get('file.fileExt').toUpperCase()];
      }
      return;
    }),
    isSelected: Ember.computed('selectedFiles.[]', function () {
      var view = this;
      return this.selectedFiles && this.selectedFiles.find(function (item) {
        return item.get('id') === view.get('file.id');
      });
    }),
    click: function click() {
      this.sendAction('action', this.file); // eslint-disable-line ember/closure-actions
    }
  });
  _exports.default = _default;
});