define("experience-studio/components/content-tmp-edit-colour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-colour
      field=field
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-colour'],
    colorChange: Ember.observer('field.value', function () {
      this.spectrumElem.spectrum('set', this.get('field.value'));
    }),
    didInsertElement: function didInsertElement() {
      var view = this;
      var spectrumElem = this.$('.color-preview').spectrum({
        change: function change(color) {
          view.set('field.value', color.toHexString());
        }
      });
      view.set('spectrumElem', spectrumElem);
      if (view.get('field.value')) {
        spectrumElem.spectrum('set', view.get('field.value'));
      }
    }
  });
  _exports.default = _default;
});