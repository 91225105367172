define("experience-studio/components/content-tmp-branch-objarray", ["exports", "experience-studio/mixins/draggable-list"], function (_exports, _draggableList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var findField = function findField(arr, tmpID) {
    return arr.filter(function (item) {
      return item.tmpID === tmpID;
    })[0];
  };
  var _default = Ember.Component.extend(_draggableList.default, {
    classNames: ['content-tmp-branch-objarray'],
    attributeBindings: ['field.tmpID:tmp-id'],
    isExpanded: true,
    hasOptions: true,
    childrenExpanded: true,
    childrenTrigger: false,
    actions: {
      didObjArrayUpdateData: function didObjArrayUpdateData(options) {
        if (typeof this.didObjArrayUpdateData !== 'undefined') {
          this.didObjArrayUpdateData(options);
        }
      },
      didFocusIn: function didFocusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      },

      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      },
      isPreviewVideoTooltip: function isPreviewVideoTooltip(value) {
        if (typeof this.isPreviewVideoTooltip !== 'undefined') {
          this.isPreviewVideoTooltip(value);
        }
      },
      addItem: function addItem() {
        this.get('field.value').pushObject(this.field.newChild());
        if (typeof this.didObjArrayUpdateData !== 'undefined') {
          this.didObjArrayUpdateData(this.get('field'));
        }
      },
      delete: function _delete(field) {
        this.get('field.value').removeObject(field);
        if (typeof this.didObjArrayUpdateData !== 'undefined') {
          this.didObjArrayUpdateData(this.get('field'));
        }
      },
      duplicate: function duplicate(field) {
        var index = this.get('field.value').indexOf(field);
        if (index > -1) {
          var copy = field.duplicate();
          this.get('field.value').insertAt(++index, copy);
        }
        if (typeof this.didObjArrayUpdateData !== 'undefined') {
          this.didObjArrayUpdateData(this.get('field'));
        }
      },
      collapseAll: function collapseAll() {
        this.set('childrenExpanded', false);
        this.toggleProperty('childrenTrigger');
      },
      expandAll: function expandAll() {
        this.set('childrenExpanded', true);
        this.toggleProperty('childrenTrigger');
      },
      clearArray: function clearArray() {
        this.get('field.value').clear();
        if (typeof this.didObjArrayUpdateData !== 'undefined') {
          this.didObjArrayUpdateData(this.get('field'));
        }
      },
      reorder: function reorder(opts) {
        var elem = findField(this.get('field.value'), opts.elemID);
        var sibling = findField(this.get('field.value'), opts.siblingID);
        this.get('field.value').removeObject(elem);
        if (sibling) {
          var destinationIndex = this.get('field.value').indexOf(sibling);
          this.get('field.value').insertAt(destinationIndex, elem);
        } else {
          this.get('field.value').pushObject(elem);
        }
        this.reorder();
        if (typeof this.didObjArrayUpdateData !== 'undefined') {
          this.didObjArrayUpdateData(this.get('field'));
        }
      }
    },
    didInsertElement: function didInsertElement() {
      if (typeof this.didObjArrayUpdateData !== 'undefined') {
        this.didObjArrayUpdateData(this.get('field'));
      }
      this.initDrag();
    }
  });
  _exports.default = _default;
});