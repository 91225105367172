define("experience-studio/models/rule-simulation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    ruleId: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    errorCount: _emberData.default.attr('number'),
    falseCount: _emberData.default.attr('number'),
    trueCount: _emberData.default.attr('number'),
    completedAt: _emberData.default.attr('epoch'),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    // Computed Properties

    computedRule: Ember.computed('ruleId', function () {
      var id = this.ruleId;
      var rule;
      if (id) {
        rule = this.store.findRecord('rule', id);
      }
      return rule;
    })
  });
  _exports.default = _default;
});