define("experience-studio/components/confirm-group-records-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['batch-actions-modal'],
    selectedGroups: null,
    selectedRecords: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        selectedRecords: [],
        selectedGroups: [],
        headers: [Ember.Object.create({
          name: 'Name',
          class: 'name-col'
        })]
      });
    },
    confirmModalTitle: Ember.computed('model.{pluralEntityName,isAdding}', function () {
      if (this.model.isAdding) {
        return "Add to an Existing Group";
      } else {
        return "Remove from Group";
      }
    }),
    searchPlaceholder: Ember.computed('model.pluralEntityName', function () {
      return "Search ".concat(this.model.pluralEntityName);
    }),
    isActionDisabled: Ember.computed('selectedRecords.[]', 'selectedGroups.[]', function () {
      return this.selectedRecords.length === 0 || this.selectedGroups.length === 0;
    }),
    groupSham: Ember.computed('model.{groups.[],selectedEntities.[],isAdding}', function () {
      var _this = this;
      if (this.model.isAdding) {
        return this.model.groups;
      } else {
        return this.model.groups.filter(function (group) {
          return _this.model.selectedEntities.reduce(function (previous, item) {
            return previous || item.groupIds.includes(group.id);
          }, false);
        });
      }
    }),
    actions: {
      group: function group() {
        var records = this.selectedRecords;
        var groups = this.selectedGroups;
        this.performAction({
          action: 'groupRecords',
          isAdding: this.model.isAdding,
          singularEntityName: this.model.singularEntityName,
          pluralEntityName: this.model.pluralEntityName,
          confirmModalTitle: this.confirmModalTitle,
          records: records,
          groups: groups
        });
        this.send('close');
      },
      onSelectGroups: function onSelectGroups(groups) {
        var _this2 = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this2.set('selectedGroups', groups);
        });
      },
      onSelectRecords: function onSelectRecords(records) {
        var _this3 = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this3.set('selectedRecords', records);
        });
      }
    }
  });
  _exports.default = _default;
});