define("experience-studio/components/content-tmp-edit-content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-content-instance'],
    store: Ember.inject.service(),
    selectedOption: '',
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this.store.findAll('content-instance').then(function (contentInstances) {
        var instances = [];
        contentInstances.forEach(function (contentInstance) {
          instances.pushObject({
            'name': contentInstance.get('name'),
            'value': contentInstance.get('id')
          });
        });
        Ember.set(_this.field, 'options', instances);
      });
      if (this.field.value) {
        var selectedOptionValue = this.store.peekRecord('content-instance', this.field.value);
        this.set('selectedOption', {
          'name': selectedOptionValue.get('name'),
          'value': selectedOptionValue.get('id')
        });
      }
    },
    actions: {
      selectValue: function selectValue(option) {
        this.set('selectedOption', option);
        Ember.set(this.field, 'value', option.value);
      }
    }
  });
  _exports.default = _default;
});