define("experience-studio/mirage/factories/rule-engagement", ["exports", "ember-cli-mirage", "@faker-js/faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    totalReach: function totalReach() {
      return _faker.faker.datatype.number(5000000);
    },
    uniqueReach: function uniqueReach() {
      return _faker.faker.datatype.number(this.totalReach);
    },
    uniqueReachRate: function uniqueReachRate() {
      return this.uniqueReach / this.totalReach;
    },
    ruleId: function ruleId() {
      return _faker.faker.datatype.uuid();
    }
  });
  _exports.default = _default;
});