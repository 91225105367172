define("experience-studio/components/confirm-activate-records-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['batch-actions-modal'],
    selectedRecords: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedRecords', []);
      this.set('headers', [Ember.Object.create({
        name: 'Name',
        class: 'name-col'
      }), Ember.Object.create({
        name: 'Status',
        class: 'attr-col'
      })]);
    },
    searchPlaceholder: Ember.computed('model.pluralEntityName', function () {
      return "Search ".concat(this.model.pluralEntityName);
    }),
    confirmModalTitle: Ember.computed('model.{pluralEntityName,isActive}', function () {
      if (this.model.isActive) {
        return "Activate ".concat(this.model.pluralEntityName);
      } else {
        return "Deactivate ".concat(this.model.pluralEntityName);
      }
    }),
    isActionDisabled: Ember.computed('selectedRecords.[]', function () {
      return this.selectedRecords.length === 0;
    }),
    actions: {
      validationFunction: function validationFunction(record) {
        if (record.constructor.modelName === 'push-message' && record.isBroadcast) {
          if (record.isSent) {
            record.setProperties({
              statusText: 'Sent',
              modalStatusClass: 'sent'
            });
          } else {
            record.setProperties({
              statusText: 'Ready to Send',
              modalStatusClass: 'not-sent'
            });
          }
          return "Broadcast Push Notifications can only be sent using the manual \"Send\" tool.";
        } else if (record.constructor.modelName === 'push-message' && !record.isBroadcast) {
          if (record.isActive) {
            record.setProperties({
              statusText: 'Active',
              modalStatusClass: 'active'
            });
          } else {
            record.setProperties({
              statusText: 'Inactive',
              modalStatusClass: 'inactive'
            });
          }
        } else if (record.constructor.modelName === 'experience' && !record.isActive) {
          record.setProperties({
            statusText: 'Inactive',
            modalStatusClass: 'inactive'
          });
          if (record.computedRuleObjects.get('firstObject.isDraft')) {
            return "Cannot activate an ".concat(this.model.singularEntityName, " which is associated to a draft rule");
          }
        } else {
          record.setProperties({
            statusText: 'Active',
            modalStatusClass: 'active'
          });
        }
        if (record.isActive && this.model.isActive) {
          return "".concat(this.model.singularEntityName, " is already active.");
        } else if (!record.isActive && !this.model.isActive) {
          return "".concat(this.model.singularEntityName, " is already inactive.");
        }
      },
      activate: function activate() {
        var records = this.selectedRecords;
        this.performAction({
          action: 'activateRecords',
          singularEntityName: this.model.singularEntityName,
          pluralEntityName: this.model.pluralEntityName,
          confirmModalTitle: this.confirmModalTitle,
          isActive: this.model.isActive,
          records: records
        });
        this.send('close');
      },
      onSelectRecords: function onSelectRecords(records) {
        var _this = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this.set('selectedRecords', records);
        });
      }
    }
  });
  _exports.default = _default;
});