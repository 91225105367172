define("experience-studio/components/confirmation-modal-dialog-v2", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    userPreferences: Ember.inject.service(),
    classNames: ['confirmation-modal-dialog'],
    type: '',
    blockModal: false,
    store: Ember.inject.service(),
    relatedEntities: Ember.Object.create({}),
    isPushDropdownOpen: false,
    isExpDropdownOpen: false,
    isLoading: false,
    ruleHasActiveEntities: false,
    // FUNCTIONS -----------------------------------------------------------------
    getPushMessagesWithTheSameRule: function getPushMessagesWithTheSameRule(selectedRule) {
      var adapter = this.store.adapterFor('push-message');
      return adapter.getPushMessagesByRuleId(selectedRule);
    },
    getExperiencesWithTheSameRule: function getExperiencesWithTheSameRule(selectedRule) {
      var adapter = this.store.adapterFor('experience');
      return adapter.getExperiencesByRuleId(selectedRule);
    },
    getRelatedEntities: function getRelatedEntities(rule) {
      var _this = this;
      var promises = Ember.Object.create({
        pushMessages: this.getPushMessagesWithTheSameRule(rule),
        experiences: this.getExperiencesWithTheSameRule(rule)
      });
      return Ember.RSVP.hashSettled(promises).then(function (hash) {
        if (hash.pushMessages.state === 'fulfilled' && hash.experiences.state === 'fulfilled') {
          _this.set("relatedEntities", {
            pushMessages: hash.pushMessages.value.data,
            experiences: hash.experiences.value.data
          });
          if (hash.pushMessages.value.data) {
            hash.pushMessages.value.data.forEach(function (push) {
              if (push.isActive) {
                _this.set('ruleHasActiveEntities', true);
              }
            });
          }
          if (hash.experiences.value.data) {
            hash.experiences.value.data.forEach(function (experience) {
              if (experience.isActive) {
                _this.set('ruleHasActiveEntities', true);
              }
            });
          }
        }
      });
    },
    // COMPUTED -----------------------------------------------------------------
    htmlSafeSecondaryMessage: Ember.computed('model.secondaryMessage', function () {
      return Ember.String.htmlSafe(this.model.secondaryMessage);
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      if (this.model.modelName === 'Rule') {
        this.set('isLoading', true);
        this.getRelatedEntities(this.model.record).then(function () {
          _this2.set('isLoading', false);
        });
      }
    },
    // ACTIONS -----------------------------------------------------------------
    actions: {
      continue: function _continue() {
        if (this.model.primaryAction) {
          this.performAction(this.model.primaryAction);
          this.send('close');
        }
      },
      toggleOptIn: function toggleOptIn() {
        var preference = this.userPreferences.getPreference('batchActionBarDeselectAll');
        preference.toggleProperty('value');
        preference.save();
        this.set('blockModal', !this.blockModal);
      },
      toggleDropdown: function toggleDropdown(option) {
        if (option === 'experience') {
          this.set('isExpDropdownOpen', !this.isExpDropdownOpen);
        }
        if (option === 'push') {
          this.set('isPushDropdownOpen', !this.isPushDropdownOpen);
        }
      }
    }
  });
  _exports.default = _default;
});