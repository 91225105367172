define("experience-studio/components/experience-manage-groups-modal", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['experience-manage-groups-modal-dialog'],
    errorMessage: '',
    isSaving: false,
    searchKeyword: '',
    selectedGroups: Ember.computed.filterBy('model.groups', 'isAssociatedToExperience', true),
    utility: Ember.inject.service(),
    modelSham: Ember.computed('model.groups.[]', 'searchKeyword', function () {
      var searchKeyword = this.searchKeyword;
      var model = this.get('model.groups');
      if (!Ember.isEmpty(model)) {
        model = model.toArray();
        if (searchKeyword) {
          model = model.filter(function (item) {
            return item.get('name').toLowerCase().indexOf(searchKeyword) >= 0;
          });
        }
      }
      return model;
    }),
    didInsertElement: function didInsertElement() {
      var experienceId = this.get('model.record.id') || this.get('model.experience.id');
      var groups = this.get('model.groups');
      if (!Ember.isEmpty(experienceId) && !Ember.isEmpty(groups)) {
        groups.forEach(function (group) {
          return group.set('isAssociatedToExperience', group.get('experienceIds').includes(experienceId));
        });
      }
      this._super.apply(this, arguments);
    },
    willCloseModal: function willCloseModal() {
      var groups = this.get('model.groups');
      if (!Ember.isEmpty(groups)) {
        groups.setEach('isAssociatedToExperience', false);
      }
      this._super.apply(this, arguments);
    },
    actions: {
      close: function close() {
        if (!this.isSaving) {
          this._super.apply(this, arguments);
        }
      },
      save: function save(modifiedGroups) {
        var promises = [];
        var self = this;
        this.setProperties({
          errorMessage: '',
          isSaving: true
        });
        modifiedGroups.forEach(function (group) {
          return promises.pushObject(group.save());
        });
        Ember.RSVP.allSettled(promises).then(function (responses) {
          var rejectedItems = responses.filterBy('state', 'rejected');
          if (!Ember.isEmpty(rejectedItems)) {
            var reason = rejectedItems.objectAt(0).reason;
            self.setProperties({
              errorMessage: self.get('utility').parseError(reason),
              isSaving: false
            });
          } else {
            self.set('isSaving', false);
            var model = self.get('model.record') || self.get('model.experience');
            model.reload().then(function () {
              self.send('close');
            });
          }
        });
      },
      validate: function validate() {
        var experienceId = '';
        var groups = this.model.groups;
        var modifiedGroups = [];
        if (this.model && this.model.record && this.model.record.id) {
          experienceId = this.model.record.id;
        } else if (this.model && this.model.experience && this.model.experience.id) {
          experienceId = this.model.experience.id;
        }
        if (!Ember.isEmpty(experienceId) && !Ember.isEmpty(groups)) {
          groups.forEach(function (group) {
            var experienceIdsInGroup = group.experienceIds;
            var isAssociatedToExperience = group.isAssociatedToExperience;
            if (isAssociatedToExperience && !experienceIdsInGroup.includes(experienceId)) {
              experienceIdsInGroup.pushObject(experienceId);
              modifiedGroups.pushObject(group);
            } else if (!isAssociatedToExperience && experienceIdsInGroup.includes(experienceId)) {
              experienceIdsInGroup.removeObject(experienceId);
              modifiedGroups.pushObject(group);
            }

            // Do not leak group's isAssociatedToExperience property outside
            delete group['isAssociatedToExperience'];
          });
          if (!Ember.isEmpty(modifiedGroups)) {
            this.send('save', modifiedGroups);
          } else {
            this.send('close');
          }
        }
      }
    }
  });
  _exports.default = _default;
});