define("experience-studio/templates/components/context-rule-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w8lq4QVH",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-group selected-model\"],[12],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"model\"],[[35,7]]]]],[2,\"\\n    \"],[11,\"div\"],[24,0,\"cancel-selected\"],[4,[38,6],[[32,0],\"deselectRule\"],null],[12],[2,\"\\n      \"],[10,\"div\"],[12],[1,[30,[36,9],[\"times-circle\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"btn btn-selector \",[30,[36,5],[[35,4],\"active\"],null]]]],[4,[38,6],[[32,0],\"showRuleSelectModal\"],null],[12],[2,\"\\n      Select Context Rule\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"modal-wrapper\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"isOpen\",\"rules\",\"ruleProperties\",\"handleSearch\",\"loadModels\",\"selectRule\",\"onlyProduction\"],[[35,2],[35,1],[35,0],\"handleSearch\",\"loadModels\",\"selectRule\",true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"ruleProperties\",\"rules\",\"isRuleSelectModalOpen\",\"rule-select-modal-dialog\",\"isActive\",\"if\",\"action\",\"selectedRuleSham\",\"model-list-context-rule-v2\",\"svg-jar\",\"selectedRule\"]}",
    "moduleName": "experience-studio/templates/components/context-rule-selector.hbs"
  });
  _exports.default = _default;
});