define("experience-studio/transforms/epoch", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var value;
      if (serialized && window.moment) {
        value = window.moment.unix(serialized).toDate();
      } else {
        value = window.moment.unix(0).toDate();
      }
      return value;
    },
    serialize: function serialize(deserialized) {
      var value;
      if (deserialized && window.moment) {
        value = window.moment(deserialized).unix();
      }
      return value;
    }
  });
  _exports.default = _default;
});