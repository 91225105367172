define("experience-studio/templates/components/content-form-editor-draggable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C/iamb31",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"name\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[35,0,[\"icon\"]]],null]],[2,\"\\n    \"],[10,\"span\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"svg-jar\"]}",
    "moduleName": "experience-studio/templates/components/content-form-editor-draggable-item.hbs"
  });
  _exports.default = _default;
});