define("experience-studio/components/add-location", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    autocomplete: null,
    classNames: ['add-location'],
    detectedLocation: null,
    errorMessage: '',
    isDetectingLocation: true,
    isInjectingScript: true,
    isMapLoading: true,
    isPolygonInCenter: true,
    isPolygonInViewport: false,
    locationLabels: null,
    marker: null,
    polygon: null,
    polygonOptions: null,
    previousLocationLabel: '',
    record: null,
    searchKeywordAddress: '',
    utility: Ember.inject.service(),
    store: Ember.inject.service(),
    // OBSERVERS -----------------------------------------------------------------
    observeSetupGoogleMap: Ember.observer('isDetectingLocation', 'isInjectingScript', function () {
      if (!this.isDetectingLocation && !this.isInjectingScript) {
        this.setupGoogleMap();
      }
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.set('polygonOptions', {
        strokeColor: '#29BEEE',
        strokeOpacity: 0.9,
        fillColor: '#29BEEE',
        fillOpacity: 0.35,
        editable: true,
        draggable: true
      });
      this.set('locationLabels', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.record.id) {
        this.set('isDetectingLocation', false);
      } else {
        this.detectLocation();
      }
      this.injectGoogleMapsScript();
    },
    // FUNCTIONS -----------------------------------------------------------------
    getPolygonBounds: function getPolygonBounds(polygon) {
      var bounds = new window.google.maps.LatLngBounds();
      polygon.getPath().forEach(function (coordinates) {
        return bounds.extend(coordinates);
      });
      return bounds;
    },
    detectLocation: function detectLocation() {
      var _this = this;
      if (window.navigator && window.navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (geoposition) {
          _this.set('detectedLocation', Ember.Object.create({
            lat: geoposition.coords.latitude,
            lng: geoposition.coords.longitude
          }));
          _this.set('isDetectingLocation', false);
        }, function () {
          _this.set('isDetectingLocation', false);
        }, {
          enableHighAccuracy: false,
          maximumAge: 1000 * 60 * 15,
          timeout: 5000
        });
      } else {
        this.set('isDetectingLocation', false);
      }
    },
    didChangePlace: function didChangePlace() {
      var place = this.autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        this.map.setCenter(place.geometry.location);
        if (place.types.toString().indexOf('political') >= 0 || place.types.toString().indexOf('establishment') >= 0) {
          if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
            this.map.setZoom(this.map.getZoom() - 1);
          } else {
            this.map.setZoom(11);
          }
        } else {
          this.map.setZoom(16);
        }
      }
    },
    didChangeMapBounds: function didChangeMapBounds() {
      var isPolygonInCenter = true;
      var isPolygonInViewport = false;
      if (this.polygon) {
        var mapCenter = this.map.getCenter();
        var polygonCenter = this.getPolygonBounds(this.polygon).getCenter();
        if (this.map.getBounds().contains(polygonCenter)) {
          isPolygonInViewport = true;
        }
        var mapCenterLat = mapCenter.lat().toFixed(4);
        var mapCenterLng = mapCenter.lng().toFixed(4);
        var polygonCenterLat = polygonCenter.lat().toFixed(4);
        var polygonCenterLng = polygonCenter.lng().toFixed(4);
        if (window.Math.abs(mapCenterLat - polygonCenterLat) != 0 && window.Math.abs(mapCenterLng - polygonCenterLng) != 0) {
          isPolygonInCenter = false;
        }
      }
      this.setProperties({
        isPolygonInCenter: isPolygonInCenter,
        isPolygonInViewport: isPolygonInViewport
      });
    },
    generatePolygon: function generatePolygon(coordinate) {
      var computeOffset = window.google.maps.geometry.spherical.computeOffset;
      var distance = 150;
      var paths = [];
      paths.pushObject(computeOffset(coordinate, distance, -45));
      paths.pushObject(computeOffset(coordinate, distance, 45));
      paths.pushObject(computeOffset(coordinate, distance, 135));
      paths.pushObject(computeOffset(coordinate, distance, -135));
      return new window.google.maps.Polygon({
        paths: paths
      });
    },
    injectGoogleMapsScript: function injectGoogleMapsScript() {
      var _this2 = this;
      var successCallback = function successCallback() {
        _this2.setProperties({
          errorMessage: '',
          isInjectingScript: false
        });
      };
      if (!window.google || !window.google.maps) {
        var url = "//maps.googleapis.com/maps/api/js?".concat(_jquery.default.param({
          key: _environment.default.APP.googleMapKey,
          libraries: 'drawing,geometry,places'
        }));
        _jquery.default.getScript(url).done(Ember.run.bind(this, successCallback)).fail(function (response) {
          _this2.setProperties({
            errorMessage: _this2.utility.parseError(response),
            isInjectingScript: false
          });
        });
      } else {
        successCallback();
      }
    },
    moveMapToShowPolygon: function moveMapToShowPolygon() {
      this.map.fitBounds(this.getPolygonBounds(this.polygon));
      this.map.setZoom(this.map.getZoom() - 2);
    },
    movePolygon: function movePolygon(polygon, coordinates) {
      var geometry = window.google.maps.geometry.spherical;
      var paths = polygon.getPaths();
      var polygonCoordinate = this.getPolygonBounds(polygon).getCenter();
      var pathsLength = paths.length;
      while (pathsLength--) {
        var path = paths.getAt(pathsLength);
        var pathLength = path.length;
        while (pathLength--) {
          var vertexCoordinates = path.getAt(pathLength);
          vertexCoordinates = geometry.computeOffset(coordinates, geometry.computeDistanceBetween(polygonCoordinate, vertexCoordinates), geometry.computeHeading(polygonCoordinate, vertexCoordinates));
          path.setAt(pathLength, vertexCoordinates);
        }
      }
      window.google.maps.event.trigger(polygon, 'dragend');
      this.moveMapToShowPolygon();
    },
    setPolygon: function setPolygon(polygon) {
      var _this3 = this;
      this.setProperties({
        polygon: polygon,
        searchKeywordAddress: ''
      });
      polygon.setOptions(this.polygonOptions);
      polygon.setMap(this.map);
      polygon.addListener('dragend', Ember.run.bind(this, function () {
        _this3.updateAddress('record.address', _this3.polygon);
      }));
      polygon.addListener('rightclick', Ember.run.bind(this, function (event) {
        var path = _this3.polygon.getPath();
        if (event.vertex && path.length > 4) {
          _this3.polygon.getPath().removeAt(event.vertex);
        } else if (!event.vertex) {
          window.google.maps.event.clearInstanceListeners(_this3.polygon);
          _this3.polygon.setMap(null);
          _this3.setProperties({
            errorMessage: '',
            polygon: null
          });
          _this3.record.setProperties({
            address: '',
            shape: []
          });
          _this3.removePreviousLabel();
        }
      }));
      this.moveMapToShowPolygon();
      window.google.maps.event.trigger(polygon, 'dragend');
    },
    setupAutocomplete: function setupAutocomplete() {
      this.set('isMapLoading', true);
      var autocomplete = new window.google.maps.places.Autocomplete((0, _jquery.default)('.map-container .search-container input').get(0));
      autocomplete.addListener('place_changed', Ember.run.bind(this, this.didChangePlace));
      this.set('autocomplete', autocomplete);
      this.set('isMapLoading', false);
    },
    removePreviousLabel: function removePreviousLabel() {
      if (this.previousLocationLabel) {
        var previousLabels = this.selectedLabels.filterBy('value', this.previousLocationLabel);
        if (previousLabels.length) {
          this.selectedLabels.removeObject(previousLabels[0]);
        }
        this.record.labels.removeObject(this.previousLocationLabel);
        this.locationLabels.removeObject(this.previousLocationLabel);
      }
    },
    setupGoogleMap: function setupGoogleMap() {
      var _this4 = this;
      var mapCenter = {
        lat: -37.111112,
        lng: -12.28834
      };
      var mapZoom = 11;
      if (!Ember.isEmpty(this.record.shape)) {
        this.set('polygon', new window.google.maps.Polygon({
          paths: this.record.shape
        }));
        mapCenter = this.getPolygonBounds(this.polygon).getCenter();
        mapZoom = 14;
      } else if (this.detectedLocation) {
        mapCenter = this.detectedLocation;
        mapZoom = 12;
      }
      this.set('map', new window.google.maps.Map((0, _jquery.default)('.map').get(0), {
        center: mapCenter,
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true,
        zoom: mapZoom
      }));
      if (this.polygon) {
        this.setPolygon(this.polygon);
      }
      window.google.maps.event.addListenerOnce(this.map, 'tilesloaded', function () {
        _this4.set('isMapLoading', false);
      });
      window.google.maps.event.addListener(this.map, 'bounds_changed', Ember.run.bind(this, this.didChangeMapBounds));
      this.setupAutocomplete();
    },
    updateAddress: function updateAddress(propertyName, polygon) {
      var _this5 = this;
      new window.google.maps.Geocoder().geocode({
        location: this.getPolygonBounds(polygon).getCenter()
      }, function (results, status) {
        if (status.toUpperCase() === 'OK') {
          var place = results.objectAt(0);
          var country = '';
          var province = '';
          var city = '';
          if (place.address_components) {
            place.address_components.forEach(function (item) {
              var types = item.types.toString();
              if (types.indexOf('locality') >= 0) {
                city = item.long_name;
              } else if (types.indexOf('administrative_area_level_1') >= 0) {
                province = item.long_name;
              } else if (types.indexOf('country') >= 0) {
                country = item.long_name;
              }
              if (!province && types.indexOf('administrative_area') >= 0) {
                province = item.long_name;
              }
            });
            _this5.updateLabels(city, province, country);
          }
          if (place.formatted_address) {
            _this5.set(propertyName, place.formatted_address);
          }
        }
      });
    },
    updateLabels: function updateLabels(city, province, country) {
      var label = [city, province, country].filter(function (i) {
        return !!i;
      }).map(function (j) {
        return j.normalize("NFD").replace(/[\u0300-\u036f]/ig, '').replace(/[^A-Z0-9]+/ig, '-');
      }).join('-').toString().toLowerCase();
      this.removePreviousLabel();
      this.locationLabels.pushObject(label);
      this.set('previousLocationLabel', label);
    },
    actions: {
      cancel: function cancel() {
        this.record.rollbackAttributes();
        this.record.reload();
        this.cancel();
      },
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      createShape: function createShape() {
        if (!this.polygon) {
          this.setPolygon(this.generatePolygon(this.map.getCenter()));
        }
      },
      fetchLabels: function fetchLabels(promise, query) {
        if (this.fetchLabels) {
          this.fetchLabels(promise, query);
        }
      },
      findShape: function findShape() {
        this.moveMapToShowPolygon();
      },
      moveShapeToCenter: function moveShapeToCenter() {
        this.movePolygon(this.polygon, this.map.getCenter());
        this.set('searchKeywordAddress', '');
      },
      resetShape: function resetShape() {
        if (this.polygon) {
          window.google.maps.event.clearInstanceListeners(this.polygon);
          this.polygon.setMap(null);
          this.setProperties({
            errorMessage: '',
            polygon: null,
            searchKeywordAddress: ''
          });
          this.record.setProperties({
            address: '',
            shape: []
          });
          this.removePreviousLabel();
          this.setPolygon(this.generatePolygon(this.getPolygonBounds(this.polygon).getCenter()));
        }
      },
      save: function save() {
        var _this6 = this;
        var isNew = this.record.isNew;
        this.record.save().then(function () {
          if (_this6.saveLocation) {
            _this6.saveLocation(_this6.record, isNew);
          }
        }).catch(function (response) {
          var message = _this6.utility.parseError(response);
          if (message.toLowerCase().indexOf('loop is not valid') >= 0) {
            message = 'The shape contains overlapping edges. Reset shape to fix it.';
          }
          _this6.set('errorMessage', message);
        });
      },
      toggleHowTo: function toggleHowTo() {
        this.toggleProperty('isHowToVisible');
      },
      validate: function validate() {
        var record = this.record;
        if (this.polygon) {
          record.set('shape', this.polygon.getPath().getArray().map(function (coordinate) {
            return Ember.Object.create(coordinate.toJSON());
          }));
          if (record.shape[0].lat !== record.shape[record.shape.length - 1].lat && record.shape[0].lng !== record.shape[record.shape.length - 1].lng) {
            record.shape.pushObject(record.shape[0]);
          }
        }
        if (this.selectedLabels) {
          this.record.set('labels', this.selectedLabels.map(function (i) {
            return i.value;
          }));
        }
        if (!record.name) {
          this.set('errorMessage', 'Enter name');
        } else if (record.shape && record.shape.length <= 0) {
          this.set('errorMessage', 'Create shape');
        } else {
          this.set('errorMessage', '');
          this.send('save');
        }
      }
    }
  });
  _exports.default = _default;
});