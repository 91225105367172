define("experience-studio/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    creatorId: _emberData.default.attr('string'),
    creatorFullName: _emberData.default.attr('string'),
    labels: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    radius: _emberData.default.attr('number'),
    shape: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    createdAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch')
  });
  _exports.default = _default;
});