define("experience-studio/serializers/plugin", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      var values = item.attributes.values || {};
      var valuesEmber = Ember.Object.create({});
      for (var propertyName in values) {
        if (values.hasOwnProperty(propertyName)) {
          var value = Ember.Object.create(values[propertyName]);
          if (!value.name) {
            var name = value.name;
            name = value.uid.split('.').pop();
            name = name.replace(/([A-Z])/g, ' $1').trim();
            name = name.substring(0, 1).toUpperCase() + name.substring(1, name.length);
            value.name = name;
          }
          if (value.isTimeContext) {
            item.attributes.hasDateTimeAttribute = true;
          }
          valuesEmber.set(propertyName, value);
        }
      }
      if (item.attributes.tenantConfig && !Ember.isNone(item.attributes.tenantConfig.isEnabled)) {
        item.attributes.isActive = item.attributes.tenantConfig.isEnabled;
      }
      item.attributes.attributes = valuesEmber;
      return item;
    }
  });
  _exports.default = _default;
});