define("experience-studio/components/selected-locations-map", ["exports", "jquery", "experience-studio/config/environment", "experience-studio/helpers/location"], function (_exports, _jquery, _environment, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['selected-locations-map'],
    classNameBindings: ['isSmallFormFactor'],
    model: null,
    store: Ember.inject.service(),
    creator: '',
    isViewSelectedMaps: true,
    mapImageSelectedUrl: Ember.computed('model.{isFulfilled}', 'locations.[]', function () {
      var paths = [];
      if (this.locations.length) {
        this.locations.forEach(function (location) {
          var shape = location.get('shape');
          var coordinates = [];
          if (shape) {
            shape.forEach(function (coordinate) {
              return coordinates.push("".concat(coordinate.lat, ",").concat(coordinate.lng));
            });
            paths.push("&path=color:0x29BFEDFF|weight:2|fillcolor:0x29BFED33|".concat(coordinates.join('|')));
          }
        });
      }
      var endpoint = 'maps/api/staticmap';
      var host = 'https://maps.googleapis.com';
      var query = {
        key: _environment.default.APP.googleMapKey,
        size: '550x300'
      };
      return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query)).concat(paths.join(''));
    }),
    mapImageSelectedCircleUrl: Ember.computed('model.{isFulfilled}', 'locations.[]', function () {
      var shape = null;
      var paths = [];
      var approxAreaRadius = 3000; //metres
      if (this.locations.length) {
        this.locations.forEach(function (location) {
          shape = location.get('shape');
          var shapeCenter = (0, _location.calculateCenter)(shape);
          var lat = shapeCenter.lat;
          var lng = shapeCenter.lng;
          var radius = shapeCenter.radius + approxAreaRadius;
          paths.push("&path=color:0x29BFEDFF|weight:0|fillcolor:0x29BFED63".concat((0, _location.calculateCircleData)(lat, lng, radius)));
        });
      }
      var endpoint = 'maps/api/staticmap';
      var host = 'https://maps.googleapis.com';
      var query = {
        key: _environment.default.APP.googleMapKey,
        size: '550x300'
      };
      return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query)).concat(paths.join(''));
    }),
    actions: {
      viewInfo: function viewInfo() {
        this.set('isViewSelectedMaps', !this.isViewSelectedMaps);
      }
    }
  });
  _exports.default = _default;
});