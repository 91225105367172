define("experience-studio/serializers/content-data", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      return data.values;
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      var unwantedValues = ['_id', 'id'];
      var values = Ember.Object.create();
      item.id = item.attributes.id || item.attributes._id;
      window.Object.keys(item.attributes).map(function (key) {
        if (!unwantedValues.contains(key)) {
          values.set(key, item.attributes[key]);
        }
      });
      if (window.Object.keys(values).length) {
        item.attributes.values = values;
      }
      return item;
    }
  });
  _exports.default = _default;
});