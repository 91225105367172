define("experience-studio/templates/components/unauthenticated-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YhkV2Sb1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/flybits_logo.png\"],[14,0,\"logo\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,6,\"https://flybits.com\"],[12],[2,\"What is Flybits?\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "experience-studio/templates/components/unauthenticated-layout.hbs"
  });
  _exports.default = _default;
});