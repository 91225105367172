define("experience-studio/templates/components/custom-field--localized-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "d3uuXjwW",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[1,[30,[36,6],null,[[\"isRequired\",\"isTextarea\",\"label\",\"languages\",\"plugins\",\"updateDynamicAttribute\",\"dynamicAtrributeValue\",\"field\",\"model\",\"keyUp\",\"lableTooltip\"],[[35,0,[\"isRequired\"]],[35,0,[\"isTextarea\"]],[35,5],[35,4],[35,3],[30,[36,2],[[32,0],\"updateDynamicAttribute\"],null],[35,1],[35,0],[35,0,[\"input\"]],\"onValue\",\"Notification and/or content will be displayed in default language if customer device language is not listed here.\"]]]],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,0,[\"errorMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"error-message text-danger\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"field\",\"dynamicAtrributeValue\",\"action\",\"plugins\",\"languages\",\"name\",\"localized-input\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--localized-input.hbs"
  });
  _exports.default = _default;
});