define("experience-studio/components/main-sidebar-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['main-sidebar-logo'],
    numberOfClicks: 25,
    openUserDropdownMenu: function openUserDropdownMenu() {
      var _this = this;
      this.set('numberOfClicks', 25);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var timeoutId = setTimeout(function () {
          clearTimeout(timeoutId);
          var dropup = _this.$().parent().parent().find('.user-container .title .dropup');
          dropup.find('.dropdown-toggle').click();
          dropup.find('.dropdown-item.settings').addClass('opt-in');
          var timeoutIdToRemoveClass = setTimeout(function () {
            clearTimeout(timeoutIdToRemoveClass);
            dropup.find('.dropdown-item.settings').removeClass('opt-in');
          }, 1000);
        }, 0);
      });
    },
    click: function click() {
      var _this2 = this;
      var preference = this.userPreferencePagePreference;
      this.set('numberOfClicks', this.numberOfClicks - 1);
      if (!this.isSettingsDropdownItemVisible && preference) {
        if (this.numberOfClicks <= 5 && this.numberOfClicks > 0) {
          this.showNotification({
            type: 'confirm',
            message: "You are ".concat(this.numberOfClicks, " step away from unlocking Preferences page.")
          });
        }
        if (this.numberOfClicks <= 0) {
          preference.setProperties({
            hasOptIn: true,
            value: true
          });
          preference.save().then(function () {
            _this2.showNotification({
              type: 'confirm',
              message: 'Congrats! You can now access Preferences page from user\'s dropdown menu.'
            });
            _this2.openUserDropdownMenu();
          });
        }
      } else if (this.numberOfClicks <= 5) {
        this.openUserDropdownMenu();
      }
    }
  });
  _exports.default = _default;
});