define("experience-studio/mixins/model-actions", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Mixin.create({
    utility: Ember.inject.service(),
    checkIsScheduleExpired: false,
    actions: {
      aggregateModel: function aggregateModel(modelName, modelVar) {
        var _this = this;
        var store = this.store;
        var params = {};
        if (this.controller.data) {
          params = this.utility.atob(this.controller.data);
        }
        var sortOrder = params.sortOrder || this.controller.sortOrder;
        var sortBy = params.sortBy || this.controller.sortBy;
        var recordsPerPage = params.recordsPerPage || this.controller.recordsPerPage;
        var groupId = params.groupId || this.controller.groupId;
        var page = params.currentPage || this.controller.page || 1;
        var search = params.searchKeyword || this.controller.searchKeyword;
        var isActive = params.isActive;
        var createdAtFrom = params.createdAtFrom;
        var createdAtTo = params.createdAtTo;
        var labels = params.labels;
        this.controller.set('isLoading', true);
        var modelPromise = store.query(modelName, {
          page: page,
          recordsPerPage: recordsPerPage,
          sortorder: sortOrder,
          sortby: sortBy,
          groupId: groupId,
          search: search,
          visibility: groupId ? 'all' : '',
          isActive: isActive,
          createdAtFrom: createdAtFrom,
          createdAtTo: createdAtTo,
          labels: labels
        });
        modelPromise.catch(function () {
          return null;
        }).then(function () {
          _this.controller.set('isLoading', false);
        });
        this.controller.set("model.".concat(modelVar), modelPromise);
      },
      confirmDeleteRecord: function confirmDeleteRecord(record) {
        var message;
        var modelName;
        message = 'Are you sure you want to delete ';
        modelName = Ember.String.classify(record.constructor.modelName);
        modelName = modelName.replace(/([A-Z])/g, ' $1').trim();
        if (modelName.toLowerCase().indexOf('content') >= 0) {
          modelName = 'Content';
        }
        message += "\"".concat(record.get('name'), "\" ").concat(modelName, "?");
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'deleteRecord',
          primaryMessage: message,
          record: record,
          secondaryMessage: 'You can\'t undo this action.',
          title: 'Confirm Action'
        }));
      },
      confirmDeleteAction: function confirmDeleteAction(record) {
        var container = null;
        var message;
        var modelName;
        var numberOfAffectedRules = 0;
        var p = null;
        var ul = null;
        message = 'Are you sure you want to delete ';
        modelName = Ember.String.classify(record.constructor.modelName);
        modelName = modelName.replace(/([A-Z])/g, ' $1').trim();
        if (modelName.toLowerCase().indexOf('content') >= 0) {
          modelName = 'Content';
        }
        message += "\"".concat(record.get('name'), "\" ").concat(modelName.toLowerCase());
        ul = document.createElement('ul');
        if (modelName.toLowerCase() === 'rule') {
          if (arguments[1] && _typeof(arguments[1]) === 'object' && !Ember.isEmpty(arguments[1].data)) {
            numberOfAffectedRules = arguments[1].data.length;
            arguments[1].data.forEach(function (item) {
              var li = document.createElement('li');
              li.innerHTML = item.name;
              ul.appendChild(li);
            });
            if (arguments[1].data.length > 1) {
              message += "'?  Note that the following ".concat(numberOfAffectedRules, " ").concat((0, _emberInflector.pluralize)(modelName).toLowerCase(), " are dependents and WILL ALSO BE DELETED");
            } else {
              message += "'?  Note that the following ".concat(numberOfAffectedRules, " ").concat(modelName.toLowerCase(), " is a dependent and WILL ALSO BE DELETED");
            }
          }
          message += '.';
        } else {
          message += '?';
        }
        p = document.createElement('p');
        p.innerHTML = 'You cannot undo this action.';
        container = document.createElement('p');
        container.appendChild(ul);
        container.appendChild(p);
        container.setAttribute('style', 'max-height: 320px; overflow: auto;');
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'deleteRecord',
          primaryMessage: message,
          record: record,
          secondaryMessage: container,
          title: 'Confirm Action'
        }));
      },
      confirmToggleActivation: function confirmToggleActivation(record) {
        var modelName = record.constructor.modelName;
        var activationVerb = record.get('isActive') ? 'deactivate' : 'activate';
        var message = "Are you sure you want to ".concat(activationVerb, " ").concat(modelName, ": ").concat(record.get('name'), "?");
        if (modelName === 'push-message') {
          message = message.replace('push-message', 'Push Notification');
        }
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'toggleActivation',
          primaryMessage: message,
          record: record,
          secondaryMessage: '',
          title: 'Confirm Action'
        }));
      },
      confirmUndraftAction: function confirmUndraftAction(record) {
        var message = '';
        message += 'Are you sure you want to activate ';
        message += "\"".concat(record.get('name'), "\"") + " Context Rule?";
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'undraft',
          primaryMessage: message,
          records: [record],
          secondaryMessage: 'Activated Rule cannot be edited nor turned back into draft.',
          title: 'Confirm Action'
        }));
      },
      createRecord: function createRecord(type) {
        var routeName = {
          'experience': 'experiences.create',
          'content-instance': 'content-hub.content-templates',
          'context-rule': 'context-hub.create',
          'push-message-v2': 'communications-hub.push-messages-v2.create'
        }[type];
        this.transitionTo(routeName, {
          queryParams: {
            search: null,
            sortBy: null,
            sortOrder: null,
            page: null,
            recordsPerPage: null
          }
        });
      },
      deleteRecord: function deleteRecord(model) {
        var record = model.record;
        var self = this;
        record.destroyRecord().then(function () {
          self.send('hideModal');
          if (self.refreshModels) {
            self.refreshModels(record);
          }
          Ember.run.scheduleOnce('afterRender', self, function () {
            self.send('showNotification', {
              type: 'confirm',
              message: 'Deleted successfully!'
            });
          });
          self.send('deselectModelListItems');
          if (record.constructor.modelName.toLowerCase() === 'rule') {
            var id = record.get('id');
            self.store.findAll('rule').then(function (rules) {
              rules.forEach(function (item) {
                if (item.get('affectedBy') && !Ember.isEmpty(item.get('affectedBy'))) {
                  if (item.get('affectedBy').includes(id)) {
                    self.store.unloadRecord(item);
                  }
                }
              });
            });
            self.store.unloadAll('experience');
          }
        }).catch(function (response) {
          var message = self.get('utility').parseError(response);
          self.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: message,
            title: 'Error',
            type: 'danger'
          }));
        });
      },
      deselectModelListItems: function deselectModelListItems() {
        var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var selectedEntitiesPath = params.selectedEntitiesPath || 'selectedEntities';
        var selectedEntities = this.controller.get(selectedEntitiesPath);
        if (selectedEntities) {
          selectedEntities.setEach('isSelected', false);
        }
        this.controller.set(selectedEntitiesPath, []);
      },
      duplicate: function duplicate(record, routeName) {
        if (record) {
          var data = record.toJSON();
          if (routeName === 'context-hub') {
            if (data.body.hasComplexHistoricalPreview) {
              this.send('showModal', Ember.Object.create({
                modalName: 'message-modal-dialog',
                primaryMessage: "The rule \"".concat(record.get('name'), "\" contains custom expression."),
                secondaryMessage: 'You cannot duplicate this rule.',
                title: 'Invalid Action'
              }));
              return;
            }
          }
          delete data.composition;
          this.transitionTo("".concat(routeName, ".create"), {
            queryParams: {
              data: this.utility.btoa(data)
            }
          });
        }
      },
      editRecord: function editRecord(record) {
        var modelName = record.constructor.modelName;
        var routeName = {
          'content-blueprint': 'content-hub.blueprint-instance',
          'content-instance': 'content-hub.content-instance',
          'experience': 'experiences.edit',
          'rule': 'context-hub.rule.edit'
        }[modelName];
        this.transitionTo(routeName, record.get('id'), {
          queryParams: {
            search: null,
            sortBy: null,
            sortOrder: null,
            page: null,
            recordsPerPage: null
          }
        });
      },
      getAffectedRules: function getAffectedRules(model) {
        var adapter = this.store.adapterFor(model.constructor.modelName);
        var affectedRules = adapter.getAffectedRules(this.store, model.constructor, model);
        var controller = this.controller;
        var self = this;
        controller.set('isLoadingAffectedRules', true);
        affectedRules.then(function (response) {
          controller.set('isLoadingAffectedRules', false);
          self.send('confirmDeleteAction', model, response);
        }).catch(function (response) {
          controller.set('isLoadingAffectedRules', false);
          self.send('showNotification', Ember.Object.create({
            type: 'alert',
            message: self.get('utility').parseError(response)
          }));
        });
      },
      handleAction: function handleAction(action, model, params) {
        var modelName;
        if (model) {
          modelName = model.constructor.modelName;
        }
        switch (action) {
          case 'activation':
            this.send('confirmToggleActivation', model);
            break;
          case 'aggregateModel':
            this.send('aggregateModel', params.modelName, params.modelVar, params.limit);
            break;
          case 'create':
            this.send('createRecord', params.type);
            break;
          case 'delete':
            modelName = model.constructor.modelName;
            if (modelName.toLowerCase() === 'rule') {
              this.send('getAffectedRules', model);
            } else {
              this.send('confirmDeleteRecord', model);
            }
            break;
          case 'deselect':
            this.send('deselectModelListItems');
            break;
          case 'duplicate':
            this.send('duplicate', model, params.routeName);
            break;
          case 'edit':
            this.send('editRecord', model, params.queryParams);
            break;
          case 'priority':
            this.send('showManagePrioritizationModal', params.action, model, params.parent);
            break;
          case 'refresh':
            this.send('refresh');
            break;
          case 'reload':
            this.send('reload', model);
            break;
          case 'select':
            this.send('selectModelListItems', model, params.selectedEntitiesPath);
            break;
          case 'show-info':
            this.send('showInfo', model, params);
            break;
          case 'simulate':
            this.send('showSimulationModal', model);
            break;
          case 'undraft':
            this.send('confirmUndraftAction', model);
        }
      },
      hideSimulationModal: function hideSimulationModal() {
        this.controller.set('simulationModalData', null);
      },
      reload: function reload(record) {
        var _this2 = this;
        record.reload().then(function () {
          _this2.send('showNotification', Ember.Object.create({
            type: 'confirm',
            message: 'Reloaded successfully'
          }));
        }).catch(function (response) {
          _this2.send('showNotification', Ember.Object.create({
            type: 'alert',
            message: _this2.utility.parseError(response)
          }));
        });
      },
      search: function search(options) {
        var controller = this.controller;
        controller.set('searchKeyword', options.keyword);
      },
      selectModelListItems: function selectModelListItems(model, selectedEntitiesPath) {
        var record = model;
        selectedEntitiesPath = selectedEntitiesPath || 'selectedEntities';

        // If is multi select an array is sent
        if (Ember.isArray(record)) {
          // Only 1 item in the Array
          if (record.toArray) {
            record = record.toArray();
          }
          // Select all
          if (record.length) {
            if (record.isAny('isSelected')) {
              record.setEach('isSelected', false);
              this.controller.get(selectedEntitiesPath).removeObjects(record);
            } else {
              if (this.controller.get(selectedEntitiesPath).isAny('visibility', 'system')) {
                var templatedExperience = this.controller.get(selectedEntitiesPath).findBy('visibility', 'system');
                templatedExperience.set('isSelected', false);
                this.controller.set(selectedEntitiesPath, []);
              }
              record.setEach('isSelected', true);
              this.controller.get(selectedEntitiesPath).pushObjects(record);
            }
          }

          // If a single record its single selected
        } else {
          if (record.isSelected) {
            this.send('deselectModelListItems', {
              selectedEntitiesPath: selectedEntitiesPath
            });
            this.controller.get(selectedEntitiesPath).removeObject(record);
          } else {
            this.send('deselectModelListItems', {
              selectedEntitiesPath: selectedEntitiesPath
            });
            record.set('isSelected', true);
            this.controller.get(selectedEntitiesPath).pushObject(record);
          }
        }
      },
      showInfo: function showInfo(record, params) {
        var controller = params.controller ? this.controller.get(params.controller) : this.controller;
        if (record.isSelected) {
          record.set('isSelected', false);
        }
        this.send('selectModelListItems', record, params.selectedEntitiesPath);
        controller.set('isInfoSidebarOpen', true);
      },
      showManagePrioritizationModal: function showManagePrioritizationModal(action, item, parent) {
        var data = Ember.Object.create({
          action: action,
          experiences: [],
          groups: [],
          selectedExperience: null,
          selectedGroup: null
        });
        if (action.indexOf('CONTENT_INSTANCE') >= 0) {
          data.set('selectedExperience', item);
        } else if (action.indexOf('GROUP_EXPERIENCE') >= 0) {
          data.setProperties({
            groups: this.store.query('group', {
              sort: 'priority'
            }),
            selectedGroup: parent
          });
          data.set('groups', this.store.query('group', {
            sort: 'priority'
          }));
        } else if (action.indexOf('EXPERIENCE') >= 0) {
          data.set('experiences', this.store.query('experience', {
            sort: 'priority'
          }));
        } else if (action.indexOf('GROUP') >= 0) {
          data.set('groups', this.store.query('group', {
            sort: 'priority'
          }));
        }
        this.controller.set('managePrioritizationModalData', data);
      },
      showSimulationModal: function showSimulationModal(model) {
        this.controller.set('simulationModalData', model);
      },
      simulate: function simulate(model) {
        var _this3 = this;
        var self = this;
        var simulation;
        if (model) {
          var adapter = this.store.adapterFor(model.constructor.modelName);
          simulation = adapter.simulate(this.store, model.constructor, model);
          simulation.then(function () {
            var message = "Simulation for ".concat(model.get('name'), " rule ");
            message += 'in progress! This may take few minutes.';
            model.reload();
            _this3.send('showNotification', Ember.Object.create({
              type: 'confirm',
              message: message
            }));
          }).catch(function (response) {
            self.send('showModal', Ember.Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: self.get('utility').parseError(response),
              title: "Failed to undraft ".concat(model.get('name')),
              type: 'danger'
            }));
          });
        }
        return simulation;
      },
      toggleActivation: function toggleActivation(record) {
        var _this4 = this;
        var model = record.record;
        var isActive = !model.get('isActive');
        var modelName = Ember.String.classify(model.constructor.modelName);
        modelName = modelName.replace(/([A-Z])/g, ' $1').trim();
        var adapter = this.store.adapterFor(modelName);
        adapter.toggleProperty(model, 'isActive').then(function () {
          _this4.send('hideModal');
          var activationVerb = isActive ? 'activated' : 'deactivated';
          _this4.send('showNotification', {
            type: 'confirm',
            message: "Successfully ".concat(activationVerb, " ").concat(model.get('name'), " ").concat(modelName)
          });
        }).catch(function (response) {
          _this4.send('hideModal');
          _this4.send('showNotification', {
            type: 'alert',
            message: _this4.utility.parseError(response)
          });
        });
      },
      undraft: function undraft(model) {
        var _this5 = this;
        var record = model.records.get('firstObject');
        var adapter = this.store.adapterFor(record.constructor.modelName);
        var self = this;
        adapter.undraft(this.store, record.constructor, record).then(function () {
          record.reload().then(function () {
            self.send('hideModal');
            var message = 'The rule has been updated successfully.';
            _this5.send('showNotification', Ember.Object.create({
              type: 'confirm',
              message: message
            }));
          });
        }).catch(function (response) {
          self.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: self.get('utility').parseError(response),
            title: "Failed to simulate ".concat(record.get('name')),
            type: 'danger'
          }));
        });
      },
      checkScheduleExpiration: function checkScheduleExpiration(model) {
        model.set('checkIsScheduleExpired', true);
      }
    }
  });
  _exports.default = _default;
});