define("experience-studio/components/route-model-table-content-instance", ["exports", "experience-studio/components/route-model-table-entity"], function (_exports, _routeModelTableEntity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelTableEntity.default.extend({
    classNames: ['route-model-table-content-instance'],
    data: null,
    utility: Ember.inject.service(),
    dropdownComponent: Ember.computed('contentType', function () {
      return 'route-model-table-dropdown-content-instance';
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('headers', [Ember.Object.create({
        label: '',
        isAsc: false,
        isSelected: false,
        class: 'icon-col',
        isHidden: false
      }), Ember.Object.create({
        label: 'Name',
        sortProperty: 'name',
        isAsc: false,
        isSelected: false,
        class: 'name-col',
        isHidden: false,
        tooltip: 'The name of this Content.'
      }), Ember.Object.create({
        label: 'Last Modified',
        sortProperty: 'modifiedAt',
        isAsc: false,
        isSelected: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'The date this Content was last modified on.'
      }), Ember.Object.create({
        label: 'Date Created',
        sortProperty: 'createdAt',
        isSelected: false,
        isAsc: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'The date this Content was created on.'
      })]);
    }
  });
  _exports.default = _default;
});