define("experience-studio/helpers/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateCenter = calculateCenter;
  _exports.calculateCircleData = calculateCircleData;
  _exports.default = void 0;
  _exports.getDistance = getDistance;
  _exports.rad = rad;
  function calculateCircleData() {
    var lat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var lng = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var radius = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var r = 6371;
    var pi = Math.PI;
    var _lat = lat * pi / 180;
    var _lng = lng * pi / 180;
    var d = radius / 1000 / r;
    var detail = 8;
    var data = '';
    for (var i = 0; i <= 360; i += detail) {
      var brng = i * pi / 180;
      var pLat = Math.asin(Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng));
      var pLng = (_lng + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(_lat), Math.cos(d) - Math.sin(_lat) * Math.sin(pLat))) * 180 / pi;
      pLat = pLat * 180 / pi;
      data += "|".concat(pLat, ",").concat(pLng);
    }
    return data;
  }
  function calculateCenter(points) {
    var longitudes = points.map(function (i) {
      return i.lng;
    }).sort();
    var latitudes = points.map(function (i) {
      return i.lat;
    }).sort();
    var lowX = latitudes[0];
    var highX = latitudes[latitudes.length - 1];
    var lowY = longitudes[0];
    var highY = longitudes[longitudes.length - 1];
    var longestDistance = getDistance({
      lat: lowX,
      lng: lowY
    }, {
      lat: highX,
      lng: highY
    });
    var radius = Math.ceil(longestDistance / 2);
    var centerX = lowX + (highX - lowX) / 2;
    var centerY = lowY + (highY - lowY) / 2;
    return {
      lat: centerX,
      lng: centerY,
      radius: radius
    };
  }
  function rad(x) {
    return x * Math.PI / 180;
  }
  function getDistance(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  }
  var _default = Ember.Helper.helper(calculateCircleData);
  _exports.default = _default;
});