define("experience-studio/mixins/field-model", ["exports", "experience-studio/mixins/obj-util"], function (_exports, _objUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var uiTypes = {
    ctobject: {
      'label': 'Object',
      'serverType': 'object',
      'isContainer': true,
      'componentName': 'content-tmp-branch-obj'
    },
    ctobjarray: {
      'label': 'Object array',
      'serverType': 'array',
      'childType': 'object',
      'isContainer': true,
      'componentName': 'content-tmp-branch-objarray'
    },
    ctprimarray: {
      'label': 'Primitive array',
      'serverType': 'array',
      'childType': 'string',
      'isContainer': true,
      'componentName': 'content-tmp-branch-primarray'
    },
    ctgradient: {
      'label': 'Gradient',
      'serverType': 'array',
      'childType': 'string',
      'isContainer': true,
      'componentName': 'content-tmp-branch-gradient'
    },
    cttext: {
      'label': 'Text field',
      'serverType': 'localized',
      'isContainer': false,
      'componentName': 'content-tmp-edit-text-locale'
    },
    ctstring: {
      'label': 'Text field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-text'
    },
    ctemail: {
      'label': 'Email field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-email'
    },
    ctphone: {
      'label': 'Phone field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-phone'
    },
    ctcolor: {
      'label': 'Color field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-colour'
    },
    ctdate: {
      'label': 'Date field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-date'
    },
    ctdatetimezone: {
      'label': 'Date field',
      'serverType': 'object',
      'isContainer': false,
      'componentName': 'content-tmp-edit-date-timezone'
    },
    ctimage: {
      'label': 'Image field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-image'
    },
    ctlocalizedimage: {
      'label': 'Localized Image field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-localized-image'
    },
    ctmedia: {
      'label': 'Media field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-media'
    },
    ctlocalizedmedia: {
      'label': 'Localized Media field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-media'
    },
    ctfile: {
      'label': 'File field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-file'
    },
    ctnumber: {
      'label': 'Number field',
      'serverType': 'number',
      'isContainer': false,
      'componentName': 'content-tmp-edit-number'
    },
    ctdropdown: {
      'label': 'Dropdown field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-dropdown'
    },
    ctcontentinstance: {
      'label': 'Dropdown field',
      'serverType': 'string',
      'isContainer': false,
      'componentName': 'content-tmp-edit-content-instance'
    },
    ctgeoshape: {
      'label': 'Geoshape field',
      'serverType': 'array',
      'isContainer': false,
      'componentName': 'content-tmp-edit-geoshape'
    },
    ctlocation: {
      'label': 'Location field',
      'serverType': 'location',
      'isContainer': false,
      'componentName': 'content-tmp-edit-location'
    }
  };
  var typeKeys = window.Object.keys(uiTypes);
  function Field(serverObj) {
    this.tmpID = _objUtil.default.guid(1);
    this.key = '';
    this.displayName = '';
    this.isRequired = false;
    this.type = null;
    this.placeholder = null;
    this.schema = null;
    this.childType = null;
    this.inputUI = Field.inputType.INPUT;
    this.componentName = null;
    this.componentTypeName = null;
    this.options = null;
    this.hasMore = false;
    this.imgAspectRatio = null;
    this.imgMaxWidth = null;
    this.imgMaxHeight = null;
    this.defaultOption = null;
    // could be an array of fields or a single value
    this.value = null;
    this.revalidate = false;
    this.clearError = false;
    if (serverObj) {
      this.fromJSON(serverObj);
    }
  }
  Field.prototype = {
    typeResolver: function typeResolver(serverObj) {
      if (serverObj.metadata && serverObj.metadata.uiType) {
        return serverObj.metadata.uiType;
      } else if (serverObj.type === 'localized') {
        return 'cttext';
      } else {
        for (var i = 0; i < typeKeys.length; i++) {
          var key = typeKeys[i];
          if (uiTypes[key].serverType === serverObj.type) {
            return key;
          }
        }
        return 'cttext';
      }
    },
    fromJSON: function fromJSON(serverObj) {
      this.key = serverObj.name;
      this.displayName = serverObj.displayName ? serverObj.displayName.en : null;
      this.isRequired = serverObj.properties ? serverObj.properties.isRequired : false;
      this.type = this.typeResolver(serverObj);
      this.placeholder = serverObj.metadata ? serverObj.metadata.placeholder : null;
      this.childTypeName = serverObj.metadata ? serverObj.metadata.childTypeName : null;
      this.options = serverObj.metadata ? serverObj.metadata.options : [];
      this.imgAspectRatio = serverObj.metadata ? serverObj.metadata.aspectRatio : null;
      this.imgMaxWidth = serverObj.metadata ? serverObj.metadata.maxWidth : null;
      this.imgMaxHeight = serverObj.metadata ? serverObj.metadata.maxHeight : null;
      this.defaultOption = serverObj.metadata ? serverObj.metadata.defaultOption : null;
      this.maxLength = serverObj.metadata ? serverObj.metadata.maxLength : null;
      this.isWebLink = serverObj.metadata ? serverObj.metadata.isWebLink : null;
      this.isRichTextEnabled = serverObj.metadata ? serverObj.metadata.isRichTextEnabled : null;
      this.isDynamicAttributeEnabled = serverObj.metadata ? serverObj.metadata.isDynamicAttributeEnabled : null;
      this.isVisible = serverObj.metadata && serverObj.metadata.isVisible !== null && typeof serverObj.metadata.isVisible !== 'undefined' ? serverObj.metadata.isVisible : true;
      this.showFullOptionLabel = serverObj.metadata && serverObj.metadata.showFullOptionLabel ? serverObj.metadata.showFullOptionLabel : null;
      this.inputUI = serverObj.metadata ? serverObj.metadata.inputUI : Field.inputType.INPUT;
      this.childType = serverObj.subtype;
      this.schema = serverObj.fields ? serverObj.fields.map(function (obj) {
        return new Field(obj);
      }) : null;
      this.componentName = uiTypes[this.type] && uiTypes[this.type].componentName || 'content-tmp-edit-text';
      this.componentTypeName = uiTypes[this.type] && uiTypes[this.type].isContainer ? 'content-template-branch' : 'content-template-leaf';
      if (this.componentTypeName === 'content-template-branch') {
        this.value = [];
      }
      if (this.componentName === 'content-tmp-edit-location') {
        this.value = Ember.Object.create({
          address: null,
          latitude: null,
          longitude: null
        });
      }
      if (this.componentName === 'content-tmp-edit-geoshape') {
        this.value = [];
      }
      return Ember.Object.create({
        this: this
      });
    },
    toJSON: function toJSON() {
      var retObj = {
        name: this.key,
        displayName: {
          en: this.displayName
        },
        properties: {
          isRequired: this.isRequired
        },
        metadata: {
          uiType: this.type,
          childTypeName: this.childTypeName,
          defaultOption: this.defaultOption !== '' ? this.defaultOption : null
        },
        type: uiTypes[this.type].serverType
      };
      if (this.childType) {
        retObj.subtype = this.childType;
      }
      if (this.options) {
        retObj.metadata.options = this.options;
      }
      if (this.imgAspectRatio) {
        retObj.metadata.aspectRatio = +this.imgAspectRatio;
      }
      if (this.imgMaxWidth) {
        retObj.metadata.maxWidth = +this.imgMaxWidth;
      }
      if (this.imgMaxWidth) {
        retObj.metadata.maxHeight = +this.imgMaxHeight;
      }
      if (this.inputUI) {
        retObj.metadata.inputUI = this.inputUI;
      }
      if (this.isWebLink) {
        retObj.metadata.isWebLink = this.isWebLink;
      }
      if (this.isRichTextEnabled) {
        retObj.metadata.isRichTextEnabled = this.isRichTextEnabled;
      }
      if (this.isDynamicAttributeEnabled) {
        retObj.metadata.isDynamicAttributeEnabled = this.isDynamicAttributeEnabled;
      }
      if (this.maxLength) {
        retObj.metadata.maxLength = this.maxLength;
      }
      retObj.fields = this.schema ? this.schema.map(function (obj) {
        return obj.toJSON();
      }) : null;
      if (this.placeholder !== null) {
        retObj.metadata.placeholder = this.placeholder;
      }
      return retObj;
    },
    newChild: function newChild() {
      if (!this.childType) {
        throw new Error('Child type uninstantiable');
      }
      var child = new Field();
      if (this.childType === Field.childType.OBJECT) {
        child.type = 'ctobject';
        child.componentName = 'content-tmp-edit-object';
        child.componentTypeName = 'content-template-branch';
        child.displayName = this.childTypeName;
        child.schema = this.schema.map(function (field) {
          return field.duplicate();
        });
      } else if (this.type === 'ctgradient' && this.childType === Field.childType.STRING) {
        child.type = 'ctcolor';
        child.componentName = 'content-tmp-edit-colour';
        child.componentTypeName = 'content-template-leaf';
      } else if (this.childType === Field.childType.STRING) {
        child.type = 'ctstring';
        child.componentName = 'content-tmp-edit-text';
        child.componentTypeName = 'content-template-leaf';
      } else if (this.childType === Field.childType.NUMBER) {
        child.type = 'ctnumber';
        child.componentName = 'content-tmp-edit-number';
        child.componentTypeName = 'content-template-leaf';
      }
      return child;
    },
    toData: function toData() {
      var retObj = {};
      if (this.type === 'ctobject' || this.type === 'ctmedia' || this.type === 'ctlocalizedmedia') {
        var obj = {};
        if (['ctmedia', 'ctlocalizedmedia'].includes(this.type) && !this.schema.find(function (field) {
          return field.key === 'mediaType';
        }).value) {
          return obj;
        }
        if (this.schema) {
          for (var i = 0; i < this.schema.length; i++) {
            var field = this.schema[i];
            obj = _objUtil.default.extend(obj, field.toData());
          }
        }
        if (this.key) {
          retObj[this.key] = obj;
          return retObj;
        }
        return obj;
      } else if (this.type === 'ctobjarray') {
        var arr = [];
        if (this.value) {
          for (var e = 0; e < this.value.length; e++) {
            var objField = this.value[e];
            arr.push(objField.toData());
          }
        }
        retObj[this.key] = arr;
        return retObj;
      } else if (this.type === 'ctprimarray') {
        var primArr = [];
        if (this.value) {
          for (var u = 0; u < this.value.length; u++) {
            var primField = this.value[u];
            primArr.push(primField.value);
          }
        }
        retObj[this.key] = primArr;
        return retObj;
      } else if (this.type === 'ctgradient') {
        var gradientArr = [];
        if (this.value) {
          for (var _u = 0; _u < this.value.length; _u++) {
            var gradientField = this.value[_u];
            gradientArr.push(gradientField.value);
          }
        }
        retObj[this.key] = gradientArr;
        return retObj;
      } else if (this.type === 'cttext') {
        if (this.value) {
          if (!retObj.localizations) {
            retObj.localizations = {};
          }
          var localeKeys = window.Object.keys(this.value);
          for (var keyIndex = 0; keyIndex < localeKeys.length; keyIndex++) {
            var localeKey = localeKeys[keyIndex];
            if (this.value[localeKey] && this.value[localeKey] !== '') {
              if (!retObj.localizations[localeKey]) {
                retObj.localizations[localeKey] = {};
              }
              retObj.localizations[localeKey][this.key] = this.value[localeKey];
            }
          }
          if (window.Object.keys(retObj.localizations).length < 1) {
            delete retObj.localizations;
          }
        }
        return retObj;
      } else if (this.type === 'ctlocalizedimage') {
        if (this.value) {
          if (!retObj.localizations) {
            retObj.localizations = {};
          }
          var _localeKeys = window.Object.keys(this.value);
          for (var _keyIndex = 0; _keyIndex < _localeKeys.length; _keyIndex++) {
            var _localeKey = _localeKeys[_keyIndex];
            if (this.value[_localeKey] && this.value[_localeKey] !== '') {
              if (!retObj.localizations[_localeKey]) {
                retObj.localizations[_localeKey] = {};
              }
              retObj.localizations[_localeKey][this.key] = this.value[_localeKey][this.key];
            }
          }
          if (window.Object.keys(retObj.localizations).length < 1) {
            delete retObj.localizations;
          }
        }
        return retObj;
      } else if (this.type === 'ctlocation') {
        if (this.value && this.value.latitude && this.value.longitude) {
          retObj[this.key] = {
            metadata: {
              address: this.value.address
            },
            lat: window.parseFloat(this.value.latitude),
            lng: window.parseFloat(this.value.longitude)
          };
        }
        return retObj;
      } else if (this.type === 'ctgeoshape') {
        if (this.value && this.value.length) {
          retObj[this.key] = this.value;
        }
        return retObj;
      } else if (this.type === 'ctimage') {
        if (this.value == null || this.value == undefined) {
          Ember.set(this, 'value', '');
        }
        retObj[this.key] = this.value;
      } else if (this.type === 'ctdropdown' && this.value !== undefined && this.value !== null) {
        if (this.key === 'actionType' && this.value.includes('UNIVERSAL_ACTION')) {
          retObj[this.key] = 'UNIVERSAL_ACTION';
        } else {
          retObj[this.key] = this.value;
        }
      }
      if (this.value !== undefined && this.value !== null && this.value !== '') {
        if (!(this.key === 'actionType' && this.value.includes('UNIVERSAL_ACTION'))) {
          retObj[this.key] = this.value;
          if (typeof this.value === 'string') {
            retObj[this.key] = this.value.trim();
          }
        }
      }
      return retObj;
    },
    fromData: function fromData(data) {
      var pagination;
      if (this.type === 'ctobject' || this.type === 'ctmedia' || this.type === 'ctlocalizedmedia') {
        if (this.schema) {
          for (var i = 0; i < this.schema.length; i++) {
            if (this.key === '') {
              this.schema[i].fromData(data);
            } else if (data && this.key) {
              this.schema[i].fromData(data[this.key]);
            }
          }
        }
      } else if (this.type === 'ctobjarray') {
        if (!data) {
          data = {};
        }
        var arr = data[this.key];
        pagination = data["".concat(this.key, ".pagination")];
        if (pagination && pagination.totalRecords > pagination.limit) {
          this.hasMore = true;
        }
        if (arr) {
          this.value.splice(0, this.value.length);
          for (var e = 0; e < arr.length; e++) {
            this.value.pushObject(this.newChild().fromData(arr[e]));
          }
        }
      } else if (this.type === 'ctprimarray') {
        if (!data) {
          data = {};
        }
        var primArr = data[this.key];
        pagination = data["".concat(this.key, ".pagination")];
        if (pagination && pagination.totalRecords > pagination.limit) {
          this.hasMore = true;
        }
        if (primArr) {
          this.value.splice(0, this.value.length);
          for (var u = 0; u < primArr.length; u++) {
            var childField = this.newChild();
            childField.value = primArr[u];
            this.value.pushObject(childField);
          }
        }
      } else if (this.type === 'ctgradient') {
        if (!data) {
          data = {};
        }
        var gradientArr = data[this.key];
        pagination = data["".concat(this.key, ".pagination")];
        if (pagination && pagination.totalRecords > pagination.limit) {
          this.hasMore = true;
        }
        if (gradientArr) {
          this.value.splice(0, this.value.length);
          for (var _u2 = 0; _u2 < gradientArr.length; _u2++) {
            var _childField = this.newChild();
            _childField.value = gradientArr[_u2];
            this.value.pushObject(_childField);
          }
        }
      } else if (this.type === 'cttext' && data && data.localizations) {
        var valueObj = {};
        var localeKeys = data.localizations ? window.Object.keys(data.localizations) : [];
        for (var keyIndex = 0; keyIndex < localeKeys.length; keyIndex++) {
          var localeKey = localeKeys[keyIndex];
          var value = data.localizations[localeKey][this.key];
          if (value) {
            valueObj[localeKey] = value;
          }
        }
        Ember.set(this, 'value', valueObj);
      } else if (this.type === 'ctlocalizedimage' && data && data.localizations) {
        var _valueObj = {};
        var _localeKeys2 = data.localizations ? window.Object.keys(data.localizations) : [];
        for (var _keyIndex2 = 0; _keyIndex2 < _localeKeys2.length; _keyIndex2++) {
          var _localeKey2 = _localeKeys2[_keyIndex2];
          var _value = data.localizations[_localeKey2][this.key];
          if (_value) {
            if (!_valueObj[_localeKey2]) {
              _valueObj[_localeKey2] = {};
            }
            _valueObj[_localeKey2][this.key] = _value;
          }
        }
        Ember.set(this, 'value', _valueObj);
      } else if (this.type === 'ctlocation') {
        var _valueObj2 = {};
        var _value2 = data && this.key ? data[this.key] : {};
        if (_value2) {
          _valueObj2.latitude = _value2.lat;
          _valueObj2.longitude = _value2.lng;
          if (_value2.metadata) {
            _valueObj2.address = _value2.metadata.address;
          }
        }
        Ember.set(this, 'value', Ember.Object.create(_valueObj2));
      } else if (this.type === 'ctgeoshape') {
        var _value3 = data && this.key ? data[this.key] : [];
        Ember.set(this, 'value', _value3);
      } else if (this.type === 'ctdropdown' && data && data[this.key] !== undefined && data[this.key] !== null) {
        if (data[this.key] === 'UNIVERSAL_ACTION' && typeof data.validationPrefix !== 'undefined') {
          Ember.set(this, 'value', data.validationPrefix + data[this.key]);
        } else if (data[this.key] === 'UNIVERSAL_ACTION') {
          var langKey = data.localizations ? Object.keys(data.localizations)[0] || '' : '';
          var actionTypePrefix = '';
          if (data.localizations[langKey] && data.localizations[langKey].actionValue) {
            var actionValueData = data.localizations[langKey].actionValue;
            if (actionValueData.includes('mail://?sendTo=')) {
              actionTypePrefix = 'mailto:';
            } else if (actionValueData.includes('phone://?phoneNumber=')) {
              actionTypePrefix = 'tel:';
            } else if (actionValueData.includes('concierge://?')) {
              actionTypePrefix = 'concierge:';
            } else if (actionValueData.includes('universalLink://?')) {
              actionTypePrefix = 'universalLink:';
            } else if (actionValueData.includes('web://?url=')) {
              actionTypePrefix = 'web:';
            } else if (actionValueData.includes('details://?contentId=')) {
              actionTypePrefix = 'details:';
            } else if (actionValueData.includes('browser://?url=')) {
              actionTypePrefix = 'browser:';
            } else if (actionValueData.includes('app://?')) {
              actionTypePrefix = 'app:';
            }
          }
          Ember.set(this, 'value', actionTypePrefix + data[this.key]);
        } else {
          Ember.set(this, 'value', data[this.key]);
        }
      } else if (data) {
        // this.value = data[this.key];
        // this.set('value',data[this.key]);
        Ember.set(this, 'value', data[this.key]);
      }
      return this;
    },
    duplicate: function duplicate() {
      var copy = new Field();
      copy.fromJSON(this.toJSON());
      copy.fromData(this.toData());
      return copy;
    }
  };
  Field.prototype.childType = Field.childType = {};
  Field.prototype.inputType = Field.inputType = {};
  Field.prototype.childType.NUMBER = Field.childType.NUMBER = 'number';
  Field.prototype.childType.STRING = Field.childType.STRING = 'string';
  Field.prototype.childType.OBJECT = Field.childType.OBJECT = 'object';
  Field.prototype.inputType.INPUT = Field.inputType.INPUT = 'input';
  Field.prototype.inputType.TEXTAREA = Field.inputType.TEXTAREA = 'textarea';
  var _default = Field;
  _exports.default = _default;
});