define("experience-studio/templates/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HOk0LNry",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"analytics-route\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"headerTitle\",\"isSearchFormHidden\",\"showInfoButton\",\"mainHeaderTabs\",\"selectedEntities\"],[\"Analytics\",[35,5],[35,4],[35,3],[35,2]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"selectedEntities\",\"mainHeaderTabs\",\"showInfoButton\",\"isSearchFormHidden\",\"main-header-info-sidebar\"]}",
    "moduleName": "experience-studio/templates/analytics.hbs"
  });
  _exports.default = _default;
});