define("experience-studio/components/context-hub-attribute-v2", ["exports", "experience-studio/utils/nearby-location"], function (_exports, _nearbyLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['context-hub-attribute-v2'],
    locationErrorMessage: null,
    isRefreshFilterModel: false,
    locationModel: null,
    model: null,
    selectedCompositionGroup: null,
    selectedLocationLabels: null,
    userPreferences: Ember.inject.service(),
    mathOperators: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      label: 'Add',
      text: 'plus',
      value: '+'
    }), Ember.Object.create({
      label: 'Subtract',
      text: 'minus',
      value: '-'
    }), Ember.Object.create({
      label: 'Multiply',
      text: 'multiplied by',
      value: '*'
    }), Ember.Object.create({
      label: 'Divide',
      text: 'divided by',
      value: '/'
    }), Ember.Object.create({
      label: 'Exponent',
      text: 'to the power',
      value: '^'
    }), Ember.Object.create({
      label: 'Modulo',
      text: 'modulo',
      value: '%'
    })],
    // COMPUTED ------------------------------------------------------------------
    selectedDate: Ember.computed('model.value', function () {
      var date = new Date().getTime();
      if (this.model.selectedAttribute.isDateTimeType && this.model.value) {
        date = this.model.value * 1000;
      }
      return date;
    }),
    attributes: Ember.computed('model', function () {
      var attributes = [];
      var attributesObj = this.get('model.originalModel.attributes');
      for (var property in attributesObj) {
        if (attributesObj.hasOwnProperty(property)) {
          var attribute = attributesObj[property];
          this.setupAttribute(attribute);
          attributes.pushObject(attribute);
        }
      }
      return attributes;
    }),
    isValueOptionsEmpty: Ember.computed('model.selectedAttribute.valueTypeOptions.[]', function () {
      var selectedAttribute = this.model.selectedAttribute || Ember.Object.create();
      var valueTypeOptions = selectedAttribute.valueTypeOptions || [];
      var isValueOptionsEmpty = false;
      switch (true) {
        case valueTypeOptions.length < 1:
          isValueOptionsEmpty = true;
          return isValueOptionsEmpty;
        case valueTypeOptions.length === 1 && Ember.isEmpty(valueTypeOptions.objectAt(0).value) && Ember.isEmpty(valueTypeOptions.objectAt(0).displayName):
          isValueOptionsEmpty = true;
          return isValueOptionsEmpty;
        case valueTypeOptions.length >= 1 && !Ember.isEmpty(valueTypeOptions.objectAt(0).value) && !Ember.isEmpty(valueTypeOptions.objectAt(0).displayName):
          isValueOptionsEmpty = false;
          return isValueOptionsEmpty;
        default:
          isValueOptionsEmpty = false;
          return isValueOptionsEmpty;
      }
    }),
    attributesToCompare: Ember.computed('model.selectedAttribute', function () {
      var attributes = [];
      var plugins = this.plugins;
      var selectedAttribute = this.get('model.selectedAttribute');
      if (!Ember.isEmpty(plugins) && selectedAttribute) {
        plugins.forEach(function (plugin) {
          var pluginAttributes = plugin.get('attributes');
          var keys = window.Object.keys(pluginAttributes);
          if (pluginAttributes && !Ember.isEmpty(keys)) {
            keys.forEach(function (key) {
              var pluginAttribute = pluginAttributes.get(key);
              if (pluginAttribute.get('uid') !== selectedAttribute.get('uid') &&
              // ME-1290 currently there is no way to input parameters for parameterized plugins
              // so we avoid showing them in the dropdown
              !pluginAttribute.get('uid').includes('query') && pluginAttribute.get('valueType') === selectedAttribute.get('valueType')) {
                attributes.pushObject(Ember.Object.create({
                  label: "".concat(plugin.get('name'), " - ").concat(pluginAttribute.get('name')),
                  value: pluginAttribute.get('uid')
                }));
              }
            });
          }
        });
      }
      return attributes;
    }),
    historicalQueryOptions: Ember.computed('model.historicalQueries', function () {
      var attributes = [];
      var _historicalQueries = this.historicalQueries;
      var selectedAttribute = this.get('model.selectedAttribute');
      if (!Ember.isEmpty(_historicalQueries) && selectedAttribute) {
        _historicalQueries.forEach(function (_historicalQuery) {
          attributes.pushObject(Ember.Object.create({
            label: _historicalQuery.get('name'),
            value: "ctx.flybits.ctxhistory.query.result.".concat(_historicalQuery.get('id'))
          }));
        });
      }
      return attributes;
    }),
    isConfigureHistoricalPlugin: Ember.computed('model', function () {
      var selectedAttribute = this.get('model.selectedAttribute');
      if (selectedAttribute.id === 'ctx.flybits.ctxhistory.query.result') {
        return true;
      }
      return false;
    }),
    isLocationAttribute: Ember.computed('model.originalModel.uid', function () {
      return this.get('model.originalModel.uid').indexOf('ctx.flybits.area') >= 0 || this.isApproximateAreaAttribute;
    }),
    isApproximateAreaAttribute: Ember.computed('model.originalModel.uid', function () {
      return this.get('model.originalModel.uid').indexOf('ctx.flybits.approximateArea') >= 0 || false;
    }),
    isHistoricalOptionsVisible: Ember.computed('model.comparisonPredicate', function () {
      if (this.model.comparisonPredicate && this.model.comparisonPredicate.value && this.model.comparisonPredicate.value.indexOf('has_') > -1) {
        return true;
      } else {
        return false;
      }
    }),
    isHistoricalCount: Ember.computed('model.comparisonPredicate', function () {
      if (this.model.comparisonPredicate && this.model.comparisonPredicate.value && this.model.comparisonPredicate.value.indexOf('sum') > -1) {
        return false;
      } else {
        return true;
      }
    }),
    isRecencyOptionsVisible: Ember.computed('model.recencyOption', function () {
      if (this.model.recencyOption && this.model.recencyOption !== 'anytime') {
        return true;
      } else {
        return false;
      }
    }),
    isRecencyOptionsBetweenVisible: Ember.computed('model.recencyOption', function () {
      if (this.model.recencyOption && this.model.recencyOption === 'between') {
        return true;
      } else {
        return false;
      }
    }),
    isRecencyOptionsInThePastVisible: Ember.computed('model.recencyOption', function () {
      if (this.model.recencyOption && this.model.recencyOption === 'in the past') {
        return true;
      } else {
        return false;
      }
    }),
    recencyInThePastOptions: Ember.computed('model.recencyOption', function () {
      return ['minute(s)', 'hour(s)', 'day(s)'];
    }),
    parametersCompostionItem: Ember.computed('model.selectedAttribute', function () {
      var parameters = [];
      if (this.model && this.model.parameters) {
        parameters = this.model.parameters;
      } else if (this.model && this.model.selectedAttribute && Array.isArray(this.model.selectedAttribute.parameters)) {
        this.model.selectedAttribute.parameters.forEach(function (item) {
          parameters.pushObject(Ember.Object.create(JSON.parse(JSON.stringify(item))));
        });
        this.model.parameters = parameters;
      }
      return parameters;
    }),
    comparisionSelectOptions: Ember.computed('model.selectedAttribute', function () {
      var selectedAttribute = this.get('model.selectedAttribute');
      var model = this.model;
      if (selectedAttribute.id === 'ctx.flybits.ctxhistory.query.result') {
        //remove historical select options for ctx history plugin
        var _selectOptions = model.selectedAttribute.comparisonPredicates.filter(function (item) {
          return !item.label.includes('Has') && !item.label.includes('Sum');
        });
        return _selectOptions;
      }
      return model.selectedAttribute.comparisonPredicates;
    }),
    didSelectedAttribute: Ember.observer('model.selectedAttribute', function () {
      var selectedAttribute = this.get('model.selectedAttribute');
      var model = this.model;
      if (selectedAttribute) {
        selectedAttribute.set('isSelected', true);
      }
      if (selectedAttribute.id === 'ctx.flybits.ctxhistory.query.result') {
        model.set('isExpression', true);
      }
    }),
    didUpdateCaseSensitive: Ember.observer('model.isCaseSensitive', function () {
      var model = this.model;
      var predicates = this.getPredicates(model.get('selectedAttribute'));
      model.set('comparisonPredicate', null);
      model.set('selectedAttribute.comparisonPredicates', predicates);
    }),
    didUpdateValues: Ember.observer('model.attributeToCompare', 'model.comparisonPredicate', 'model.isCaseSensitive', 'model.selectedAttribute', 'model.value', function () {
      var model = this.model;
      var comparisonPredicateLabel = model.get('comparisonPredicate.label');
      var description = null;
      var selectedAttribute = model.get('selectedAttribute');
      if (selectedAttribute) {
        if (comparisonPredicateLabel) {
          description = "".concat(selectedAttribute.get('name'), " ").concat(comparisonPredicateLabel);
        }
        if (model.get('attributeToCompare')) {
          model.set('value', model.get('attributeToCompare.label'));
        }
        if (model.get('value')) {
          var value = model.get('value');
          if (model.get('isCaseSensitive')) {
            value = "".concat(value, " (Case Sensitive)");
          }
          var descriptionValue = value;
          if (selectedAttribute.get('isTimeContext') && value) {
            descriptionValue = window.moment(parseInt(value) * 1000).format('h:mA MMM D, YYYY');
          }
          description = "".concat(description, " ").concat(descriptionValue);
        }
        model.set('description', description);
      }
    }),
    observeIsCompareAttribute: Ember.observer('model.isCompareAttribute', function () {
      var model = this.model;
      if (!model.get('isCompareAttribute')) {
        model.setProperties({
          attributeToCompare: null,
          description: null,
          value: null
        });
      }
    }),
    observeIsExpression: Ember.observer('model.isExpression', function () {
      var model = this.model;
      if (model.get('isExpression')) {
        var expressionItems = model.get('expressionItems');
        expressionItems.clear();
        expressionItems.pushObject(Ember.Object.create({
          attribute: model.get('selectedAttribute'),
          isRoot: true,
          operator: null
        }));
      } else {
        model.set('expression', null);
      }
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var attributesToCompare = self.get('attributesToCompare');
        var model = self.get('model');
        if (model.originalModel.uid === 'ctx.flybits.location' || model.originalModel.uid === 'ctx.flybits.approximateArea') {
          model.set('comparisonPredicate', null);
          model.set('selectedAttribute', self.attributes.objectAt(0));
          model.set('comparisonPredicate', self.attributes.objectAt(0).comparisonPredicates.filterBy('label', 'is').objectAt(0));
        }
        if (!Ember.isEmpty(attributesToCompare)) {
          var filteredItems = attributesToCompare.filterBy('value', model.get('value'));
          if (!Ember.isEmpty(filteredItems)) {
            model.set('attributeToCompare', filteredItems.get('firstObject'));
          }
        }
      });
    },
    getFrequencyOptions: function getFrequencyOptions() {
      return [Ember.Object.create({
        label: 'at least',
        operator: '>=',
        value: 'greq'
      }), Ember.Object.create({
        label: 'at most',
        operator: '<=',
        value: 'lseq'
      }), Ember.Object.create({
        label: 'exactly',
        operator: '=',
        value: 'eq'
      }), Ember.Object.create({
        label: 'less than',
        operator: '<',
        value: 'ls'
      }), Ember.Object.create({
        label: 'more than',
        operator: '>',
        value: 'gr'
      })];
    },
    getRecencyOptions: function getRecencyOptions() {
      return ['in the past'];
    },
    getPredicates: function getPredicates(attribute) {
      var predicates = [];
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          break;
        case '[]string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringArrayIncludes'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringArrayIncludes'
            }));
          } else {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringArrayIncludes'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringArrayIncludes'
            }));
          }
          break;
        case 'bool':
          predicates.pushObject(Ember.Object.create({
            label: 'Is',
            operator: '=',
            value: 'boolEq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is not',
            operator: '!=',
            value: 'boolNeq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has been',
            operator: '=',
            value: 'has_boolEq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been',
            operator: '=',
            value: 'has_not_boolEq'
          }));
          break;
        case 'float':
        case 'int':
          predicates.pushObject(Ember.Object.create({
            label: 'Is',
            operator: '=',
            value: 'eq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is not',
            operator: '!=',
            value: 'neq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is greater than',
            operator: '>',
            value: 'gr'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is greater than or equal to',
            operator: '>=',
            value: 'greq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is less than',
            operator: '<',
            value: 'ls'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is less than or equal to',
            operator: '<=',
            value: 'lseq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has been',
            operator: '=',
            value: 'has_eq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been',
            operator: '=',
            value: 'has_not_eq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has been greater than',
            operator: '>',
            value: 'has_gr'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been greater than',
            operator: '>',
            value: 'has_not_gr'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has been less than',
            operator: '<',
            value: 'has_ls'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been less than',
            operator: '<',
            value: 'has_not_ls'
          }));

          // historical conditions for Sum
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that are equal to',
            operator: '=',
            value: 'has_eq_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that are not equal to',
            operator: '=',
            value: 'has_not_eq_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have been greater than',
            operator: '>',
            value: 'has_gr_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have not been greater than',
            operator: '>',
            value: 'has_not_gr_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have been less than',
            operator: '<',
            value: 'has_ls_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have not been less than',
            operator: '<',
            value: 'has_not_ls_sum'
          }));

          // predicates.pushObject(EmberObject.create({
          //   label: 'Has been greater than or equal to', operator: '>=', value: 'has_greq',
          // }));

          // predicates.pushObject(EmberObject.create({
          //   label: 'Has been less than or equal to', operator: '<=', value: 'has_lseq',
          // }));
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          break;
        case 'string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringEq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Is not',
              operator: '!=',
              value: 'stringNeq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Contains',
              operator: '=',
              value: 'stringContains'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringEq'
            }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has not been', operator: '!=', value: 'has_stringNeq',
            // }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has contained', operator: '=', value: 'has_stringContains',
            // }));
          } else {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringFoldEq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Is not',
              operator: '!=',
              value: 'stringFoldNeq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Contains',
              operator: '=',
              value: 'stringFoldContains'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringFoldEq'
            }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has not been', operator: '!=', value: 'has_stringFoldNeq',
            // }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has contained', operator: '=', value: 'has_stringFoldContains',
            // }));
          }
      }

      return predicates;
    },
    setupAttribute: function setupAttribute(attribute) {
      attribute.setProperties({
        frequencyOptions: this.getFrequencyOptions(),
        recencyOptions: this.getRecencyOptions(),
        comparisonPredicates: this.getPredicates(attribute),
        isCaseSensitiveVisible: false,
        isSupported: false
      });
      if (this.model.frequencyOption) {
        this.model.set('frequencyOption', attribute.frequencyOptions.filterBy('value', this.model.frequencyOption.value).objectAt(0));
      } else {
        this.model.set('frequencyOption', attribute.frequencyOptions.objectAt(0));
      }
      if (Ember.isNone(this.model.frequencyValue)) {
        this.model.set('frequencyValue', '1');
      }
      if (!this.model.recencyOption) {
        this.model.set('recencyOption', attribute.recencyOptions.objectAt(0));
      }
      if (!this.model.recencyValue) {
        this.model.set('recencyValue', new Date());
      }
      if (!this.model.recencyValueFrom) {
        this.model.set('recencyValueFrom', new Date());
        this.model.set('recencyValueTo', new Date());
      }
      if (attribute.uid.indexOf('inArea') >= 0 || attribute.uid.indexOf('inApproximateArea') >= 0) {
        attribute.set('isInArea', true);
      } else if ((0, _nearbyLocation.isLabelLocation)(attribute.uid)) {
        attribute.set('IsInLocationWithLabel', true);
      }
      if (!Ember.isNone(this.model.value) && !Ember.isEmpty(attribute.valueTypeOptions)) {
        var option = attribute.valueTypeOptions.filterBy('value', this.model.value).get('firstObject');
        if (option) {
          this.model.set('valueDropdownOption', option);
        }
      }
      if (!Ember.isEmpty(this.model.parameters)) {
        this.model.parameters.forEach(function (item) {
          if (!Ember.isNone(item.value) && !Ember.isEmpty(item.valueTypeOptions)) {
            var _option = item.valueTypeOptions.filterBy('value', item.value).get('firstObject');
            if (_option) {
              item.set('valueDropdownOption', _option);
            }
          }
        });
      }
      var yesNoOption = null;
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          attribute.setProperties({
            isArrayType: true,
            isSupported: false
          });
          break;
        case '[]string':
          attribute.setProperties({
            isArrayType: true,
            isCaseSensitiveVisible: false,
            isSupported: true
          });
          break;
        case 'bool':
          attribute.setProperties({
            isSupported: true,
            isYesNoType: true
          });
          attribute.set('yesNoOptions', [Ember.Object.create({
            label: 'true',
            value: 'true'
          }), Ember.Object.create({
            label: 'false',
            value: 'false'
          })]);
          if (!Ember.isNone(this.model.yesNoOption) || !Ember.isNone(this.model.value)) {
            var yesNoOptionValue = null;
            if (!Ember.isNone(this.model.value)) {
              yesNoOptionValue = this.model.value;
            } else {
              yesNoOptionValue = this.model.yesNoOption.value;
            }
            yesNoOption = attribute.yesNoOptions.filterBy('value', "".concat(yesNoOptionValue)).objectAt(0);
          } else if (this.model.selectedAttribute) {
            yesNoOption = attribute.yesNoOptions.objectAt(0);
          }
          if (yesNoOption) {
            this.model.set('value', yesNoOption.value);
            this.model.set('yesNoOption', yesNoOption);
          }
          break;
        case 'float':
        case 'int':
          if (attribute.isTimeContext) {
            attribute.setProperties({
              isDateTimeType: true,
              isSupported: true
            });
          } else {
            attribute.setProperties({
              isNumberType: true,
              isSupported: true
            });
          }
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          attribute.setProperties({
            isHashMapType: true,
            isSupported: false
          });
          break;
        case 'string':
          attribute.setProperties({
            isCaseSensitiveVisible: true,
            isSupported: true,
            isTextType: true
          });
          break;
      }
    },
    actions: {
      addLocation: function addLocation() {
        this.set('isAddLocation', true);
      },
      cancelLocation: function cancelLocation() {
        this.set('isAddLocation', false);
      },
      saveLocation: function saveLocation(location) {
        if (this.model.selectedAttribute) {
          this.model.selectedAttribute.setProperties({
            location: location,
            value: location.id
          });
        }
        this.set('isAddLocation', false);
      },
      updateSelectedDate: function updateSelectedDate(event, unixDate) {
        this.model.set('value', parseInt(unixDate));
      },
      didUpdateAttributeLocation: function didUpdateAttributeLocation(location) {
        this.model.selectedAttribute.setProperties({
          location: location,
          value: location.id
        });
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      addExpressionItem: function addExpressionItem(operator) {
        var expressionItems = this.get('model.expressionItems');
        if (expressionItems) {
          expressionItems.pushObject(Ember.Object.create({
            operator: operator,
            attribute: null
          }));
        }
      },
      selectValue: function selectValue(model, variable, selection) {
        model.set(variable, selection);
      },
      selectYesNoOption: function selectYesNoOption(item) {
        this.model.set('value', item.value);
        this.model.set('yesNoOption', item);
      },
      selectValueTypeOption: function selectValueTypeOption(item) {
        this.model.set('valueDropdownOption', item);
        this.model.set('value', item.value);
      },
      selectParameterValueTypeOption: function selectParameterValueTypeOption(item, option) {
        item.set('valueDropdownOption', option);
        item.set('value', option.value);
      },
      setItemValue: function setItemValue(item, value) {
        item.set('value', value);
      },
      removeExpressionItem: function removeExpressionItem(item) {
        var expressionItems = this.get('model.expressionItems');
        if (!Ember.isEmpty(expressionItems)) {
          expressionItems.removeObject(item);
        }
      },
      selectAttribute: function selectAttribute() {
        var model = this.model;
        model.set('selectedAttribute.isSelected', false);
        model.setProperties({
          comparisonPredicate: null,
          description: null,
          selectedAttribute: null,
          value: null
        });
      },
      updateRecencyValue: function updateRecencyValue(property, date) {
        this.model.set(property, date);
      }
    }
  });
  _exports.default = _default;
});