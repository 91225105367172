define("experience-studio/mixins/time-conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    convertSecondsToPeriodObject: function convertSecondsToPeriodObject(valueInSeconds) {
      var secondsInDay = window.moment.duration(1, 'days').asSeconds();
      var secondsInHour = window.moment.duration(1, 'hours').asSeconds();
      var secondsInMinute = window.moment.duration(1, 'minutes').asSeconds();
      var secondsInMonth = window.moment.duration(1, 'months').asSeconds();
      var secondsInWeek = window.moment.duration(1, 'weeks').asSeconds();
      var secondsInYear = window.moment.duration(1, 'years').asSeconds();
      var unit = null;
      var value = null;

      // In years
      if (!unit && valueInSeconds >= secondsInYear) {
        value = parseFloat((valueInSeconds / secondsInYear).toFixed(2));
        if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
          unit = 'years';
        }
      }

      // In months
      if (!unit && valueInSeconds >= secondsInMonth) {
        value = parseFloat((valueInSeconds / secondsInMonth).toFixed(2));
        if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
          unit = 'months';
        }
      }

      // In weeks
      if (!unit && valueInSeconds >= secondsInWeek) {
        value = parseFloat((valueInSeconds / secondsInWeek).toFixed(2));
        if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
          unit = 'weeks';
        }
      }

      // In days
      if (!unit && valueInSeconds >= secondsInDay) {
        value = parseFloat((valueInSeconds / secondsInDay).toFixed(2));
        if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
          unit = 'days';
        }
      }

      // In hours
      if (!unit && valueInSeconds >= secondsInHour) {
        value = parseFloat((valueInSeconds / secondsInHour).toFixed(2));
        if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
          unit = 'hours';
        }
      }

      // In minutes
      if (!unit && valueInSeconds >= secondsInMinute) {
        value = parseFloat((valueInSeconds / secondsInMinute).toFixed(2));
        if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
          unit = 'minutes';
        }
      }
      if (value % 1 !== 0 && (value - 0.1) % 1 === 0) {
        value = Math.round(value);
      }
      if (!unit) {
        unit = 'seconds';
        value = valueInSeconds;
      }
      return Ember.Object.create({
        value: value,
        unit: unit
      });
    },
    convertToSeconds: function convertToSeconds(time, period) {
      var secondsInMinute = window.moment.duration(1, 'minutes').asSeconds();
      var secondsInHour = window.moment.duration(1, 'hours').asSeconds();
      var secondsInDay = window.moment.duration(1, 'days').asSeconds();
      var secondsInWeek = window.moment.duration(1, 'weeks').asSeconds();
      var secondsInMonth = window.moment.duration(1, 'months').asSeconds();
      var secondsInYear = window.moment.duration(1, 'years').asSeconds();
      if (period === 'minutes') {
        time = time * secondsInMinute;
      }
      if (period === 'hours') {
        time = time * secondsInHour;
      }
      if (period === 'days') {
        time = time * secondsInDay;
      }
      if (period === 'weeks') {
        time = time * secondsInWeek;
      }
      if (period === 'months') {
        time = time * secondsInMonth;
      }
      if (period === 'years') {
        time = time * secondsInYear;
      }
      return time;
    }
  });
  _exports.default = _default;
});