define("experience-studio/components/content-form-editor-number", ["exports", "experience-studio/components/content-form-editor-dropped-item"], function (_exports, _contentFormEditorDroppedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _contentFormEditorDroppedItem.default.extend({
    classNames: ['content-form-editor-number'],
    model: null
  });
  _exports.default = _default;
});