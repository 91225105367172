define("experience-studio/services/context-rule-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    timezone: Ember.inject.service(),
    getRule: function getRule(r) {
      var rule = null;
      if (Array.isArray(r) && r.length) {
        rule = r[0];
      } else if (r) {
        rule = r;
      } else if (r.content) {
        rule = r.content;
      }
      return rule;
    },
    getAbsoluteStartSchedule: function getAbsoluteStartSchedule(r) {
      var rule = this.getRule(r);
      return rule && rule.hasAbsoluteStart ? this.timezone.getAbsoluteScheduleWithLocalTimezone(rule.schedule.startScheduler) : null;
    },
    getAbsoluteEndSchedule: function getAbsoluteEndSchedule(r) {
      var rule = this.getRule(r);
      return rule && rule.hasAbsoluteEnd ? this.timezone.getAbsoluteScheduleWithLocalTimezone(rule.schedule.endScheduler) : null;
    },
    hasStartSchedule: function hasStartSchedule(r) {
      return this.hasRuleStartSchedule(r) || this.hasPluginStartSchedule(r) || this.hasAbsoluteStartSchedule(r);
    },
    hasAbsoluteStartSchedule: function hasAbsoluteStartSchedule(r) {
      var rule = this.getRule(r);
      return rule ? rule.hasAbsoluteStart : false;
    },
    hasRuleStartSchedule: function hasRuleStartSchedule(r) {
      var rule = this.getRule(r);
      if (rule && rule.schedule && rule.schedule.startScheduler && rule.schedule.startScheduler.contextualRuleStart && rule.schedule.startScheduler.contextualRuleStart.stringRepresentation) {
        return true;
      } else {
        return rule.contextualStartType === 'rule';
      }
    },
    hasPluginStartSchedule: function hasPluginStartSchedule(r) {
      var rule = this.getRule(r);
      if (rule && rule.schedule && rule.schedule.startScheduler && rule.schedule.startScheduler.contextualStart) {
        return true;
      } else {
        return rule.contextualStartType === 'plugin';
      }
    },
    isConciergeTemplate: function isConciergeTemplate(templateType) {
      var validTypes = ['concierge-card-buttons', 'concierge-card-link'];
      return validTypes.includes(templateType);
    }
  });
  _exports.default = _default;
});