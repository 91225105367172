define("experience-studio/services/user-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    preferences: null,
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.preferences = [];
    },
    fetch: function fetch() {
      var _this = this;
      if (Ember.isEmpty(this.preferences)) {
        return this.store.findAll('user-preference').then(function (records) {
          _this.set('preferences', records);
          return records;
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve(_this.preferences);
        });
      }
    },
    getPreference: function getPreference(id) {
      var _this2 = this;
      var filteredItems = [];
      if (Ember.isEmpty(this.preferences)) {
        this.fetch().then(function () {
          filteredItems = _this2.preferences.filterBy('id', id);
        });
      } else {
        filteredItems = this.preferences.filterBy('id', id);
      }
      if (filteredItems.length > 0) {
        return filteredItems.objectAt(0);
      } else {
        return null;
      }
    },
    getValue: function getValue(id) {
      var preference = this.getPreference(id);
      if (preference) {
        if (id === 'richTextFormatting') {
          window.localStorage.setItem('isRichTextEnabled', preference.get('value'));
        }
        return preference.get('value');
      }
      return null;
    }
  });
  _exports.default = _default;
});