define("experience-studio/templates/components/permissions-hub-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "urGSax8M",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[2,\"\\n  \"],[10,\"li\"],[12],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,1],[[35,0],\"active\"],null]]]],[4,[38,2],[[32,0],\"select\",\"isAccessLevelsTabSelected\"],null],[12],[2,\"Access Levels\"],[13],[13],[2,\"\\n  \"],[10,\"li\"],[12],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,1],[[35,3],\"active\"],null]]]],[4,[38,2],[[32,0],\"select\",\"isUserAccountsTabSelected\"],null],[12],[2,\"Users\"],[13],[13],[2,\"\\n  \"],[10,\"li\"],[12],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,1],[[35,4],\"active\"],null]]]],[4,[38,2],[[32,0],\"select\",\"isRolesTabSelected\"],null],[12],[2,\"Roles\"],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isAccessLevelsTabSelected\",\"if\",\"action\",\"isUserAccountsTabSelected\",\"isRolesTabSelected\"]}",
    "moduleName": "experience-studio/templates/components/permissions-hub-tabs.hbs"
  });
  _exports.default = _default;
});