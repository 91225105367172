define("experience-studio/components/content-template-editor-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Component.extend({
    classNames: ['content-template-editor-preview'],
    previewData: null,
    contentInstance: null,
    contentInstanceUrl: null,
    isImageVisible: false,
    selectedMedia: '',
    updateContentInstanceDetailViewValues: Ember.observer('contentInstanceUrlSchema.link.schema.@each.value', 'contentInstanceUrlSchema.details.schema.@each.value', 'contentInstanceUrlSchema.media.schema.@each.value', 'dropdownObject.buttons0.schema.@each.value', 'dropdownObject.buttons1.schema.@each.value', 'dropdownObject.buttons2.schema.@each.value', 'dropdownObject.buttons3.schema.@each.value', 'dropdownObject.buttons4.schema.@each.value', 'dropdownObject.buttons5.schema.@each.value', 'contentInstance.schema.@each.value', function () {
      this.loadPreview();
    }),
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    setPreviewImageVisibility: function setPreviewImageVisibility(value) {
      if (value) {
        this.set('isPreviewEmpty', false);
      }
      this.set('isImageVisible', false);
      if (this.contentInstance && this.contentInstance.image && this.contentInstance.image[this.selectedLanguageCode]) {
        this.set('isImageVisible', this.contentInstance.image[this.selectedLanguageCode].image);
      }
      if (this.contentInstance && this.contentInstance.media) {
        this.set('isImageVisible', false);
      }
    },
    isPreviewEmpty: Ember.computed('previewData', function () {
      var _this = this;
      var isPreviewEmpty = false;
      if (this.previewData && _typeof(this.previewData) === 'object') {
        isPreviewEmpty = true;
        var _loop = function _loop(field) {
          if (_this.previewData.hasOwnProperty(field)) {
            var data = _this.previewData[field];
            if (data && _typeof(data) === 'object') {
              _this.languages.forEach(function (lang) {
                if (data[lang.id]) {
                  if (_typeof(data[lang.id]) === 'object') {
                    if (data[lang.id][field]) {
                      isPreviewEmpty = false;
                    }
                  } else {
                    isPreviewEmpty = false;
                  }
                }
              });
            }
          }
        };
        for (var field in this.previewData) {
          _loop(field);
        }
      }
      return isPreviewEmpty;
    }),
    loadPreview: function loadPreview() {
      var schema = this.get('contentInstance.schema');
      var fieldValues = {};
      var self = this;
      var selectedLang = this.languages.find(function (element) {
        return element.isSelected;
      });
      if (schema) {
        schema.forEach(function (field) {
          if (field.type === 'ctobject') {
            fieldValues[field.key] = {};
            field.schema.forEach(function (item) {
              switch (item.key) {
                case 'text':
                  fieldValues[field.key] = item.value;
                  break;
                case 'body':
                  fieldValues[field.key] = item.value;
                  break;
              }
            });
          } else if (field.type === 'ctobjarray') {
            field.value.forEach(function (value, index) {
              fieldValues[field.key + index] = value.schema[0].value;
            });
          } else if (field.type === 'cttext') {
            if (selectedLang) {
              fieldValues[field.key] = field.value;
            }
          } else if (field.type === 'ctlocalizedmedia') {
            fieldValues[field.key] = {};
            field.schema.forEach(function (item) {
              switch (item.key) {
                case 'mediaType':
                  self.set('selectedMedia', item.value);
                  break;
                case 'resourceURL':
                  fieldValues[field.key] = item.value;
                  break;
              }
            });
          } else {
            fieldValues[field.key] = field.value;
          }
        });
        this.set('previewData', fieldValues);
      }
    },
    didInsertElement: function didInsertElement() {
      var attributes = [];
      this.plugins.forEach(function (plugin) {
        var pluginAttributes = plugin.get('attributes');
        var keys = window.Object.keys(pluginAttributes);
        if (pluginAttributes && !Ember.isEmpty(keys)) {
          keys.forEach(function (key) {
            var pluginAttribute = pluginAttributes.get(key);
            attributes.pushObject(Ember.Object.create({
              label: "".concat(plugin.get('name')),
              pluginAttribute: "".concat(pluginAttribute.get('name')),
              value: "".concat(pluginAttribute.get('uid')),
              valueType: "".concat(pluginAttribute.get('valueType'))
            }));
          });
        }
      });
      this.set('contentPluginList', attributes);
    }
  });
  _exports.default = _default;
});