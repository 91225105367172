define("experience-studio/adapters/triggered-email", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'push/email',
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('triggered-emails', 'templates');
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return "".concat(url, "?limit=").concat(this.paginationLimit);
    }
  });
  _exports.default = _default;
});