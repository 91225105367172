define("experience-studio/helpers/blacklist-sites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.blacklistSites = blacklistSites;
  // blacklisted sites
  function blacklistSites() {
    var domain = window.location.origin;
    var blacklist = ['https://dev-td-us-flybits.flybits.com', 'https://td-us.flybits.com', 'https://tddev3.flybits.com', 'https://tdint3.flybits.com', 'https://tdpat3.flybits.com', 'https://tdcanada3.flybits.com', 'https://tdi-dev.flybits.com', 'https://tdi-pat.flybits.com', 'https://tdi-prod.flybits.com', 'https://ces-dev.flybits.com', 'https://ces-stage.flybits.com', 'https://ces-mtf.flybits.com', 'https://ces-prerelease.flybits.com', 'https://ces-prod.flybits.com ', 'https://boschnextdevelopment.flybits.com', 'https://boschnextq.flybits.com', 'https://boschnext.flybits.com', 'https://dev-boschnext.flybits.com', 'https://qa-boschnext.flybits.com', 'https://prod-boschnext.flybits.com', 'https://lennar.flybits.com', 'https://fivepoint-dev.flybits.com', 'https://fivepoint.flybits.com', 'https://members1st-dev.flybits.com', 'https://members1st-staging.flybits.com', 'https://members1st.flybits.com', 'https://rmhc-dev.flybits.com', 'https://canadalife-dev.canada.flybits.com', 'https://canadalife-staging.canada.flybits.com', 'https://canadalife.canada.flybits.com', 'http://fab-dev.flybits.com/', 'https://liv-dev.v3-europe.flybits.com/', 'https://diebold-dev.flybits.com', 'https://diebold-staging.flybits.com', 'https://diebold.flybits.com'];
    if (blacklist.includes(domain)) {
      return false;
    }
    return true;
  }
});