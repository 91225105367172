define("experience-studio/templates/components/model-list-context-data-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CLz9zHmn",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"model\",\"dispatchAction\"],[[32,1],\"dispatchAction\"]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"no-model-message no-model-graphic-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"graphic\"],[12],[13],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Context Data Upload Files\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model-list-item-context-data-v2\",\"model\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/model-list-context-data-v2.hbs"
  });
  _exports.default = _default;
});