define("experience-studio/components/filter-pill-modified-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['filter-pill'],
    value: Ember.computed('model.filters.[]', function () {
      var modifiedAtFromFilter = this.model.filters.findBy('id', 'modifiedAtFrom');
      var modifiedAtToFilter = this.model.filters.findBy('id', 'modifiedAtTo');
      if (!Ember.isNone(modifiedAtFromFilter) && !Ember.isNone(modifiedAtToFilter)) {
        var modifiedAtFromValue = window.moment.unix(modifiedAtFromFilter.value).format('MMM D/Y, h:mma');
        var modifiedAtToValue = window.moment.unix(modifiedAtToFilter.value).format('MMM D/Y, h:mma');
        return "Modified between ".concat(modifiedAtFromValue, " - ").concat(modifiedAtToValue);
      } else if (!Ember.isNone(modifiedAtFromFilter)) {
        var _modifiedAtFromValue = window.moment.unix(modifiedAtFromFilter.value).format('MMM D/Y, h:mma');
        return "Modified after ".concat(_modifiedAtFromValue);
      } else if (!Ember.isNone(modifiedAtToFilter)) {
        var _modifiedAtToValue = window.moment.unix(modifiedAtToFilter.value).format('MMM D/Y, h:mma');
        return "Modified before ".concat(_modifiedAtToValue);
      }
    }),
    actions: {
      clearPill: function clearPill() {
        if (this.didUpdateQueryParams && this.model.filters.length) {
          var filters = this.model.filters;
          var parametersToModify = {};
          filters.forEach(function (filter) {
            parametersToModify[filter.id] = null;
          });
          this.didUpdateQueryParams(parametersToModify);
        }
      }
    }
  });
  _exports.default = _default;
});