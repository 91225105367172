define("experience-studio/mixins/route-data-query-param", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    utility: Ember.inject.service(),
    updateDataQueryParam: function updateDataQueryParam() {
      this.controller.setProperties({
        data: this.controller._data,
        isUpdatingDataQueryParam: false
      });
    },
    actions: {
      updateQueryParams: function updateQueryParams(newData) {
        var data = {};
        if (this.controller.data) {
          var currentData = this.utility.atob(this.controller.data);
          if (currentData) {
            window.Object.assign(data, currentData);
          }
        }
        window.Object.assign(data, newData);

        // If key is set to null remove key
        window.Object.keys(data).forEach(function (key) {
          if (Ember.isNone(data[key])) {
            delete data[key];
          }
        });
        this.controller.setProperties({
          _data: this.utility.btoa(data),
          isUpdatingDataQueryParam: true
        });
        Ember.run.debounce(this, function () {
          this.updateDataQueryParam();
          this.send('aggregateModel', 'experience', 'experiences');
        }, 400);
      }
    }
  });
  _exports.default = _default;
});