define("experience-studio/mixins/route-model-batch-actions-base", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    utility: Ember.inject.service(),
    createBatchRequest: function createBatchRequest(records, parents, callback) {
      var _this = this;
      var recordsToRequest;
      if (parents) {
        recordsToRequest = parents;
      } else {
        recordsToRequest = records;
      }
      var length = recordsToRequest.length;
      var limit = _environment.default.APP.batchActionsLimit;
      var delay = _environment.default.APP.batchActionsDelay;
      var requests = [];
      var _loop = function _loop(i) {
        var recordSubset = recordsToRequest.slice(i * limit, i * limit + limit);
        var defferedRequest = Ember.RSVP.defer();
        Ember.run.later(_this, function () {
          var recordPromises = recordSubset.map(function (record) {
            return new Promise(function (resolve, reject) {
              callback(record, records).then(function () {
                resolve(record);
              }).catch(function (error) {
                reject(Ember.Object.create({
                  error: _this.utility.parseError(error),
                  value: record
                }));
              });
            });
          });
          defferedRequest.resolve(Ember.RSVP.allSettled(recordPromises));
        }, delay * i);
        requests.pushObject(defferedRequest);
      };
      for (var i = 0; i * limit < length; i++) {
        _loop(i);
      }
      return requests;
    },
    actions: {
      confirmCloseBarModal: function confirmCloseBarModal(params) {
        if (params.shouldDisplayModal) {
          this.send('showModal', Ember.Object.create({
            modalName: 'confirmation-modal-dialog-v2',
            title: "Deselect ".concat(params.pluralEntityName),
            primaryMessage: 'Are you sure you want to deselect all?',
            primaryAction: {
              action: 'closeBatchBar'
            }
          }));
        } else {
          this.send('closeBatchBar');
        }
      },
      closeBatchBar: function closeBatchBar() {
        this.send('deselectModelListItems');
        this.send('hideModal');
      },
      associateRecords: function associateRecords(params) {
        var _this2 = this;
        var records = params.records;
        var experiences = params.experiences;
        var isAssociating = params.isAssociating;
        var associateVar = params.associateVar;
        var requests = this.createBatchRequest(records, experiences, function (experience, records) {
          if (isAssociating) {
            experience.set(associateVar, experience.get(associateVar).concat(records.mapBy('id')).uniq());
          } else {
            experience.get(associateVar).removeObjects(records.mapBy('id'));
          }
          return experience.save();
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this2.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            records: records,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            confirmModalTitle: params.confirmModalTitle,
            retryAction: isAssociating ? 'showAssociateRecordsModal' : 'showDisassociateRecordsModal',
            finishAction: 'finalizeRecordsAction',
            failedPostfix: 'associate.',
            sucessPostfix: 'associated!',
            type: 'associate',
            total: records.length
          }));
        });
      },
      groupRecords: function groupRecords(params) {
        var _this3 = this;
        var records = params.records;
        var groups = params.groups;
        var isAdding = params.isAdding;
        var requests = this.createBatchRequest(records, groups, function (group, records) {
          if (isAdding) {
            group.set('experienceIds', group.experienceIds.concat(records.mapBy('id')).uniq());
          } else {
            group.experienceIds.removeObjects(records.mapBy('id'));
          }
          return group.save();
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this3.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            records: records,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            confirmModalTitle: params.confirmModalTitle,
            retryAction: isAdding ? 'showAddGroupsRecordsModal' : 'showRemoveGroupsRecordsModal',
            finishAction: 'finalizeRecordsAction',
            failedPostfix: 'group.',
            sucessPostfix: isAdding ? 'grouped!' : 'ungrouped.',
            type: 'group',
            total: records.length
          }));
        });
      },
      deleteRecords: function deleteRecords(params) {
        var _this4 = this;
        var records = params.records;
        var requests = this.createBatchRequest(records, null, function (record) {
          return record.destroyRecord();
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this4.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            confirmModalTitle: params.confirmModalTitle,
            records: records,
            retryAction: 'showDeleteRecordsModal',
            finishAction: 'finalizeRecordsAction',
            failedPostfix: 'delete.',
            sucessPostfix: 'deleted!',
            total: records.length
          }));
        });
      },
      fetchLabelsBatch: function fetchLabelsBatch(params) {
        this.store.query('label', {
          search: params.query
        }).then(function (result) {
          params.labelPromise.resolve(result);
        });
      },
      labelRecords: function labelRecords(params) {
        var _this5 = this;
        var records = params.records;
        var labels = params.labels;
        var isAdding = params.isAdding;
        var labelAdapter = this.store.adapterFor('label');

        // Serialize newly created labels
        var newLabels = [];
        labels.forEach(function (label) {
          if (label.isCreated) {
            var record = _this5.store.createRecord('label');
            record.set('value', label.value);
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          var requests = _this5.createBatchRequest(labels, records, function (record, labels) {
            var recordLabels;
            if (isAdding) {
              recordLabels = record.labels.concat(labels.mapBy('value')).uniq();
            } else {
              recordLabels = record.labels.removeObjects(labels.mapBy('value'));
            }
            record.set('labels', recordLabels);
            return record.save();
          });
          Ember.run.scheduleOnce('afterRender', function () {
            _this5.send('showModal', Ember.Object.create({
              modalName: 'perform-action-modal-dialog',
              requests: requests,
              records: records,
              singularEntityName: params.singularEntityName,
              pluralEntityName: params.pluralEntityName,
              confirmModalTitle: params.confirmModalTitle,
              retryAction: isAdding ? 'showAddLabelRecordsModal' : 'showRemoveLabelRecordsModal',
              finishAction: 'finalizeRecordsAction',
              failedPostfix: 'label.',
              sucessPostfix: isAdding ? 'labelled!' : 'unlabled.',
              total: records.length
            }));
          });
        }).catch(function (response) {
          Ember.run.scheduleOnce('afterRender', function () {
            _this5.send('showModal', Ember.Object.create({
              modalName: 'perform-action-modal-dialog',
              requests: [],
              singularEntityName: params.singularEntityName,
              pluralEntityName: params.pluralEntityName,
              confirmModalTitle: params.confirmModalTitle,
              retryAction: isAdding ? 'showAddLabelRecordsModal' : 'showRemoveLabelRecordsModal',
              finishAction: 'finalizeRecordsAction',
              failedPostfix: 'label.',
              sucessPostfix: 'labelled!',
              remainderFailure: records,
              remainderFailureMessage: _this5.utility.parseError(response),
              total: records.length
            }));
          });
        });
      },
      finalizeRecordsAction: function finalizeRecordsAction(params) {
        var _this6 = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          if (params.numFailure > 0) {
            _this6.send('showNotification', {
              type: 'alert',
              message: "".concat(params.numFailure, " ").concat(params.pluralEntityName, " failed to ").concat(params.failedPostfix)
            });
            if (!Ember.isEmpty(params.records)) {
              params.records.forEach(function (record) {
                return record.rollbackAttributes();
              });
            }
          }
          if (params.numSuccess > 0) {
            _this6.send('showNotification', {
              type: 'confirm',
              message: "".concat(params.numSuccess, " ").concat(params.pluralEntityName, " successfully ").concat(params.sucessPostfix)
            });
          }
          if (_this6.refreshModels) {
            _this6.refreshModels();
          }
        });
      },
      performModelBatchAction: function performModelBatchAction(batchAction) {
        this.send(batchAction.action, batchAction);
      }
    }
  });
  _exports.default = _default;
});