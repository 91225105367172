define("experience-studio/controllers/context-hub/rule/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    data: '',
    queryParams: [{
      data: 'data'
    }],
    userPreferences: Ember.inject.service(),
    isHistoricalContextPluginEditorVisiblePreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('isHistoricalContextPluginEditorVisible');
    }),
    isHistoricalContextPluginEditorVisible: Ember.computed('isHistoricalContextPluginEditorVisiblePreference.value', function () {
      var value = false;
      if (this.isHistoricalContextPluginEditorVisiblePreference) {
        value = this.isHistoricalContextPluginEditorVisiblePreference.get('value');
      }
      return value;
    }),
    filteredModel: Ember.computed('isRefreshFilterModel', 'data', function () {
      return this.store.query('location', {
        data: this.data
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    }),
    filterDropdownSpecification: Object.freeze([Ember.Object.create({
      title: '',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-model-select',
        label: '',
        options: [Ember.Object.create({
          modelName: 'label',
          // used to look up model in store
          modelRepresentation: ['name'],
          // used to retrieve id or value to be saved in param
          value: undefined,
          label: 'Labels',
          property: 'labels'
        })],
        incompatibleProperties: [],
        disabledOnProperties: []
      })]
    }), Ember.Object.create({
      title: 'Created by',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-user-select',
        label: '',
        options: [Ember.Object.create({
          modelName: 'label',
          // used to look up model in store
          modelRepresentation: ['name'],
          // used to retrieve id or value to be saved in param
          value: undefined,
          label: 'User',
          property: 'labels'
        })],
        incompatibleProperties: [],
        disabledOnProperties: []
      }), Ember.Object.create({
        component: 'route-model-filter-dropdown-checkbox-select',
        options: [Ember.Object.create({
          isSelected: false,
          value: true,
          label: 'Me',
          property: 'isActive'
        })],
        incompatibleProperties: [],
        disabledOnProperties: []
      })]
    }), Ember.Object.create({
      title: 'Date',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Created',
        options: [Ember.Object.create({
          value: undefined,
          property: 'createdAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'createdAtTo'
        })],
        incompatibleProperties: [],
        disabledOnProperties: ['']
      }), Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Modified',
        options: [Ember.Object.create({
          value: undefined,
          property: 'modifiedAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'modifiedAtTo'
        })],
        incompatibleProperties: [],
        disabledOnProperties: ['']
      })]
    })]),
    paramTitleMap: Object.freeze([Ember.Object.create({
      id: 'createdAt',
      isMultiParam: true,
      getDisplayValue: function getDisplayValue(value) {
        var createdAtFrom = window.moment.unix(value.createdAtFrom).format('MMM D/Y, h:mma');
        var createdAtTo = window.moment.unix(value.createdAtTo).format('MMM D/Y, h:mma');
        return "Created between ".concat(createdAtFrom, " - ").concat(createdAtTo);
      }
    }), Ember.Object.create({
      id: 'modifiedAt',
      isMultiParam: true,
      getDisplayValue: function getDisplayValue(value) {
        var createdAtFrom = window.moment.unix(value.createdAtFrom).format('MMM D/Y, h:mma');
        var createdAtTo = window.moment.unix(value.createdAtTo).format('MMM D/Y, h:mma');
        return "Modified between ".concat(createdAtFrom, " - ").concat(createdAtTo);
      }
    }), Ember.Object.create({
      id: 'labels',
      isGroup: true,
      getDisplayValue: function getDisplayValue(value) {
        return "Label : ".concat(value.name); // additional formatting may be required
      }
    })])
  });
  _exports.default = _default;
});