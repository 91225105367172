define("experience-studio/services/push-tour-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // PROPERTIES ----------------------------------------------------------------
    userPreferences: Ember.inject.service(),
    pushTourData: null,
    isMenuIconLoaded: false,
    shepherd: null,
    shepherd2: null,
    // COMPUTED ------------------------------------------------------------------
    pushMessageV1Preference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('pushMessageV1');
    }),
    pushMessageV2TriggeredPreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('pushMessageV2Triggered');
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        pushTourData: [Ember.Object.create({
          stepName: 'broadcast-schedule',
          opt: Ember.Object.create({
            title: 'Send at later date/time',
            text: "<p>You are now able to send Broadcast\n                  Push Notifications at a scheduled date/time, in any timezone.</p>\n                  <p>This allows you to automate the delivery of one-off messages.</p>",
            attachTo: {
              element: '.target-datepicker',
              on: 'top'
            },
            classes: 'welcome-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'NEXT'
            }]
          })
        }), Ember.Object.create({
          stepName: 'everyone-option',
          opt: Ember.Object.create({
            title: 'Send manually',
            text: "<p>You can still choose to send a push notification manually\n                  using the \"Immediately\" option.</p>",
            attachTo: {
              element: '.target-everyone-option',
              on: 'top'
            },
            classes: 'users-class',
            buttons: [{
              action: 'next',
              text: 'GOT IT'
            }]
          })
        })],
        pushTourData2: [Ember.Object.create({
          stepName: 'triggered-schedule',
          opt: Ember.Object.create({
            title: "We've updated the Delivery section!",
            text: "<p>Creating a notification is even easier with a new streamlined process.\n                      We've also added new features that help with automating your message delivery.</p>",
            attachTo: {
              element: '.target-send-method',
              on: 'top'
            },
            classes: 'welcome-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'NEXT'
            }]
          })
        }), Ember.Object.create({
          stepName: 'everyone-option',
          opt: Ember.Object.create({
            title: 'Audience-based options',
            text: "<p>Select your method of sending based upon your audience's actions/events.</p>",
            attachTo: {
              element: '.target-contextual-selector',
              on: 'top'
            },
            classes: 'users-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'NEXT'
            }]
          })
        }), Ember.Object.create({
          stepName: 'everyone-option',
          opt: Ember.Object.create({
            title: 'Target remaining audience members',
            text: "<p>Turning this on will allow audience members to continuously receive this message,\n                      so long as they continue to meet the audience criteria you've selected.</p>",
            attachTo: {
              element: '.target-users-who-remain-in-audience',
              on: 'top'
            },
            classes: 'users-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'NEXT'
            }]
          })
        }), Ember.Object.create({
          stepName: 'everyone-option',
          opt: Ember.Object.create({
            title: 'Message amount',
            text: "<p>Choose the amount of messages your target audience members will receive within the set message schedule.</p>",
            attachTo: {
              element: '.target-message-amount-intervals',
              on: 'top'
            },
            classes: 'users-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'NEXT'
            }]
          })
        }), Ember.Object.create({
          stepName: 'everyone-option',
          opt: Ember.Object.create({
            title: 'Message Delay',
            text: "<p>Set a delay before each message that is sent to your audience members.\n                      This lets you decide how soon a user receives each message after meeting the audience criteria.</p>",
            attachTo: {
              element: '.target-wait-before-sending',
              on: 'top'
            },
            classes: 'users-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'NEXT'
            }]
          })
        }), Ember.Object.create({
          stepName: 'everyone-option',
          opt: Ember.Object.create({
            title: 'Message Interval',
            text: "<p>Set a time interval between each notification that is sent to your audience members.\n                      This will set how frequently the audience receives this message, based on the chosen message amount.</p>",
            attachTo: {
              element: '.target-wait-at-least',
              on: 'top'
            },
            classes: 'users-class',
            buttons: [{
              action: 'next',
              text: 'GOT IT'
            }]
          })
        })]
      });
    },
    // FUNCTIONS -----------------------------------------------------------------
    setupShepherd: function setupShepherd() {
      var _this = this;
      var shepherd = new window.Shepherd.Tour({
        defaultStepOptions: {
          scrollTo: {
            behavior: 'smooth',
            block: 'center'
          },
          showCancelLink: true,
          tippyOptions: {
            maxWidth: 400
          }
        },
        styleVariables: {
          useDropShadow: true
        },
        useModalOverlay: true
      });
      this.pushTourData.forEach(function (item) {
        if (item.opt.buttons) {
          item.opt.buttons.forEach(function (btn) {
            if (btn.action === 'cancel') {
              btn.action = shepherd.cancel;
            } else if (btn.action === 'next') {
              btn.action = shepherd.next;
            }
          });
        }
        shepherd.addStep(item.stepName, item.opt);
      });
      shepherd.on('complete', function () {
        _this.pushMessageV1Preference.set('hasViewedTour', true);
        _this.pushMessageV1Preference.save();
      });
      shepherd.on('cancel', function () {
        _this.pushMessageV1Preference.set('hasViewedTour', true);
        _this.pushMessageV1Preference.save();
      });
      return shepherd;
    },
    setupShepherdForTriggeredFeatures: function setupShepherdForTriggeredFeatures() {
      var _this2 = this;
      var shepherd2 = new window.Shepherd.Tour({
        defaultStepOptions: {
          scrollTo: {
            behavior: 'smooth',
            block: 'center'
          },
          showCancelLink: true,
          tippyOptions: {
            maxWidth: 400
          }
        },
        styleVariables: {
          useDropShadow: true
        },
        useModalOverlay: true
      });
      this.pushTourData2.forEach(function (item) {
        if (item.opt.buttons) {
          item.opt.buttons.forEach(function (btn) {
            if (btn.action === 'cancel') {
              btn.action = shepherd2.cancel;
            } else if (btn.action === 'next') {
              btn.action = shepherd2.next;
            }
          });
        }
        shepherd2.addStep(item.stepName, item.opt);
      });
      shepherd2.on('complete', function () {
        _this2.pushMessageV2TriggeredPreference.set('hasViewedTour', true);
        _this2.pushMessageV2TriggeredPreference.save();
      });
      shepherd2.on('cancel', function () {
        _this2.pushMessageV2TriggeredPreference.set('hasViewedTour', true);
        _this2.pushMessageV2TriggeredPreference.save();
      });
      return shepherd2;
    },
    initiatePushTour: function initiatePushTour() {
      var hash = window.location.hash;
      if (hash.includes('communications-hub/push-messages-v2/edit') || hash.includes('communications-hub/push-messages-v2/create')) {
        if (this.pushMessageV1Preference && !this.pushMessageV1Preference.hasViewedTour) {
          if (!this.shepherd) {
            this.set('shepherd', this.setupShepherd());
          }
          this.shepherd.start();
        }
      }
    },
    initiatePushTourForTriggeredFeatures: function initiatePushTourForTriggeredFeatures() {
      var hash = window.location.hash;
      if (hash.includes('communications-hub/push-messages-v2/edit') || hash.includes('communications-hub/push-messages-v2/create')) {
        if (this.pushMessageV2TriggeredPreference && !this.pushMessageV2TriggeredPreference.hasViewedTour) {
          if (!this.shepherd2) {
            this.set('shepherd2', this.setupShepherdForTriggeredFeatures());
          }
          this.shepherd2.start();
        }
      }
    } // ACTIONS -------------------------------------------------------------------
  });
  _exports.default = _default;
});