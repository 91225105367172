define("experience-studio/routes/content-hub-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('selectedEntities', []);
      controller.toggleProperty('isRefreshFilterModel');
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});