define("experience-studio/routes/test2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* This route tests the new duplicate modal */
  var _default = Ember.Route.extend({
    model: function model() {
      var ruleAdapter = this.store.adapterFor('rule');
      return Ember.RSVP.hash({
        rule: this.store.findRecord('rule', '5e332fdfe9a35f0001eac0f3'),
        conflictingEntities: ruleAdapter.getConflictingEntities('5e332fdfe9a35f0001eac0f3')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('isModalOpen', true);
      controller.set('selectedLabels', null);
      controller.setProperties({
        isModalOpen: true,
        forceDuplicate: false,
        // true or false
        selectedLabels: null,
        conflictingEntities: Ember.Object.create({
          pushMessages: model.conflictingEntities.pushMessages,
          experiences: model.conflictingEntities.experiences
        })
      });
    },
    actions: {
      handleOnSuccess: function handleOnSuccess(_ref) {
        var action = _ref.action,
          rule = _ref.rule;
        switch (action) {
          case 'duplicate':
            {
              alert('duplication success, copied rule: ', rule);
              break;
            }
          case 'edit-anyway':
            {
              alert('edit anyways clicked');
              break;
            }
          default:
            {
              alert('default');
              break;
            }
        }
      }
    }
  });
  _exports.default = _default;
});