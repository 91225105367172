define("experience-studio/routes/experiences/edit", ["exports", "experience-studio/mixins/infinite-scroll-search-route"], function (_exports, _infiniteScrollSearchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = Ember.Route.extend(_infiniteScrollSearchRoute.default, {
    utility: Ember.inject.service(),
    contextRuleUtility: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        contentInstances: store.query('content-instance', {
          page: 1,
          recordsPerPage: 15
        }),
        experience: store.findRecord('experience', params.experience_id),
        rules: store.query('rule', {
          page: 1,
          recordsPerPage: 15
        }),
        plugins: store.query('plugin', {
          page: 1,
          recordsPerPage: 15
        })
      });
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: controller.model.experience.get('name'),
        description: controller.model.experience.get('description'),
        selectedRules: controller.selectedRules.mapBy('id').sort(),
        selectedContent: controller.selectedContent.mapBy('id').sort(),
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var previousRouteName = this._router.getPreviousRouteName();
      if (previousRouteName) {
        this.transitionTo(previousRouteName);
      } else if (this.controller.get('previousTransition')) {
        this.controller.get('previousTransition').retry();
      } else {
        window.history.back();
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      controller.setProperties({
        transitionConfirmed: false,
        isEditMode: true,
        isModalOpen: false,
        selectedContent: [],
        selectedRules: [],
        selectedLabels: [],
        ruleProperties: this.generateModelProperties(model.rules, 'model.rules'),
        contentProperties: this.generateModelProperties(model.contentInstances, 'model.contentInstances'),
        initialHash: this.utility.hash({
          name: model.experience.get('name'),
          description: model.experience.get('description'),
          selectedRules: model.experience.get('ruleId') ? [model.experience.get('ruleId')] : [],
          selectedContent: model.experience.get('contentIds').sort(),
          selectedLabels: model.experience.get('labels').sort()
        })
      });
      Ember.RSVP.allSettled(model.experience.get('computedContents')).then(function (response) {
        var fulfilledItems = response.filterBy('state', 'fulfilled');
        Ember.run.scheduleOnce('afterRender', _this, function () {
          fulfilledItems.forEach(function (item) {
            item.value.set('isSelected', true);
            _this.controller.selectedContent.pushObject(item.value);
          });
        });
      });
      Ember.RSVP.allSettled(model.experience.get('computedRuleObjects')).then(function (response) {
        var fulfilledItems = response.filterBy('state', 'fulfilled');
        fulfilledItems.forEach(function (item) {
          item.value.set('isSelected', true);
          _this.controller.selectedRules.pushObject(item.value);
        });
      });
      this._super(controller, model);
    },
    actions: {
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        controller.model.experience.rollbackAttributes();
        this.goBack();
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        if (this.hasChanges()) {
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this.send('cancel');
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      save: function save() {
        var _this2 = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var model = controller.get('model.experience');
        var selectedContentInstances = controller.selectedContent;
        var selectedRules = controller.selectedRules;
        var selectedLabels = controller.selectedLabels;
        var self = this;
        this.send('saveSchedule');
        model.set('isActive', true);
        if (!Ember.isEmpty(selectedRules)) {
          model.set('ruleId', selectedRules.objectAt(0).get('id'));
        } else {
          model.set('ruleId', null);
        }
        if (!Ember.isEmpty(selectedContentInstances)) {
          model.set('contentIds', selectedContentInstances.getEach('id'));
        } else {
          model.set('contentIds', []);
        }

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this2.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          model.set('labels', selectedLabels.mapBy('value'));
          model.save().then(function () {
            self.controller.set('transitionConfirmed', true);
            self.send('refresh');
            _this2.goBack();
            Ember.run.scheduleOnce('afterRender', self, function () {
              self.send('showNotification', {
                type: 'confirm',
                message: "Changes made to \"".concat(model.get('name'), "\" Experience")
              });
            });
          }).catch(function (response) {
            controller.set('errorMessage', self.get('utility').parseError(response));
          });
        }).catch(function (response) {
          controller.set('errorMessage', self.get('utility').parseError(response));
        });
      },
      saveSchedule: function saveSchedule() {
        var controller = this.controller;
        var ruleUtil = this.contextRuleUtility;
        var schedule = null;
        var startType;
        var selectedRule;
        if (!Ember.isEmpty(this.get('controller.selectedRules'))) {
          selectedRule = ruleUtil.getRule(controller.get('selectedRules'));
          schedule = selectedRule && selectedRule.get('schedule') ? selectedRule.get('schedule') : null;
          startType = selectedRule.contextualStartType;
        }
        if (schedule && !selectedRule.preserveSchedule) {
          var startDate = schedule.get('startScheduler.startDate');
          var startTime = schedule.get('startScheduler.startTime');
          if (startDate && startTime) {
            schedule.set('startScheduler.start', parseInt(window.moment("".concat(startDate, " ").concat(startTime)).utc().format('X')));
            if (schedule.get('startScheduler.repeat.isRepeat')) {
              var repeat = schedule.get('startScheduler.repeat');
              repeat.setProperties({
                frequency: parseInt(repeat.get('frequency')),
                frequencyType: repeat.get('frequencyType.value'),
                limit: parseInt(repeat.get('limit'))
              });
            } else {
              schedule.get('startScheduler.repeat').setProperties({
                frequency: 1,
                frequencyType: 'once',
                limit: 1
              });
            }
          }
          var endDate = schedule.get('endScheduler.endDate');
          var endTime = schedule.get('endScheduler.endTime');
          if (endDate && endTime) {
            schedule.set('endScheduler.start', parseInt(window.moment("".concat(endDate, " ").concat(endTime)).utc().format('X')));
            schedule.get('startScheduler.repeat').setProperties({
              frequency: 1,
              frequencyType: 'once',
              limit: 1
            });
          } else if (!schedule.get('selectedPluginAttribute')) {
            schedule.set('endScheduler', null);
          }
          if (schedule.get('selectedPluginAttribute') && Ember.isEqual(startType, 'plugin')) {
            var operator = schedule.get('numberOfMinutesOperator');
            var parametersx = schedule.get('selectedPluginAttribute.parameters');
            var seconds = 0;
            var uid = schedule.get('selectedPluginAttribute.uid');
            try {
              seconds = parseInt(schedule.get('numberOfMinutes'));
            } catch (exception) {
              // TODO: Handle exception
            }
            if (!Ember.isEmpty(parametersx)) {
              uid += ".".concat(parametersx.map(function (param) {
                return param.value || '';
              }).join('.'));
            }
            if (operator && seconds > 0 && uid) {
              var _ref = Ember.isEqual(operator, '+') ? ['endScheduler', 'startScheduler'] : ['startScheduler', 'endScheduler'],
                _ref2 = _slicedToArray(_ref, 2),
                target = _ref2[0],
                blank = _ref2[1];
              schedule.set("".concat(blank, ".contextualStart"), "".concat(uid));
              schedule.set("".concat(target, ".contextualStart"), "".concat(uid, " ").concat(operator, " ").concat(seconds));
              // Remove rule based schedule
              schedule.set("".concat(target, ".contextualRuleStart.stringRepresentation"), '');
              schedule.set("".concat(blank, ".contextualRuleStart.stringRepresentation"), '');
              schedule.startScheduler.set('start', 0);
              schedule.get('endScheduler.repeat').setProperties({
                frequency: 1,
                frequencyType: 'once',
                limit: 1
              });
            }
            // Only override existing repeat iff it doesn't exist already
            if (Ember.isEmpty(schedule.get('startScheduler.repeat.frequencyType'))) {
              schedule.get('startScheduler.repeat').setProperties({
                frequency: 1,
                frequencyType: 'once',
                limit: 1
              });
            }
          }

          // Only remove rule schedule if there is no absolute/contextual schedule.
          if (!ruleUtil.hasStartSchedule(selectedRule)) {
            selectedRule.set('schedule', null);
          }
          var serializer = this.store.serializerFor('rule');
          if (Ember.isEmpty(selectedRule.composition)) {
            selectedRule.set('composition', serializer.getComposition(Ember.merge(this.controller.model, {
              'rule': selectedRule
            })));
          }

          // Only save if going from 2nd option (context) to 1st option (rule context)
          var isContext = Ember.isEqual(selectedRule.get('contextualStartType'), 'plugin');
          // if going from 2nd option to 1st option, we remove the schedule object
          if (selectedRule.hasContextual && !isContext) {
            selectedRule.set('schedule', null);
            selectedRule.save();
          } else if (isContext) {
            // if 2nd option is selected, then save();
            selectedRule.save();
          }
        }
      },
      // Not being used atm
      openWarningModal: function openWarningModal(promise, conflictingPushes, conflictingExperiences) {
        var _this3 = this;
        Ember.run.scheduleOnce('afterRender', function () {
          _this3.controller.setProperties({
            'isModalOpen': true,
            'promiseResolver': promise,
            'pushesWithSameRule': conflictingPushes,
            'experiencesWithSameRule': conflictingExperiences
          });
        });
      },
      validate: function validate() {
        var _this4 = this;
        var controller = this.controller;
        var ruleUtil = this.contextRuleUtility;
        var errorMessage;
        var model = controller.get('model.experience');
        var selectedRule = ruleUtil.getRule(controller.get('selectedRules'));
        var validation = Ember.Object.create({});
        if (Ember.isEmpty(model.get('name'))) {
          errorMessage = 'Enter name.';
          validation.set('name', errorMessage);
        }
        if (selectedRule.get('schedule') && ruleUtil.hasPluginStartSchedule(selectedRule)) {
          var isScheduleValid = !selectedRule.schedule.get('selectedPluginAttribute') ? false : true;
          if (!isScheduleValid) {
            errorMessage = Number.isNaN(parseInt(selectedRule.schedule.numberOfMinutes)) ? 'Enter a value.' : errorMessage;
            errorMessage = Ember.isEmpty(errorMessage) ? 'Set content visibility by selecting plugin and attribute.' : errorMessage;
            errorMessage = Ember.isEqual(errorMessage, 'Enter name.') ? 'Complete the required fields highlighted below.' : errorMessage;
            Ember.run.scheduleOnce('afterRender', function () {
              model.set('isCTXScheduleValid', false);
            });
          }
        }
        if (!errorMessage && selectedRule.get('schedule') && ruleUtil.hasPluginStartSchedule(selectedRule)) {
          var ruleAdapter = this.store.adapterFor('rule');
          // remove self from conflict
          var conflictingEntities = ruleAdapter.getConflictingEntities(selectedRule.id, {
            experienceId: this.get('controller.model.experience.id')
          }); // eslint-disable-line max-len
          conflictingEntities.then(function (entities) {
            if (entities.pushMessages.length || entities.experiences.length) {
              Ember.run.scheduleOnce('afterRender', function () {
                controller.setProperties({
                  'isModalOpen': true,
                  'pushesWithSameRule': entities.pushMessages,
                  'experiencesWithSameRule': entities.experiences
                });
              });
            } else {
              _this4.send('save');
            }
          });
        } else if (errorMessage) {
          controller.setProperties({
            errorMessage: errorMessage,
            validation: validation
          });
        } else {
          this.send('save');
        }
      },
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        var controller = this.controller;
        var model = controller.get('model.experience');
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          controller.set('previousTransition', transition);
          window.history.forward();
          this.send('confirmCancel');
        } else {
          controller.selectedContent.setEach('isSelected', false);
          controller.selectedRules.setEach('isSelected', false);
          model.reload();
          controller.setProperties({
            errorMessage: '',
            model: null,
            validation: null,
            previousTransition: null,
            transitionConfirmed: false
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});