define("experience-studio/helpers/color-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.colorPreview = colorPreview;
  _exports.default = void 0;
  var isColor = function isColor(hexStr) {
    return /^#[0-9A-F]{6}$/i.test(hexStr);
  };
  function colorPreview(params, hash) {
    var hex = hash.hex && isColor(hash.hex) ? hash.hex : '#000000';
    var markup = "<div class='helper-color-preview' style='background-color:".concat(hex, "' title='").concat(hex, "'></div>");
    return Ember.String.htmlSafe(markup);
  }
  var _default = Ember.Helper.helper(colorPreview);
  _exports.default = _default;
});