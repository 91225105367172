define("experience-studio/templates/components/custom-field-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hj2yFMD9",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],[[35,4]],[[\"field\",\"onValue\",\"bindFieldToSection\",\"unbindFieldFromSection\",\"defaultLanguage\",\"languages\",\"isExternalDeepLink\",\"dropdownValue\"],[[35,1],\"onValue\",\"bindFieldToSection\",\"unbindFieldFromSection\",[35,3],[35,2],[35,1,[\"isExternalDeepLink\"]],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dropdownValue\",\"field\",\"languages\",\"defaultLanguage\",\"component\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/custom-field-factory.hbs"
  });
  _exports.default = _default;
});