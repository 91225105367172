define("experience-studio/templates/components/model-priority-select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vZiCNiC5",
    "block": "{\"symbols\":[\"priority\"],\"statements\":[[6,[37,3],null,[[\"options\",\"selected\",\"searchEnabled\",\"onchange\"],[[35,2],[35,1],false,[30,[36,0],[[32,0],\"toggleSelection\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedPriorityObject\",\"priorityList\",\"power-select\"]}",
    "moduleName": "experience-studio/templates/components/model-priority-select-dropdown.hbs"
  });
  _exports.default = _default;
});