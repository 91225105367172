define("experience-studio/components/date-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['date-time-picker'],
    dateUpdated: Ember.observer('value', function () {
      var pickerInput = this.get('picker.input');
      if (pickerInput) {
        var pickerVal = this.get('picker.input').value;
        if (pickerVal !== this.value) {
          if (!this.value) {
            this.picker.clear();
          } else {
            this.picker.setDate(this.extractDateStr(this.value), false);
          }
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      var dateInput = this.$('.date-field');

      // eslint-disable-next-line
      var picker = new window.flatpickr(dateInput.get(0), {
        enableTime: Ember.isEmpty(this.enableTime) ? true : this.enableTime,
        noCalendar: this.noCalendar || false,
        defaultHour: 12,
        defaultMinute: 0,
        onChange: function onChange(selectedDates, dateStr) {
          self.setModelDate(dateStr);
        }
      });
      this.set('picker', picker);
    },
    extractDateStr: function extractDateStr(isoStr) {
      var dateStr = isoStr.replace('T', ' ');
      if (dateStr.indexOf('Z') > -1) {
        dateStr = dateStr.split('Z')[0];
      } else {
        dateStr = dateStr.substr(0, dateStr.length - 6);
      }
      return dateStr;
    },
    setModelDate: function setModelDate(dateStr) {
      if (!dateStr && this.value) {
        this.set('value', '');
      } else if (dateStr && dateStr !== '') {
        this.set('value', dateStr);
      }
    }
  });
  _exports.default = _default;
});