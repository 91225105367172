define("experience-studio/helpers/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFormat = dateFormat;
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  /*
  
    Usage:
  
    Note: Underscored properties are optional.
  
    {{date-format
      value=model.updatedAt
      _isTimeFromNow=true
      _cutOffValue=2
      _cutOffUnit='hours'
    }}
  
    Possible values for cuttOffUnit:
      years | months | weeks | days | hours | minutes | seconds
  
   */

  function dateFormat(params, hash) {
    var date = null;
    var isTimeFromNow = hash.isTimeFromNow;
    if (_typeof(hash.value) !== 'object') {
      date = window.moment.unix(hash.value).toDate();
    }
    date = window.moment(date || hash.value);
    if (!Ember.isNone(hash.cutOffUnit) && !Ember.isNone(hash.cutOffValue) && window.moment().diff(date, hash.cutOffUnit) < hash.cutOffValue) {
      isTimeFromNow = true;
    }
    if (isTimeFromNow) {
      return date.fromNow();
    } else {
      return date.format(hash.format);
    }
  }
  var _default = Ember.Helper.helper(dateFormat);
  _exports.default = _default;
});