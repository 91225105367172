define("experience-studio/components/av5-push-engagement-card-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['av5-push-engagement-card-items'],
    cardsData: null,
    requests: null,
    cardsDataSham: Ember.computed('cardsData.[]', function () {
      var cardsData = [];
      var timeFrom = window.moment(this.requests.objectAt(0).queryParams.timeFrom).format('MMM DD, YYYY');
      var timeTo = window.moment(this.requests.objectAt(0).queryParams.timeTo).format('MMM DD, YYYY');
      var uniqueOpen = 0;
      var uniqueSent = 0;
      var uniqueDelivered = 0;
      this.cardsData.forEach(function (item) {
        if (item.cardType === 'open') {
          uniqueOpen = item.value;
          cardsData.pushObject(Ember.Object.create({
            rate: item.diff * 100,
            timeRange: "".concat(timeFrom, " - ").concat(timeTo),
            title: 'Notifications Opened',
            value: window.Number(uniqueOpen.toFixed(2)).toLocaleString(),
            tooltip: 'Sum of push notifications opened by unique users across all campaigns based on selected time range and labels.'
          }));
        }
        if (item.cardType === 'sent') {
          uniqueSent = item.value;
          cardsData.pushObject(Ember.Object.create({
            rate: item.diff * 100,
            timeRange: "".concat(timeFrom, " - ").concat(timeTo),
            title: 'Notifications Sent',
            value: window.Number(uniqueSent.toFixed(2)).toLocaleString(),
            tooltip: 'Sum of push notifications sent to unique users across all campaigns based on selected time range and labels.'
          }));
        }
        if (item.cardType === 'delivered') {
          uniqueDelivered = item.value;
          cardsData.pushObject(Ember.Object.create({
            rate: item.diff * 100,
            timeRange: "".concat(timeFrom, " - ").concat(timeTo),
            title: 'Notifications Delivered',
            value: window.Number(uniqueDelivered.toFixed(2)).toLocaleString(),
            tooltip: 'Sum of push notifications delivered to unique users across all campaigns based on selected time range and labels.'
          }));
        }
      });
      var uniqueOpenRate = uniqueSent !== 0 ? uniqueOpen / uniqueSent * 100 : 0;
      cardsData.pushObject(Ember.Object.create({
        rate: uniqueOpenRate,
        timeRange: "".concat(timeFrom, " - ").concat(timeTo),
        title: 'Notifications Open Rate',
        value: "".concat(uniqueOpenRate.toFixed(2), "%"),
        tooltip: 'Ratio of total number of push notifications opened to total number of push notifications sent across all campaigns, within the time range and labels you have selected.'
      }));
      return cardsData;
    })
  });
  _exports.default = _default;
});