define("experience-studio/serializers/invitation", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      delete data.acceptedAt;
      delete data.permission.role;
      return data;
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      if (Ember.isEmpty(item.id)) {
        item.id = item.attributes.recipient.email;
        item.attributes.email = item.attributes.recipient.email;
        item.attributes.permission = item.attributes.recipient.permission;
      }
      item.attributes.email = item.attributes.recipient.email;
      item.attributes.permission = item.attributes.recipient.permission;
      return item;
    }
  });
  _exports.default = _default;
});