define("experience-studio/routes/analytics", ["exports", "experience-studio/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import $ from 'jquery';
  // import { isEmpty } from '@ember/utils';
  // import { scheduleOnce } from '@ember/runloop';
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'analytics') {
        this.transitionTo('analytics.index');
      }
      return this._super.apply(this, arguments);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        isShowUploadModal: false,
        isSearchFormHidden: true,
        showInfoButton: false,
        selectedEntities: []
      });
    },
    actions: {}
  });
  _exports.default = _default;
});