define("experience-studio/templates/components/confirm-undraft-records-modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "q95OnkOF",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,10],null,[[\"onClose\",\"clickOutsideToClose\",\"containerClassNames\",\"translucentOverlay\"],[\"close\",true,[35,9],true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"modal-dialog\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n      \"],[10,\"header\"],[14,0,\"modal-header\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-title\"],[12],[1,[30,[36,0],[\"clone\"],null]],[2,\" \"],[1,[34,1]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n        \"],[10,\"label\"],[14,0,\"modal-label\"],[12],[1,[35,2,[\"pluralEntityName\"]]],[2,\" (\"],[1,[35,3,[\"length\"]]],[2,\")\"],[13],[2,\"\\n\"],[6,[37,7],null,[[\"headers\",\"hasSearch\",\"hasCheckbox\",\"hasAlert\",\"isPreselect\",\"searchPlaceholder\",\"onSelect\",\"validationFunction\",\"model\"],[[35,6],true,true,true,true,[35,5],[30,[36,4],[[32,0],\"onSelectRecords\"],null],[30,[36,4],[[32,0],\"validationFunction\"],null],[35,2,[\"selectedEntities\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"td\"],[14,0,\"name-col\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"footer\"],[14,0,\"modal-footer\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn btn-link btn-cancel\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"close\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[16,\"disabled\",[34,8]],[24,4,\"button\"],[4,[38,4],[[32,0],\"undraft\"],null],[12],[2,\"\\n            Move to production\\n          \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"confirmModalTitle\",\"model\",\"selectedRecords\",\"action\",\"searchPlaceholder\",\"headers\",\"model-display-table\",\"isActionDisabled\",\"classNames\",\"modal-dialog\"]}",
    "moduleName": "experience-studio/templates/components/confirm-undraft-records-modal-dialog.hbs"
  });
  _exports.default = _default;
});