define("experience-studio/routes/context-hub/rule", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/model-actions", "experience-studio/mixins/pagination-route", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-context"], function (_exports, _authenticatedRoute, _modelActions, _paginationRoute, _routeModelBatchActionsBase, _routeModelBatchActionsContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _paginationRoute.default, _modelActions.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsContext.default, {
    storage: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        rules: store.query('rule', {
          page: params.page,
          recordsPerPage: params.recordsPerPage,
          search: params.searchKeyword,
          sortby: params.sortBy,
          sortorder: params.sortOrder
        })
      });
    },
    beforeModel: function beforeModel(transition) {
      if (this.storage.getItem('isContextHubListView') === 'true' && (transition.targetName === 'context-hub.rule-v2' || transition.targetName === 'context-hub.rule')) {
        this.transitionTo('context-hub.rule-v2');
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          searchKeyword: '',
          sortBy: '',
          sortOrder: '',
          page: 1,
          recordsPerPage: 48
        });
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.setProperties({
        ruleListSortProperties: [Ember.Object.create({
          label: 'Name',
          sortValue: 'name',
          isSorted: false,
          order: {
            desc: 'Z-A',
            asc: 'A-Z'
          }
        }), Ember.Object.create({
          label: 'Status',
          sortValue: 'isDraft',
          isSorted: false,
          order: {
            desc: 'Draft',
            asc: 'Production'
          }
        }), Ember.Object.create({
          label: 'Date Created',
          sortValue: 'createdAt',
          isSorted: true,
          order: {
            desc: 'Newest',
            asc: 'Oldest'
          }
        })],
        contextHubController: this.controllerFor('context-hub'),
        pagination: model.rules.meta.pagination
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createRule: function createRule() {
        this.send('handleAction', 'create', null, {
          type: 'context-rule'
        });
      },
      deselectModelListItems: function deselectModelListItems() {
        this._super({
          selectedEntitiesPath: 'contextHubController.selectedEntities'
        });
      },
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.searchKeyword);
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
        this.send('aggregateModel', 'rule', 'rules');
      },
      editRecord: function editRecord(record) {
        if (record.constructor.modelName === 'rule' && !record.get('isDraft')) {
          this.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: "Production rules cannot be edited as they will impact your active Experiences.\n                           If you wish to edit a rule, please select \u201Csave as draft\u201D during the rule creation process.",
            title: 'Cannot edit rules in Production.',
            type: 'danger'
          }));
        } else if (record.get('body.hasComplexHistoricalPreview')) {
          this.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: "You cannot perform this action, because this rule contains an advanced expression.",
            title: 'Cannot perform action.',
            type: 'danger'
          }));
        } else {
          this._super.apply(this, arguments);
        }
      },
      selectModelListItems: function selectModelListItems(model) {
        this._super(model, 'contextHubController.selectedEntities');
      },
      changeToListView: function changeToListView(routeName) {
        this.storage.setItem('isContextHubListView', true);
        this.transitionTo(routeName);
      },
      didTransition: function didTransition() {
        var page = this.controller.page;
        var model = this.controller.model.rules;
        if (page > 1 && model.get('length') === 0) {
          this.controller.set('page', 1);
        }
      }
    }
  });
  _exports.default = _default;
});