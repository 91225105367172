define("experience-studio/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    queryParams: {
      hideSidebar: false
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var hideSidebar = transition.to.queryParams.hideSidebar;
      return {
        hideSidebar: hideSidebar
      };
    },
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.setProperties({
        isMainSidebarHidden: model.hideSidebar,
        isMainSidebarMinimized: false,
        session: this.session
      });
      if (window.Notyf) {
        controller.set('notyf', new window.Notyf());
      }
    },
    actions: {
      error: function error(_error, transition) {
        if (_error && Array.isArray(_error.errors) && _error.errors[0].status === '401') {
          this.session.unauthenticate();
          return;
        }
        var target = this;
        var notification = Ember.Object.create({
          message: this.utility.parseError(_error),
          type: 'alert'
        });
        if (transition && transition.router.currentSequence <= 1) {
          var routeName = 'experiences';
          var tokens = transition.targetName.split('.');
          if (tokens.length >= 2) {
            tokens.pop();
            routeName = tokens.join('.');
          }
          target = this.transitionTo(routeName);
        } else if (transition) {
          target = transition;
        }
        target.send('showNotification', notification);
      },
      hideModal: function hideModal() {
        this.controller.setProperties({
          modalData: null,
          modalName: null
        });
      },
      signOut: function signOut() {
        this.session.unauthenticate();
      },
      showModal: function showModal(data) {
        this.controller.setProperties({
          modalData: data,
          modalName: data.modalName
        });
      },
      showNotification: function showNotification(options) {
        if (options) {
          var controller = this.controller;
          Ember.run.scheduleOnce('afterRender', this, function () {
            var notyf = controller.get('notyf');
            if (notyf && options.type === 'alert') {
              notyf.alert(options.message);
            } else if (notyf && options.type === 'confirm') {
              notyf.confirm(options.message);
            } else if (notyf && options.type === 'success') {
              notyf.confirm(options.message);
            }
          });
        }
      },
      performAction: function performAction(params) {
        var action = params.action;
        delete params.action;
        this.send(action, params);
      },
      switchRoutes: function switchRoutes() {
        var currentRouteName = this.get('router.currentRouteName');
        var routeListViewPreference = this.userPreferences.getPreference('routeListView');
        switch (currentRouteName) {
          case 'experiences.index':
          case 'experiences-v2':
            if (routeListViewPreference.value) {
              this.transitionTo('experiences-v2');
            } else {
              this.transitionTo('experiences');
            }
            break;
          case 'content-hub.index':
          case 'content-hub-v2.index':
            if (routeListViewPreference.value) {
              this.transitionTo('content-hub-v2');
            } else {
              this.transitionTo('content-hub');
            }
            break;
          case 'context-hub.rule.index':
          case 'context-hub.rule-v2':
            this.transitionTo('context-hub.rule');
            if (routeListViewPreference.value) {
              this.transitionTo('context-hub.rule-v2');
            } else {
              this.transitionTo('context-hub.rule');
            }
            break;
          case 'communications-hub.push-messages-v3.index':
          case 'communications-hub.push-messages-v3':
            if (routeListViewPreference.value) {
              this.transitionTo('communications-hub.push-messages-v3');
            } else {
              this.transitionTo('communications-hub.push-messages-v3');
            }
            break;
          case 'communications-hub.push-messages-v2.index':
          case 'communications-hub.push-messages-v2':
            if (routeListViewPreference.value) {
              this.transitionTo('communications-hub.push-messages-v3');
            } else {
              this.transitionTo('communications-hub.push-messages-v3');
            }
            break;
          case 'communications-hub.push-messages-v4.index':
          case 'communications-hub.push-messages-v4':
            if (routeListViewPreference.value) {
              this.transitionTo('communications-hub.push-messages-v4');
            } else {
              this.transitionTo('communications-hub.push-messages-v4');
            }
            break;
          case 'experiences.groups':
          case 'groups-v2':
            if (routeListViewPreference.value) {
              this.transitionTo('groups-v2');
            } else {
              this.transitionTo('experiences.groups');
            }
            break;
          default:
        }
      },
      transitionTo: function transitionTo(data, model) {
        if (data && data.get && data.get('routeName') && data.get('model')) {
          this.transitionTo(data.get('routeName'), data.get('model'));
        } else if (data && data.get && data.get('routeName')) {
          this.transitionTo(data.get('routeName'));
        } else {
          this.transitionTo(data, model);
        }
      }
    }
  });
  _exports.default = _default;
});