define("experience-studio/components/content-form-editor-draggable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    attributeBindings: ['isDrabbble:draggable'],
    classNames: ['content-form-editor-draggable-item'],
    model: null,
    tagName: 'li',
    isDrabbble: true,
    drag: function drag() {},
    dragEnd: function dragEnd(event) {
      this.set('isDragging', false);
      event.dataTransfer.clearData();
    },
    dragEnter: function dragEnter() {},
    dragExit: function dragExit() {},
    dragLeave: function dragLeave() {},
    dragOver: function dragOver() {},
    dragStart: function dragStart(event) {
      var componentName = this.get('model.componentName');
      if (componentName) {
        event.dataTransfer.setData('text/plain', this.get('model.uiType'));
        this.set('isDragging', true);
      }
    }
  });
  _exports.default = _default;
});