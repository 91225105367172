define("experience-studio/components/composition-group-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['composition-group-items'],
    model: null,
    modelSham: Ember.computed('model.[]', function () {
      var result = [];
      this.model.forEach(function (item) {
        item.set('isLocationItem', item.originalModel.uid === 'ctx.flybits.location' || item.originalModel.uid === 'ctx.flybits.approximateArea');
        result.pushObject(item);
      });
      return result;
    }),
    actions: {
      removeCompositionItem: function removeCompositionItem(item) {
        this.sendAction('removeCompositionItem', item); // eslint-disable-line ember/closure-actions
      },
      selectCompositionItem: function selectCompositionItem(item) {
        this.sendAction('selectCompositionItem', item); // eslint-disable-line ember/closure-actions
      },
      selectComparisonOperator: function selectComparisonOperator(a, b, c) {
        this.sendAction('selectComparisonOperator', a, b, c); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});