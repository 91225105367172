define("experience-studio/helpers/info-sidebar-rule-complex-historical-preview-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute: function compute(params) {
      var record = params[0].record;
      var description = 'Historical Query';
      if (record && record.get('id')) {
        var func = Ember.String.capitalize(record.get('function'));
        var plugin = record.get('plugin.id').split('.').pop();
        var attribute = record.get('plugin.attribute');
        var parameters = [];
        var whereOperator = record.get('where');
        var whereValue = '';
        var timeRange = {};
        if (whereOperator.objectAt(0)) {
          whereValue = whereOperator.objectAt(0).value;
          whereOperator = whereOperator.objectAt(0).operator;
        }
        if (record.get('timeRange').objectAt(0)) {
          timeRange = record.get('timeRange').objectAt(0);
        }
        var whereOperatorText = '';
        if (whereOperator === '=') {
          whereOperatorText = "has been ".concat(whereValue);
        } else if (whereOperator === '>') {
          whereOperatorText = "has been greater than ".concat(whereValue);
        } else if (whereOperator === '<') {
          whereOperatorText = "has been less than ".concat(whereValue);
        }
        if (params[1].get('body.isNegated')) {
          whereOperator = whereOperator.replace('has been', 'has not been');
        }
        var parametersText = '';
        for (var i = 1; i <= 5; i++) {
          if (record.get("plugin.parameter".concat(i))) {
            parameters.pushObject(record.get("plugin.parameter".concat(i)));
          }
        }
        if (parameters.length) {
          parametersText = ".".concat(parameters.join('.'));
        }
        var timeRangeText = '';
        if (timeRange.from > 0 && timeRange.to > 0 && timeRange.duration <= 0) {
          timeRangeText += ' between ';
          timeRangeText += window.moment.unix(timeRange.from).format('ddd MMM DD YYYY h:mma');
          timeRangeText += window.moment.unix(timeRange.to).format('ddd MMM DD YYYY h:mma');
        } else if (timeRange.from > 0 && timeRange.to <= -1 && timeRange.duration <= 0) {
          timeRangeText += ' after ';
          timeRangeText += window.moment.unix(timeRange.from).format('ddd MMM DD YYYY h:mma');
        } else if (timeRange.to > 0 && timeRange.from <= -1 && timeRange.duration <= 0) {
          timeRangeText += ' before ';
          timeRangeText += window.moment.unix(timeRange.to).format('ddd MMM DD YYYY h:mma');
        } else if (timeRange.from === 0 && timeRange.to === -1 && timeRange.duration > 0) {
          timeRangeText += ' in the past ';
          var recencyInThePastUnit = 'minute(s)';
          var recencyInThePastValue = null;
          if (timeRange.duration >= 86400) {
            recencyInThePastValue = timeRange.duration / 86400;
            recencyInThePastUnit = 'day(s)';
          } else if (timeRange.duration >= 3600) {
            recencyInThePastValue = timeRange.duration / 3600;
            recencyInThePastUnit = 'hour(s)';
          } else {
            recencyInThePastValue = timeRange.duration / 60;
            recencyInThePastUnit = 'minute(s)';
          }
          timeRangeText += "".concat(recencyInThePastValue, " ").concat(recencyInThePastUnit);
        }
        description = "".concat(func, " (").concat(plugin, ").").concat(attribute).concat(parametersText, " where value ").concat(whereOperatorText, " ").concat(timeRangeText);
      }
      return description;
    }
  });
  _exports.default = _default;
});