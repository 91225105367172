define("experience-studio/components/four-o-four", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['four-o-four'],
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var particlesJS = 'https://cdnjs.cloudflare.com/ajax/libs/particles.js/2.0.0/particles.js';
      _jquery.default.getScript(particlesJS).then(function () {
        Ember.run.scheduleOnce('afterRender', _this, _this.initParticles);
      });
    },
    initParticles: function initParticles() {
      window.particlesJS('particles-js', {
        'particles': {
          'number': {
            'value': 400,
            'density': {
              'enable': true,
              'value_area': 800
            }
          },
          'color': {
            'value': '#fff'
          },
          'shape': {
            'type': 'circle',
            'stroke': {
              'width': 0,
              'color': '#000000'
            },
            'polygon': {
              'nb_sides': 5
            },
            'image': {
              'src': 'img/github.svg',
              'width': 100,
              'height': 100
            }
          },
          'opacity': {
            'value': 0.5,
            'random': true,
            'anim': {
              'enable': false,
              'speed': 1,
              'opacity_min': 0.1,
              'sync': false
            }
          },
          'size': {
            'value': 10,
            'random': true,
            'anim': {
              'enable': false,
              'speed': 40,
              'size_min': 0.1,
              'sync': false
            }
          },
          'line_linked': {
            'enable': false,
            'distance': 500,
            'color': '#ffffff',
            'opacity': 0.4,
            'width': 2
          },
          'move': {
            'enable': true,
            'speed': 1.603412060865523,
            'direction': 'bottom',
            'random': false,
            'straight': false,
            'out_mode': 'out',
            'bounce': false,
            'attract': {
              'enable': false,
              'rotateX': 600,
              'rotateY': 1200
            }
          }
        },
        'interactivity': {
          'detect_on': 'canvas',
          'events': {
            'onhover': {
              'enable': true,
              'mode': 'bubble'
            },
            'onclick': {
              'enable': true,
              'mode': 'repulse'
            },
            'resize': true
          },
          'modes': {
            'grab': {
              'distance': 400,
              'line_linked': {
                'opacity': 0.5
              }
            },
            'bubble': {
              'distance': 400,
              'size': 4,
              'duration': 0.3,
              'opacity': 1,
              'speed': 3
            },
            'repulse': {
              'distance': 200,
              'duration': 0.4
            },
            'push': {
              'particles_nb': 4
            },
            'remove': {
              'particles_nb': 2
            }
          }
        },
        'retina_detect': true
      });
    }
  });
  _exports.default = _default;
});