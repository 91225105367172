define("experience-studio/mixins/analytics-rule-overview", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    _buildRequest: function _buildRequest(options, isOverrideThen) {
      var _this = this;
      var endpoint = options.endpoint.replace('_id_', options.id);
      var promise = null;
      options.interval = options.temporalTick;
      delete options.endpoint;
      endpoint += "?".concat(_jquery.default.param(options));
      promise = this.utility.makeAjaxRequest({
        endpoint: endpoint
      });
      if (!isOverrideThen) {
        promise.then(function (response) {
          if (response && response.data) {
            response.errorCount = 0;
            response.falseCount = 0;
            response.totalCount = 0;
            response.trueCount = 0;
            response.data.forEach(function (item) {
              item.error = window.parseInt(item.error || 0);
              item.false = window.parseInt(item.false || 0);
              item.true = window.parseInt(item.true || 0);
              if (window.isNaN(item.error)) {
                item.error = 0;
              }
              if (window.isNaN(item.false)) {
                item.false = 0;
              }
              if (window.isNaN(item.true)) {
                item.true = 0;
              }
              response.errorCount += item.error;
              response.falseCount += item.false;
              response.totalCount += item.error + item.false + item.true;
              response.trueCount += item.true;
            });
            if (response.totalCount > 0) {
              response.errorPercentage = _this._round(response.errorCount / response.totalCount * 100);
              response.falsePercentage = _this._round(response.falseCount / response.totalCount * 100);
              response.truePercentage = _this._round(response.trueCount / response.totalCount * 100);
            }
          } else if (response) {
            response.data = [];
          }
          return response;
        });
      }
      return promise;
    },
    _generateData: function _generateData(options) {
      var data = [];
      var length = 0;
      var startMoment = window.moment.unix(options.startTime);
      if (options.temporalRange === 'day') {
        length = 24;
      } else if (options.temporalRange === 'week') {
        length = 7;
      } else if (options.temporalRange === 'month') {
        length = 30;
      } else if (options.temporalRange === 'year') {
        length = 12;
      }
      for (var i = 0; i < length; i++) {
        var timestamp = null;
        var minutes = Math.round(Math.random() * 50);
        var hours = Math.round(Math.random() * 22);
        if (options.temporalRange === 'day') {
          timestamp = startMoment.add(minutes, 'minutes');
        } else {
          timestamp = startMoment.add(i, 'days').add(hours, 'hours').add(minutes, 'minutes');
        }
        var timestampString = timestamp.format('MMMM Do YYYY, h:mm:ss a');
        data.pushObject({
          error: parseInt("1".concat(Math.round(Math.random() * 9))),
          false: parseInt("2".concat(Math.round(Math.random() * 9))),
          true: parseInt("3".concat(Math.round(Math.random() * 9))),
          timestampString: timestampString,
          timestamp: timestamp.utc().unix()
        });
      }
      data.sort(function (a, b) {
        if (a.timestamp < b.timestamp) {
          return -1;
        } else if (a.timestamp > b.timestamp) {
          return 1;
        } else {
          return 0;
        }
      });
      return {
        data: data
      };
    },
    _getDurationData: function _getDurationData(options) {
      options.endpoint = 'analytics/analytics/rules/_id_/durations';
      options.function = 'avg';
      options.type = 'breakdown';
      return this._buildRequest(options);
    },
    _getEvaluationsPerUserData: function _getEvaluationsPerUserData(options) {
      options.endpoint = 'analytics/analytics/rules/_id_/evaluations';
      options.function = 'avg';
      options.type = 'breakdown';
      return this._buildRequest(options);
    },
    _getTotalEvaluationsData: function _getTotalEvaluationsData(options) {
      options.endpoint = 'analytics/analytics/rules/_id_/evaluations';
      options.function = 'sum';
      options.type = 'breakdown';
      return this._buildRequest(options);
    },
    _getUniqueUsersData: function _getUniqueUsersData(options) {
      options.endpoint = 'analytics/analytics/rules/_id_/users';
      options.function = 'sum';
      options.type = 'breakdown';
      return this._buildRequest(options);
    },
    _getUsersAffected: function _getUsersAffected(options) {
      options.endpoint = 'analytics/analytics/rules/_id_/activeSessions';
      options.function = 'aggregate';
      options.type = 'breakdown';
      options.unit = 'user';
      return this._buildRequest(options);
    },
    _round: function _round(value, decimals) {
      // Shamelessly copied from http://www.jacklmoore.com/notes/rounding-in-javascript/
      decimals = decimals || 2;
      return Number("".concat(Math.round("".concat(value, "e").concat(decimals)), "e-").concat(decimals));
    }
  });
  _exports.default = _default;
});