define("experience-studio/components/v2/search-form-group-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="input-group">
    {{input
      type="text"
      class="search-field form-control"
      placeholder=placeholder
      key-up="_onInput"
      value=value
      autocomplete="off"
    }}
    <div class="search-icon input-group-append">
      {{#if value}}
        <span tabindex="0" aria-label="Clear search" class="input-group-text cancel" {{action 'cancel' bubbles=false}}>
          {{svg-jar 'times'}}
        </span>
      {{else}}
        <span class="input-group-text">
          {{svg-jar 'search'}}
        </span>
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "94fO33oJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"type\",\"class\",\"placeholder\",\"key-up\",\"value\",\"autocomplete\"],[\"text\",\"search-field form-control\",[35,3],\"_onInput\",[35,2],\"off\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"search-icon input-group-append\"],[12],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"span\"],[24,\"tabindex\",\"0\"],[24,\"aria-label\",\"Clear search\"],[24,0,\"input-group-text cancel\"],[4,[38,1],[[32,0],\"cancel\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"times\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"input-group-text\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"search\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"action\",\"value\",\"placeholder\",\"input\",\"if\"]}",
    "moduleName": "experience-studio/components/v2/search-form-group-v2.hbs"
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['search-form-group', 'form-group'],
    placeholder: 'Search',
    debounceTime: 300,
    value: '',
    handleOnInput: function handleOnInput() {
      var keyword = (this.value || '').toLowerCase();
      this.get('onInput')(keyword);
    },
    actions: {
      cancel: function cancel() {
        this.set('value', '');
        this.handleOnInput();
        document.getElementById(this.elementId).getElementsByClassName('search-field')[0].focus();
      },
      _onInput: function _onInput() {
        Ember.run.debounce(this, this.handleOnInput, this.debounceTime);
      }
    }
  }));
  _exports.default = _default;
});