define("experience-studio/components/image-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{image-field
      value=value
      showURL=true,
      showPreview=true,
      showTriggerButton=true,
      isURLEditable=true,
      previewSize='inline',
      triggerLabel='Choose',
      defaultTransform: {
        resize: {
          width: 900
        }
      },
      requirements: {
        aspectRatio: 16/9
      }
    }}
  
   */

  var previewSize = {
    FULL: 'full',
    INLINE: 'inline'
  };
  var _default = Ember.Component.extend({
    classNames: ['image-field'],
    classNameBindings: ['showURL:has-url', 'showPreview:has-preview', 'showTriggerButton:has-trigger', 'hasInlinePreview:inline-preview:full-preview'],
    value: null,
    showURL: true,
    showPreview: true,
    showTriggerButton: true,
    isURLEditable: true,
    showModal: false,
    previewSize: 'inline',
    triggerLabel: 'Choose',
    defaultTransform: {
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      resize: {
        width: 1000
      }
    },
    requirements: null,
    modalModel: null,
    urlRegex: /(https?:\/\/.*\.(?:png|jpg|jpeg|svg|tiff))/i,
    inputDisabled: Ember.computed('isURLEditable', function () {
      return !this.isURLEditable;
    }),
    hasInlinePreview: Ember.computed('previewSize', function () {
      return this.previewSize === previewSize.INLINE;
    }),
    backgroundURL: Ember.computed('value', function () {
      var style = '';
      var value = this.value;
      if (value && this.urlRegex.test(value)) {
        style = "background-image:url(".concat(value, ");");
      }
      return Ember.String.htmlSafe(style);
    }),
    actions: {
      previewClick: function previewClick() {
        if (!this.hasInlinePreview) {
          this.send('openModal');
        }
      },
      setImage: function setImage(url) {
        this.set('value', url);
        if (this.setPreviewImgURL) {
          this.setPreviewImgURL(url);
        }
      },
      openModal: function openModal() {
        this.set('modalModel', Ember.Object.create({
          primaryActionName: 'setImage',
          existingURL: this.value,
          defaultTransform: this.defaultTransform,
          requirements: this.requirements
        }));
        this.set('showModal', true);
      },
      modalClose: function modalClose() {
        this.set('showModal', false);
      }
    }
  });
  _exports.default = _default;
});