define("experience-studio/mixins/analytics-data", ["exports", "jquery", "ember-inflector"], function (_exports, _jquery, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Mixin.create({
    // Content Analytics Flag
    userPreferences: Ember.inject.service(),
    utility: Ember.inject.service(),
    pieColorArray: [Ember.Object.create({
      label: 'blue',
      hexCode: '#6D78D1'
    }), Ember.Object.create({
      label: 'green',
      hexCode: '#66D0B6'
    }), Ember.Object.create({
      label: 'red',
      hexCode: '#FA7579'
    }), Ember.Object.create({
      label: 'yellow',
      hexCode: '#FFC043'
    }), Ember.Object.create({
      label: 'grey',
      hexCode: '#EBEDF8'
    })],
    // Content Analytics Flag: Remove this function
    getIsContentAnalyticsFlagEnabled: function getIsContentAnalyticsFlagEnabled() {
      return this.userPreferences.getValue('contentAnalytics');
    },
    getPieLabels: function getPieLabels(type) {
      var retVal = [];
      switch (type) {
        case 'devices':
          retVal = this.getDevicePieLabels();
          break;
      }
      return retVal;
    },
    getPieColors: function getPieColors(index) {
      return this.pieColorArray.objectAt(index % (this.pieColorArray.length - 1));
    },
    getTableHeaders: function getTableHeaders(type) {
      var retVal = [];
      switch (type) {
        case 'push':
          retVal = this.getPushTableHeaders();
          break;
        case 'rule':
          retVal = this.getRuleTableHeaders();
          break;
        case 'content':
          retVal = this.getContentTableHeaders();
          break;
      }
      return retVal;
    },
    normalizeTableData: function normalizeTableData(data, type, store) {
      var retVal = null;
      switch (type) {
        case 'push':
          retVal = this.normalizePushTableData(data, store);
          break;
        case 'rule':
          retVal = this.normalizeRuleTableData(data, store);
          break;
        case 'content':
          retVal = this.normalizeContentTableData(data, store);
          break;
      }
      return retVal;
    },
    normalizePaginationData: function normalizePaginationData(data, type) {
      var retVal = null;
      switch (type) {
        case 'push':
          retVal = this.normalizePushPaginationData(data);
          break;
        case 'rule':
          retVal = this.normalizeRulePaginationData(data);
          break;
      }
      return retVal;
    },
    normalizeCardData: function normalizeCardData(data) {
      return new Promise(function (resolve) {
        var sent = data.data.NotificationSent;
        var open = data.data.NotificationOpened;
        var delivered = data.data.NotificationSent - data.data.NotificationFailed;
        var arr = [];
        arr.push({
          cardType: 'sent',
          value: sent || 0
        }, {
          cardType: 'delivered',
          value: delivered || 0
        }, {
          cardType: 'open',
          value: open || 0
        });
        resolve(arr);
      });
    },
    serializeQueryParams: function serializeQueryParams(data, type) {
      var retVal = null;
      switch (type) {
        case 'push':
          retVal = this.serializePushQueryParams(data);
          break;
        case 'rule':
          retVal = this.serializeRuleQueryParams(data);
          break;
        case 'content':
          retVal = this.serializeContentQueryParams(data);
          break;
      }
      return retVal;
    },
    getDevicePieLabels: function getDevicePieLabels() {
      return [Ember.Object.create({
        title: 'Android',
        dataVar: 'android'
      }), Ember.Object.create({
        title: 'iOS',
        dataVar: 'ios'
      }), Ember.Object.create({
        title: 'Web',
        dataVar: 'web'
      }), Ember.Object.create({
        title: 'Other',
        dataVar: 'other'
      })];
    },
    getPushTableHeaders: function getPushTableHeaders() {
      return [Ember.Object.create({
        title: 'Name',
        isSortable: false,
        dataType: 'string',
        dataVar: null,
        tooltip: ''
      }), Ember.Object.create({
        title: 'Associated Context Rule',
        isSortable: false,
        dataType: 'string',
        dataVar: null,
        tooltip: ''
      }), Ember.Object.create({
        title: 'Status',
        isSortable: false,
        dataType: 'string',
        dataVar: null,
        tooltip: ''
      }), Ember.Object.create({
        title: 'Total / Unique Sent',
        isSortable: false,
        sortByVar: 'total_sent',
        dataType: 'integer',
        dataVar: 'totalAllNotifications',
        dataSubVar: 'uniqueAllNotifications',
        isSelected: false,
        tooltip: 'The total number of times this push notification was sent, and the number of unique users it was sent to, respectively.'
      }), Ember.Object.create({
        title: 'Total / Unique Delivered',
        isSortable: true,
        sortByVar: 'total_sent',
        dataType: 'integer',
        dataVar: 'totalSent',
        dataSubVar: 'uniqueSent',
        isSelected: true,
        tooltip: 'The total number of times this push notification was successfully delivered, and the number of unique users it was delivered to, respectively.'
      }), Ember.Object.create({
        title: 'Total / Unique Bounces',
        isSortable: true,
        sortByVar: 'total_failed',
        dataType: 'integer',
        dataVar: 'totalFailed',
        dataSubVar: 'uniqueFailed',
        isSelected: false,
        tooltip: 'The total number of push notifications that failed to get delivered, and the number of unique users it was failed to get delivered, respectively'
      }), Ember.Object.create({
        title: 'Total / Unique Opens',
        isSortable: true,
        sortByVar: 'total_open',
        dataType: 'integer',
        dataVar: 'totalOpen',
        dataSubVar: 'uniqueOpen',
        isSelected: false,
        tooltip: 'The total number of times this push notification was opened, and the number of unique users that opened it, respectively.'
      }), Ember.Object.create({
        title: 'Unique Open Rate',
        isSortable: true,
        sortByVar: 'unique_open_rate',
        dataType: 'percent',
        dataVar: 'uniqueOpenRate',
        isSelected: false,
        tooltip: 'Unique Opens divided by Unique Delivered.'
      })];
    },
    normalizePushPaginationData: function normalizePushPaginationData(pageData) {
      return pageData;
    },
    normalizePushTableData: function normalizePushTableData(resData, store) {
      var _this = this;
      return new Promise(function (resolve, reject) {
        // handle resolution of each row
        if (!Ember.isEmpty(resData.data.Pushes) && resData.pagination) {
          var pageInfo = resData.pagination;
          var data = resData.data.Pushes;
          var formattedData = [];
          var pushMessages = [];
          var pushTemplates = [];
          var contextRules = [];
          data.forEach(function (row) {
            var formattedRow = [];

            // perhaps we can delegate the ternary checks to the table itself
            // populate available data
            _this.getPushTableHeaders().forEach(function (header) {
              formattedRow.pushObject(Ember.Object.create({
                header: header.title,
                isSelected: pageInfo.sortBy === header.sortByVar ? true : false,
                value: row[header.dataVar],
                isSecondRow: header.dataSubVar ? true : false,
                secondRowValue: row[header.dataSubVar],
                subValue: header.dataType === 'string' ? '' : null,
                dataType: header.dataType,
                tooltip: header.tooltip
              }));
            });
            formattedData.pushObject(formattedRow);
            if (row.pushId) {
              pushMessages.pushObject(store.findRecord('push-message', row.pushId));
            } else {
              pushMessages.pushObject(Ember.Object.create({
                name: 'Push ID N/A'
              }));
            }
          });

          // get push notifications by id from data
          Ember.RSVP.allSettled(pushMessages).then(function (pushResArr) {
            pushResArr.forEach(function (pushRes, idx, arr) {
              if (pushRes.state === 'rejected') {
                var err = pushRes.reason;
                if (err.errors.objectAt(0).status === '404') {
                  // handle deleted push messages
                  arr[idx] = Ember.Object.create({
                    name: data[idx].pushId,
                    type: "\u2014"
                  });
                  pushTemplates.pushObject(Ember.Object.create({
                    name: "\u2014"
                  }));
                  contextRules.pushObject(Ember.Object.create({
                    name: "\u2014"
                  }));
                  formattedData.objectAt(idx).find(function (field) {
                    return field.header === 'Status';
                  }).value = 'Deleted';
                } else {
                  reject(err.errors.objectAt(0));
                }
              } else {
                // push enitites into collections for later use
                if (data[idx].pushId) {
                  if (pushRes.value.pushTypeId === '') {
                    pushTemplates.pushObject(Ember.Object.create({
                      name: 'Basic'
                    }));
                  } else {
                    pushTemplates.pushObject(store.findRecord('push-type', pushRes.value.pushTypeId));
                  }
                  if (!Ember.isEmpty(pushRes.value.rules)) {
                    contextRules.pushObject(store.findRecord('rule', pushRes.value.rules.objectAt(0).ruleID));
                  } else {
                    contextRules.pushObject(Ember.Object.create({
                      name: 'N/A'
                    }));
                  }
                  arr[idx] = pushRes.value;
                } else {
                  pushTemplates.pushObject(Ember.Object.create({
                    name: "\u2014"
                  }));
                  contextRules.pushObject(Ember.Object.create({
                    name: "\u2014"
                  }));
                  formattedData.objectAt(idx).find(function (field) {
                    return field.header === 'Status';
                  }).value = "\u2014";
                }
              }
            });

            // resolve entity (findRecord) calls
            Ember.RSVP.allSettled([Ember.RSVP.allSettled(pushTemplates), Ember.RSVP.allSettled(contextRules)]).then(function (resArr) {
              var pTemplateResArr = resArr[0].value;
              var ruleResArr = resArr[1].value;
              pTemplateResArr.forEach(function (res, idx, arr) {
                if (res.state === 'rejected') {
                  var err = res.reason;
                  if (err.errors.objectAt(0).status === '404') {
                    arr[idx] = Ember.Object.create({
                      name: "\u2014"
                    });
                  } else {
                    reject(res.reason.errors.objectAt(0));
                  }
                } else {
                  arr[idx] = res.value;
                }
              });
              ruleResArr.forEach(function (res, idx, arr) {
                if (res.state === 'rejected') {
                  var err = res.reason;
                  if (err.errors.objectAt(0).status === '404') {
                    arr[idx] = Ember.Object.create({
                      name: "".concat(pushResArr.objectAt(idx).rules.objectAt(0).ruleID)
                    });
                  } else {
                    reject(res.reason.errors.objectAt(0));
                  }
                } else {
                  arr[idx] = res.value;
                }
              });

              // stitch together formatted data
              if (!Ember.isEmpty(ruleResArr) && !Ember.isEmpty(pTemplateResArr)) {
                formattedData.forEach(function (row, idx) {
                  var contextRule = row.find(function (field) {
                    return field.header === 'Associated Context Rule';
                  });
                  if (!contextRule.value) {
                    contextRule.value = ruleResArr.objectAt(idx).name;
                  }
                  var status = row.find(function (field) {
                    return field.header === 'Status';
                  });
                  if (!status.value) {
                    if (pushResArr.objectAt(idx).type === 'broadcast') {
                      status.value = 'Sent';
                    } else {
                      status.value = pushResArr.objectAt(idx).isActive ? 'Active' : 'Inactive';
                    }
                  }
                  var nameField = row.find(function (field) {
                    return field.header === 'Name';
                  });
                  nameField.value = pushResArr.objectAt(idx).name;
                  nameField.subValue = "".concat(pTemplateResArr.objectAt(idx).name, ", ").concat(status.value === 'Deleted' || status.value === "\u2014" ? "\u2014" : pushResArr.objectAt(idx).type === 'broadcast' ? 'Manual' : 'Automatic');
                });
              }
              resolve(formattedData);
            });
          });
        } else if (resData.pagination) {
          resolve([]);
        } else {
          var err = new Error();
          err.status = 9001;
          reject(err);
        }
      });
    },
    serializePushQueryParams: function serializePushQueryParams(requestObject) {
      var retVal = Ember.Object.create({
        domain: requestObject.domain,
        endpoint: requestObject.endpoint,
        isProduction: requestObject.isProduction,
        queryParams: Ember.Object.create({
          labelsFormula: window.encodeURIComponent(requestObject.queryParams.labels),
          limit: requestObject.queryParams.limit,
          offset: requestObject.queryParams.offset,
          sortBy: requestObject.queryParams.sortBy,
          sortOrder: requestObject.queryParams.sortOrder,
          lastFrom: Math.floor(requestObject.queryParams.timeFrom / 1000),
          lastTo: Math.floor(requestObject.queryParams.timeTo / 1000),
          search: requestObject.queryParams.search
        })
      });
      if (!requestObject.queryParams.labels) {
        delete retVal.queryParams.labelsFormula;
      }
      return retVal;
    },
    getRuleTableHeaders: function getRuleTableHeaders() {
      return [Ember.Object.create({
        title: 'Name',
        isSortable: true,
        sortByVar: 'rule_name',
        dataType: 'string',
        dataVar: 'ruleName',
        isSelected: false,
        tooltip: ''
      }), Ember.Object.create({
        title: 'Status',
        isSortable: false,
        dataType: 'string',
        dataVar: 'status',
        tooltip: ''
      }), Ember.Object.create({
        title: 'Total Reach',
        isSortable: true,
        sortByVar: 'total_reach',
        dataType: 'integer',
        dataVar: 'totalReach',
        isSelected: false,
        tooltip: 'The total number of times this particular rule was fulfilled, within the time range and labels you have selected.'
      }), Ember.Object.create({
        title: 'Unique Reach',
        isSortable: true,
        sortByVar: 'unique_reach',
        dataType: 'integer',
        dataVar: 'uniqueReach',
        isSelected: true,
        tooltip: 'The number of unique users who have fulfilled this particular rule, within the time range and labels you have selected.'
      })];
    },
    getContentTableHeaders: function getContentTableHeaders() {
      // Content Analytics Flag: Remove if and second return
      var isContentAnalyticsFlagEnabled = this.getIsContentAnalyticsFlagEnabled();
      if (isContentAnalyticsFlagEnabled) {
        return [Ember.Object.create({
          title: 'Name',
          isSortable: false,
          dataType: 'string',
          dataVar: 'elementName'
        }), Ember.Object.create({
          title: 'Type',
          isSortable: false,
          dataType: 'string',
          dataVar: 'elementType'
        }), Ember.Object.create({
          title: 'Total Views',
          isSortable: true,
          sortByVar: 'totalViews',
          dataType: 'integer',
          dataVar: 'totalViews',
          isSelected: true,
          tooltip: 'The total number of times this particular content item has been viewed.'
        }), Ember.Object.create({
          title: 'Unique Views',
          isSortable: true,
          sortByVar: 'uniqueViews',
          dataType: 'integer',
          dataVar: 'uniqueViews',
          isSelected: false,
          tooltip: 'The number of unique users who have viewed this particular content item.'
        }), Ember.Object.create({
          title: 'Total Clicks',
          isSortable: true,
          sortByVar: 'totalClicks',
          dataType: 'integer',
          dataVar: 'totalClicks',
          isSelected: false,
          tooltip: 'The total number of clicks on any call-to-action within the content.'
        }), Ember.Object.create({
          title: 'Unique Clicks',
          isSortable: true,
          sortByVar: 'uniqueClicks',
          dataType: 'integer',
          dataVar: 'uniqueClicks',
          isSelected: false,
          tooltip: 'The number of unique users who clicked a call-to-action within the content.'
        }), Ember.Object.create({
          title: 'Unique Click Rate',
          isSortable: true,
          sortByVar: 'uniqueClickRate',
          dataType: 'percent',
          dataVar: 'uniqueClickRate',
          isSelected: false,
          tooltip: 'Unique clicks divided by unique views.'
        })];
      }
      return [Ember.Object.create({
        title: 'Name',
        isSortable: false,
        dataType: 'string',
        dataVar: null
      }), Ember.Object.create({
        title: 'Type',
        isSortable: false,
        dataType: 'string',
        dataVar: null
      }), Ember.Object.create({
        title: 'Total Views',
        isSortable: true,
        sortByVar: 'total_views',
        dataType: 'integer',
        dataVar: 'totalViews',
        isSelected: true,
        tooltip: 'The total number of times this particular content item has been viewed.'
      }), Ember.Object.create({
        title: 'Unique Views',
        isSortable: true,
        sortByVar: 'unique_views',
        dataType: 'integer',
        dataVar: 'uniqueViews',
        isSelected: false,
        tooltip: 'The number of unique users who have viewed this particular content item.'
      }), Ember.Object.create({
        title: 'Unique Engagement',
        isSortable: true,
        sortByVar: 'unique_engagements',
        dataType: 'integer',
        dataVar: 'uniqueEngagements',
        isSelected: false,
        tooltip: 'The number of unique users who have “engaged” a certain action. Engagement differs per content item type, depending on how your team has defined it per content item.'
      }), Ember.Object.create({
        title: 'Unique Engagement Rate',
        isSortable: true,
        sortByVar: 'engagement_rate',
        dataType: 'percent',
        dataVar: 'engagementRate',
        isSelected: false,
        tooltip: 'Unique engagement divided by unique views.'
      }), Ember.Object.create({
        title: 'Unique Fulfillment',
        isSortable: true,
        sortByVar: 'unique_fulfillments',
        dataType: 'integer',
        dataVar: 'uniqueFulfillments',
        isSelected: false,
        tooltip: 'The number of unique users who have “fulfilled” a certain action. Fulfillment differs per content item type, depending on how your team has defined it per content item. '
      }), Ember.Object.create({
        title: 'Unique Fulfillment Rate',
        isSortable: true,
        sortByVar: 'fulfillment_rate',
        dataType: 'percent',
        dataVar: 'fulfillmentRate',
        isSelected: false,
        tooltip: 'Unique fulfillments divided by unique views.'
      })];
    },
    // Hacky function to (kind of) determine if a component name
    //  exists inside a content, by doing a string search to the values
    //  of content.data array.
    isContentDataMatchComponentName: function isContentDataMatchComponentName(data, name) {
      var reg = /\.pagination/;
      var search = function search(obj) {
        var searchSet = new Set();
        if (Array.isArray(obj)) {
          obj.forEach(function (item) {
            searchSet.add(search(item));
          });
        } else if (_typeof(obj) === 'object') {
          if (obj.buttons) {
            return search(obj.buttons);
          } else if (obj.link) {
            return search(obj.link);
          }
          Object.keys(obj).forEach(function (key) {
            if (!reg.test(key)) {
              searchSet.add(search(obj[key]));
            }
          });
        }
        if (searchSet.has(true)) return true;
        return obj === name;
      };
      return search(data);
    },
    // Put the name in default user language in componentNames to be the first
    //  in the list.
    normalizeLocalizedComponentNames: function normalizeLocalizedComponentNames(componentNames, data, defaultLanguage) {
      // Search for localizations object
      var searchDefaultLocalizationValue = function searchDefaultLocalizationValue(name, obj) {
        var searchSet = [];
        if (Array.isArray(obj)) {
          obj.forEach(function (item) {
            searchSet.push(searchDefaultLocalizationValue(name, item));
          });
        } else if (_typeof(obj) === 'object') {
          if (obj.buttons) {
            return searchDefaultLocalizationValue(name, obj.buttons);
          } else if (obj.link) {
            return searchDefaultLocalizationValue(name, obj.link);
          } else if (obj.localizations) {
            return obj.localizations[defaultLanguage];
          }
          Object.keys(obj).forEach(function (key) {
            searchSet.push(searchDefaultLocalizationValue(name, obj[key]));
          });
        }
        return searchSet.filter(function (item) {
          return item;
        }).find(function (loc) {
          return Object.values(loc).find(function (value) {
            return value === name;
          });
        });
      };
      var orderedComponentNames = [];
      componentNames.forEach(function (name) {
        if (orderedComponentNames.length) return;
        var isNameDefaultLang = searchDefaultLocalizationValue(name, data);
        if (isNameDefaultLang) {
          orderedComponentNames.push(name);
        }
      });
      return Array.from(new Set(orderedComponentNames.concat(componentNames)));
    },
    normalizeRulePaginationData: function normalizeRulePaginationData(pageData) {
      return pageData;
    },
    normalizeRuleTableData: function normalizeRuleTableData(resData) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        // handle resolution of each row
        if (!Ember.isEmpty(resData.data) && resData.pagination) {
          var pageInfo = resData.pagination;
          var data = resData.data;
          var formattedData = [];
          data.forEach(function (row) {
            var formattedRow = [];

            // perhaps we can delegate the ternary checks to the table itself
            // populate available data
            _this2.getRuleTableHeaders().forEach(function (header) {
              formattedRow.pushObject(Ember.Object.create({
                header: header.title,
                isSelected: pageInfo.sortBy === header.sortByVar ? true : false,
                value: row[header.dataVar],
                subValue: header.dataType === 'string' ? '' : null,
                dataType: header.dataType,
                tooltip: header.tooltip
              }));
            });
            formattedData.pushObject(formattedRow);
          });
          resolve(formattedData);
        } else if (resData.pagination) {
          resolve([]);
        } else {
          var err = new Error();
          err.status = 9001;
          reject(err);
        }
      });
    },
    normalizeContentTableData: function normalizeContentTableData(resData, store) {
      var _this3 = this;
      // Content Analytics Flag: Remove if and second return
      var isContentAnalyticsFlagEnabled = this.getIsContentAnalyticsFlagEnabled();
      if (isContentAnalyticsFlagEnabled) {
        return new Promise(function (resolve, reject) {
          if (!Ember.isEmpty(resData.data) && resData.pagination) {
            var pageInfo = resData.pagination;
            var data = resData.data.map(function (content) {
              var componentIdMap = {};
              content.components.forEach(function (comp) {
                if (!componentIdMap[comp.componentId]) {
                  componentIdMap[comp.componentId] = {
                    elementName: [comp.elementName],
                    elementType: comp.elementType,
                    totalClicks: comp.totalClicks,
                    uniqueClicks: comp.uniqueClicks
                  };
                } else {
                  componentIdMap[comp.componentId].elementName.push(comp.elementName);
                }
              });
              return Object.assign({}, content, {
                components: Object.keys(componentIdMap).map(function (key) {
                  return componentIdMap[key];
                })
              });
            });
            var formattedData = [];
            var contentInstances = [];
            var contentTemplates = [];
            var isRowOdd = true;
            data.forEach(function (row) {
              var formattedRow = {
                components: [],
                data: []
              };
              var formattedRowData = [];
              _this3.getContentTableHeaders().forEach(function (header) {
                formattedRowData.pushObject(Ember.Object.create({
                  header: header.title,
                  isSelected: pageInfo.sortBy === header.sortByVar ? true : false,
                  value: row[header.dataVar],
                  subValue: header.dataType === 'string' ? '' : null,
                  dataType: header.dataType,
                  tooltip: header.tooltip
                }));
              });
              formattedRow.data = formattedRowData;
              if (row.components && row.components.length) {
                var rowComponents = [];
                row.components.forEach(function (component) {
                  var formattedDataComponentRow = [];
                  _this3.getContentTableHeaders().forEach(function (header) {
                    formattedDataComponentRow.pushObject(Ember.Object.create({
                      header: header.title,
                      isSelected: false,
                      value: component[header.dataVar] || '-',
                      subValue: '',
                      dataType: 'string',
                      tooltip: null
                    }));
                  });
                  rowComponents.push(formattedDataComponentRow);
                });
                formattedRow.components = rowComponents;
                formattedRow.hasComponents = true;
                formattedRow.id = row.contentId;
              }
              // showShadow will be true for even index of formattedRow. It is used
              //  to determine the absolute index of table rows as component rows
              //  exist between content rows. showShadow is currently used to add a
              //  different class to even index of content rows.
              formattedRow.showShadow = isRowOdd;
              isRowOdd = !isRowOdd;
              formattedData.pushObject(formattedRow);
              if (!Ember.isEmpty(row.contentId)) {
                contentInstances.pushObject(store.findRecord('content-instance', row.contentId));
              } else {
                contentInstances.pushObject(Ember.Object.create({
                  name: 'Content ID N/A'
                }));
              }
            });
            Ember.RSVP.allSettled(contentInstances).then(function (contentResArr) {
              contentResArr.forEach(function (contentRes, idx, arr) {
                if (contentRes.state === 'rejected') {
                  var err = contentRes.reason;
                  if (err.errors.objectAt(0).status === '404') {
                    var deletedObj = Ember.Object.create({
                      name: '[Deleted]'
                    });
                    arr[idx] = deletedObj;
                    contentTemplates.pushObject(deletedObj);
                    formattedData[idx].components.map(function (comp) {
                      return comp.find(function (field) {
                        return field.header === 'Name';
                      });
                    }).forEach(function (nameArr) {
                      nameArr.value = nameArr.value.map(function (name) {
                        return "\"".concat(name, "\"");
                      }).join(' / ') || '[Deleted]';
                    });
                  } else {
                    reject(err.errors.objectAt(0));
                  }
                } else {
                  arr[idx] = contentRes.value;
                  // The following block removes outdated component names that
                  //  does not exist within the content anymore, using
                  //  isContentDataMatchComponentName function.
                  _this3.utility.get('languages').then(function (lang) {
                    formattedData[idx].components.map(function (comp) {
                      return comp.find(function (field) {
                        return field.header === 'Name';
                      });
                    }).forEach(function (nameArr) {
                      nameArr.value = _this3.normalizeLocalizedComponentNames(nameArr.value.filter(function (name) {
                        return _this3.isContentDataMatchComponentName(contentResArr[idx].content.data, name);
                      }), contentResArr[idx].content.data, lang.config.find(function (lang) {
                        return lang.isDefault;
                      }).id).map(function (name) {
                        return "\"".concat(name, "\"");
                      }).join(' / ') || '[Deleted]';
                    });
                  });
                  if (contentRes.value.contentTemplateId) {
                    contentTemplates.pushObject(store.findRecord('content-template', contentRes.value.contentTemplateId));
                  } else {
                    contentTemplates.pushObject(Ember.Object.create({
                      name: "\u2014"
                    }));
                  }
                }
              });
              Ember.RSVP.allSettled(contentTemplates).then(function (templateResArr) {
                templateResArr.forEach(function (templateRes, idx, arr) {
                  if (templateRes.state === 'rejected') {
                    var err = templateRes.reason;
                    if (err.errors.objectAt(0).status === '404') {
                      arr[idx] = Ember.Object.create({
                        // perhaps this can facilite the removal of {{{ templating
                        name: "\u2014"
                      });
                    } else {
                      reject(err.errors.objectAt(0));
                    }
                  } else {
                    arr[idx] = templateRes.value;
                  }
                });

                // not sure what the performance costs are for grouping all of these
                // field population calls in one place rather than doing it along
                // the logic's flow
                formattedData.forEach(function (formattedRow, idx) {
                  formattedRow.data.find(function (field) {
                    return field.header === 'Name';
                  }).value = contentResArr.objectAt(idx).name;
                  formattedRow.data.find(function (field) {
                    return field.header === 'Type';
                  }).value = templateResArr.objectAt(idx).name;
                });
                resolve(formattedData);
              });
            });
          } else if (resData.pagination) {
            resolve([]);
          } else {
            var err = new Error();
            err.status = 9001;
            reject(err);
          }
        });
      }

      // Content Analytics Flag: remove this return
      return new Promise(function (resolve, reject) {
        // handle resolution of each row
        if (!Ember.isEmpty(resData.data) && resData.pagination) {
          var pageInfo = resData.pagination;
          var data = resData.data;
          var formattedData = [];
          var contentInstances = [];
          var contentTemplates = [];
          data.forEach(function (row) {
            var formattedRow = [];
            _this3.getContentTableHeaders().forEach(function (header) {
              formattedRow.pushObject(Ember.Object.create({
                header: header.title,
                isSelected: pageInfo.sortBy === header.sortByVar ? true : false,
                value: row[header.dataVar],
                subValue: header.dataType === 'string' ? '' : null,
                dataType: header.dataType,
                tooltip: header.tooltip
              }));
            });
            formattedData.pushObject(formattedRow);
            if (!Ember.isEmpty(row.contentId)) {
              contentInstances.pushObject(store.findRecord('content-instance', row.contentId));
            } else {
              contentInstances.pushObject(Ember.Object.create({
                name: 'Content ID N/A'
              }));
            }
          });
          Ember.RSVP.allSettled(contentInstances).then(function (contentResArr) {
            contentResArr.forEach(function (contentRes, idx, arr) {
              if (contentRes.state === 'rejected') {
                var err = contentRes.reason;
                if (err.errors.objectAt(0).status === '404') {
                  arr[idx] = Ember.Object.create({
                    name: data[idx].contentId
                  });
                  contentTemplates.pushObject(Ember.Object.create({
                    name: '&mdash;'
                  }));
                } else if (err.errors.objectAt(0).status === '501') {
                  arr[idx] = Ember.Object.create({
                    name: 'Content ID N/A'
                  });
                  contentTemplates.pushObject(Ember.Object.create({
                    name: '&mdash;'
                  }));
                } else {
                  reject(err.errors.objectAt(0));
                }
              } else {
                arr[idx] = contentRes.value;
                if (contentRes.value.contentTemplateId) {
                  contentTemplates.pushObject(store.findRecord('content-template', contentRes.value.contentTemplateId));
                } else {
                  contentTemplates.pushObject(Ember.Object.create({
                    name: "\u2014"
                  }));
                }
              }
            });
            Ember.RSVP.allSettled(contentTemplates).then(function (templateResArr) {
              templateResArr.forEach(function (templateRes, idx, arr) {
                if (templateRes.state === 'rejected') {
                  var err = templateRes.reason;
                  if (err.errors.objectAt(0).status === '404') {
                    arr[idx] = Ember.Object.create({
                      // perhaps this can facilite the removal of {{{ templating
                      name: "\u2014"
                    });
                  } else {
                    reject(err.errors.objectAt(0));
                  }
                } else {
                  arr[idx] = templateRes.value;
                }
              });

              // not sure what the performance costs are for grouping all of these
              // field population calls in one place rather than doing it along
              // the logic's flow
              formattedData.forEach(function (formattedRow, idx) {
                formattedRow.find(function (field) {
                  return field.header === 'Name';
                }).value = contentResArr.objectAt(idx).name;
                formattedRow.find(function (field) {
                  return field.header === 'Type';
                }).value = templateResArr.objectAt(idx).name;
              });
              resolve(formattedData);
            });
          });
        } else if (resData.pagination) {
          resolve([]);
        } else {
          var err = new Error();
          err.status = 9001;
          reject(err);
        }
      });
    },
    serializeRuleQueryParams: function serializeRuleQueryParams(requestObject) {
      var retVal = Ember.Object.create({
        domain: requestObject.domain,
        endpoint: requestObject.endpoint,
        isProduction: requestObject.isProduction,
        queryParams: Ember.Object.create({
          labelsFormula: window.encodeURIComponent(requestObject.queryParams.labels),
          limit: requestObject.queryParams.limit,
          offset: requestObject.queryParams.offset,
          sortBy: requestObject.queryParams.sortBy,
          sortOrder: requestObject.queryParams.sortOrder,
          lastFrom: Math.floor(requestObject.queryParams.timeFrom / 1000),
          lastTo: Math.floor(requestObject.queryParams.timeTo / 1000),
          search: requestObject.queryParams.search
        })
      });
      if (!requestObject.queryParams.labels) {
        delete retVal.queryParams.labelsFormula;
      }
      return retVal;
    },
    serializeContentQueryParams: function serializeContentQueryParams(requestObject) {
      var retVal = Ember.Object.create({
        domain: requestObject.domain,
        endpoint: requestObject.endpoint,
        isProduction: requestObject.isProduction,
        queryParams: Ember.Object.create({
          labelsFormula: window.encodeURIComponent(requestObject.queryParams.labels),
          limit: requestObject.queryParams.limit,
          offset: requestObject.queryParams.offset,
          sortBy: requestObject.queryParams.sortBy,
          sortOrder: requestObject.queryParams.sortOrder,
          lastFrom: Math.floor(requestObject.queryParams.timeFrom / 1000),
          lastTo: Math.floor(requestObject.queryParams.timeTo / 1000),
          search: requestObject.queryParams.search
        })
      });
      if (!requestObject.queryParams.labels) {
        delete retVal.queryParams.labelsFormula;
      }
      return retVal;
    },
    setupTotalUsersRequests: function setupTotalUsersRequests(selectedInterval, opt) {
      var durationUnit = (0, _emberInflector.pluralize)(selectedInterval.name);
      var obj = {
        from: null,
        to: null,
        interval: ''
      };
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === 'from') {
            var from = window.moment(opt.from);
            if (durationUnit === 'days') {
              obj[key] = from.subtract(1, 'days').startOf('day').unix();
            } else {
              obj[key] = from.startOf('day').unix();
            }
          }
          if (key === 'to') {
            var to = window.moment(opt.to);
            obj[key] = to.endOf('day').unix();
          }
          if (key === 'interval') {
            obj[key] = selectedInterval.name;
          }
        }
      }
      this.requests[0].setProperties({
        from: obj.from,
        to: obj.to,
        interval: obj.interval
      });
    },
    setupActiveUsersRequests: function setupActiveUsersRequests(selectedInterval, opt) {
      var durationUnit = (0, _emberInflector.pluralize)(selectedInterval.name);
      var obj = {
        from: null,
        to: null,
        interval: ''
      };
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === 'from') {
            var from = window.moment(opt.from);
            obj[key] = from.startOf('day').unix();
            if (durationUnit === 'days') {
              obj[key] = from.subtract(1, 'days').startOf('day').unix();
            }
          }
          if (key === 'to') {
            var to = window.moment(opt.to);
            obj[key] = to.endOf('day').unix();
          }
          if (key === 'interval') {
            obj[key] = selectedInterval.name;
          }
        }
      }
      this.requests[0].setProperties({
        from: obj.from,
        to: obj.to,
        interval: obj.interval
      });
    },
    normalizeLabels: function normalizeLabels(labels) {
      return labels.map(function (label) {
        return Ember.Object.create({
          name: label.id || label,
          isSelected: false
        });
      });
    },
    fetchLabels: function fetchLabels(data) {
      var _this4 = this;
      var queryParams = {
        recordsPerPage: data.recordsPerPage,
        page: data.page,
        search: data.search || ''
      };
      return new Promise(function (resolve, reject) {
        var labelAdapter = _this4.store.adapterFor('label');
        var headers = labelAdapter.get('headers');
        var url = "".concat(labelAdapter.urlForFindAll(), "/labels?").concat(_jquery.default.param(queryParams));
        _jquery.default.ajax({
          dataType: 'json',
          headers: headers,
          type: 'GET',
          url: url
        }).done(function (response) {
          return resolve(response);
        }).fail(function (jqXHR) {
          return reject(jqXHR);
        });
      });
    },
    makeLabelsSearchRequest: function makeLabelsSearchRequest(searchKeyword) {
      var _this5 = this;
      var searchResultsArray;
      this.fetchLabels({
        search: searchKeyword
      }).then(function (response) {
        searchResultsArray = _this5.normalizeLabels(response.data);
        var searchResultsLabelNames = searchResultsArray.map(function (item) {
          return item.name;
        });
        _this5.labelsSham.forEach(function (item) {
          if (searchResultsLabelNames.includes(item.name)) {
            searchResultsArray.filterBy('name', item.name).objectAt(0).set('isSelected', item.isSelected);
          }
        });
        _this5.requests.forEach(function (item) {
          item.set('labelSearchResults', searchResultsArray);
        });
        _this5.set('isLoadingSearchResults', false);
      }).catch(function (response) {
        _this5.setProperties({
          errorMessage: _this5.utility.parseError(response).toLowerCase(),
          isLoadingSearchResults: false
        });
      });
    },
    actions: {
      searchLabels: function searchLabels(searchKeyword) {
        var _this6 = this;
        var data = {
          recordsPerPage: 1000000,
          page: 1
        };
        this.setProperties({
          inSearchMode: true,
          isLoadingSearchResults: true
        });
        this.fetchLabels(data).then(function (response) {
          _this6.set('labelsPagination', response.pagination);
          var newLabels = _this6.normalizeLabels(response.data);
          var filteredLabelsSham = _this6.labelsSham.filterBy('isSelected', true);
          if (!Ember.isEmpty(filteredLabelsSham)) {
            newLabels.forEach(function (item) {
              if (!Ember.isEmpty(filteredLabelsSham.filterBy('name', item.name))) {
                item.set('isSelected', true);
              }
            });
          }
          _this6.setProperties({
            isLoadingLabels: false,
            labelsSham: newLabels
          });
          _this6.makeLabelsSearchRequest(searchKeyword);
        }).catch(function (response) {
          _this6.setProperties({
            errorMessage: _this6.utility.parseError(response).toLowerCase(),
            isLoadingSearchResults: false
          });
        });
      },
      loadMoreLabels: function loadMoreLabels(data) {
        var _this7 = this;
        this.set('inSearchMode', false);
        if (this.labelsPagination && this.labelsPagination.total <= this.labelsSham.length) {
          this.set('isLoadingLabels', false);
        } else {
          this.set('isLoadingLabels', true);
          this.fetchLabels(data).then(function (response) {
            _this7.set('labelsPagination', response.meta.pagination);
            _this7.normalizeLabels(response).forEach(function (label) {
              _this7.labelsSham.pushObject(label);
            });
            _this7.set('isLoadingLabels', false);
            data.element.currrentTarget.scrollTop(data.element.lastTop);
          }).catch(function (response) {
            _this7.setProperties({
              errorMessage: _this7.utility.parseError(response).toLowerCase(),
              isLoadingSearchResults: false
            });
          });
        }
      }
    }
  });
  _exports.default = _default;
});