define("experience-studio/components/content-tmp-edit-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-date
      field=field
      pickerOpts=flatPickrOptions
    }}
  
  */

  var timezoneOffsets = null;
  var findTZName = function findTZName(offset) {
    var results = timezoneOffsets.filter(function (obj) {
      return obj.offset === offset;
    });
    var usResult = results.filter(function (obj) {
      return obj.name.indexOf('US/') > -1;
    })[0];
    var americaResult = results.filter(function (obj) {
      return obj.name.indexOf('America/') > -1;
    })[0];
    return usResult || americaResult || results[0];
  };
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-date'],
    availableTimezones: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    pickerOpts: {},
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    timezone: null,
    timezoneInit: false,
    setModelDate: function setModelDate(dateStr) {
      if (!dateStr && this.get('field.value')) {
        this.set('field.value', '');
      } else if (dateStr && dateStr !== '') {
        var isoStr = window.moment.tz(dateStr, this.timezone).format();
        this.set('field.value', isoStr);
      }
    },
    extractDateStr: function extractDateStr(isoStr) {
      var dateStr = isoStr.replace('T', ' ');
      if (dateStr.indexOf('Z') > -1) {
        dateStr = dateStr.split('Z')[0];
      } else {
        dateStr = dateStr.substr(0, dateStr.length - 6);
      }
      return dateStr;
    },
    initTimezone: function initTimezone() {
      if (this.get('field.value')) {
        var offset = window.moment.parseZone(this.get('field.value')).utcOffset();
        var guess = findTZName(offset);
        if (guess) {
          this.set('timezone', guess.name);
        } else {
          this.set('timezone', window.moment.tz.guess());
        }
        this.set('timezoneInit', true);
      } else {
        this.set('timezone', window.moment.tz.guess());
      }
    },
    dateUpdated: Ember.observer('field.value', function () {
      var pickerInput = this.get('picker.input');
      if (pickerInput) {
        var pickerVal = this.get('picker.input').value;
        if (pickerVal !== this.get('field.value')) {
          if (!this.get('field.value')) {
            this.picker.clear();
          } else {
            this.picker.setDate(this.extractDateStr(this.get('field.value')), false);
            if (!this.timezoneInit) {
              this.initTimezone();
            }
          }
        }
      }
    }),
    timezoneUpdated: Ember.observer('timezone', function () {
      if (this.get('field.value') && this.timezone) {
        var dateStr = this.extractDateStr(this.get('field.value'));
        this.setModelDate(dateStr);
      }
    }),
    didInsertElement: function didInsertElement() {
      var view = this;
      var dateInput = this.$('.date-field');
      var tzStrs = window.moment.tz.names();
      if (!timezoneOffsets) {
        timezoneOffsets = window.moment.tz.names().map(function (obj) {
          return {
            name: obj,
            offset: window.moment.tz(obj)._offset
          };
        });
      }
      this.set('availableTimezones', tzStrs);
      this.initTimezone();

      // eslint-disable-next-line
      var picker = new window.flatpickr(dateInput.get(0), {
        enableTime: true,
        onChange: function onChange(selectedDates, dateStr) {
          view.setModelDate(dateStr);
        }
      });
      this.set('picker', picker);
      if (picker && this.get('field.value')) {
        picker.setDate(view.extractDateStr(this.get('field.value')), false);
      }
    },
    actions: {
      selectValue: function selectValue(model, variable, selection) {
        if (model) {
          model.set(variable, selection);
        } else {
          this.set(variable, selection);
        }
      }
    }
  });
  _exports.default = _default;
});