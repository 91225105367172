define("experience-studio/components/av5-info-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Info chart component. The chart is initialized and options are passed to it.
  
    Usages:
  
      <Av5InfoChart
        model=model
      />
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-info-chart'],
    areaStyle: null,
    charts: null,
    model: null,
    timestamps: null,
    values: null,
    requests: null,
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.charts) {
        this.set('charts', []);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initalizeChart(this.modelSham);
    },
    // COMPUTED ------------------------------------------------------------------
    modelSham: Ember.computed('model.[]', function () {
      return this.model;
    }),
    // OBSERVERS -----------------------------------------------------------------
    didModelChange: Ember.observer('model.[]', function () {
      this.set('modelSham', this.model);
      this.initalizeChart(this.modelSham);
    }),
    // FUNCTIONS -----------------------------------------------------------------
    initalizeChart: function initalizeChart(model) {
      var values = [];
      var timestamps = [];
      var self = this;
      var interval = this.requests.objectAt(0).interval;
      model.forEach(function (item) {
        values.pushObject(item.value);
        timestamps.pushObject(item.timestamp * 1000);
      });
      var option = this.prepareOptions(values, timestamps, this.areaStyle, interval);
      var chart = document.querySelector("#".concat(this.elementId, " .chart"));
      var myChart = window.echarts.init(chart);
      this.charts.push(myChart);
      window.onresize = function () {
        self.charts.forEach(function (obj) {
          obj.resize();
        });
      };
      myChart.setOption(option);
    },
    parseValues: function parseValues(values) {
      var endpoint = this.requests.objectAt(0).endpoint;
      if (endpoint === 'users/timeline/total' || endpoint === 'analytics/users/timeline/active') {
        return values;
      }
    },
    parseTimestamps: function parseTimestamps(timestamps) {
      var endpoint = this.requests.objectAt(0).endpoint;
      if (endpoint === 'users/timeline/total' || endpoint === 'analytics/users/timeline/active') {
        return timestamps;
      }
    },
    createToolTipContainer: function createToolTipContainer(percentage, diff, params, requests, timeRange) {
      var interval = requests.objectAt(0).interval;
      var svgUp = "<svg class=\"carrot-down\" style=\"fill:#50E3C2; width: 16px; transform: rotate(180deg);\" viewBox=\"0 0 24 24\">\n      <path d=\"M3.8,7.65H20.2a1,1,0,0,1,.63,1.77l-8.2,6.71a1,1,0,0,1-1.26,0L3.17,9.42A1,1,0,0,1,3.8,7.65Z\"/>\n    </svg>";
      var svgDown = "<svg class=\"carrot-down\" style=\"fill:#D63031; width: 16px;\" viewBox=\"0 0 24 24\">\n      <path d=\"M3.8,7.65H20.2a1,1,0,0,1,.63,1.77l-8.2,6.71a1,1,0,0,1-1.26,0L3.17,9.42A1,1,0,0,1,3.8,7.65Z\"/>\n    </svg>";
      var indicator = '';
      var displayPercentage = '';
      if (interval === 'day' || params[0].dataIndex > 0) {
        displayPercentage = percentage;
        if (diff > 0) {
          indicator = "<span>\n          ".concat(svgUp, "\n        </span> vs.prev ").concat(interval);
        } else if (diff < 0) {
          indicator = "<span>\n          ".concat(svgDown, "\n        </span> vs.prev ").concat(interval);
        } else {
          indicator = "<span>\n          --\n        </span> vs.prev ".concat(interval);
        }
      }
      return "<div>\n        <p style=\"text-align: left; font-size: 11px;\">".concat(timeRange, "</p>\n        <p style=\"text-align: left;\">\n          ").concat(this.tooltipConfig.title, ":\n          <span style=\"font-weight: bold;\">\n            ").concat(window.Number(params[0].value).toLocaleString(), "\n          </span>\n          <span style=\"font-weight: bold; margin-left: 100px;\">").concat(displayPercentage, "</span>\n          ").concat(indicator, "\n        </p>\n      </div>");
    },
    calculateTotalUsersChange: function calculateTotalUsersChange(idx, values) {
      var diff;
      var percentage;
      if (idx < 1) {
        diff = 0;
        percentage = 0;
      } else {
        diff = values[idx] - values[idx - 1];
        var ratio = values[idx - 1] !== 0 ? diff / values[idx - 1] : diff;
        var rounded = ratio === 0 ? 0 : parseFloat(Math.round(ratio * 100) / 100);
        percentage = "".concat((rounded * 100).toFixed(0), "%");
      }
      return {
        diff: diff,
        percentage: percentage
      };
    },
    setupActiveUsersTooltipTimerange: function setupActiveUsersTooltipTimerange(idx, timeTo, startFrom, timestamps) {
      var timeRange;
      var timeFrom;
      var timeToISOString;
      var timeFromISOString;
      if (idx > 0) {
        timeFrom = timestamps[idx - 1];
        timeFromISOString = window.moment.utc(timeFrom, 'MMMM DD YYYY, h:mm a').toISOString();
        timeFrom = window.moment.utc(timeFromISOString).add(1, 'days').format('ddd, MMMM DD, YYYY');
        timeToISOString = window.moment.utc(timeTo, 'MMMM DD YYYY, h:mm a').toISOString();
        timeTo = window.moment.utc(timeToISOString).format('ddd, MMMM DD, YYYY');
        timeRange = "".concat(timeFrom, " to ").concat(timeTo);
      } else {
        timeTo = timestamps[idx];
        timeToISOString = window.moment.utc(timeTo, 'MMMM DD YYYY, h:mm a').toISOString();
        timeTo = window.moment.utc(timeToISOString).format('ddd, MMMM DD, YYYY');
        timeRange = "".concat(startFrom, " to ").concat(timeTo);
      }
      return timeRange;
    },
    calculateActiveUsersChange: function calculateActiveUsersChange(idx, values) {
      var diff;
      var percentage;
      if (idx < 1) {
        diff = 0;
        percentage = 0;
      } else {
        diff = values[idx] - values[idx - 1];
        var ratio = values[idx - 1] !== 0 ? diff / values[idx - 1] : diff;
        var rounded = ratio === 0 ? 0 : parseFloat(Math.round(ratio * 100) / 100);
        percentage = "".concat((rounded * 100).toFixed(0), "%");
      }
      return {
        diff: diff,
        percentage: percentage
      };
    },
    prepareOptions: function prepareOptions(values, timestamps, areaStyle, interval) {
      var self = this;
      var len = timestamps.length;
      var option = {};
      this.set('option', null);
      timestamps = timestamps.map(function (item, index) {
        if (interval === 'month') {
          if (index !== len - 1) {
            return window.moment.utc(item).endOf('month').format('MMM DD, YYYY');
          } else {
            return window.moment.utc(item).format('MMM DD, YYYY');
          }
        }
        if (interval === 'week') {
          return window.moment.utc(item).format('MMM DD, YYYY');
        }
        return window.moment.utc(item).format('MMM DD, YYYY');
      });
      this.setProperties({
        timestamps: timestamps,
        values: values
      });
      option = {
        tooltip: {
          trigger: 'axis',
          showDelay: 0,
          hideDelay: 50,
          confine: true,
          transitionDuration: 0,
          backgroundColor: ['rgba(250, 250, 253, 1)'],
          opacity: 0.6,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: ['rgba(210, 210, 210)'],
          padding: [15, 15, 15, 10],
          shadowBlur: 10,
          position: function position(p) {
            return [p[0] + 10, p[1] - 10];
          },
          textStyle: {
            color: 'black',
            decoration: 'none',
            fontFamily: 'Verdana, sans-serif',
            fontSize: 12
          },
          formatter: function formatter(params) {
            var requests = self.requests;
            var endpoint = requests.objectAt(0).endpoint;
            var interval = requests.objectAt(0).interval;
            var timeRange = '';
            if (endpoint === 'users/timeline/total') {
              var diffObj = self.calculateTotalUsersChange(params[0].dataIndex, values, interval);
              timeRange = params[0].axisValueLabel;
              return self.createToolTipContainer(diffObj.percentage, diffObj.diff, params, requests, timeRange);
            }
            if (endpoint === 'analytics/users/timeline/active') {
              var _timeRange = '';
              var timeTo = '';
              var _diffObj;
              var idx = params[0].dataIndex;
              if (interval === 'week') {
                var from = window.moment.utc(self.requests.objectAt(0).from * 1000);
                var startFrom = from.format('ddd, MMMM DD, YYYY');
                timeTo = self.timestamps[idx];
                _timeRange = self.setupActiveUsersTooltipTimerange(idx, timeTo, startFrom, timestamps);
                _diffObj = self.calculateActiveUsersChange(idx, values);
              } else if (interval === 'month') {
                var _from = window.moment.utc(self.requests.objectAt(0).from * 1000);
                var _startFrom = _from.format('ddd, MMMM DD, YYYY');
                timeTo = self.timestamps[idx];
                _timeRange = self.setupActiveUsersTooltipTimerange(idx, timeTo, _startFrom, timestamps);
                _diffObj = self.calculateActiveUsersChange(idx, values);
              } else {
                _timeRange = self.timestamps[idx];
                var timeRangeISOString = window.moment.utc(_timeRange, 'MMMM DD YYYY, h:mm a').toISOString();
                _timeRange = window.moment.utc(timeRangeISOString).format('ddd, MMMM DD, YYYY');
                _diffObj = self.calculateActiveUsersChange(idx, values);
              }
              return self.createToolTipContainer(_diffObj.percentage, _diffObj.diff, params, requests, _timeRange);
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: self.parseTimestamps(timestamps),
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 0.1
            }
          },
          axisLabel: {
            show: true,
            fontFamily: '\'Source Sans Pro\', sans-serif',
            color: '#454B60'
          }
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
            inside: true,
            lineStyle: {
              color: '#94a4b3'
            }
          },
          axisLabel: {
            show: true,
            showMinLabel: false,
            verticalAlign: 'top',
            inside: true,
            color: '#94a4b3',
            padding: [4, 0, 0, -8],
            fontFamily: '\'Source Sans Pro\', sans-serif',
            fontWeight: 200,
            formatter: function formatter(value) {
              var str = window.Number(value).toString();
              var formattedValue = '';
              if (str.length <= 3) {
                formattedValue = "".concat(value);
              } else if (str.length > 3 && str.length <= 6) {
                formattedValue = "".concat(value / 1e3, "K");
              } else if (str.length > 6 && str.length <= 9) {
                formattedValue = "".concat(value / 1e6, "M");
              } else {
                formattedValue = "".concat(value / 1e9, "B");
              }
              return formattedValue;
            }
          },
          axisLine: {
            lineStyle: {
              width: 0
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dotted',
              color: '#ccc',
              opacity: 0.3
            }
          }
        },
        grid: {
          left: '34px',
          right: '34px',
          top: '10px',
          bottom: '20px'
        },
        series: [{
          data: self.parseValues(values),
          type: 'line',
          markPoint: {
            animation: false,
            silent: true
          },
          symbolSize: 10,
          symbol: 'circle',
          itemStyle: {
            color: areaStyle.borderColor,
            emphasis: {
              borderColor: "#".concat(areaStyle.borderColor.slice(1), "80"),
              // give 50% opacity to color
              borderWidth: 10,
              borderType: 'solid'
            }
          },
          lineStyle: {
            width: 4,
            color: areaStyle.borderColor
          },
          animation: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: "#".concat(areaStyle.borderColor.slice(1), "4D") // give 30% opacity to color
              }, {
                offset: 1,
                color: "#".concat(areaStyle.borderColor.slice(1), "26") // give 15% opacity to color
              }]
            }
          }
        }]
      };

      return option;
    } // ACTIONS -------------------------------------------------------------------
  });
  _exports.default = _default;
});