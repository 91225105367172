define("experience-studio/mixins/prevent-reload-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    resetController: function resetController(controller) {
      controller.setProperties({
        isPreventReload: false,
        preventReloadRoute: null
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('isPreventReload')) {
          transition.abort();
          this.controller.set('preventedTransition', transition);
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this._super.apply(this, arguments);
          return true;
        }
      },
      cancel: function cancel() {
        var self = this;
        this.send('hideModal');
        this.controller.set('isPreventReload', false);
        Ember.run.scheduleOnce('afterRender', this, function () {
          self.controller.get('preventedTransition').retry();
        });
      }
    }
  });
  _exports.default = _default;
});