define("experience-studio/components/edit-experience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['edit-experience'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('contextualVisibilitySelections', [Ember.Object.create({
        name: 'no-contextual-scheduling',
        message: 'Show content as long as the user meets the target audience criteria',
        isContext: false
      }), Ember.Object.create({
        name: 'contextual-scheduling',
        message: 'Show content within a time window before/after an event unique to each audience member',
        isContext: true
      }), Ember.Object.create({
        name: 'original-contextual-scheduling',
        message: 'Use existing audience scheduling',
        isContext: true,
        preserveDefault: true
      })]);
      this.set('selectedVisibilityOption', this.contextualVisibilitySelections[0]);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.isEditMode) {
        // eslint-disable-next-line max-len
        if (this.experience && this.experience.get('computedRule.hasContextual')) {
          this.selectedRules[0].set('contextualStartType', 'plugin');
          this.set('selectedVisibilityOption', this.contextualVisibilitySelections[1]);
          this.set('isContextModifierVisible', true);
        }
      }
    },
    actions: {
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      handleSearch: function handleSearch(properties, modelName) {
        if (modelName === 'rule') {
          this.sendAction('handleSearch', this.ruleProperties, properties); // eslint-disable-line ember/closure-actions
        } else if (modelName === 'content-instance') {
          this.sendAction('handleSearch', properties); // eslint-disable-line ember/closure-actions
        }
      },
      loadModels: function loadModels(modelName) {
        if (modelName === 'rule') {
          this.sendAction('loadModels', this.ruleProperties, modelName); // eslint-disable-line ember/closure-actions
        } else if (modelName === 'content-instance') {
          this.sendAction('loadModels', this.contentProperties); // eslint-disable-line ember/closure-actions
        }
      },
      showRuleSelectModal: function showRuleSelectModal() {
        this.set('isRuleSelectModalOpen', true);
      },
      selectVisibilityOption: function selectVisibilityOption(option) {
        if (this.experience.rule) {
          this.set('selectedVisibilityOption', option);
          this.set('isContextModifierVisible', option.isContext && !option.preserveDefault);
          if (this.selectedRules[0]) {
            this.selectedRules[0].set('contextualStartType', option.isContext ? 'plugin' : 'rule');
            this.selectedRules[0].set('preserveSchedule', !!option.preserveDefault);
          }
        } else {
          this.set('displayRuleSelectionError', true);
        }
      },
      selectRule: function selectRule(rule) {
        this.experience.set('rule', rule);
        this.set('isRuleSelectModalOpen', false);
        if (this.selectedRules.length > 0) {
          this.selectedRules.popObject();
        }
        this.selectedRules.pushObject(rule);
        this.set('displayRuleSelectionError', false);
        if (this.selectedRules[0].hasContextual) {
          this.selectedRules[0].set('contextualStartType', 'plugin');
          this.send('selectVisibilityOption', this.contextualVisibilitySelections[1]);
        } else {
          this.selectedRules[0].set('contextualStartType', 'rule');
          this.send('selectVisibilityOption', this.contextualVisibilitySelections[0]);
        }
      },
      deselectRule: function deselectRule() {
        this.experience.set('rule', null);
        this.set('isContextModifierVisible', false);
        this.set('selectedVisibilityOption', this.contextualVisibilitySelections[0]);
        if (this.selectedRules.length > 0) {
          this.selectedRules.popObject();
        }
      }
    }
  });
  _exports.default = _default;
});