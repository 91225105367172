define("experience-studio/routes/analytics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'analytics.index') {
        this.transitionTo('analytics.users');
      }
    }
  });
  _exports.default = _default;
});