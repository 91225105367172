define("experience-studio/adapters/vidyard", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //https://play.vidyard.com/player/
  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: 'https://play.vidyard.com',
    getVidyardVideoName: function getVidyardVideoName(videoId) {
      var url = "".concat(this.host, "/player/").concat(videoId, ".json");
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          type: 'GET',
          url: url
        }).done(function (data) {
          var _data$payload;
          resolve((data === null || data === void 0 ? void 0 : (_data$payload = data.payload) === null || _data$payload === void 0 ? void 0 : _data$payload.name) || '');
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    }
  });
  _exports.default = _default;
});