define("experience-studio/components/manage-group-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog", "experience-studio/mixins/prioritization"], function (_exports, _baseModalDialog, _prioritization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend(_prioritization.default, {
    classNames: ['manage-group-modal-dialog'],
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    errorMessage: '',
    isCreating: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('isNew', this.model.group.isNew);
      // preload experiences for drop down
      this.set('selectedExperiences', this.model.group.computedExperiences);
      this.selectedExperiences.setEach('dropdownSelected', true);
    },
    filteredExperiences: Ember.computed('model.experiences', function () {
      var filteredExperiences = this.model.experiences.filter(function (e) {
        return e.visibility !== 'system';
      });
      this.model.set('experiences', filteredExperiences);
      return filteredExperiences;
    }),
    clearSelectedExperiences: function clearSelectedExperiences() {
      this.model.experiences.setEach('dropdownSelected', false);
    },
    actions: {
      cancel: function cancel() {
        var model = this.model.group;
        this.clearSelectedExperiences();
        if (!model.isSaving && model.isNew) {
          model.deleteRecord();
        }
        this.set('model', null);
        this.set('isCreating', false);
      },
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      save: function save() {
        var _this = this;
        var model = this.model.group;
        var isNew = this.isNew;
        var message;
        if (isNew) {
          message = "Successfully created \"".concat(model.name, "\" Group");
        } else {
          message = "Changes made to \"".concat(model.name, "\" Group");
        }
        model.set('experienceIds', this.selectedExperiences.mapBy('id'));
        this.set('isCreating', true);
        model.save().then(function () {
          _this.set('isCreating', false);
          _this.savePriorities([model], null, 'GROUP').then(function () {
            _this.sendAction('refresh'); // eslint-disable-line ember/closure-actions
            _this.send('close');
            _this.sendAction('showNotification', {
              // eslint-disable-line ember/closure-actions
              type: 'confirm',
              message: message
            });
          });
        }).catch(function (response) {
          _this.set('isCreating', false);
          _this.set('errorMessage', _this.utility.parseError(response));
        });
      },
      updateSelectedExperiences: function updateSelectedExperiences(experiences) {
        this.set('selectedExperiences', experiences);
      },
      validate: function validate() {
        var name = (this.model.group.name || '').trim();
        var validation = Ember.Object.create({});
        var errorMessage;
        if (Ember.isEmpty(name)) {
          errorMessage = 'Enter name.';
          validation.set('name', errorMessage);
        }
        this.setProperties({
          errorMessage: errorMessage,
          validation: validation
        });
        if (!errorMessage) {
          this.send('save');
        }
      }
    }
  });
  _exports.default = _default;
});