define("experience-studio/components/av5-info-card-tabbed-stat", ["exports", "experience-studio/components/av5-info-card-base", "experience-studio/mixins/analytics-data", "jquery"], function (_exports, _av5InfoCardBase, _analyticsData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Displays single discrete time statistics using av5-info-card-base's logic
  
    Usages:
  
      {{av5-info-card-tabbed-stat
        title=''
        subtitle=''
        requests=[{
          domain='',
          endpoint='',
          from='',
          to='',
        }]
        isDropdown=false
        isPercentagePie=false
        isBreakdownPie=false}}
  
  */
  var _default = _av5InfoCardBase.default.extend(_analyticsData.default, {
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-info-card-tabbed-stat', 'av5-info-card'],
    utility: Ember.inject.service(),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        currentSelectedCard: this.analyticCards.objectAt(0)
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var self = this;
      if (!Ember.isEmpty(this.analyticCards)) {
        this.analyticCards.forEach(function (card) {
          if (card.tooltip) {
            (0, _jquery.default)(self.element).find(".tab-title__".concat(card.key)).tooltip({
              title: card.tooltip,
              template: self.utility.generateStandardTooltipMarkup(_this.classNames.join(' '))
            });
          }
        });
      }
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      selectTab: function selectTab(card) {
        this.set('currentSelectedCard', card);
      },
      refresh: function refresh() {
        window.location.reload(true);
      }
    }
  });
  _exports.default = _default;
});