define("experience-studio/templates/components/content-tmp-edit-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "blz3tXqu",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container clearfix\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[34,2,[\"defaultOption\",\"defaultImage\"]]],[14,0,\"default-image\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"alert alert-info configuration-alert-box\"],[14,\"role\",\"alert\"],[12],[2,\"\\n        \"],[1,[30,[36,4],[\"exclamation-triangle\"],null]],[2,\"\\n        This default image is not configurable.\\n      \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"value\",\"requirements\",\"defaultTransform\"],[[35,2,[\"value\"]],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"defaultTransform\",\"requirements\",\"field\",\"image-field\",\"svg-jar\",\"isImageDisabled\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-image.hbs"
  });
  _exports.default = _default;
});