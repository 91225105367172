define("experience-studio/templates/components/filter-pill-associated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ns6MxJQY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"remove-pill\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"times-circle\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"svg-jar\"]}",
    "moduleName": "experience-studio/templates/components/filter-pill-associated.hbs"
  });
  _exports.default = _default;
});