define("experience-studio/templates/components/date-time-picker-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aFYRm7Nd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[14,0,\"form-control date-field\"],[15,\"placeholder\",[31,[[34,0]]]],[15,\"disabled\",[34,1]],[14,4,\"text\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"isDisabled\"]}",
    "moduleName": "experience-studio/templates/components/date-time-picker-v2.hbs"
  });
  _exports.default = _default;
});