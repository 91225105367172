define("experience-studio/adapters/context-data-upload", ["exports", "jquery", "experience-studio/adapters/application"], function (_exports, _jquery, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    session: Ember.inject.service(),
    namespace: 'context/ctxdataimport/files',
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('/context-data-uploads', '');
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      url = "".concat(url, "?limit=").concat(this.paginationLimit);
      return url.replace('/context-data-uploads', '');
    },
    processFile: function processFile(store, type, snapshot) {
      var _this = this;
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'queryRecord');
      url = "".concat(url, "/import");
      var data = {
        fileId: snapshot.get('fileID')
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'POST',
          data: JSON.stringify(data),
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    }
  });
  _exports.default = _default;
});