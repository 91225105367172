define("experience-studio/components/model-select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-select-dropdown'],
    model: null,
    selectedModels: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.selectedModels = [];
    },
    didRecieveAttrs: function didRecieveAttrs() {
      if (Ember.isEmpty(this.selectedModels)) {
        this.set('selectedModels', []);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.model.setEach('dropdownSelected', false);
    },
    actions: {
      selectModel: function selectModel(item) {
        item.toggleProperty('dropdownSelected');
        if (this.onSelect) {
          this.onSelect(this.model.filterBy('dropdownSelected', true));
        }
      }
    }
  });
  _exports.default = _default;
});