define("experience-studio/serializers/content-instance", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      data.metadata = data.associatedContentInstanceIds;
      data.templateId = data.contentTemplateId;
      if (data.isContextLocked) {
        data.accessType = 'rule';
        delete data.isContextLocked;
      } else if (data.accessType !== 'user') {
        data.accessType = 'default';
      }
      if (data.root && typeof data.root.toData === 'function') {
        data.data = this.checkDetailsData(data.root.toData().root, data.templateType);
        delete data.root;
      }
      delete data.associatedContentInstanceIds;
      delete data.contentTemplateId;
      delete data.values;
      return data;
    },
    checkDetailsData: function checkDetailsData(data, type) {
      if (type === 'concierge-card-link' || type === 'concierge-card-buttons') {
        if (data.link && data.link.actionType === 'LINK') {
          data.details = {};
        }
        if (data.buttons) {
          var buttonList = data.buttons;
          var isDetailOptionSelected = false;
          for (var count = 0; count < buttonList.length; count++) {
            if (buttonList[count].actionType === 'OPEN_DETAIL') {
              isDetailOptionSelected = true;
            }
          }
          if (!isDetailOptionSelected) {
            data.details = {};
          }
        }
      }
      return data;
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      item.attributes.contentTemplateId = item.attributes.templateId;
      item.attributes.contentTemplateType = item.attributes.templateType;
      item.attributes.datax = item.attributes.data;
      item.attributes.isContextLocked = false;
      item.attributes.templateType = item.attributes.templateType || 'default';
      if (item.attributes.accessType === 'rule') {
        item.attributes.isContextLocked = true;
      }
      var meta = item.attributes.surveyMetadata || item.attributes.metadata;
      if (meta) {
        item.attributes.associatedContentInstanceIds = Ember.Object.create(meta);
      }
      if (item.attributes.icon) {
        item.attributes.iconUrl = item.attributes.icon;
      }
      if (item.attributes.index) {
        item.attributes.priorityIndex = item.attributes.index;
      }
      if (Ember.isEmpty(item.attributes.linkedFields)) {
        item.attributes.linkedFields = [];
      }
      if (item.attributes.content) {
        var content = item.attributes.content;
        var values;
        if (Array.isArray(content.data)) {
          if (!Ember.isEmpty(content.data)) {
            values = content.data.objectAt(0);
          }
        } else {
          values = content.data;
        }
        if (values) {
          if (values._id) {
            item.attributes.dataId = values._id;
            delete values._id;
          }
          item.attributes.values = Ember.Object.create({
            root: values
          });
        }
      }
      return item;
    }
  });
  _exports.default = _default;
});