define("experience-studio/components/localized-image", ["exports", "experience-studio/mixins/localized-image-component", "experience-studio/helpers/mapVidyardShareToPlay", "jquery"], function (_exports, _localizedImageComponent, _mapVidyardShareToPlay, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_localizedImageComponent.default, {
    classNames: ['localized-image'],
    emptyMessage: '',
    isValueChangeRender: true,
    isVideoLoadError: false,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('isValueChangeRender', true);
      (0, _jquery.default)('.tooltip-field').tooltip();
    },
    localizedImageValue: Ember.observer('value', function () {
      this.send('imageValue');
      this.set('isValueChangeRender', false);
    }),
    isImageMedia: Ember.computed('selectedMedia', function () {
      return this.selectedMedia === 'image';
    }),
    isVideoMedia: Ember.computed('selectedMedia', function () {
      return this.selectedMedia === 'video';
    }),
    videoSource: Ember.computed('value', function () {
      if ((0, _mapVidyardShareToPlay.default)(this.value)) {
        return ''.concat((0, _mapVidyardShareToPlay.default)(this.value), '.jpg');
      } else {
        return '';
      }
    }),
    videoUuid: Ember.computed('value', function () {
      var shareURLPrefix = 'https://share.vidyard.com/watch/';
      if (!this.value || this.value.indexOf(shareURLPrefix) !== 0) {
        return '';
      }
      return this.value.replace(shareURLPrefix, '');
    }),
    isVideoSourceAndUuid: Ember.computed('videoSource', 'videoUuid', function () {
      return this.videoSource && this.videoUuid;
    }),
    actions: {
      imageValue: function imageValue() {
        if (typeof this.localizedImageData !== 'undefined') {
          this.localizedImageData(this.value);
        }
      },
      onError: function onError() {
        this.set('isVideoLoadError', true);
      },
      onLoad: function onLoad() {
        this.set('isVideoLoadError', false);
      }
    }
  });
  _exports.default = _default;
});