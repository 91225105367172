define("experience-studio/templates/settings/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4MC7Dg6E",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route settings-general-route route-container\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"feature-list\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"setPreferenceValue\",\"model\",\"showNotification\",\"transitionTo\"],[[35,2],[35,1],[30,[36,0],[\"showNotification\"],null],[30,[36,0],[\"transitionTo\"],null]]]]],[2,\"\\n \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"setPreferenceValue\",\"settings-list-view\"]}",
    "moduleName": "experience-studio/templates/settings/general.hbs"
  });
  _exports.default = _default;
});