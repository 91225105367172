define("experience-studio/models/rule", ["exports", "ember-data", "experience-studio/mixins/recently-modified-item", "experience-studio/mixins/time-conversion"], function (_exports, _emberData, _recentlyModifiedItem, _timeConversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _emberData.default.Model.extend(_recentlyModifiedItem.default, _timeConversion.default, {
    session: Ember.inject.service(),
    affectedBy: _emberData.default.attr(),
    affectedByHeadNames: _emberData.default.attr(),
    color: _emberData.default.attr('string'),
    creatorId: _emberData.default.attr('string'),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    isDraft: _emberData.default.attr('boolean'),
    metadata: _emberData.default.attr('string', {
      defaultValue: null
    }),
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    scope: _emberData.default.attr('string', {
      defaultValue: 'tenant'
    }),
    stringRepresentation: _emberData.default.attr('string'),
    hasSuspendedSchedules: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    ignorePastSchedule: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    body: _emberData.default.attr(),
    composition: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    experienceIds: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    head: _emberData.default.attr(),
    pluginData: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    schedule: _emberData.default.attr(),
    labels: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces

    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    visibility: _emberData.default.attr('string'),
    emitEveryEvaluationResult: _emberData.default.attr('boolean'),
    isHighLightItem: Ember.computed.alias('recentlyModified'),
    // set by the server
    variables: _emberData.default.attr(),
    // Message Schedule Type
    isInterval: Ember.computed.and('hasContextualRuleStart', 'hasRepeatOptions'),
    hasLimitedInterval: Ember.computed.and('isInterval', 'isRepeatLimited'),
    hasUnlimitedInterval: Ember.computed.and('isInterval', 'isRepeatUnlimited'),
    hasContextual: Ember.computed.or('hasContextualStart', 'hasContextualEnd'),
    hasContextualRuleSchedule: Ember.computed.or('hasContextualRuleStart'),
    hasAbsoluteSchedule: Ember.computed.or('hasAbsoluteStart', 'hasAbsoluteEnd'),
    hasAbsoluteStart: Ember.computed('schedule', function () {
      return this.get('schedule.startScheduler.start') > 0 ? true : false;
    }),
    hasAbsoluteEnd: Ember.computed('schedule', function () {
      return this.get('schedule.endScheduler.start') > 0 ? true : false;
    }),
    hasContextualRuleStart: Ember.computed('schedule', function () {
      return !Ember.isEmpty(this.get('schedule.startScheduler.contextualRuleStart.stringRepresentation'));
    }),
    isHistory: Ember.computed('stringRepresentation', function () {
      return this.stringRepresentation.indexOf('history') > 0;
    }),
    // Helper Functions
    /**
     * eg) n days before plugin - attribute
     * @param {*} key 'startScheduler' or 'endScheduler'
     * @return {string} contextual schedule information in simple format
     */
    _contextualTimeHelper: function _contextualTimeHelper() {
      var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'startScheduler';
      var contextualStart = this.get("schedule.".concat(key, ".contextualStart"));
      if (Ember.isEmpty(contextualStart)) return;
      var _contextualStart$spli = contextualStart.split(' '),
        _contextualStart$spli2 = _slicedToArray(_contextualStart$spli, 3),
        ctx = _contextualStart$spli2[0],
        operator = _contextualStart$spli2[1],
        _time = _contextualStart$spli2[2];
      var operatorText = operator === '+' ? 'after' : 'before';
      var _ctxArray = ctx.trim().split('.');
      var attributeName = _ctxArray.pop();
      var pluginName = _ctxArray.pop();
      var time = this.convertSecondsToPeriodObject(_time);
      return "".concat(time.value, " ").concat(time.unit, " ").concat(operatorText, " ").concat(attributeName, " - ").concat(pluginName);
    },
    // Computed Properties

    /**
     * checks if a rule has a contextual start, which follows the below format
     * eg) ctx.helloworld-staging.NormalTest.DateField - 7200
     */
    hasContextualStart: Ember.computed('schedule', function () {
      var contextualStart = this.get('schedule.startScheduler.contextualStart');
      if (Ember.isEmpty(contextualStart)) return;
      var _contextualStart$spli3 = contextualStart.split(' '),
        _contextualStart$spli4 = _slicedToArray(_contextualStart$spli3, 3),
        ctx = _contextualStart$spli4[0],
        operator = _contextualStart$spli4[1],
        time = _contextualStart$spli4[2];
      return [ctx, operator, time].every(function (r) {
        return !Ember.isEmpty(r);
      });
    }),
    /**
     * checks if a rule has a contextual end, which follows the below format
     * eg) ctx.helloworld-staging.NormalTest.DateField + 7200
     */
    hasContextualEnd: Ember.computed('schedule', function () {
      var contextualStart = this.get('schedule.endScheduler.contextualStart');
      if (Ember.isEmpty(contextualStart)) return;
      var _contextualStart$spli5 = contextualStart.split(' '),
        _contextualStart$spli6 = _slicedToArray(_contextualStart$spli5, 3),
        ctx = _contextualStart$spli6[0],
        operator = _contextualStart$spli6[1],
        time = _contextualStart$spli6[2];
      return [ctx, operator, time].every(function (r) {
        return !Ember.isEmpty(r);
      });
    }),
    /**
     * returns the detail of the contextual start schedule in sentence format.
     * eg) n days before ctx
     */
    contextualStartText: Ember.computed('schedule.startScheduler', function () {
      return this._contextualTimeHelper('startScheduler');
    }),
    /**
     * returns the detail of the contextual end schedule in sentence format.
     * eg) n days after ctx
     */
    contextualEndText: Ember.computed('schedule.endScheduler', function () {
      return this._contextualTimeHelper('endScheduler');
    }),
    /**
     * returns simple contextual info
     * eg DateField (ctx.helloworld-staging.NormalTest.DateField)
     */
    contextualText: Ember.computed('schedule.endScheduler', 'hasContextual', function () {
      if (!this.hasContextual) return;
      var ctx = this.get("schedule.endScheduler.contextualStart").split(' ')[0];
      var attributeName = ctx.trim().split('.').pop();
      return "".concat(attributeName, " (").concat(ctx, ")");
    }),
    /**
     * @return {string} n days before/after ctx
     */
    contextualInfoText: Ember.computed('hasContextual', function () {
      if (!this.hasContextual) return;
      return this.hasContextualStart ? this.contextualStartText : this.contextualEndText;
    }),
    startScheduleRepeat: Ember.computed('schedule', function () {
      return this.get('schedule.startScheduler.repeat');
    }),
    hasRepeatOptions: Ember.computed('schedule', function () {
      return !Ember.isEmpty(this.get('schedule.startScheduler.repeat.frequencyType'));
    }),
    isRepeatLimited: Ember.computed('schedule.startScheduler.repeat.frequency', function () {
      return this.get('schedule.startScheduler.repeat.limit') > 0;
    }),
    isRepeatUnlimited: Ember.computed('schedule.startScheduler.repeat.frequency', function () {
      return this.get('schedule.startScheduler.repeat.limit') === -1;
    }),
    isUserOwnRule: Ember.computed(function () {
      var userID = '';
      try {
        userID = this.session.userID;
      } catch (exception) {} // eslint-disable-line no-empty
      return !Ember.isEqual(userID, this.creatorId) && Ember.isEqual(this.scope, 'user') ? false : true;
    }),
    removeSchedule: function removeSchedule() {
      this.set('schedule', null);
    },
    removeContextual: function removeContextual() {
      var _this = this;
      var types = ['startScheduler', 'endScheduler'];
      types.forEach(function (type) {
        var scheduler = _this.get("schedule.".concat(type));
        if (scheduler) {
          scheduler.set('contextualStart', '');
        }
      });
    },
    removeContextualRule: function removeContextualRule() {
      var _this2 = this;
      var types = ['startScheduler', 'endScheduler'];
      types.forEach(function (type) {
        var scheduler = _this2.get("schedule.".concat(type));
        if (scheduler) {
          scheduler.set('contextualRuleStart.modifier', '');
          scheduler.set('contextualRuleStart.stringRepresentation', '');
          scheduler.set('contextualRuleStart.variables', []);
        }
      });
    },
    setDefaultRepeat: function setDefaultRepeat() {
      var _this3 = this;
      var types = ['startScheduler', 'endScheduler'];
      types.forEach(function (type) {
        var scheduler = _this3.get("schedule.".concat(type));
        if (scheduler) {
          scheduler.get('repeat').setProperties({
            limit: 1,
            frequency: 1,
            frequencyType: 'once'
          });
        }
      });
    },
    removeRepeat: function removeRepeat() {
      this.get('schedule.startScheduler.repeat').setProperties({
        limit: 0,
        frequency: 0,
        frequencyType: ''
      });
    },
    // Analytics

    // eslint-disable-next-line max-len
    computedPotentialAudienceReachPercentage: Ember.computed('computedPotentialAudienceReachUsers', 'computedSimulationTotalUsers', function () {
      var percentage = 0;
      var simulation = this.computedSimulation;
      var totalUsers = this.computedSimulationTotalUsers;
      if (simulation && totalUsers > 0) {
        percentage = this.computedPotentialAudienceReachUsers / totalUsers;
        percentage = (percentage * 100).toFixed(2);
      }
      return percentage;
    }),
    // eslint-disable-next-line max-len
    computedPotentialAudienceReachUsers: Ember.computed('computedSimulation.errorCount', 'computedSimulationTotalUsers', function () {
      var simulation = this.computedSimulation;
      var total = 0;
      if (simulation) {
        total = this.computedSimulationTotalUsers - simulation.get('errorCount');
      }
      return total;
    }),
    computedSimulation: Ember.computed('computedSimulations.[]', function () {
      var self = this;
      var simulation;
      var simulations = this.computedSimulations;
      if (!Ember.isEmpty(simulations)) {
        var filteredRecords = simulations.filterBy('ruleId', self.get('id'));
        if (!Ember.isEmpty(filteredRecords)) {
          filteredRecords = filteredRecords.sortBy('modifiedAt');
          simulation = filteredRecords.get('lastObject');
        }
      }
      return simulation;
    }),
    computedSimulations: Ember.computed('isSaving', 'isReloading', function () {
      return this.store.findAll('rule-simulation');
    }),
    computedSimulationTotalUsers: Ember.computed('computedSimulation.{falseCount,trueCount}', function () {
      var simulation = this.computedSimulation;
      var total = 0;
      if (simulation) {
        total = simulation.get('falseCount') + simulation.get('trueCount');
      }
      return total;
    }),
    computedTotalAudienceReachPercentage: Ember.computed('computedSimulation.{errorCount,trueCount}', function () {
      var percentage = 0;
      var simulation = this.computedSimulation;
      var totalUsers = this.computedSimulationTotalUsers;
      if (simulation && totalUsers > 0) {
        percentage = simulation.get('trueCount') / totalUsers;
        percentage = (percentage * 100).toFixed(2);
      }
      return percentage;
    }),
    // This can be on persisted rules as variables is set from the back-end
    // returns the label used for precise location plugin

    // The variables array contains rule plugins in the following format
    // variables: [
    //   "ctx.flybits.area.query.inArea.3FC08E2B-84CF-42E3-97C9-F3D3E73903FB",
    //   "ctx.flybits.area.query.inAreaWithLabel.eventType.Label2",
    //   "ctx.flybits.area.query.inAreaWithLabel.eventType.Label1"
    // ]

    // The computed property below will return ['Label2', 'Label1']
    locationLabels: Ember.computed('variables', function () {
      return this.variables.filter(function (variable) {
        return variable.indexOf('.inAreaWithLabel.eventType.') > -1;
      }).map(function (stringRep) {
        return stringRep.split('.').pop();
      });
    })
  });
  _exports.default = _default;
});