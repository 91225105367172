define("experience-studio/components/power-select-custom-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['power-select-custom-search'],
    actions: {
      didUpdateSearchKeyword: function didUpdateSearchKeyword(keyword) {
        this.get('select.actions.search')(keyword);
      }
    }
  });
  _exports.default = _default;
});