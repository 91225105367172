define("experience-studio/templates/components/model-list-sort-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZSmDzWe1",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"button\"],[14,0,\"btn btn-sm btn-light dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,4,\"button\"],[12],[2,\"\\n  \"],[1,[35,1,[\"label\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"button\"],[16,0,[31,[\"btn btn-sm \",[30,[36,2],[[35,1,[\"isAscending\"]],\"btn-light\",\"btn-primary\"],null]]]],[24,4,\"button\"],[4,[38,0],[[32,0],\"toggleOrder\",[35,1],\"desc\"],null],[12],[2,\"\\n  \"],[1,[35,1,[\"order\",\"desc\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"button\"],[16,0,[31,[\"btn btn-sm \",[30,[36,2],[[35,1,[\"isAscending\"]],\"btn-primary\",\"btn-light\"],null]]]],[24,4,\"button\"],[4,[38,0],[[32,0],\"toggleOrder\",[35,1],\"asc\"],null],[12],[2,\"\\n  \"],[1,[35,1,[\"order\",\"asc\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,0,\"dropdown-item\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"sort\",[32,1]],null],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"current\",\"if\",\"sortProperties\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/model-list-sort-dropdown.hbs"
  });
  _exports.default = _default;
});