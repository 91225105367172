define("experience-studio/templates/permissions-hub/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lUjOB36C",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"permissions-hub-roles-route route-container\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"model\",\"sortProperties\",\"updateSortedModel\"],[[35,3,[\"roles\"]],[35,2],\"updateSortedModel\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"add-model-bar\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-secondary gtm-route-new-button\"],[24,\"data-gtm-text\",\"Role\"],[24,4,\"button\"],[4,[38,5],[[32,0],\"createRecord\"],null],[12],[2,\"+ New Role\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,8],null,[[\"create\",\"dispatchAction\",\"model\",\"searchKeyword\",\"showActions\"],[\"createRecord\",\"handleAction\",[35,7],[35,6],true]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"fetchEntities\",\"model\",\"refresh\",\"showNotification\"],[\"fetchEntities\",[35,0],\"refresh\",\"showNotification\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"manageRoleModalData\",\"manage-role-modal-dialog\",\"listSortProperties\",\"model\",\"model-list-sort-dropdown\",\"action\",\"searchKeyword\",\"filteredModel\",\"model-list-permission-role\",\"if\"]}",
    "moduleName": "experience-studio/templates/permissions-hub/roles.hbs"
  });
  _exports.default = _default;
});