define("experience-studio/components/model-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['table', 'model-table'],
    tagName: 'table',
    actions: {
      dispatchAction: function dispatchAction(name, model, params) {
        this.sendAction('dispatchAction', name, model, params); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});