define("experience-studio/controllers/context-hub/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    data: '',
    queryParams: [{
      data: 'data'
    }],
    filteredModel: Ember.computed('isRefreshFilterModel', 'data', function () {
      return this.store.query('csvfile', {
        data: this.data
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    }),
    filterState: Object.freeze([Ember.Object.create({
      title: '',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-checkbox-select',
        options: [Ember.Object.create({
          isSelected: false,
          value: 'context-data',
          label: 'Context Data',
          property: 'fileType'
        }), Ember.Object.create({
          isSelected: false,
          value: 'location',
          label: 'Location Data',
          property: 'fileType'
        })],
        incompatibleProperties: [],
        disabledOnProperties: []
      })]
    })]),
    filterParams: Object.freeze([]),
    paramTitleMap: Object.freeze([Ember.Object.create({
      id: 'fileType',
      getDisplayValue: function getDisplayValue(value) {
        if (value === 'context-data') {
          value = 'Context Data';
        } else {
          value = 'Location';
        }
        return "File Type : ".concat(value);
      }
    })])
  });
  _exports.default = _default;
});