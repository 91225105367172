define("experience-studio/models/plugin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    category: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    hasDateTimeAttribute: _emberData.default.attr('boolean'),
    iconUrl: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    isReserved: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    provider: _emberData.default.attr('string'),
    uid: _emberData.default.attr('string'),
    userScope: _emberData.default.attr('string'),
    attributes: _emberData.default.attr(),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    attributesArray: Ember.computed('attributes', function () {
      var attributes = this.attributes;
      var result = [];
      if (attributes) {
        window.Object.keys(attributes).forEach(function (key) {
          result.pushObject(attributes.get(key));
        });
      }
      return result;
    })
  });
  _exports.default = _default;
});