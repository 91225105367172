define("experience-studio/components/analytics-card-context-rule-performance-over-time", ["exports", "experience-studio/components/analytics-card"], function (_exports, _analyticsCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _analyticsCard.default.extend({
    classNames: ['analytics-card-context-rule-performance-over-time'],
    model: null,
    primaryLineChartData: Ember.computed(function () {
      var data = {
        datasets: [{
          borderColor: '#0076A5',
          borderWidth: 2,
          data: [],
          fill: false,
          label: 'Not Satisfied'
        }],
        labels: []
      };
      var audienceReachData = this.model.audienceReach;
      for (var i = 0; i < audienceReachData.length; i++) {
        var item = audienceReachData.objectAt(i);
        var timestampMoment = window.moment.unix(item.timestamp);
        data.labels.pushObject(timestampMoment);
        data.datasets[0].data.pushObject(item.value);
      }
      return data;
    }),
    primaryLineChartOptions: Ember.computed(function () {
      var scaleLabel = {
        display: false
      };
      var options = {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            type: 'time'
          }],
          yAxes: [{
            scaleLabel: scaleLabel,
            ticks: {
              suggestedMax: 10,
              suggestedMin: 0
            }
          }]
        },
        tooltips: {
          custom: function custom(tooltipModel) {
            var title = tooltipModel.title;
            if (title && title.length) {
              tooltipModel.title[0] = tooltipModel.title[0].format('llll');
            }
            return tooltipModel;
          }
        }
      };
      return options;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.model = Ember.Object.create({
        audienceReach: [{
          value: 100,
          timestamp: 1503256896
        }, {
          value: 50,
          timestamp: 1503343296
        }, {
          value: 10,
          timestamp: 1503429696
        }, {
          value: 50,
          timestamp: 1503516096
        }, {
          value: 18,
          timestamp: 1503602496
        }, {
          value: 80,
          timestamp: 1503688896
        }, {
          value: 5,
          timestamp: 1503775296
        }, {
          value: 25,
          timestamp: 1503861696
        }]
      });
    }
  });
  _exports.default = _default;
});