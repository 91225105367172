define("experience-studio/components/content-form-editor", ["exports", "jquery", "experience-studio/mixins/question-factory"], function (_exports, _jquery, _questionFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-form-editor
      draggableItems=draggableItems
      droppedItems=droppedItems
      isDragging=isDragging
      isEditMode=isEditMode
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-form-editor'],
    draggableItems: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    droppedItems: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    isDragging: false,
    isEditMode: true,
    isLoading: true,
    utility: Ember.inject.service(),
    languages: null,
    observeLanguages: Ember.observer('languages.[]', function () {
      var languages = this.languages;
      if (!Ember.isEmpty(languages) && !languages.findBy('isSelected', true)) {
        var language = languages.findBy('isDefault', true);
        if (!language) {
          language = languages.get('firstObject');
        }
        if (language) {
          language.set('isSelected', true);
        }
      }
    }),
    selectedQuestion: null,
    actions: {
      addDroppedItem: function addDroppedItem(uiType) {
        var question = _questionFactory.default.getQuestion(uiType);
        if (question) {
          if (question.type.serverType === 'multiple' || question.type.serverType === 'single') {
            question.addChoice();
          }
          this.set('selectedQuestion', question);
          this.droppedItems.pushObject(question);
        }
      },
      deleteDropped: function deleteDropped(question) {
        this.droppedItems.removeObject(question);
      },
      duplicate: function duplicate(question) {
        var originalIndex = this.droppedItems.indexOf(question);
        var copy = question.duplicate();
        this.droppedItems.insertAt(originalIndex, copy);
      },
      selectQuestion: function selectQuestion(question) {
        this.set('selectedQuestion', question);
      }
    },
    didInsertElement: function didInsertElement() {
      var scrollable = this.$();
      var qTypes = this.$('.draggable-items');
      var reCheckCon = function reCheckCon() {
        window.requestAnimationFrame(function () {
          var scrolled = scrollable.scrollTop();
          var scrollableHeight = scrollable[0].getBoundingClientRect().height;
          if (scrolled >= 50) {
            qTypes.addClass('affix');
            qTypes.height(scrollableHeight);
            qTypes.css('top', scrolled - 50);
          } else {
            qTypes.removeClass('affix');
            qTypes.height('auto');
            qTypes.css('top', 0);
          }
        });
      };
      scrollable.on('scroll.qtypes', function () {
        reCheckCon();
      });
      (0, _jquery.default)(window).on('resize.qtypes', function () {
        reCheckCon();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('.qtypes');
      (0, _jquery.default)(window).off('.qtypes');
    }
  });
  _exports.default = _default;
});