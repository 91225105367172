define("experience-studio/mixins/scroll-focus", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    focusTo: function focusTo(stepIndex) {
      var stepBlockContainerClassName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.push-messages-new-form';
      var errorInputFieldClassName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.is-invalid';
      Ember.run.scheduleOnce('afterRender', this, function () {
        var stepBlock = (0, _jquery.default)("".concat(stepBlockContainerClassName)).children().eq(stepIndex);
        // const fieldFinded = stepBlock.find('.form-control');
        var errorInputFinded = stepBlock.find("".concat(errorInputFieldClassName));
        if (errorInputFinded.length > 0) {
          errorInputFinded[0].focus();
        }
      });
    },
    scrollTo: function scrollTo(stepIndex) {
      var stepBlockContainerClassName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.push-messages-new-form';
      var errorInputFieldClassName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.is-invalid';
      var scrollingElement = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '.page-main';
      Ember.run.scheduleOnce('afterRender', this, function () {
        var stepBlock = (0, _jquery.default)("".concat(stepBlockContainerClassName)).children().eq(stepIndex);
        var pixelOffset = stepBlock.position().top;
        var errorInputFinded = stepBlock.find("".concat(errorInputFieldClassName));
        var stepBlockPositionTop = stepBlock.position().top;
        if (errorInputFinded.length > 0) {
          pixelOffset = errorInputFinded.position().top + stepBlockPositionTop;
        }
        (0, _jquery.default)("".concat(scrollingElement)).animate({
          scrollTop: pixelOffset
        }, 500);
      });
    }
  });
  _exports.default = _default;
});