define("experience-studio/adapters/content-instance", ["exports", "experience-studio/adapters/application", "jquery"], function (_exports, _application, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'kernel/content',
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('content-instances', 'instances');
    },
    query: function query(store, type, _query) {
      _query.bypassAdmin = true;
      _query.managementMode = true;
      _query.disableContextEmbed = true;
      if (_query.type) {
        _query.templateType = _query.type;
        delete _query.type;
      }
      return this._super(store, type, _query);
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      var url = this._super.apply(this, arguments);
      url = "".concat(url, "/").concat(query.id, "?");
      delete query.id;
      query.bypassAdmin = true;
      query.managementMode = true;
      query.disableContextEmbed = true;
      url += _jquery.default.param(query);
      return url;
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url.replace('content-instances', "instances?bypassAdmin=true&managementMode=true&disableContextEmbed=true&limit=".concat(this.paginationLimit));
    },
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this._super.apply(this, arguments), "?bypassAdmin=true&managementMode=true&disableContextEmbed=true&data=true");
    }
  });
  _exports.default = _default;
});