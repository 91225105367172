define("experience-studio/utils/nearby-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getContentCriteria = getContentCriteria;
  _exports.isLabelLocation = isLabelLocation;
  // Link to document for a more detailed explanation of nearby-locations
  // https://github.com/flybits/info/blob/nearbyLocations/engineering/templated-experiences/nearby-locations/workflow-es-1.0.md

  // Explanation of regex
  // ====================
  // Support Nearby Location with either precise location (inArea) or with labels (withLabel)
  // An example of how the new stringRepresentation for location with labels look like.
  // new) stringFoldEq(ctx.flybits.area.query.inAreaWithLabel.eventType.atm,'entry')
  // new) stringFoldEq(ctx.flybits.area.query.inAreaWithLabel.eventType.branch,'entry')
  // Instead of the old version
  // old) boolEq(ctx.flybits.area.query.inLocationWithLabel.atm,true)
  // old) boolEq(ctx.flybits.area.query.inLocationWithLabel.branch,true)

  var regex = {
    nearbyLocation: /(\.inArea\.)|(\.inAreaWithLabel\.eventType\.)/,
    inArea: /\.inArea\./,
    inAreaClause: /\.inArea\.(\w|-)+/,
    oldLabel: /\.inLocationWithLabel\./,
    // this label exists for backward compatibility, but is not used for nearby location content.
    withLabel: /\.inAreaWithLabel\./,
    withLabelClause: /\.inAreaWithLabel\.eventType\.(\w)+/,
    or: /Or/,
    and: /And/
  };

  // parse and return nearby location related rule strings
  function getLocationCriteria(stringRep) {
    var isInArea = regex.inArea.test(stringRep);
    var isOr = regex.or.test(stringRep);
    var arr = stringRep.split(isOr ? 'Or' : 'And');
    var clauseRegex = isInArea ? regex.inAreaClause : regex.withLabelClause;
    var criteriaArr = arr.reduce(function (acc, curr) {
      if (clauseRegex.test(curr)) {
        // returns the tail end of stringRep
        // eg) ctx....query.eventType.atm would just return 'atm'
        var _stringRepArr = curr.match(clauseRegex)[0].split('.');
        var label = _stringRepArr[_stringRepArr.length - 1]; // get the last element
        acc.push(label);
      }
      return acc;
    }, []);
    var locationCriteria = {
      zoneIds: [],
      labelsFormula: {}
    };
    if (isInArea) {
      locationCriteria.zoneIds = criteriaArr;
    } else {
      locationCriteria.labelsFormula[isOr ? "or" : "and"] = criteriaArr;
    }
    return locationCriteria;
  }
  function getContentCriteria(rule) {
    var stringRep = rule.get('stringRepresentation');
    if (!isNearbyLocation(stringRep)) return null;
    return {
      locationCriteria: getLocationCriteria(stringRep)
    };
  }

  // Previous version of rules with labels uses the plugin "inLocationWithLabel" as shown below.
  // old) boolEq(ctx.flybits.area.query.inLocationWithLabel.label2,true)
  // new) stringFoldEq(ctx.flybits.area.query.inAreaWithLabel.eventType.atm,'entry')
  // The new rule engine requires "inAreaWithLabel" instead of
  //  "inLocationWithLabel" for location content to work.
  function isNearbyLocation(stringRep) {
    return regex.nearbyLocation.test(stringRep);
  }

  // This is function that is used by UI.
  // It just needs to know whether uid is of type label
  // location to display the label select component.
  // This covers both the old label stringRep + the new label strignRep for locations
  // This function should only be to check if the stringRep is of type label
  function isLabelLocation(uid) {
    return regex.withLabel.test(uid) || regex.oldLabel.test(uid);
  }
});