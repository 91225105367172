define("experience-studio/components/av5-push-engagement-card-item", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['av5-push-engagement-card-item'],
    model: null,
    utility: Ember.inject.service(),
    svgUp: '',
    svgDown: '',
    init: function init() {
      this._super.apply(this, arguments);
      var svgUp = "<svg class=\"carrot-down\" style=\"fill:#50E3C2; width: 16px; height: 16px; transform: rotate(180deg);\" viewBox=\"0 0 24 24\">\n      <path d=\"M3.8,7.65H20.2a1,1,0,0,1,.63,1.77l-8.2,6.71a1,1,0,0,1-1.26,0L3.17,9.42A1,1,0,0,1,3.8,7.65Z\"/>\n    </svg>";
      var svgDown = "<svg class=\"carrot-down\" style=\"fill:#D63031; width: 16px; height: 16px;\" viewBox=\"0 0 24 24\">\n      <path d=\"M3.8,7.65H20.2a1,1,0,0,1,.63,1.77l-8.2,6.71a1,1,0,0,1-1.26,0L3.17,9.42A1,1,0,0,1,3.8,7.65Z\"/>\n    </svg>";
      this.set('svgUp', svgUp);
      this.set('svgDown', svgDown);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('.title').each(function (index, el) {
        (0, _jquery.default)(el).tooltip({
          trigger: 'hover',
          placement: 'auto',
          title: _this.model.tooltip || '',
          template: _this.utility.generateStandardTooltipMarkup(_this.classNames.join(' '))
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('.title').each(function (index, el) {
        (0, _jquery.default)(el).tooltip('dispose');
      });
    },
    rate: Ember.computed('model.rate', function () {
      return "".concat(this.model.rate.toFixed(2), "%");
    }),
    changeIcon: Ember.computed('model.rate', function () {
      if (this.model.rate > 0) {
        return this.svgUp;
      } else if (this.model.rate < 0) {
        return this.svgDown;
      } else {
        return 0;
      }
    })
  });
  _exports.default = _default;
});