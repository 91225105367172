define("experience-studio/templates/content-hub-v2/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jyyM+mpi",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[2,\"\\n\"],[10,\"div\"],[14,0,\"route-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"view-changers\"],[12],[2,\"  \\n    \"],[11,\"button\"],[24,0,\"btn view-changer\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"changeToCardView\",\"content-hub\"],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"toggle-grid-view\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"data\",\"didUpdateQueryParams\",\"filterState\",\"filterParams\",\"filteredModel\",\"fetchModel\",\"toggleFilterSections\"],[[35,6],[30,[36,2],[\"updateQueryParams\"],null],[35,5],[35,4],[35,3],[30,[36,2],[\"performFetchModel\"],null],[30,[36,2],[\"toggleFilterSections\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"add-model-bar\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-secondary gtm-route-new-button\"],[24,\"data-gtm-text\",\"Content\"],[24,\"aria-label\",\"Create New Content\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"createEntity\"],null],[12],[2,\"+ New Content\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,10],null,[[\"createEntity\",\"model\",\"isLoading\",\"data\",\"isMultiSelect\",\"selectedEntities\",\"onToggleRow\",\"performModelAction\"],[[30,[36,2],[\"createEntity\"],null],[35,3],[35,9],[35,6],true,[35,8],[30,[36,2],[\"updateQueryParams\"],null],[30,[36,2],[\"performModelAction\"],null]]]]],[2,\"\\n  \"],[1,[30,[36,13],null,[[\"selectedEntities\",\"pluralEntityName\",\"batchActions\",\"onClose\",\"isInfoSidebarOpen\",\"performModelBatchAction\"],[[35,8],\"Content\",[35,12],[30,[36,2],[\"confirmCloseBarModal\"],null],[35,11],[30,[36,2],[\"performModelBatchAction\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"svg-jar\",\"route-action\",\"filteredModel\",\"filterParams\",\"filterState\",\"data\",\"route-model-filter\",\"selectedEntities\",\"isLoading\",\"route-model-table-content-instance\",\"isInfoSidebarOpen\",\"batchActions\",\"batch-actions-bar\"]}",
    "moduleName": "experience-studio/templates/content-hub-v2/index.hbs"
  });
  _exports.default = _default;
});