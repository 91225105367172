define("experience-studio/mirage/factories/push-engagement", ["exports", "ember-cli-mirage", "@faker-js/faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    totalSent: function totalSent() {
      return _faker.faker.datatype.number(5000000);
    },
    uniqueSent: function uniqueSent() {
      return _faker.faker.datatype.number(this.totalSent);
    },
    totalOpen: function totalOpen() {
      return _faker.faker.datatype.number(5000000);
    },
    uniqueOpen: function uniqueOpen() {
      return _faker.faker.datatype.number(this.totalOpen);
    },
    uniqueOpenRate: function uniqueOpenRate() {
      return this.uniqueOpen / this.totalSent;
    },
    pushId: function pushId() {
      return _faker.faker.datatype.uuid();
    }
  });
  _exports.default = _default;
});