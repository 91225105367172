define("experience-studio/mixins/route-model-batch-actions-push-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modalData = Ember.Object.extend({
    singularEntityName: 'Push Notification',
    pluralEntityName: 'Push Notifications'
  });
  var _default = Ember.Mixin.create({
    actions: {
      showActivateRecordsModal: function showActivateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-activate-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.communicationsHubController.selectedEntities,
          isActive: true
        }));
      },
      showSendRecordsModal: function showSendRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-send-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.communicationsHubController.selectedEntities
        }));
      },
      showDeactivateRecordsModal: function showDeactivateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-activate-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.communicationsHubController.selectedEntities,
          isActive: false
        }));
      },
      showDeleteRecordsModal: function showDeleteRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-delete-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.communicationsHubController.selectedEntities,
          message: "This is permanent, and cannot be undone."
        }));
      },
      activateRecords: function activateRecords(params) {
        var _this = this;
        var isActive = params.isActive;
        var records = params.records.filter(function (record) {
          return !record.isBroadcast && record.isActive === !isActive;
        });
        var requests = this.createBatchRequest(records, null, function (record) {
          record.set('isActive', isActive);
          return record.save();
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            records: records,
            confirmModalTitle: params.confirmModalTitle,
            retryAction: isActive ? 'showActivateRecordsModal' : 'showDeactivateRecordsModal',
            finishAction: 'finalizeRecordsAction',
            failedPostfix: 'send.',
            sucessPostfix: 'sent!',
            total: records.length
          }));
        });
      },
      sendRecords: function sendRecords(params) {
        var _this2 = this;
        var records = params.records.filterBy('isBroadcast', true);
        var requests = this.createBatchRequest(records, null, function (record) {
          record.set('isSent', true);
          return _this2.broadcastRecord(record);
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this2.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            records: records,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            confirmModalTitle: params.confirmModalTitle,
            retryAction: 'showSendRecordsModal',
            finishAction: 'finalizeRecordsAction',
            failedPostfix: 'send.',
            sucessPostfix: 'sent!',
            total: records.length
          }));
        });
      }
    }
  });
  _exports.default = _default;
});