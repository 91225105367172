define("experience-studio/templates/components/content-template-editor-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9ZCWLsOw",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,11],[[35,12]],[[\"contentInstance\",\"isPreviewEmpty\",\"isImageVisible\",\"selectedMedia\",\"selectedLanguageCode\",\"isVideoTooltipVisible\",\"setPreviewImageVisibility\",\"languages\",\"plugins\"],[[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"],[6,[37,14],[[35,13]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,11],[[35,10]],[[\"contentInstance\",\"isPreviewEmpty\",\"isImageVisible\",\"selectedMedia\",\"selectedLanguageCode\",\"isVideoTooltipVisible\",\"buttonCount\",\"setPreviewImageVisibility\",\"languages\",\"plugins\"],[[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"plugins\",\"languages\",\"setPreviewImageVisibility\",\"buttonCount\",\"isVideoTooltipVisible\",\"selectedLanguageCode\",\"selectedMedia\",\"isImageVisible\",\"isPreviewEmpty\",\"previewData\",\"contentTemplateDetailView\",\"component\",\"contentTemplate\",\"isDetailView\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/content-template-editor-preview.hbs"
  });
  _exports.default = _default;
});