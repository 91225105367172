define("experience-studio/components/route-model-table-dropdown-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      sendPerformModelAction: function sendPerformModelAction(actionName) {
        this.performModelAction({
          action: actionName,
          record: this.model
        });
      }
    }
  });
  _exports.default = _default;
});