define("experience-studio/components/communications-hub-email-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['communications-hub-table', 'communications-hub-email-table'],
    keyword: '',
    model: null,
    modelSham: Ember.computed('keyword', 'model.[]', function () {
      var keyword = this.keyword;
      var model = [];
      if (!Ember.isEmpty(this.model)) {
        model = this.model.toArray().sort(function (a, b) {
          var ma = window.moment(a.get('createdAt'));
          var mb = window.moment(b.get('createdAt'));
          if (ma.isBefore(mb)) {
            return 1;
          } else if (ma.isAfter(mb)) {
            return -1;
          } else {
            return 0;
          }
        });
      }
      if (keyword) {
        model = model.filter(function (item) {
          return item.get('name').toLowerCase().indexOf(keyword) >= 0;
        });
      }
      return model;
    }),
    actions: {
      activate: function activate(item) {
        this.send('dispatchAction', 'activation', item);
      },
      delete: function _delete(item) {
        this.send('dispatchAction', 'delete', item);
      },
      didClick: function didClick(model) {
        this.sendAction('didClick', model); // eslint-disable-line ember/closure-actions
      },
      dispatchAction: function dispatchAction() {
        this.sendAction.apply(this, ['dispatchAction'].concat(Array.prototype.slice.call(arguments))); // eslint-disable-line ember/closure-actions
      },
      reload: function reload(item) {
        this.send('dispatchAction', 'reload', item);
      }
    }
  });
  _exports.default = _default;
});