define("experience-studio/templates/components/priority-indicator-content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WkYHtVEt",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"priority\"],[[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"priority\",\"priority-indicator\"]}",
    "moduleName": "experience-studio/templates/components/priority-indicator-content-instance.hbs"
  });
  _exports.default = _default;
});