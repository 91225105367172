define("experience-studio/mixins/route-model-batch-actions-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modalData = Ember.Object.extend({
    singularEntityName: 'Content',
    pluralEntityName: 'Content'
  });
  var _default = Ember.Mixin.create({
    actions: {
      showAddLabelRecordsModal: function showAddLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAdding: true
        }));
      },
      showRemoveLabelRecordsModal: function showRemoveLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAdding: false
        }));
      },
      showAssociateRecordsModal: function showAssociateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-associate-records-modal-dialog',
          experiences: this.store.findAll('Experience'),
          associateVar: 'contentIds',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAssociating: true
        }));
      },
      showDisassociateRecordsModal: function showDisassociateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-associate-records-modal-dialog',
          experiences: this.store.findAll('Experience'),
          associateVar: 'contentIds',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAssociating: false
        }));
      },
      showDeleteRecordsModal: function showDeleteRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-delete-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          message: "The selected Content will be permanently deleted from the\n        Content Hub and any associated Experiences and/or Push Notifications.\n        This action cannot be undone. Do you still wish to continue?"
        }));
      }
    }
  });
  _exports.default = _default;
});