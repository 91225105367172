define("experience-studio/components/context-hub-attribute-v2-dropdown", ["exports", "experience-studio/utils/nearby-location"], function (_exports, _nearbyLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['context-hub-attribute-v2-dropdown', 'form'],
    attributes: Ember.computed('', function () {
      var attributes = [];
      var attributesObj = this.get('composition.originalModel.attributes');
      for (var property in attributesObj) {
        if (attributesObj.hasOwnProperty(property)) {
          var attribute = attributesObj[property];
          this.setupAttribute(attribute);
          attributes.pushObject(attribute);
        }
      }
      return attributes;
    }),
    getPredicates: function getPredicates(attribute) {
      var predicates = [];
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          break;
        case '[]string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Object.create({
              label: 'is',
              value: 'stringArrayIncludes'
            }));
          } else {
            predicates.pushObject(Object.create({
              label: 'is',
              value: 'stringArrayIncludes'
            }));
          }
          break;
        case 'bool':
          predicates.pushObject(Object.create({
            label: 'Yes',
            value: 'boolEq'
          }));
          predicates.pushObject(Object.create({
            label: 'No',
            value: 'boolNeq'
          }));
          break;
        case 'float':
        case 'int':
          predicates.pushObject(Object.create({
            label: 'is',
            value: 'eq'
          }));
          predicates.pushObject(Object.create({
            label: 'is not',
            value: 'neq'
          }));
          predicates.pushObject(Object.create({
            label: 'is less than',
            value: 'ls'
          }));
          predicates.pushObject(Object.create({
            label: 'is less than or equal to',
            value: 'lseq'
          }));
          predicates.pushObject(Object.create({
            label: 'is greater than',
            value: 'gr'
          }));
          predicates.pushObject(Object.create({
            label: 'is greater than or equal to',
            value: 'greq'
          }));
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          break;
        case 'string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Object.create({
              label: 'is',
              value: 'stringEq'
            }));
            predicates.pushObject(Object.create({
              label: 'is not',
              value: 'stringNeq'
            }));
            predicates.pushObject(Object.create({
              label: 'contains',
              value: 'stringContains'
            }));
          } else {
            predicates.pushObject(Object.create({
              label: 'is',
              value: 'stringFoldEq'
            }));
            predicates.pushObject(Object.create({
              label: 'is not',
              value: 'stringFoldNeq'
            }));
            predicates.pushObject(Object.create({
              label: 'contains',
              value: 'stringFoldContains'
            }));
          }
          break;
      }
      return predicates;
    },
    setupAttribute: function setupAttribute(attribute) {
      attribute.setProperties({
        comparisonPredicates: this.getPredicates(attribute),
        isCaseSensitiveVisible: false,
        isSupported: false
      });
      if (attribute.uid.indexOf('inArea') >= 0) {
        attribute.set('isInArea', true);
      } else if ((0, _nearbyLocation.isLabelLocation)(attribute.uid)) {
        attribute.set('IsInLocationWithLabel', true);
      }
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          attribute.setProperties({
            isArrayType: true,
            isSupported: false
          });
          break;
        case '[]string':
          attribute.setProperties({
            isArrayType: true,
            isCaseSensitiveVisible: false,
            isSupported: true
          });
          break;
        case 'bool':
          attribute.setProperties({
            isSupported: true,
            isYesNoType: true
          });
          break;
        case 'float':
        case 'int':
          if (attribute.isTimeContext) {
            attribute.setProperties({
              isDateTimeType: true,
              isSupported: true
            });
          } else {
            attribute.setProperties({
              isNumberType: true,
              isSupported: true
            });
          }
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          attribute.setProperties({
            isHashMapType: true,
            isSupported: false
          });
          break;
        case 'string':
          attribute.setProperties({
            isCaseSensitiveVisible: true,
            isSupported: true,
            isTextType: true
          });
          break;
      }
    },
    actions: {
      setAttribute: function setAttribute(composition, property, value) {
        composition.set(property, value);
      }
    }
  });
  _exports.default = _default;
});