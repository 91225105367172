define("experience-studio/components/model-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-table-row'],
    tagName: 'tr',
    click: function click(event) {
      if (this.$(event.target).parents('.dropdown').length <= 0 && this.$(event.target).parents('footer').length <= 0) {
        if (this.$(event.target).parents('.checkbox-column').length > 0) {
          this.send('multiSelect');
        } else {
          this.send('select');
        }
      }
    },
    actions: {
      activation: function activation() {
        this.send('dispatchAction', 'activation', this.model); // eslint-disable-line ember/closure-actions
      },
      delete: function _delete() {
        this.send('dispatchAction', 'delete', this.model); // eslint-disable-line ember/closure-actions
      },
      dispatchAction: function dispatchAction(name, model, params) {
        this.sendAction('dispatchAction', name, model, params); // eslint-disable-line ember/closure-actions
      },
      duplicate: function duplicate(routeName) {
        this.send('dispatchAction', 'duplicate', this.model, routeName); // eslint-disable-line ember/closure-actions
      },
      info: function info() {
        this.send('dispatchAction', 'show-info', this.model); // eslint-disable-line ember/closure-actions
      },
      refresh: function refresh() {
        this.send('dispatchAction', 'reload', this.model); // eslint-disable-line ember/closure-actions
      },
      select: function select() {
        this.send('dispatchAction', 'select', this.model, {}); // eslint-disable-line ember/closure-actions
      },
      multiSelect: function multiSelect() {
        this.send('dispatchAction', 'select', [this.model], {}); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});