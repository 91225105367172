define("experience-studio/routes/authorize/saml", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      var _this = this;
      this._super.apply(this, arguments);
      var queryParams = null;
      if (transition && transition.queryParams && transition.queryParams.token) {
        queryParams = transition.queryParams;
      }
      if (!queryParams && transition.to && transition.to.queryParams) {
        queryParams = transition.to.queryParams;
      }
      if (queryParams && queryParams.token) {
        this.session.authorize(queryParams, true);
      }
      this.session.user.then(function (user) {
        if (user.accessLevel.value < 5) {
          _this.transitionTo('no-access');
        } else if (user.accessLevel.value === 17) {
          var url = _environment.default.APP.permissionsManagerUrl;
          url = "".concat(url, "/#/authorize?token=").concat(transition.queryParams.token);
          window.location.replace(url);
        } else {
          _this.transitionTo('experiences-v2');
        }
      }).catch(function () {
        _this.transitionTo('no-access');
      });
    }
  });
  _exports.default = _default;
});