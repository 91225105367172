define("experience-studio/adapters/content-data", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'kernel/content',
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      var contentInstanceId = 'D1D1328A-AB89-4BDD-BC04-8B70FC6E6AF8';

      /* TODO: Pass id of Content Instance */

      return url.replace('content-data', "instances/".concat(contentInstanceId, "/data"));
    }
  });
  _exports.default = _default;
});