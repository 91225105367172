define("experience-studio/mirage/config", ["exports", "experience-studio/config/environment", "ember-cli-mirage"], function (_exports, _environment, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.urlPrefix = _environment.default.APP.mirageUrl;
    this.namespace = '';
    this.get('/analytics/users/total', function () {
      return {
        data: {
          value: 9001
        }
      };
    });
    this.get('/analytics/analytics/devices/total', function () {
      return {
        data: {
          android: _emberCliMirage.faker.random.number(400000),
          iOS: _emberCliMirage.faker.random.number(400000),
          web: _emberCliMirage.faker.random.number(400000),
          other: _emberCliMirage.faker.random.number(400000)
        }
      };
    });
    this.get('/analytics/analytics/devices/active', function () {
      return {
        data: {
          android: _emberCliMirage.faker.random.number(300000),
          iOS: _emberCliMirage.faker.random.number(300000),
          web: _emberCliMirage.faker.random.number(300000),
          other: _emberCliMirage.faker.random.number(300000)
        }
      };
    });
    this.get('analytics/analytics/users/timeline/total', function (schema, request) {
      var from = window.moment.unix(Number(request.queryParams.lastFrom)).add(1, 'days');
      var to = window.moment.unix(Number(request.queryParams.lastTo)).add(1, 'days');
      var currentTime = window.moment(JSON.parse(JSON.stringify(to)));
      var beginningOfTime = window.moment(JSON.parse(JSON.stringify(from)));
      var arr = [];
      var interval = '';
      switch (request.queryParams.interval) {
        case 'day':
          interval = request.queryParams.interval;
          break;
        case 'week':
          interval = request.queryParams.interval;
          break;
        case 'month':
          interval = request.queryParams.interval;
          break;
        default:
          interval = 'day';
          break;
      }
      if (interval === 'day') {
        while (!currentTime.isBefore(from)) {
          var r = Math.floor(Math.random() * 100) + 1;
          currentTime.subtract(1, 'days');
          arr.push({
            value: r * 99,
            timestamp: currentTime.unix()
          });
        }
      }
      if (interval === 'week') {
        while (beginningOfTime.isBefore(to)) {
          var _r = Math.floor(Math.random() * 100) + 1;
          beginningOfTime.add(1, 'weeks');
          arr.push({
            value: _r * 99,
            timestamp: beginningOfTime.unix()
          });
        }
      }
      if (interval === 'month') {
        while (beginningOfTime.isBefore(to)) {
          var _r2 = Math.floor(Math.random() * 100) + 1;
          beginningOfTime.add(1, 'months');
          arr.push({
            value: _r2 * 99,
            timestamp: beginningOfTime.unix()
          });
        }
      }
      return {
        data: interval === 'week' || interval === 'month' ? arr : arr.reverse()
      };
    });
    this.get('/analytics/users/timeline/active', function (schema, request) {
      var from = window.moment.unix(Number(request.queryParams.lastFrom)).add(1, 'days');
      var to = window.moment.unix(Number(request.queryParams.lastTo)).add(1, 'days');
      var currentTime = window.moment(JSON.parse(JSON.stringify(to)));
      var beginningOfTime = window.moment(JSON.parse(JSON.stringify(from)));
      var arr = [];
      var interval = '';
      switch (request.queryParams.interval) {
        case 'day':
          interval = request.queryParams.interval;
          break;
        case 'week':
          interval = request.queryParams.interval;
          break;
        case 'month':
          interval = request.queryParams.interval;
          break;
        default:
          interval = 'day';
          break;
      }
      if (interval === 'day') {
        while (!currentTime.isBefore(from)) {
          var r = Math.floor(Math.random() * 100) + 1;
          currentTime.subtract(1, 'days');
          arr.push({
            value: r * 99,
            timestamp: currentTime.unix()
          });
        }
      }
      if (interval === 'week') {
        while (beginningOfTime.isBefore(to)) {
          var _r3 = Math.floor(Math.random() * 100) + 1;
          beginningOfTime.add(1, 'weeks');
          arr.push({
            value: _r3 * 99,
            timestamp: beginningOfTime.unix()
          });
        }
      }
      if (interval === 'month') {
        while (beginningOfTime.isBefore(to)) {
          var _r4 = Math.floor(Math.random() * 100) + 1;
          beginningOfTime.add(1, 'months');
          arr.push({
            value: _r4 * 99,
            timestamp: beginningOfTime.unix()
          });
        }
      }
      return {
        data: interval === 'week' || interval === 'month' ? arr : arr.reverse()
      };
    });
    this.get('/analytics/analytics/users/totalActive', function (schema, request) {
      var createdAt = Number(request.queryParams.createdAt);
      var retVal;
      if (window.moment().subtract(89, 'days').isAfter(window.moment(createdAt))) {
        retVal = 7001;
      } else if (window.moment().subtract(59, 'days').isAfter(window.moment(createdAt))) {
        retVal = 5001;
      } else if (window.moment().subtract(29, 'days').isAfter(window.moment(createdAt))) {
        retVal = 4001;
      } else if (window.moment().subtract(6, 'days').isAfter(window.moment(createdAt))) {
        retVal = 3001;
      } else {
        retVal = 1337;
      }
      return {
        data: {
          value: retVal
        }
      };
    });
    this.get('/analytics/users/push/total', function () {
      return {
        data: {
          value: Math.floor(Math.random() * 9001)
        }
      };
    });
    var uniqueSent = (Math.random() + 1) * 1000;
    this.get('/analytics/analytics/engagement/push/sent', function () {
      var randomMult = Math.random() + 1;
      var value = uniqueSent * randomMult;
      return {
        data: {
          value: Math.ceil(value)
        }
      };
    });
    this.get('/analytics/analytics/engagement/push/open', function () {
      var randomMult = Math.random();
      var value = uniqueSent / 2 * randomMult;
      return {
        data: {
          value: Math.ceil(value)
        }
      };
    });
    this.get('/analytics/analytics/engagement/push', function (schema, request) {
      var dataSet = schema.db.pushEngagements;
      var qP = request.queryParams;
      var sortByMap = new Map([['total_sent', 'totalSent'], ['users_sent_to', 'uniqueSent'], ['total_open', 'totalOpen'], ['unique_opened', 'uniqueOpen'], ['open_rate', 'uniqueOpenRate']]);
      dataSet.sort(function (a, b) {
        if (qP.sortOrder === 'desc') {
          return b[sortByMap.get(qP.sortBy)] - a[sortByMap.get(qP.sortBy)];
        } else {
          return a[sortByMap.get(qP.sortBy)] - b[sortByMap.get(qP.sortBy)];
        }
      });
      var slice = dataSet.slice(parseInt(qP.offset), parseInt(qP.offset) + 5);
      return {
        NotificationSent: 32,
        NotificationOpened: 27,
        Pushes: slice,
        pagination: {
          limit: parseInt(qP.limit, 10),
          offset: parseInt(qP.offset, 10),
          sortBy: qP.sortBy,
          sortOrder: qP.sortOrder,
          totalRecords: dataSet.length
        }
      };
    });
    this.get('analytics/analytics/context/rules', function (schema, request) {
      var dataSet = schema.db.ruleEngagements;
      var qP = request.queryParams;
      var sortByMap = new Map([['total_reach', 'totalReach'], ['unique_reach', 'uniqueReach'], ['unique_reach_rate', 'uniqueReachRate']]);
      dataSet.sort(function (a, b) {
        if (qP.sortOrder === 'desc') {
          return b[sortByMap.get(qP.sortBy)] - a[sortByMap.get(qP.sortBy)];
        } else {
          return a[sortByMap.get(qP.sortBy)] - b[sortByMap.get(qP.sortBy)];
        }
      });
      var slice = dataSet.slice(parseInt(qP.offset), parseInt(qP.offset) + 5);
      return {
        data: slice,
        pagination: {
          limit: parseInt(qP.limit, 10),
          offset: parseInt(qP.offset, 10),
          sortBy: qP.sortBy,
          sortOrder: qP.sortOrder,
          totalRecords: dataSet.length
        }
      };
    });
    this.get('/analytics/analytics/content', function (schema, request) {
      var dataSet = schema.db.contentEngagements;
      var qP = request.queryParams;
      var sortByMap = new Map([['total_views', 'totalViews'], ['unique_views', 'uniqueViews'], ['unique_engagement', 'uniqueEngagement'], ['unique_engagement_rate', 'uniqueEngagementRate'], ['unique_fulfillment', 'uniqueFulfillment'], ['unique_fulfillment_rate', 'uniqueFulfillmentRate']]);
      dataSet.sort(function (a, b) {
        if (qP.sortOrder === 'desc') {
          return b[sortByMap.get(qP.sortBy)] - a[sortByMap.get(qP.sortBy)];
        } else {
          return a[sortByMap.get(qP.sortBy)] - b[sortByMap.get(qP.sortBy)];
        }
      });
      var slice = dataSet.slice(parseInt(qP.offset), parseInt(qP.offset) + 5);
      return {
        data: slice,
        pagination: {
          limit: parseInt(qP.limit, 10),
          offset: parseInt(qP.offset, 10),
          sortBy: qP.sortBy,
          sortOrder: qP.sortOrder,
          totalRecords: dataSet.length
        }
      };
    });
    this.passthrough("".concat(_environment.default.APP.hostUrl, "/*"));
    this.passthrough('https://storage.googleapis.com/*');
    this.passthrough('https://pixelpusher.solutions-us.flybits.com/*');
    this.passthrough('https://flybits-file-manager.s3.amazonaws.com/*');
    this.passthrough('https://flybits-staging-file-manager.s3.amazonaws.com/*');
    this.passthrough('https://play.vidyard.com/*');
    this.passthrough('https://flybits-development-file-manager.s3.amazonaws.com/*');
  }
});