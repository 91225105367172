define("experience-studio/adapters/content-blueprint", ["exports", "experience-studio/adapters/content-instance"], function (_exports, _contentInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _contentInstance.default.extend({
    namespace: 'kernel/content',
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('content-blueprints', 'blueprints');
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url.replace('content-blueprints', "blueprints?bypassAdmin=true&managementMode=true&disableContextEmbed=true&limit=".concat(this.paginationLimit));
    }
  });
  _exports.default = _default;
});