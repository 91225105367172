define("experience-studio/templates/components/route-model-table-dropdown-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NfOFw/uN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"sendPerformModelAction\",\"editRecord\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"pen\"],null]],[2,\" Edit Access Level\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"svg-jar\",\"route-model-table-dropdown\"]}",
    "moduleName": "experience-studio/templates/components/route-model-table-dropdown-user.hbs"
  });
  _exports.default = _default;
});