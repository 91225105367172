define("experience-studio/components/info-sidebar-experience", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar-experience'],
    didRender: function didRender() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
      this._super.apply(this, arguments);
    },
    hasAssociations: Ember.computed('model.{computedGroups,computedRule,computedContents}', function () {
      var model = this.model;
      return model.computedGroups || model.computedRule || model.computedContents;
    }),
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});