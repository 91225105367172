define("experience-studio/components/content-template-branch", ["exports", "experience-studio/mixins/field-validator-factory", "experience-studio/mixins/nested-data-load"], function (_exports, _fieldValidatorFactory, _nestedDataLoad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-template-branch
      field=field
      rootID=rootID
    }}
  
  */
  var _default = Ember.Component.extend(_nestedDataLoad.default, {
    classNames: ['content-template-branch'],
    errorMsg: '',
    isLoading: false,
    _revalidateTriggered: Ember.observer('field.revalidate', function () {
      this.send('validate');
    }),
    _clearErrorTriggered: Ember.observer('field.clearError', function () {
      this.send('clearError');
    }),
    actions: {
      didUpdateData: function didUpdateData(options) {
        if (typeof this.didUpdateData !== 'undefined') {
          this.didUpdateData(options);
        }
      },
      didFocusIn: function didFocusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      },

      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      },
      isPreviewVideoTooltip: function isPreviewVideoTooltip(value) {
        if (typeof this.isPreviewVideoTooltip !== 'undefined') {
          this.isPreviewVideoTooltip(value);
        }
      },
      clearError: function clearError() {
        this.set('errorMsg', '');
      },
      validate: function validate() {
        var validator = _fieldValidatorFactory.default.getValidator(this.field, this.languages);
        var validation = validator.validate();
        if (!validation.state) {
          this.set('errorMsg', validation.firstError().header);
        } else {
          this.set('errorMsg', '');
        }
      },
      loadMore: function loadMore() {
        var view = this;
        if (this.get('field.hasMore')) {
          this.set('field.hasMore', false);
          this.set('isLoading', true);
          this.getAllData(this.rootID, this.get('field.key')).then(function (result) {
            var root = result.data[0];
            if (root) {
              view.set('field.value', []);
              view.get('field').fromData(root);
            }
          }).catch(function () {}).then(function () {
            view.set('isLoading', false);
          });
        }
      }
    },
    didRender: function didRender() {
      this.send('loadMore');
    }
  });
  _exports.default = _default;
});