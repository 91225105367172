define("experience-studio/components/av5-info-charts-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Serves as a container for analytics charts that will be rendred inside
  
    Usages:
  
      <Av5InfoChartsContainer  />
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-info-charts-container'],
    isApplyToAll: false,
    charts: null,
    sharedDateRange: null,
    dateRange: null,
    // COMPUTED ------------------------------------------------------------------
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        sharedDateParams: Ember.Object.create({
          isApplyToAll: false,
          sharedDateRange: null
        })
      });
    } // FUNCTIONS -----------------------------------------------------------------
    // ACTIONS -------------------------------------------------------------------
  });
  _exports.default = _default;
});