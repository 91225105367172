define("experience-studio/mixins/route-model-table-actions-experience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    userPreferences: Ember.inject.service(),
    actions: {
      activateRecord: function activateRecord(params) {
        var _this = this;
        var adapter = this.store.adapterFor('experience');
        var record = params.record;
        var isActive = !record.get('isActive');
        adapter.toggleProperty(record, 'isActive').then(function () {
          _this.send('hideModal');
          var activationVerb = isActive ? 'activated' : 'deactivated';
          _this.send('showNotification', {
            type: 'confirm',
            message: "Successfully ".concat(activationVerb, " ").concat(record.name, " experience.")
          });
        }).catch(function (response) {
          _this.send('hideModal');
          _this.send('showNotification', {
            type: 'alert',
            message: _this.utility.parseError(response)
          });
        });
      },
      confirmActivateRecord: function confirmActivateRecord(params) {
        var record = params.record;
        var rule = record.computedRule;
        var activationVerb = record.get('isActive') ? 'deactivate' : 'activate';
        var message = "Are you sure you want to ".concat(activationVerb, " Experience: ").concat(record.get('name'), "?");
        if (rule && rule.get('isDraft')) {
          this.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: "You cannot activate this Experience because the Rule is in \"Draft\" mode. Please change the Rule to \"Production\" mode and try again.",
            title: 'Invalid Action'
          }));
        } else {
          this.send('showModal', Ember.Object.create({
            modalName: 'confirmation-modal-dialog',
            primaryActionName: 'activateRecord',
            primaryMessage: message,
            record: record,
            secondaryMessage: '',
            title: 'Confirm Action',
            primaryAction: {
              action: 'activateRecord',
              record: record
            }
          }));
        }
      },
      showManageGroupModalDialog: function showManageGroupModalDialog(group) {
        var model = this.controller.model;
        var data = Ember.Object.create({
          experiences: this.store.findAll('experience'),
          groups: model.groups,
          group: group ? group : this.store.createRecord('group'),
          languages: model.languages.config
        });
        this.controller.set('manageGroupModalData', data);
      },
      showManageExperiencesGroupsModal: function showManageExperiencesGroupsModal(params) {
        var record = params.record;
        var controller = this.controller;
        controller.set('manageExperiencesGroupsModalData', Ember.Object.create({
          record: record,
          groups: this.controller.model.groups
        }));
      },
      showManagePrioritizationModal: function showManagePrioritizationModal(params) {
        var type = params.type || arguments[0]; // temp backwards compatibility
        var record = params.record || arguments[1];
        var parent = params.parent || arguments[2];
        var data = Ember.Object.create({
          action: type,
          experiences: [],
          groups: [],
          selectedExperience: null,
          selectedGroup: null
        });
        if (type.indexOf('CONTENT_INSTANCE') >= 0) {
          data.set('selectedExperience', record);
        } else if (type.indexOf('GROUP_EXPERIENCE') >= 0) {
          data.setProperties({
            groups: this.store.query('group', {
              sort: 'priority'
            }),
            selectedGroup: parent
          });
          data.set('groups', this.store.query('group', {
            sort: 'priority'
          }));
        } else if (type.indexOf('EXPERIENCE') >= 0) {
          data.set('experiences', this.store.query('experience', {
            sort: 'priority'
          }));
        } else if (type.indexOf('GROUP') >= 0) {
          data.set('groups', this.store.query('group', {
            sort: 'priority'
          }));
        }
        this.controller.set('managePrioritizationModalData', data);
      }
    }
  });
  _exports.default = _default;
});