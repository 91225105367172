define("experience-studio/adapters/invitation", ["exports", "experience-studio/adapters/application", "jquery"], function (_exports, _application, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'sso/auth',
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'createRecord');
      serializer.serializeIntoHash(data, type, snapshot);
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax({
          contentType: 'application/json',
          data: JSON.stringify([data]),
          headers: _this.headers,
          type: 'POST',
          url: url
        }).done(function (response) {
          response = {
            recipient: {
              email: data.email,
              permission: data.permission
            }
          };
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('invitations', 'invitations');
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments);
      return url.replace('invitations', 'invitations');
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url.replace('invitations', 'invitations');
    }
  });
  _exports.default = _default;
});