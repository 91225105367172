define("experience-studio/templates/components/settings-list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YNE4aNf+",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"table\"],[14,0,\"table table-striped\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Name\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Description\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Opt In\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"theme\",\"value\",\"onToggle\"],[\"ios\",[32,1,[\"value\"]],[30,[36,0],[[32,0],\"didToggle\",[32,1]],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[2,\"There are no settings.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"x-toggle\",\"model\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/settings-list-view.hbs"
  });
  _exports.default = _default;
});