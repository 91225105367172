define("experience-studio/templates/components/location-view-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qJAPPvdt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"map-group\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"subtitle\"],[12],[2,\"Geofence\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"map-container\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"subtitle\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          User can be anywhere in this area\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          User is in Area\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"img\"],[15,\"src\",[31,[[30,[36,2],[[35,1],[35,4],[35,3]],null]]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"info-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n        \"],[10,\"p\"],[14,0,\"subtitle\"],[12],[2,\"Date Created\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"body\"],[12],[1,[30,[36,5],[[35,0,[\"createdAt\"]],\"MMMM Do YYYY, h:mm:ss a\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,2],[[35,0,[\"creator\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"list-group-item\"],[12],[2,\"\\n          \"],[10,\"p\"],[14,0,\"subtitle\"],[12],[2,\"Created By\"],[13],[2,\"\\n          \"],[10,\"p\"],[14,0,\"body\"],[12],[1,[35,0,[\"creator\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"isApproximateAreaAttribute\",\"if\",\"mapImageUrl\",\"mapImageCircleUrl\",\"moment-format\"]}",
    "moduleName": "experience-studio/templates/components/location-view-info.hbs"
  });
  _exports.default = _default;
});