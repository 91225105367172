define("experience-studio/templates/404", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YKCDBSBr",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"four-o-four\"]}",
    "moduleName": "experience-studio/templates/404.hbs"
  });
  _exports.default = _default;
});