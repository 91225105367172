define("experience-studio/routes/content-hub/content-template/form/create", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    intl: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model() {
      var _this = this;
      var store = this.store;
      var contentTemplate = this.modelFor('content-hub.content-template');
      var contentInstance = this.store.createRecord('contentInstance', {
        contentTemplateId: contentTemplate.id,
        description: contentTemplate.description,
        iconUrl: contentTemplate.iconUrl,
        localizations: contentTemplate.localizations,
        name: contentTemplate.name,
        templateType: contentTemplate.type
      });
      return contentInstance.save().then(function (record) {
        var ids = record.associatedContentInstanceIds;
        return Ember.RSVP.hash({
          contentInstance: record,
          languages: _this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          surveyAnswers: store.findRecord('content-instance', ids.surveyAnswersInstanceID),
          surveyQuestions: store.findRecord('content-instance', ids.surveyQuestionsInstanceID),
          surveyResults: store.findRecord('content-instance', ids.surveyResultInstanceID)
        });
      });
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var model = this.controller.get('model');
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: model.surveyQuestions.get('name'),
        description: model.surveyQuestions.get('description'),
        image: model.surveyQuestions.get('iconUrl'),
        questions: controller.questions,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var applicationController = this.controllerFor('application');
      var queryParams = null;
      var routeName = 'content-hub.index';
      if (applicationController) {
        if (this.userPreferences.getValue('routeListView')) {
          routeName = 'content-hub-v2.index';
          queryParams = {
            data: this.utility.btoa({
              timestamp: Date.now()
            })
          };
        }
      }
      if (queryParams) {
        this.transitionTo(routeName, {
          queryParams: queryParams
        });
      } else {
        this.transitionTo(routeName);
      }
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        languages: model.languages.config,
        selectedLabels: [],
        transitionConfirmed: false
      });
      controller.set('initialHash', this.utility.hash({
        name: model.surveyQuestions.get('name'),
        description: model.surveyQuestions.get('description'),
        image: model.surveyQuestions.get('iconUrl'),
        questions: [],
        selectedLabels: []
      }));
      this._super(controller, model);
    },
    actions: {
      cancel: function cancel() {
        var _this2 = this;
        this.controller.get('model.contentInstance').destroyRecord().then(function () {
          var controller = _this2.controller;
          controller.set('transitionConfirmed', true);
          _this2.goBack();
          _this2.send('hideModal');
        });
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      confirmCancel: function confirmCancel() {
        if (this.hasChanges()) {
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this.send('cancel');
        }
      },
      save: function save() {
        var _this3 = this;
        var self = this;
        var controller = this.controller;
        var qInstance = this.controller.model.surveyQuestions;
        var labelAdapter = this.store.adapterFor('label');
        var selectedLabels = this.controller.selectedLabels;
        var url = "".concat(_environment.default.APP.hostUrl, "/kernel/content/instances/").concat(qInstance.id, "/data");

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.isNew) {
            var record = _this3.store.createRecord('label');
            record.set('value', label.value);
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          qInstance.set('labels', selectedLabels.mapBy('value'));
          var payload = {
            questions: controller.questions.map(function (q) {
              return q.toJSON();
            })
          };
          return new Ember.RSVP.Promise(function (resolve, reject) {
            _jquery.default.ajax({
              contentType: 'application/json',
              data: JSON.stringify(payload),
              headers: {
                'X-Authorization': _this3.session.token,
                'X-User-Agent': _this3.session.userAgent
              },
              type: 'POST',
              url: url
            }).done(function (response) {
              resolve(response);
            }).fail(function (jqXHR) {
              reject(jqXHR);
            });
          });
        }).then(function () {
          return qInstance.save();
        }).then(function () {
          qInstance.reload();
          _this3.controller.set('transitionConfirmed', true);
          _this3.send('refresh');
          _this3.goBack();
          Ember.run.scheduleOnce('afterRender', _this3, function () {
            _this3.send('showNotification', {
              type: 'confirm',
              message: "Successfully created \"".concat(qInstance.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', self.get('utility').parseError(response));
        });
      },
      validate: function validate() {
        var controller = this.controller;
        var model = controller.get('model.surveyQuestions');
        if (!Ember.isEmpty(model.get('iconUrl')) && !model.get('iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(model.get('name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else {
          this.send('save');
        }
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          window.history.go(1);
          this.send('confirmCancel');
        } else {
          var _controller = this.controller;
          _controller.setProperties({
            errorMessage: '',
            transitionConfirmed: false
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});