define("experience-studio/adapters/push-message", ["exports", "jquery", "experience-studio/adapters/application"], function (_exports, _jquery, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'push',
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('push-messages', 'pushrequests');
    },
    getPushMessagesByRuleId: function getPushMessagesByRuleId(selectedRule) {
      var _this = this;
      var url = this.buildURL();
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'GET',
          url: "".concat(url, "/pushrequests?ruleIDs=").concat(selectedRule.id)
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    broadcastRecord: function broadcastRecord(store, type, model) {
      var _this2 = this;
      var url = this.buildURL(type.modelName, model.id, model, 'findRecord');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this2.headers,
          type: 'POST',
          url: "".concat(url, "/broadcast")
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    _toggleIsActive: function _toggleIsActive(model) {
      var _this3 = this;
      var url = this.buildURL('push-message', model.id);
      url = "".concat(url, "/").concat(model.isActive ? 'deactivate' : 'activate');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this3.headers,
          type: 'PUT',
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    // Toggle the isActive flag of push message and suspend the rule if it has a limited interval
    // "Limited Interval" is a rule that has a non-unlimited frequency set in the push message page.
    toggleIsActive: function toggleIsActive(store, type, model) {
      var promises = [];
      var _ref = model.rules.objectAt(0) || {
          ruleID: undefined,
          result: 0
        },
        ruleID = _ref.ruleID;
      // Suspend the rule if it has limited interval, so it doesn't exhaust its evaluation limit
      if (ruleID) {
        var associatedRule = this.store.peekRecord('rule', ruleID);
        if (associatedRule.hasLimitedInterval) {
          associatedRule.set('hasSuspendedSchedules', model.isActive);
          promises.push(associatedRule.save());
        }
      }
      // Toggle isActive Status of the Push
      promises.push(this._toggleIsActive(model));
      return Ember.RSVP.Promise.all(promises);
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url.replace('content-messages', "pushrequests?limit=".concat(this.paginationLimit));
    }
  });
  _exports.default = _default;
});