define("experience-studio/components/route-model-table", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    classNames: ['route-model-table', 'table-responsive'],
    headers: null,
    numColumns: 0,
    showClickOutsideModal: true,
    showActionsColumn: true,
    isAllSelected: false,
    isMultiSelect: false,
    sortBy: '',
    sortOrder: '',
    allSelectionObserver: Ember.observer('model.@each.isSelected', function () {
      (0, _jquery.default)('.all-checkbox').prop('indeterminate', false);
      if (this.model.isEvery('isSelected', true) && !Ember.isEmpty(this.model)) {
        this.set('isAllSelected', true);
      } else if (this.model.isAny('isSelected', true)) {
        this.set('isAllSelected', false);
        (0, _jquery.default)('.all-checkbox').prop('indeterminate', true);
      } else {
        this.set('isAllSelected', false);
      }
    }),
    sortOrderArrowIcon: Ember.computed('sortOrder', function () {
      return this.sortOrder === 'asc' ? 'arrow-up' : 'arrow-down';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this.initializeHeaders();
      this.setNumColumns();
      this._super.apply(this, arguments);
    },
    didUpdate: function didUpdate() {
      this.initializeTooltips();
      this._super.apply(this, arguments);
    },
    willUpdate: function willUpdate() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
    },
    actions: {
      viewInfo: function viewInfo(model) {
        if (this.model.filterBy('isSelected').length > 1) {
          if (this.userPreferences.getValue('batchActionBarDeselectAll')) {
            this.performModelAction({
              action: 'showModal',
              modalName: 'confirmation-modal-dialog-v2',
              title: 'Deselect All',
              primaryMessage: 'Are you sure you want to deselect All?',
              primaryAction: {
                action: 'viewInfo',
                record: model,
                toggle: true
              }
            });
          } else {
            this.performModelAction({
              action: 'viewInfo',
              record: model,
              toggle: true
            });
          }
        } else {
          this.performModelAction({
            action: 'viewInfo',
            record: model,
            toggle: true
          });
        }
      },
      multiSelection: function multiSelection(model) {
        this.performModelAction({
          action: 'selectModelListItems',
          record: [model]
        });
      },
      singleSelection: function singleSelection(model) {
        if (!this.showActionsColumn && this.$(event.target).parents('.preference-toggle').length >= 1) {
          this.performModelAction({
            action: 'setPreferenceValue',
            model: model,
            type: 'value',
            isToggle: true
          });
        } else if (this.showActionsColumn && this.$(event.target).parents('.action-buttons').length <= 0 && this.$(event.target).parents('.check-col').length <= 0 && !this.$(event.target).hasClass('check-col')) {
          if (this.model.filterBy('isSelected').length > 1) {
            if (this.userPreferences.getValue('confirmationDeselectAll')) {
              this.performModelAction({
                action: 'showModal',
                modalName: 'confirmation-modal-dialog-v2',
                title: 'Selection',
                primaryMessage: 'This selection will deselect your other items.',
                primaryAction: {
                  action: 'selectModelListItems',
                  record: model
                }
              });
            } else {
              this.performModelAction({
                action: 'selectModelListItems',
                record: model
              });
            }
          } else {
            this.performModelAction({
              action: 'selectModelListItems',
              record: model
            });
          }
        }
      },
      toggleRow: function toggleRow(header) {
        if (header.sortProperty) {
          if (header.isSelected) {
            this.sortOrder === 'asc' ? this.set('sortOrder', 'desc') : this.set('sortOrder', 'asc');
          } else {
            this.headers.setEach('isSelected', false);
            header.set('isSelected', true);
            this.set('sortBy', header.sortProperty);
          }
          if (this.onToggleRow) {
            this.onToggleRow({
              sortOrder: this.sortOrder,
              sortBy: this.sortBy
            });
          }
        }
      },
      performModelAction: function performModelAction(params) {
        return this.performModelAction(params);
      },
      toggleSelectAll: function toggleSelectAll() {
        this.performModelAction({
          action: 'selectModelListItems',
          record: this.model
        });
      }
    },
    initializeHeaders: function initializeHeaders() {
      this.headers.setEach('isSelected', false);
      var currentHeader = this.headers.findBy('sortProperty', this.sortBy);
      if (!Ember.isEmpty(currentHeader)) {
        currentHeader.set('isSelected', true);
      }
    },
    setNumColumns: function setNumColumns() {
      var count = this.headers.filterBy('isHidden', false).length;
      this.set('numColumns', this.isMultiSelect ? count + 2 : count + 1);
    },
    initializeTooltips: function initializeTooltips() {
      var headlements = (0, _jquery.default)(this.element).find('th > [data-toggle="tooltip"]');
      var titleElements = (0, _jquery.default)(this.element).find('td > [data-toggle="tooltip"]');
      headlements.tooltip();
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('td > [data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    }
  });
  _exports.default = _default;
});