define("experience-studio/components/content-tmp-branch-obj", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-branch-obj
      field=field
      rootID=contentInstance.id
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-branch-obj'],
    isRoot: false,
    hasOptions: false,
    initExpansion: function initExpansion() {
      this.set('field._isExpanded', true);
    },
    globalExpandedUpdate: Ember.observer('globalTrigger', function () {
      if (this.globalExpanded) {
        this.set('field._isExpanded', true);
      } else {
        this.set('field._isExpanded', false);
      }
    }),
    presetFieldExpansion: Ember.observer('field', function () {
      if (this.get('field._isExpanded') === undefined) {
        this.set('field._isExpanded', true);
      }
    }),
    actions: {
      didObjUpdateData: function didObjUpdateData(options) {
        if (typeof this.didObjUpdateData !== 'undefined') {
          this.didObjUpdateData(options);
        }
      },
      didFocusIn: function didFocusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      },

      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      },
      isPreviewVideoTooltip: function isPreviewVideoTooltip(value) {
        if (typeof this.isPreviewVideoTooltip !== 'undefined') {
          this.isPreviewVideoTooltip(value);
        }
      },
      delete: function _delete() {
        this.sendAction('delete', this.field); // eslint-disable-line ember/closure-actions
      },

      duplicate: function duplicate() {
        this.sendAction('duplicate', this.field); // eslint-disable-line ember/closure-actions
      }
    },

    didInsertElement: function didInsertElement() {
      if (this.field && typeof this.didObjUpdateData !== 'undefined') {
        if (this.parentView && this.parentView.field) {
          this.didObjUpdateData(this.parentView.field);
        }
      }
      if (this.field && this.get('field._isExpanded') === undefined) {
        this.initExpansion();
      }
    }
  });
  _exports.default = _default;
});