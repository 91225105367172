define("experience-studio/helpers/mapVidyardShareToPlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mapVidyardShareToPlay;
  function mapVidyardShareToPlay(shareURL) {
    var shareURLPrefix = 'https://share.vidyard.com/watch/';
    if (!shareURL || shareURL.indexOf(shareURLPrefix) !== 0) {
      return '';
    }
    return "https://play.vidyard.com/".concat(shareURL.substr(shareURLPrefix.length).replace(/\W/g, ''));
  }
});