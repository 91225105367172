define("experience-studio/templates/components/analytics-timeline-presets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SdbNqt6n",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"dropdown\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[12],[2,\"\\n    \"],[1,[35,2,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"isDivider\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"dropdown-divider\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"change\",[32,1]],null],[12],[2,\"\\n          \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"selectedPreset\",\"presets\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/analytics-timeline-presets.hbs"
  });
  _exports.default = _default;
});