define("experience-studio/components/model-list-item-permission-role", ["exports", "experience-studio/components/model-list-item-v2"], function (_exports, _modelListItemV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItemV.default.extend({
    classNames: ['model-list-item-permission-role'],
    model: null
  });
  _exports.default = _default;
});