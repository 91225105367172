define("experience-studio/models/content-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    contentInstanceId: _emberData.default.attr('string'),
    values: _emberData.default.attr(),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch')
  });
  _exports.default = _default;
});