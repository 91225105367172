define("experience-studio/templates/permissions-hub/invitations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "87Pp24jp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"invitations-route\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"model\",\"invitations\",\"onSuccess\"],[[35,1],[35,0],\"didSentInvitation\"]]]],[2,\"\\n\\n  \"],[1,[30,[36,3],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"invitation\",\"send-invite-form\",\"invitees-list\"]}",
    "moduleName": "experience-studio/templates/permissions-hub/invitations.hbs"
  });
  _exports.default = _default;
});