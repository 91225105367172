define("experience-studio/components/pill-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['pill-list'],
    model: null,
    property: null,
    limit: null,
    pills: Ember.computed('model.[]', function () {
      if (this.property) {
        return this.model.mapBy(this.property);
      } else {
        return this.model;
      }
    }),
    remainingPills: Ember.computed('pills.[]', 'limit', function () {
      if (this.limit) {
        return this.pills.slice(this.limit, this.pills.length);
      }
    }),
    remainingPillsTooltipText: Ember.computed('remainingPills.[]', function () {
      if (this.remainingPills) {
        return this.remainingPills.join(', ');
      }
    }),
    displayPills: Ember.computed('pills.[]', 'limit', function () {
      if (this.limit) {
        return this.pills.slice(0, this.limit);
      }
      return this.pills;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
    }
  });
  _exports.default = _default;
});