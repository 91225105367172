define("experience-studio/mixins/route-model-batch-actions-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modalData = Ember.Object.extend({
    singularEntityName: 'Rule',
    pluralEntityName: 'Rules'
  });
  var _default = Ember.Mixin.create({
    actions: {
      showAddLabelRecordsModal: function showAddLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          isAdding: true
        }));
      },
      showRemoveLabelRecordsModal: function showRemoveLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          isAdding: false
        }));
      },
      showAssociateRecordsModal: function showAssociateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-associate-records-modal-dialog',
          experiences: this.store.findAll('Experience'),
          associateVar: 'ruleId',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          isAssociating: true
        }));
      },
      showDisassociateRecordsModal: function showDisassociateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-associate-records-modal-dialog',
          experiences: this.store.findAll('Experience'),
          associateVar: 'ruleId',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          isAssociating: false
        }));
      },
      showDeleteRecordsModal: function showDeleteRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-delete-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          message: "Deleting the selected Rule(s) will permantly remove it from\n        any associated Experiences and/or push notifications. This action\n        cannot be undone. Do you still wish to continue?"
        }));
      },
      showUndraftRecordsModal: function showUndraftRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-undraft-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities
        }));
      },
      undraftRecords: function undraftRecords(params) {
        var _this = this;
        var records = params.records.filterBy('isDraft', true);
        var adapter = this.store.adapterFor('rule');
        var requests = this.createBatchRequest(records, null, function (record) {
          return adapter.undraft(_this.store, record.constructor, record);
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            records: records,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            retryAction: 'showUndraftRecordsModal',
            finishAction: 'finalizeRecordsAction',
            confirmModalTitle: params.confirmModalTitle,
            failedPostfix: params.failedPostfix,
            sucessPostfix: params.sucessPostfix,
            total: records.length
          }));
        });
      }
    }
  });
  _exports.default = _default;
});