define("experience-studio/templates/components/route-model-table-dropdown-content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Rzzc/+Tj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],null,[[\"selectedEntities\"],[[35,6]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,3],[[32,0],\"sendPerformModelAction\",\"viewInfo\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"info-circle\"],null]],[2,\" View Info\\n  \"],[13],[2,\"\\n\"],[6,[37,2],null,[[\"class\",\"route\",\"model\"],[\"dropdown-item\",\"content-hub.content-instance.edit\",[35,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"pen\"],null]],[2,\" Edit\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,3],[[32,0],\"sendPerformModelAction\",\"duplicateRecord\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"clone\"],null]],[2,\" Duplicate\\n  \"],[13],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"class\",\"route\",\"model\"],[\"dropdown-item\",\"analytics-v2.content-instance\",[35,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"analytics-default\"],null]],[2,\" View Analytics\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"dropdown-divider\"],[12],[13],[2,\"\\n  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,3],[[32,0],\"sendPerformModelAction\",\"confirmDeleteRecord\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"trash\"],null]],[2,\" Delete\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"model\",\"link-to\",\"action\",\"isViewAnalyticsVisible\",\"if\",\"selectedEntities\",\"route-model-table-dropdown\"]}",
    "moduleName": "experience-studio/templates/components/route-model-table-dropdown-content-instance.hbs"
  });
  _exports.default = _default;
});