define("experience-studio/templates/components/main-header-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "h5KFucE0",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"ul\"],[14,0,\"nav nav-pills justify-content-center\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"isHidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[15,0,[32,1,[\"name\"]]],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"routeName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],null,[[\"route\"],[[32,1,[\"routeName\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,0],[[32,1,[\"isActive\"]],\"active\"],null]]]],[4,[38,1],[[32,0],\"changeTab\",[32,1]],null],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"link-to\",\"unless\",\"model\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/main-header-tabs.hbs"
  });
  _exports.default = _default;
});