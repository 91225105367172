define("experience-studio/components/fb/dropdown-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['dropdown-menu'],
    classNameBindings: ['dropDirection'],
    dropDirection: Ember.computed('parentDir', 'childDir', function () {
      if (this.parentDir) {
        return "dropdown-menu-".concat(this.parentDir);
      } else if (this.childDir) {
        return "drop".concat(this.childDir);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});