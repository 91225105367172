define("experience-studio/controllers/context-hub/rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    searchKeyword: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
    page: 1,
    recordsPerPage: 48,
    queryParams: [{
      sortBy: 'sortBy',
      sortOrder: 'sortOrder',
      searchKeyword: 'search',
      page: 'page',
      recordsPerPage: 'recordsPerPage'
    }],
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Move to Production',
      icon: 'context-default',
      action: 'showUndraftRecordsModal',
      subActions: [],
      validationFunction: function validationFunction(selectedEntities) {
        if (selectedEntities.isEvery('isDraft', false)) {
          return 'Selected rules are already in production.';
        }
      }
    }), Ember.Object.create({
      label: 'Label',
      icon: 'tag',
      subActions: [Ember.Object.create({
        label: 'Add Labels',
        action: 'showAddLabelRecordsModal',
        subActions: [],
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isDraft', false)) {
            return 'Production rules cannot be edited.';
          }
        }
      }), Ember.Object.create({
        label: 'Remove Labels',
        action: 'showRemoveLabelRecordsModal',
        subActions: [],
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('labels.length', 0)) {
            return 'These items have no labels to remove.';
          }
          if (selectedEntities.isEvery('isDraft', false)) {
            return 'Production rules cannot be edited.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: []
    })])
  });
  _exports.default = _default;
});