define("experience-studio/components/image-choice-modal", ["exports", "jquery", "experience-studio/components/base-modal-dialog", "experience-studio/config/environment"], function (_exports, _jquery, _baseModalDialog, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  /*
  
    Usage:
  
    this.send('showModal', Object.create({
      modalName: 'image-choice-modal',
      primaryActionName: 'setImage',
      existingURL: 'https://cdn.com/image.jpg',
      defaultTransform: {
        resize: {
          width: 900
        }
      },
      requirements: {
        aspectRatio: 16/9
      }
    }));
  
  */

  var toStringParams = function toStringParams(obj) {
    var retObj = {};
    var keys = window.Object.keys(obj);
    keys.forEach(function (key) {
      var val = obj[key];
      if (_typeof(val) === 'object') {
        try {
          retObj[key] = JSON.stringify(val);
        } catch (e) {
          retObj[key] = val;
        }
      } else {
        retObj[key] = val;
      }
    });
    return retObj;
  };
  var fromStringParams = function fromStringParams(obj) {
    var retObj = {};
    var keys = window.Object.keys(obj);
    keys.forEach(function (key) {
      var val = obj[key];
      try {
        retObj[key] = JSON.parse(val);
      } catch (e) {
        retObj[key] = val;
      }
    });
    return retObj;
  };
  var tabs = {
    UPLOAD: '#imgchoicemodal-upload',
    IMAGES: '#imgchoicemodal-myimages',
    EDIT: '#imgchoicemodal-edit'
  };
  var _default = _baseModalDialog.default.extend({
    classNames: ['image-choice-modal'],
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    isLoading: false,
    editToggle: false,
    editDefaultToggle: false,
    browsedSelected: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    imageURL: null,
    errorMessage: null,
    imageConfig: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    uploadURL: '',
    imageExts: 'jpg,jpeg,svg,png,gif',
    uploadInputFileClassName: Ember.computed(function () {
      return "".concat(this.elementId, "-file-upload-input");
    }),
    isAcceptable: Ember.computed('imageURL', 'imageConfig.[]', 'model.existingURL', function () {
      var accept = this.imageURL;
      if (this.get('model.requirements.aspectRatio') && this.get('model.existingURL') === '') {
        var hasCropped = this.imageConfig.filter(function (config) {
          return config.crop;
        })[0];
        accept = accept && hasCropped;
      }
      return accept;
    }),
    fileUploadURL: Ember.computed(function () {
      return "".concat(_environment.default.APP.pixelHostUrl, "/upload");
    }),
    fileUploadParam: Ember.computed(function () {
      var param = {
        'proxyTarget': "".concat(_environment.default.APP.hostUrl, "/kernel/file-manager/files/upload"),
        'proxyFileParam': 'file',
        'proxyHeaders': {
          'x-authorization': this.get('session.token')
        },
        'proxyParams': {
          isPrivate: false
        },
        'x-authorization': null
      };
      if (this.get('model.defaultTransform')) {
        param = _jquery.default.extend(param, this.get('model.defaultTransform'));
      }
      param = toStringParams(param);
      return param;
    }),
    fileUploadParamTransform: Ember.computed('fileUploadParam', function () {
      return fromStringParams(this.fileUploadParam);
    }),
    pusherProxy: Ember.computed('fileUploadParamTransform', function () {
      return {
        target: this.get('fileUploadParamTransform.proxyTarget'),
        fileParam: this.get('fileUploadParamTransform.proxyFileParam'),
        headers: this.get('fileUploadParamTransform.proxyHeaders'),
        params: this.get('fileUploadParamTransform.proxyParams')
      };
    }),
    headerTitle: Ember.computed('model.title', function () {
      return this.get('model.title') || 'Choose Image';
    }),
    browseSelected: Ember.observer('browsedSelected.[]', function () {
      if (this.browsedSelected.length > 0) {
        this.send('pickNewImgURL', this.browsedSelected[0].get('url'));
        this.send('setDefaultEdit');
      } else {
        this.send('setImageURL', null);
      }
    }),
    actions: {
      switchTab: function switchTab(tab) {
        Ember.run.next(function () {
          (0, _jquery.default)(".image-choice-modal a[href='".concat(tab, "']")).click();
        });
      },
      fileUploaded: function fileUploaded(resp) {
        if (resp.hasOwnProperty('error')) {
          this.set('errorMessage', this.utility.parseError(resp.error));
        } else {
          this.set('errorMessage', '');
          var url = null;
          try {
            url = JSON.parse(resp.data)[0].url;
          } catch (e) {
            this.set('errorMessage', "An unknown error has occurred.\n            If the problem persists, please contact an administrator.");
          }
          this.browsedSelected.clear();
          this.send('pickNewImgURL', url);
          this.send('setDefaultEdit');
        }
      },
      setImageURL: function setImageURL(url) {
        this.set('imageURL', url);
        this.set('errorMessage', '');
        this.toggleProperty('editToggle');
        this.send('switchTab', tabs.EDIT);
      },
      pickNewImgURL: function pickNewImgURL(url) {
        this.set('model.existingURL', '');
        this.send('setImageURL', url);
      },
      setImageConfig: function setImageConfig(config) {
        this.imageConfig.pushObject(config);
      },
      setDefaultEdit: function setDefaultEdit() {
        if (this.get('model.requirements.aspectRatio')) {
          var view = this;
          Ember.run.next(function () {
            view.toggleProperty('editDefaultToggle');
          });
        }
        this.imageConfig.splice(0);
      },
      imageTransformed: function imageTransformed(res) {
        var view = this;
        if (res.hasOwnProperty('error')) {
          view.set('errorMessage', this.utility.parseError(res.error));
        } else if (!res.hasOwnProperty('state')) {
          this.browsedSelected.clear();
          Ember.run.next(this, function () {
            view.send('setImageURL', res.result[0].url);
            view.send('setImageConfig', res.config);
          });
        }
      },
      uploadURL: function uploadURL() {
        var view = this;
        this.set('isLoading', true);
        var param = this.fileUploadParamTransform;
        var reqObj = {
          method: 'POST',
          contentType: 'application/json',
          data: param
        };
        reqObj.data['image'] = this.uploadURL;
        reqObj.data = JSON.stringify(reqObj.data);
        _jquery.default.ajax("".concat(_environment.default.APP.pixelHostUrl, "/transform"), reqObj).then(function (res) {
          view.send('pickNewImgURL', res[0].url);
          view.send('setDefaultEdit');
          view.get('browsedSelected').clear();
        }).catch(function (error) {
          view.set('errorMessage', view.utility.parseError(error));
        }).then(function () {
          view.set('isLoading', false);
        });
      },
      accept: function accept() {
        if (this.isAcceptable && this.get('model.primaryActionName')) {
          this.sendAction('primaryActionName', this.imageURL); // eslint-disable-line ember/closure-actions
          this.send('close'); // eslint-disable-line ember/closure-actions
          this.sendAction('modalClose'); // eslint-disable-line ember/closure-actions
        }
      },

      cancelClick: function cancelClick() {
        this.send('close');
        this.sendAction('modalClose'); // eslint-disable-line ember/closure-actions
      },
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', null);
      },
      uploadButtonClicked: function uploadButtonClicked(event) {
        (0, _jquery.default)(".".concat(this.uploadInputFileClassName)).click();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      if (this.get('model.existingURL')) {
        this.send('setImageURL', this.get('model.existingURL'));
      }
      (0, _jquery.default)(".".concat(this.uploadInputFileClassName)).on('change', function (event) {
        var view = _this;
        _this.set('isLoading', true);
        if (event.target.files && event.target.files.length) {
          var formData = new FormData();
          formData.append('file', event.target.files[0]);
          fetch("".concat(_environment.default.APP.hostUrl, "/kernel/file-manager/files/upload"), {
            method: 'POST',
            body: formData,
            headers: {
              'X-Authorization': _this.get('session.token')
            }
          }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
              response.json().then(function (res) {
                if (res && res.length && res[0].url) {
                  view.send('pickNewImgURL', res[0].url);
                  view.send('setDefaultEdit');
                  view.set('isLoading', false);
                  view.browsedSelected.clear();
                }
              });
            } else {
              view.set('errorMessage', "Couldn't upload a file: ".concat(response.statusText));
              view.set('isLoading', false);
            }
          });
        } else {
          view.set('errorMessage', "Couldn't find a file to upload");
          _this.set('isLoading', false);
        }
      });
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(".".concat(this.uploadInputFileClassName)).off();
      this.browsedSelected.splice(0);
      this.imageConfig.splice(0);
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});