define("experience-studio/templates/communications-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w0y7ghHl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"communications-hub-route\"],[12],[2,\"\\n\\n\"],[6,[37,10],null,[[\"deselectModelListItems\",\"didUpdateSearchKeyword\",\"headerTitle\",\"languages\",\"isInfoSidebarOpen\",\"isSearchFormHidden\",\"mainHeaderTabs\",\"searchKeyword\",\"selectedEntities\",\"performAction\",\"sideBarTitle\"],[\"deselectModelListItems\",\"didUpdateSearchKeyword\",\"Push Notifications\",[35,9,[\"languages\",\"config\"]],[35,8],[35,7],[35,6],[35,5],[35,4],[30,[36,3],[\"performAction\"],null],[35,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"sideBarSubTitle\",\"route-action\",\"selectedEntities\",\"searchKeyword\",\"mainHeaderTabs\",\"isSearchFormHidden\",\"isInfoSidebarOpen\",\"model\",\"main-header-info-sidebar\"]}",
    "moduleName": "experience-studio/templates/communications-hub.hbs"
  });
  _exports.default = _default;
});