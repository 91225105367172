define("experience-studio/routes/content-hub/content-instance", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    userPreferences: Ember.inject.service(),
    isRichTextEnabled: false,
    beforeModel: function beforeModel() {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      if (this.userPreferences.getValue('richTextFormatting')) {
        this.set('isRichTextEnabled', this.userPreferences.getValue('richTextFormatting'));
      } else if (isNonTdOrUsEnv) {
        this.set('isRichTextEnabled', true);
      } else {
        this.set('isRichTextEnabled', window.localStorage.getItem('isRichTextEnabled'));
      }
    },
    model: function model(params) {
      return this.store.queryRecord('content-instance', {
        id: params.content_instance_id,
        data: true,
        bypassAdmin: true,
        returnRichText: this.isRichTextEnabled
      });
    },
    afterModel: function afterModel(model) {
      var callingRoute = this._router.currentURL;
      var isCallingFromTemplatedExp = callingRoute.indexOf('edit-v2') !== -1;
      if (isCallingFromTemplatedExp) {
        this.transitionTo('content-hub.content-instance.edit-v2', model);
      } else if (model.get('templateType') && model.get('templateType').toLowerCase().indexOf('survey') >= 0) {
        this.transitionTo('content-hub.content-instance.form.edit', model);
      } else {
        this.transitionTo('content-hub.content-instance.edit', model);
      }
    }
  });
  _exports.default = _default;
});