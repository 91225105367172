define("experience-studio/components/flight-sidebar-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['logo-container'],
    requiredClicks: 15,
    currentClick: 0,
    timeWindow: 60,
    // 60 seconds
    timeoutID: undefined,
    userPreferences: Ember.inject.service(),
    userPreferencePagePreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('userPreferencePage');
    }),
    reset: function reset() {
      clearTimeout(this.timeoutID);
      this.set('timeoutID', undefined);
      this.set('currentClick', 0);
    },
    openUserDropdownMenu: function openUserDropdownMenu() {
      var _this = this;
      this.set('numberOfClicks', 25);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var timeoutId = setTimeout(function () {
          clearTimeout(timeoutId);
          var dropup = _this.$().parent().parent().parent().find('.user-container .title .dropup');
          dropup.find('.dropdown-toggle').click();
          dropup.find('.dropdown-item.settings').addClass('opt-in');
          var timeoutIdToRemoveClass = setTimeout(function () {
            clearTimeout(timeoutIdToRemoveClass);
            dropup.find('.dropdown-item.settings').removeClass('opt-in');
          }, 1000);
        }, 0);
      });
    },
    enableAdminMode: function enableAdminMode() {
      var _this2 = this;
      var preference = this.userPreferencePagePreference;
      preference.setProperties({
        hasOptIn: true,
        value: true
      });
      preference.save().then(function () {
        _this2.showNotification({
          type: 'confirm',
          message: 'Congrats! You can now access Preferences page from user\'s dropdown menu.'
        });
        _this2.openUserDropdownMenu();
      });
    },
    click: function click() {
      var _this3 = this;
      // reset the counter back to 0 after 60 seconds
      if (!this.timeoutID) {
        this.set('timeoutID', setTimeout(function () {
          _this3.reset();
        }, this.timeWindow * 1000));
      }
      this.incrementProperty('currentClick');
      if (this.currentClick > this.requiredClicks) {
        this.enableAdminMode();
        this.reset();
      }
    }
  });
  _exports.default = _default;
});