define("experience-studio/components/model-list-item-context-rule-v2", ["exports", "jquery", "experience-studio/components/model-list-item-v2"], function (_exports, _jquery, _modelListItemV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItemV.default.extend({
    classNames: ['model-list-item-context-rule-v2'],
    userPreferences: Ember.inject.service(),
    isViewAnalyticsVisible: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('analyticsPageV2');
    }),
    isBroadcast: Ember.computed('pushObject.type', function () {
      return this.pushObject && this.pushObject.type === 'broadcast';
    }),
    didUpdate: function didUpdate() {
      this.initializeTooltips();
      this._super.apply(this, arguments);
    },
    willUpdate: function willUpdate() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
      (0, _jquery.default)(document).off('click.experienceModelList');
    },
    initializeTooltips: function initializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    },
    actions: {
      duplicate: function duplicate() {
        this.sendAction('dispatchAction', 'duplicate', this.model, {
          routeName: 'context-hub'
        }); // eslint-disable-line ember/closure-actions
      },
      simulate: function simulate() {
        this.sendAction('dispatchAction', 'simulate', this.model); // eslint-disable-line ember/closure-actions
      },
      info: function info() {
        this.send('dispatchAction', 'show-info', this.model, {
          // eslint-disable-line ember/closure-actions
          controller: 'contextHubController',
          selectedEntitiesPath: 'contextHubController.selectedEntities'
        });
      },
      undraft: function undraft() {
        this.sendAction('dispatchAction', 'undraft', this.model); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});