define("experience-studio/adapters/user-preference", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'kernel',
    buildURL: function buildURL() {
      return this._super.apply(this, arguments).replace('user-preferences', 'userpref');
    },
    findAll: function findAll() {
      return this._super.apply(this, arguments).catch(function (response) {
        if (response.errors[0].status === '404') {
          return {
            data: {}
          };
        } else {
          return response;
        }
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var ajaxType = 'POST';
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      url = url.replace("/".concat(snapshot.id), '');
      if (data.id) {
        ajaxType = 'PUT';
        delete data.id;
      }
      return this.ajax(url, ajaxType, {
        data: data
      });
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var ajaxType = 'POST';
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      url = url.replace("/".concat(snapshot.id), '');
      if (data.id) {
        ajaxType = 'PUT';
        delete data.id;
      }
      if (data.data && data.data.experienceStudioUI) {
        var filteredItems = data.data.experienceStudioUI.filter(function (item) {
          return item && item.id == id;
        });
        if (filteredItems.length > 0) {
          data.data.experienceStudioUI.removeObject(filteredItems[0]);
        }
      }
      return this.ajax(url, ajaxType, {
        data: data
      });
    }
  });
  _exports.default = _default;
});