define("experience-studio/templates/components/route-model-filter-dropdown-multi-checkbox-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A7gN5Pl1",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"span\"],[14,0,\"sub-link\"],[12],[2,\"\\n  \"],[1,[35,2,[\"label\"]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"dropdown-item-indicator\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"chevron-circle\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"divider\"],[12],[13],[2,\"\\n\"],[6,[37,4],[[35,1,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"dropdown-item-indicator\"],[12],[2,\"\\n      \"],[1,[35,1,[\"length\"]]],[2,\" Selected\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,2,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[14,0,\"dropdown-item\"],[12],[2,\"\\n      \"],[11,\"div\"],[24,0,\"custom-control custom-checkbox\"],[4,[38,0],[[32,0],\"toggleSelection\",[32,1]],null],[12],[2,\"\\n        \"],[10,\"input\"],[14,0,\"custom-control-input\"],[15,\"checked\",[32,1,[\"isSelected\"]]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n        \"],[10,\"label\"],[14,0,\"custom-control-label\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedOptions\",\"section\",\"svg-jar\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/route-model-filter-dropdown-multi-checkbox-select.hbs"
  });
  _exports.default = _default;
});