define("experience-studio/routes/analytics-v1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        rules: store.findAll('rule')
      });
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        endTime: null,
        selectedRule: null,
        startTime: null,
        temporalRange: null,
        temporalTick: null
      });
    }
  });
  _exports.default = _default;
});