define("experience-studio/components/edit-content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['edit-content-instance'],
    showSideBar: true,
    userPreferences: Ember.inject.service(),
    // If LocationContent is enabled, then display rule select component on the left side
    isLocationContentEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      var ENV = Ember.getOwner(this).resolveRegistration('config:environment').environment;
      return (this.userPreferences.getValue('locationContent') || ENV === 'x-production-td' || ENV === 'x-production-eu') && location.href.indexOf('blueprint') > -1;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setProperties({
        iconTransform: {
          resize: {
            width: 900
          }
        },
        iconRequirements: {
          aspectRatio: 1
        }
      });
    },
    actions: {
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      toggleMeta: function toggleMeta() {
        this.toggleProperty('metaOpen');
      },
      trimValue: function trimValue(key, val) {
        this.model.set(key, val.trim());
      }
    }
  });
  _exports.default = _default;
});