define("experience-studio/controllers/content-hub/content-template/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // route - controller - component
    // redirect the selected rule to the route
    actions: {
      onRuleSelect: function onRuleSelect(rule) {
        this.send('onSelectRule', rule);
      }
    }
  });
  _exports.default = _default;
});