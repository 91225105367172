define("experience-studio/components/model-list-item-content-instance-v2", ["exports", "jquery", "experience-studio/components/model-list-item-v2"], function (_exports, _jquery, _modelListItemV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItemV.default.extend({
    classNames: ['model-list-item-content-instance-v2'],
    userPreferences: Ember.inject.service(),
    store: Ember.inject.service(),
    isDrodownOverflow: false,
    dropdownTeatherConstraints: [
    // eslint-disable-line
    {
      to: 'window',
      attachment: 'together'
    }],
    isLocationContent: Ember.computed('window.location.href', function () {
      return window.location.href.indexOf('/content-hub/location') > -1;
    }),
    isViewAnalyticsVisible: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('analyticsPageV2');
    }),
    mainGroupImageStyle: Ember.computed('model.iconUrl', function () {
      var style = '';
      var value = this.get('model.iconUrl');
      if (value) {
        style = "background-image:url(".concat(value, ");");
      }
      return Ember.String.htmlSafe(style);
    }),
    actionOptionNode: Ember.computed(function () {
      return this.$('.btn-more')[0];
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('isDropDownVisible', false);
      (0, _jquery.default)(document).on("click.".concat(this.elementId), function (event) {
        if (!(0, _jquery.default)(event.target).closest(_this.$('.more-option')).length) {
          _this.closeDropDown();
        }
      });
      this.$().closest('.internal-model-list-container').on("scroll.".concat(this.elementId), function () {
        _this.closeDropDown();
      });
      (0, _jquery.default)(document).on("show.bs.dropdown.".concat(this.elementId), function () {
        _this.closeDropDown(); // eslint-disable-line ember/jquery-ember-run
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)('.internal-model-list-container').off("scroll.".concat(this.elementId));
      (0, _jquery.default)(document).off("click.".concat(this.elementId));
      (0, _jquery.default)(document).off("show.bs.dropdown.".concat(this.elementId));
    },
    closeDropDown: function closeDropDown() {
      if (this.isDropDownVisible) {
        this.set('isDropDownVisible', false);
      }
    },
    actions: {
      toggleDropDown: function toggleDropDown() {
        this.toggleProperty('isDropDownVisible');
      },
      info: function info() {
        this.send('dispatchAction', 'show-info', this.model, {
          // eslint-disable-line ember/closure-actions
          controller: 'contentHubController',
          selectedEntitiesPath: 'contentHubController.selectedEntities'
        });
      },
      activate: function activate() {
        var _this2 = this;
        this.model.set('isActive', true);
        var ruleId = this.model.get('ruleId');
        this.store.findRecord('rule', ruleId).then(function (res) {
          _this2.model.set('rule', res);
          _this2.model.save().then(function () {
            // todo send notification
            // this.sendAction('showNotification', {
            //   type: 'confirm',
            //   message: 'Succesfully Deactivated',
            // });
          });
        });
      },
      deactivate: function deactivate() {
        var _this3 = this;
        this.model.set('isActive', false);
        var ruleId = this.model.get('ruleId');
        this.store.findRecord('rule', ruleId).then(function (res) {
          _this3.model.set('rule', res);
          _this3.model.save().then(function () {
            // todo send notification
            // this.showNotification({
            //   type: 'confirm',
            //   message: 'Succesfully Deactivated',
            // });
          });
        });
      }
    }
  });
  _exports.default = _default;
});