define("experience-studio/components/model-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['model.isSelected:active', 'isHighlighted:highlight', 'modelName'],
    classNames: ['model-list-item'],
    modelName: '',
    tagName: 'li',
    click: function click(event) {
      var tagNames = ['A', 'BUTTON'];
      if (!tagNames.includes(event.target.tagName)) {
        this.didClick(this.model);
        return false;
      }
    },
    isHighlighted: Ember.computed('model.{createdAt,isSelected,modifiedAt}', function () {
      var model = this.model;
      var date = model.get('modifiedAt') || model.get('createdAt');
      var minuteAgoMoment;
      var modelMoment;
      if (date && window.moment) {
        minuteAgoMoment = window.moment().subtract(30, 'seconds');
        modelMoment = window.moment(date);
      }
      if (!model.get('isSelected') && modelMoment && modelMoment.isAfter(minuteAgoMoment)) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      customAction: function customAction(data) {
        this.customAction(data);
      },
      didClick: function didClick(model, modelName) {
        this.didClick(model, modelName);
      },
      toggleProperty: function toggleProperty(property) {
        var model = this.model;
        var value = model.get(property);
        if (!model.get('isSaving')) {
          model.toggleProperty(property);
          model.save().catch(function () {
            model.set(property, value);
          });
        }
      },
      didDoubleClick: function didDoubleClick(data) {
        this.didDoubleClick(data);
      },
      showModal: function showModal(data) {
        this.showModal(data);
      }
    }
  });
  _exports.default = _default;
});