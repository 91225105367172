define("experience-studio/components/route-model-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-search'],
    data: '',
    queryParameters: null,
    model: null,
    searchKeyword: '',
    utility: Ember.inject.service(),
    dataObserver: Ember.observer('data', function () {
      this.setKeyword();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setKeyword();
    },
    decodeData: function decodeData() {
      var data = {};
      if (this.data) {
        data = this.utility.atob(this.data) || {};
      } else if (this.queryParameters) {
        data = this.queryParameters;
      }
      return data;
    },
    setKeyword: function setKeyword() {
      this.set('searchKeyword', this.getLastKeyword());
    },
    getLastKeyword: function getLastKeyword() {
      var _data = this.decodeData();
      return _data.searchKeyword || '';
    },
    actions: {
      didUpdateSearchKeyword: function didUpdateSearchKeyword(value) {
        if (this.didUpdateQueryParams) {
          if (this.getLastKeyword() == this.searchKeyword) return;
          this.didUpdateQueryParams({
            currentPage: 1,
            searchKeyword: value
          });
        }
      }
    }
  });
  _exports.default = _default;
});