define("experience-studio/templates/components/route-model-table-actions-uploads-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6hXuC+CM",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,0,[\"statusData\",\"isUploaded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"Processing...\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"Processing...\"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"btn-delete\"],[4,[38,2],[[32,0],\"sendPerformModelAction\",\"confirmDeleteRecord\"],null],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"times\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"a\"],[16,0,[31,[\"btn btn-primary btn-process \",[30,[36,5],[[35,4],\"is-hidden\",\"\"],null]]]],[4,[38,2],[[32,0],\"sendPerformModelAction\",\"processData\"],null],[12],[2,\"\\n      Process\\n    \"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"btn-delete\"],[4,[38,2],[[32,0],\"sendPerformModelAction\",\"confirmDeleteRecord\"],null],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"times\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"status-message\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"isProcessingFailed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"message\"],[12],[1,[35,0,[\"statusData\",\"statusMessage\"]]],[13],[2,\"\\n       -\\n      \"],[10,\"span\"],[14,0,\"status-date\"],[12],[1,[35,0,[\"updatedAtLabel\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[11,\"a\"],[24,0,\"btn-delete\"],[4,[38,2],[[32,0],\"sendPerformModelAction\",\"confirmDeleteRecord\"],null],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"times\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"unless\",\"action\",\"svg-jar\",\"isHide\",\"if\",\"isProcessing\"]}",
    "moduleName": "experience-studio/templates/components/route-model-table-actions-uploads-data.hbs"
  });
  _exports.default = _default;
});