define("experience-studio/components/push-messages-broadcast-limit-fields", ["exports", "jquery", "experience-studio/mixins/time-conversion"], function (_exports, _jquery, _timeConversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_timeConversion.default, {
    router: Ember.inject.service(),
    classNames: ['push-messages-broadcast-limit-fields'],
    model: null,
    broadcastOptions: null,
    // Pased from parent
    messageLimit: null,
    _focusedInput: null,
    errors: Ember.Object.create({
      hasMessageLimitError: false,
      hasCooldownRepeatError: false
    }),
    timeUnits: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Object.create({
      name: 'second(s)',
      value: 'seconds',
      singularValue: 'second'
    }), Object.create({
      name: 'minute(s)',
      value: 'minutes',
      singularValue: 'minute'
    }), Object.create({
      name: 'hour(s)',
      value: 'hours',
      singularValue: 'hour'
    }), Object.create({
      name: 'day(s)',
      value: 'days',
      singularValue: 'day'
    }), Object.create({
      name: 'week(s)',
      value: 'weeks',
      singularValue: 'week'
    }), Object.create({
      name: 'month(s)',
      value: 'months',
      singularValue: 'month'
    }), Object.create({
      name: 'year(s)',
      value: 'years',
      singularValue: 'year'
    })],
    isEditMode: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName.indexOf('edit') > 0;
    }),
    hasError: Ember.observer('errors.{hasMessageLimitError,hasCooldownRepeatError}', 'broadcastOptions.{hasMessageLimit,isBroadcastCooldownPeriodEnabled}', function () {
      // errors
      var limit = this.broadcastOptions.hasMessageLimit && this.errors.hasMessageLimitError;
      var cooldown = this.broadcastOptions.isBroadcastCooldownPeriodEnabled && this.errors.hasCooldownRepeatError; // eslint-disable-line max-len
      var hasError = limit || cooldown;
      this.set('broadcastOptions.hasError', hasError);
    }),
    hasMessageLimitError: Ember.observer('messageLimit', function () {
      this.messageLimit < 1 ? this.errors.set('hasMessageLimitError', true) : this.errors.set('hasMessageLimitError', false);
      this.set('model.options.limitSham', Number.parseInt(this.messageLimit));
    }),
    observeCooldownRepeat: Ember.observer('model.options.cooldownShamRepeat', function () {
      this.model.options.cooldownShamRepeat < 1 && this.broadcastOptions.isBroadcastCooldownPeriodEnabled // eslint-disable-line max-len
      ? this.errors.set('hasCooldownRepeatError', true) : this.errors.set('hasCooldownRepeatError', false);
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
    },
    /**
     *
     *  This is lifecycle hook is triggered in two scenarios.
     *  1) When the delivery method is changed from Broadcast to Triggered
     *  2) When a new rule is selected
     *
     * */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.later(function () {
        _this.initPushLimit();
        _this.initCooldownRepeat();
      }, 100);
    },
    setDefaultValues: function setDefaultValues(type) {
      if (Ember.isEqual(type, 'cooldownRepeat')) {
        // Message Interval
        this.set('model.options.max', 1);
        this.set('model.options.cooldownShamRepeat', 1);
        this.set('broadcastOptions.selectedTimeUnitForBroadcastCooldown', this.timeUnits.objectAt(3)); // Day
      }
    },
    initPushLimit: function initPushLimit() {
      var _limit = this.broadcastOptions.hasMessageLimit ? 1 : 0;
      this.set('broadcastOptions.hasMessageLimit', this.model.get('options.limit') > 0 || this.broadcastOptions.hasMessageLimit);
      this.set('messageLimit', this.model.get('options.limit') || _limit || 0);
    },
    // Time in between message intervals (Message Scheduling Type 2)
    initCooldownRepeat: function initCooldownRepeat() {
      var seconds = Number.parseInt(this.model.get('options.cooldown'));
      var limit = Number.parseInt(this.model.get('options.limit'));
      var period = this.convertSecondsToPeriodObject(seconds);
      if (period.value > 0) {
        this.set('broadcastOptions.isBroadcastCooldownPeriodEnabled', true);
        this.set('model.options.cooldownShamRepeat', period.value);
        this.set('model.options.max', 1);
        this.set('broadcastOptions.selectedTimeUnitForBroadcastCooldown', this.timeUnits.findBy('value', period.unit));
      } else if (this.isEditMode) {
        if (!seconds && !limit) {
          this.set('broadcastOptions.protectAgainstMultipleMessages', false);
        }
        if (!seconds) {
          this.set('broadcastOptions.isBroadcastCooldownPeriodEnabled', false);
          this.set('model.options.max', 0);
        }
      } else {
        this.setDefaultValues('cooldownRepeat');
        this.set('broadcastOptions.protectAgainstMultipleMessages', true);
      }
    },
    updatePushInterval: function updatePushInterval() {
      var _limit = this.broadcastOptions.hasMessageLimit ? 1 : 0;
      this.set('model.options.limitSham', _limit);
    },
    actions: {
      /**
       * This function is called when an input field for the rule entity is focused.
       * It will prompt a warning modal if there is a conflicting entity with the
       * selectedRule, and will only resume back to its original flow if resolved.
       * Otherwise, the input will be blurred.
       * @param {string} className target input classname
       */
      onInputFocusRuleEdit: function onInputFocusRuleEdit(className) {
        var _this2 = this;
        if (Ember.isEqual(this._focusedInput, className)) return;
        var el = document.querySelector(".".concat(className));
        if (this.editRuleAttributesCB) {
          this.editRuleAttributesCB(
          // success callback (duplicate, edit anyways)
          function () {
            _this2.set('_focusedInput', className);
            el.addEventListener('blur', function () {
              _this2.set('_focusedInput', undefined);
            });
            el.focus();
          },
          // cancel callback (cancel)
          function () {
            el.blur();
          });
        }
      },
      /**
       * Prompt user with rule conflict/duplicate modal when turning off message schedule
       * option with a rule that has an interval already.
       */
      toggleIsBroadcastCooldownPeriodEnabled: function toggleIsBroadcastCooldownPeriodEnabled(isEnabled) {
        this.broadcastOptions.toggleProperty('isBroadcastCooldownPeriodEnabled');
        this.set('model.options.cooldownShamRepeat', isEnabled ? 1 : 0);
        this.set('model.options.max', isEnabled ? 1 : 0);
        this.set('broadcastOptions.selectedTimeUnitForBroadcastCooldown', this.timeUnits.objectAt(3)); // Day
      },
      selectValue: function selectValue(model, variable, selection) {
        model ? model.set(variable, selection) : this.set(variable, selection);
      },
      /**
       * Calls the "selectValue" function only after rule edit conflict is resolved.
       * @param {object} model
       * @param {string} variable
       * @param {string} selection
       */
      selectValueConflictRule: function selectValueConflictRule(model, variable, selection) {
        var _this3 = this;
        this.editRuleAttributesCB(function () {
          _this3.send('selectValue', model, variable, selection);
        });
      },
      validateNumberInput: function validateNumberInput(value, event) {
        if (!value && event.key === '0' || event.key === '-' || event.key === '.' || event.key === 'e') {
          event.preventDefault();
        }
      },
      toggleBroadcastMessagesLimitEnabled: function toggleBroadcastMessagesLimitEnabled(isEnabled) {
        this.set('broadcastOptions.hasMessageLimit', isEnabled);
        this.updatePushInterval();
      },
      toggleProtectAgainstMultipleMessages: function toggleProtectAgainstMultipleMessages(isEnabled) {
        this.initPushLimit();
        this.initCooldownRepeat();
        this.setDefaultValues('cooldownRepeat');
        this.set('model.options.limit', 0);
        this.set('broadcastOptions.protectAgainstMultipleMessages', isEnabled);
        this.set('broadcastOptions.isBroadcastCooldownPeriodEnabled', isEnabled);
        this.set('broadcastOptions.hasMessageLimit', !isEnabled);
      }
    }
  });
  _exports.default = _default;
});