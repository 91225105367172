define("experience-studio/controllers/context-hub/context-data-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    data: '',
    queryParams: [{
      data: 'data'
    }],
    filteredModel: Ember.computed('isRefreshFilterModel', 'data', function () {
      return this.store.query('csvfile', {
        recordsPerPage: 48
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    }),
    filterDropdownSpecification: Object.freeze([Ember.Object.create({
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-checkbox-select',
        options: [Ember.Object.create({
          isSelected: false,
          value: 'draft',
          label: 'Context Data',
          property: 'visibility'
        }), Ember.Object.create({
          isSelected: false,
          value: 'public',
          label: 'Location Data',
          property: 'visibility'
        })],
        incompatibleProperties: [],
        disabledOnProperties: []
      })]
    })]),
    paramTitleMap: Object.freeze([])
  });
  _exports.default = _default;
});