define("experience-studio/components/content-tmp-edit-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-dropdown
      field=field
    }}
  
  */

  var findOpt = function findOpt(opts, optVal) {
    for (var i = 0; i < opts.length; i++) {
      if (optVal !== null && typeof optVal !== 'undefined' && optVal.length && optVal.includes('UNIVERSAL_ACTION') && (opts[i].key === 'action_types_4' || opts[i].key === 'action_types_5')) {
        var actionTypeOptions = opts[i].options;
        for (var count = 0; count < actionTypeOptions.length; count++) {
          if (actionTypeOptions[count].val === optVal) {
            return actionTypeOptions[count];
          }
        }
      } else if (opts[i].val === optVal) {
        return opts[i];
      }
    }
    return;
  };
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-dropdown'],
    defaultInit: false,
    selectedOption: null,
    defaultUpdated: Ember.observer('field.options.[]', 'field.defaultOption', function () {
      this.initDefault();
    }),
    proxyVal: Ember.computed('field.value', {
      get: function get() {
        return findOpt(this.get('field.options'), this.get('field.value'));
      },
      set: function set(key, value) {
        this.set('field.value', value.val);
        return value;
      }
    }),
    initDefault: function initDefault() {
      if (this.defaultInit) {
        return;
      }
      this.set('defaultInit', true);
      if (!this.proxyVal && this.get('field.defaultOption')) {
        this.set('proxyVal', this.get('field.defaultOption'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.field.defaultOption && !this.field.value) {
        this.set('selectedOption', this.field.defaultOption);
        Ember.set(this.field, 'value', this.field.defaultOption.val);
      } else if (this.field.value) {
        this.set('selectedOption', this.field.value);
      }
    },
    actions: {
      selectValue: function selectValue(option) {
        this.set('selectedOption', option);
        Ember.set(this.field, 'value', option.val);
        Ember.set(this.field, 'validationPrefix', option.validationPrefix);
        if (typeof this.updateData !== 'undefined') {
          this.updateData(this.field);
        }
      }
    }
  });
  _exports.default = _default;
});