define("experience-studio/controllers/context-hub/rule-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    data: '',
    queryParams: [{
      data: 'data'
    }],
    utility: Ember.inject.service(),
    filteredModel: Ember.computed('isRefreshFilterModel', 'data', function () {
      var data = this.utility.atob(this.data);
      if (!data) {
        data = {
          page: 1,
          recordsPerPage: 10
        };
      }
      if (data && !data.page) {
        data.page = 1;
      }
      if (data && !data.recordsPerPage) {
        data.recordsPerPage = 10;
      }
      data = this.utility.btoa(data);
      return this.store.query('rule', {
        data: data
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    }),
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Move to Production',
      icon: 'context-default',
      action: 'showUndraftRecordsModal',
      subActions: [],
      validationFunction: function validationFunction(selectedEntities) {
        if (selectedEntities.isEvery('isDraft', false)) {
          return 'Selected Rules are already in production.';
        }
      }
    }), Ember.Object.create({
      label: 'Label',
      icon: 'tag',
      subActions: [Ember.Object.create({
        label: 'Add Labels',
        action: 'showAddLabelRecordsModal',
        subActions: [],
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isDraft', false)) {
            return 'Production Rules cannot be edited.';
          }
        }
      }), Ember.Object.create({
        label: 'Remove Labels',
        action: 'showRemoveLabelRecordsModal',
        subActions: [],
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isDraft', false)) {
            return 'Production Rules cannot be edited.';
          }
          if (selectedEntities.isEvery('labels.length', 0)) {
            return 'These items have no labels to remove.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: []
    })]),
    filterState: Object.freeze([Ember.Object.create({
      title: 'Type',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-checkbox-select',
        id: 'type',
        isDisabled: false,
        options: [Ember.Object.create({
          isSelected: false,
          value: 'draft',
          label: 'Draft',
          property: 'visibility'
        }), Ember.Object.create({
          isSelected: false,
          value: 'public',
          label: 'Production',
          property: 'visibility'
        })],
        incompatibleProperties: [],
        disabledOnProperties: []
      })]
    }), Ember.Object.create({
      title: 'Date',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Created',
        id: 'created',
        isDisabled: false,
        options: [Ember.Object.create({
          value: undefined,
          property: 'createdAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'createdAtTo'
        })]
      })]
    }), Ember.Object.create({
      title: '',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-model-select',
        label: '',
        id: 'label',
        isDisabled: false,
        options: [Ember.Object.create({
          modelName: 'label',
          // used to look up model in store
          modelRepresentation: ['name', 'value'],
          // used to retrieve id or value to be saved in param
          value: undefined,
          label: 'Labels',
          property: 'labels'
        })],
        incompatibleProperties: [],
        disabledOnProperties: []
      })]
    })]),
    filterParams: Object.freeze([Ember.Object.create({
      id: 'createdAtTo',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'createdAtFrom',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'labels',
      component: 'filter-pill-label',
      value: null
    }), Ember.Object.create({
      id: 'visibility',
      component: 'filter-pill-visibility',
      value: null
    })])
  });
  _exports.default = _default;
});