define("experience-studio/templates/components/content-tmp-edit-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AU0/1Mvi",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,4],null,[[\"options\",\"onchange\",\"renderInPlace\",\"searchEnabled\",\"placeholder\",\"selected\"],[[35,1,[\"options\"]],[30,[36,3],[[32,0],\"selectValue\"],null],true,false,\"Pick an option\",[35,2]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"displayName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"displayName\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1,[\"val\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[35,1,[\"showFullOptionLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  (\"],[1,[32,1,[\"val\"]]],[2,\")\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"field\",\"proxyVal\",\"action\",\"power-select\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-dropdown.hbs"
  });
  _exports.default = _default;
});