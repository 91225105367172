define("experience-studio/templates/permissions-hub/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eWp/U+S/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route permissions-hub-user-route\"],[12],[2,\"\\n  \"],[1,[30,[36,7],null,[[\"model\",\"data\",\"onToggleRow\",\"didUpdateSearchKeyword\",\"isLoading\",\"performModelAction\",\"selectedEntities\"],[[35,6],[35,5],[30,[36,3],[\"updateQueryParams\"],null],\"didUpdateSearchKeyword\",[35,4],[30,[36,3],[\"performModelAction\"],null],[35,2,[\"selectedEntities\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,8],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"accessLevelModalData\",\"permissions-hub-access-level-modal-dialog\",\"permissionsHubController\",\"route-action\",\"isLoading\",\"data\",\"filteredModel\",\"route-model-table-user\",\"if\"]}",
    "moduleName": "experience-studio/templates/permissions-hub/users.hbs"
  });
  _exports.default = _default;
});