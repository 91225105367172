define("experience-studio/components/location-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['location-list-item'],
    classNameBindings: ['isViewInfo:is-expanded-view', 'isSmallFormFactor'],
    tagName: 'li',
    isViewInfo: false,
    model: null,
    actions: {
      edit: function edit() {
        if (this.editLocation) {
          this.editLocation(this.get('model.content') || this.get('model'));
        }
      },
      viewInfo: function viewInfo() {
        this.toggleProperty('isViewInfo');
      },
      selectValue: function selectValue() {
        this.sendAction('selectValue', this.model); // eslint-disable-line ember/closure-actions
      },
      toggleSelectModel: function toggleSelectModel(model) {
        model.toggleProperty('isSelected');
        this.send('selectValue');
      }
    }
  });
  _exports.default = _default;
});