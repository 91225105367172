define("experience-studio/adapters/access-level", ["exports", "experience-studio/mixins/access-level", "experience-studio/adapters/application"], function (_exports, _accessLevel, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend(_accessLevel.default, {
    findAll: function findAll() {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var data = [{
          name: 'App User',
          description: 'App Users can only access Flybits-powered digital channels (i.e. end users who sign into your Flybits-powered application or website).',
          value: 1
        }, {
          name: 'Viewer',
          description: 'Viewers are able to access various entities within the Experience Studio such as Groups, Experiences, Content, and Context Rules.',
          value: 5
        }, {
          name: 'Creator',
          description: 'Creators are able to create and edit various entities through the Experience Studio such as Groups, Experiences, Content, and Context Rules.',
          value: 10
        }, {
          name: 'Publisher',
          description: 'Publishers are able to publish content through Experiences and Push Notifications that directly affect the end users of the application.',
          value: 15
        }, {
          name: 'Permission Manager',
          description: 'Permissions Managers can Manage user\'s permissions for Developer Portal and Experience Studio.',
          value: 17
        }, {
          name: 'Owner',
          description: 'Owners are able to directly affect and impact the project in all ways. They have full access to all the entities and are able to manage the permissions of every user in the system.',
          value: 20
        }];
        data.forEach(function (item, index) {
          item.id = "".concat(index + 1);
          item.actions = self.getAccessLevelActions(item.value);
        });
        resolve({
          data: data
        });
      });
    }
  });
  _exports.default = _default;
});