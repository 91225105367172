define("experience-studio/templates/components/custom-field--datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VGJYYL0r",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[35,0,[\"name\"]]],[13],[6,[37,1],[[35,0,[\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,3],null,[[\"value\",\"enableTime\"],[[35,2],false]]]],[2,\"\\n\"],[10,\"span\"],[14,0,\"text-danger\"],[12],[1,[34,4]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"field\",\"if\",\"date\",\"date-time-picker\",\"errorText\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--datepicker.hbs"
  });
  _exports.default = _default;
});