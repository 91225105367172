define("experience-studio/components/main-header-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['main-header-tabs'],
    classNameBindings: ['isHidden'],
    isHidden: Ember.computed('model.[]', function () {
      return this.model.length === 1;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var filteredItems = this.model.filterBy('isActive');
      if (Ember.isEmpty(filteredItems)) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.send('changeTab', this.model.objectAt(0));
        });
      }
    },
    actions: {
      changeTab: function changeTab(tab) {
        this.model.setEach('isActive', false);
        tab.set('isActive', true);
        this.sendAction('didChange', tab); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});