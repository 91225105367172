define("experience-studio/components/route-model-suggestions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-search', 'route-model-suggestions-search'],
    queryParameters: null,
    model: null,
    utility: Ember.inject.service(),
    fieldNode: null,
    searchOptions: null,
    currentSearchOption: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('searchOptions', [{
        label: 'All',
        property: 'search'
      }, {
        label: 'Name',
        property: 'name'
      }, {
        label: 'Address',
        property: 'address'
      }, {
        label: 'Label',
        property: 'labelsFormula'
      }]);
      this.set('currentSearchOption', {
        label: 'All',
        property: 'search'
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('fieldNode', this.element.querySelector('input.search-field'));
    },
    updateQueryParams: function updateQueryParams(value) {
      var _this = this;
      var queryObject = {};
      this.searchOptions.forEach(function (option) {
        if (option.property === _this.currentSearchOption.property) {
          var queryValue = value ? value : _this.searchKeyword;
          if (option.property === 'labelsFormula') {
            queryObject[option.property] = queryValue ? "".concat('(', queryValue, ')') : '';
          } else {
            queryObject[option.property] = queryValue;
          }
        } else {
          queryObject[option.property] = null;
        }
      });
      queryObject['currentPage'] = 1;
      queryObject['recordsPerPage'] = 10;
      this.didUpdateQueryParams(queryObject);
    },
    actions: {
      selectSuggestion: function selectSuggestion(value, keyCode) {
        if (keyCode === 13) {
          this.updateQueryParams(value);
        }
      },
      updateSearchParams: function updateSearchParams(value) {
        this.set('currentSearchOption', value);
        this.updateQueryParams();
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(value) {
        this.set('searchKeyword', value);
      }
    }
  });
  _exports.default = _default;
});