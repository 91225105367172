define("experience-studio/models/csvfile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    extension: _emberData.default.attr('string'),
    fileID: _emberData.default.attr('string'),
    meta: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    statusMessage: _emberData.default.attr('string'),
    tenantId: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    fileType: _emberData.default.attr('string'),
    isProcessingInProgress: _emberData.default.attr('boolean'),
    errors: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces

    createdAt: _emberData.default.attr('epoch'),
    updatedAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    processedAt: _emberData.default.attr('epoch'),
    willCompleteAt: _emberData.default.attr('epoch'),
    // Computed Properties

    sizeStr: Ember.computed('size', function () {
      var size = this.size;
      if (size < 1000) {
        return "".concat(size, " B");
      } else if (size < 1000000) {
        return "".concat((size / 1000).toFixed(1), " KB");
      } else if (size < 1000000000) {
        return "".concat((size / 1000000).toFixed(1), " MB");
      } else if (size < 1000000000000) {
        return "".concat((size / 1000000000).toFixed(1), " GB");
      }
      return "".concat(size, " B");
    }),
    isProcessingFailed: Ember.computed('status', function () {
      var status = this.status;
      return status.toLowerCase() === 'processing_failed';
    }),
    progressStatus: Ember.computed('status', 'willCompleteAt', function () {
      var status = this.status.toLowerCase() || '';
      var willCompleteAt = this.willCompleteAt;
      var progressStatus = '';
      var percentage = 0;
      var isShowSpinner = false;
      if (status === 'processing' && !willCompleteAt) {
        progressStatus = 'Calculating estimated time.';
        isShowSpinner = true;
      } else if (status === 'processing' && willCompleteAt) {
        var processedAt = window.moment(this.processedAt).unix() * 1000;
        var currentTime = window.moment().unix() * 1000;
        var _willCompleteAt = window.moment(this.willCompleteAt).unix() * 1000;
        percentage = Math.round((currentTime - processedAt) / (_willCompleteAt - processedAt) * 100);
        var timeRemaining = window.moment(this.willCompleteAt).fromNow();
        if (timeRemaining && timeRemaining.toLowerCase() === 'in a few seconds') {
          timeRemaining = 'in less than 1 minute';
        }
        progressStatus = "".concat(timeRemaining, ".");
        isShowSpinner = false;
      }
      return Ember.Object.create({
        progressStatus: progressStatus,
        isShowSpinner: isShowSpinner,
        percentage: percentage
      });
    }),
    setStatus: function setStatus(obj) {
      var statusData = {
        isUploaded: false,
        isProcessing: false,
        isCompleted: false,
        isFailed: false,
        isCancelled: false
      };
      for (var key in statusData) {
        if (statusData.hasOwnProperty(key)) {
          statusData[key] = key === obj['state'];
        }
      }
      return Ember.Object.create(statusData);
    },
    statusData: Ember.computed('status', function () {
      var status = this.status.toLowerCase() || '';
      var statusData;
      switch (status) {
        case 'uploaded':
          statusData = this.setStatus({
            state: 'isUploaded'
          });
          statusData.set('statusMessage', 'Uploaded');
          break;
        case 'processing':
          statusData = this.setStatus({
            state: 'isProcessing'
          });
          statusData.set('statusMessage', 'Processing started');
          break;
        case 'processing_failed':
          statusData = this.setStatus({
            state: 'isFailed'
          });
          statusData.set('statusMessage', this.get('statusMessage'));
          break;
        case 'processing_success':
          statusData = this.setStatus({
            state: 'isCompleted'
          });
          statusData.set('statusMessage', 'Completed');
          break;
        case 'processing_canceled':
          statusData = this.setStatus({
            state: 'isCancelled'
          });
          statusData.set('statusMessage', 'Cancelled');
          break;
        default:
          statusData = this.setStatus({
            state: 'isUploaded'
          });
          statusData.set('statusMessage', 'Uploaded');
      }
      return statusData;
    }),
    updatedAtLabel: Ember.computed('status', 'updatedAt', function () {
      var processedAt = window.moment(this.processedAt);
      var status = this.status.toLowerCase();
      var updatedAt = window.moment(this.updatedAt);
      var diff;
      var timeElapsed;
      var timeElapsedUpdated;
      if (status !== 'uploaded' && status === 'processing') {
        diff = processedAt.from(updatedAt);
        if (diff === 'a few seconds ago') {
          timeElapsed = 'less than a minute ago';
        } else {
          timeElapsed = diff;
        }
      } else {
        if (window.moment().diff(updatedAt, 'hours') < 24) {
          timeElapsedUpdated = updatedAt.fromNow();
          if (timeElapsedUpdated === 'a few seconds ago') {
            timeElapsed = 'less than a minute ago';
          } else {
            timeElapsed = timeElapsedUpdated;
          }
        } else {
          timeElapsed = updatedAt.format('MMMM Do YYYY, h:mm:ss a');
        }
      }
      return timeElapsed;
    }),
    isContextFile: Ember.computed('fileType', function () {
      return this.fileType === 'context-data';
    })
  });
  _exports.default = _default;
});