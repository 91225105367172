define("experience-studio/templates/components/analytics-card-context-rule-performance-over-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nTR+s3OD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"left-half\"],[12],[2,\"\\n      \"],[10,\"h4\"],[14,0,\"name\"],[12],[2,\"Performance Over Time\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"right-half\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"dropdown\"],[12],[2,\"\\n        \"],[10,\"button\"],[14,0,\"btn btn-default\"],[14,\"data-toggle\",\"dropdown\"],[14,4,\"button\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"ellipsis-v\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[12],[2,\"\\n          \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"dropdown-item\"],[12],[2,\"One\"],[13],[2,\"\\n          \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"dropdown-item\"],[12],[2,\"Two\"],[13],[2,\"\\n          \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"dropdown-item\"],[12],[2,\"Three\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"chart-container\"],[12],[2,\"\\n          \"],[1,[30,[36,3],null,[[\"data\",\"options\",\"type\"],[[35,2],[35,1],\"line\"]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"primaryLineChartOptions\",\"primaryLineChartData\",\"ember-chart\"]}",
    "moduleName": "experience-studio/templates/components/analytics-card-context-rule-performance-over-time.hbs"
  });
  _exports.default = _default;
});