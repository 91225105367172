define("experience-studio/routes/analytics-v2/push-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(params) {
      var _this = this;
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('store').findAll('push-message').then(function (response) {
          var model = response.findBy('id', params.model_id);
          if (model) {
            resolve(model);
          } else {
            reject();
          }
        }).catch(function () {
          reject();
        });
      });
      return promise;
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        endTime: null,
        startTime: null,
        temporalRange: null,
        temporalTick: null
      });
    }
  });
  _exports.default = _default;
});