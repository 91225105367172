define("experience-studio/components/context-locked-form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['context-locked-form-group', 'form-group'],
    didInsertElement: function didInsertElement() {
      this.$('label').find('.svg-icon-container').popover();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.$('label').find('.svg-icon-container').popover('dispose');
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});