define("experience-studio/components/analytics-potential-audience", ["exports", "experience-studio/components/analytics-doughnut-step-card"], function (_exports, _analyticsDoughnutStepCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _analyticsDoughnutStepCard.default.extend({
    classNames: ['analytics-potential-audience'],
    model: null,
    sliceOneValue: Ember.computed('model.potentialUniqueUsersCount', function () {
      return this.model.potentialUniqueUsersCount;
    }),
    sliceTotalValue: Ember.computed('model.totalUsersCount', function () {
      return this.model.totalUsersCount;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.model = Ember.Object.create({
        potentialUniqueUsersCount: 0,
        totalUsersCount: 0
      });
    },
    getPromiseDataArray: function getPromiseDataArray() {
      var promises = [];
      promises.pushObject(this.makeAPIRequest({}, function () {
        return {
          potentialUniqueUsersCount: 200
        };
      }, function () {
        return {
          potentialUniqueUsersCount: 200
        };
      }));
      promises.pushObject(this.makeAPIRequest({}, function () {
        return {
          totalUsersCount: 600
        };
      }, function () {
        return {
          totalUsersCount: 600
        };
      }));
      return promises;
    }
  });
  _exports.default = _default;
});