define("experience-studio/components/model-list-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-list-v2 list-unstyled'],
    classNameBindings: ['isSomeSelected'],
    modelName: '',
    tagName: 'ul',
    showPriority: false,
    preloadListObserver: Ember.observer('model', 'model.[]', function () {
      if (this.isLoadScroll) {
        this.ensureFilledList();
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      var modelListElement = this.$();
      if (this.isLoadScroll) {
        this.ensureFilledList();
        this.$().on("scroll.".concat(this.elementId), function () {
          requestAnimationFrame(function () {
            var scrollLength = modelListElement.height() + modelListElement.scrollTop();
            var scrollThreshold = modelListElement[0].scrollHeight - 10;
            var canLoadModels = _this.canLoadModels;
            var isLoading = _this.isLoading;
            if (scrollLength > scrollThreshold && canLoadModels && !isLoading) {
              _this.set('showLoadStatus', true);
              Ember.run.debounce(_this, _this.triggerScrollRefresh, 100, true);
            }
          });
        });
      }
    },
    ensureFilledList: function ensureFilledList() {
      if (this.isLoadScroll) {
        var modelListHeight = this.$().height();
        var modelListItemHeight = this.$('.model-list-item-v2').height();
        var numModels = this.get('model.length');
        if (numModels * modelListItemHeight < modelListHeight) {
          this.triggerScrollRefresh();
        }
      }
    },
    triggerScrollRefresh: function triggerScrollRefresh() {
      var modelName = this.modelName;
      this.sendAction('handleScrollBottom', modelName); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      if (this.isLoadScroll) {
        this.$().off("scroll.".concat(this.elementId));
      }
    },
    actions: {
      dispatchAction: function dispatchAction(name, model, params) {
        this.sendAction('dispatchAction', name, model, params); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});