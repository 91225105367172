define("experience-studio/components/push-messages-table", ["exports", "experience-studio/components/model-table", "jquery"], function (_exports, _modelTable, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelTable.default.extend({
    classNames: ['push-messages-table'],
    init: function init() {
      this._super.apply(this, arguments);
      var tableSortHeaders = [Ember.Object.create({
        title: 'Name',
        sortVar: 'name',
        class: 'name-column',
        isAsc: false,
        reversed: false,
        isSelected: false,
        isSortable: true
      }), Ember.Object.create({
        title: 'Delivery',
        sortVar: 'type',
        class: 'delivery-column',
        isAsc: false,
        reversed: false,
        isSelected: false,
        isSortable: true
      }), Ember.Object.create({
        title: 'Created At',
        sortVar: 'createdAt',
        class: 'created-column',
        isAsc: false,
        reversed: true,
        isSelected: false,
        isSortable: true
      }), Ember.Object.create({
        title: 'Status',
        sortVar: 'isActive',
        class: 'status-column',
        isAsc: false,
        reversed: true,
        isSelected: false,
        isSortable: false
      })];
      tableSortHeaders.findBy('sortVar', 'createdAt').set('isSelected', true);
      this.set('tableSortHeaders', tableSortHeaders);
    },
    modelSham: Ember.computed('model.[]', 'tableSortHeaders.@each.{isSelected,isAsc}', function () {
      var model = [];
      var currentSortHeader = this.tableSortHeaders.findBy('isSelected');
      if (!Ember.isEmpty(this.model) && !Ember.isEmpty(currentSortHeader)) {
        model = this.model.toArray().sortBy(currentSortHeader.sortVar);
        if (currentSortHeader.isAsc && !currentSortHeader.reversed || !currentSortHeader.isAsc && currentSortHeader.reversed) {
          model.reverse();
        }
      }
      return model;
    }),
    didUpdate: function didUpdate() {
      this.initializeTooltips();
      this._super.apply(this, arguments);
    },
    willUpdate: function willUpdate() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
      (0, _jquery.default)(document).off('click.experienceModelList');
    },
    initializeTooltips: function initializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    },
    actions: {
      selectHeader: function selectHeader(header) {
        if (header.title !== 'Status') {
          if (header.get('isSelected')) {
            header.toggleProperty('isAsc');
          } else {
            this.tableSortHeaders.setEach('isSelected', false);
            header.set('isSelected', true);
          }
        }
      }
    }
  });
  _exports.default = _default;
});