define("experience-studio/templates/components/custom-field--content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Veaac2Ul",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,0]],[13],[6,[37,2],[[35,1,[\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,7],null,[[\"isActive\",\"content\",\"selectedContent\",\"onSelectContent\",\"selectContent\",\"deselectContent\",\"contentProperties\",\"loadModels\",\"handleSearch\"],[true,[35,6],[35,5],[35,4],\"selectContent\",\"deselectContent\",[35,3],\"loadModels\",\"handleSearch\"]]]],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,1,[\"errorMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"error-message text-danger\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"field\",\"if\",\"contentProperties\",\"onSelectContent\",\"selectedContent\",\"content\",\"content-instance-selector\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--content.hbs"
  });
  _exports.default = _default;
});