define("experience-studio/templates/components/route-model-table-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7Mqv2FPv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"tooltip-wrapper\"],[14,\"data-toggle\",\"tooltip\"],[15,\"title\",[32,0,[\"multiSelectInfoText\"]]],[12],[2,\"\\n\"],[11,\"button\"],[16,0,[31,[\"btn btn-action btn-model-action-dropdown \",[30,[36,5],[[35,4],\"is-multi-selected\"],null]]]],[4,[38,6],[[32,0],\"toggleDropdown\"],null],[12],[2,\"\\n  \"],[1,[30,[36,7],[\"ellipsis-v\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"target\",\"targetAttachment\",\"attachment\",\"constraints\",\"class\"],[[35,2],\"bottom right\",\"top right\",[35,1],\"dropdown route-model-table-dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"dropdown-menu dropdown-menu-tethered dropdown-menu-right \",[34,0]]]],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"menuClass\",\"constraints\",\"targetElement\",\"ember-tether\",\"isMultiSelected\",\"if\",\"action\",\"svg-jar\",\"isOpen\"]}",
    "moduleName": "experience-studio/templates/components/route-model-table-dropdown.hbs"
  });
  _exports.default = _default;
});