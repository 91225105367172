define("experience-studio/components/custom-field--text", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    didRootChange: Ember.observer('dropdownValue', function () {
      if (this.dropdownValue === 'concierge:UNIVERSAL_ACTION' || this.dropdownValue === 'app:UNIVERSAL_ACTION' || this.dropdownValue === 'mailto:UNIVERSAL_ACTION' || this.dropdownValue === 'universalLink:UNIVERSAL_ACTION' || this.dropdownValue === 'tel:UNIVERSAL_ACTION' || this.dropdownValue === 'web:UNIVERSAL_ACTION' || this.dropdownValue === 'browser:UNIVERSAL_ACTION' || this.dropdownValue === 'details:UNIVERSAL_ACTION' || this.dropdownValue === 'Web Link') {
        this.set('isValidationVisible', true);
      } else {
        this.set('isValidationVisible', false);
      }
    }),
    isLanguagesVisible: Ember.computed('dropdownValue', function () {
      return !!this.dropdownValue;
    }),
    isActionTypeComponent: Ember.computed('dropdownValue', function () {
      return this.dropdownValue === 'concierge:UNIVERSAL_ACTION' || this.dropdownValue === 'app:UNIVERSAL_ACTION' || this.dropdownValue === 'mailto:UNIVERSAL_ACTION' || this.dropdownValue === 'universalLink:UNIVERSAL_ACTION' || this.dropdownValue === 'tel:UNIVERSAL_ACTION' || this.dropdownValue === 'web:UNIVERSAL_ACTION' || this.dropdownValue === 'details:UNIVERSAL_ACTION' || this.dropdownValue === 'browser:UNIVERSAL_ACTION';
    }),
    didInsertElement: function didInsertElement() {
      if (this.dropdownValue === 'concierge:UNIVERSAL_ACTION' || this.dropdownValue === 'app:UNIVERSAL_ACTION' || this.dropdownValue === 'mailto:UNIVERSAL_ACTION' || this.dropdownValue === 'universalLink:UNIVERSAL_ACTION' || this.dropdownValue === 'tel:UNIVERSAL_ACTION' || this.dropdownValue === 'web:UNIVERSAL_ACTION' || this.dropdownValue === 'browser:UNIVERSAL_ACTION' || this.dropdownValue === 'details:UNIVERSAL_ACTION' || this.dropdownValue === 'Web Link') {
        if (this.field && this.field.value) {
          this.setValidity(this.field.value);
        }
        this.set('isValidationVisible', true);
      } else {
        this.set('isValidationVisible', false);
      }
      this.set('isSkipUrlValidation', false);
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var field = this.field;
      field.set('errorMessages', []);
      this._super(value, isValid);
    },
    triggerValidation: function triggerValidation() {
      this.sendAction('onValue', this.field.value, this.field.key, true); // eslint-disable-line ember/closure-actions
    },

    actions: {
      onValue: function onValue() {
        var input = this.get("field.input");
        var value;
        if (!Ember.isEmpty(input)) {
          value = JSON.parse(JSON.stringify(input));
        } else {
          value = {};
        }
        Object.keys(value).forEach(function (key) {
          value[key] = value[key] || undefined;
        });
        value = Ember.Object.create(value);
        this.field.set('value', value);
        this.field.set('input', input);
        this.setDirty(input);
        this.setValidity(input);
        this.sendAction('onValue', value, this.field.key, true); // eslint-disable-line ember/closure-actions
      },
      selectLanguage: function selectLanguage(language) {
        this.languages.setEach('isSelected', false);
        language.set('isSelected', true);
      }
    }
  });
  _exports.default = _default;
});