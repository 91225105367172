define("experience-studio/components/confirmation-modal-stub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('message', this.messages.findBy('id', this.model.id).get('message'));
    }
  });
  _exports.default = _default;
});