define("experience-studio/serializers/context-historical-query", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    utility: Ember.inject.service(),
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      item.attributes.plugin = this.transformToEmberObject(item.attributes.plugin);
      //we set wildcard as default if parameters are empty
      for (var property in item.attributes.plugin) {
        if (property === "parameter1" && item.attributes.plugin[property] === "") {
          item.attributes.plugin[property] = "*";
        }
        if (property === "parameter2" && item.attributes.plugin[property] === "") {
          item.attributes.plugin[property] = "*";
        }
        if (property === "parameter3" && item.attributes.plugin[property] === "") {
          item.attributes.plugin[property] = "*";
        }
        if (property === "parameter4" && item.attributes.plugin[property] === "") {
          item.attributes.plugin[property] = "*";
        }
        if (property === "parameter5" && item.attributes.plugin[property] === "") {
          item.attributes.plugin[property] = "*";
        }
      }
      item.attributes.where = this.transformToEmberObject(item.attributes.where);
      return item;
    }
  });
  _exports.default = _default;
});