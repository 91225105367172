define("experience-studio/components/content-tmp-edit-text-locale", ["exports", "sanitize-html", "experience-studio/config/environment"], function (_exports, _sanitizeHtml, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-text-locale'],
    userPreferences: Ember.inject.service(),
    utility: Ember.inject.service(),
    isTextArea: Ember.computed('field.inputUI', function () {
      return this.get('field.inputUI') && this.get('field.inputUI') === 'textarea';
    }),
    isSkipUrlValidationVisible: Ember.computed('field', function () {
      return this.field.key === 'actionValue' && this.field.isWebLink;
    }),
    languages: Ember.computed(function () {
      return this.utility.get('languages');
    }),
    observeLanguages: Ember.observer('languages.[]', function () {
      var languages = this.languages;
      if (!Ember.isEmpty(languages) && !languages.findBy('isSelected', true)) {
        var language = languages.findBy('isDefault', true);
        if (!language) {
          language = languages.get('firstObject');
        }
        if (language) {
          language.set('isSelected', true);
        }
      }
    }),
    isRichTextEnabled: Ember.computed('userPreferences.preferences.[]', 'field', 'isRichTextNotBlacklisted', function () {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      var isRTFEnabled = isNonTdOrUsEnv || this.userPreferences.getValue('richTextFormatting');
      return isRTFEnabled && this.field.isRichTextEnabled && this.isRichTextNotBlacklisted;
    }),
    isRichTextNotBlacklisted: Ember.computed(function () {
      var domain = window.location.origin;
      var blacklist = ['https://dev-td-us-flybits.flybits.com', 'https://td-us.flybits.com', 'https://tddev3.flybits.com', 'https://tdint3.flybits.com', 'https://tdpat3.flybits.com', 'https://tdcanada3.flybits.com', 'https://tdi-dev.flybits.com', 'https://tdi-pat.flybits.com', 'https://tdi-prod.flybits.com', 'https://ces-dev.flybits.com', 'https://ces-stage.flybits.com', 'https://ces-mtf.flybits.com', 'https://ces-prerelease.flybits.com', 'https://ces-prod.flybits.com ', 'https://boschnextdevelopment.flybits.com', 'https://boschnextq.flybits.com', 'https://boschnext.flybits.com', 'https://dev-boschnext.flybits.com', 'https://qa-boschnext.flybits.com', 'https://prod-boschnext.flybits.com', 'https://lennar.flybits.com', 'https://fivepoint-dev.flybits.com', 'https://fivepoint.flybits.com', 'https://members1st-dev.flybits.com', 'https://members1st-staging.flybits.com', 'https://members1st.flybits.com', 'https://rmhc-dev.flybits.com', 'https://canadalife-dev.canada.flybits.com', 'https://canadalife-staging.canada.flybits.com', 'https://canadalife.canada.flybits.com', 'http://fab-dev.flybits.com/', 'https://liv-dev.v3-europe.flybits.com/', 'https://diebold-dev.flybits.com', 'https://diebold-staging.flybits.com', 'https://diebold.flybits.com'];
      if (blacklist.includes(domain)) {
        return false;
      }
      return true;
    }),
    setRichTextValueObject: function setRichTextValueObject() {
      var _this = this;
      var fieldValueTot = {};
      this.languages.forEach(function (lang) {
        var fieldValue = _this.get('field.value')[lang.id];
        if (fieldValue) {
          var value;
          try {
            value = decodeURIComponent(typeof fieldValue === 'string' ? fieldValue : fieldValue.html);
          } catch (exception) {
            value = typeof fieldValue === 'string' ? fieldValue : fieldValue.html;
          }
          fieldValue = {
            'html': encodeURIComponent(_this.getSanitizeHtml(value)),
            'text': _this.extractHTMLContent(value)
          };
          fieldValueTot[lang.id] = fieldValue;
        }
      });
      this.set('field.value', fieldValueTot);
    },
    extractHTMLContent: function extractHTMLContent(text) {
      var span = document.createElement('span');
      span.innerHTML = text;
      return span.textContent || span.innerText;
    },
    getSanitizeHtml: function getSanitizeHtml(content) {
      var sanitizeHtmlText = (0, _sanitizeHtml.default)(content, {
        allowedTags: ['p', 'span', 'em', 'strong', 'ol', 'li', 'ul', 'sup', 'a', 'q', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre'],
        allowedAttributes: {
          'a': ["href", "target"],
          'span': ["class", "data-mce-dynamic-attribute", "title", "contenteditable", "style"],
          'q': ["class"],
          'p': ["style", "dir"],
          'li': ["style"]
        },
        allowedStyles: {
          '*': {
            'text-decoration': [/^underline/],
            'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
            'font-family': [/^[a-zA-Z',-; ]*$/]
          },
          'p': {
            'text-align': [/^left$/, /^right$/, /^center$/]
          },
          'li': {
            'text-align': [/^left$/, /^right$/, /^center$/]
          }
        }
      });
      return sanitizeHtmlText.replace(/text-decoration:underline/gi, 'text-decoration: underline');
    },
    didInsertElement: function didInsertElement() {
      if (!this.get('field.value')) {
        this.set('field.value', {});
      }
      if (this.isRichTextEnabled && this.get('field.inputUI') === 'textarea' && this.get('field.value')) {
        this.setRichTextValueObject();
      }
    },
    actions: {
      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      },
      didFocusIn: function didFocusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});