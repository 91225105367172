define("experience-studio/templates/components/search-form-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "INIvHm+d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"type\",\"class\",\"placeholder\",\"key-up\",\"value\",\"autocomplete\"],[\"text\",\"search-field form-control\",[35,3],\"onInput\",[35,2],\"off\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"search-icon input-group-append\"],[12],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"span\"],[24,\"tabindex\",\"0\"],[24,\"aria-label\",\"Clear search\"],[24,0,\"input-group-text cancel\"],[4,[38,1],[[32,0],\"cancel\"],[[\"bubbles\"],[false]]],[4,[38,1],[[32,0],\"clearSearch\"],[[\"bubbles\",\"on\"],[false,\"keyPress\"]]],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"times\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"input-group-text\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"search\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"action\",\"value\",\"placeholder\",\"input\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/search-form-group.hbs"
  });
  _exports.default = _default;
});