define("experience-studio/services/resolver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This is a generic resolve status marker/checker.
   *
   * It is currently, being used to keep status of rule conflict.
   * The purpose is to flag wether or not the user has
   * resolved the "conflict" when trying to edit a rule that have conflicts
   * with other entities.
   *
   * A conflict is deemed "resolved" in the following two scenarios.
   * 1) When the user duplicates a rule.
   * 2) When the user presses "make changes anyway"
   */
  var _default = Ember.Service.extend({
    isResolved: false,
    reset: function reset() {
      this.set('isResolved', false);
    },
    resolve: function resolve() {
      this.set('isResolved', true);
    }
  });
  _exports.default = _default;
});