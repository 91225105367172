define("experience-studio/routes/communications-hub/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'communications-hub.index') {
        this.transitionTo('communications-hub.push-messages');
      }
    }
  });
  _exports.default = _default;
});