define("experience-studio/models/group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    defaultLocalization: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    isPinned: _emberData.default.attr('boolean'),
    isPartiallyLoaded: _emberData.default.attr('boolean'),
    localizations: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return Ember.Object.create();
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    priorityIndex: _emberData.default.attr('number'),
    experienceIds: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    experiences: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    labelIds: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    labels: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces

    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    // Computed Properties

    computedExperiences: Ember.computed('experiences.[]', function () {
      var experiences = this.experiences;
      var records = [];
      var store = this.store;
      var serializer = store.serializerFor('experience');
      if (!Ember.isEmpty(experiences)) {
        experiences.forEach(function (item) {
          var record = store.peekRecord('experience', item.id);
          if (!record) {
            item.isPartiallyLoaded = true;
            record = store.push({
              data: serializer.transformPayloadForEmber(item, 'experience')
            });
          }
          records.pushObject(record);
        });
      }
      return records;
    })
  });
  _exports.default = _default;
});