define("experience-studio/templates/components/power-select-custom-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "j3vcmpyS",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"onInput\",\"value\"],[\"didUpdateSearchKeyword\",[35,0,[\"searchText\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"select\",\"search-form-group\"]}",
    "moduleName": "experience-studio/templates/components/power-select-custom-search.hbs"
  });
  _exports.default = _default;
});