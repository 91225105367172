define("experience-studio/controllers/content-hub/blueprint-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    userPreferences: Ember.inject.service(),
    isLocationContentEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      var ENV = Ember.getOwner(this).resolveRegistration('config:environment').environment;
      return this.userPreferences.getValue('locationContent') || ENV === 'x-production-td' || ENV === 'x-production-eu';
    }),
    isTabShow: true,
    tabsData: Ember.computed('isLocationContentEnabled', function () {
      return [Ember.Object.create({
        displayName: 'Location Template',
        templatesList: this.model.templates,
        isActive: true
      })];
    }),
    actions: {
      selectContentTemplate: function selectContentTemplate(template) {
        if (template.get('isSelected')) {
          template.set('isSelected', false);
        } else {
          this.model.templates.setEach('isSelected', false);
          template.set('isSelected', true);
        }
      }
    }
  });
  _exports.default = _default;
});