define("experience-studio/templates/components/model-list-permission-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HF1KyRBP",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"model\",\"showActions\",\"dispatchAction\"],[[32,1],[35,3],\"dispatchAction\"]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"no-model-message no-model-graphic-container\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"graphic no-search-results\"],[12],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"No Roles found for \"],[10,\"strong\"],[12],[1,[34,1]],[13],[2,\".\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"graphic\"],[12],[13],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"Roles\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"create\"],null],[12],[2,\"Create a new Role\"],[13],[2,\" to define a custom set of Access Level permissions for your team.\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"searchKeyword\",\"if\",\"showActions\",\"model-list-item-permission-role\",\"modelSham\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/model-list-permission-role.hbs"
  });
  _exports.default = _default;
});