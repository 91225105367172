define("experience-studio/routes/analytics/engagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var EXPORT_TABLES_MAP = {
    rule: 'Rules',
    push: 'Push',
    content: 'Content'
  };
  var _default = Ember.Route.extend({
    av5TourGuide: Ember.inject.service(),
    // Analtics export feature flag remove
    // Content Analytics Flag
    userPreferences: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var analyticsCardGroups = [];
      // set up options for date range dropdown
      var dateRange = this.dateRangeConstructor();
      // Analtics export feature flag remove
      var isAnalyticsExportEnabled = true; // remove ff from template later
      var routeClass = 'analytics-engagement-route';
      if (isAnalyticsExportEnabled) {
        routeClass += ' analytics-export-flag';
      }
      analyticsCardGroups.pushObject([Ember.Object.create({
        title: 'PUSH NOTIFICATIONS',
        dataType: 'push',
        subtitle: '',
        cardType: 'advancedEngagement',
        allLabels: [],
        labels: [],
        labelsOperator: 'AND',
        requests: [Ember.Object.create({
          constraint: null,
          domain: 'analytics/analytics',
          endpoint: 'engagement/push',
          isProduction: true,
          labelSearchResults: [],
          queryParams: Ember.Object.create({
            timeFrom: '',
            timeTo: '',
            sortOrder: 'desc',
            sortBy: '',
            offset: 0,
            limit: 5,
            op: null,
            labels: '',
            search: ''
          })
        }), Ember.Object.create({
          constraint: null,
          display: 'card',
          domain: 'analytics/analytics',
          endpoint: 'engagement/push',
          isProduction: true,
          labelSearchResults: [],
          queryParams: Ember.Object.create({
            timeFrom: '',
            timeTo: '',
            sortOrder: 'desc',
            sortBy: '',
            offset: 0,
            limit: 5,
            op: null,
            labels: ''
          })
        })]
      }), Ember.Object.create({
        title: 'CONTEXT RULES',
        dataType: 'rule',
        subtitle: '',
        cardType: 'advancedEngagement',
        allLabels: [],
        labels: [],
        labelsOperator: 'AND',
        requests: [Ember.Object.create({
          constraint: null,
          domain: 'analytics/analytics',
          endpoint: 'context/rules',
          isProduction: true,
          labelSearchResults: [],
          queryParams: Ember.Object.create({
            timeFrom: '',
            timeTo: '',
            sortOrder: 'desc',
            sortBy: '',
            offset: 0,
            limit: 5,
            op: null,
            labels: '',
            search: ''
          })
        })]
      }), Ember.Object.create({
        title: 'CONTENT',
        dataType: 'content',
        subtitle: '',
        cardType: 'advancedEngagement',
        allLabels: [],
        labels: [],
        labelsOperator: 'AND',
        requests: [Ember.Object.create({
          constraint: null,
          domain: 'analytics/analytics',
          endpoint: this.userPreferences.getValue('contentAnalytics') ? 'reports/contents' : 'content',
          isProduction: true,
          labelSearchResults: [],
          queryParams: Ember.Object.create({
            timeFrom: '',
            timeTo: '',
            sortOrder: 'desc',
            sortBy: '',
            offset: 0,
            limit: 5,
            op: null,
            labels: '',
            search: ''
          })
        })]
      })]);
      controller.setProperties({
        isApplyLabelsToAll: false,
        analyticsCardGroups: analyticsCardGroups,
        dateRange: dateRange,
        selectedDateOption: dateRange[0],
        fromDate: dateRange[0].from,
        toDate: dateRange[0].to,
        // Analtics export feature flag remove
        isAnalyticsExportEnabled: isAnalyticsExportEnabled,
        routeClass: routeClass,
        // remove before lines til here
        tablesIncluded: {},
        exportFormat: 'CSV',
        exportDateRange: Ember.Object.create({
          from: null,
          to: null
        }),
        exportEmail: '',
        isExportEmailInvalid: false,
        isExportComplete: false,
        isExportError: false,
        exportErrorMessage: ''
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          isApplyLabelsToAll: false
        });
      }
    },
    dateRangeConstructor: function dateRangeConstructor() {
      var durations = [7, 30, 90];
      var option = null;
      var currentDayStartTime = window.moment().endOf('day').unix() * 1000;
      var retVal = durations.map(function (duration) {
        option = Ember.Object.create({
          name: "Last ".concat(duration, " days"),
          isSelected: false,
          to: currentDayStartTime,
          from: window.moment().subtract(duration, 'days').startOf('day').unix() * 1000
        });
        return option;
      });
      retVal.unshiftObject(Ember.Object.create({
        name: 'Today',
        isSelected: true,
        to: window.moment().endOf('day').unix() * 1000,
        from: window.moment().startOf('day').unix() * 1000
      }));
      return retVal;
    },
    actions: {
      didTransition: function didTransition() {
        Ember.run.scheduleOnce('afterRender', this.av5TourGuide, this.av5TourGuide.initiateAv5Tour);
      },
      selectRange: function selectRange(opt) {
        this.controller.setProperties({
          fromDate: opt.from,
          toDate: opt.to
        });
      },
      setExportFromDate: function setExportFromDate(fromDate) {
        this.controller.exportDateRange.setProperties({
          from: fromDate
        });
      },
      setExportToDate: function setExportToDate(toDate) {
        this.controller.exportDateRange.setProperties({
          to: toDate
        });
      },
      updateSelectedExportTable: function updateSelectedExportTable(tableName) {
        this.controller.setProperties({
          tablesIncluded: Object.assign({}, this.controller.tablesIncluded, _defineProperty({}, tableName, !this.controller.tablesIncluded[tableName]))
        });
      },
      updateExportFormat: function updateExportFormat(exportFormat) {
        this.controller.setProperties({
          exportFormat: exportFormat
        });
      },
      updateExportEmail: function updateExportEmail(event) {
        this.controller.setProperties({
          exportEmail: event.currentTarget.value,
          isExportEmailInvalid: false
        });
      },
      handleExport: function handleExport() {
        var _this = this;
        var exportAdapter = this.store.adapterFor('analytics-export');
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
        if (!emailRegEx.test(this.controller.exportEmail)) {
          this.controller.setProperties({
            isExportEmailInvalid: true
          });
          event.stopPropagation();
          return;
        }

        // tables to be exported
        var dataTypes = Object.keys(this.controller.tablesIncluded).filter(function (tableType) {
          return _this.controller.tablesIncluded[tableType];
        }).map(function (tableType) {
          return EXPORT_TABLES_MAP[tableType];
        });

        // Add one more date toexportDateRange.to date to make endDate inclusive
        var toDate = this.controller.exportDateRange.to;
        toDate.setDate(toDate.getDate() + 1);
        exportAdapter.export({
          startTime: Math.floor(this.controller.exportDateRange.from.getTime() / 1000),
          endTime: Math.ceil(toDate.getTime() / 1000)
        }, dataTypes, this.controller.exportFormat, this.controller.exportEmail).catch(function (err) {
          _this.controller.setProperties({
            isExportError: true,
            exportErrorMessage: err && err.responseJSON && err.responseJSON.error && err.responseJSON.error.exceptionMessage || 'Unkown error'
          });
        }).finally(function () {
          _this.controller.setProperties({
            isExportComplete: true
          });
        });
      },
      exportResultModalOnClose: function exportResultModalOnClose() {
        this.controller.setProperties({
          tablesIncluded: {},
          exportFormat: 'CSV',
          exportDateRange: Ember.Object.create({
            from: null,
            to: null
          }),
          exportEmail: '',
          isExportEmailInvalid: false,
          isExportComplete: false,
          isExportError: false,
          exportErrorMessage: ''
        });
      }
    }
  });
  _exports.default = _default;
});