define("experience-studio/components/location-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['location-list'],
    areaModel: null,
    attributes: null,
    isAllSelected: false,
    isShowSelected: false,
    model: null,
    queryParameters: null,
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.areaModel) {
        this.set('areaModel.isHasHistoricalPlugin', false);
      }
    },
    modelSham: Ember.computed('model.[]', 'areaModel.locations.[]', 'isShowSelected', function () {
      if (this.isShowSelected && this.areaModel) {
        return this.areaModel.locations;
      }
      if (!Array.isArray(this.model)) {
        return this.model.toArray();
      }
      return this.model;
    }),
    isLoading: Ember.computed('model.isFulfilled', function () {
      return !Array.isArray(this.model) && !this.model.isFulfilled;
    }),
    selectionObserver: Ember.observer('modelSham.[]', 'areaModel.locations.[]', 'isShowSelected', 'queryParameters', function () {
      var _this = this;
      var model = this.modelSham;
      model.setEach('isSelected', false);
      model.forEach(function (location) {
        if (location) {
          var isSelected = false;
          if (_this.areaModel && _this.areaModel.locations) {
            isSelected = _this.areaModel.locations.map(function (loc) {
              return loc.get('content') ? loc.get('content.id') : loc.get('id');
            }).includes(location.get('id'));
          }
          location.set('isSelected', isSelected);
        }
      });
      this.setCheckboxValue();
      if (this.areaModel && Ember.isEmpty(this.areaModel.locations)) {
        this.send('showLocations', 'all');
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.decodeData();
    },
    decodeData: function decodeData() {
      var data = this.utility.atob(this.data) || {};
      this.set('queryParameters', data);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('areaModel.locations.length')) {
        this.send('showLocations', 'selected');
      }
    },
    actions: {
      editLocation: function editLocation(model) {
        if (this.editLocation) {
          this.editLocation(model);
        }
      },
      didUpdateQueryParams: function didUpdateQueryParams(params) {
        if (this.didUpdateQueryParams) {
          this.didUpdateQueryParams(params);
        }
      },
      selectValue: function selectValue(location) {
        var isSelect = location.get('isSelected');
        this.sendAction('selectValue', location, isSelect); // eslint-disable-line ember/closure-actions
      },
      showLocations: function showLocations(item) {
        this.set('isShowSelected', item === 'selected');
      },
      toggleSelectAll: function toggleSelectAll() {
        var _this2 = this;
        var modelSham = this.modelSham;
        var isSelect = !modelSham.isAny('isSelected', true);
        modelSham.forEach(function (location) {
          _this2.sendAction('selectValue', location, isSelect); // eslint-disable-line ember/closure-actions
        });
      }
    },
    setCheckboxValue: function setCheckboxValue() {
      var _this3 = this;
      var model = this.modelSham;
      Ember.run.scheduleOnce('afterRender', function () {
        (0, _jquery.default)('.all-checkbox').prop('indeterminate', false);
        _this3.set('isAllSelected', false);
        if (model.isEvery('isSelected', true) && model.length > 0) {
          _this3.set('isAllSelected', true);
        } else if (model.isAny('isSelected', true) && model.length > 0) {
          (0, _jquery.default)('.all-checkbox').prop('indeterminate', true);
        }
      });
    }
  });
  _exports.default = _default;
});