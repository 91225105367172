define("experience-studio/templates/components/content-tmp-edit-text-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "D+vjZj3h",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,8],null,[[\"placeholder\",\"languages\",\"isSkipUrlValidationVisible\",\"isRichText\",\"isNonMaxLengthField\",\"model\",\"isTextarea\",\"key\",\"maxLength\",\"field\",\"plugins\",\"updateDynamicAttribute\",\"linkValue\",\"dynamicAtrributeValue\",\"didFocusIn\"],[[35,2,[\"placeholder\"]],[35,7],[35,6],[35,2,[\"isRichTextEnabled\"]],[35,5],[35,2,[\"value\"]],[35,4],[35,2,[\"key\"]],[35,2,[\"maxLength\"]],[35,2],[35,3],[30,[36,0],[[32,0],\"updateDynamicAttribute\"],null],[35,2,[\"linkValue\"]],[35,1],[30,[36,0],[[32,0],\"didFocusIn\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"dynamicAtrributeValue\",\"field\",\"plugins\",\"isTextArea\",\"isNonMaxLengthField\",\"isSkipUrlValidationVisible\",\"languages\",\"localized-input\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-text-locale.hbs"
  });
  _exports.default = _default;
});