define("experience-studio/components/all-upload", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    accept: '*',
    classNames: ['all-upload'],
    filePath: null,
    fileUrl: null,
    customTargetUrl: null,
    customFileParamName: null,
    customFormArgs: null,
    isPickerVisible: false,
    isPrivate: false,
    isUploading: false,
    response: null,
    uploadTimeout: 1000 * 60 * 15,
    session: Ember.inject.service(),
    targetUrl: Ember.computed('customTargetUrl', function () {
      var url = this.customTargetUrl || "".concat(_environment.default.APP.hostUrl, "/kernel/file-manager/files/upload");
      url += "?callback=".concat(window.location.origin, "/noop.html");
      return url;
    }),
    fileParamName: Ember.computed('customFileParamName', function () {
      return this.customFileParamName || 'data';
    }),
    formArgs: Ember.computed('customFormArgs', function () {
      var defaultObj = {
        'isPrivate': this.isPrivate,
        'X-Authorization': this.get('session.token')
      };
      if (this.customFormArgs) {
        defaultObj = _jquery.default.extend(defaultObj, this.customFormArgs);
      }
      return defaultObj;
    }),
    showPicker: Ember.observer('isPickerVisible', function () {
      if (!this.isUploading) {
        this.send('upload');
      }
    }),
    actions: {
      didUpload: function didUpload(response) {
        if (!Ember.isEmpty(response) && Array.isArray(response)) {
          this.set('fileUrl', response.objectAt(0).url);
        }
        this.setProperties({
          isUploading: false,
          response: response
        });
        this.sendAction('didUpload', response); // eslint-disable-line ember/closure-actions
      },
      upload: function upload() {
        var self = this;
        window.allUpload({
          acceptStr: this.accept,
          fileParamName: this.fileParamName,
          formArgs: this.formArgs,
          isResponseInQueryString: true,
          onfilechange: function onfilechange(filePath) {
            self.setProperties({
              isPickerVisible: false,
              isUploading: true,
              filePath: filePath
            });
          },
          uploadTimeout: this.uploadTimeout,
          targetURL: this.targetUrl
        }).then(function (response) {
          var payload;
          try {
            payload = JSON.parse(response.response);
          } catch (exception) {
            // TODO: Handle exception
          }
          self.send('didUpload', payload || response);
        }).catch(function (error) {
          self.send('didUpload', {
            error: error
          });
        });
      }
    }
  });
  _exports.default = _default;
});