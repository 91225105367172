define("experience-studio/controllers/communications-hub/push-messages-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    searchKeyword: '',
    page: 1,
    recordsPerPage: 48,
    queryParams: [{
      searchKeyword: 'search',
      page: 'page',
      recordsPerPage: 'recordsPerPage'
    }],
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Send',
      icon: 'paper-plane',
      action: 'showSendRecordsModal',
      validationFunction: function validationFunction(selectedEntities) {
        if (selectedEntities.isEvery('isBroadcast', false)) {
          return 'Broadcast push notifications cannot be sent.';
        }
      }
    }), Ember.Object.create({
      label: 'Activation',
      icon: 'power',
      subActions: [Ember.Object.create({
        label: 'Set as Active',
        action: 'showActivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isBroadcast', true)) {
            return 'Triggered push notifications cannot be activated.';
          }
          if (selectedEntities.isEvery('isActive', true)) {
            return 'At least one item has to be Inactive.';
          }
        }
      }), Ember.Object.create({
        label: 'Set as Inactive',
        action: 'showDeactivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isBroadcast', true)) {
            return 'Broadcast push notifications cannot be deactivated.';
          }
          if (selectedEntities.isEvery('isActive', false)) {
            return 'At least one item has to be Active.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: [],
      hasDivider: true
    })])
  });
  _exports.default = _default;
});