define("experience-studio/components/av5-info-cards-list-container", ["exports", "experience-studio/mixins/analytics-data"], function (_exports, _analyticsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Renders a list of cards based on their component type
  
    Usages:
  
      {{av5-info-cards-list-container analyticsCards=analyticsCards}}
  
  */
  var _default = Ember.Component.extend(_analyticsData.default, {
    // PROPERTIES ----------------------------------------------------------------
    cardMap: null,
    classNames: ['av5-info-cards-list-container'],
    dateRangeParams: null,
    utility: Ember.inject.service(),
    // COMPUTED ------------------------------------------------------------------
    analyticsCardDeck: Ember.computed('analyticsCards', function () {
      var _this = this;
      var retArr = [];
      this.analyticsCards.forEach(function (card) {
        var prop = _this.cardMap.get(card.cardType);
        card.set(prop ? prop : _this.cardMap.get('basic'), true);
        retArr.pushObject(card);
      });
      return retArr;
    }),
    hasCollapsedCards: Ember.computed('analyticsCards', function () {
      return this.analyticsCards.filter(function (card) {
        return card.isCollapsed;
      }).length > 0;
    }),
    tabbedCards: Ember.computed('analyticsCards', function () {
      return this.analyticsCards.filter(function (card) {
        return card.isTabbed;
      });
    }),
    hasTabbedCards: Ember.computed('tabbedCards', function () {
      return this.tabbedCards.length;
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        cardMap: new Map([['advancedEngagement', 'isAdvancedEngagement'], ['basic', 'isBasic'], ['chart', 'isChart']]),
        sharedDateParams: Ember.Object.create({
          isApplyToAll: false,
          sharedDateRange: null
        })
      });
    }
  });
  _exports.default = _default;
});