define("experience-studio/components/av5-custom-date-range-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    A custom date range dropdown
  
    Usages:
  
      <Av5CustomDateRangeDropdown
        selectRange=(action 'selectRange')
      />
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------

    classNames: ['av5-custom-date-range-dropdown'],
    dateFormat: 'M d/Y, h:i K',
    dateRange: null,
    enableTime: false,
    isApplyToAll: false,
    isDisabled: false,
    placeholder: 'Select start date',
    customDateRange: null,
    selectedOption: null,
    sharedDateRange: null,
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        customDateRange: Ember.Object.create({
          name: 'Custom',
          isSelected: false,
          to: null,
          from: null
        }),
        selectedOption: this.dateRange.objectAt(0)
      });
      this.selectRange(this.dateRange.objectAt(0));
    },
    // COMPUTED ------------------------------------------------------------------
    isCustom: Ember.computed('selectedOption.name', function () {
      return this.selectedOption.name.toLowerCase() === 'custom';
    }),
    dropdownClass: Ember.computed('dropdownClassName', function () {
      return "dropdown".concat(this.dropdownClassName ? " ".concat(this.dropdownClassName) : '');
    }),
    dropdownTriggerClass: Ember.computed('triggerClass', function () {
      return "btn dropdown-toggle".concat(this.triggerClass ? " ".concat(this.triggerClass) : '');
    }),
    // OBSERVERS -----------------------------------------------------------------
    didSharedOptionsChange: Ember.observer('sharedDateRange.name', 'sharedDateRange.to', 'sharedDateRange.from', function () {
      Ember.run.once(this, 'handleSharedOptionsChanged');
    }),
    // FUNCTIONS -----------------------------------------------------------------
    handleSharedOptionsChanged: function handleSharedOptionsChanged() {
      var _this = this;
      // gate this function from non-change
      if (!this.isApplyToAll || !this.sharedDateRange) {
        return;
      }
      if (this.sharedDateRange.to === this.selectedOption.to && this.sharedDateRange.from === this.selectedOption.from && this.sharedDateRange.name.toLowerCase() === this.selectedOption.name.toLowerCase()) {
        return;
      }
      var toSelect;
      this.dateRange.forEach(function (range) {
        if (range.name.toLowerCase() === _this.sharedDateRange.name.toLowerCase()) {
          range.set('isSelected', true);
          toSelect = range;
        } else {
          range.set('isSelected', false);
        }
      });
      if (this.sharedDateRange.name.toLowerCase() === 'custom') {
        toSelect = this.customDateRange;
        this.customDateRange.setProperties({
          isSelected: true,
          to: this.sharedDateRange.to,
          from: this.sharedDateRange.from
        });
      } else {
        this.customDateRange.set('isSelected', false);
      }
      this.set('selectedOption', toSelect);
      if (toSelect.to && toSelect.from) {
        this.selectRange(toSelect);
      }
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      toggleApplyToAll: function toggleApplyToAll() {
        this.setProperties({
          isApplyToAll: !this.isApplyToAll,
          sharedDateRange: !this.isApplyToAll ? this.selectedOption : null
        });
      },
      selectCustom: function selectCustom(e) {
        e.stopPropagation();
        e.preventDefault();

        // i hate this
        (0, _jquery.default)('.dropdown-toggle', this.element).dropdown('update');
        this.send('onValueChange', this.customDateRange);
      },
      onFromDateChange: function onFromDateChange(date, dateString) {
        this.customDateRange.set('from', window.moment(window.Number(dateString) * 1000).startOf('day').unix() * 1000);
        if (this.customDateRange.to) {
          this.send('onValueChange', this.customDateRange);
        }
      },
      onToDateChange: function onToDateChange(date, dateString) {
        this.customDateRange.set('to', window.moment(window.Number(dateString) * 1000).endOf('day').unix() * 1000);
        if (this.customDateRange.from) {
          this.send('onValueChange', this.customDateRange);
        }
      },
      onValueChange: function onValueChange(option) {
        var optionName = option.name.toLowerCase() || '';

        // gate conditions to prevent unnecessary calls
        if (this.selectedOption.name.toLowerCase() === optionName) {
          if (optionName !== 'custom') {
            return;
          }
        }
        this.dateRange.setEach('isSelected', false);
        this.customDateRange.set('isSelected', false);
        option.set('isSelected', true);
        this.setProperties({
          selectedOption: option
        });
        if (this.isApplyToAll) {
          this.set('sharedDateRange', option);
        }
        if (option.to && option.from) {
          this.selectRange(option);
        }
      }
    }
  });
  _exports.default = _default;
});