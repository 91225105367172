define("experience-studio/components/av5-info-card-advanced-engagement", ["exports", "experience-studio/mixins/analytics-data", "experience-studio/components/av5-info-card-base"], function (_exports, _analyticsData, _av5InfoCardBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Displays single discrete time statistics using av5-info-card-base's logic
  
    Usages:
  
      {{av5-info-card-advanced-engagement
        title=''
        subtitle=''
        requests=[{
          domain='',
          endpoint='',
          from='',
          to='',
        }]}}
  
  */
  var _default = _av5InfoCardBase.default.extend(_analyticsData.default, {
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-info-card-advanced-engagement', 'av5-info-card'],
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    // Analtics export feature flag remove
    // Content Analytics Flag
    userPreferences: Ember.inject.service(),
    // visual
    displayToDate: '',
    displayFromDate: '',
    // date dropdown parameters
    dateRange: null,
    selectedDateOption: null,
    sharedDateParams: null,
    // search box
    searchKeyword: '',
    searchDebounceTime: 600,
    // metadata
    errorCode: '',
    hasNetworkError: false,
    isLoading: false,
    // table variables
    tableData: null,
    tableHeaders: null,
    tableRequest: null,
    tableTotalRecords: 0,
    isTableLoading: false,
    allLabels: null,
    labels: null,
    labelsOperator: 'AND',
    fetchDataCall: null,
    cardRequest: null,
    cardsData: null,
    isCardLoading: null,
    // COMPUTED ------------------------------------------------------------------
    isCustom: Ember.computed('selectedDateOption.name', function () {
      return this.selectedDateOption.name.toLowerCase() === 'custom';
    }),
    isExpandableTable: Ember.computed('dataType', 'userPreferences.preferences.[]', function () {
      // Content Analytics Flag: remove user pref check
      return this.dataType === 'content' && this.userPreferences.getValue('contentAnalytics');
    }),
    observeLabelChanges: Ember.observer('labels.@each.isSelected', 'labelsOperator', 'isApplyLabelsToAll', function () {
      Ember.run.debounce(this, this.configureTableRequestObject, 200);
    }),
    // Analtics export feature flag remove
    dateRangeDropdownClass: Ember.computed('title', function () {
      return this.title ? "".concat(this.title.toLocaleLowerCase().split(' ').join('-'), "-date-dropdown") : '';
    }),
    labelDropdownClass: Ember.computed('title', function () {
      return this.title ? "".concat(this.title.toLocaleLowerCase().split(' ').join('-'), "-label-dropdown") : '';
    }),
    // Analtics export feature flag remove
    isAnalyticsExportEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      return true; // remove ff from template later
    }),

    labelsOperatorDesc: Ember.computed('labelsOperator', function () {
      switch (this.labelsOperator) {
        case 'AND':
          return "Contains all of";
        case 'OR':
          return "Contains any of";
        default:
          return "";
      }
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      var headers = this.getTableHeaders(this.dataType);
      var defaultSortBy = headers.find(function (header) {
        return header.isSelected === true;
      }).sortByVar;
      var tableRequest = this.requests.filter(function (request) {
        return !request.display || request.display === 'table';
      }).objectAt(0);
      var cardRequest = this.requests.find(function (request) {
        return request.display === 'card';
      });
      tableRequest.queryParams.set('sortBy', defaultSortBy);
      // TODO: inject card request data here.
      this.setProperties({
        cardRequest: cardRequest,
        cardsData: [],
        // Analtics export feature flag remove
        dateRange: this.dateRangeConstructor(),
        tableHeaders: headers,
        tableRequest: tableRequest,
        fetchDataCall: null
      });

      // Analtics export feature flag remove
      if (!this.selectedDateOption) {
        this.dateRange.objectAt(0).set('isSelected', true);
        this.set('selectedDateOption', this.dateRange.objectAt(0));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      // Analtics export feature flag remove if
      if (this.isAnalyticsExportEnabled) {
        var currentTableRequest = JSON.stringify(this.tableRequest);

        // TODO: sketchy
        this.requests.forEach(function (request) {
          request.queryParams.setProperties({
            timeFrom: _this.fromDate,
            timeTo: _this.toDate
          });
        });
        this.tableRequest.queryParams.set('offset', 0);
        if (currentTableRequest !== JSON.stringify(this.tableRequest)) {
          this.setProperties({
            isLoading: true,
            displayFromDate: window.moment(this.fromDate).format('ddd, MMM DD, YYYY'),
            displayToDate: window.moment(this.toDate).format('ddd, MMM DD, YYYY')
          });
          this.generateRequestStructure().then(function (resArr) {
            if (_this.checkErrors(resArr)) {
              _this.rejectResponses(resArr);
            } else {
              _this.resolveResponses(resArr);
            }
          });
        }
      }
    },
    // FUNCTIONS -----------------------------------------------------------------
    configureTableRequestObject: function configureTableRequestObject() {
      var _this2 = this;
      var labelStr = '';
      if (!Ember.isEmpty(this.labels)) {
        if (this.labelsOperator === 'OR') {
          labelStr = this.labels.getEach('name').join(';');
        } else {
          labelStr = this.labels.getEach('name').join(',');
        }
        labelStr = "(".concat(labelStr, ")");
      }
      this.tableRequest.queryParams.setProperties({
        labels: labelStr,
        op: this.labelsOperator
      });
      this.generateRequestStructure().then(function (resArr) {
        if (_this2.checkErrors(resArr)) {
          _this2.rejectResponses(resArr);
        } else {
          _this2.resolveResponses(resArr);
        }
      });
    },
    // Analtics export feature flag remove
    dateRangeConstructor: function dateRangeConstructor() {
      var durations = [7, 30, 90];
      var option = null;
      var currentDayStartTime = window.moment().subtract(1, 'days').endOf('day').unix() * 1000;
      var retVal = durations.map(function (duration) {
        option = Ember.Object.create({
          name: "Last ".concat(duration, " days"),
          isSelected: false,
          to: currentDayStartTime,
          from: window.moment().subtract(duration, 'days').startOf('day').unix() * 1000
        });
        return option;
      });
      retVal.unshiftObject(Ember.Object.create({
        name: 'Today',
        isSelected: false,
        to: window.moment().endOf('day').unix() * 1000,
        from: window.moment().startOf('day').unix() * 1000
      }));
      return retVal;
    },
    generateRequestStructure: function generateRequestStructure() {
      this.fetchDataCall = this.fetchData(this.serializeQueryParams(this.tableRequest, this.dataType));
      var requestWrapper = [this.processTableRequest(this.tableRequest)];
      if (this.tableRequest.endpoint === 'engagement/push') {
        requestWrapper.push(this.processCardRequest(this.tableRequest));
      }
      return Ember.RSVP.allSettled(requestWrapper);
    },
    processTableRequest: function processTableRequest() {
      var _this3 = this;
      return new Promise(function (resolve, reject) {
        _this3.fetchDataCall.then(function (res) {
          if (res.pagination) {
            _this3.tableRequest.queryParams.setProperties({
              sortBy: res.pagination.sortBy,
              sortOrder: res.pagination.sortOrder,
              limit: res.pagination.limit,
              offset: res.pagination.offset
            });
            _this3.setProperties({
              tableTotalRecords: res.pagination.totalRecords
            });
          }
          _this3.normalizeTableData(res, _this3.dataType, _this3.store).then(function (normRes) {
            resolve(normRes);
          }).catch(function (err) {
            reject(err);
          });
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    processCardRequest: function processCardRequest() {
      var _this4 = this;
      return new Promise(function (resolve, reject) {
        _this4.fetchDataCall.then(function (res) {
          if (_this4.dataType === 'push') {
            _this4.normalizeCardData(res, _this4.dataType, _this4.store).then(function (normRes) {
              resolve(normRes);
            }).catch(function (err) {
              reject(err);
            });
          }
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    resolveResponses: function resolveResponses(resArr) {
      var cardsData = [];
      if (!Ember.isEmpty(resArr)) {
        resArr.forEach(function (item) {
          if (item.state === 'fulfilled') {
            var cardTypes = item.value.filter(function (val) {
              return val.cardType;
            });
            if (!Ember.isEmpty(item.value) && !Ember.isEmpty(cardTypes)) {
              item.value.forEach(function (val) {
                cardsData.pushObject(val);
              });
            }
          }
        });

        // TODO: add logic for cards
        this.setProperties({
          cardsData: cardsData,
          tableData: resArr.objectAt(0).value,
          hasNetworkError: false,
          isLoading: false,
          isTableLoading: false
        });
      }
    },
    rejectResponses: function rejectResponses(resArr) {
      var err = this.getLastError(resArr);
      this.setProperties({
        hasNetworkError: true,
        isLoading: false,
        isTableLoading: false,
        errorCode: err.status
      });
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      addOrRemoveLabelToArrays: function addOrRemoveLabelToArrays(cards, label, action) {
        cards.forEach(function (card) {
          if (action === 'add') {
            var localLabel = card.allLabels.filterBy('name', label.name)[0];
            if (!localLabel) {
              localLabel = Ember.Object.create({
                isSelected: true,
                name: label.name
              });
              card.allLabels.pushObject(localLabel);
            } else {
              localLabel.set('isSelected', true);
            }
            card.labels.pushObject(localLabel);
          } else if (action === 'remove') {
            var _localLabel = card.labels.filterBy('name', label.name)[0];
            if (_localLabel) {
              _localLabel.set('isSelected', false);
              card.labels.removeObject(_localLabel);
            }
          }
        });
      },
      didSelectPage: function didSelectPage(pageNumber) {
        this.set('selectedPage', pageNumber);
      },
      removeLabel: function removeLabel(label) {
        var _this5 = this;
        var cards = this.analyticsCards;
        if (!this.get('isApplyLabelsToAll')) {
          cards = cards.filter(function (card) {
            return card.labels === _this5.labels;
          });
        }
        this.send('addOrRemoveLabelToArrays', cards, label, 'remove');
      },
      // Analtics export feature flag remove
      selectRange: function selectRange(opt) {
        var _this6 = this;
        if (!this.isAnalyticsExportEnabled) {
          this.setProperties({
            isLoading: true,
            displayFromDate: window.moment(opt.from).format('ddd, MMM DD, YYYY'),
            displayToDate: window.moment(opt.to).format('ddd, MMM DD, YYYY')
          });

          // TODO: sketchy
          this.requests.forEach(function (request) {
            request.queryParams.setProperties({
              timeFrom: opt.from,
              timeTo: opt.to
            });
          });
          this.tableRequest.queryParams.set('offset', 0);
          this.generateRequestStructure().then(function (resArr) {
            if (_this6.checkErrors(resArr)) {
              _this6.rejectResponses(resArr);
            } else {
              _this6.resolveResponses(resArr);
            }
          });
        }
      },
      updateSearchKeyword: function updateSearchKeyword() {
        var _this7 = this;
        if (this.requests.objectAt(0).search !== this.searchKeyword) {
          this.requests.forEach(function (request) {
            request.queryParams.set('search', _this7.searchKeyword);
          });
          this.tableRequest.queryParams.set('offset', 0);
          this.send('updateFull');
        }
      },
      updateTable: function updateTable() {
        var _this8 = this;
        this.set('isTableLoading', true);
        this.fetchDataCall = this.fetchData(this.serializeQueryParams(this.tableRequest, this.dataType));
        this.processTableRequest(this.tableRequest).then(function (res) {
          _this8.setProperties({
            tableData: res,
            hasNetworkError: false,
            isLoading: false,
            isTableLoading: false
          });
        }).catch(function (err) {
          _this8.setProperties({
            hasNetworkError: true,
            isLoading: false,
            isTableLoading: false,
            errorCode: err.status
          });
        });
      },
      updateFull: function updateFull() {
        var _this9 = this;
        this.setProperties({
          hasNetworkError: false,
          isLoading: true
        });
        this.generateRequestStructure().then(function (resArr) {
          if (_this9.checkErrors(resArr)) {
            _this9.rejectResponses(resArr);
          } else {
            _this9.resolveResponses(resArr);
          }
        });
      },
      refresh: function refresh() {
        window.location.reload(true);
      }
    }
  });
  _exports.default = _default;
});