define("experience-studio/components/context-hub-create-schedule", ["exports", "experience-studio/mixins/time-conversion"], function (_exports, _timeConversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_timeConversion.default, {
    classNames: ['context-hub-create-schedule'],
    timeDelay: Ember.Object.create({
      param: 'seconds',
      val: 1
    }),
    dateComparisonOperators: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      label: 'after',
      value: '+'
    }), Ember.Object.create({
      label: 'before',
      value: '-'
    })],
    frequencyTypes: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      label: 'Once',
      value: 'once'
    }), Ember.Object.create({
      label: 'Second',
      value: 'second'
    }), Ember.Object.create({
      label: 'Minute',
      value: 'minute'
    }), Ember.Object.create({
      label: 'Hour',
      value: 'hour'
    }), Ember.Object.create({
      label: 'Day',
      value: 'day'
    }), Ember.Object.create({
      label: 'Week',
      value: 'week'
    }), Ember.Object.create({
      label: 'Month',
      value: 'month'
    }), Ember.Object.create({
      label: 'Year',
      value: 'year'
    })],
    scheduleTypes: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      label: 'One Time',
      value: 'onetime'
    }), Ember.Object.create({
      label: 'Recurring',
      value: 'recurring'
    })],
    startTypes: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      label: 'based on date and time',
      value: 'noncontextual'
    }), Ember.Object.create({
      label: 'based on context',
      value: 'contextual'
    })],
    isContextualStart: Ember.computed('selectedStartType', function () {
      return this.get('selectedStartType.value') === 'contextual';
    }),
    isSelectedScheduleTypeOneTime: Ember.computed('selectedScheduleType', function () {
      return this.get('selectedScheduleType.value') === 'onetime';
    }),
    observeSelectedScheduleType: Ember.observer('selectedScheduleType', function () {
      this.setProperties({
        selectedStartType: null,
        startDate: null,
        startTime: null
      });
      this.set('model.schedule.startScheduler.repeat.isRepeat', false);
      this.set('model.schedule.hasEndScheduler', false);
    }),
    pluginsWithDateTimeAttributes: Ember.computed('plugins.[]', function () {
      if (this.plugins && this.plugins.length) {
        return this.plugins.filterBy('hasDateTimeAttribute', true);
      } else {
        return [];
      }
    }),
    selectedPluginAttributes: Ember.computed('selectedPlugin', function () {
      var attributes = [];
      if (this.selectedPlugin) {
        var selectedPluginAttributes = this.selectedPlugin.attributes;
        for (var property in selectedPluginAttributes) {
          if (selectedPluginAttributes.hasOwnProperty(property)) {
            var attribute = selectedPluginAttributes[property];
            if (attribute.isTimeContext) {
              attributes.pushObject(attribute);
            }
          }
        }
      }
      return attributes;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('input[type="date"]').flatpickr({
        altInput: true,
        enableTime: true,
        minDate: window.moment().add(2, 'minutes').format('YYYY-MM-DD HH:mm')
      });
      this.set('periodSelectors', [Ember.Object.create({
        name: 'Second(s)',
        unit: 'seconds'
      }), Ember.Object.create({
        name: 'Minute(s)',
        unit: 'minutes'
      }), Ember.Object.create({
        name: 'Hour(s)',
        unit: 'hours'
      }), Ember.Object.create({
        name: 'Day(s)',
        unit: 'days'
      }), Ember.Object.create({
        name: 'Week(s)',
        unit: 'weeks'
      }), Ember.Object.create({
        name: 'Month(s)',
        unit: 'months'
      }), Ember.Object.create({
        name: 'Year(s)',
        unit: 'years'
      })]);
      var dayPeriod = this.periodSelectors.filterBy('name', 'Day(s)').objectAt(0);
      this.set('selectedPeriod', dayPeriod);
      var seconds = this.convertToSeconds(1, 'days');
      this.set('model.schedule.numberOfMinutes', seconds);
      this.set('model.schedule.startScheduler.repeat.frequencyType', this.frequencyTypes.objectAt(0));
    },
    actions: {
      selectPluginValue: function selectPluginValue() {
        this.set('model.schedule.selectedPluginAttribute', null);
        this.send.apply(this, ['selectValue'].concat(Array.prototype.slice.call(arguments)));
      },
      selectValue: function selectValue(model, variable, selection) {
        if (model) {
          model.set(variable, selection);
        } else {
          this.set(variable, selection);
        }
      },
      setBeforeAfterTime: function setBeforeAfterTime() {
        var time = this.timeDelay.val;
        var period = this.get('selectedPeriod');
        var seconds = this.convertToSeconds(time, period.unit);
        this.set('model.schedule.numberOfMinutes', seconds);
        this.timeDelay.setProperties({
          param: period.param,
          val: time
        });
      },
      selectPeriod: function selectPeriod(period) {
        this.set('selectedPeriod', period);
        var time = this.timeDelay.val;
        var seconds = this.convertToSeconds(time, period.unit);
        this.set('model.schedule.numberOfMinutes', seconds);
        this.timeDelay.setProperties({
          param: period.param,
          val: time
        });
      }
    }
  });
  _exports.default = _default;
});