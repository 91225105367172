define("experience-studio/components/route-model-table-location-data", ["exports", "experience-studio/components/route-model-table-entity"], function (_exports, _routeModelTableEntity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelTableEntity.default.extend({
    classNames: ['route-model-table-location-data'],
    data: null,
    headers: null,
    utility: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('headers', [Ember.Object.create({
        label: 'Name',
        sortProperty: 'name',
        isSelected: false,
        class: 'name-col',
        isHidden: false,
        tooltip: 'Name of Location'
      }), Ember.Object.create({
        label: 'Address',
        isSelected: false,
        class: 'address-col',
        isHidden: false,
        tooltip: "Address of Location"
      }), Ember.Object.create({
        label: 'Labels',
        isSelected: false,
        class: 'labels-col',
        isHidden: false,
        tooltip: 'Labels associated with Location'
      }), Ember.Object.create({
        label: 'Date Created',
        sortProperty: 'createdAt',
        isSelected: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'Date when Location was created'
      })]);
    }
  });
  _exports.default = _default;
});