define("experience-studio/components/fb/dropdown-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn', 'btn-toggle'],
    classNameBindings: ['showArrow'],
    didInsertElement: function didInsertElement() {
      this.element.setAttribute('data-toggle', 'dropdown');
    },
    showArrow: Ember.computed('arrow', function () {
      if (this.arrow) {
        return 'arrow';
      }
    })
  });
  _exports.default = _default;
});