define("experience-studio/adapters/permission-role", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'sso',
    utility: Ember.inject.service(),
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace('permission-roles', 'permissions/roles');
    },
    deleteRecord: function deleteRecord() {
      return this._super.apply(this, arguments).then(function () {
        return null;
      });
    }
  });
  _exports.default = _default;
});