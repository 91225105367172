define("experience-studio/templates/content-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kIDUZEpI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-hub-route\"],[12],[2,\"\\n\\n\"],[6,[37,8],null,[[\"deselectModelListItems\",\"didUpdateSearchKeyword\",\"headerTitle\",\"isInfoSidebarOpen\",\"languages\",\"mainHeaderTabs\",\"selectedEntities\",\"performAction\",\"searchKeyword\",\"sideBarTitle\"],[\"deselectModelListItems\",\"didUpdateSearchKeyword\",\"Content Hub\",[35,7],[35,6,[\"languages\",\"config\"]],[35,5],[35,4],[30,[36,3],[\"performAction\"],null],[35,2],\"Content\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"searchKeyword\",\"route-action\",\"selectedEntities\",\"mainHeaderTabs\",\"model\",\"isInfoSidebarOpen\",\"main-header-info-sidebar\"]}",
    "moduleName": "experience-studio/templates/content-hub.hbs"
  });
  _exports.default = _default;
});