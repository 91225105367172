define("experience-studio/components/label-select-dropdown-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['label-select-dropdown-item', 'dropdown-item'],
    tagName: 'li',
    attributeBindings: ['listIndex:tabindex', 'ariaCurrent:aria-current'],
    // eslint-disable-line  ember/avoid-leaking-state-in-ember-objects

    label: null,
    listIndex: 0,
    currentIndex: 0,
    ariaCurrent: Ember.computed('listIndex', 'currentIndex', function () {
      return String(this.listIndex === this.currentIndex);
    }),
    click: function click() {
      this.onclick(this.label);
    }
  });
  _exports.default = _default;
});