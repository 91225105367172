define("experience-studio/serializers/user-preference", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    utility: Ember.inject.service(),
    attrs: {
      key: {
        serialize: false
      },
      categoryKey: {
        serialize: false
      },
      groupKey: {
        serialize: false
      },
      typeKey: {
        serialize: false
      }
    },
    preferenceIds: null,
    preferenceRoot: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.preferenceIds = ['activateExperienceConfirmationModal', 'analyticsPageV1', 'analyticsPageV2', 'analyticsPageV5', 'batchActionBarDeselectAll', 'experienceStudioExpressLink', 'isHistoricalContextPluginEditorVisible', 'locationV2', 'locationV2AutoGenerateLabels', 'locationV2ContextRule', 'nestorLink', 'permissionRolesPage', 'routeListView', 'userPreferencePage', 'pushMessageV1', 'pushMessageV2Triggered', 'newAnalyticUpdate', 'templatedExperiences', 'analyticsExport', 'contentAnalytics', 'pushMessagesDefaultLanguage', 'newSSOHost', 'richTextFormatting', 'dynamicAttribute', 'locationContent'];

      // Content Analytics Blacklisting tenants
      var domain = window.location.origin;
      var blacklist = ['https://members1st.flybits.com', 'https://canadalife.canada.flybits.com', 'https://tdcanada3.flybits.com', 'https://td-us.flybits.com', 'https://tdi-prod.flybits.com', 'https://tdpat3.flybits.com', 'https://prod-boschnext.flybits.com', 'https://fivepoint.flybits.com'];
      if (!blacklist.includes(domain)) {
        this.preferenceIds.push('contentAnalytics');
      }
    },
    _normalizeResourceHelper: function _normalizeResourceHelper(resourceHash) {
      resourceHash.type = 'user-preferences';
      return this._super(resourceHash);
    },
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;
      var formattedPayload = {
        data: []
      };
      var experienceStudioUI = [];
      this.preferenceRoot = payload;
      if (payload && payload.data && payload.data.experienceStudioUI && Array.isArray(payload.data.experienceStudioUI)) {
        experienceStudioUI = payload.data.experienceStudioUI;
      }
      this.preferenceIds.forEach(function (id) {
        var filteredItems = experienceStudioUI.filter(function (item) {
          return item && item.id === id;
        });
        if (filteredItems.length > 0) {
          filteredItems[0].createdAt = payload.createdAt;
          filteredItems[0].modifiedAt = payload.modifiedAt;
        } else {
          filteredItems = [{
            createdAt: payload.createdAt,
            modifiedAt: payload.modifiedAt
          }];
        }
        formattedPayload.data.push(_this.normalizePreference(id, filteredItems[0]));
      });
      return this._super(store, primaryModelClass, formattedPayload, id, requestType);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var formattedPayload = {};
      if (payload && payload.data && payload.data.experienceStudioUI) {
        var filteredItems = payload.data.experienceStudioUI.filter(function (item) {
          return item && item.id === id;
        });
        if (filteredItems.length > 0) {
          filteredItems[0].createdAt = payload.createdAt;
          filteredItems[0].modifiedAt = payload.modifiedAt;
          formattedPayload = this.normalizePreference(id, filteredItems[0] || {});
        }
      }
      return this._super(store, primaryModelClass, {
        data: formattedPayload
      }, id, requestType);
    },
    normalizePreference: function normalizePreference(id, hash) {
      var formattedHash = {
        attributes: {},
        id: id,
        type: 'user-preferences'
      };
      var attributes = formattedHash.attributes;
      attributes.name = hash.name || '';
      attributes.description = hash.description || '';
      attributes.category = hash.category || '';
      attributes.hasOptIn = !!hash.hasOptIn;
      attributes.hasViewedTour = !!hash.hasViewedTour;
      attributes.value = hash.value;
      attributes.createdAt = hash.createdAt;
      attributes.modifiedAt = hash.modifiedAt || hash.updatedAt || hash.createdAt;
      if (id === 'activateExperienceConfirmationModal') {
        attributes.name = 'Batch Action Bar - Experience - Activate/Deactivate';
        attributes.description = 'Show confirmation dialog when activating or deactivating Experience';
        attributes.category = 'Confirmation Dialog';
        attributes.value = true;
      } else if (id === 'analyticsPageV1') {
        attributes.name = 'Analytics Page v1';
        attributes.description = 'You can view analytics for Context Rule entity.';
        attributes.category = 'Confirmation Dialog';
      } else if (id === 'analyticsPageV2') {
        attributes.name = 'Analytics Page v2';
        attributes.description = 'You can view analytics for Context Rule, Content, and Push Notification entity.';
        attributes.category = 'Confirmation Dialog';
      } else if (id === 'analyticsPageV5') {
        attributes.name = 'Analytics Dashboard V5';
        attributes.description = 'You can view analytics for user and engagement based metrics';
        attributes.category = 'Confirmation Dialog';
      } else if (id === 'batchActionBarDeselectAll') {
        attributes.name = 'Batch Action Bar - Deselect All';
        attributes.description = 'Show confirmation dialog when deselecting all entities from batch action bar';
        attributes.category = 'Confirmation Dialog';
      } else if (id === 'experienceStudioExpressLink') {
        attributes.name = 'Experience Studio Express';
        attributes.description = 'Display "Experience Studio Express" option in User\'s dropdown menu to navigate to Experience Studio Express UI.';
        attributes.category = 'feature';
      } else if (id === 'nestorLink') {
        attributes.name = 'Nestor';
        attributes.description = 'Display "Nestor" option in User\'s dropdown menu to navigate to Nestor UI.';
        attributes.category = 'feature';
      } else if (id === 'isHistoricalContextPluginEditorVisible') {
        attributes.name = 'Allow user to configure Historical Context plugin';
        attributes.description = 'You can create Context Rule using Historical Context plugin.';
        attributes.category = 'Feature';
      } else if (id === 'locationV2') {
        attributes.name = 'Locations V2 - Uploads Page';
        attributes.description = 'You can create, edit, update, and delete locations. You can also upload CSV file to create locations in bulk.';
        attributes.category = 'Feature';
      } else if (id === 'locationV2ContextRule') {
        attributes.name = 'Locations V2 - Context Rule';
        attributes.description = 'You can create Context Rule using new location plugin.';
        attributes.category = 'Feature';
      } else if (id === 'locationV2AutoGenerateLabels') {
        attributes.name = 'Locations V2 - Auto Generate Labels';
        attributes.description = 'In Create Location modal dialog when you are done creating shape, the system will auto generate label based on shape\'s location.';
        attributes.category = 'Feature';
      } else if (id === 'permissionRolesPage') {
        attributes.name = 'Permissions Role Page';
        attributes.description = 'You can create, edit, update, and delete Permission Roles and allow associate/disassociate users from Permision Roles.';
        attributes.category = 'Feature';
      } else if (id === 'routeListView') {
        attributes.name = 'List View';
        attributes.description = 'For all pages, display entities in a list view.';
        attributes.category = 'Feature';
      } else if (id === 'userPreferencePage') {
        attributes.name = 'User Preferences Page';
        attributes.description = 'Display "Settings" option in User\'s dropdown menu to allow access to this (Preferences) page.';
        attributes.category = 'Feature';
      } else if (id === 'pushMessageV1') {
        attributes.name = 'Push Messages Page V1';
        attributes.description = 'You can see spotlight wizard for broadcast push notification scheduling';
        attributes.category = 'Confirmation Dialog';
      } else if (id === 'pushMessageV2Triggered') {
        attributes.name = 'Push Messages Page V2 Triggered';
        attributes.description = 'You can see spotlight wizard for triggered push notification';
        attributes.category = 'Confirmation Dialog';
      } else if (id === 'newAnalyticUpdate') {
        attributes.name = 'Enable Analytics Dashboard Update';
        attributes.description = 'You can set to show new updates to analytics dashboard';
        attributes.category = 'Feature';
      } else if (id === 'templatedExperiences') {
        attributes.name = 'Enable Templated Experiences';
        attributes.description = 'You can set to show Templated Experiences in Experiences section';
        attributes.category = 'Feature';
      } else if (id === 'analyticsExport') {
        attributes.name = 'Enable Analytics Export';
        attributes.description = 'You can set to show Analytics Export in Analytics Engagement section';
        attributes.category = 'Feature';
      } else if (id === 'newSSOHost') {
        attributes.name = 'Enable the New SSO UI';
        attributes.description = 'You can set to show New SSO UI in Experiences section';
        attributes.category = 'Feature';
      } else if (id === 'contentAnalytics') {
        attributes.name = 'Enable Content Analytics';
        attributes.description = 'You can set to show Content Analytics in analtyics engagement content table';
        attributes.category = 'Feature';
      } else if (id === 'pushMessagesDefaultLanguage') {
        attributes.name = 'Enable Default Language Notification';
        attributes.description = 'You can enable default language notification to the end user in the Push Notification Page in Message section.';
        attributes.category = 'Feature';
      } else if (id === 'richTextFormatting') {
        attributes.name = 'Enable Rich Text Formatting';
        attributes.description = 'You can enable rich text formatting feature for all the paragraph in Experience Studio.';
        attributes.category = 'Feature';
      } else if (id === 'dynamicAttribute') {
        attributes.name = 'Enable Dynamic Attribute';
        attributes.description = 'You can enable Dynamic Attribute feature in Content Hub and Push Notification in Experience Studio.';
        attributes.category = 'Feature';
      } else if (id === 'locationContent') {
        attributes.name = 'Enable Location Content';
        attributes.description = 'You can set to show Location Content tab in the Content Hub Page.';
        attributes.category = 'Feature';
      }
      if (!hash.name) {
        attributes.name = Ember.String.capitalize(id).replace(/([a-z])([A-Z])/g, '$1 $2');
      }
      return formattedHash;
    },
    serialize: function serialize() {
      var experienceStudioUI = [];
      var json = {
        id: this.preferenceRoot.id,
        data: {
          experienceStudioUI: []
        }
      };
      var records = this.store.peekAll('user-preference');
      this.preferenceIds.forEach(function (id) {
        var filteredItems = records.filter(function (item) {
          return item && item.id === id;
        });
        if (filteredItems.length > 0) {
          var hash = filteredItems[0].toJSON();
          hash.id = id;
          experienceStudioUI.push(hash);
        }
      });
      if (Object.keys(this.preferenceRoot.data).length > 0) {
        json.data = this.preferenceRoot.data;
      }
      json.data['experienceStudioUI'] = experienceStudioUI;
      return json;
    }
  });
  _exports.default = _default;
});