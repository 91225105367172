define("experience-studio/components/info-sidebar-location", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar-location'],
    didRender: function didRender() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
      this._super.apply(this, arguments);
    },
    mapImageUrl: Ember.computed('model.shape', function () {
      var coordinates = [];
      var endpoint = 'maps/api/staticmap';
      var host = 'https://maps.googleapis.com';
      try {
        this.model.shape.forEach(function (coordinate) {
          return coordinates.push("".concat(coordinate.lat, ",").concat(coordinate.lng));
        });
      } catch (exception) {
        // TODO: Show exception
      }
      var query = {
        key: _environment.default.APP.googleMapKey,
        path: "color:0x29BFEDFF|weight:2|".concat(coordinates.join('|')),
        size: '200x200',
        scale: 2
      };
      return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query));
    }),
    hasAssociations: Ember.computed('model.{computedGroups,computedRule,computedContents}', function () {
      var model = this.model;
      return model.computedGroups || model.computedRule || model.computedContents;
    }),
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});