define("experience-studio/components/content-tmp-edit-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    address: null,
    classNames: ['content-tmp-edit-location'],
    isCustomVisible: false,
    isLoading: false,
    isMapVisible: false,
    isPlacesVisible: true,
    latitude: null,
    longitude: null,
    marker: null,
    isLocationContent: Ember.computed('location.href', function () {
      return location.href.indexOf('blueprint') > -1;
    }),
    observeCoordiantes: Ember.observer('field.value.{latitude,longitude}', function () {
      var latitude = this.field.value.get('latitude');
      var longitude = this.field.value.get('longitude');
      var marker = this.marker;
      if (marker) {
        var markerLatitude = marker.getPosition().lat();
        var markerLongitude = marker.getPosition().lng();
        if (markerLatitude !== latitude && markerLongitude !== longitude) {
          marker.setPosition({
            lat: latitude,
            lng: longitude
          });
          marker.getMap().fitBounds(new window.google.maps.LatLngBounds(marker.getPosition()));
        }
      }
    }),
    updateFieldValue: function updateFieldValue(data) {
      if (data.latitude && data.longitude) {
        this.field.value.setProperties({
          latitude: data.latitude,
          longitude: data.longitude
        });
      }
      if (data.address) {
        this.field.value.set('address', data.address);
      }
    },
    actions: {
      didClickMap: function didClickMap(googleMaps, event) {
        var _this = this;
        var marker = this.marker;
        if (marker) {
          googleMaps.get('gmaps.event').clearListeners(marker);
          googleMaps.removeMarker(marker);
        }
        marker = googleMaps.createMarker({
          position: event.latLng
        });
        marker.setDraggable(true);
        googleMaps.addListener(marker, 'click', function () {
          googleMaps.clearInstanceListeners(marker);
          marker.setMap(null);
          _this.setProperties({
            latitude: null,
            longitude: null
          });
        });
        marker.addListener('dragend', function (event) {
          _this.setProperties({
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng()
          });
        });
        this.set('marker', marker);
        this.updateFieldValue({
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng()
        });
      },
      didChangePlace: function didChangePlace() {},
      toggleUI: function toggleUI(property) {
        this.set(property, true);
        if (property === 'isMapVisible') {
          this.setProperties({
            isCustomVisible: false,
            isPlacesVisible: false
          });
        } else if (property === 'isPlacesVisible') {
          this.setProperties({
            isCustomVisible: false,
            isMapVisible: false
          });
        } else if (property === 'isCustomVisible') {
          this.setProperties({
            isMapVisible: false,
            isPlacesVisible: false
          });
        }
      }
    }
  });
  _exports.default = _default;
});