define("experience-studio/templates/components/device-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MVprF0IA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"device-preview-options\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"btn btn-light \",[30,[36,7],[[30,[36,6],[[35,4],\"ios\"],null],\"active\"],null]]]],[24,\"aria-label\",\"Show device preview\"],[4,[38,8],[[32,0],\"selectPreview\",\"ios\"],null],[12],[2,\"\\n    \"],[1,[30,[36,9],[\"apple\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"btn btn-light \",[30,[36,7],[[30,[36,6],[[35,4],\"android\"],null],\"active android\"],null]]]],[24,\"aria-label\",\"Show device preview\"],[4,[38,8],[[32,0],\"selectPreview\",\"android\"],null],[12],[2,\"\\n    \"],[1,[30,[36,9],[\"android-bot\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"btn btn-light \",[30,[36,7],[[30,[36,6],[[35,4],\"code\"],null],\"active\"],null]]]],[24,\"aria-label\",\"Show code preview\"],[4,[38,8],[[32,0],\"selectPreview\",\"code\"],null],[12],[2,\"\\n    \"],[1,[30,[36,9],[\"code\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,10],[[30,[36,6],[[35,4],\"ios\"],null],[30,[36,6],[[35,4],\"android\"],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"languages\",\"model\",\"deviceType\",\"plugins\"],[[35,1,[\"languages\",\"config\"]],[35,1,[\"pushMessage\"]],[35,4],[35,3]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"languages\",\"model\",\"fields\"],[[35,1,[\"languages\",\"config\"]],[35,1,[\"pushMessage\"]],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectedMessageTemplateFields\",\"model\",\"push-messages-create--code-preview\",\"plugins\",\"deviceType\",\"push-messages-create--device-preview\",\"eq\",\"if\",\"action\",\"svg-jar\",\"or\"]}",
    "moduleName": "experience-studio/templates/components/device-preview.hbs"
  });
  _exports.default = _default;
});