define("experience-studio/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8jQeswLa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route settings-route\"],[12],[2,\"\\n\\n\"],[6,[37,3],null,[[\"headerTitle\",\"isInfoSidebarOpen\",\"isSearchFormHidden\",\"showInfoButton\",\"selectedEntities\"],[\"Preferences\",false,true,false,[35,2]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"selectedEntities\",\"main-header-info-sidebar\"]}",
    "moduleName": "experience-studio/templates/settings.hbs"
  });
  _exports.default = _default;
});