define("experience-studio/helpers/number-ordinal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.numberOrdinal = numberOrdinal;
  function numberOrdinal(params) {
    var nth = function nth(d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'nth';
      }
    };
    return nth(parseInt(params.objectAt(0)));
  }
  var _default = Ember.Helper.helper(numberOrdinal);
  _exports.default = _default;
});