define("experience-studio/components/route-model-table-entity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    model: null,
    headers: null,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    utility: Ember.inject.service(),
    showTable: Ember.computed('model.length', 'isLoading', function () {
      return this.isLoading || this.model.length > 0;
    }),
    searchKeyword: Ember.computed('data', function () {
      var keyword = '';
      if (this.utility.atob(this.data) && this.utility.atob(this.data).searchKeyword) {
        var data = this.utility.atob(this.data);
        if (data.searchKeyword) {
          keyword = data.searchKeyword;
        }
      }
      return keyword;
    }),
    didInsertElement: function didInsertElement() {
      if (!Ember.isEmpty(this.data)) {
        var data = this.utility.atob(this.data);
        this.setProperties({
          sortBy: data.sortBy || this.sortBy,
          sortOrder: data.sortOrder || this.sortOrder,
          searchKeyword: data.searchKeyword || this.searchKeyword
        });
      }
      this._super.apply(this, arguments);
    },
    actions: {
      createEntity: function createEntity() {
        this.createEntity();
      },
      performModelAction: function performModelAction(params) {
        return this.performModelAction(params);
      },
      onToggleRow: function onToggleRow(data) {
        this.onToggleRow(data);
      }
    }
  });
  _exports.default = _default;
});