define("experience-studio/templates/content-hub-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OREYa1hO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-hub-loading-route loading-route\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"spinner-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"spinner\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "experience-studio/templates/content-hub-loading.hbs"
  });
  _exports.default = _default;
});