define("experience-studio/routes/permissions-hub/users", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-table-actions-base"], function (_exports, _authenticatedRoute, _routeDataQueryParam, _routeModelTableActionsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeDataQueryParam.default, _routeModelTableActionsBase.default, {
    utility: Ember.inject.service(),
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          errorMessage: '',
          searchKeyword: ''
        });
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        permissionsHubController: this.controllerFor('permissions-hub')
      });
    },
    actions: {
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.get('searchKeyword'));
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
        this.send('updateQueryParams', {
          searchKeyword: searchObject.keyword
        });
      },
      editRecord: function editRecord(record) {
        this.send('showAccessLevelModal', record);
      },
      processModelListAction: function processModelListAction(action, model) {
        switch (action) {
          case 'edit':
            this.send('editRecord', model);
            break;
          default:
            this._super.apply(this, arguments);
            break;
        }
      },
      selectModelListItems: function selectModelListItems(params) {
        params.selectedEntitiesPath = 'permissionsHubController.selectedEntities';
        this._super(params);
      },
      deselectModelListItems: function deselectModelListItems() {
        this._super({
          selectedEntitiesPath: 'permissionsHubController.selectedEntities'
        });
      },
      showAccessLevelModal: function showAccessLevelModal(model) {
        this.controller.set('accessLevelModalData', Ember.Object.create({
          accessLevels: this.store.findAll('access-level'),
          user: model.record
        }));
      },
      viewInfo: function viewInfo(params) {
        this.send('selectModelListItems', params);
        if (this.controller.permissionsHubController.isInfoSidebarOpen && params.record.isSelected) {
          this.controller.permissionsHubController.toggleProperty('isInfoSidebarOpen');
          params.record.set('isSelected', false);
        } else {
          this.controller.permissionsHubController.set('isInfoSidebarOpen', true);
        }
      }
    }
  });
  _exports.default = _default;
});