define("experience-studio/templates/components/info-rule-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ncqrgJom",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[3,\" Common Details \"],[2,\"\\n\"],[3,\" Rule composition info \"],[2,\"\\n\"],[1,[30,[36,3],null,[[\"model\",\"rule\",\"pushObject\"],[[35,2],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"pushObject\",\"rule\",\"model\",\"info-sidebar-rule-composition\"]}",
    "moduleName": "experience-studio/templates/components/info-rule-select.hbs"
  });
  _exports.default = _default;
});