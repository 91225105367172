define("experience-studio/components/av5-label-select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    allLabels: null,
    classNames: ['av5-label-select-dropdown'],
    isApplyLabelsToAll: false,
    labelsOperator: 'AND',
    labels: null,
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.fetchLabels();
    },
    dropdownClass: Ember.computed('dropdownClassName', function () {
      return "dropdown".concat(this.dropdownClassName ? " ".concat(this.dropdownClassName) : '');
    }),
    selectedLabelsQty: Ember.computed('labels.[]', function () {
      return this.labels.length;
    }),
    isLabelsOperatorAND: Ember.computed('labelsOperator', function () {
      return this.labelsOperator === 'AND';
    }),
    fetchLabels: function fetchLabels() {
      var _this = this;
      var options = Ember.Object.create({
        limit: 1000000
      });
      var searchKeyword = (this.searchKeyword || '').trim();
      if (searchKeyword) {
        options.set('search', searchKeyword);
      }
      this.store.query('label', options).then(function (response) {
        var results = response.toArray().map(function (item) {
          return Ember.Object.create({
            isSelected: false,
            name: item.name
          });
        });
        _this.labels.forEach(function (item) {
          var selectedLabel = results.filterBy('name', item.name).objectAt(0);
          if (selectedLabel) {
            selectedLabel.set('isSelected', item.isSelected);
          }
        });
        _this.set('allLabels', results);
      });
    },
    actions: {
      clearAll: function clearAll() {
        var _this2 = this;
        this.labels.forEach(function (label) {
          var timeoutId = setTimeout(function () {
            clearTimeout(timeoutId);
            _this2.send('toggleLabel', label);
          }, 1);
        });
      },
      didClickApplyLabelsToAll: function didClickApplyLabelsToAll() {
        var _this3 = this;
        this.toggleProperty('isApplyLabelsToAll');
        if (this.get('isApplyLabelsToAll')) {
          var cards = [];
          this.analyticsCards.forEach(function (card) {
            if (card.labels != _this3.labels) {
              card.labels.clear();
              cards.pushObject(card);
            }
          });
          this.labels.forEach(function (label) {
            _this3.addOrRemoveLabelToArrays(cards, label, 'add');
          });
        }
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword() {
        this.fetchLabels();
      },
      updateLabelsOperator: function updateLabelsOperator(value) {
        this.set('labelsOperator', value);
        if (this.get('isApplyLabelsToAll')) {
          this.analyticsCards.forEach(function (card) {
            if (card.labelsOperator !== value) {
              card.set('labelsOperator', value);
            }
          });
        }
      },
      toggleLabel: function toggleLabel(label) {
        var _this4 = this;
        var action = 'add';
        var cards = this.analyticsCards;
        if (label.isSelected) {
          action = 'remove';
        }
        if (!this.get('isApplyLabelsToAll')) {
          cards = cards.filter(function (card) {
            return card.labels === _this4.labels;
          });
        }
        this.addOrRemoveLabelToArrays(cards, label, action);
      }
    }
  });
  _exports.default = _default;
});