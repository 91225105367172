define("experience-studio/templates/components/push-messages-new--section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hp3hnRS6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"aside\"],[14,0,\"form-section-indicator\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"form-section-bullet\"],[4,[38,4],[[32,0],\"openSection\",\"detail\"],null],[12],[2,\"\\n\"],[6,[37,3],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,2,[\"details\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"times\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"check\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-section-line\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-section-content\"],[12],[2,\"\\n  \"],[11,\"header\"],[24,0,\"form-section-header form-section-part\"],[4,[38,4],[[32,0],\"openSection\"],null],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"form-section-title\"],[12],[1,[34,6]],[13],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"form-section-subtitle\"],[12],[1,[34,7]],[2,\".\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"open-section-button\"],[12],[2,\"\\n      \"],[10,\"button\"],[12],[1,[30,[36,3],[[35,8],\"Close\",\"Open\"],null]],[13],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"section-icon \",[30,[36,3],[[35,8],\"opened\",\"\"],null]]]],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"chevron-stroke\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"main\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"index\",\"errors\",\"if\",\"action\",\"isInvalid\",\"title\",\"description\",\"isOpen\"]}",
    "moduleName": "experience-studio/templates/components/push-messages-new--section.hbs"
  });
  _exports.default = _default;
});