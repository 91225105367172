define("experience-studio/templates/components/rich-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H0OedTjB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,13],[[35,18]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"bdi\"],[12],[2,\"\\n    \"],[10,\"textarea\"],[15,1,[34,15]],[15,0,[34,3]],[12],[1,[34,16]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,13],[[35,17]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"yellow-notification-block\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"notification-header\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"orange-info-icon\"],[12],[2,\"i\"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"notification-header-text\"],[12],[1,[34,14]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,13],[[35,12]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,11],null,[[\"field\",\"plugins\",\"isTextarea\",\"rows\",\"isRtl\",\"languages\",\"updateDynamicAttribute\",\"dynamicAtrributeValue\"],[[35,10],[35,9],true,[35,0],[35,8],[35,7],[30,[36,6],[[32,0],\"updateDynamicAttribute\"],null],[35,5]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"class\",\"placeholder\",\"value\",\"key-down\",\"key-up\",\"rows\"],[[35,3],[35,2],[35,1],\"didUpdateKeyAction\",\"didUpdateKeyUp\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"rows\",\"value\",\"placeholder\",\"className\",\"textarea\",\"dynamicAtrributeValue\",\"action\",\"languages\",\"isRtl\",\"plugins\",\"field\",\"dynamic-attribute-input\",\"isDynamicAttributeFeatureEnabled\",\"if\",\"notificationMsg\",\"tinyMceIdhbs\",\"valueNew\",\"isFieldFocus\",\"isRichTextEnabled\"]}",
    "moduleName": "experience-studio/templates/components/rich-text-input.hbs"
  });
  _exports.default = _default;
});