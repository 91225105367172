define("experience-studio/components/route-model-table-dropdown-experience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      sendPerformModelAction: function sendPerformModelAction(actionName) {
        this.performModelAction({
          action: actionName,
          record: this.model
        });
      },
      edit: function edit() {
        this.performModelAction({
          action: 'editRecord',
          record: this.model,
          route: 'experiences.edit'
        }); // action in base mixin
      },
      manageGroups: function manageGroups() {
        this.performModelAction({
          action: 'showManageExperiencesGroupsModal',
          record: this.model
        }); // action in experience router
      },
      changePriority: function changePriority(type, parent) {
        this.performModelAction({
          action: 'showManagePrioritizationModal',
          type: type,
          record: this.model,
          parent: parent
        });
      }
    }
  });
  _exports.default = _default;
});