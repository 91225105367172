define("experience-studio/templates/components/invitees-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tmucJo9J",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Email\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Access Level\"],[13],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"Status\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,1,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[1,[30,[36,0],[[32,1,[\"permission\"]]],null]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"status\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[14,\"colspan\",\"12\"],[12],[2,\"\\n            \"],[10,\"p\"],[14,0,\"no-data\"],[12],[2,\"There is no invitees data.\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"invitee-access-level\",\"modelSham\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/invitees-list.hbs"
  });
  _exports.default = _default;
});