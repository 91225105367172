define("experience-studio/components/content-template-leaf", ["exports", "jquery", "experience-studio/mixins/field-validator-factory", "experience-studio/config/environment"], function (_exports, _jquery, _fieldValidatorFactory, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-template-leaf
      field=field
      rootID=rootID
    }}
  
  */
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    classNames: ['content-template-leaf'],
    errorMsg: '',
    notificationMsg: '',
    isFieldFocus: false,
    visited: false,
    _valueChanged: Ember.observer('field.value', function () {
      if (this.visited) {
        this.send('validate');
      }
    }),
    _revalidateTriggered: Ember.observer('field.revalidate', function () {
      this.send('validate');
    }),
    isGeoshape: Ember.computed('field.type', function () {
      return this.field.type === 'ctgeoshape' || false;
    }),
    isNonMaxLengthField: Ember.computed('field', 'userPreferences', function () {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      var isRTFEnabled = isNonTdOrUsEnv || this.userPreferences.getValue('richTextFormatting');
      if (this.field.maxLength && isRTFEnabled && this.field.key === 'description') {
        return false;
      } else {
        return this.field.maxLength && (this.field.key === 'title' || this.field.key === 'description');
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
      this._super.apply(this, arguments);
    },
    actions: {
      didUpdateData: function didUpdateData(options) {
        if (typeof this.didUpdateData !== 'undefined') {
          this.didUpdateData(options);
        }
      },
      didUpdateErrorMessage: function didUpdateErrorMessage(error) {
        this.set('errorMsg', error);
      },
      didFocusIn: function didFocusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      },

      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      },
      isPreviewVideoTooltip: function isPreviewVideoTooltip(value) {
        if (typeof this.isPreviewVideoTooltip !== 'undefined') {
          this.isPreviewVideoTooltip(value);
        }
      },
      validate: function validate() {
        var validator = _fieldValidatorFactory.default.getValidator(this.field, this.languages);
        if (validator) {
          var validation = validator.validate();
          if (!validation.state) {
            this.set('errorMsg', validation.firstError().header);
          } else {
            this.set('errorMsg', '');
          }
        }
      },
      setVisited: function setVisited(bool) {
        this.set('visited', bool);
      }
    },
    focusIn: function focusIn() {
      if (this.field.key === 'title') {
        this.set('notificationMsg', 'Recommended: 80 characters.');
      } else if (this.field.key === 'description') {
        this.set('notificationMsg', 'Recommended: 160 characters. Additional text will result in a 5-line expandable truncated view on the mobile device.');
      } else {
        this.set('notificationMsg', '');
      }
      this.set('isFieldFocus', this.isNonMaxLengthField);
      this.set('visited', true);
    },
    focusOut: function focusOut() {
      this.set('isFieldFocus', false);
      this.send('validate');
    }
  });
  _exports.default = _default;
});