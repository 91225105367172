define("experience-studio/templates/components/rule-search-scroll-select-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "O0UMTWgH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"rule-list-header\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"rule-list-title\"],[12],[2,\"Select Context Rule\"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"rule-list-selection-count \"],[12],[1,[35,0,[\"length\"]]],[2,\" selected\"],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"onTyping\",\"onInput\",\"value\"],[\"onTyping\",\"handleSearch\",[35,2]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"rule-list-container\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"rule-list-indicator\"],[12],[2,\"\\n      Selected Rule\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"selected-rule-list\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"model\",\"showCheckbox\",\"dispatchAction\"],[[35,0],true,\"handleAction\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"rule-list-indicator\"],[12],[2,\"\\n    \"],[1,[34,5]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"all-rule-list\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"model\",\"dispatchAction\",\"showCheckbox\",\"isLoadScroll\",\"modelName\",\"showTextEmptyState\",\"canLoadModels\",\"isLoading\",\"handleScrollBottom\"],[[35,7],\"handleAction\",true,true,\"rule\",true,[35,6,[\"canLoadModels\"]],[35,6,[\"isLoading\"]],\"loadModels\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedRules\",\"model-list-context-rule-v2\",\"searchKeyword\",\"search-form-group\",\"if\",\"listStateIndicator\",\"modelProperties\",\"model\"]}",
    "moduleName": "experience-studio/templates/components/rule-search-scroll-select-list.hbs"
  });
  _exports.default = _default;
});