define("experience-studio/components/x-toggle-switch", ["exports", "ember-toggle/components/x-toggle-switch"], function (_exports, _xToggleSwitch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _xToggleSwitch.default;
    }
  });
});