define("experience-studio/components/custom-field--boolean", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    options: [
    // eslint-disable-line  ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      name: 'Yes',
      value: true
    }), Ember.Object.create({
      name: 'No',
      value: false
    })],
    didInsertElement: function didInsertElement() {
      if (!Ember.isNone(this.get('field.value'))) {
        var currentOption = this.options.findBy('value', Boolean(this.get('field.value')));
        this.send('onValue', currentOption);
      } else {
        this.set('field.isValid', !this.get('field.isRequired'));
      }
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    triggerValidation: function triggerValidation() {
      if (Ember.isEmpty(this.field.value) && this.field.isRequired) {
        this.set('isDirty', true);
        this.send('onValue', '');
      }
    },
    actions: {
      onValue: function onValue(option) {
        this.set('currentOption', option);
        this.field.set('errorMessages', []);
        this._super(option.value);
      }
    }
  });
  _exports.default = _default;
});