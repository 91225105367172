define("experience-studio/templates/components/custom-field--image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H0+kMwD9",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,0]],[13],[6,[37,2],[[35,1,[\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,3],null,[[\"value\",\"showURL\",\"showPreview\",\"showTriggerButton\",\"isURLEditable\",\"triggerLabel\"],[[35,1,[\"value\"]],true,false,true,true,\"Choose\"]]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,1,[\"errorMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"error-message text-danger\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"field\",\"if\",\"image-field\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--image.hbs"
  });
  _exports.default = _default;
});