define("experience-studio/components/message-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['message-modal-dialog'],
    messageTypeClassName: Ember.computed(function () {
      return "text-".concat(this.get('model.type'));
    })
  });
  _exports.default = _default;
});