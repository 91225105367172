define("experience-studio/serializers/experience", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      return {
        contentIds: data.contentIds,
        isActive: data.isActive,
        localizations: data.localizations,
        ruleId: data.ruleId,
        labels: data.labels,
        groupIds: data.groupIds
      };
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      if (item.attributes.index) {
        item.attributes.priorityIndex = item.attributes.index;
      }
      return item;
    }
  });
  _exports.default = _default;
});