define("experience-studio/components/content-tmp-edit-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-localized-media'],
    languages: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    selectedMediaType: null,
    isLocalized: Ember.computed('field.type', function () {
      return this.get('field.type') === 'ctlocalizedmedia';
    }),
    mediaTypeField: Ember.computed('field.schema', function () {
      if (!this.get('field.schema')) return undefined;
      return this.get('field.schema') && this.get('field.schema').find(function (field) {
        return field.key === 'mediaType';
      });
    }),
    resourceURLField: Ember.computed('field.schema', function () {
      if (!this.get('field.schema')) return undefined;
      return this.get('field.schema') && this.get('field.schema').find(function (field) {
        return field.key === 'resourceURL';
      });
    }),
    actions: {
      clearMediaURL: function clearMediaURL() {
        for (var i = 0; i < this.get('field.schema.length'); ++i) {
          // TODO: might be changed into something else other than ct(localized)image
          if (this.get("field.schema.".concat(i, ".type")) === 'ctlocalizedimage') {
            this.set("field.schema.".concat(i, ".value"), {});
          } else if (this.get("field.schema.".concat(i, ".type")) === 'ctimage') {
            this.set("field.schema.".concat(i, ".value"), '');
          }
        }
      },
      setSelectedMediaType: function setSelectedMediaType(type) {
        this.set('selectedMediaType', type);
        var mediaProvider = this.get('field.schema').find(function (field) {
          return field.key === 'mediaProvider';
        });
        if (type === 'video' && mediaProvider.options && mediaProvider.options[0]) {
          Ember.set(mediaProvider, 'value', mediaProvider.options[0].val);
        } else {
          Ember.set(mediaProvider, 'value', '');
        }
        this.validate();
      },
      setPreviewImgURL: function setPreviewImgURL(previewURL, language) {
        var _previewImgURL$value;
        var previewImgURL = this.get('field.schema').find(function (field) {
          return field.key === 'previewImgURL';
        });
        if (previewImgURL && previewImgURL.value && previewImgURL.value[language] && previewURL) {
          Ember.set(previewImgURL, "value.".concat(language, ".previewImgURL"), previewURL);
        } else if (previewImgURL && previewImgURL.value && previewURL) {
          Ember.set(previewImgURL, 'value', _objectSpread(_objectSpread({}, previewImgURL.value), {}, _defineProperty({}, language, {
            previewImgURL: previewURL
          })));
        } else if (previewImgURL !== null && previewImgURL !== void 0 && (_previewImgURL$value = previewImgURL.value) !== null && _previewImgURL$value !== void 0 && _previewImgURL$value[language]) {
          delete previewImgURL.value[language];
        }
      },
      setVideoLabel: function setVideoLabel(name, language) {
        var _this$get, _label$value;
        this.isPreviewVideoTooltip(name ? true : false);
        var label = (_this$get = this.get('field.schema')) === null || _this$get === void 0 ? void 0 : _this$get.find(function (field) {
          return field.key === 'label';
        });
        if (label && name) {
          Ember.set(label, 'value', _objectSpread(_objectSpread({}, label.value), {}, _defineProperty({}, language, name)));
        } else if (label !== null && label !== void 0 && (_label$value = label.value) !== null && _label$value !== void 0 && _label$value[language]) {
          delete label.value[language];
        }
      }
    }
  });
  _exports.default = _default;
});