define("experience-studio/models/triggered-email", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    rule: _emberData.default.attr(),
    content: _emberData.default.attr(),
    ruleId: _emberData.default.attr('string'),
    contentId: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch')
  });
  _exports.default = _default;
});