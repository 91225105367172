define("experience-studio/mixins/route-model-table-actions-push-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    utility: Ember.inject.service(),
    openConfirmScheduleModal: function openConfirmScheduleModal(record) {
      this.send('showModal', Ember.Object.create({
        modalName: 'confirm-push-schedule-modal-dialog',
        primaryMessage: 'primary message',
        title: 'Send Notification',
        record: record
      }));
    },
    actions: {
      confirmActivateRecord: function confirmActivateRecord(params) {
        var record = params.record;
        var activationVerb = record.isActive ? 'deactivate' : 'activate';
        var message = "Are you sure you want to ".concat(activationVerb, " Push Notification: ").concat(record.name, "?");
        var primaryButtonName = 'Continue';
        var secondaryMessage = '';
        if (record.activeSchedule && !record.isActive) {
          message = "Immediate activation of this push notification will remove the following scheduled start date:";
          secondaryMessage = "<strong>".concat(window.moment(record.activeSchedule).format('MMMM DD, YYYY [at] hh:mmA'), "</strong>\n          <br><br>This activation will not affect the end date currently set.");
          primaryButtonName = 'Remove start date and activate';
        }
        if (record.activeEndSchedule && record.isActive) {
          message = "Immediate deactivation of this push notification will remove the following scheduled end date:";
          secondaryMessage = "<strong>".concat(window.moment(record.activeEndSchedule).format('MMMM DD, YYYY [at] hh:mmA'), "</strong><br><br>");
          primaryButtonName = 'Remove end date and deactivate';
        }
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog-v2',
          primaryActionName: 'activateRecord',
          primaryMessage: message,
          record: record,
          secondaryMessage: secondaryMessage,
          title: 'Confirm Action',
          primaryAction: {
            action: 'activateRecord',
            record: record
          },
          primaryButtonName: primaryButtonName
        }));
      },
      broadcastRecord: function broadcastRecord(params) {
        var record = params.record;
        var self = this;
        this.broadcastRecord(record).then(function () {
          record.set('isSent', true);
          self.send('showNotification', Ember.Object.create({
            message: "The push notification ".concat(record.get('name'), "\n                     has been sent successfully."),
            type: 'confirm'
          }));
        }).catch(function (response) {
          self.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: self.get('utility').parseError(response),
            title: 'Failed to send Push Notification',
            type: 'danger'
          }));
        });
      },
      activateRecord: function activateRecord(params) {
        var self = this;
        var model = params.record;
        if (!model.get('isSaving')) {
          var myZone = window.moment.tz.guess().replace(/_/g, ' ');
          if (!model.isActive && model.isTriggered) {
            if (model.schedule && model.schedule.scheduler) {
              model.schedule.scheduler.set('start', 0);
              if (!model.schedule.scheduler.end) {
                model.schedule.scheduler.set('timezone', Ember.Object.create({
                  name: '',
                  offset: 0
                }));
              } else {
                if (model.schedule.scheduler.end < window.moment.unix() * 1000) {
                  model.schedule.scheduler.set('end', 0);
                }
              }
            }
            if (model.executionHistory) {
              model.executionHistory.setProperties({
                lastStartDate: Date.now() / 1000,
                timezone: Ember.Object.create({
                  name: myZone,
                  offset: 0
                })
              });
            } else {
              model.setProperties({
                executionHistory: Ember.Object.create({
                  lastStartDate: Date.now() / 1000,
                  timezone: Ember.Object.create({
                    name: myZone,
                    offset: 0
                  })
                })
              });
            }
          }
          if (model.isActive && model.isTriggered && model.schedule && model.schedule.scheduler) {
            if (!model.schedule.scheduler.start) {
              model.schedule.scheduler.set('timezone', Ember.Object.create({
                name: '',
                offset: 0
              }));
            }
            if (model.schedule.scheduler.end) {
              model.schedule.scheduler.set('end', 0);
            }
            if (model.executionHistory) {
              model.executionHistory.setProperties({
                lastEndDate: Date.now() / 1000,
                timezone: Ember.Object.create({
                  name: myZone,
                  offset: 0
                })
              });
            } else {
              model.setProperties({
                executionHistory: Ember.Object.create({
                  lastEndDate: Date.now() / 1000,
                  timezone: Ember.Object.create({
                    name: myZone,
                    offset: 0
                  })
                })
              });
            }
          }
          var adapter = this.store.adapterFor('push-message');
          adapter.toggleIsActive(this.store, model.constructor, model).then(function () {
            self.send('hideModal');
            model.set('isActive', !model.isActive);
            if (model.isActive && model.get('schedule.scheduler.end')) {
              model.schedule.scheduler.set('end', 0);
            }
            self.send('showNotification', Ember.Object.create({
              message: 'Successfully updated status!',
              type: 'confirm'
            }));
          }).catch(function (response) {
            self.send('hideModal');
            self.send('showModal', Ember.Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: self.get('utility').parseError(response),
              title: 'Failed to save status',
              type: 'danger'
            }));
          });
        }
      },
      duplicateRecord: function duplicateRecord(params) {
        var record = params.record;
        if (record) {
          var data = record.toJSON();
          this.transitionTo("communications-hub.push-messages-v2.create", {
            queryParams: {
              data: this.utility.btoa(data)
            }
          });
        }
      },
      validateAndBroadcast: function validateAndBroadcast(params) {
        var record = params.record;
        var pushScheduleExist = false;
        if (record.schedule && record.schedule.scheduler && record.schedule.scheduler.start) {
          pushScheduleExist = record.schedule.scheduler.start;
        }
        if (pushScheduleExist) {
          this.openConfirmScheduleModal(record);
        } else {
          this.send('broadcastRecord', params);
        }
      }
    },
    broadcastRecord: function broadcastRecord(model) {
      var adapter = this.store.adapterFor(model.constructor.modelName);
      return adapter.broadcastRecord(this.store, model.constructor, model);
    }
  });
  _exports.default = _default;
});