define("experience-studio/components/filter-pill-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['filter-pill'],
    value: Ember.computed('model.filters.[]', function () {
      var filter = this.model.filters.objectAt(0);
      if (filter) {
        return "Address : ".concat(filter.value);
      }
    }),
    actions: {
      clearPill: function clearPill() {
        if (this.didUpdateQueryParams && this.model.filters.length) {
          var filters = this.model.filters;
          var parametersToModify = {
            currentPage: 1,
            recordsPerPage: 10
          };
          filters.forEach(function (filter) {
            parametersToModify[filter.id] = null;
          });
          this.didUpdateQueryParams(parametersToModify);
        }
      }
    }
  });
  _exports.default = _default;
});