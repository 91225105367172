define("experience-studio/templates/components/fb-bs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g10vvzOZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"alert-content\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"alert-close\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"close\"],[14,\"data-dismiss\",\"alert\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"times-circle\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"dismissible\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/fb-bs-alert.hbs"
  });
  _exports.default = _default;
});