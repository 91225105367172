define("experience-studio/components/rich-text-input", ["exports", "tinymce", "experience-studio/config/environment", "experience-studio/helpers/dynamic-attribute-rtf-serializer", "sanitize-html", "experience-studio/helpers/blacklist-sites"], function (_exports, _tinymce, _environment, _dynamicAttributeRtfSerializer, _sanitizeHtml, _blacklistSites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = Ember.Component.extend({
    idName: '',
    notificationMsg: '',
    userPreferences: Ember.inject.service(),
    isFieldFocus: false,
    isLangChanged: false,
    isLoading: true,
    isNonMaxLengthField: Ember.computed('maxlength', 'key', function () {
      return this.maxLength && this.key === 'description';
    }),
    isDynamicAttributeFeatureEnabled: Ember.computed('userPreferences.preferences.[]', 'field', function () {
      return this.field && this.field.isDynamicAttributeEnabled;
    }),
    isRichTextEnabled: Ember.computed('userPreferences.preferences.[]', 'isRichText', function () {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      var isRTFEnabled = isNonTdOrUsEnv || this.userPreferences.getValue('richTextFormatting');
      return isRTFEnabled && this.isRichText && (0, _blacklistSites.blacklistSites)();
    }),
    tinyMceIdhbs: Ember.computed('idName', function () {
      return "".concat(this.elementId, " ").concat(this.idName);
    }),
    didSelectLanguage: Ember.observer('languages.@each.isSelected', function () {
      this.set('isLangChanged', true);
    }),
    didChangeLanguage: Ember.observer('isRtl', function () {
      if (!this.isLoading) {
        _tinymce.default.remove('#'.concat(this.tinyMceIdhbs));
        this.initializeTinyMCE();
      }
    }),
    dynamicAttrPluginAttrsMap: Ember.computed('plugins.content.[].attributes', function () {
      if (!(this.plugins && this.plugins.content && this.plugins.content.length)) return [];
      var pluginAttrMap = {};
      this.plugins.content.forEach(function (plugin) {
        if (plugin._record) {
          var attributes = plugin._record.attributes;
          for (var attrKey in attributes) {
            if (attributes.hasOwnProperty(attrKey)) {
              pluginAttrMap[attributes[attrKey].uid] = "".concat(plugin._record.name, " / ").concat(attributes[attrKey].name);
            }
          }
        }
      });
      return pluginAttrMap;
    }),
    setRichTextFormatValue: Ember.observer('value', function () {
      if (this.isRichTextEnabled) {
        var htmlValue = '',
          plainValue = '';
        if (this.value) {
          var value = '';
          try {
            value = decodeURIComponent(typeof this.value === 'string' ? this.value : this.value.html);
          } catch (exception) {
            value = typeof this.value === 'string' ? this.value : this.value.html;
          }
          htmlValue = this.isDynamicAttributeFeatureEnabled ? (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(value, this.dynamicAttrPluginAttrsMap, this.isRichTextEnabled) : value;
          plainValue = this.extractHTMLContent(value, false);
          if (_tinymce.default.get(this.tinyMceIdhbs) && this.isLangChanged
          // converting no-break space and &nbsp; to regular space for htmlValue
          && htmlValue.replaceAll('&nbsp;', ' ').replaceAll(' ', ' ') !== _tinymce.default.get(this.tinyMceIdhbs).getContent().replaceAll('&nbsp;', ' ').replaceAll('style="text-decoration: underline;"', 'style="text-decoration: underline"')) {
            _tinymce.default.get(this.tinyMceIdhbs).setContent(htmlValue);
          }
          this.set('isLangChanged', false);
          this.set('valueNew', htmlValue);
        } else {
          this.set('valueNew', '');
          if (_tinymce.default.get(this.tinyMceIdhbs)) {
            _tinymce.default.get(this.tinyMceIdhbs).setContent('');
          }
        }
        this.set('value', {
          'html': encodeURIComponent(this.getSanitizeHtml((0, _dynamicAttributeRtfSerializer.serializeDynamicAttributes)(htmlValue, this.isRichTextEnabled, true))),
          'text': plainValue
        });
      } else {
        if (this.value) {
          try {
            this.set('value', this.extractHTMLContent(decodeURIComponent(this.value), this.field && this.field.inputUI === 'textarea'));
          } catch (exception) {
            this.set('value', this.extractHTMLContent(this.value), this.field && this.field.inputUI === 'textarea');
          }
        }
      }
    }),
    setEvents: Ember.observer('editor', function () {
      var editor = this.get('editor');
      if (!editor) {
        return;
      }
      editor.on('change keyup keydown keypress mousedown', Ember.run.bind(this, this.contentChanged, this.editor));
      editor.on('focusin', Ember.run.bind(this, this.focusIn, this.editor));
      editor.on('focusout', Ember.run.bind(this, this.focusOut, this.editor));
    }),
    focusIn: function focusIn() {
      if (this.key === 'description') {
        this.set('notificationMsg', 'Recommended: 160 characters. Additional text will result in a 5-line expandable truncated view on the mobile device.');
      } else {
        this.set('notificationMsg', '');
      }
      this.set('isFieldFocus', this.isNonMaxLengthField);
      if (this.didFocusIn) {
        this.didFocusIn();
      }
    },
    focusOut: function focusOut() {
      this.set('isFieldFocus', false);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.later(function () {
        if (_this.isRichTextEnabled) {
          var htmlValue = '',
            plainValue = '';
          if (_this.value) {
            var value;
            try {
              value = decodeURIComponent(typeof _this.value === 'string' ? _this.value : _this.value.html);
            } catch (exception) {
              value = typeof _this.value === 'string' ? _this.value : _this.value.html;
            }
            htmlValue = _this.isDynamicAttributeFeatureEnabled ? (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(value, _this.dynamicAttrPluginAttrsMap, _this.isRichTextEnabled) : value;
            plainValue = _this.extractHTMLContent(value, false);
            _this.set('valueNew', htmlValue);
            _this.set('value', {
              'html': encodeURIComponent(_this.getSanitizeHtml((0, _dynamicAttributeRtfSerializer.serializeDynamicAttributes)(htmlValue, _this.isRichTextEnabled, true))),
              'text': plainValue
            });
          }
          var self = _this;
          Ember.run.scheduleOnce('afterRender', function () {
            self.initializeTinyMCE();
            self.set('isLoading', false);
          }, 200);
        } else {
          if (_this.value) {
            try {
              _this.set('value', _this.extractHTMLContent(decodeURIComponent(_this.value), _this.field && _this.field.inputUI === 'textarea'));
            } catch (exception) {
              _this.set('value', _this.extractHTMLContent(_this.value), _this.field && _this.field.inputUI === 'textarea');
            }
          }
        }
      }, 100);
    },
    initializeTinyMCE: function initializeTinyMCE() {
      var _this2 = this;
      _tinymce.default.remove(this.tinyMceIdhbs);
      if (!this.isDestroyed) {
        _tinymce.default.baseURL = 'assets/tinymce';
        var directionTiny = this.isRtl ? "rtl" : "ltr";
        if (this.isDynamicAttributeFeatureEnabled) {
          _tinymce.default.init({
            selector: "#".concat(this.tinyMceIdhbs),
            menubar: false,
            statusbar: false,
            directionality: directionTiny,
            toolbar: 'bold italic underline | fontselect formatselect | forecolor | numlist bullist | superscript | link emoticons | dynamicattribute',
            plugins: 'lists link emoticons dynamicattribute paste quickbars directionality',
            quickbars_selection_toolbar: 'ltr rtl',
            quickbars_insert_toolbar: false,
            toolbar_mode: 'sliding',
            contextmenu: 'link dynamicattribute',
            dynamicAttributes: [{
              text: 'Select an attribute',
              value: ''
            }].concat(_toConsumableArray(Object.keys(this.dynamicAttrPluginAttrsMap).map(function (attrId) {
              return {
                text: _this2.dynamicAttrPluginAttrsMap[attrId],
                value: attrId
              };
            })))
          });
        } else {
          _tinymce.default.init({
            selector: "#".concat(this.tinyMceIdhbs),
            menubar: false,
            statusbar: false,
            directionality: directionTiny,
            toolbar: 'bold italic underline | fontselect formatselect | forecolor | numlist bullist | superscript | link emoticons',
            plugins: 'lists link emoticons paste  quickbars directionality',
            quickbars_selection_toolbar: 'ltr rtl',
            quickbars_insert_toolbar: false,
            toolbar_mode: 'sliding',
            contextmenu: 'link'
          });
        }
        this.set('editor', _tinymce.default.get(this.tinyMceIdhbs));
      }
    },
    extractHTMLContent: function extractHTMLContent(text, isPlainTextArea) {
      var span = document.createElement('span');
      span.innerHTML = (0, _dynamicAttributeRtfSerializer.serializeDynamicAttributes)(text, this.isRichTextEnabled, true);
      if (isPlainTextArea) {
        return span.textContent && span.textContent || span.innerText && span.innerText || '';
      } else {
        return span.textContent && span.textContent.replace(/\r?\n|\r/g, " ") || span.innerText && span.innerText.replace(/\r?\n|\r/g, " ") || '';
      }
    },
    onValueChanged: function onValueChanged(editor) {
      var htmlText = this.getSanitizeHtml(editor.getContent());
      var plainText = this.extractHTMLContent(htmlText, false);
      if (this.isRichTextEnabled) {
        this.set('value', {
          'html': encodeURIComponent(htmlText),
          'text': plainText.trim()
        });
        this.set('valueNew', htmlText);
      }
      this.set('charcterLimit', plainText.length);
    },
    getSanitizeHtml: function getSanitizeHtml(content) {
      var sanitizeHtmlText = (0, _sanitizeHtml.default)(content, {
        allowedTags: ['p', 'span', 'em', 'strong', 'ol', 'li', 'ul', 'sup', 'a', 'q', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre'],
        allowedAttributes: {
          'a': ["href", "target"],
          'span': ["class", "data-mce-dynamic-attribute", "title", "contenteditable", "style"],
          'q': ["class"],
          'p': ["style", "dir"],
          'li': ["style"]
        },
        allowedStyles: {
          '*': {
            'text-decoration': [/^underline/],
            'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
            'font-family': [/^[a-zA-Z',-; ]*$/]
          },
          'p': {
            'text-align': [/^left$/, /^right$/, /^center$/]
          },
          'li': {
            'text-align': [/^left$/, /^right$/, /^center$/]
          }
        }
      });
      return sanitizeHtmlText.replace(/text-decoration:underline/gi, 'text-decoration: underline');
    },
    contentChanged: function contentChanged(editor) {
      var content = this.getSanitizeHtml(this.editor.getContent());
      if (this.editor.isDirty()) {
        var value;
        if (this.get('value')) {
          try {
            value = decodeURIComponent(typeof this.get('value') === 'string' ? this.get('value') : this.get('value').html);
          } catch (exception) {
            value = typeof this.get('value') === 'string' ? this.get('value') : this.get('value').html;
          }
        }
        if (content !== value) {
          this.onValueChanged(this.editor);
          editor.setDirty(true);
        }
      }
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      _tinymce.default.remove(this.tinyMceIdhbs);
      this.set('isRichTextEnabled', false);
      this.set('value', '');
      this.set('valueNew', '');
    },
    actions: {
      didUpdateKeyAction: function didUpdateKeyAction(inputVal, evt) {
        if (this.didKeyAction) {
          this.didKeyAction(inputVal, evt);
        }
      },
      didUpdateKeyUp: function didUpdateKeyUp(inputVal, evt) {
        if (this.didKeyUp) {
          this.didKeyUp(inputVal, evt);
        }
      },
      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      }
    }
  });
  _exports.default = _default;
});