define("experience-studio/services/utility", ["exports", "jquery", "flatted"], function (_exports, _jquery, _flatted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Service.extend({
    errorParser: Ember.inject.service(),
    storage: Ember.inject.service(),
    store: Ember.inject.service(),
    btoa: function (_btoa) {
      function btoa(_x) {
        return _btoa.apply(this, arguments);
      }
      btoa.toString = function () {
        return _btoa.toString();
      };
      return btoa;
    }(function (data) {
      var plainString = '';
      if (typeof data == 'string') {
        plainString = data;
      } else if (data) {
        plainString = (0, _flatted.stringify)(data);
      } else {
        return '';
      }
      var aUTF16CodeUnits = new window.Uint16Array(plainString.length);
      window.Array.prototype.forEach.call(aUTF16CodeUnits, function (el, idx, arr) {
        arr[idx] = plainString.charCodeAt(idx);
      });
      return btoa(window.String.fromCharCode.apply(null, new window.Uint8Array(aUTF16CodeUnits.buffer)));
    }),
    atob: function (_atob) {
      function atob(_x2) {
        return _atob.apply(this, arguments);
      }
      atob.toString = function () {
        return _atob.toString();
      };
      return atob;
    }(function (base64String) {
      if (typeof base64String !== 'string') {
        return Ember.Object.create({});
      }
      var sBinaryString = atob(base64String);
      var aBinaryView = new window.Uint8Array(sBinaryString.length);
      window.Array.prototype.forEach.call(aBinaryView, function (el, idx, arr) {
        arr[idx] = sBinaryString.charCodeAt(idx);
      });
      var data = window.String.fromCharCode.apply(null, new window.Uint16Array(aBinaryView.buffer));
      try {
        data = Ember.Object.create((0, _flatted.parse)(data));
      } catch (exception) {
        // TODO: Handle exception
      }
      return data;
    }),
    hash: function hash(value) {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }
      var hash = 0;
      if (value.length == 0) return hash;
      for (var i = 0; i < value.length; i++) {
        var char = value.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }

      return hash;
    },
    isObject: function isObject(object) {
      return _typeof(object) === 'object';
    },
    isArray: function isArray(object) {
      return window.Object.prototype.toString.call(object) === '[object Array]';
    },
    cloneObject: function cloneObject(object) {
      var copy;
      var property;
      if (!this.isObject(object) || object === null) {
        copy = object;
        return copy;
      }
      if (this.isArray(object)) {
        copy = [];
        for (var i = 0, l = object.length; i < l; i++) {
          copy[i] = this.cloneObject(object[i]);
        }
        return copy;
      }
      try {
        copy = new object.constructor();
      } catch (e) {
        copy = {};
      }
      for (property in object) {
        if (!object.hasOwnProperty(property)) {
          continue;
        }
        if (this.isObject(object[property]) && object[property] !== null) {
          copy[property] = this.cloneObject(object[property]);
        } else {
          copy[property] = object[property];
        }
      }
      return copy;
    },
    transformToEmberObject: function transformToEmberObject(data) {
      var result;
      if (Array.isArray(data)) {
        var length = data.length;
        result = [];
        for (var i = 0; i < length; i++) {
          result.push(this.transformToEmberObject(data[i]));
        }
      } else if (window.Object(data) === data && typeof data !== 'function' && !(data instanceof Date) && !(data instanceof RegExp)) {
        result = Ember.Object.create({});
        for (var property in data) {
          if (data.hasOwnProperty(property)) {
            result.set(property, this.transformToEmberObject(data[property]));
          }
        }
      } else {
        result = data;
      }
      return result;
    },
    languages: Ember.computed(function () {
      return this.store.queryRecord('project-config', {
        setting: 'language'
      });
    }),
    getGUID: function getGUID() {
      /*
        RFC4122 version 4 Compliant
        See http://stackoverflow.com/a/2117523 and https://gist.github.com/jed/982883 for more details.
      */

      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
        return (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
      }).toUpperCase();
    },
    makeAjaxRequest: function makeAjaxRequest(options) {
      var adapter = this.store.adapterFor('application');
      if (!options.contentType) {
        options.contentType = 'application/json';
      }
      if (options.data && options.contentType === 'application/json') {
        options.data = JSON.stringify(options.data);
      }
      if (!options.dataType) {
        options.dataType = 'json';
      }
      if (options.endpoint) {
        options.url = "".concat(adapter.get('host'), "/").concat(options.endpoint);
      }
      if (!options.headers) {
        options.headers = adapter.get('headers');
      }
      if (!options.timeout) {
        options.timeout = 0;
      }
      if (!options.type) {
        options.type = 'GET';
      }
      return this.makeRequest(options);
    },
    makeRequest: function makeRequest(options) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          contentType: options.contentType,
          data: options.data,
          dataType: options.dataType,
          headers: options.headers,
          type: options.type,
          url: options.url
        }).done(function (response) {
          return resolve(response);
        }).fail(function (response) {
          return reject(response);
        });
      });
    },
    parseError: function parseError(data) {
      return this.errorParser.parse(data);
    },
    generateStandardTooltipMarkup: function generateStandardTooltipMarkup(className) {
      var innerMarkupOverride = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var retMarkup = "\n      <div class=\"tooltip ".concat(className, "\" role=\"tooltip\">\n    ");
      var defaultInnerMarkup = '<div class="arrow"></div><div class="tooltip-inner"></div>';
      if (innerMarkupOverride) {
        var parser = new DOMParser();
        var parsedHTML = parser.parseFromString(innerMarkupOverride, 'text/html');
        if (parsedHTML.querySelector('.tooltip-inner')) {
          retMarkup += "".concat(innerMarkupOverride);
        } else {
          retMarkup += defaultInnerMarkup;
        }
      } else {
        retMarkup += defaultInnerMarkup;
      }
      return retMarkup += '</div>';
    }
  });
  _exports.default = _default;
});