define("experience-studio/templates/components/push-messages-summary-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yZpMwO/+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"preview-toggle\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"list-inline\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,4],[[35,5],\"active\",\"\"],null]]]],[4,[38,6],[[32,0],\"togglePreview\",\"device\"],null],[12],[2,\"Device Preview\"],[13],[13],[2,\"\\n    \"],[10,\"li\"],[12],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[30,[36,4],[[35,7],\"active\",\"\"],null]]]],[4,[38,6],[[32,0],\"togglePreview\",\"code\"],null],[12],[2,\"Code Preview\"],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,3,[\"isDevicePreview\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"phone\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"time text-center\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"local-time\"],[12],[1,[35,3,[\"localTime\"]]],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"local-date\"],[12],[1,[35,3,[\"localDate\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"push-message-box\"],[12],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"title\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[1,[34,2]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"message\"],[12],[2,\"\\n        \"],[1,[34,1]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/phone.svg\"],[14,\"alt\",\"phone\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"code-view\"],[12],[2,\"\\n  \"],[10,\"pre\"],[12],[10,\"code\"],[12],[1,[34,0]],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"payloadJSON\",\"message\",\"title\",\"model\",\"if\",\"isDevice\",\"action\",\"isCode\"]}",
    "moduleName": "experience-studio/templates/components/push-messages-summary-preview.hbs"
  });
  _exports.default = _default;
});