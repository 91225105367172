define("experience-studio/routes/communications-hub/push-messages-v2", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/model-actions", "experience-studio/mixins/pagination-route", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-push-messages"], function (_exports, _authenticatedRoute, _modelActions, _paginationRoute, _routeModelBatchActionsBase, _routeModelBatchActionsPushMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _modelActions.default, _paginationRoute.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsPushMessages.default, {
    utility: Ember.inject.service(),
    isScheduled: function isScheduled(model) {
      var isScheduled = false;
      var rules = model.computedRules;
      if (!Ember.isEmpty(rules)) {
        for (var i = 0; i < rules.length; i++) {
          var rule = rules[i];
          if (rule.isFulfilled && rule.content.schedule && rule.content.schedule.startScheduler && rule.content.schedule.startScheduler.start > 0) {
            return rule.content.schedule.startScheduler.start;
          }
        }
        return isScheduled;
      } else {
        if (model.schedule && model.schedule.scheduler && model.schedule.scheduler.start > 0) {
          return model.schedule.scheduler.start;
        } else {
          return isScheduled;
        }
      }
    },
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        languages: store.queryRecord('project-config', {
          setting: 'language'
        }),
        pushMessages: store.query('push-message', {
          page: params.page,
          recordsPerPage: params.recordsPerPage || 48,
          search: params.searchKeyword
        })
      });
    },
    afterModel: function afterModel(model, transition) {
      var userPreference = this.userPreferences.getValue('routeListView');
      if (transition.targetName === 'communications-hub.push-messages-v2.index' && userPreference) {
        this.transitionTo('communications-hub.push-messages-v3');
      }
      return this._super.apply(this, arguments);
    },
    broadcastRecord: function broadcastRecord(model) {
      var adapter = this.store.adapterFor(model.constructor.modelName);
      return adapter.broadcastRecord(this.store, model.constructor, model);
    },
    openConfirmScheduleModal: function openConfirmScheduleModal(record) {
      this.send('showModal', Ember.Object.create({
        modalName: 'confirm-push-schedule-modal-dialog',
        primaryMessage: 'primary message',
        title: 'Send Notification',
        record: record
      }));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          searchKeyword: '',
          page: 1,
          recordsPerPage: 48
        });
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        isDuplicatesModalOpen: false,
        communicationsHubController: this.controllerFor('communications-hub')
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      ifUniqRuleName: function ifUniqRuleName(newRuleName, oldRule) {
        this.sendAction('checkIfUniqRuleName', newRuleName, oldRule); // eslint-disable-line ember/closure-actions
      },
      validateAndBroadcast: function validateAndBroadcast(record) {
        if (this.isScheduled(record)) {
          this.openConfirmScheduleModal(record);
        } else {
          this.send('broadcastRecord', record);
        }
      },
      broadcastRecord: function broadcastRecord(record) {
        record = record.hasOwnProperty('record') ? record.record : record;
        var self = this;
        this.broadcastRecord(record).then(function () {
          record.set('isSent', true);
          self.send('showNotification', Ember.Object.create({
            message: "The push notification ".concat(record.get('name'), "\n                 has been sent successfully."),
            type: 'confirm'
          }));
        }).catch(function (response) {
          self.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: self.get('utility').parseError(response),
            title: 'Failed to send Push Notification',
            type: 'danger'
          }));
        });
      },
      createPushMessage: function createPushMessage() {
        this.send('createRecord', 'push-message-v2');
      },
      editRecord: function editRecord(record) {
        if (record.isActive && !record.isBroadcast) {
          var message = '';
          message += 'Deactivate Push Notification to edit Push Notification.';
          this.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: message,
            title: 'Error',
            type: 'danger'
          }));
        } else {
          this.transitionTo('communications-hub.push-messages-v2.edit', record.get('id'));
        }
      },
      dispatchAction: function dispatchAction(action, model) {
        switch (action) {
          case 'send':
            this.send('validateAndBroadcast', model);
            break;
          default:
            this.send.apply(this, ['handleAction'].concat(Array.prototype.slice.call(arguments)));
        }
      },
      deselectModelListItems: function deselectModelListItems() {
        this._super({
          selectedEntitiesPath: 'communicationsHubController.selectedEntities'
        });
      },
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.searchKeyword);
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(options) {
        this.controller.set('searchKeyword', options.keyword);
        this.send('aggregateModel', 'push-message', 'pushMessages', 48);
      },
      selectModelListItems: function selectModelListItems(model) {
        var _this = this;
        var self = this;
        if (model && model.customFields && model.customFields.contentId && !Ember.isEmpty(model.customFields.contentId.value)) {
          var contentId = model.customFields.contentId.value;
          if (!model.customFields.contentId.metadata.contentName) {
            this.store.findRecord('content-instance', contentId).then(function (response) {
              model.customFields.contentId.metadata['contentName'] = response.name;
              _this._super(model, 'communicationsHubController.selectedEntities');
            }).catch(function (response) {
              self.send('showModal', Ember.Object.create({
                modalName: 'message-modal-dialog',
                primaryMessage: self.get('utility').parseError(response),
                title: 'Failed to get content instance',
                type: 'danger'
              }));
            });
          } else {
            this._super(model, 'communicationsHubController.selectedEntities');
          }
        } else {
          this._super(model, 'communicationsHubController.selectedEntities');
        }
      },
      toggleActivation: function toggleActivation(record) {
        var self = this;
        var model = record.record || record;
        if (!model.get('isSaving')) {
          model.toggleProperty('isActive');
          model.save().then(function () {
            self.send('hideModal');
            model.reload();
            self.send('showNotification', Ember.Object.create({
              message: 'Successfully updated status',
              type: 'confirm'
            }));
          }).catch(function (response) {
            self.send('hideModal');
            self.send('showModal', Ember.Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: self.get('utility').parseError(response),
              title: 'Failed to save status',
              type: 'danger'
            }));
          });
        }
      },
      didTransition: function didTransition() {
        var page = this.controller.get('page');
        var model = this.controller.model.pushMessages;
        if (page > 1 && model.get('length') === 0) {
          this.controller.set('page', 1);
        }
      }
    }
  });
  _exports.default = _default;
});