define("experience-studio/components/push-messages-create--device-preview", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Create a clock object that ticks every second.
  var ClockService = Ember.Object.extend({
    pulse: Ember.computed.oneWay('_seconds').readOnly(),
    tick: function () {
      var clock = this;
      Ember.run.later(function () {
        var seconds = clock.get('_seconds');
        if (typeof seconds === 'number') {
          clock.set('_seconds', seconds + 1 / 4);
        }
      }, 1000);
    }.observes('_seconds').on('init'),
    _seconds: 0
  });
  var _default = Ember.Component.extend({
    classNames: ['push-messages-create--device-preview'],
    clock: ClockService.create(),
    pushStartDate: Ember.computed('model.schedule.scheduler.start', function () {
      return this.get('model.schedule.scheduler.start');
    }),
    didSelectLanguage: Ember.observer('languages.@each.isSelected', function () {
      var language = this.languages.findBy('isSelected', true);
      this.set('rtlPreviewClass', language.direction === 'rtl' ? 'rtl-preview-card' : '');
    }),
    isScheduled: Ember.computed('model.computedRules.@each.isFulfilled', 'model.schedule.scheduler.start', 'clock.pulse', function () {
      // eslint-disable-line ember/use-brace-expansion
      var isScheduled = (0, _moment.default)().unix() * 1000;
      var rules = this.model.computedRules;
      if (!Ember.isEmpty(rules)) {
        for (var i = 0; i < rules.length; i++) {
          var rule = rules[i];
          if (rule.isFulfilled && rule.content.schedule && rule.content.schedule.startScheduler && rule.content.schedule.startScheduler.start > 0) {
            return rule.content.schedule.startScheduler.start;
          }
        }
        return isScheduled;
      } else {
        if (this.model.schedule && this.model.schedule.scheduler && this.model.schedule.scheduler.start > 0) {
          return this.model.schedule.scheduler.start;
        } else {
          return isScheduled;
        }
      }
    }),
    date: Ember.computed('isScheduled', function () {
      return (0, _moment.default)(this.isScheduled).format('dddd, MMMM D');
    }),
    time: Ember.computed('isScheduled', function () {
      return (0, _moment.default)(this.isScheduled).format('h:mm');
    })

    // actions: {
    //   selectDevice(device) {
    //     switch (device) {
    //       case 'ios':
    //         this.set('deviceType', 'ios');
    //         this.set('deviceActive', true);
    //         break;
    //       case 'android':
    //         this.set('deviceType', 'android');
    //         this.set('deviceActive', false);
    //         break;
    //     }
    //   },
    // },
  });
  _exports.default = _default;
});