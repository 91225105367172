define("experience-studio/components/av5-pagination-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { isEmpty } from '@ember/utils';
  /*
  
    Description:
  
      Displays current page boundaries and total records
  
    Usages:
  
      <ModelPaginationInfo
        limit=pagesize
        offset=indexoffset
        totalRecords=totalRecords
      />
  
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-pagination-info'],
    records: null,
    limit: 10,
    offset: 0,
    totalRecords: 0,
    // COMPUTED -------------------------------------------------------------------
    isClientSidePagination: Ember.computed('records', function () {
      if (this.records === null || this.records === undefined) {
        return false;
      }
      return true;
    }),
    currentPageStart: Ember.computed('offset', function () {
      return this.offset + 1;
    }),
    currentPageEnd: Ember.computed('offset', 'limit', 'totalRecords', function () {
      return this.offset + this.limit > this.totalRecords ? this.totalRecords : this.offset + this.limit;
    }),
    // OBSERVERS ------------------------------------------------------------------
    // FUNCTIONS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    // ACTIONS -------------------------------------------------------------------

    actions: {
      // ಠ_ಠ
      updateRecordsPerPage: function updateRecordsPerPage(value) {
        this.set('recordsPerPage', value);
        if (this.didUpdateRecordsPerPage) {
          this.didUpdateRecordsPerPage();
        }
      }
    }
  });
  _exports.default = _default;
});