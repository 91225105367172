define("experience-studio/components/permissions-hub-access-level-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{permissions-hub-access-level-list
      model=model.accessLevels
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['permissions-hub-access-level-list'],
    model: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

    actions: {
      toggleIsActionsVisible: function toggleIsActionsVisible(accessLevel) {
        accessLevel.toggleProperty('isActionsVisible');
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.count').each(function () {
        var $count = (0, _jquery.default)(this);
        var $text = $count.siblings('.text');
        if (parseInt($count.text()) <= 1) {
          $text.text($text.text().slice(0, -1));
        }
      });
    }
  });
  _exports.default = _default;
});