define("experience-studio/components/permissions-hub-user-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['permissions-hub-user-table'],
    model: null,
    searchKeyword: '',
    tableHeadings: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.tableHeadings = [Ember.Object.create({
        label: 'Name',
        sortProperty: 'firstName'
      }), Ember.Object.create({
        label: 'E-mail',
        sortProperty: 'email'
      }), Ember.Object.create({
        label: 'Access Level',
        sortProperty: 'accessLevel.name'
      })];
    },
    isFetching: Ember.computed('model', 'model.isPending', function () {
      if (Array.isArray(this.model)) {
        return false;
      }
      return this.model.isPending;
    }),
    modelSham: Ember.computed('model.[]', 'tableHeadings.@each.isSorted', function () {
      var tableHeading = this.tableHeadings.filterBy('isSorted', true).get('firstObject');
      var isAscending = true;
      var model = [];
      var sortProperty = null;
      if (tableHeading) {
        isAscending = tableHeading.get('isAscending');
        sortProperty = tableHeading.get('sortProperty');
      }
      if (this.model && this.model.length && !this.isFetching) {
        model = this.model.toArray();
        model.sort(function (a, b) {
          var order = 0;
          var valueA;
          var valueB;
          if (sortProperty) {
            valueA = a.get(sortProperty);
            valueB = b.get(sortProperty);
            if (valueA < valueB) {
              order = 1;
            } else if (valueA > valueB) {
              order = -1;
            }
            if (isAscending) {
              order = order * -1;
            }
          } else {
            valueA = a.get('modifiedAt') || a.get('createdAt');
            valueB = b.get('modifiedAt') || b.get('createdAt');
            if (valueA && valueB) {
              valueA = window.moment(valueA);
              valueB = window.moment(valueB);
              if (valueA.isBefore(valueB)) {
                order = 1;
              } else if (valueA.isAfter(valueB)) {
                order = -1;
              }
            }
          }
          return order;
        });
      }
      return model;
    }),
    actions: {
      didClick: function didClick(item) {
        var model = this.model;
        if (!Ember.isEmpty(model)) {
          model.setEach('isSelected', false);
        }
        this.sendAction('didClick', item); // eslint-disable-line ember/closure-actions
      },
      processModelListAction: function processModelListAction(action, model, params) {
        this.sendAction('processModelListAction', action, model, params); // eslint-disable-line ember/closure-actions
      },
      edit: function edit(item) {
        this.send('processModelListAction', 'edit', item);
      },
      sort: function sort(item) {
        var tableHeadings = this.tableHeadings;
        var isAscending = item.get('isAscending');
        if (Ember.isNone(isAscending)) {
          isAscending = false;
        }
        tableHeadings.forEach(function (item) {
          return item.setProperties({
            isAscending: false,
            isSorted: false
          });
        });
        item.setProperties({
          isAscending: !isAscending,
          isSorted: true
        });
      }
    }
  });
  _exports.default = _default;
});