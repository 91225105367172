define("experience-studio/templates/components/model-select-dropdown--trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XlbTkjog",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\" selected\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedCount\"]}",
    "moduleName": "experience-studio/templates/components/model-select-dropdown--trigger.hbs"
  });
  _exports.default = _default;
});