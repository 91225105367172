define("experience-studio/templates/components/content-tmp-edit-localized-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qH+PGuMz",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,6],[[35,11]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"language-list list-unstyled\"],[12],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[15,0,[31,[[30,[36,6],[[32,1,[\"isSelected\"]],\"active\",\"\"],null]]]],[12],[2,\"\\n          \"],[11,\"a\"],[24,6,\"#\"],[4,[38,7],[[32,0],\"selectLanguage\",[32,1]],null],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[6,[37,6],[[32,1,[\"isDefault\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,6],[[35,12]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container clearfix\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[34,4,[\"defaultOption\",\"defaultImage\"]]],[14,0,\"default-image\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"alert alert-info configuration-alert-box\"],[14,\"role\",\"alert\"],[12],[2,\"\\n        \"],[1,[30,[36,5],[\"exclamation-triangle\"],null]],[2,\"\\n        This default image is not configurable.\\n      \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"value\",\"requirements\",\"defaultTransform\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"defaultTransform\",\"requirements\",\"valueImage\",\"image-field\",\"field\",\"svg-jar\",\"if\",\"action\",\"languages\",\"-track-array\",\"each\",\"isLanguagesVisible\",\"isImageDisabled\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-localized-image.hbs"
  });
  _exports.default = _default;
});