define("experience-studio/controllers/content-hub/index", ["exports", "experience-studio/mixins/pagination-controller"], function (_exports, _paginationController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_paginationController.default, {
    sortBy: 'createdAt',
    sortOrder: 'desc',
    searchKeyword: '',
    page: 1,
    recordsPerPage: 48,
    queryParams: [{
      sortBy: 'sortBy',
      sortOrder: 'sortOrder',
      searchKeyword: 'search',
      page: 'page',
      recordsPerPage: 'recordsPerPage'
    }],
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Associate',
      icon: 'th-large',
      subActions: [Ember.Object.create({
        label: 'Associate Content Instance',
        action: 'showAssociateRecordsModal',
        subActions: []
      }), Ember.Object.create({
        label: 'Disassociate Content Instance',
        action: 'showDisassociateRecordsModal',
        subActions: []
      })]
    }), Ember.Object.create({
      label: 'Label',
      icon: 'tag',
      subActions: [Ember.Object.create({
        label: 'Add Labels',
        action: 'showAddLabelRecordsModal',
        subActions: []
      }), Ember.Object.create({
        label: 'Remove Labels',
        action: 'showRemoveLabelRecordsModal',
        subActions: [],
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('labels.length', 0)) {
            return 'These items have no labels to remove.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: []
    })])
  });
  _exports.default = _default;
});