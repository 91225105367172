define("experience-studio/templates/components/content-tmp-edit-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IfrWtfca",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"class\",\"value\"],[\"field input-field data-field\",[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"valueProxy\",\"intl-tel-input\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-phone.hbs"
  });
  _exports.default = _default;
});