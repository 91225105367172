define("experience-studio/components/rule-select-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.send('clearSearch');
    },
    classNames: ['rule-select-modal-dialog', 'fly-modal'],
    errorMessage: '',
    isSelectionInvalid: Ember.computed('desiredRule', function () {
      return !this.desiredRule || this.onlyProduction && this.get('desiredRule.isDraft');
    }),
    desiredRule: Ember.computed('rules.{[],@each.isSelected}', function () {
      return this.rules.filterBy('isSelected').objectAt(0);
    }),
    isDesiredRuleEmpty: Ember.computed('desiredRule', function () {
      var ruleBody = this.desiredRule.body;
      return Ember.isEmpty(ruleBody.andLiterals) && Ember.isEmpty(ruleBody.orLiterals) && Ember.isEmpty(ruleBody.andBlocks) && Ember.isEmpty(ruleBody.orBlocks);
    }),
    isBroadcast: Ember.computed('pushObject.type', function () {
      return this.pushObject.type === 'broadcast';
    }),
    actions: {
      cancel: function cancel() {
        this.rules.setEach('isSelected', false);
        this.send('clearSearch');
        this.set('isOpen', false);
      },
      confirm: function confirm() {
        this.sendAction('selectRule', this.desiredRule); // eslint-disable-line ember/closure-actions
        this.send('clearSearch');
        this.set('isOpen', false);
        this.desiredRule.set('isSelected', false);
      },
      handleAction: function handleAction(name, model) {
        if (name === 'select') {
          var errorMessage = model.isDraft ? 'Production rule is required.' : '';
          if (!model.hasContextualStart && model.get('schedule.startScheduler.start') && model.get('schedule.startScheduler.start') < Date.now()) {
            errorMessage = this.intl.t('errors.expired.schedule.rule');
          }
          var rules = this.rules;
          rules.setEach('isSelected', false);
          model.set('isSelected', true);
          // This code will be used in the next sprint for scheduled triggered push messages
          // if (!this.isBroadcast && this.isPushModal &&
          //   model.schedule &&
          //   model.schedule.startScheduler &&
          //   model.schedule.startScheduler.start
          // ) {
          //   errorMessage = 'This rule has a schedule.
          //   Any schedule that is currently set for this notification
          //   will be replaced with the one in this rule';
          // }
          this.set('errorMessage', errorMessage);
        }
      },
      onTyping: function onTyping(isTyping) {
        this.set('ruleProperties.isTyping', isTyping);
      },
      clearSearch: function clearSearch() {
        this.ruleProperties.set('searchKeyword', '');
        this.send('handleSearch', '');
      },
      handleSearch: function handleSearch(keyword) {
        this.sendAction('handleSearch', keyword, 'rule', 'ruleProperties'); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels(modelName) {
        this.sendAction('loadModels', modelName, 'ruleProperties'); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});