define("experience-studio/components/info-sidebar-content-instance", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['info-sidebar-content-instance'],
    locationLabels: Ember.A([]),
    didRender: function didRender() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      if (this.isBlueprintContentInstance) {
        this.loadLocationLabels();
      }
    },
    hasAssociations: Ember.computed('model', function () {
      return false;
    }),
    isBlueprintContentInstance: Ember.computed('model', function () {
      return this.model.constructor.modelName === 'content-blueprint';
    }),
    onModelChange: Ember.observer('model', function () {
      if (this.isBlueprintContentInstance) {
        this.loadLocationLabels();
      }
    }),
    loadLocationLabels: function loadLocationLabels() {
      var _this = this;
      this.set('locationLabels', Ember.A([]));
      return this.store.findRecord('rule', this.model.ruleId).then(function (res) {
        _this.set('locationLabels', Ember.A(res.locationLabels));
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});