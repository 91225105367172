define("experience-studio/components/route-model-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter'],
    data: null,
    queryParameters: null,
    userPreference: null,
    filterParams: null,
    filterState: null,
    utility: Ember.inject.service(),
    decodeData: function decodeData() {
      var data = this.utility.atob(this.data) || {};
      this.set('queryParameters', data);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.decodeData();
    },
    // Returns filters defined in filterParams
    relevantFilters: Ember.computed('queryParameters', 'filterParams', function () {
      var _this = this;
      var relevantFilters = [];
      var queryParameterKeys = Object.keys(this.queryParameters);
      queryParameterKeys.forEach(function (paramKey) {
        var filter = _this.filterParams.find(function (filter) {
          return paramKey.indexOf(filter.id) > -1;
        });
        if (filter) {
          filter.set('value', _this.queryParameters[filter.id]);
          relevantFilters.pushObject(filter);
        }
      });
      return relevantFilters;
    }),
    filterPills: Ember.computed('relevantFilters.[]', function () {
      var filterPills = [];
      this.relevantFilters.forEach(function (filter) {
        var existingFilterPill = filterPills.find(function (pill) {
          return filter.component.indexOf(pill.component) > -1;
        });
        if (!Ember.isNone(existingFilterPill)) {
          existingFilterPill.filters.push(filter);
        } else {
          filterPills.pushObject(Ember.Object.create({
            component: filter.component,
            filters: [filter]
          }));
        }
      });
      return filterPills;
    }),
    actions: {
      clearAllFilters: function clearAllFilters() {
        if (this.didUpdateQueryParams) {
          var parametersToModify = {};
          this.relevantFilters.forEach(function (filter) {
            parametersToModify[filter.id] = null;
          });
          this.didUpdateQueryParams(parametersToModify);
        }
      },
      didUpdateQueryParams: function didUpdateQueryParams(params) {
        if (this.didUpdateQueryParams) {
          this.didUpdateQueryParams(params);
        }
      },
      toggleFilterSections: function toggleFilterSections(sectionIds, isDisabling) {
        if (this.toggleFilterSections) {
          this.toggleFilterSections(sectionIds, isDisabling);
        }
      },
      performFetchModel: function performFetchModel(modelName, params) {
        if (this.fetchModel) {
          return this.fetchModel(modelName, params);
        }
      }
    }
  });
  _exports.default = _default;
});