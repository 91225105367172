define("experience-studio/components/google-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['google-maps'],
    gmaps: null,
    latitude: null,
    longitude: null,
    map: null,
    zoom: null,
    /*
      addListener
      addListenerOnce
      clearInstanceListeners
      clearListeners
      createMarker
      didClick
      didInsertElement
      initMap
      removeMarker
    */
    addListener: function addListener(instance, eventName, handler) {
      this.gmaps.event.addListener(instance, eventName, handler);
    },
    addListenerOnce: function addListenerOnce(instance, eventName, handler) {
      this.gmaps.event.addListenerOnce(instance, eventName, handler);
    },
    clearInstanceListeners: function clearInstanceListeners(instance) {
      this.gmaps.event.clearInstanceListeners(instance);
    },
    clearListeners: function clearListeners(instance, eventName) {
      this.gmaps.event.clearListeners(instance, eventName);
    },
    createMarker: function createMarker(options) {
      var gmaps = this.gmaps;
      var marker = new gmaps.Marker({
        map: this.map,
        position: options.position
      });
      return marker;
    },
    didClick: function didClick(event) {
      var onClick = this.onClick;
      if (onClick && event) {
        onClick(this, event);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initMap();
    },
    initMap: function initMap() {
      var gmaps = window.google.maps;
      var latitude = window.parseFloat(this.latitude) || -37.111112;
      var longitude = window.parseFloat(this.longitude) || -12.28834;
      var map = new gmaps.Map(this.$('.map').get(0), {
        center: {
          lat: latitude,
          lng: longitude
        },
        zoom: this.zoom || 11
      });
      map.addListener('click', Ember.run.bind(this, this.didClick));
      this.setProperties({
        gmaps: gmaps,
        isLoading: false,
        map: map
      });
      if (latitude && longitude) {
        gmaps.event.trigger(map, 'click', {
          latLng: map.getCenter()
        });
      }
    },
    removeMarker: function removeMarker(marker) {
      if (!Ember.isNone(marker)) {
        marker.setMap(null);
      }
    }
  });
  _exports.default = _default;
});