define("experience-studio/components/composition-group-item-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['composition-group-item-description', 'subtitle'],
    model: null,
    tagName: 'p',
    description: Ember.computed('model.{description,areaTabSelected,labels.[],locations.[],didUpdateLabel}', function () {
      var description = this.model.description;
      if (this.model.areaTabSelected === 'label' && this.model.labels.length) {
        description = "".concat(this.model.labels.length, " Labels Selected");
      } else if (this.model.areaTabSelected === 'list' && this.model.locations && this.model.locations.length) {
        description = "".concat(this.model.locations.length, " Locations Selected");
      } else if (this.model.areaTabSelected === 'historical' && this.model.frequencyValue && this.model.recencyInThePastValue && this.model.isHasAreaPlugin) {
        description = "Historical location has been true atleast ".concat(this.model.frequencyValue, " times(s)\n                     ").concat(this.model.recencyOption, " ").concat(this.model.recencyInThePastValue, " ").concat(this.model.recencyInThePastUnit);
      }
      return description;
    })
  });
  _exports.default = _default;
});