define("experience-studio/serializers/push-message", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (Array.isArray(payload.data)) {
        payload.data = payload.data.filter(function (item) {
          return item.pushType !== 2;
        });
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      var schedule = data.schedule || Ember.Object.create();
      var scheduler = schedule.scheduler || Ember.Object.create();
      var start = scheduler.start || 0;
      var end = scheduler.end || 0;
      if (schedule && scheduler && start && start > 99999999999) {
        if (scheduler.timezoneUnixTime) {
          scheduler.set('start', scheduler.timezoneUnixTime / 1000);
          delete scheduler.timezoneUnixTime;
        } else {
          scheduler.set('start', Math.round(start / 1000));
        }
      }
      if (schedule && scheduler && end && end > 99999999999) {
        if (scheduler.timezoneUnixTimeEnd) {
          scheduler.set('end', scheduler.timezoneUnixTimeEnd / 1000);
          delete scheduler.timezoneUnixTimeEnd;
        } else {
          scheduler.set('end', Math.round(end / 1000));
        }
      }
      data.backgroundPushType = data.isAlert ? 1 : 2;
      data.body = this.serializeBody(data);
      data.keywords = data.subscriptions;
      data.pushType = this.serializeType(data);
      delete data.customFields;
      delete data.isAlert;
      delete data.message;
      delete data.subscriptions;
      delete data.title;
      delete data.type;
      delete data.timezoneUnixTime;

      // Delete front-end related options field
      delete data.options.isUnlimited;
      delete data.options.maxCycleSham;
      delete data.options.maxIntervalSham;
      delete data.options.cooldownShamRepeat; // message repeat (frequency)
      delete data.options.cooldownShamReset; // message cycle
      delete data.options.delaySham;
      delete data.options.limitSham;
      delete data.options.samplingSham;
      return data;
    },
    serializeBody: function serializeBody(data) {
      var body = [{
        payload: {
          action: 'custom',
          alert: {},
          title: {},
          data: {}
        },
        weight: 100
      }];
      var payload = body.objectAt(0).payload;
      if (data.customFields) {
        // Location Content / Blueprint
        if (data.customFields.contentBlueprintId) {
          payload.data = {
            contentBlueprintId: data.customFields.contentBlueprintId.value
          };
        } else {
          payload.data = data.customFields;
        }
      }
      if (data.pushTypeId && data.pushTypeId.toLowerCase() !== 'basic') {
        payload.data.pushPayloadTypeId = data.pushTypeId;
      }
      if (data.message) {
        payload.alert = data.message;
      }
      if (data.title) {
        payload.title = data.title;
      }
      if (window.Object.keys(payload.alert).length <= 0) {
        payload.alert = null;
      }
      if (window.Object.keys(payload.title).length <= 0) {
        payload.title = null;
      }
      return body;
    },
    serializeType: function serializeType(data) {
      switch (data.type) {
        case 'broadcast':
          return 1;
        case 'triggered':
          return 3;
        default:
          return 1;
      }
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      var attributes = item.attributes;
      var payload = {};
      item.id = attributes.pushRequestID;
      if (!Ember.isEmpty(attributes.body)) {
        payload = attributes.body.objectAt(0).payload || {};
      }
      if (payload.data && payload.data.images) {
        payload.data.images = Ember.Object.create(payload.data.images);
      }

      // hardcode this for blueprint to see if it works
      // this is for content

      if (payload.data && payload.data.pushPayloadTypeId) {
        attributes.pushTypeId = payload.data.pushPayloadTypeId;
        delete payload.data.pushPayloadTypeId;
      }
      if (payload.data) {
        if (payload.data.contentBlueprintId) {
          attributes.pushTypeId = 'LocationContent';
          payload.data.contentBlueprintId = {
            entity: "content",
            value: payload.data.contentBlueprintId,
            isContent: true,
            metadata: {}
          };
        }
        attributes.customFields = Ember.Object.create(payload.data);
      }
      if (attributes.experiences) {
        attributes.experiences = this.transformToEmberObject(attributes.experiences);
      }
      if (payload.alert) {
        attributes.message = Ember.Object.create(payload.alert);
      }
      if (attributes.options) {
        attributes.options = Ember.Object.create(attributes.options);
        attributes.options.limitSham = attributes.options.limit;
      }
      if (payload.title) {
        attributes.title = Ember.Object.create(payload.title);
      }
      if (attributes.rules) {
        attributes.rules = this.transformToEmberObject(attributes.rules);
      }
      if (attributes.pushType) {
        attributes.type = this.transformType(attributes);
      }
      if (attributes.executionHistory) {
        attributes.executionHistory = this.transformToEmberObject(attributes.executionHistory);
      }
      attributes.isAlert = attributes.backgroundPushType === 0 || attributes.backgroundPushType === 1; // eslint-disable-line max-len
      attributes.modifiedAt = attributes.updatedAt;
      attributes.subscriptions = attributes.keywords;
      if (item.attributes.schedule) {
        attributes.schedule = this.utility.transformToEmberObject(attributes.schedule);
      }
      var schedule = attributes.schedule || Ember.Object.create();
      var scheduler = schedule.scheduler || Ember.Object.create();
      var start = scheduler.start || 0;
      var end = scheduler.end || 0;
      var timezone = scheduler.timezone || Ember.Object.create();
      if (schedule && scheduler && start) {
        if (timezone) {
          var localZone = window.moment.tz.guess();
          var localZoneOffset = window.moment.tz(localZone).format('Z');
          var timeDiff = 0;
          if (timezone.offset) {
            timeDiff = (parseInt(timezone.offset) - parseInt(localZoneOffset)) * 3600;
          }
          var zoneTime = start + timeDiff;
          if (parseInt(timeDiff) < 0) {
            zoneTime = start - Math.abs(timeDiff);
          }
          scheduler.start = zoneTime * 1000; // eslint-disable-line max-len
        } else {
          scheduler.start = start * 1000; // eslint-disable-line max-len
        }
      }

      if (schedule && scheduler && end) {
        if (timezone) {
          var _localZone = window.moment.tz.guess();
          var _localZoneOffset = window.moment.tz(_localZone).format('Z');
          var _timeDiff = 0;
          if (timezone.offset) {
            _timeDiff = (parseInt(timezone.offset) - parseInt(_localZoneOffset)) * 3600;
          }
          var _zoneTime = end + _timeDiff;
          if (parseInt(_timeDiff) < 0) {
            _zoneTime = end - Math.abs(_timeDiff);
          }
          scheduler.end = _zoneTime * 1000; // eslint-disable-line max-len
        } else {
          scheduler.end = end * 1000; // eslint-disable-line max-len
        }
      }

      return item;
    },
    transformType: function transformType(attributes) {
      switch (attributes.pushType) {
        case 0:
        case 1:
          return 'broadcast';
        case 3:
          return 'triggered';
      }
    }
  });
  _exports.default = _default;
});