define("experience-studio/components/analytics-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['analytics-card'],
    actions: {
      toggleProperty: function toggleProperty(propery) {
        this.toggleProperty(propery);
      }
    }
  });
  _exports.default = _default;
});