define("experience-studio/templates/components/route-model-table-dropdown-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "INpwc21G",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"selectedEntities\"],[[35,2]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"sendPerformModelAction\",\"viewInfo\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"info-circle\"],null]],[2,\" View Info\\n  \"],[13],[2,\"\\n  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"sendPerformModelAction\",\"showManageGroupModalDialog\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"pen\"],null]],[2,\" Edit\\n  \"],[13],[2,\"\\n  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"changePriority\",\"GROUP\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"diamond\"],null]],[2,\" Change Priority\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-divider\"],[12],[13],[2,\"\\n  \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"sendPerformModelAction\",\"confirmDeleteRecord\"],null],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"trash\"],null]],[2,\" Delete\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"svg-jar\",\"selectedEntities\",\"route-model-table-dropdown\"]}",
    "moduleName": "experience-studio/templates/components/route-model-table-dropdown-group.hbs"
  });
  _exports.default = _default;
});