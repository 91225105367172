define("experience-studio/components/av5-engagement-export-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['av5-engagement-export-dropdown'],
    dropdownClass: Ember.computed('dropdownClassName', function () {
      return "dropdown".concat(this.dropdownClassName ? " ".concat(this.dropdownClassName) : '');
    }),
    isIncludePushTable: Ember.computed('tablesIncluded.[]', function () {
      return this.tablesIncluded && this.tablesIncluded.push;
    }),
    isIncludeRuleTable: Ember.computed('tablesIncluded.[]', function () {
      return this.tablesIncluded && this.tablesIncluded.rule;
    }),
    isIncludeContentTable: Ember.computed('tablesIncluded.[]', function () {
      return this.tablesIncluded && this.tablesIncluded.content;
    }),
    isExportFormatCSV: Ember.computed('exportFormat', function () {
      return this.exportFormat === 'CSV';
    }),
    isExportFormatJSON: Ember.computed('exportFormat', function () {
      return this.exportFormat === 'JSON';
    }),
    isExportButtonDisabled: Ember.computed('exportDateRange.{from,to}', 'isIncludePushTable', 'isIncludeRuleTable', 'isIncludeContentTable', 'exportEmail', function () {
      return !(this.exportDateRange.from && this.exportDateRange.to && this.exportEmail) || !(this.isIncludePushTable || this.isIncludeRuleTable || this.isIncludeContentTable);
    }),
    maxToDate: Ember.computed('exportDateRange.from', 'maxDate', function () {
      if (!this.exportDateRange.from) return this.maxDate;
      var maxToDate = new Date(this.exportDateRange.from);
      // Allow max 7 days to export, last day inclusive
      maxToDate.setDate(maxToDate.getDate() + 6);
      return maxToDate < this.maxDate ? maxToDate : this.maxDate;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      // max date on date pickers cannot be current date and onward.
      var maxDate = new Date();
      maxDate.setHours(0, 0, 0, 0);
      maxDate.setDate(maxDate.getDate() - 1);
      this.set('maxDate', maxDate);
    },
    actions: {
      updateSelectedExportTable: function updateSelectedExportTable(tableName) {
        event.stopPropagation();
        this.updateSelectedExportTable(tableName);
      },
      updateExportFormat: function updateExportFormat(tableName) {
        event.stopPropagation();
        this.updateExportFormat(tableName);
      },
      handleExport: function handleExport() {
        this.handleExport();
      },
      setExportFromDate: function setExportFromDate(fromDate) {
        this.setExportFromDate(fromDate);
        this.setExportToDate(null);
      }
    }
  });
  _exports.default = _default;
});