define("experience-studio/templates/components/info-sidebar-rule-composition-literal-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3cmTCmmr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"map-container\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[1,[34,7]],[13],[2,\"\\n\"],[6,[37,3],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"p\"],[24,0,\"expand-location\"],[4,[38,1],[[32,0],\"toggleViewLocationList\"],null],[12],[2,\"\\n      \"],[1,[30,[36,3],[[35,6],\"Less\",\"More\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"model\",\"isSelectable\",\"isSmallFormFactor\"],[[35,4,[\"locations\"]],false,true]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[31,[[34,0]]]],[14,\"alt\",\"\"],[15,\"onerror\",[30,[36,1],[[32,0],\"setSingleMapError\"],null]],[12],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"map-error\"],[12],[2,\"Map Is Unavailable\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"singleImageUrl\",\"action\",\"isMapError\",\"if\",\"model\",\"location-list\",\"isLocationListExpanded\",\"locationTitle\",\"hasMultiple\"]}",
    "moduleName": "experience-studio/templates/components/info-sidebar-rule-composition-literal-location.hbs"
  });
  _exports.default = _default;
});