define("experience-studio/mixins/nested-data-load", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    getAllData: function getAllData(parentID, arrayKey) {
      var url = "".concat(_environment.default.APP.hostUrl, "/kernel/content/instances/").concat(parentID, "/data?field=").concat(arrayKey, "&").concat(arrayKey, ".offset=0&").concat(arrayKey, ".limit=1000000");
      return _jquery.default.ajax(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'x-authorization': this.get('session.token'),
          'accept-language': 'all'
        }
      });
    }
  });
  _exports.default = _default;
});