define("experience-studio/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;
  function capitalize(params) {
    return Ember.String.capitalize(params.objectAt(0));
  }
  var _default = Ember.Helper.helper(capitalize);
  _exports.default = _default;
});