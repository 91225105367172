define("experience-studio/templates/components/role-permission-select-dropdown-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZRNDvJM/",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[11,\"div\"],[24,0,\"custom-control custom-checkbox\"],[4,[38,0],[[32,0],\"togglePermissionSelection\"],null],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"checked\"],[\"checkbox\",\"custom-control-input permission-input\",[35,2,[\"isSelected\"]]]]]],[2,\"\\n  \"],[10,\"label\"],[14,0,\"custom-control-label\"],[12],[1,[35,2,[\"name\"]]],[13],[2,\"\\n  \"],[1,[30,[36,3],[\"chevron\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,2,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[14,0,\"dropdown-item\"],[12],[2,\"\\n      \"],[11,\"div\"],[24,0,\"custom-control custom-checkbox\"],[4,[38,0],[[32,0],\"toggleOptionSelection\",[32,1]],null],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"checked\"],[\"checkbox\",\"custom-control-input\",[32,1,[\"isSelected\"]]]]]],[2,\"\\n        \"],[10,\"label\"],[14,0,\"custom-control-label\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"input\",\"permission\",\"svg-jar\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/role-permission-select-dropdown-item.hbs"
  });
  _exports.default = _default;
});