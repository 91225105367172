define("experience-studio/components/title-description-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['title-description-select'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var selectors = this.getProperties('titleSelector', 'descriptionSelector');
      var options = this.options || [];
      var items = options.map(function (option) {
        return Ember.Object.create({
          title: option.get(selectors.titleSelector || 'title'),
          description: option.get(selectors.descriptionSelector || 'description'),
          isSelected: option.get('isSelected'),
          option: option
        });
      });
      this.set('selection', items.findBy('isSelected', true));
      this.set('items', items);
    },
    actions: {
      selectOption: function selectOption(item) {
        var items = this.items;
        items.setEach('isSelected', false);
        items.setEach('option.isSelected', false);
        item.set('isSelected', true);
        item.set('option.isSelected', true);
        this.set('selection', item);
        this.sendAction('onSelect', item.option); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});