define("experience-studio/components/role-permission-select-dropdown-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['dropdown-item', 'dropdown-submenu'],
    permission: null,
    optionSelectionObserver: Ember.observer('permission.options.@each.isSelected', function () {
      this.$('.permission-input').prop('indeterminate', false);
      if (this.permission.options.isEvery('isSelected', true)) {
        this.permission.set('isSelected', true);
      } else if (this.permission.options.isEvery('isSelected', false)) {
        this.permission.set('isSelected', false);
      } else {
        this.permission.set('isSelected', true);
        this.$('.permission-input').prop('indeterminate', true);
      }
      this.permission.set('selectedCount', this.permission.options.filterBy('isSelected', true).length);
    }),
    actions: {
      toggleOptionSelection: function toggleOptionSelection(option) {
        option.toggleProperty('isSelected');
      },
      togglePermissionSelection: function togglePermissionSelection() {
        if (this.permission.options.isEvery('isSelected', false)) {
          this.permission.options.setEach('isSelected', true);
        } else {
          this.permission.options.setEach('isSelected', false);
        }
      }
    }
  });
  _exports.default = _default;
});