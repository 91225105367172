define("experience-studio/templates/components/localized-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xOR0hReH",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,5],[[35,10]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"language-list list-unstyled\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[15,0,[31,[[30,[36,5],[[32,1,[\"isSelected\"]],\"active\",\"\"],null]]]],[12],[2,\"\\n          \"],[11,\"a\"],[24,6,\"#\"],[4,[38,6],[[32,0],\"selectLanguage\",[32,1]],null],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[6,[37,5],[[32,1,[\"isDefault\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[15,0,[31,[[34,1]]]],[12],[2,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[15,0,[31,[[34,1]]]],[12],[2,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"no-value-message\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"emptyMessage\",\"formClass\",\"decodedDynamicTextValue\",\"decodedTextValue\",\"isRichTextEnabled\",\"if\",\"action\",\"languages\",\"-track-array\",\"each\",\"isLanguagesVisible\",\"value\"]}",
    "moduleName": "experience-studio/templates/components/localized-text.hbs"
  });
  _exports.default = _default;
});