define("experience-studio/components/priority-indicator-experience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    priority: Ember.computed('group', 'experience', function () {
      if (this.group && this.group.experiences) {
        var experience = this.group.experiences.findBy('id', this.experience.id);
        if (experience) {
          return experience.index;
        }
      }
    })
  });
  _exports.default = _default;
});