define("experience-studio/templates/components/content-tmp-edit-media-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/VmA5Vm7",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"custom-control custom-radio\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"custom-control-input\"],[15,\"checked\",[31,[[30,[36,2],[[30,[36,4],[[35,0,[\"value\"]]],null],\"checked\"],null]]]],[14,4,\"radio\"],[12],[13],[2,\"\\n  \"],[11,\"label\"],[24,0,\"custom-control-label\"],[4,[38,3],[[32,0],\"selectMediaType\",null,true],null],[12],[2,\"No Media\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"custom-control custom-radio\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"custom-control-input\"],[15,\"checked\",[31,[[30,[36,2],[[30,[36,1],[[32,1,[\"val\"]],[35,0,[\"value\"]]],null],\"checked\"],null]]]],[14,4,\"radio\"],[12],[13],[2,\"\\n    \"],[11,\"label\"],[24,0,\"custom-control-label\"],[4,[38,3],[[32,0],\"selectMediaType\",[32,1,[\"val\"]],true],null],[12],[1,[32,1,[\"displayName\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"field\",\"eq\",\"if\",\"action\",\"not\",\"filteredOptions\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-media-type.hbs"
  });
  _exports.default = _default;
});