define("experience-studio/components/content-select-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['content-select-modal-dialog', 'fly-modal'],
    isSelectionInvalid: Ember.computed('desiredContent', function () {
      return !this.desiredContent;
    }),
    desiredContent: Ember.computed('content.{[],@each.isSelected}', function () {
      return this.content.filterBy('isSelected').objectAt(0);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.content.setEach('isSelected', false);
    },
    actions: {
      cancel: function cancel() {
        this.content.setEach('isSelected', false);
        this.send('clearSearch');
        this.set('isOpen', false);
      },
      confirm: function confirm() {
        this.sendAction('selectContent', this.desiredContent); // eslint-disable-line ember/closure-actions
        this.send('clearSearch');
        this.set('isOpen', false);
      },
      handleAction: function handleAction(name, model) {
        if (name === 'select') {
          var content = this.content;
          content.setEach('isSelected', false);
          model.set('isSelected', true);
        }
      },
      clearSearch: function clearSearch() {
        this.contentProperties.set('searchKeyword', '');
        this.send('handleSearch', '');
      },
      onTyping: function onTyping(isTyping) {
        this.set('contentProperties.isTyping', isTyping);
      },
      handleSearch: function handleSearch(keyword) {
        this.sendAction('handleSearch', keyword, 'content-instance', 'contentProperties'); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels(modelName) {
        this.sendAction('loadModels', modelName, 'contentProperties'); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});