define("experience-studio/controllers/experiences/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    groupSortBy: '',
    groupSortOrder: '',
    searchKeyword: '',
    sortedModel: null,
    queryParams: [{
      groupSortBy: 'groupSortBy',
      groupSortOrder: 'groupSortOrder',
      groupSearchKeyword: 'groupSearch'
    }],
    filteredModel: Ember.computed('groupSearchKeyword', 'model.groups', 'sortedModel', function () {
      var searchKeyword = this.get('groupSearchKeyword');
      var model = this.get('model.groups');
      if (!Ember.isEmpty(this.get('sortedModel'))) {
        model = this.get('sortedModel');
      }
      if (searchKeyword) {
        return model.filter(function (item) {
          return item.get('name').toLowerCase().indexOf(searchKeyword) >= 0;
        });
      } else {
        return model;
      }
    }),
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Duplicate',
      icon: 'clone',
      action: 'showDuplicateRecordsModal',
      subActions: []
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: [],
      hasDivider: true
    })])
  });
  _exports.default = _default;
});