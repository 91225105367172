define("experience-studio/controllers/permissions-hub/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    searchKeyword: '',
    sortedModel: null,
    queryParams: [{
      searchKeyword: 'search'
    }],
    filteredModel: Ember.computed('searchKeyword', 'model.roles', 'sortedModel', function () {
      var searchKeyword = this.get('searchKeyword');
      var model = this.get('model.roles');
      if (!Ember.isEmpty(this.get('sortedModel'))) {
        model = this.get('sortedModel');
      }
      if (searchKeyword) {
        return model.filter(function (item) {
          return item.get('name').toLowerCase().indexOf(searchKeyword) >= 0;
        });
      } else {
        return model;
      }
    })
  });
  _exports.default = _default;
});