define("experience-studio/components/concierge-card", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didLocalizedFieldData: function didLocalizedFieldData(value) {
      this.setPreviewImageVisibility(value);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)('.tooltip-field').tooltip();
    },
    didSelectLanguage: Ember.observer('languages.@each.isSelected', function () {
      var language = this.languages.findBy('isSelected', true);
      this.set('rtlPreviewClass', language.direction === 'rtl' ? 'rtl-preview-card' : '');
    })
  });
  _exports.default = _default;
});