define("experience-studio/components/configure-historical-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['configure-historical-location'],
    labels: null,
    selectedLocationLabels: null,
    isExpand: false,
    store: Ember.inject.service(),
    locationPagination: 10,
    labelsOrHistoricalLabel: false,
    frequencyOption: null,
    frequencyValue: null,
    recencyOption: null,
    recencyInThePastUnit: null,
    recencyInThePastValue: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.selectedLocationLabels = [];
      this.set('areaModel.isHasHistoricalPlugin', true);
    },
    getFrequencyOptions: function getFrequencyOptions() {
      return [Ember.Object.create({
        label: 'at least',
        operator: '>=',
        value: 'greq'
      }), Ember.Object.create({
        label: 'at most',
        operator: '<=',
        value: 'lseq'
      }), Ember.Object.create({
        label: 'exactly',
        operator: '=',
        value: 'eq'
      }), Ember.Object.create({
        label: 'less than',
        operator: '<',
        value: 'ls'
      }), Ember.Object.create({
        label: 'more than',
        operator: '>',
        value: 'gr'
      })];
    },
    locationsWithLabelsSham: Ember.computed('locationsWithLabels.[]', 'locationPagination', function () {
      if (this.locationsWithLabels) {
        return this.locationsWithLabels.slice(0, this.locationPagination);
      } else {
        return [];
      }
    }),
    canLoadMore: Ember.computed('locationPagination', 'locationsWithLabelsSham.[]', function () {
      var locations = this.locationsWithLabels;
      return locations && locations.length && this.locationPagination < locations.length;
    }),
    isLoading: Ember.computed('locationsWithLabels.isFulfilled', function () {
      var locationsWithLabels = this.locationsWithLabels;
      return locationsWithLabels && !locationsWithLabels.isFulfilled;
    }),
    locationsWithLabelsText: Ember.computed('areaModel.labels.[]', function () {
      var text = '';
      if (this.areaModel && Array.isArray(this.areaModel.labels)) {
        try {
          var ids = this.areaModel.labels.toArray().getEach('value');
          ids.forEach(function (id, index) {
            text += "<strong>".concat(id, "</strong>");
            if (index < ids.length - 1) {
              text += ' AND ';
            }
          });
        } catch (exception) {
          // TODO: Handle exception
        }
      }
      return text;
    }),
    locationsWithLabels: Ember.computed('areaModel.labels.[]', 'model.isFulfilled', function () {
      var labels = '';
      var promise = null;
      try {
        labels = this.areaModel.labels.toArray().getEach('value').join(',');
      } catch (exception) {
        // TODO: Handle exceptions
      }
      if (!Ember.isEmpty(labels)) {
        promise = this.store.query('location', {
          labelsFormula: "(".concat(labels, ")"),
          limit: 1000000,
          offset: 0,
          sortby: 'createdAt',
          sortorder: 'desc'
        });
      }
      return promise;
    }),
    isDisableAddLabel: Ember.computed('areaModel.labels.[]', function () {
      if (this.areaModel.labels.length === 1) {
        return true;
      }
      return false;
    }),
    selectedLocationLabelsHash: Ember.computed('selectedLocationLabels.[]', function () {
      return this.selectedLocationLabels;
    }),
    frequencyOptions: Ember.computed('model', function () {
      var _frequencyOptions = this.getFrequencyOptions();
      return _frequencyOptions;
    }),
    recencyInThePastOptions: Ember.computed('model', function () {
      return ['minute(s)', 'hour(s)', 'day(s)'];
    }),
    recenyOptions: Ember.computed('model', function () {
      return ['in the past'];
    }),
    actions: {
      didUpdateLabel: function didUpdateLabel() {
        this.set('locationPagination', 10);
        this.areaModel.toggleProperty('didUpdateLabel');
      },
      editLocation: function editLocation(model) {
        if (this.editLocation) {
          this.editLocation(model);
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      expand: function expand(isExpand) {
        this.set('isExpand', isExpand);
      },
      incrementPage: function incrementPage() {
        if (this.locationsWithLabelsSham.length + 10 >= this.locationsWithLabels.length) {
          this.set('locationPagination', this.locationsWithLabels.length);
        } else {
          this.incrementProperty('locationPagination', 10);
        }
      },
      selectValue: function selectValue(model, variable, selection) {
        model.set(variable, selection);
      }
    }
  });
  _exports.default = _default;
});