define("experience-studio/serializers/csvfile", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && payload.data) {
        payload.data.filter(function (item) {
          if (item.status && item.status.toLowerCase() === 'processing') {
            item.willCompleteAt = window.moment().add(1, 'minutes').unix();
          }
          item.updatedAt = item.updatedAt || item.modifiedAt;
          if (item.friendlyName) {
            item.name = item.friendlyName;
          }
          if (Ember.isEmpty(item.fileID)) {
            item.fileID = item.id;
          }
        });
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
  _exports.default = _default;
});