define("experience-studio/components/push-messages-custom-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-message-custom-fields'],
    fields: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    model: null,
    nameErrorMessage: '',
    typeErrorMessage: '',
    valueErrorMessage: '',
    alphanumericRegEx: /^[a-zA-Z]+[a-z0-9]*$/i,
    dataTypes: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      name: 'Decimal Number',
      type: 'float'
    }), Ember.Object.create({
      name: 'Image',
      type: 'image'
    }), Ember.Object.create({
      name: 'Text',
      type: 'string'
    }), Ember.Object.create({
      name: 'Whole Number',
      type: 'int'
    }), Ember.Object.create({
      name: 'Yes/No',
      type: 'boolean'
    })],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var model = this.model;
      this.fields.clear();
      if (window.Object.keys(model).length) {
        for (var key in model) {
          if (model.hasOwnProperty(key) && key !== 'images') {
            if (this.isString(model.get(key))) {
              this.fields.pushObject(Ember.Object.create({
                isEditModeOn: false,
                name: key,
                type: 'string',
                value: model.get(key)
              }));
            }
            if (this.isBoolean(model.get(key))) {
              if (model.get(key) === false) {
                this.fields.pushObject(Ember.Object.create({
                  isEditModeOn: false,
                  name: key,
                  type: 'boolean',
                  value: 'no'
                }));
              }
              if (model.get(key) === true) {
                this.fields.pushObject(Ember.Object.create({
                  isEditModeOn: false,
                  name: key,
                  type: 'boolean',
                  value: 'yes'
                }));
              }
            }
            if (!this.isBoolean(model.get(key)) && this.isInteger(model.get(key))) {
              this.fields.pushObject(Ember.Object.create({
                isEditModeOn: false,
                name: key,
                type: 'int',
                value: model.get(key)
              }));
            }
            if (this.isFloat(model.get(key))) {
              this.fields.pushObject(Ember.Object.create({
                isEditModeOn: false,
                name: key,
                type: 'float',
                value: model.get(key)
              }));
            }
          }
        }
        if (model.hasOwnProperty('images')) {
          for (var image in model.get('images')) {
            if (model.get('images').hasOwnProperty(image)) {
              if (this.isImage(model.get('images')[image])) {
                this.fields.pushObject(Ember.Object.create({
                  isEditModeOn: false,
                  name: image,
                  type: 'image',
                  value: model.get('images')[image]
                }));
              }
            }
          }
        }
      }
    },
    didUpdateFields: Ember.observer('fields.@each.name', 'fields.@each.type', 'fields.@each.value', function () {
      var _this = this;
      var customFields = Ember.Object.create();
      var fields = this.fields;
      var images = Ember.Object.create();
      if (fields.length) {
        fields.forEach(function (item) {
          if (!item.get('selectedDataType')) {
            item.set('selectedDataType', _this.dataTypes.filterBy('type', item.get('type')).objectAt(0));
          }
          var type = item.get('selectedDataType.type');
          var name = (item.get('name') || '').trim();
          var value;
          if (_this.isString(item.get('value'))) {
            value = (item.get('value') || '').trim();
          }
          value = item.get('value') || '';
          if (type && name && value && _this.alphanumericRegEx.test(name)) {
            if (type === 'image') {
              images.set(name, value);
            } else {
              customFields.set(name, _this.convertValue(type, value));
            }
          }
        });
      }
      if (window.Object.keys(customFields).length) {
        if (window.Object.keys(images).length) {
          customFields.set('images', images);
        }
        this.set('model', customFields);
      } else {
        this.set('model', null);
      }
    }),
    convertValue: function convertValue(type, value) {
      var convertValue;
      var val;
      switch (type) {
        case 'boolean':
          val = false;
          if (value.toLowerCase() === 'yes') {
            val = true;
          }
          if (value.toLowerCase() === 'no') {
            val = false;
          }
          return val;
        case 'float':
          convertValue = 0.0;
          try {
            convertValue = parseFloat(value);
          } catch (exception) {
            /* TODO: Handle exception */
          }
          return convertValue;
        case 'image':
          return value;
        case 'string':
          return value;
        case 'int':
          convertValue = 0;
          try {
            convertValue = parseInt(value);
          } catch (exception) {
            /* TODO: Handle exception */
          }
          return convertValue;
        default:
          return value;
      }
    },
    isInteger: function isInteger(n) {
      return Number(n) && n % 1 === 0;
    },
    isString: function isString(value) {
      var toString = window.Object.prototype.toString;
      return toString.call(value) === '[object String]';
    },
    isYesNo: function isYesNo(value) {
      var isValid = false;
      if (value === 'yes' || value === 'no') {
        isValid = true;
      } else {
        isValid = false;
      }
      return isValid;
    },
    isBoolean: function isBoolean(value) {
      if (value === 'true') {
        value = true;
      }
      if (value === 'false') {
        value = false;
      }
      var toString = window.Object.prototype.toString;
      return value === true || value === false || toString.call(value) === '[object Boolean]';
    },
    isImage: function isImage(value) {
      return value.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    isFloat: function isFloat(n) {
      return Number(n) && n % 1 !== 0;
    },
    actions: {
      add: function add() {
        var _this2 = this;
        var fields = this.fields;
        fields.forEach(function (item) {
          if (Ember.isEmpty(item.get('name')) && Ember.isEmpty(item.get('value'))) {
            fields.removeObject(item);
          } else {
            _this2.send('done', item);
          }
        });
        this.fields.pushObject(Ember.Object.create({
          isEditModeOn: true,
          name: '',
          type: null,
          value: ''
        }));
      },
      selectValue: function selectValue(model, variable, selection) {
        if (model) {
          model.set(variable, selection);
        } else {
          this.set(variable, selection);
        }
      },
      done: function done(item) {
        var isValid = true;
        item.setProperties({
          nameErrorMessage: '',
          typeErrorMessage: '',
          valueErrorMessage: ''
        });
        if (!item.get('name')) {
          item.set('nameErrorMessage', 'Enter name');
          isValid = false;
        }
        if (!item.get('selectedDataType.type')) {
          item.set('typeErrorMessage', 'Select type');
          isValid = false;
        }
        if (!item.get('value')) {
          item.set('valueErrorMessage', 'Enter value');
          isValid = false;
        }
        if (isValid) {
          if (item.get('selectedDataType.type') === 'int') {
            isValid = this.isInteger(item.get('value'));
          } else if (item.get('selectedDataType.type') === 'float') {
            isValid = this.isFloat(item.get('value'));
          } else if (item.get('selectedDataType.type') === 'boolean') {
            isValid = this.isYesNo(item.get('value').toLowerCase());
          } else if (item.get('selectedDataType.type') === 'image') {
            isValid = this.isImage(item.get('value'));
          } else if (item.get('selectedDataType.type') === 'string') {
            isValid = this.isString(item.get('value'));
          }

          // TODO: Validate image type

          if (!isValid) {
            var dataTypeErrorMesssage = "Enter ".concat(item.get('selectedDataType.name').toLowerCase(), " value");
            item.set('valueErrorMessage', dataTypeErrorMesssage);
          }
        }
        if (isValid && !this.alphanumericRegEx.test(item.get('name'))) {
          item.set('nameErrorMessage', 'Invalid name');
          isValid = false;
        }
        if (isValid) {
          var name = item.get('name').toLowerCase();
          var duplicateItems = this.fields.filter(function (field) {
            return name === field.get('name').toLowerCase();
          });
          if (duplicateItems.get('length') > 1) {
            item.set('nameErrorMessage', 'Duplicate field');
            isValid = false;
          }
        }
        if (isValid) {
          item.set('isEditModeOn', false);
        } else {
          item.set('isEditModeOn', true);
        }
      },
      edit: function edit(item) {
        item.set('isEditModeOn', true);
      },
      remove: function remove(item) {
        this.fields.removeObject(item);
      }
    }
  });
  _exports.default = _default;
});