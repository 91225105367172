define("experience-studio/router", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    previousRouteNames: null,
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.intl.setLocale(['en-us']);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.previousRouteNames = [];
    },
    getPreviousRouteName: function getPreviousRouteName() {
      var previousRouteName = '';
      if (this.previousRouteNames.length >= 2) {
        previousRouteName = this.previousRouteNames[this.previousRouteNames.length - 2];
      }
      return previousRouteName;
    },
    trackPreviousRoutes: Ember.on('didTransition', function () {
      if (this.previousRouteNames.length >= 2) {
        this.previousRouteNames = [this.previousRouteNames[1], this.currentRouteName];
      } else {
        this.previousRouteNames.pushObject(this.currentRouteName);
      }
    })
  });
  Router.map(function () {
    this.route('analytics-v1');
    this.route('analytics-v2', function () {
      this.route('content-instance', {
        path: 'content/:model_id'
      });
      this.route('context-rule', {
        path: 'context-rules/:model_id'
      });
      this.route('push-notification', {
        path: 'push-notifications/:model_id'
      });
    });
    this.route('analytics', function () {
      this.route('users');
      this.route('engagement');
    });
    this.route('authenticate');
    this.route('authorize', function () {
      this.route('saml');
    });
    this.route('content-hub', function () {
      // Tabs
      this.route('index', {
        path: '/'
      }); // default path - (content tab selected)
      this.route('location'); // feature-flagged tab required by TD

      // blueprint content [template, instance]
      this.route('blueprint-templates');
      this.route('blueprint-template', {
        path: '/blueprint-template/:content_template_id'
      }, function () {
        this.route('create');
      });
      this.route('blueprint-instance', {
        path: '/blueprint-instance/:blueprint_instance_id'
      }, function () {
        this.route('edit');
      });

      // regular content [template, instance]
      this.route('content-templates', {
        path: '/templates'
      });
      this.route('content-template', {
        path: '/templates/:content_template_id'
      }, function () {
        this.route('create');
        this.route('create-v2');
        this.route('form', function () {
          this.route('create');
        });
      });
      this.route('content-instance', {
        path: '/content/:content_instance_id'
      }, function () {
        this.route('edit');
        this.route('edit-v2');
        this.route('form', function () {
          this.route('edit');
        });
      });
    });
    this.route('content-hub-v2', function () {
      this.route('index', {
        path: '/'
      }); // default path
    });

    this.route('context-hub', function () {
      this.route('create');
      this.route('create-v2'); // for templated experience
      this.route('rule', function () {
        this.route('edit', {
          path: '/edit/:rule_id'
        });
      });
      this.route('context-data-upload');
      this.route('rule-v2');
      this.route('location', {
        path: 'locations'
      });
      this.route('upload');
    });
    this.route('experiences', function () {
      this.route('create');
      this.route('edit', {
        path: '/edit/:experience_id'
      });
      this.route('groups');
    });
    this.route('experiences-v2');
    this.route('groups-v2');
    this.route('communications-hub', function () {
      this.route('triggered-emails', function () {
        this.route('create');
      });
      this.route('push-messages-v2', function () {
        this.route('create');
        this.route('edit', {
          path: '/edit/:push_notification_id'
        });
      });
      this.route('push-messages-v4', function () {
        this.route('create');
        this.route('edit', {
          path: '/edit/:push_notification_id'
        });
      });
      this.route('push-messages-v3');
    });
    this.route('permissions-hub', {
      path: '/manage-teams'
    }, function () {
      this.route('access-levels');
      this.route('users');
      this.route('invitations');
      this.route('roles');
    });
    this.route('settings', {
      path: '/preferences'
    }, function () {
      this.route('general');
      this.route('beta');
    });
    this.route('404', {
      path: '/*path'
    });
    this.route('no-access');
    this.route('test2');
  });
  var _default = Router;
  _exports.default = _default;
});