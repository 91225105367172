define("experience-studio/templates/components/flight-sidebar-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ziweXDWA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/logo.svg\"],[14,0,\"logo large-image\"],[14,\"alt\",\"Experience Studio\"],[15,\"onload\",[30,[36,1],[[32,0],[35,0]],null]],[12],[13]],\"hasEval\":false,\"upvars\":[\"onload\",\"action\"]}",
    "moduleName": "experience-studio/templates/components/flight-sidebar-logo.hbs"
  });
  _exports.default = _default;
});