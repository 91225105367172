define("experience-studio/templates/components/flight-sidebar-dropdown-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FXRwutJ/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"class\",\"route\"],[\"dropdown-item gtm-left-sidebar-new-dropdown-item\",[35,3]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0]],null]],[2,\" \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-icon\",\"svg-jar\",\"link-name\",\"link\",\"link-to\"]}",
    "moduleName": "experience-studio/templates/components/flight-sidebar-dropdown-item.hbs"
  });
  _exports.default = _default;
});