define("experience-studio/components/localized-input-dynamic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['localized-input-dynamic'],
    isLanguagesVisible: true,
    isRequired: false,
    isTextarea: false,
    label: '',
    languages: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    model: null,
    placeholder: '',
    property: '',
    value: '',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var language;
      var languages = this.languages;
      if (!Ember.isEmpty(languages)) {
        language = languages.filterBy('isDefault').objectAt(0);
        if (!language) {
          language = languages.objectAt(0);
        }
        this.send('selectLanguage', language);
      }
    },
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    didUpdateLanguage: Ember.observer('languages.@each.isSelected', function () {
      var selectedLanguageCode = this.selectedLanguageCode;
      var value;
      if (!selectedLanguageCode) {
        var selectedLanguage = this.languages.findBy('isSelected', true);
        if (selectedLanguage) {
          selectedLanguageCode = selectedLanguage.get('id');
        }
      }
      if (selectedLanguageCode) {
        value = this.get("model.".concat(selectedLanguageCode, ".").concat(this.property));
      }
      this.set('value', value);
    }),
    didUpdateValue: Ember.observer('value', function () {
      var selectedLanguageCode = this.selectedLanguageCode;
      var value = this.value;
      if (!Ember.isEmpty(selectedLanguageCode)) {
        if (Ember.isNone(this.model)) {
          this.set('model', Ember.Object.create({}));
        }
        if (Ember.isNone(this.get("model.".concat(selectedLanguageCode)))) {
          this.set("model.".concat(selectedLanguageCode), Ember.Object.create({}));
        }
        this.set("model.".concat(selectedLanguageCode, ".").concat(this.property), value);
      }
    }),
    actions: {
      selectLanguage: function selectLanguage(language) {
        this.languages.setEach('isSelected', false);
        language.set('isSelected', true);
      }
    }
  });
  _exports.default = _default;
});