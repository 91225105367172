define("experience-studio/mixins/localized-input-text-dynamic-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    isLanguagesVisible: true,
    languages: [],
    model: null,
    value: Ember.computed.alias('modelSham.value'),
    property: '',
    modelSham: Ember.computed('selectedLanguageCode', 'property', function () {
      var languageCode = this.selectedLanguageCode;
      var property = this.property;
      var modelSham;
      if (languageCode) {
        if (this.property) {
          modelSham = Ember.Object.extend({
            value: Ember.computed.alias("model.".concat(languageCode, ".").concat(property))
          }).create({
            model: this.model
          });
        }
      } else {
        modelSham = Ember.Object.create({});
      }
      return modelSham;
    }),
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    actions: {
      selectLanguage: function selectLanguage(language) {
        this.languages.setEach('isSelected', false);
        language.set('isSelected', true);
      }
    }
  });
  _exports.default = _default;
});