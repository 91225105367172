define("experience-studio/components/custom-field--image", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    intl: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    triggerValidation: function triggerValidation() {
      if (!this.field.value && this.field.isRequired) {
        this.set('isDirty', true);
        this.send('onValue', '');
      }
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var field = this.field;
      field.set('errorMessages', []);
      if (!Ember.isEmpty(value) && !value.startsWith('https://')) {
        field.errorMessages.pushObject(this.intl.t('errors.missing.url.https'));
        isValid = false;
      }
      if (!Ember.isEmpty(value) && !value.match(/(.*\.[a-z]{3,4}$)/i)) {
        field.errorMessages.pushObject(this.intl.t('errors.invalid.image.extension'));
        isValid = false;
      }
      this._super(value, isValid);
    },
    imageValueObserver: Ember.observer('field.value', function () {
      this.send('onValue', this.get('field.value') || undefined); // eslint-disable-line ember/closure-actions
    })
  });
  _exports.default = _default;
});