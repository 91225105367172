define("experience-studio/components/context-hub-attribute-area", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isDetectingLocation'],
    classNames: ['context-hub-attribute-area'],
    drawingManager: null,
    errorMessage: null,
    map: null,
    overlay: null,
    utility: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;
      if (!window.google || !window.google.maps) {
        var url = "//maps.googleapis.com/maps/api/js?".concat(_jquery.default.param({
          key: _environment.default.APP.googleMapKey,
          libraries: 'drawing,geometry,places',
          v: 3.29
        }));
        _jquery.default.getScript(url).done(function () {
          self.showGoogleMap(); // eslint-disable-line
        }).fail(function (response) {
          if (response && response.responseText) {
            self.set('errorMessage', response.responseText);
          }
        });
      } else {
        this.showGoogleMap();
      }
    },
    detectLocation: function detectLocation() {
      var self = this;
      if (window.navigator && window.navigator.geolocation) {
        this.$().find('.location-button-container').addClass('is-detecting');
        navigator.geolocation.getCurrentPosition(function (geoposition) {
          if (geoposition && geoposition.coords && geoposition.coords.latitude) {
            self.$().find('.location-button-container').removeClass('is-detecting');
            var map = self.get('map');
            if (map) {
              map.setCenter({
                lat: geoposition.coords.latitude,
                lng: geoposition.coords.longitude
              });
              map.setZoom(16);
            }
          }
        });
      }
    },
    resetShape: function resetShape() {
      var overlay = this.overlay;
      if (overlay) {
        overlay.setMap(null);
      }
      this.updatePluginData(null);
    },
    setupOverlay: function setupOverlay(event) {
      this.drawingManager.setDrawingMode(null);
      this.set('overlay', event.overlay);
      this.updatePluginData(event.overlay);
    },
    showDrawingManager: function showDrawingManager() {
      var self = this;
      var googleMaps = window.google.maps;
      var drawingManager = new googleMaps.drawing.DrawingManager({
        drawingControlOptions: {
          drawingModes: [googleMaps.drawing.OverlayType.POLYGON],
          position: googleMaps.ControlPosition.RIGHT_TOP
        },
        polygonOptions: {
          fillColor: '#29BFED',
          strokeColor: '#29BFED'
        },
        map: this.map
      });
      drawingManager.addListener('overlaycomplete', function (event) {
        return self.setupOverlay(event);
      });
      this.set('drawingManager', drawingManager);
    },
    showGoogleMap: function showGoogleMap() {
      var _this = this;
      var googleMaps = window.google.maps;
      this.set('map', new googleMaps.Map(this.$('.map').get(0), {
        center: {
          lat: -37.111112,
          lng: -12.28834
        },
        disableDefaultUI: true,
        styles: [{
          'featureType': 'water',
          'elementType': 'geometry',
          'stylers': [{
            'color': '#e9e9e9'
          }, {
            'lightness': 17
          }]
        }, {
          'featureType': 'landscape',
          'elementType': 'geometry',
          'stylers': [{
            'color': '#f5f5f5'
          }, {
            'lightness': 20
          }]
        }, {
          'featureType': 'road.highway',
          'elementType': 'geometry.fill',
          'stylers': [{
            'color': '#ffffff'
          }, {
            'lightness': 17
          }]
        }, {
          'featureType': 'road.highway',
          'elementType': 'geometry.stroke',
          'stylers': [{
            'color': '#ffffff'
          }, {
            'lightness': 29
          }, {
            'weight': 0.2
          }]
        }, {
          'featureType': 'road.arterial',
          'elementType': 'geometry',
          'stylers': [{
            'color': '#ffffff'
          }, {
            'lightness': 18
          }]
        }, {
          'featureType': 'road.local',
          'elementType': 'geometry',
          'stylers': [{
            'color': '#ffffff'
          }, {
            'lightness': 16
          }]
        }, {
          'featureType': 'poi',
          'elementType': 'geometry',
          'stylers': [{
            'color': '#f5f5f5'
          }, {
            'lightness': 21
          }]
        }, {
          'featureType': 'poi.park',
          'elementType': 'geometry',
          'stylers': [{
            'color': '#dedede'
          }, {
            'lightness': 21
          }]
        }, {
          'elementType': 'labels.text.stroke',
          'stylers': [{
            'visibility': 'on'
          }, {
            'color': '#ffffff'
          }, {
            'lightness': 16
          }]
        }, {
          'elementType': 'labels.text.fill',
          'stylers': [{
            'saturation': 36
          }, {
            'color': '#333333'
          }, {
            'lightness': 40
          }]
        }, {
          'elementType': 'labels.icon',
          'stylers': [{
            'visibility': 'off'
          }]
        }, {
          'featureType': 'transit',
          'elementType': 'geometry',
          'stylers': [{
            'color': '#f2f2f2'
          }, {
            'lightness': 19
          }]
        }, {
          'featureType': 'administrative',
          'elementType': 'geometry.fill',
          'stylers': [{
            'color': '#fefefe'
          }, {
            'lightness': 20
          }]
        }, {
          'featureType': 'administrative',
          'elementType': 'geometry.stroke',
          'stylers': [{
            'color': '#fefefe'
          }, {
            'lightness': 17
          }, {
            'weight': 1.2
          }]
        }],
        zoomControl: true,
        zoom: 11
      }));
      this.showDrawingManager();
      this.showLocationControl();
      this.showSearchControl();
      this.initPluginData();
      googleMaps.event.addListenerOnce(this.map, 'idle', function () {
        return googleMaps.event.trigger(_this, 'resize');
      });
    },
    initPluginData: function initPluginData() {
      var paths;
      try {
        var pluginData = null;
        var shapes = null;
        pluginData = this.get('model._rule.pluginData');
        pluginData = pluginData[window.Object.keys(pluginData)[0]];
        pluginData = this.utility.atob(pluginData);
        shapes = pluginData.area.shapes || pluginData.area.Shapes;
        paths = shapes[0];
      } catch (exception) {
        // TODO: Handle exception
      }
      if (!Ember.isEmpty(paths)) {
        var googleMaps = window.google.maps;
        var bounds = new googleMaps.LatLngBounds();
        var map = this.map;
        var polygonOptions = this.drawingManager.polygonOptions;
        this.setupOverlay({
          overlay: new window.google.maps.Polygon({
            fillColor: polygonOptions.fillColor,
            map: map,
            paths: paths,
            strokeColor: polygonOptions.strokeColor
          })
        });
        paths.forEach(function (coordinate) {
          return bounds.extend(coordinate);
        });
        map.fitBounds(bounds);
      }
    },
    showLocationControl: function showLocationControl() {
      var self = this;
      var googleMaps = window.google.maps;
      var $control = (0, _jquery.default)('<div/>').addClass('location-button-container').append('<button type="button"></button');
      $control.find('button').click(function () {
        self.detectLocation();
        return false;
      });
      this.map.controls[googleMaps.ControlPosition.RIGHT_BOTTOM].push($control.get(0));
    },
    showSearchControl: function showSearchControl() {
      var self = this;
      var googleMaps = window.google.maps;
      var $control = (0, _jquery.default)('<div/>').addClass('search-container');
      var markup = '';
      markup += '<div class="form-group row">';
      markup += '  <div class="col-sm-8">';
      markup += '    <input type="text" class="form-control" placeholder="Search" />';
      markup += '  </div>';
      markup += '  <div class="col-sm-4">';
      markup += '    <button type="button" class="btn btn-primary">Reset</button>';
      markup += '  </div>';
      markup += '</div>';
      $control.append(markup);
      $control.find('button').click(function () {
        self.resetShape();
        return false;
      });
      var autocomplete = new googleMaps.places.Autocomplete($control.find('input').get(0));
      autocomplete.bindTo('bounds', this.map);
      autocomplete.addListener('place_changed', function () {
        var map = self.get('map');
        var place = autocomplete.getPlace();
        if (place.geometry && place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else if (place.geometry && place.geometry.location) {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
      });
      this.map.controls[googleMaps.ControlPosition.LEFT_TOP].push($control.get(0));
    },
    updatePluginData: function updatePluginData(overlay) {
      var model = this.model;
      if (model) {
        var data = null;
        if (overlay) {
          var coordinates = [];
          var vertices = overlay.getPath().getArray();
          vertices.forEach(function (item) {
            return coordinates.pushObject(item.toJSON());
          });
          coordinates.pushObject(vertices.objectAt(0).toJSON());
          data = JSON.stringify({
            area: {
              shapes: [coordinates]
            }
          });
          data = btoa(encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode("0x".concat(p1));
          }));
        }
        model.set('pluginData', data);
      }
    }
  });
  _exports.default = _default;
});