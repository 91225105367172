define("experience-studio/templates/content-hub/content-template/form/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9WrPr+Mu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-hub-create-edit-form-route route\"],[12],[2,\"\\n\\n  \"],[10,\"header\"],[14,0,\"page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"page-header--title\"],[12],[2,\"New Form\"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"page-header--cancel\"],[4,[38,4],[[32,0],\"confirmCancel\"],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"times\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"route-error-message\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"type\",\"onClosed\"],[\"danger\",[30,[36,2],[\"clearErrorMessage\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"alert-icon\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"exclamation-triangle\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"alert-text\"],[12],[2,\"\\n          \"],[1,[34,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"main\"],[14,0,\"page-main\"],[12],[2,\"\\n    \"],[1,[30,[36,10],null,[[\"fetchLabels\",\"languages\",\"model\",\"questions\",\"selectedLabels\"],[\"fetchLabels\",[35,9],[35,8],[35,7],[35,6]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"footer\"],[14,0,\"page-footer\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-primary btn-save\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"validate\"],null],[12],[2,\"Create\"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link btn-cancel\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"confirmCancel\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"errorMessage\",\"route-action\",\"fb-bs-alert\",\"action\",\"if\",\"selectedLabels\",\"questions\",\"model\",\"languages\",\"edit-form\"]}",
    "moduleName": "experience-studio/templates/content-hub/content-template/form/create.hbs"
  });
  _exports.default = _default;
});