define("experience-studio/components/context-rule-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['context-rule-selector'],
    selectedRuleSham: Ember.computed('selectedRule', function () {
      return [this.selectedRule];
    }),
    actions: {
      selectRule: function selectRule(model) {
        this.sendAction('selectRule', model); // eslint-disable-line ember/closure-actions
      },
      deselectRule: function deselectRule() {
        this.sendAction('deselectRule'); // eslint-disable-line ember/closure-actions
      },
      showRuleSelectModal: function showRuleSelectModal() {
        this.set('isRuleSelectModalOpen', true);
      },
      handleSearch: function handleSearch(keyword, modelName, propertiesVar) {
        this.sendAction('handleSearch', keyword, modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels(modelName, propertiesVar) {
        this.sendAction('loadModels', modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});