define("experience-studio/routes/content-hub/content-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    contextRuleUtility: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
      this.controller.set('projectCustomTemplates', this.currentModel.projectCustomTemplates);
      var tabsData = this.controller.get('tabsData');
      tabsData.forEach(function (field, index) {
        Ember.set(field, 'isActive', index === 0);
      });
      this.controller.set('tabsData', tabsData);
    },
    model: function model() {
      return Ember.RSVP.hash({
        contentTemplates: this.store.findAll('content-template', {
          reload: true
        }),
        projectCustomTemplates: this.store.queryRecord('project-config', {
          setting: 'project-custom-content-templates'
        })
      });
    },
    actions: {
      closeModal: function closeModal() {
        this.controller.set('contentInstanceModalData', null);
      },
      createInstance: function createInstance() {
        var contentInstanceModalData = this.controller.get('contentInstanceModalData');
        var contentTemplateRouteData = this.controller.get('contentTemplateRouteData');
        contentTemplateRouteData.contentInstanceModalData = contentInstanceModalData;
        this.transitionTo('content-hub.content-template', contentTemplateRouteData);
        this.controller.set('contentInstanceModalData', null);
      },
      cancel: function cancel() {
        var previousRouteName = this._router.getPreviousRouteName();
        if (previousRouteName) {
          this.transitionTo(previousRouteName);
        } else {
          window.history.back();
        }
      },
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      create: function create(contentTemplate) {
        var controller = this.controller;
        if (controller.isTabShow && this.contextRuleUtility.isConciergeTemplate(contentTemplate.type)) {
          var contentInstance = this.store.createRecord('contentInstance', {
            contentTemplateId: contentTemplate.id,
            description: contentTemplate.description,
            iconUrl: contentTemplate.iconUrl,
            isContextLocked: true,
            localizations: contentTemplate.localizations,
            labels: contentTemplate.labels,
            name: contentTemplate.name,
            templateType: contentTemplate.type,
            selectedLabels: contentTemplate.selectedLabels,
            isConciergeTemplate: true,
            internal: true
          });
          this.controller.set('contentInstanceModalData', contentInstance);
          this.controller.set('contentTemplateRouteData', contentTemplate);
        } else {
          this.transitionTo('content-hub.content-template', contentTemplate);
        }
      },
      doubleClickContentTemplate: function doubleClickContentTemplate(campaignTemplate) {
        this.send('selectContentTemplate', campaignTemplate);
        this.send('validate');
      },
      selectContentTemplate: function selectContentTemplate(campaignTemplate) {
        if (campaignTemplate.get('isSelected')) {
          campaignTemplate.set('isSelected', false);
        } else {
          this.currentModel.contentTemplates.setEach('isSelected', false);
          campaignTemplate.set('isSelected', true);
        }
      },
      promptChangeTab: function promptChangeTab(value) {
        var tabsData = this.controller.get('tabsData');
        tabsData.forEach(function (field) {
          Ember.set(field, 'isActive', field.displayName === value);
        });
        this.controller.set('tabsData', tabsData);
      },
      tabSelectContentTemplate: function tabSelectContentTemplate(campaignTemplate) {
        if (event.key === 'Enter') {
          this.send('doubleClickContentTemplate', campaignTemplate);
        }
      },
      validate: function validate() {
        var controller = this.controller;
        var errorMessage;
        var selectedTemplate = controller.model.contentTemplates.findBy('isSelected', true);
        if (!selectedTemplate) {
          errorMessage = 'Select Content.';
        }
        controller.set('errorMessage', errorMessage);
        if (!errorMessage) {
          this.send('create', selectedTemplate);
        }
      },
      willTransition: function willTransition() {
        var controller = this.controller;
        controller.setProperties({
          errorMessage: ''
        });
        controller.model.contentTemplates.setEach('isSelected', false);
        return true;
      }
    }
  });
  _exports.default = _default;
});