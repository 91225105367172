define("experience-studio/components/av5-basic-table", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Presents a simple table view of data.
  
    Usages:
  
      {{av5-basic-table
        data=tableData
        sortBy=column
        isLoading=tableIsLoading
        updateTable=(action 'updateTable')
        pageSize=pageSize}}
  
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-basic-table'],
    utility: Ember.inject.service(),
    headers: null,
    data: null,
    isLoading: false,
    // COMPUTED ------------------------------------------------------------------
    isSortDesc: Ember.computed('queryParams.sortOrder', function () {
      return this.queryParams.sortOrder === 'desc';
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.queryParams) {
        this.set('queryParams', Ember.Object.create({
          sortBy: '',
          sortOrder: 'desc',
          limit: 5,
          offset: 0
        }));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // currently, the stylings of the datapoints are handled by a helper
      // perhaps we could migrate them to these if performance becomes an issue
      (0, _jquery.default)(this.element).find('.td .value').each(function (idx, el) {
        (0, _jquery.default)(el).tooltip('dispose');
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!Ember.isEmpty(this.headers)) {
        (0, _jquery.default)(this.element).find('.title').each(function (index, el) {
          (0, _jquery.default)(el).tooltip({
            trigger: 'hover',
            placement: 'auto',
            container: 'body',
            title: _this.headers.objectAt(index).tooltip || '',
            template: _this.utility.generateStandardTooltipMarkup(_this.classNames.join(' '))
          });
        });
      }
    },
    didRender: function didRender() {
      var _this2 = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('td .value').each(function (idx, el) {
        var tooltip = _this2.generateDataValueTooltip(idx);
        if (tooltip) {
          (0, _jquery.default)(el).tooltip({
            trigger: 'hover',
            placement: 'auto',
            container: 'body',
            title: tooltip,
            template: _this2.utility.generateStandardTooltipMarkup(_this2.classNames.join(' '))
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!Ember.isEmpty(this.headers)) {
        (0, _jquery.default)(this.element).find('.title').each(function (index, el) {
          (0, _jquery.default)(el).tooltip('dispose');
        });
      }
    },
    // FUNCTIONS -----------------------------------------------------------------
    generateDataValueTooltip: function generateDataValueTooltip(idx) {
      var row = Math.floor(idx / this.headers.length);
      var column = idx % this.headers.length;
      if (this.data.objectAt(row) && this.data.objectAt(row).objectAt(column)) {
        var value = this.data.objectAt(row).objectAt(column).value;
        return value && value.length > 40 ? value : '';
      }
      return '';
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      updateSortBy: function updateSortBy(clickedHeader) {
        if (clickedHeader.isSortable) {
          var selectedHeader = this.headers.find(function (header) {
            return header.isSelected === true;
          });
          if (!(selectedHeader === clickedHeader)) {
            this.queryParams.setProperties({
              sortOrder: 'desc',
              sortBy: clickedHeader.sortByVar
            });
            selectedHeader.set('isSelected', false);
            clickedHeader.set('isSelected', true);
            this.updateTable();
          }
        }
      },
      updateSortOrder: function updateSortOrder(clickedHeader) {
        var selectedHeader = this.headers.find(function (header) {
          return header.isSelected === true;
        });
        if (selectedHeader === clickedHeader) {
          this.queryParams.setProperties({
            sortOrder: this.queryParams.sortOrder === 'desc' ? 'asc' : 'desc'
          });
          this.updateTable();
        }
      }
    }
  });
  _exports.default = _default;
});