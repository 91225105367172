define("experience-studio/templates/components/four-o-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "P+xVvyFf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"particles-js\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"texts\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"Error 404\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"ooops, something went wrong\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "experience-studio/templates/components/four-o-four.hbs"
  });
  _exports.default = _default;
});