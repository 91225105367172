define("experience-studio/models/content-blueprint", ["exports", "ember-data", "experience-studio/models/content-instance", "experience-studio/mixins/recently-modified-item"], function (_exports, _emberData, _contentInstance, _recentlyModifiedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // content blueprint inherits all properties from content instance
  var _default = _contentInstance.default.extend(_recentlyModifiedItem.default, {
    // rule object is not persisted to the back-end (only used to create contentCriteria)
    // It is removed in the serialization step
    rule: _emberData.default.attr(),
    ruleId: _emberData.default.attr('string'),
    // This field is important! (It is the engine of nearby location)
    // It is also set in the rule serializer before sending request
    contentCriteria: _emberData.default.attr(),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isDraft: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    content: _emberData.default.attr()
  });
  _exports.default = _default;
});