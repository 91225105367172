define("experience-studio/controllers/content-hub/content-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    isTabShow: Ember.computed('projectCustomTemplates', function () {
      var categories = this.get('projectCustomTemplates.config.categories');
      var tabsData = this.tabsData;
      if (categories && categories.length > 0 && tabsData.length > 1) {
        return true;
      } else {
        return false;
      }
    }),
    // return tab list for the new content page
    tabsData: Ember.computed('projectCustomTemplates', function () {
      var _this = this;
      var tabs = [];
      var categories = this.get('projectCustomTemplates.config.categories');
      if (categories && categories.length > 0) {
        this.projectCustomTemplates.config.categories.forEach(function (field) {
          var tab = Ember.Object.create({
            displayName: field.displayName,
            templatesList: _this.getTemplateList(field.labels),
            isActive: false
          });
          tabs.pushObject(tab);
        });
      }
      if (!Ember.isEmpty(tabs)) {
        tabs[0].isActive = true;
      }
      return tabs;
    }),
    getTemplateList: function getTemplateList(labels) {
      var templates = this.model.contentTemplates;
      var templateList = [];
      if (labels.length > 0) {
        templateList = templates.filter(function (site) {
          var siteLabels = site.get('labels');
          if (Array.isArray(siteLabels) && siteLabels.length === labels.length) {
            return siteLabels.every(function (val) {
              return labels.indexOf(val) >= 0;
            });
          }
          return false;
        });
      } else {
        var categories = this.projectCustomTemplates.config.categories;
        templateList = templates.filter(function (site) {
          var isCustom = true;
          categories.forEach(function (field) {
            var siteLabels = site.get('labels');
            if (field.labels.length > 0 && Array.isArray(siteLabels) && siteLabels.length === field.labels.length) {
              var isSame = siteLabels.every(function (val) {
                return field.labels.indexOf(val) >= 0;
              });
              isCustom = isSame ? false : true;
            }
          });
          return isCustom;
        });
      }
      return templateList;
    }
  });
  _exports.default = _default;
});