define("experience-studio/templates/components/file-browser-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jaYZak86",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"file-col preview\"],[12],[2,\"\\n\"],[6,[37,3],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,1]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[34,0,[\"url\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"file-col name\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"line-wrap\"],[12],[1,[35,0,[\"fileName\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"file-col date\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"line-wrap\"],[12],[2,\"\\n    \"],[1,[30,[36,4],[[35,0,[\"createdAt\"]],\"MMMM DD YYYY\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"file-col size\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"line-wrap\"],[12],[1,[35,0,[\"sizeStr\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"file\",\"fileIcon\",\"svg-jar\",\"if\",\"moment-format\"]}",
    "moduleName": "experience-studio/templates/components/file-browser-item.hbs"
  });
  _exports.default = _default;
});