define("experience-studio/helpers/increment-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.incrementNumber = incrementNumber;
  function incrementNumber(params) {
    return params.reduce(function (previous, value) {
      return previous + value;
    });
  }
  var _default = Ember.Helper.helper(incrementNumber);
  _exports.default = _default;
});