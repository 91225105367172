define("experience-studio/adapters/group", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'kernel/experiences',
    urlForFindAll: function urlForFindAll() {
      return "".concat(this._super.apply(this, arguments), "?limit=").concat(this.paginationLimit);
    }
  });
  _exports.default = _default;
});