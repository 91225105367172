define("experience-studio/adapters/rule", ["exports", "jquery", "experience-studio/adapters/application"], function (_exports, _jquery, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = _application.default.extend({
    namespace: 'context',
    store: Ember.inject.service(),
    // doc: https://github.com/flybits/rules/tree/getRulesByVariables/docs#get-rules
    // refer to section on "Variables Formula" for more details on how it's used.
    getNearbyLocationRule: function getNearbyLocationRule(params) {
      return this.store.query('rule', _objectSpread(_objectSpread({}, params), {}, {
        variablesFormula: '(ctx.flybits.area.query.inAreaWithLabel.eventType;ctx.flybits.area.query.inArea)'
      }));
    },
    getAffectedRules: function getAffectedRules(store, type, snapshot) {
      var _this = this;
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'findAll');
      url = "".concat(url.replace('rules', 'rules/affectedBy').split('?')[0], "/").concat(snapshot.get('id'));
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          contentType: 'application/json',
          headers: _this.headers,
          type: 'GET',
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (response) {
          reject(response);
        });
      });
    },
    simulate: function simulate(store, type, snapshot) {
      var _this2 = this;
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'findAll');
      url = url.replace('rules', 'rules/simulations').split('?')[0];
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          contentType: 'application/json',
          data: JSON.stringify({
            ruleId: snapshot.id
          }),
          headers: _this2.headers,
          type: 'POST',
          url: url
        }).done(function (response) {
          return resolve(response);
        }).fail(function (response) {
          return reject(response);
        });
      });
    },
    query: function query(store, type, _query) {
      if (!Ember.isNone(_query.isDraft)) {
        _query.visibility = _query.isDraft ? 'draft' : 'public';
        delete _query.isDraft;
      }
      return this._super(store, type, _query);
    },
    undraft: function undraft(store, type, snapshot) {
      var _this3 = this;
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'findAll');
      url = url.replace('rules', 'rules/undraft/').split('?')[0] + snapshot.id;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this3.headers,
          type: 'POST',
          url: url
        }).done(function (response) {
          return resolve(response);
        }).fail(function (response) {
          return reject(response);
        });
      });
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this._super.apply(this, arguments), "?limit=").concat(this.paginationLimit);
    },
    getConflictingPushMessages: function getConflictingPushMessages(ruleID) {
      var _this4 = this;
      var excludeID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var pushAdapter = this.store.adapterFor('push-message');
      var pushUrl = pushAdapter.buildURL();
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this4.headers,
          type: 'GET',
          url: "".concat(pushUrl, "/pushrequests?ruleIDs=").concat(ruleID)
        }).done(function (response) {
          var filteredData = response.data.filter(function (r) {
            return r.pushRequestID != excludeID;
          });
          response.data = filteredData;
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    getConflictingExperiences: function getConflictingExperiences(ruleID) {
      var _this5 = this;
      var excludeID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var expAdapter = this.store.adapterFor('experience');
      var experienceURL = expAdapter.buildURL();
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this5.headers,
          type: 'GET',
          url: "".concat(experienceURL, "/experiences?ruleIDs=").concat(ruleID)
        }).done(function (response) {
          var filteredData = response.data.filter(function (r) {
            return r.id != excludeID;
          });
          response.data = filteredData;
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    // Retrieves entities associated with the ruleID.
    // the second parameter will exclude entities with given id.
    getConflictingEntities: function getConflictingEntities(ruleID) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$pushID = _ref.pushID,
        pushID = _ref$pushID === void 0 ? null : _ref$pushID,
        _ref$experienceID = _ref.experienceID,
        experienceID = _ref$experienceID === void 0 ? null : _ref$experienceID;
      var promises = Ember.Object.create({
        'experiences': this.getConflictingExperiences(ruleID, experienceID),
        'pushMessages': this.getConflictingPushMessages(ruleID, pushID)
      });
      return Ember.RSVP.hashSettled(promises).then(function (hash) {
        if (hash.pushMessages.state === 'fulfilled' && hash.experiences.state === 'fulfilled') {
          return Ember.Object.create({
            'experiences': hash.experiences.value.data,
            'pushMessages': hash.pushMessages.value.data
          });
        }
      });
    },
    findRuleByRuleName: function findRuleByRuleName(newRuleName) {
      var _this6 = this;
      var url = this.buildURL();
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this6.headers,
          type: 'GET',
          url: "".concat(url, "/rules/validations?ruleName=").concat(newRuleName)
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    getRuleBody: function getRuleBody(stringRepresentation) {
      var _this7 = this;
      var url = this.buildURL();
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this7.headers,
          type: 'GET',
          url: "".concat(url, "/rules/validations?stringRepresentation=").concat(stringRepresentation)
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    }
  });
  _exports.default = _default;
});