define("experience-studio/components/dynamic-attribute-modal", ["exports", "experience-studio/components/base-modal-dialog", "jquery"], function (_exports, _baseModalDialog, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['message-modal-dialog'],
    defaultValue: '',
    insertButtonDisable: Ember.computed('contentPlugin', function () {
      return this.contentPlugin ? false : true;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)('.attribute-default-hover').tooltip();
    },
    actions: {
      close: function close() {
        this.set('isModal', false);
        if (this.dynamicAtrributeValue) {
          this.set('dynamicAtrributeValue.value', '');
        }
        this.set('isEditDynamic', false);
        this.set('contentPlugin', null);
      },
      saveDynamic: function saveDynamic() {
        this.updateData(''.concat(this.get('contentPlugin').label, ' / ', this.get('contentPlugin').pluginAttribute), this.defaultValue.trim(), this.get('contentPlugin').value);
        this.set('isModal', false);
        this.set('dynamicAtrributeValue.value', '');
        this.set('isEditDynamic', false);
        this.set('contentPlugin', null);
      },
      selectValue: function selectValue(option) {
        this.set('contentPlugin', option);
      },
      myMatcher: function myMatcher(person, term) {
        return "".concat(person.label.toLowerCase(), " ").concat(person.pluginAttribute.toLowerCase()).indexOf(term.toLowerCase());
      }
    }
  });
  _exports.default = _default;
});