define("experience-studio/templates/components/custom-field--number-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qImbtaVC",
    "block": "{\"symbols\":[\"message\",\"value\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,0]],[13],[6,[37,2],[[35,1,[\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],null,[[\"class\",\"triggerClass\",\"dropdownClass\",\"options\",\"selected\",\"searchEnabled\",\"placeholder\",\"dropdownClass\",\"onchange\"],[\"form-control\",\"form-control\",\"form-control\",[35,1,[\"valueOptions\"]],[35,1,[\"value\"]],false,\"Select an option\",\"custom-field\",[30,[36,3],[[32,0],\"onValue\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[2]}]]],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,1,[\"errorMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"error-message text-danger\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"field\",\"if\",\"action\",\"power-select\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--number-dropdown.hbs"
  });
  _exports.default = _default;
});