define("experience-studio/components/location-configuration-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['location-configuration-options'],
    options: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('options', [Ember.Object.create({
        title: "Select from saved locations",
        desc: "Target locations that have been uploaded or added manually.",
        type: 'saved'
      }), Ember.Object.create({
        title: "Create a new location",
        desc: "Target a new custom location.",
        type: 'new'
      }), Ember.Object.create({
        title: "Apply a location label",
        desc: "Target locations by labels and region.",
        type: 'label'
      })]);
    },
    actions: {
      create: function create(type) {
        this.sendAction('createLocation', type); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});