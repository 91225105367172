define("experience-studio/components/info-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{info-sidebar
      close='toggleInfoSidebar'
      isInfoSidebarOpen=isInfoSidebarOpen
      selectedEntities=selectedEntities
      title=sidebarTitle
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar'],
    title: 'Preview',
    modelSham: Ember.computed('selectedEntities.[]', function () {
      if (!Ember.isEmpty(this.selectedEntities)) {
        return this.selectedEntities[0];
      }
    }),
    componentName: Ember.computed('modelSham', 'selectedEntities.[]', function () {
      var componentName;
      var model = this.modelSham;
      var selectedEntities = this.selectedEntities;
      if (selectedEntities && selectedEntities.length === 1) {
        var modelName = model.constructor.modelName || model._internalModel.modelName;
        // Hijack content-instance's sidebar if we've selected content-blueprint
        modelName = modelName === 'content-blueprint' ? 'content-instance' : modelName;
        componentName = "info-sidebar-".concat(modelName);
      }
      return componentName;
    }),
    multipleSelected: Ember.computed('selectedEntities.[]', function () {
      return this.selectedEntities.length > 1;
    }),
    titleSham: Ember.computed('modelSham', 'selectedEntities.[]', function () {
      var selectedEntities = this.selectedEntities;
      if (!Ember.isEmpty(this.selectedEntities) && selectedEntities.length > 1) {
        return this.selectedEntities.objectAt(0).constructor.modelName;
      } else if (this.modelSham) {
        return this.modelSham.name;
      } else {
        return '';
      }
    }),
    observeModel: Ember.observer('modelSham.isPartiallyLoaded', function () {
      var model = this.modelSham;
      if (model && model.get('isPartiallyLoaded')) {
        model.reload();
      }
    }),
    actions: {
      close: function close() {
        this.sendAction('close'); // eslint-disable-line ember/closure-actions
      },
      clear: function clear() {
        this.sendAction('deselectModelListItems'); // eslint-disable-line ember/closure-actions
        this.send('close');
      },
      deselectAll: function deselectAll() {
        this.modelSham.setEach('isSelected', false);
      }
    }
  });
  _exports.default = _default;
});