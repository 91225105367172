define("experience-studio/components/main-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{main-header
      didClickInfoButton='toggleInfoSidebar'
      didUpdateSearchKeyword='didUpdateSearchKeyword'
      groups=groups
      isInfoSidebarOpen=isInfoSidebarOpen
      title=headerTitle
      isLoadingAffectedRules=isLoadingAffectedRules
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['main-header'],
    groups: null,
    isModelItemSelected: false,
    isLoadingAffectedRules: false,
    isSearchFormVisible: true,
    showInfoButton: true,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('isExperience', Ember.getOwner(this).lookup('controller:application').currentPath === 'experiences.index');
    },
    actions: {
      changeGroupPriorities: function changeGroupPriorities(action) {
        this.sendAction('changeGroupPriorities', action); // eslint-disable-line ember/closure-actions
      },
      showManageGroupModalDialog: function showManageGroupModalDialog() {
        this.sendAction('showManageGroupModalDialog'); // eslint-disable-line ember/closure-actions
      },
      deselectModelListItems: function deselectModelListItems() {
        this.sendAction('deselectModelListItems'); // eslint-disable-line ember/closure-actions
      },
      didChangeTab: function didChangeTab(tab) {
        this.sendAction('didChangeTab', tab); // eslint-disable-line ember/closure-actions
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(keyword) {
        this.sendAction('didUpdateSearchKeyword', Ember.Object.create({
          // eslint-disable-line ember/closure-actions
          keyword: keyword
        }));
      },
      dispatchAction: function dispatchAction(action, model, params) {
        this.sendAction('dispatchAction', action, model, params); // eslint-disable-line ember/closure-actions
      },
      toggleActivation: function toggleActivation(item) {
        this.sendAction('toggleActivation', item); // eslint-disable-line ember/closure-actions
      },
      toggleInfoSidebar: function toggleInfoSidebar() {
        this.sendAction('didClickInfoButton'); // eslint-disable-line ember/closure-actions
      },
      updateQueryParams: function updateQueryParams(data) {
        if (this.updateQueryParams) {
          this.updateQueryParams(data);
        }
      }
    }
  });
  _exports.default = _default;
});