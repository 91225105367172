define("experience-studio/components/push-messages-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['push-messages-new'],
    isEditMode: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName.indexOf('edit') > 0;
    }),
    immediatelyText: Ember.computed('model.isTriggered', function () {
      return this.model.isTriggered ? 'Activate' : 'Send';
    }),
    scheduledText: Ember.computed('model', function () {
      return 'Schedule';
    }),
    isInactiveAllowed: Ember.computed('model.{isEditMode,isDraft,isTriggered}', function () {
      return this.isEditMode && !this.model.isDraft && this.model.isTriggered;
    }),
    isDraftAllowed: Ember.computed('model.{activeSchedule,isDraft}', function () {
      return !this.isEditMode || this.isEditMode && (this.model.isDraft || this.model.isBroadcast);
    }),
    isScheduled: Ember.computed('model.schedule.scheduler.start', function () {
      return this.get('model.schedule.scheduler.start');
    }),
    actions: {
      deferPushMessage: function deferPushMessage() {
        var isDraft = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.deferPushMessage(isDraft);
      },
      finalizePushMessage: function finalizePushMessage() {
        this.finalizePushMessage();
      },
      confirmCancel: function confirmCancel() {
        this.confirmCancel();
      }
    }
  });
  _exports.default = _default;
});