define("experience-studio/components/route-model-table-actions-uploads-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-table-actions-uploads-data'],
    isProcessing: Ember.computed('model.isProcessingInProgress', function () {
      return this.model.isProcessingInProgress;
    }),
    isHide: false,
    processFailedObserver: Ember.observer('model.statusData.statusMessage', function () {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.$('[data-toggle="tooltip"]').tooltip();
      });
    }),
    actions: {
      sendPerformModelAction: function sendPerformModelAction(actionName) {
        if (actionName !== 'confirmDeleteRecord') {
          this.set('isHide', true);
          Ember.run.later(this, function () {
            this.performModelAction({
              action: actionName,
              record: this.model
            });
          }, 3000);
        } else {
          this.performModelAction({
            action: actionName,
            record: this.model
          });
        }
      }
    }
  });
  _exports.default = _default;
});