define("experience-studio/components/info-sidebar-rule", ["exports", "jquery", "ember-inflector"], function (_exports, _jquery, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar-context-rule'],
    observeModel: Ember.observer('model', function () {
      if (!this.hasSchedule) {
        this.$('a[href="#info-sidebar-details"]').tab('show');
      }
    }),
    didRender: function didRender() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
      this._super.apply(this, arguments);
    },
    isRuleBody: Ember.computed('model.body', function () {
      var body = this.model.body;
      return body.andBlocks && body.andBlocks.length || body.andLiterals && body.andLiterals.length || body.orBlocks && body.orBlocks.length || body.orLiterals && body.orLiterals.length;
    }),
    maxRepeatText: Ember.computed('model.schedule.startScheduler.repeat', function () {
      var limit = this.get('model.schedule.startScheduler.repeat.limit');
      return limit == -1 ? 'unlimited' : limit;
    }),
    frequencyText: Ember.computed('model.schedule.startScheduler.repeat', function () {
      var _frequency = this.get('model.schedule.startScheduler.repeat.frequency');
      var _frequencyType = this.get('model.schedule.startScheduler.repeat.frequencyType');
      _frequencyType = _frequency == 1 ? (0, _emberInflector.singularize)(_frequencyType) : (0, _emberInflector.pluralize)(_frequencyType);
      return Ember.isEqual(_frequencyType, 'once') ? 'once' : "".concat(_frequency, " ").concat(_frequencyType);
    }),
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
      this._super.apply(this, arguments);
    },
    hasSchedule: Ember.computed('model.schedule.startScheduler', function () {
      var startScheduler = this.get('model.schedule.startScheduler');
      return !Ember.isEmpty(startScheduler.contextualStart) || !Ember.isNone(startScheduler.start) && startScheduler.start > 0;
    })
  });
  _exports.default = _default;
});