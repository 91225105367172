define("experience-studio/components/content-tmp-edit-geoshape", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    autocomplete: null,
    classNameBindings: ['isDetectingLocation'],
    classNames: ['content-tmp-edit-geoshape'],
    detectedLocation: null,
    drawingManager: null,
    errorMessage: null,
    isDetectingLocation: true,
    isInjectingScript: true,
    isMapLoading: true,
    isPolygonInCenter: true,
    isPolygonInViewport: false,
    map: null,
    overlay: null,
    polygon: null,
    polygonOptions: null,
    searchKeywordAddress: '',
    utility: Ember.inject.service(),
    // OBSERVERS -----------------------------------------------------------------
    observeSetupGoogleMap: Ember.observer('isDetectingLocation', 'isInjectingScript', function () {
      if (!this.isDetectingLocation && !this.isInjectingScript) {
        this.setupGoogleMap();
      }
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.set('polygonOptions', {
        strokeColor: '#29BEEE',
        strokeOpacity: 0.9,
        fillColor: '#29BEEE',
        fillOpacity: 0.35,
        editable: true,
        draggable: true
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.field.value) {
        this.set('isDetectingLocation', false);
      } else {
        this.detectLocation();
      }
      this.injectGoogleMapsScript();
    },
    // FUNCTIONS -----------------------------------------------------------------
    getPolygonBounds: function getPolygonBounds(polygon) {
      var bounds = new window.google.maps.LatLngBounds();
      polygon.getPath().forEach(function (coordinates) {
        return bounds.extend(coordinates);
      });
      return bounds;
    },
    detectLocation: function detectLocation() {
      var self = this;
      if (window.navigator && window.navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (geoposition) {
          if (geoposition && geoposition.coords && geoposition.coords.latitude) {
            self.set('detectedLocation', Ember.Object.create({
              lat: geoposition.coords.latitude,
              lng: geoposition.coords.longitude
            }));
            self.set('isDetectingLocation', false);
          }
        }, function () {
          self.set('isDetectingLocation', false);
        }, {
          enableHighAccuracy: false,
          maximumAge: 1000 * 60 * 15,
          timeout: 5000
        });
      } else {
        self.set('isDetectingLocation', false);
      }
    },
    didChangePlace: function didChangePlace() {
      var place = this.autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        this.map.setCenter(place.geometry.location);
        if (place.types.toString().indexOf('political') >= 0 || place.types.toString().indexOf('establishment') >= 0) {
          if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
            this.map.setZoom(this.map.getZoom() - 1);
          } else {
            this.map.setZoom(11);
          }
        } else {
          this.map.setZoom(16);
        }
      }
    },
    didChangeMapBounds: function didChangeMapBounds() {
      var isPolygonInCenter = true;
      var isPolygonInViewport = false;
      if (this.polygon) {
        var mapCenter = this.map.getCenter();
        var polygonCenter = this.getPolygonBounds(this.polygon).getCenter();
        if (this.map.getBounds().contains(polygonCenter)) {
          isPolygonInViewport = true;
        }
        var mapCenterLat = mapCenter.lat().toFixed(4);
        var mapCenterLng = mapCenter.lng().toFixed(4);
        var polygonCenterLat = polygonCenter.lat().toFixed(4);
        var polygonCenterLng = polygonCenter.lng().toFixed(4);
        if (window.Math.abs(mapCenterLat - polygonCenterLat) != 0 && window.Math.abs(mapCenterLng - polygonCenterLng) != 0) {
          isPolygonInCenter = false;
        }
      }
      if (this.isDestroyed) {
        return;
      }
      this.setProperties({
        isPolygonInCenter: isPolygonInCenter,
        isPolygonInViewport: isPolygonInViewport
      });
    },
    generatePolygon: function generatePolygon(coordinate) {
      var computeOffset = window.google.maps.geometry.spherical.computeOffset;
      var distance = 150;
      var paths = [];
      paths.pushObject(computeOffset(coordinate, distance, -45));
      paths.pushObject(computeOffset(coordinate, distance, 45));
      paths.pushObject(computeOffset(coordinate, distance, 135));
      paths.pushObject(computeOffset(coordinate, distance, -135));
      return new window.google.maps.Polygon({
        paths: paths
      });
    },
    injectGoogleMapsScript: function injectGoogleMapsScript() {
      var _this = this;
      var successCallback = function successCallback() {
        _this.setProperties({
          errorMessage: '',
          isInjectingScript: false
        });
      };
      if (!window.google || !window.google.maps) {
        var url = "//maps.googleapis.com/maps/api/js?".concat(_jquery.default.param({
          key: _environment.default.APP.googleMapKey,
          libraries: 'drawing,geometry,places'
        }));
        _jquery.default.getScript(url).done(Ember.run.bind(this, successCallback)).fail(function (response) {
          _this.setProperties({
            errorMessage: _this.utility.parseError(response),
            isInjectingScript: false
          });
        });
      } else {
        successCallback();
      }
    },
    moveMapToShowPolygon: function moveMapToShowPolygon() {
      this.map.fitBounds(this.getPolygonBounds(this.polygon));
      this.map.setZoom(this.map.getZoom() - 2);
    },
    setPolygon: function setPolygon(polygon) {
      var _this2 = this;
      var self = this;
      this.setProperties({
        polygon: polygon,
        searchKeywordAddress: ''
      });
      polygon.setOptions(this.polygonOptions);
      polygon.setMap(this.map);
      var paths = this.polygon.getPaths();
      paths.forEach(function (path) {
        window.google.maps.event.addListener(path, "insert_at", function () {
          self.updateFieldValue();
        });
        window.google.maps.event.addListener(path, "set_at", function () {
          self.updateFieldValue();
        });
        window.google.maps.event.addListener(path, "remove_at", function () {
          self.updateFieldValue();
        });
      });
      polygon.addListener('rightclick', Ember.run.bind(this, function (event) {
        var path = _this2.polygon.getPath();
        if (event.vertex && path.length > 4) {
          _this2.polygon.getPath().removeAt(event.vertex);
        } else if (!event.vertex) {
          window.google.maps.event.clearInstanceListeners(_this2.polygon);
          _this2.polygon.setMap(null);
          _this2.setProperties({
            errorMessage: '',
            polygon: null
          });
          _this2.field.setProperties({
            value: null
          });
        }
      }));
      this.moveMapToShowPolygon();
      window.google.maps.event.trigger(polygon, 'dragend');
    },
    setupAutocomplete: function setupAutocomplete() {
      this.set('isMapLoading', true);
      var autocomplete = new window.google.maps.places.Autocomplete((0, _jquery.default)('.map-container .search-container input').get(0));
      autocomplete.addListener('place_changed', Ember.run.bind(this, this.didChangePlace));
      this.set('autocomplete', autocomplete);
      this.set('isMapLoading', false);
    },
    setupGoogleMap: function setupGoogleMap() {
      var _this3 = this;
      var mapCenter = {
        lat: -37.111112,
        lng: -12.28834
      };
      var mapZoom = 11;
      if (!Ember.isEmpty(this.field.value)) {
        this.set('polygon', new window.google.maps.Polygon({
          paths: this.field.value
        }));
        mapCenter = this.getPolygonBounds(this.polygon).getCenter();
        mapZoom = 14;
      } else if (this.detectedLocation) {
        mapCenter = this.detectedLocation;
        mapZoom = 12;
      }
      this.set('map', new window.google.maps.Map((0, _jquery.default)('.map').get(0), {
        center: mapCenter,
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true,
        zoom: mapZoom
      }));
      if (this.polygon) {
        this.setPolygon(this.polygon);
      }
      window.google.maps.event.addListenerOnce(this.map, 'tilesloaded', function () {
        if (_this3.isDestroyed) {
          return;
        }
        _this3.set('isMapLoading', false);
      });
      window.google.maps.event.addListener(this.map, 'bounds_changed', Ember.run.bind(this, this.didChangeMapBounds));
      this.setupAutocomplete();
    },
    updateFieldValue: function updateFieldValue() {
      this.set('field.value', this.polygon.getPath().getArray().map(function (coordinate) {
        return Ember.Object.create(coordinate.toJSON());
      }));
      if (this.field.value[0].lat !== this.field.value[this.field.value.length - 1].lat && this.field.value[0].lng !== this.field.value[this.field.value.length - 1].lng) {
        this.field.value.pushObject(this.field.value[0]);
      }
    },
    // ACTIONS ------------------------------------------------------------------
    actions: {
      createShape: function createShape() {
        if (!this.polygon) {
          this.setPolygon(this.generatePolygon(this.map.getCenter()));
          this.updateFieldValue();
        }
      },
      handleSearch: function handleSearch(address) {
        this.setProperties({
          polygon: null,
          searchKeywordAddress: address
        });
      },
      resetShape: function resetShape() {
        if (this.polygon) {
          window.google.maps.event.clearInstanceListeners(this.polygon);
          this.polygon.setMap(null);
          this.setProperties({
            errorMessage: '',
            polygon: null,
            searchKeywordAddress: ''
          });
          this.set('field.value', null);
        }
      }
    }
  });
  _exports.default = _default;
});