define("experience-studio/components/custom-field--localized-input", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    didInsertElement: function didInsertElement() {
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var defaultLanguage = this.defaultLanguage;
      var field = this.field;
      field.set('errorMessages', []);
      if (Ember.isEmpty(field.value.get(defaultLanguage.id))) {
        if (this.isDirty) {
          field.errorMessages.pushObject("You must populate the ".concat(defaultLanguage.name, " field"));
        }
        isValid = false;
      }
      this.set('field.isValid', isValid);
    },
    setDirty: function setDirty(value) {
      var field = this.field;
      var object = JSON.parse(JSON.stringify(this.field.value));
      var isDirty = this.isDirty || Object.keys(object).length > 0;
      this.set('isDirty', field.get('isRequired') ? isDirty : !Ember.isEmpty(value));
    },
    triggerValidation: function triggerValidation() {
      var defaultLanguage = this.defaultLanguage;
      var fieldValue = '';
      var fieldInput = '';
      if (this.field.value[defaultLanguage.id]) {
        fieldValue = this.field.value[defaultLanguage.id];
      }
      if (this.field.input[defaultLanguage.id]) {
        fieldInput = this.field.input[defaultLanguage.id];
      }
      if (!fieldValue && !fieldInput) {
        this.field.value.set(defaultLanguage.id, fieldValue);
        this.field.input.set(defaultLanguage.id, fieldInput);
        this.setDirty(this.field.input);
        this.send('onValue');
      }
    },
    actions: {
      onValue: function onValue() {
        var input = this.get("field.input");
        var value;
        if (!Ember.isEmpty(input)) {
          value = JSON.parse(JSON.stringify(input));
        } else {
          value = {};
        }
        Object.keys(value).forEach(function (key) {
          value[key] = value[key].trim() || undefined;
        });
        value = Ember.Object.create(value);
        this.field.set('value', value);
        this.field.set('input', input);
        this.setDirty(input);
        this.setValidity(input);
        this.sendAction('onValue', value); // eslint-disable-line ember/closure-actions
      },

      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      }
    }
  });
  _exports.default = _default;
});