define("experience-studio/routes/communications-hub/triggered-emails", ["exports", "experience-studio/mixins/model-actions"], function (_exports, _modelActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelActions.default, {
    actions: {
      toggleActivation: function toggleActivation(model) {
        var property = 'isActive';
        var value = model.get(property);
        var self = this;
        if (!model.get('isSaving')) {
          model.toggleProperty(property);
          model.save().then(function () {
            self.send('showNotification', Object.create({
              message: 'Successfully updated status',
              type: 'confirm'
            }));
          }).catch(function (response) {
            model.set(property, value);
            self.send('showModal', Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: self.get('utility').parseError(response),
              title: 'Failed to save status',
              type: 'danger'
            }));
          });
        }
      }
    }
  });
  _exports.default = _default;
});