define("experience-studio/components/fb/fb-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['dropdown', 'fb-dropdown'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Clicking submenu forces the child submenu to stay open
      (0, _jquery.default)(this.element).find('.dropdown-menu .fb-toggle').on('click', this.showContentEventListener);

      // Mouse - Navigation (mouseenter / mouseleave)
      (0, _jquery.default)(this.element).find('li').on('mouseenter', function () {
        (0, _jquery.default)('.dropdown-menu.show:not(:first)').removeClass('show');
      }).on('mouseenter', this.addActiveEventListener).on('mouseleave', this.removeActiveEventListener);

      // Keyboard - Navigation (focus / blur)
      (0, _jquery.default)(this.element).find('li a').focus(this.addActiveEventListener).blur(this.removeActiveEventListener);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('.dropdown-menu .dropdown-toggle').off('click');
      (0, _jquery.default)(this.element).find('li').off('mouseenter mouseleave');
      (0, _jquery.default)(this.element).find('li a').off('focus blur');
    },
    addActiveEventListener: function addActiveEventListener() {
      (0, _jquery.default)(this).closest('li').addClass('active');
      (0, _jquery.default)(this).parents('.dropdown-submenu').addClass('active');
    },
    removeActiveEventListener: function removeActiveEventListener() {
      (0, _jquery.default)(this).closest('li').removeClass('active');
      (0, _jquery.default)(this).parents('.dropdown-submenu').removeClass('active');
    },
    showContentEventListener: function showContentEventListener() {
      if (!(0, _jquery.default)(this).next().hasClass('show')) {
        (0, _jquery.default)(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
      }
      (0, _jquery.default)(this).next('.dropdown-menu').toggleClass('show');
      (0, _jquery.default)(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function () {
        (0, _jquery.default)('.dropdown-submenu .show').removeClass('show');
      });
      return false;
    }
  });
  _exports.default = _default;
});