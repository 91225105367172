define("experience-studio/templates/components/image-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w/OuJOhe",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner-wrap\"],[12],[2,\"\\n\"],[6,[37,8],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"preview\"],[16,5,[34,7]],[4,[38,2],[[32,0],\"previewClick\"],null],[12],[2,\"\\n\"],[6,[37,8],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"hover-preview\"],[15,5,[34,7]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"preview-placeholder\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,8],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"type\",\"class\",\"value\",\"placeholder\",\"disabled\"],[\"text\",\"form-control url-edit\",[35,5],\"Enter url for an image\",[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,8],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"trigger-button\"],[4,[38,2],[[32,0],\"openModal\"],null],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"model\",\"modalClose\",\"primaryActionName\"],[[35,0],\"modalClose\",\"setImage\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modalModel\",\"image-choice-modal\",\"action\",\"triggerLabel\",\"inputDisabled\",\"value\",\"input\",\"backgroundURL\",\"if\",\"showPreview\",\"showURL\",\"showTriggerButton\",\"showModal\"]}",
    "moduleName": "experience-studio/templates/components/image-field.hbs"
  });
  _exports.default = _default;
});