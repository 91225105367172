define("experience-studio/templates/components/location-configuration-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xlmip2Dl",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"div\"],[14,0,\"location-configuration-options\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"config-header\"],[12],[2,\"How would you like to define your location?\"],[13],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"config-options-list\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"config-options-list-item\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"\"],[24,0,\"action\"],[4,[38,0],[[32,0],\"create\",[32,1,[\"type\"]]],null],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"placeholder\"],null]],[2,\"\\n          \"],[10,\"div\"],[14,0,\"config-option-desc\"],[12],[2,\"\\n            \"],[10,\"h5\"],[14,0,\"desc-title\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n            \"],[10,\"p\"],[14,0,\"desc-body\"],[12],[1,[32,1,[\"desc\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[1,[30,[36,1],[\"arrow-down\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"svg-jar\",\"options\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/location-configuration-options.hbs"
  });
  _exports.default = _default;
});