define("experience-studio/components/content-tmp-edit-media-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-media-type
      field=field
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: 'content-tmp-edit-media-type',
    filteredOptions: Ember.computed('field.options', function () {
      if (!this.get('field.options')) return [];
      return this.get('field.options').filter(function (opt) {
        return ['image', 'video'].includes(opt.val);
      });
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('field.value')) {
        this.send('selectMediaType', this.get('field.value'));
      }
    },
    actions: {
      selectMediaType: function selectMediaType(type, isDirty) {
        this.set('field.value', type);
        if (isDirty) {
          this.clearMediaURL();
        }
        this.setSelectedMediaType(type);
      }
    }
  });
  _exports.default = _default;
});