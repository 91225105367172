define("experience-studio/components/batch-actions-bar", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    classNames: ['batch-actions-bar'],
    classNameBindings: ['displayBar::hidden'],
    shouldDisplayModal: true,
    displayBar: Ember.computed('selectedEntities.[]', function () {
      return this.selectedEntities && this.selectedEntities.length >= 1;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initializeTooltips();
    },
    batchActionObserver: Ember.observer('selectedEntities.@each.isSelected', 'batchActions.[]', function () {
      var _iterator = _createForOfIteratorHelper(this.batchActions),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var batchAction = _step.value;
          if (batchAction.subActions && batchAction.subActions.length) {
            var _iterator2 = _createForOfIteratorHelper(batchAction.subActions),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var subAction = _step2.value;
                this.addBatchValidation(subAction);
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          } else {
            this.addBatchValidation(batchAction);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }),
    willDestroyElement: function willDestroyElement() {
      this.uninitializeTooltips();
      this.closeDropdowns();
      this._super.apply(this, arguments);
    },
    actions: {
      onClose: function onClose() {
        if (this.onClose) {
          var preferenceValue = this.userPreferences.getValue('batchActionBarDeselectAll');
          if (Ember.isNone(preferenceValue) || preferenceValue) {
            this.set('shouldDisplayModal', true);
          } else {
            this.set('shouldDisplayModal', false);
          }
          this.onClose({
            pluralEntityName: this.pluralEntityName,
            shouldDisplayModal: this.shouldDisplayModal
          });
        }
      },
      stopBubble: function stopBubble(isDisabled) {
        if (isDisabled) {
          event.stopPropagation();
          this.uninitializeTooltips();
          this.initializeTooltips();
        }
      },
      performModelBatchAction: function performModelBatchAction(batchAction) {
        if (!batchAction.isDisabled && this.performModelBatchAction) {
          this.performModelBatchAction(batchAction);
        }
      }
    },
    addBatchValidation: function addBatchValidation(batchAction) {
      if (batchAction.validationFunction) {
        var errorMessage = batchAction.validationFunction(this.selectedEntities);
        if (!Ember.isEmpty(errorMessage)) {
          batchAction.setProperties({
            errorMessage: errorMessage,
            isDisabled: true
          });
        } else {
          batchAction.setProperties({
            errorMessage: '',
            isDisabled: false
          });
        }
      }
    },
    closeDropdowns: function closeDropdowns() {
      var dropdownElements = (0, _jquery.default)(this.element).find('[data-toggle="dropdown"]');
      dropdownElements.dropdown('dispose');
    },
    initializeTooltips: function initializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    }
  });
  _exports.default = _default;
});