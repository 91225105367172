define("experience-studio/components/model-list-permission-role", ["exports", "experience-studio/components/model-list-v2"], function (_exports, _modelListV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListV.default.extend({
    classNames: ['model-list-permission-role'],
    model: null,
    modelSham: Ember.computed('model.[]', function () {
      return this.model.filterBy('isNew', false);
    }),
    actions: {
      create: function create() {
        this.sendAction('create'); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});