define("experience-studio/components/content-form-editor-paragraph", ["exports", "experience-studio/components/content-form-editor-dropped-item"], function (_exports, _contentFormEditorDroppedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _contentFormEditorDroppedItem.default.extend({
    classNames: ['content-form-editor-paragraph'],
    typeIcon: Ember.computed('model.type', function () {
      var uiType = this.get('model.type.uiType');
      if (uiType === 'date') {
        return 'calendar';
      } else if (uiType === 'time') {
        return 'clock';
      } else if (uiType === 'location') {
        return 'map-marker';
      } else if (uiType === 'fileUpload') {
        return 'file-upload';
      }
      return;
    })
  });
  _exports.default = _default;
});