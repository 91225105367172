define("experience-studio/components/concierge-template-modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['concierge-template-modal-dialog'],
    errorMessage: '',
    actions: {
      cancel: function cancel() {
        if (this.didClose) {
          this.didClose();
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      validate: function validate() {
        if (!this.model.get('name')) {
          this.set('errorMessage', 'Enter Name');
        } else {
          this.model.set('labels', this.model.selectedLabels.map(function (selectedLabel) {
            return selectedLabel.value;
          }));
          this.set('errorMessage', '');
          if (this.didNext) {
            this.didNext();
          }
        }
      },
      trimValue: function trimValue(key, val) {
        this.model.set(key, val.trim());
      }
    }
  });
  _exports.default = _default;
});