define("experience-studio/components/model-bar-groups", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-bar-groups'],
    isRouteListView: false,
    previewListItemCount: 0,
    utility: Ember.inject.service(),
    previewListItems: Ember.computed('model.[]', 'previewListItemCount', function () {
      return this.model.slice(0, this.previewListItemCount);
    }),
    remainderListItems: Ember.computed('model.[]', 'previewListItemCount', function () {
      return this.model.slice(this.previewListItemCount);
    }),
    dataObserver: Ember.observer('data', function () {
      this.decodeData();
    }),
    decodeData: function decodeData() {
      var data = this.utility.atob(this.data) || {};
      this.set('groupId', data.groupId || '');
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(window).on('resize.model-bar', function () {
        _this.generateListCount(); // eslint-disable-line ember/jquery-ember-run
        _this.swapInSelectedItem(); // eslint-disable-line ember/jquery-ember-run
      });

      this.model.setEach('isSelected', false);
      this.generateListCount();
      if (this.isRouteListView) {
        this.decodeData();
      }
      if (this.groupId && this.groupId !== 'null') {
        this.send('toggleSelection');
      }
    },
    generateListCount: function generateListCount() {
      var minItemWidth = 150;
      var count = Math.floor(this.$('.model-bar-list').width() / minItemWidth) || 1;
      this.set('previewListItemCount', count);
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(window).off('resize.model-bar');
    },
    swapInSelectedItem: function swapInSelectedItem(item) {
      var remainderListItems = this.remainderListItems;
      var isItem = !Ember.isEmpty(item);
      if (!isItem) {
        item = remainderListItems.filterBy('isSelected', true).objectAt(0);
      }
      if (item && remainderListItems.indexOf(item) > -1) {
        var model = this.model.toArray();
        var index = isItem ? 0 : this.previewListItemCount - 1;
        model.removeObject(item);
        model.insertAt(index, item);
        this.set('model', model);
      }
    },
    actions: {
      aggregateModel: function aggregateModel() {
        this.send('dispatchAction', 'aggregateModel', null, {
          limit: 12,
          modelName: 'experience',
          modelVar: 'experiences'
        });
      },
      dispatchAction: function dispatchAction(action, model, params) {
        this.sendAction('dispatchAction', action, model, params); // eslint-disable-line ember/closure-actions
      },
      priority: function priority() {
        this.sendAction('changeGroupPriorities', 'GROUP'); // eslint-disable-line ember/closure-actions
      },
      showManageGroupModalDialog: function showManageGroupModalDialog() {
        this.sendAction('showManageGroupModalDialog'); // eslint-disable-line ember/closure-actions
      },
      toggleSelection: function toggleSelection(item) {
        var isLoading = false;
        var model = this.model;
        if (!item) {
          item = this.model.filterBy('id', this.groupId).objectAt(0);
          if (!item) {
            this.set('groupId', '');
          }
          isLoading = true;
        }
        if (item) {
          this.sendAction('deselectModelListItems'); // eslint-disable-line ember/closure-actions

          if (!item.get('isSelected')) {
            model.setEach('isSelected', false);
            item.set('isSelected', true);
            this.swapInSelectedItem(item);
            this.set('groupId', item.get('id'));
            this.set('selectedGroup', item);
          } else {
            model.setEach('isSelected', false);
            this.set('groupId', '');
            this.set('selectedGroup', null);
          }
          if (!isLoading) {
            this.send('aggregateModel');
          }
          if (this.updateQueryParams && this.isRouteListView) {
            this.updateQueryParams({
              currentPage: 1,
              groupId: this.groupId
            });
          }
        }
      }
    }
  });
  _exports.default = _default;
});