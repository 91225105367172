define("experience-studio/components/av5-pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Renders an Analytics Pie Chart
  
    Usages:
  
      <Av5InfoCardBase
        @chartData={{chartData}}
      />
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-pie-chart'],
    chart: null,
    chartOptions: null,
    showLegend: false,
    // COMPUTED ------------------------------------------------------------------
    // OBSERVERS -----------------------------------------------------------------
    dataChanged: Ember.observer('isLoading', function () {
      if (!Ember.isEmpty(this.chartData)) {
        if (!this.isLoading) {
          if (this.chart) {
            if (this.showLegend) {
              var dataMarkers = [];
              this.chartData.forEach(function (chartPoint) {
                dataMarkers.pushObject({
                  name: chartPoint.name,
                  icon: 'path://M20 60L140 60L140 99.34L20 99.34L20 60Z'
                });
              });
              this.chart.setOption({
                legend: {
                  data: dataMarkers
                }
              });
            }
            this.chart.setOption({
              series: {
                name: this.title,
                animation: true,
                data: this.chartData
              }
            });
          }
        } else {
          this.chart.setOption({
            series: {
              name: this.title,
              animation: false,
              data: [{
                value: 0,
                itemStyle: {
                  color: '#ccc'
                }
              }]
            }
          });
        }
      }
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);

      // we'll need to configure this with options from parent for later iterations
      var seriesOptions = {
        name: this.title,
        type: 'pie',
        radius: ['75%', '90%'],
        silent: true,
        animation: false,
        cursor: 'default',
        label: {
          normal: {
            show: true,
            position: 'center',
            textStyle: {
              fontSize: '24',
              fontWeight: 'lighter'
            },
            formatter: function formatter(params) {
              return "".concat(params['percent'].toPrecision(3), "%");
            },
            precision: 1
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [{
          value: 0,
          label: {
            show: false
          },
          itemStyle: {
            color: '#ccc'
          }
        }]
      };
      var chartOptions = {
        tooltip: {
          trigger: 'none'
        },
        series: [seriesOptions]
      };
      this.set('chartOptions', chartOptions);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // perhaps this can be delegated out in the future
      // for now this binary treatment should be okay for analytics
      if (this.showLegend) {
        this.chartOptions.series[0].radius = ['74%', '94%'];
        this.chartOptions.series[0].center = ['25%', '50%'];
        this.chartOptions.series[0].silent = false;
        this.chartOptions.series[0].hoverOffset = 0;
        this.chartOptions.series[0].stillShowZeroSum = false;
        this.chartOptions.legend = {
          orient: 'vertical',
          right: 0,
          top: 'center',
          itemGap: 20,
          selectedMode: false
        };
        this.chartOptions.tooltip = {
          trigger: 'item',
          hideDelay: 50,
          confine: true,
          backgroundColor: 'white',
          borderRadius: 8,
          borderWidth: 1,
          borderColor: ['rgba(210, 210, 210, 0.88)'],
          shadowBlur: 10,
          textStyle: {
            color: 'black'
          },
          formatter: function formatter(params) {
            var date = window.moment().format('dddd, MMMM D, YYYY');
            return "\n            <div style=\"font-family: 'Source Sans Pro', sans-serif; padding: 4px 8px;\">\n              <p style=\"font-size: 12px;\">\n                ".concat(date, " (As of Today)</p>\n              <p style=\"font-size: 15px; margin-top: 8px;\">\n                <span>").concat(params.name, ": </span>\n                <span style=\"font-weight: bold;\">\n                  ").concat(params.value.toLocaleString(), " (").concat(params.percent, "%)\n                </span>\n              </p>\n            </div>\n          ");
          }
        };
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var chartElement = this.element.querySelector('.pie-chart');
      var chart = window.echarts.init(chartElement);
      this.set('chart', chart);
      window.addEventListener('resize', function () {
        _this.chart.resize();
      });
      this.chart.setOption(this.chartOptions);
    } // FUNCTIONS -----------------------------------------------------------------
    // ACTIONS -------------------------------------------------------------------
  });
  _exports.default = _default;
});