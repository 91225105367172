define("experience-studio/routes/context-hub/upload", ["exports", "experience-studio/mixins/prevent-reload-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-context-data-upload", "experience-studio/config/environment"], function (_exports, _preventReloadRoute, _routeDataQueryParam, _routeModelTableActionsBase, _routeModelTableActionsContextDataUpload, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_preventReloadRoute.default, _routeDataQueryParam.default, _routeModelTableActionsBase.default, _routeModelTableActionsContextDataUpload.default, {
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    setupController: function setupController(controller) {
      controller.setProperties({
        contextHubController: this.controllerFor('context-hub'),
        currentFile: null,
        isDoneRefreshing: false,
        isPreventReload: false,
        isProcessed: false,
        isProcessImmediately: false,
        isShowProcessAction: false,
        isStartProcessing: false,
        isStartUploading: false,
        isUploading: false,
        processedContextFiles: controller.filteredModel,
        uploadError: null,
        uploadSuccess: false,
        primaryMessage: 'You are still uploading your context file. If you leave now the upload will be stopped.',
        secondaryMessage: 'Are you sure you want to continue.'
      });
      controller.contextHubController.setProperties({
        isSearchFormHidden: true
      });
      this._super.apply(this, arguments); // jshint ignore:line
    },
    /* Reset Controller */resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          _data: '',
          data: ''
        });
        if (controller.contextHubController) {
          controller.contextHubController.setProperties({
            isSearchFormHidden: false
          });
        }
        Ember.run.cancel(controller.timeOutId);
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    upload: function upload(tag, isProcessImmediately) {
      var _this = this;
      var controller = this.controller;
      var callback = "".concat(window.location.origin, "/noop.html");
      var jwt = this.get('session.token');
      var url = "".concat(_environment.default.APP.hostUrl, "/kernel/file-manager/files/upload?callback=").concat(callback);
      controller.setProperties({
        isProcessImmediately: isProcessImmediately,
        isProcessed: false,
        isStartProcessing: false,
        isUploading: true,
        progressCount: 0,
        uploadError: null,
        uploadSuccess: false
      });
      var fileCheckDeferred = Ember.RSVP.defer();
      window.allUpload({
        acceptStr: '.csv',
        targetURL: url,
        fileParamName: 'data',
        formArgs: {
          'X-Authorization': jwt,
          'isPrivate': true,
          'tags': JSON.stringify([tag])
        },
        isResponseInQueryString: true,
        onfilechange: function onfilechange(value) {
          if (value) {
            var ext = value.split('.').pop().toLowerCase();
            if (ext !== 'csv') {
              fileCheckDeferred.reject({
                msg: 'Invalid file type.  Only .csv files are accepted.'
              });
            } else {
              var fileName = value.split('\\').pop();
              controller.setProperties({
                isStartUploading: true,
                isUploading: true,
                fileName: fileName,
                isPreventReload: controller.isUploading
              });
              fileCheckDeferred.resolve();
            }
          } else {
            fileCheckDeferred.reject({
              msg: 'Missing file'
            });
          }
        }
      }).then(function (response) {
        controller.setProperties({
          isStartUploading: false,
          isUploading: false,
          uploadSuccess: true,
          isShowProcessAction: true
        });
        var id;
        controller.set('isPreventReload', controller.isUploading);
        var file = null;
        try {
          file = JSON.parse(decodeURIComponent(response.response))[0];
        } catch (exception) {
          try {
            file = JSON.parse("".concat(decodeURIComponent(response.response), "\"}]"))[0];
          } catch (exception) {
            try {
              file = {
                id: decodeURIComponent(response.response).split('"id":"')[1].split('"')[0]
              };
            } catch (exception) {
              // TODO: Handle exception
            }
          }
        }
        if (!controller.isProcessImmediately) {
          controller.set('uploadedFile', file);
        } else {
          controller.set('uploadedFile', file);
          id = Ember.run.later(_this, function () {
            _this.send('pollServer');
          }, 1000);
        }
        controller.set('timeOutId', id);
        _this.controller.toggleProperty('isRefreshFilterModel');
      }).catch(function () {
        var message = '';
        message += 'An error occured while uploading the file. ';
        message += 'Please try again.';
        controller.setProperties({
          uploadError: message,
          isUploading: false,
          isPreventReload: controller.isUploading
        });
        _this.send('showNotification', Ember.Object.create({
          type: 'alert',
          message: _this.get('utility').parseError(message)
        }));
      });
    },
    getModelListItems: function getModelListItems() {
      return this.controller.filteredModel;
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      processData: function processData(params) {
        var adapter = this.store.adapterFor('csvfile');
        var self = this;
        return adapter.processFile(this.store, params.record.constructor, params.record).then(function () {
          self.send('showNotification', Ember.Object.create({
            type: 'success',
            message: 'Processing started.'
          }));
          self.send('pollServer');
        }).catch(function (response) {
          self.send('showNotification', Ember.Object.create({
            type: 'alert',
            message: self.get('utility').parseError(response)
          }));
        });
      },
      deleteUploadedFile: function deleteUploadedFile(model) {
        var message = 'Are you sure you want to clear this entry?';
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'confirmRemoveProcessedItem',
          primaryMessage: message,
          data: model,
          secondaryMessage: 'You can\'t undo this action.',
          title: 'Confirm Action'
        }));
      },
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.searchKeyword);
      },
      removeUploadStatusSection: function removeUploadStatusSection() {
        var controller = this.controller;
        controller.setProperties({
          isStartUploading: false,
          isStartProcessing: false,
          isUploading: false,
          uploadSuccess: false,
          uploadError: null
        });
        controller.set('isPreventReload', controller.isUploading);
      },
      pollServer: function pollServer() {
        var _this2 = this;
        var controller = this.controller;
        var self = this;
        var id;
        this.store.query('csvfile', {}).then(function (response) {
          var record = controller.filteredModel.filterBy('fileID', controller.get('uploadedFile.id')).get('firstObject');
          record.set('isProcessingInProgress', true);
          if (record) {
            if (controller.isProcessImmediately) {
              record.set('isProcessingInProgress', true);
              self.send('processData', {
                record: record
              });
              controller.set('isProcessImmediately', false);
            } else {
              if (!Ember.isEmpty(response.filterBy('status', 'PROCESSING')) || !Ember.isEmpty(response.filterBy('status', 'PROCESSING_STARTED'))) {
                record.set('isProcessingInProgress', true);
                id = Ember.run.later(_this2, function () {
                  self.send('pollServer');
                }, 3000);
              } else {
                id = Ember.run.later(_this2, function () {
                  record.set('isProcessingInProgress', false);
                }, 3000);
              }
            }
          }
        });
        controller.set('timeOutId', id);
      },
      removeProcessedItem: function removeProcessedItem(model) {
        var message = 'Are you sure you want to delete the processed file?';
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog',
          primaryActionName: 'confirmRemoveProcessedItem',
          primaryMessage: message,
          data: model,
          secondaryMessage: 'You can\'t undo this action.',
          title: 'Confirm Action'
        }));
      },
      confirmRemoveProcessedItem: function confirmRemoveProcessedItem(data) {
        var model = data.data;
        var self = this;
        model.destroyRecord().then(function () {
          self.send('hideModal');
          self.send('showNotification', Ember.Object.create({
            type: 'success',
            message: 'File entry deleted successfully.'
          }));
          self.refresh();
        }).catch(function (response) {
          self.send('hideModal');
          self.send('showNotification', Ember.Object.create({
            type: 'alert',
            message: self.get('utility').parseError(response)
          }));
        });
      },
      uploadLocationData: function uploadLocationData(isProcessImmediately) {
        this.upload('location', isProcessImmediately);
      },
      uploadContextData: function uploadContextData(isProcessImmediately) {
        this.upload('context-data', isProcessImmediately);
      },
      uploadFile: function uploadFile(data) {
        var controller = this.controller;
        controller.setProperties({
          isProcessImmediately: data.isProcessImmediately,
          isUploading: true,
          fileName: '',
          uploadError: null,
          uploadSuccess: false
        });
        if (data.element && data.element.files && data.element.files.length) {
          var formData = new FormData();
          formData.append('file', data.element.files[0]);
          formData.append('extension', '.csv');
          formData.append('isPrivate', true);
          formData.append('tags', JSON.stringify([data.isContextFile ? 'context-data' : 'location']));
          controller.set('fileName', data.element.files[0].name);
          fetch("".concat(_environment.default.APP.hostUrl, "/kernel/file-manager/files/upload"), {
            method: 'POST',
            body: formData,
            headers: {
              'X-Authorization': this.get('session.token')
            }
          }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
              response.json().then(function (res) {
                if (res && res.length) {
                  controller.setProperties({
                    isUploading: false,
                    uploadSuccess: true
                  });
                  controller.send('refresh');
                }
              });
            } else {
              controller.setProperties({
                fileName: '',
                isUploading: false,
                uploadError: "Couldn't upload a ".concat(controller.fileName, " file: ").concat(response.statusText)
              });
            }
          });
        } else {
          controller.setProperties({
            isUploading: false,
            uploadError: "Couldn't find a file to upload"
          });
        }
      },
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
      },
      selectModelListItem: function selectModelListItem(params) {
        params.sidebarModelPath = 'contextHubController.sidebarModel';
        this._super(params);
      },
      showInfo: function showInfo() {
        this.send('showInfoRoute');
      },
      viewInfo: function viewInfo(params) {
        params.sidebarModelPath = 'contextHubController.sidebarModel';
        params.controller = 'contextHubController';
        this._super(params);
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.controller.toggleProperty('isRefreshFilterModel');
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});