define("experience-studio/components/route-model-table-dropdown-content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    isViewAnalyticsVisible: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('analyticsPageV2');
    }),
    actions: {
      edit: function edit() {
        this.transitionTo('content-hub.content-instance.edit', this.model);
      },
      sendPerformModelAction: function sendPerformModelAction(actionName) {
        this.performModelAction({
          action: actionName,
          record: this.model
        });
      }
    }
  });
  _exports.default = _default;
});