define("experience-studio/components/confirm-label-records-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['batch-actions-modal'],
    selectedLabels: null,
    selectedRecords: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        selectedLabels: [],
        selectedRecords: [],
        headers: [Ember.Object.create({
          name: 'Name',
          class: 'name-col'
        })]
      });
    },
    confirmModalTitle: Ember.computed('model.{pluralEntityName,isAdding}', function () {
      if (this.model.isAdding) {
        return "Add Labels to ".concat(this.model.pluralEntityName);
      } else {
        return "Remove Labels from ".concat(this.model.pluralEntityName);
      }
    }),
    searchPlaceholder: Ember.computed('model.pluralEntityName', function () {
      return "Search ".concat(this.model.pluralEntityName);
    }),
    isActionDisabled: Ember.computed('selectedRecords.[]', 'selectedLabels.[]', function () {
      return this.selectedRecords.length === 0 || this.selectedLabels.length === 0;
    }),
    sharedLabels: Ember.computed('model.{selectedEntities.[],isAdding}', function () {
      var labels = [];
      this.model.selectedEntities.forEach(function (item) {
        labels = labels.concat(item.labels.map(function (label) {
          return Ember.Object.create({
            name: label,
            value: label
          });
        }));
      });
      return labels.uniqBy('name');
    }),
    actions: {
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.performAction({
          action: 'fetchLabelsBatch',
          labelPromise: labelPromise,
          query: query
        });
      },
      label: function label() {
        var records = this.selectedRecords;
        var labels = this.selectedLabels;
        this.performAction({
          action: 'labelRecords',
          isAdding: this.model.isAdding,
          singularEntityName: this.model.singularEntityName,
          pluralEntityName: this.model.pluralEntityName,
          confirmModalTitle: this.confirmModalTitle,
          records: records,
          labels: labels
        });
        this.send('close');
      },
      onSelectRecords: function onSelectRecords(records) {
        var _this = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this.set('selectedRecords', records);
        });
      },
      onSelectLabels: function onSelectLabels(labels) {
        var _this2 = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this2.set('selectedLabels', labels);
        });
      },
      validationFunction: function validationFunction(record) {
        if (record.constructor.modelName === 'rule' && !record.isDraft) {
          return 'A production rule cannot be edited.';
        }
      }
    }
  });
  _exports.default = _default;
});