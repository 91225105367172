define("experience-studio/components/route-model-table-uploads", ["exports", "experience-studio/components/route-model-table-entity"], function (_exports, _routeModelTableEntity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelTableEntity.default.extend({
    classNames: ['route-model-table-uploads'],
    utility: Ember.inject.service(),
    data: null,
    isErrorActive: false,
    activeErrorModel: null,
    errorMessages: Ember.computed('activeErrorModel', function () {
      var errorMessages = '';
      if (this.activeErrorModel && this.activeErrorModel.statusMessage) {
        errorMessages = this.activeErrorModel.statusMessage;
      } else if (this.activeErrorModel && this.activeErrorModel.statusData && this.activeErrorModel.statusData.statusMessage) {
        errorMessages = this.activeErrorModel.statusData.statusMessage;
      }
      return errorMessages;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.$('[data-toggle="tooltip"]').tooltip();
      });
    },
    actions: {
      viewErrors: function viewErrors(model) {
        this.set('activeErrorModel', model);
      },
      closeErrors: function closeErrors() {
        this.set('activeErrorModel', null);
      }
    }
  });
  _exports.default = _default;
});