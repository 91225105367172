define("experience-studio/components/info-sidebar-permission-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar-permission-role']
  });
  _exports.default = _default;
});