define("experience-studio/components/content-form-editor-dropped-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isEditModeOn:edit-mode'],
    classNames: ['content-form-editor-dropped-item'],
    utility: Ember.inject.service(),
    languages: Ember.computed(function () {
      return this.utility.get('languages');
    }),
    isEditModeOn: false,
    selectedModel: null,
    model: null,
    updateMode: Ember.observer('selectedModel', function () {
      if (this.selectedModel !== this.model) {
        this.set('isEditModeOn', false);
      } else {
        this.set('isEditModeOn', true);
      }
    }),
    click: function click() {
      this.sendAction('select', this.model); // eslint-disable-line ember/closure-actions
    },
    focusIn: function focusIn() {
      this.sendAction('select', this.model); // eslint-disable-line ember/closure-actions
    },

    updateFocus: Ember.observer('isEditModeOn', function () {
      if (this.isEditModeOn) {
        Ember.run.scheduleOnce('afterRender', this, 'setFocus');
      }
    }),
    setFocus: function setFocus() {
      this.$('.localized-input input').first().focus();
    },
    actions: {
      delete: function _delete() {
        this.sendAction('delete', this.model); // eslint-disable-line ember/closure-actions
      },
      duplicate: function duplicate() {
        this.sendAction('duplicate', this.model); // eslint-disable-line ember/closure-actions
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.model === this.selectedModel) {
        this.set('isEditModeOn', true);
      }
    }
  });
  _exports.default = _default;
});