define("experience-studio/routes/experiences/groups", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/model-actions", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-groups"], function (_exports, _authenticatedRoute, _modelActions, _routeModelBatchActionsBase, _routeModelBatchActionsGroups) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _modelActions.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsGroups.default, {
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        groups: store.findAll('group'),
        experiences: store.query('experience', {
          sort: 'priority'
        }),
        languages: store.queryRecord('project-config', {
          setting: 'language'
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          groupSearchKeyword: '',
          groupSortBy: '',
          groupSortOrder: ''
        });
        this.send('deselectModelListItems');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        selectedEntities: [],
        groupListSortProperties: [Ember.Object.create({
          label: 'Name',
          sortValue: 'name',
          isSorted: false,
          order: {
            desc: 'A-Z',
            asc: 'Z-A'
          }
        }), Ember.Object.create({
          label: 'Date Created',
          sortValue: 'createdAt',
          isSorted: true,
          order: {
            desc: 'Newest',
            asc: 'Oldest'
          }
        }), Ember.Object.create({
          label: 'Priority',
          sortValue: 'priorityIndex',
          isSorted: false,
          order: {
            desc: 'Lowest',
            asc: 'Highest'
          }
        })],
        sortedModel: []
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createGroup: function createGroup() {
        this.send('handleAction', 'create', null, {
          type: 'group'
        });
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('groupSearchKeyword', searchObject.keyword);
      },
      handleAction: function handleAction(action, model, params) {
        switch (action) {
          case 'create':
            // override group create
            if (params.type === 'group') {
              this.send('showManageGroupModalDialog');
            } else {
              this._super.apply(this, arguments);
            }
            // if not a group call super create
            break;
          case 'edit':
            this.send('showManageGroupModalDialog', model);
            break;
          default:
            this._super.apply(this, arguments);
            break;
        }
      },
      showManageGroupModalDialog: function showManageGroupModalDialog(group) {
        var model = this.controller.get('model');
        var data = Ember.Object.create({
          experiences: this.store.findAll('experience'),
          groups: model.groups,
          group: group ? group : this.store.createRecord('group'),
          languages: model.languages.config
        });
        this.controller.set('manageGroupModalData', data);
      },
      showCreateGroupModal: function showCreateGroupModal() {
        this.transitionTo('experiences.groups.create');
      },
      updateSortedModel: function updateSortedModel(sortedModel) {
        this.controller.set('sortedModel', sortedModel);
      }
    }
  });
  _exports.default = _default;
});