define("experience-studio/mixins/route-model-batch-actions-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modalData = Ember.Object.extend({
    singularEntityName: 'Group',
    pluralEntityName: 'Groups'
  });
  var _default = Ember.Mixin.create({
    utility: Ember.inject.service(),
    actions: {
      showDeleteRecordsModal: function showDeleteRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-delete-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          message: "Deleting this group will not affect associated\n          experiences. You will be able to access these\n          items in Experiences. This is permanent, and connot be undone."
        }));
      },
      showDuplicateRecordsModal: function showDuplicateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-duplicate-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities
        }));
      },
      showAssociateRecordsModal: function showAssociateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-associate-records-modal-dialog',
          experiences: this.store.findAll('Experience'),
          associateVar: 'groupIds',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAssociating: true
        }));
      },
      showDisassociateRecordsModal: function showDisassociateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-associate-records-modal-dialog',
          experiences: this.store.findAll('Experience'),
          associateVar: 'groupIds',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAssociating: false
        }));
      },
      duplicateRecords: function duplicateRecords(params) {
        var _this = this;
        var records = params.records;
        var requests = this.createBatchRequest(records, null, function (record) {
          var recordObject = record.toJSON();
          recordObject.name = "".concat(recordObject.name, " - Copy");
          delete recordObject.priorityIndex;
          return _this.store.createRecord('group', recordObject).save();
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            records: records,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            confirmModalTitle: params.confirmModalTitle,
            retryAction: 'showDuplicateRecordsModal',
            finishAction: 'finalizeRecordsAction',
            failedPostfix: 'duplicate.',
            sucessPostfix: 'duplicated!',
            total: records.length
          }));
        });
      }
    }
  });
  _exports.default = _default;
});