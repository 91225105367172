define("experience-studio/templates/components/list-view-confirmation-modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QkEu7t1k",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"onClose\",\"clickOutsideToClose\",\"containerClassNames\",\"translucentOverlay\"],[\"close\",true,[35,1],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-dialog\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n        \"],[10,\"p\"],[14,0,\"primary-message\"],[12],[2,\"\\n          \"],[10,\"strong\"],[12],[2,\"Try the New List View\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"secondary-message\"],[12],[2,\"\\n          List view makes it easier to view information and helps you stay\\n          organized. You can revert back to card view at any time using the link\\n          on the side-menu.\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link btn-cancel\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"NOT NOW\"],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"continue\"],null],[12],[2,\"TRY LIST VIEW\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"classNames\",\"modal-dialog\"]}",
    "moduleName": "experience-studio/templates/components/list-view-confirmation-modal-dialog.hbs"
  });
  _exports.default = _default;
});