define("experience-studio/components/invitees-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['invitees-list'],
    model: null,
    modelSham: Ember.computed('model.[]', function () {
      var model = this.model;
      if (!Ember.isEmpty(model)) {
        model.forEach(function (item) {
          if (item.get('acceptedAt') > 0) {
            item.set('status', 'Confirmed');
          } else {
            item.set('status', 'Pending');
          }
        });
      }
      var filteredModel = model.filter(function (item) {
        return !Ember.isEmpty(item.id);
      });
      return filteredModel;
    })
  });
  _exports.default = _default;
});