define("experience-studio/routes/content-hub/content-template/create-v2", ["exports", "experience-studio/mixins/field-validator-factory"], function (_exports, _fieldValidatorFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_fieldValidatorFactory.default, {
    queryParams: {
      data: {}
    },
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    intl: Ember.inject.service(),
    contextRuleUtility: Ember.inject.service(),
    payload: '',
    isNearbyLocationTemplate: false,
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    parentWindowListener: function parentWindowListener(e) {
      var command = e.data.command;
      if (command === 'sendProps') {
        this.set('payload', e.data.payload);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      window.addEventListener('message', this.parentWindowListener.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('message', this.parentWindowListener);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var isNearByTemplate = transition.to.queryParams.isNearByTemplate;
      this.isNearbyLocationTemplate = isNearByTemplate === 'true';
    },
    model: function model(params) {
      var contentInstance;
      if (params.data) {
        contentInstance = this.extractModel(params);
      } else {
        var contentTemplate = this.modelFor('content-hub.content-template');
        var contInstData = contentTemplate.contentInstanceModalData;
        if (contInstData && contInstData.isConciergeTemplate) {
          contentInstance = contInstData;
        } else {
          var payload = Ember.Object.create(this.payload);
          contentInstance = this.store.createRecord('contentInstance');
          // overwrite below values with data sent from iframe.
          contentInstance.contentTemplateId = contentTemplate.id;
          contentInstance.templateType = contentTemplate.type;
          contentInstance.localizations = payload.localizations;
          contentInstance.name = payload.title || payload.name;
          contentInstance.description = payload.description;
          contentInstance.iconUrl = payload.iconUrl;
          contentInstance.isContextLocked = payload.isContextLocked;
          contentInstance.labels = contentTemplate.labels;
          contentInstance.selectedLabels = payload.labels;
          contentInstance.isNearbyLocationTemplate = this.isNearbyLocationTemplate;
          // set this property only for NearbyLocation template
          if (this.isNearbyLocationTemplate) {
            contentInstance.values = {
              root: payload.get('data')
            };
          }
        }
      }
      if (contentInstance.originalContentInstanceId) {
        return Ember.RSVP.hash({
          content: this.store.queryRecord('content-instance', {
            id: contentInstance.originalContentInstanceId,
            data: true,
            bypassAdmin: true
          }),
          plugins: this.store.findAll('plugin'),
          contentInstance: contentInstance,
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          projectCustomTemplates: this.store.queryRecord('project-config', {
            setting: 'project-custom-content-templates'
          })
        });
      } else {
        return Ember.RSVP.hash({
          contentInstance: contentInstance,
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          plugins: this.store.findAll('plugin'),
          projectCustomTemplates: this.store.queryRecord('project-config', {
            setting: 'project-custom-content-templates'
          })
        });
      }
    },
    afterModel: function afterModel(model) {
      if (model.content) {
        model.contentInstance.setProperties({
          content: model.content.get('content'),
          values: model.content.get('values')
        });
      }
      var isConciergeTemplate = false;
      if (model.projectCustomTemplates && model.projectCustomTemplates.config && model.projectCustomTemplates.config.categories) {
        var categories = model.projectCustomTemplates.config.categories;
        isConciergeTemplate = categories.length > 0 && this.contextRuleUtility.isConciergeTemplate(model.contentInstance.templateType);
      }
      model.contentInstance.set('isConciergeTemplate', isConciergeTemplate);
    },
    extractModel: function extractModel(params) {
      var data = this.utility.atob(params.data);
      data.name += ' - Copy';
      return this.store.createRecord('contentInstance', data);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: controller.model.get('name'),
        description: controller.model.get('description'),
        image: controller.model.get('iconUrl'),
        locked: controller.model.get('isContextLocked') || false,
        data: controller.model.serialize().data,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var applicationController = this.controllerFor('application');
      var queryParams = null;
      var routeName = 'content-hub.index';
      if (applicationController) {
        if (this.userPreferences.getValue('routeListView')) {
          routeName = 'content-hub-v2.index';
          queryParams = {
            data: this.utility.btoa({
              timestamp: Date.now()
            })
          };
        }
      }
      if (queryParams) {
        this.transitionTo(routeName, {
          queryParams: queryParams
        });
      } else {
        this.transitionTo(routeName, {
          queryParams: {
            data: ''
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      controller.setProperties({
        languages: model.languages.config,
        plugins: model.plugins,
        selectedLabels: [],
        transitionConfirmed: false,
        displayFooter: true
      });
      model = model.contentInstance;
      Ember.run.scheduleOnce('afterRender', this, function () {
        controller.set('initialHash', _this.utility.hash({
          name: model.get('name'),
          description: model.get('description'),
          image: model.get('iconUrl'),
          locked: model.get('isContextLocked') || false,
          data: model.serialize().data,
          selectedLabels: controller.selectedLabels.mapBy('value').sort()
        }));
      });
      this._super(controller, model);
    },
    actions: {
      displayFooter: function displayFooter() {
        var isFooterDisplayed = this.controller.get('displayFooter');
        this.controller.set('displayFooter', isFooterDisplayed ? false : true);
      },
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        parent.postMessage({
          command: "cancelled"
        }, '*');
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        if (this.hasChanges()) {
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this.send('cancel');
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      save: function save() {
        var _this2 = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var selectedLabels = this.controller.get('selectedLabels');
        var model = controller.model;
        var data = model.get('root').toData();
        if (this.controller.data) {
          model = this.store.createRecord('contentInstance', model.toJSON());
        }

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this2.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          // Assign labels in general to model
          model.set('labels', _this2.payload.labels);
          if (_this2.isNearbyLocationTemplate) {
            return model;
          } else {
            return model.save();
          }
        }).then(function () {
          _this2.controller.set('transitionConfirmed', true);
          _this2.send('refresh');
          _this2.goBack();
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            var jsonModel = model.toJSON();
            jsonModel.data = data.root;
            jsonModel.templateId = jsonModel.contentTemplateId;
            parent.postMessage({
              command: "success",
              id: model.get('id'),
              content: jsonModel
            }, '*');
            _this2.send('showNotification', {
              type: 'confirm',
              message: "Successfully created \"".concat(model.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', _this2.utility.parseError(response));
        });
      },
      validate: function validate() {
        var controller = this.controller;
        var model = Ember.Object.create(controller.get('model.root'));
        var languages = controller.get('languages');
        if (this.isNearbyLocationTemplate) {
          model.setProperties({
            schema: model.schema.filter(function (field) {
              return field.type !== 'ctlocation';
            })
          });
        }
        var validator = _fieldValidatorFactory.default.getValidator(model, languages);
        var validation = validator.validate({
          triggerCascade: true
        });
        if (!Ember.isEmpty(controller.get('model.iconUrl')) && !controller.get('model.iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(controller.get('model.name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else if (!validation.state) {
          var plural = validation.errors.length > 1 ? 's' : '';
          controller.set('errorMessage', "You have (".concat(validation.errors.length, ") unresolved error").concat(plural, "."));
        } else {
          controller.set('errorMessage', '');
          this.send('save');
        }
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          window.history.go(1);
          this.send('confirmCancel');
        } else {
          controller.setProperties({
            data: '',
            errorMessage: '',
            transitionConfirmed: false,
            previousTransition: null
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});