define("experience-studio/templates/components/labeled-locations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "waiqNwwy",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"ul\"],[14,0,\"location-list\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[1,[30,[36,0],null,[[\"model\"],[[32,1]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[1,[34,4]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"labeled-locations-list-item\",\"model\",\"-track-array\",\"each\",\"labeledLocations\"]}",
    "moduleName": "experience-studio/templates/components/labeled-locations-list.hbs"
  });
  _exports.default = _default;
});