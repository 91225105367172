define("experience-studio/routes/analytics/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // reset controller required in near future
    av5TourGuide: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var analyticsCardGroups = [];
      var analyticsCharts = [];
      var charts = [];
      var sharedDateRange = null;
      var isAnalyticsUpdateEnabled = this.userPreferences.getValue('newAnalyticUpdate');
      if (isAnalyticsUpdateEnabled) {
        analyticsCardGroups.pushObject([Ember.Object.create({
          title: 'TOTAL USERS',
          tooltip: 'The number of unique users who have opted-in to Flybits section of your app as of today.',
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'users/total',
            from: '',
            to: '',
            isProduction: true,
            isCached: true
          })],
          isDropdown: false,
          isPercentagePie: false,
          isBreakdownPie: false,
          isCollapsed: true,
          mainStatType: 'value'
        }), Ember.Object.create({
          title: 'ENGAGED USERS',
          tooltip: 'The number of unique Flybits users who have viewed a Flybits push notification or viewed a Flybits content item today.',
          requests: [Ember.Object.create({
            domain: 'analytics',
            endpoint: 'analytics/users/totalActive',
            from: '',
            to: '',
            isProduction: true
          })],
          isDropdown: true,
          isPercentagePie: false,
          isBreakdownPie: false,
          isCollapsed: true,
          mainStatType: 'value'
        }), Ember.Object.create({
          key: 'total-devices',
          title: 'TOTAL DEVICES',
          tooltip: 'The number of unique devices opted-in to the Flybits section of your app as of today.',
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'devices/total',
            from: '',
            to: '',
            isProduction: true
          })],
          isDropdown: false,
          isPercentagePie: false,
          isBreakdownPie: true,
          isTabbed: true,
          mainStatType: 'summation'
        }), Ember.Object.create({
          key: 'engaged-devices',
          title: 'ENGAGED DEVICES',
          tooltip: 'The number of unique devices that has viewed a Flybits push notification or viewed a Flybits content item today.',
          subtitle: 'TODAY',
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'devices/totalEngaged',
            from: '',
            to: '',
            isProduction: true
          })],
          isDropdown: true,
          isPercentagePie: false,
          isBreakdownPie: true,
          isTabbed: true,
          mainStatType: 'summation'
        }), Ember.Object.create({
          title: 'Users Opted into Location & Push',
          tooltip: 'The number of unique users who have enabled location services and/or push notifications on at least one device.',
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'locationAndPush/optOut',
            from: '',
            to: '',
            isProduction: true
          }), Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'push/optIn',
            from: '',
            to: '',
            isProduction: true
          }), Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'location/optIn',
            from: '',
            to: '',
            isProduction: true
          }), Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'locationAndPush/optIn',
            from: '',
            to: '',
            isProduction: true
          })],
          isDropdown: false,
          isPercentagePie: false,
          isBreakdownPie: false,
          isDoublePieChart: true,
          mainStatType: 'value'
        })]);
        analyticsCharts.push(Ember.Object.create({
          title: 'TOTAL USERS',
          subtitle: '',
          tooltip: 'The number of unique users who have opted-in to Flybits section over a period of time.',
          areaStyle: {
            borderColor: '#6D78D1',
            areaColor: '#6D78D1'
          },
          tooltipConfig: {
            title: 'Total Users'
          },
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'users/timeline/total',
            from: '',
            to: '',
            isProduction: true,
            interval: 'day'
          })],
          isDropdown: false,
          isPercentagePie: false,
          isBreakdownPie: false
        }), Ember.Object.create({
          title: 'ENGAGED USERS',
          subtitle: '',
          tooltip: 'The number of unique Flybits users who have viewed a Flybits push notification or viewed a Flybits content item over a period of time.',
          areaStyle: {
            borderColor: '#66D0B6',
            areaColor: '#66D0B6'
          },
          tooltipConfig: {
            title: 'Engaged Users'
          },
          requests: [Ember.Object.create({
            domain: 'analytics',
            endpoint: 'analytics/users/timeline/active',
            from: '',
            to: '',
            isProduction: true,
            interval: 'day'
          })],
          isDropdown: true,
          isPercentagePie: false,
          isBreakdownPie: false
        }));
      } else {
        analyticsCardGroups.pushObject([Ember.Object.create({
          title: 'TOTAL USERS',
          tooltip: 'The number of unique users who have opted-in to Flybits section of your app as of today.',
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'users/total',
            from: '',
            to: '',
            isProduction: true,
            isCached: true
          })],
          isDropdown: false,
          isPercentagePie: false,
          isBreakdownPie: false,
          mainStatType: 'value'
        }), Ember.Object.create({
          title: 'ENGAGED USERS',
          tooltip: 'The number of unique Flybits users who have viewed a Flybits push notification or viewed a Flybits content item today.',
          requests: [Ember.Object.create({
            domain: 'analytics',
            endpoint: 'analytics/users/totalActive',
            from: '',
            to: '',
            isProduction: true
          })],
          isDropdown: true,
          isPercentagePie: false,
          isBreakdownPie: false,
          mainStatType: 'value'
        }), Ember.Object.create({
          title: 'OPT IN TO PUSH NOTIFICATIONS',
          tooltip: 'The number of unique users who are able to receive push notifications sent by Flybits on your app.',
          requests: [Ember.Object.create({
            domain: 'push/token',
            endpoint: 'users/push/total',
            from: '',
            to: '',
            isProduction: true
          }), Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'users/total',
            from: '',
            to: '',
            isProduction: true,
            isCached: true
          })],
          isDropdown: false,
          isPercentagePie: true,
          isBreakdownPie: false,
          mainStatType: 'value'
        })]);
        analyticsCardGroups.pushObject([Ember.Object.create({
          title: 'TOTAL DEVICES',
          tooltip: 'The number of unique devices opted-in to the Flybits section of your app as of today.',
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'devices/total',
            from: '',
            to: '',
            isProduction: true
          })],
          isDropdown: false,
          isPercentagePie: false,
          isBreakdownPie: true,
          mainStatType: 'summation'
        }), Ember.Object.create({
          title: 'ENGAGED DEVICES',
          tooltip: 'The number of unique devices that has viewed a Flybits push notification or viewed a Flybits content item today.',
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'devices/totalEngaged',
            from: '',
            to: '',
            isProduction: true
          })],
          isDropdown: true,
          isPercentagePie: false,
          isBreakdownPie: true,
          mainStatType: 'summation'
        })]);
        analyticsCharts.push(Ember.Object.create({
          title: 'TOTAL USERS',
          subtitle: '',
          tooltip: 'The number of unique users who have opted-in to Flybits section over a period of time.',
          areaStyle: {
            borderColor: '#5661B7',
            areaColor: '#5661B7'
          },
          tooltipConfig: {
            title: 'Total Users'
          },
          requests: [Ember.Object.create({
            domain: 'analytics/analytics',
            endpoint: 'users/timeline/total',
            from: '',
            to: '',
            isProduction: true,
            interval: 'day'
          })],
          isDropdown: false,
          isPercentagePie: false,
          isBreakdownPie: false
        }), Ember.Object.create({
          title: 'ENGAGED USERS',
          subtitle: '',
          tooltip: 'The number of unique Flybits users who have viewed a Flybits push notification or viewed a Flybits content item over a period of time.',
          areaStyle: {
            borderColor: '#72aea0',
            areaColor: '#a0d8cc'
          },
          tooltipConfig: {
            title: 'Engaged Users'
          },
          requests: [Ember.Object.create({
            domain: 'analytics',
            endpoint: 'analytics/users/timeline/active',
            from: '',
            to: '',
            isProduction: true,
            interval: 'day'
          })],
          isDropdown: true,
          isPercentagePie: false,
          isBreakdownPie: false
        }));
      }
      controller.setProperties({
        analyticsCardGroups: analyticsCardGroups,
        analyticsCharts: analyticsCharts,
        charts: charts,
        sharedDateRange: sharedDateRange
      });
    },
    actions: {
      didTransition: function didTransition() {
        Ember.run.scheduleOnce('afterRender', this.av5TourGuide, this.av5TourGuide.initiateAv5Tour);
      }
    }
  });
  _exports.default = _default;
});