define("experience-studio/templates/components/av5-info-card-tabbed-stat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kY4cnSYJ",
    "block": "{\"symbols\":[\"card\",\"card\"],\"statements\":[[10,\"div\"],[14,0,\"tab-titles\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[\"tab-title tab-title__\",[32,2,[\"key\"]],[30,[36,3],[[30,[36,2],[[32,2,[\"key\"]],[35,1,[\"key\"]]],null],\" tab-title--selected\",\"\"],null]]]],[15,\"onclick\",[30,[36,4],[[32,0],\"selectTab\",[32,2]],null]],[12],[2,\"\\n      \"],[1,[32,2,[\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[13],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"key\"]],[35,1,[\"key\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"isBreakdownPie\",\"isDropdown\",\"isPercentagePie\",\"mainStatType\",\"requests\",\"tooltip\",\"key\"],[[32,1,[\"isBreakdownPie\"]],[32,1,[\"isDropdown\"]],[32,1,[\"isPercentagePie\"]],[32,1,[\"mainStatType\"]],[32,1,[\"requests\"]],[32,1,[\"tooltip\"]],[32,1,[\"key\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"av5-info-card-single-stat\",\"currentSelectedCard\",\"eq\",\"if\",\"action\",\"analyticCards\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/av5-info-card-tabbed-stat.hbs"
  });
  _exports.default = _default;
});