define("experience-studio/helpers/dynamic-attribute-rtf-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deserializeDynamicAttributes = deserializeDynamicAttributes;
  _exports.serializeDynamicAttributes = serializeDynamicAttributes;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  // converts dynamic attributes in html string from backend recognizable format
  //  to elements to be displayed in rich text editor
  function deserializeDynamicAttributes(html, pluginAttrsMap, isRichTextArea, hideTitle) {
    var result = '';
    var currIdx = 0;
    html = isRichTextArea ? html : html.replaceAll(' ', '&nbsp;');
    while (currIdx >= 0 && currIdx < html.length) {
      var newHTML = html.substr(currIdx);
      var startIdx = newHTML.indexOf('{{');
      var endIdx = startIdx + newHTML.substr(startIdx).indexOf('}}');
      if (startIdx === -1 || endIdx === -1) break;
      result += html.substr(currIdx, startIdx);
      var dynamicAttr = newHTML.substring(startIdx + 2, endIdx).split(',');
      if (pluginAttrsMap[dynamicAttr[0]]) {
        var defaultDynamicValue = dynamicAttr.slice(1, dynamicAttr.length).join(',');
        if (hideTitle) {
          result += "<span class=\"dynamic-attr-wrapper\"><span class=\"dynamic-attr\" contenteditable=\"false\"><span class=\"dynamic-attr__attr\" data-mce-dynamic-attribute=\"".concat(dynamicAttr[0], "\">").concat(pluginAttrsMap[dynamicAttr[0]], "</span>").concat(defaultDynamicValue ? "<q class=\"dynamic-attr__default-value\">".concat(defaultDynamicValue, "</q>") : '', "</span></span>");
        } else {
          result += "<span class=\"dynamic-attr-wrapper\"><span class=\"dynamic-attr\" title=\"right click to edit\" contenteditable=\"false\"><span class=\"dynamic-attr__attr\" data-mce-dynamic-attribute=\"".concat(dynamicAttr[0], "\">").concat(pluginAttrsMap[dynamicAttr[0]], "</span>").concat(defaultDynamicValue ? "<q class=\"dynamic-attr__default-value\">".concat(defaultDynamicValue, "</q>") : '', "</span></span>");
        }
      } else {
        result += newHTML.substring(startIdx, endIdx + 2);
      }
      currIdx += endIdx + 2;
    }
    result += html.substr(currIdx);
    var updatedResult = '';
    if (isRichTextArea) {
      updatedResult = result;
    } else {
      var newLineElement = result.split('\n');
      if (newLineElement && newLineElement.length > 1) {
        for (var i = 0; i < newLineElement.length; ++i) {
          if (i === 0) {
            updatedResult += newLineElement[i];
          } else {
            if (newLineElement[i]) {
              updatedResult += "<div>".concat(newLineElement[i], "</div>");
            } else {
              updatedResult += "<div><br/></div>";
            }
          }
        }
      } else {
        updatedResult = result;
      }
    }
    var container = document.createElement('span');
    container.innerHTML = updatedResult;
    var dynamicAttrs = _toConsumableArray(container.querySelectorAll('span.dynamic-attr'));
    for (var _i = 0; _i < dynamicAttrs.length; ++_i) {
      if (dynamicAttrs[_i].parentNode && !(dynamicAttrs[_i].parentNode.nodeName === 'SPAN' && dynamicAttrs[_i].parentNode.className === 'dynamic-attr-wrapper')) {
        var wrapper = document.createElement('span');
        wrapper.className = 'dynamic-attr-wrapper';
        wrapper.appendChild(dynamicAttrs[_i].cloneNode(true));
        dynamicAttrs[_i].replaceWith(wrapper);
      }
    }
    return container.innerHTML.replaceAll(/<br class="Apple-interchange-newline">/g, '');
  }

  // converts dyanmic attribute elements displayed in rich text editor to
  //  backend recognizable format
  function serializeDynamicAttributes(html, isRichTextArea, isTextArea) {
    var container = document.createElement('span');
    container.innerHTML = html.replaceAll('&nbsp;', ' ');
    var dynamicAttrs = _toConsumableArray(container.querySelectorAll('span.dynamic-attr'));
    if (!dynamicAttrs.length) {
      var dynamicAttrsWrapper = _toConsumableArray(container.querySelectorAll('span.dynamic-attr-wrapper'));
      var _loop = function _loop(i) {
        var wrapper = dynamicAttrsWrapper[i];
        if (wrapper.childNodes.length) {
          wrapper.childNodes.forEach(function (child) {
            wrapper.parentNode.insertBefore(child, wrapper);
          });
        }
        wrapper.remove();
      };
      for (var i = dynamicAttrsWrapper.length - 1; i >= 0; --i) {
        _loop(i);
      }
      var _divWrapper = _toConsumableArray(container.querySelectorAll('p.dynamic-attr-p'));
      var _loop2 = function _loop2(_i2) {
        var wrapper = _divWrapper[_i2];
        if (wrapper.childNodes.length) {
          wrapper.childNodes.forEach(function (child) {
            var text = document.createTextNode('\n');
            wrapper.parentNode.insertBefore(text, wrapper);
            if (child.nodeName !== 'BR') {
              wrapper.parentNode.insertBefore(child, wrapper);
            }
          });
        }
        wrapper.remove();
      };
      for (var _i2 = _divWrapper.length - 1; _i2 >= 0; --_i2) {
        _loop2(_i2);
      }
      if (!isRichTextArea) {
        if (isTextArea) {
          container.innerHTML = container.innerHTML.replace(/<br>/g, '\n');
        }
        container.innerHTML = container.innerText;
      }
      return container.innerHTML;
    }
    for (var _i3 = dynamicAttrs.length - 1; _i3 >= 0; --_i3) {
      var wrapper = dynamicAttrs[_i3].parentNode;
      if (wrapper.parentNode) {
        for (var j = 0; j < wrapper.childNodes.length; ++j) {
          if (wrapper.childNodes[j].className === 'dynamic-attr') {
            var defaultValue = wrapper.childNodes[j].childNodes[1];
            wrapper.parentNode.insertBefore(document.createTextNode("{{".concat(wrapper.childNodes[j].childNodes[0].getAttribute('data-mce-dynamic-attribute')).concat(defaultValue ? ",".concat(defaultValue.innerText) : '', "}}")), wrapper);
          } else {
            wrapper.parentNode.insertBefore(wrapper.childNodes[j].cloneNode(true), wrapper);
          }
        }
      }
      wrapper.remove();
    }
    var wrappers = _toConsumableArray(container.querySelectorAll('span.dynamic-attr-wrapper'));
    var _loop3 = function _loop3(_i4) {
      var wrapper = wrappers[_i4];
      if (wrapper.childNodes.length) {
        wrapper.childNodes.forEach(function (child) {
          wrapper.parentNode.insertBefore(child, wrapper);
        });
      }
      wrapper.remove();
    };
    for (var _i4 = wrappers.length - 1; _i4 >= 0; --_i4) {
      _loop3(_i4);
    }
    var divWrapper = _toConsumableArray(container.querySelectorAll('p.dynamic-attr-p'));
    for (var _i5 = divWrapper.length - 1; _i5 >= 0; --_i5) {
      var _wrapper = divWrapper[_i5];
      if (_wrapper.childNodes.length) {
        var text = document.createTextNode('\n');
        _wrapper.parentNode.insertBefore(text, _wrapper);
        for (var _j = 0; _j <= _wrapper.childNodes.length - 1;) {
          if (_wrapper.childNodes[_j].nodeName !== 'BR') {
            _wrapper.parentNode.insertBefore(_wrapper.childNodes[_j], _wrapper);
          } else {
            _j++;
          }
        }
      }
      _wrapper.remove();
    }
    if (!isRichTextArea) {
      if (isTextArea) {
        container.innerHTML = container.innerHTML.replace(/<br>/g, '\n');
      }
      container.innerHTML = container.innerText;
    }
    return container.innerHTML;
  }
});