define("experience-studio/controllers/experiences", ["exports", "experience-studio/mixins/pagination-controller"], function (_exports, _paginationController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_paginationController.default, {
    sortBy: 'createdAt',
    sortOrder: 'desc',
    groupId: '',
    searchKeyword: '',
    page: 1,
    recordsPerPage: 12,
    queryParams: [{
      groupId: 'groupId',
      sortBy: 'sortBy',
      sortOrder: 'sortOrder',
      searchKeyword: 'search',
      page: 'page',
      recordsPerPage: 'recordsPerPage'
    }],
    userPreferences: Ember.inject.service(),
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Grouping',
      icon: 'th-large',
      subActions: [Ember.Object.create({
        label: 'Add to an existing Group',
        action: 'showAddGroupRecordsModal'
      }), Ember.Object.create({
        label: 'Remove from Group',
        action: 'showRemoveGroupRecordsModal'
      })]
    }), Ember.Object.create({
      label: 'Labels',
      icon: 'tag',
      subActions: [Ember.Object.create({
        label: 'Add Labels',
        action: 'showAddLabelRecordsModal',
        subActions: []
      }), Ember.Object.create({
        label: 'Remove Labels',
        action: 'showRemoveLabelRecordsModal',
        subActions: [],
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('labels.length', 0)) {
            return 'These items have no labels to remove.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Activation',
      icon: 'power',
      subActions: [Ember.Object.create({
        label: 'Set as Active',
        action: 'showActivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isActive', true)) {
            return 'At least one item has to be Inactive.';
          }
          if (selectedEntities.isEvery('computedRuleObjects.firstObject.isDraft', true)) {
            return 'Cannot activate experiences which are associated to a draft rule';
          }
        }
      }), Ember.Object.create({
        label: 'Set as Inactive',
        action: 'showDeactivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isActive', false)) {
            return 'At least one item has to be Active.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: [],
      hasDivider: true
    })])
  });
  _exports.default = _default;
});