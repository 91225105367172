define("experience-studio/routes/permissions-hub/access-levels", ["exports", "experience-studio/mixins/model-actions"], function (_exports, _modelActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelActions.default, {
    model: function model() {
      return this.store.findAll('access-level');
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('searchKeyword', '');
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    actions: {
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.get('searchKeyword'));
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(options) {
        this.controller.set('searchKeyword', options.keyword);
      }
    }
  });
  _exports.default = _default;
});