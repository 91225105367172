define("experience-studio/components/route-focus-element", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Allows you to focus on an element when Ember renders route
  
  
    Usage:
  
    Required Properties and Actions:
  
    {{route-focus-element}}
  
  
    All Properties and Actions:
  
    When you want to focus specific element
    {{my-component
      elementSelector=".form-control"
    }}
  
    When you want to focus first element in a container
    {{my-component
      containerSelector=".route-create-experience"
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['route-focus-element'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, this.focusElement);
    },
    focusElement: function focusElement() {
      var elements = null;
      elements = (0, _jquery.default)(this.elementSelector);
      if (elements.length <= 0) {
        elements = (0, _jquery.default)(this.containerSelector).find('input');
      }
      if (elements.length <= 0) {
        elements = (0, _jquery.default)('body').find('input');
      }
      if (elements.length > 0) {
        var timeoutId = setTimeout(function () {
          clearTimeout(timeoutId);
          elements[0].focus();
        }, 0);
      }
    }
  });
  _exports.default = _default;
});