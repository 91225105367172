define("experience-studio/templates/components/fb/dropdown-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4StKYl5y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[18,1,[[30,[36,2],null,[[\"item\",\"submenu\"],[[30,[36,1],[\"fb/dropdown-item\"],null],[30,[36,1],[\"fb/dropdown-submenu\"],[[\"childDir\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"childDir\",\"component\",\"hash\"]}",
    "moduleName": "experience-studio/templates/components/fb/dropdown-content.hbs"
  });
  _exports.default = _default;
});