define("experience-studio/components/main-sidebar", ["exports", "experience-studio/mixins/access-level", "experience-studio/config/environment"], function (_exports, _accessLevel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_accessLevel.default, {
    classNames: ['main-sidebar'],
    isRouteListViewOptInModalVisible: false,
    session: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    av5TourGuide: Ember.inject.service(),
    router: Ember.inject.service(),
    analyticsPageV5Preference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('analyticsPageV5');
    }),
    canAccessPermissionsHub: Ember.computed('session.user.accessLevel.name', function () {
      return this.canAccessRoute('permissions-hub', this.get('session.user.accessLevel.value'));
    }),
    esExpressLinkPreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('experienceStudioExpressLink');
    }),
    nestorLinkPreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('nestorLink');
    }),
    userPreferencePagePreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('userPreferencePage');
    }),
    isAnalyticsPageVisible: Ember.computed(function () {
      var isAnalyticsPageVisible = true;
      var domain = window.location.origin;
      var blacklist = ['https://tdint3.flybits.com', 'https://tid-pat.flybits.com', 'https://tid-prod.flybits.com'];
      if (blacklist.includes(domain)) {
        isAnalyticsPageVisible = false;
      }
      return isAnalyticsPageVisible;
    }),
    isEsExpressDropdownItemVisible: Ember.computed('esExpressLinkPreference.value', function () {
      var value = false;
      if (_environment.default.environment !== 'production') {
        value = true;
      } else if (this.esExpressLinkPreference) {
        value = this.esExpressLinkPreference.get('value');
      }
      return value;
    }),
    isNestorVisible: Ember.computed('nestorLinkPreference.value', function () {
      var value = false;
      if (!_environment.default.environment.includes('production')) {
        value = true;
      } else if (this.nestorLinkPreference) {
        value = this.nestorLinkPreference.get('value');
      }
      return value;
    }),
    isSettingsDropdownItemVisible: Ember.computed('userPreferencePagePreference.value', function () {
      var value = false;
      if (this.userPreferencePagePreference) {
        value = this.userPreferencePagePreference.get('value');
      }
      return value;
    }),
    routeListViewPreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('routeListView');
    }),
    nestorUrl: Ember.computed('config.APP.nestorUrl', function () {
      var token = this.session.token;
      var env = _environment.default.environment;
      var url = "".concat(_environment.default.APP.nestorUrl, "/authorize/?environment=").concat(env, "&token=").concat(token);
      return url;
    }),
    developerPortalUrl: Ember.computed('session.user.accessLevel.value', function () {
      var accessLevel = this.get('session.user.accessLevel.value') || 0;
      var tenantId = '';
      var url = _environment.default.APP.developerPortalUrl;
      try {
        tenantId = this.session.decodeToken(this.session.token).tenantID;
      } catch (exception) {} // eslint-disable-line no-empty

      if (this.session.token && tenantId && accessLevel >= 20) {
        url = "".concat(url, "/project/").concat(tenantId);
      } else {
        url = _environment.default.APP.authenticationUrl;
      }
      return url;
    }),
    esExpressUrl: Ember.computed('session.user.accessLevel.value', function () {
      var accessLevel = this.get('session.user.accessLevel.value') || 0;
      var tenantId = '';
      var url = _environment.default.APP.esExpressUrl;
      var token = this.session.token;
      try {
        tenantId = this.session.decodeToken(token).tenantID;
      } catch (exception) {} // eslint-disable-line no-empty

      if (token && tenantId && accessLevel >= 20) {
        url = "".concat(url, "/#/authorize/?environment=").concat(_environment.default.environment, "&token=").concat(token);
      } else {
        url = "".concat(url, "/#/access-denied");
      }
      return url;
    }),
    versionDropdownItemText: Ember.computed(function () {
      return "".concat(_environment.default.modulePrefix, " ").concat(_environment.default.APP.appVersion);
    }),
    actions: {
      didLogoLoad: function didLogoLoad() {
        this.av5TourGuide.set('isMenuIconLoaded', true);
      },
      signOut: function signOut() {
        this.session.unauthenticate();
      },
      confirmSwitchView: function confirmSwitchView(isTryListView) {
        var preference = this.routeListViewPreference;
        if (isTryListView) {
          preference.setProperties({
            hasOptIn: true,
            value: false // switchView action makes it true when hasOptIn is true
          });

          this.send('switchView');
        } else {
          this.set('isRouteListViewOptInModalVisible', false);
        }
      },
      showNotification: function showNotification(options) {
        this.showNotification(options);
      },
      switchView: function switchView() {
        var _this = this;
        var preference = this.routeListViewPreference;
        if (preference.hasOptIn || preference.value) {
          preference.set('hasOptIn', true);
          preference.toggleProperty('value');
          preference.save().then(function () {
            _this.switchRoutes();
            _this.set('isRouteListViewOptInModalVisible', false);
          });
        } else {
          this.set('isRouteListViewOptInModalVisible', true);
        }
      }
    }
  });
  _exports.default = _default;
});