define("experience-studio/components/custom-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['custom-field', 'form-group'],
    classNameBindings: ['indicateInvalidity:is-invalid'],
    isDirty: false,
    disableValidMessage: false,
    name: Ember.computed('field.name', function () {
      if (Ember.isEmpty(this.field.name)) {
        var valueType = this.field.type === 'primitive' ? this.field.valueType : this.field.type;
        return "Field Name Not Set (".concat(valueType, ")");
      } else {
        return this.field.name;
      }
    }),
    indicateInvalidity: Ember.computed('field.isValid', 'isDirty', function () {
      var isValid = this.get('field.isValid');
      var isDirty = this.isDirty;
      return isDirty && !isValid;
    }),
    didInsertElement: function didInsertElement() {
      if (!Ember.isEmpty(this.get('field.value'))) {
        this.send('onValue', this.get('field.value'));
      } else {
        this.set('field.isValid', !this.get('field.isRequired'));
        this.sendAction('onValue', null); // eslint-disable-line ember/closure-actions
      }

      this._super.apply(this, arguments);
    },
    setDirty: function setDirty(value) {
      var isDirty = this.isDirty || !Ember.isEmpty(value);
      this.set('isDirty', isDirty);
    },
    getErrorMessage: function getErrorMessage(fieldType, valueType) {
      switch (fieldType) {
        case 'entity':
          return 'You must select a piece of Content';
        case 'url':
          return 'You must provide a valid URL';
        case 'image':
          return 'You must choose an image';
        case 'dropdown':
          return 'You must select an item from this list';
        case 'primitive':
          if (valueType === 'string') {
            return 'You must provide a text for this field';
          }
          if (valueType === 'bool') {
            return 'You must select Yes or No';
          }
          if (valueType === 'int') {
            return 'You must provide a whole number for this field';
          }
          if (valueType === 'float') {
            return 'You must provide a number for this field';
          }
          return 'You must provide an entry for this field';
        default:
          return 'Field is required';
      }
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (isValid && Ember.isEmpty(value) && this.field.isRequired && this.field.key !== 'url') {
        isValid = false;
        var errMessage = this.getErrorMessage(this.field.type, this.field.valueType);
        this.field.errorMessages.pushObject(errMessage);
      }
      this.set('field.isValid', isValid);
    },
    actions: {
      onValue: function onValue(value) {
        this.set('field.value', value);
        this.setDirty(value);
        this.setValidity(value);
        this.sendAction('onValue', value); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});