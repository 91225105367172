define("experience-studio/adapters/rule-simulation", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'context/rules',
    buildURL: function buildURL() {
      return this._super.apply(this, arguments).replace('rule-', '');
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return "".concat(url.replace('rule-', ''), "?limit=").concat(this.paginationLimit);
    }
  });
  _exports.default = _default;
});