define("experience-studio/serializers/triggered-email", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      item.attributes.content = this.transformToEmberObject(item.attributes.content);
      item.attributes.rule = this.transformToEmberObject(item.attributes.rule);
      return item;
    }
  });
  _exports.default = _default;
});