define("experience-studio/routes/content-hub/blueprint-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        templates: this.store.query('content-template', {
          location: true
        }, {
          reload: true
        })
      });
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    actions: {
      closeModal: function closeModal() {
        this.controller.set('contentInstanceModalData', null);
      },
      create: function create(template) {
        this.transitionTo('content-hub.blueprint-template.create', template);
      },
      cancel: function cancel() {
        window.history.back();
      },
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      validate: function validate() {
        var errorMessage;
        var controller = this.controller;
        var selectedTemplate = controller.model.templates.findBy('isSelected', true);
        if (!selectedTemplate) {
          errorMessage = 'Select Content.';
        }
        controller.set('errorMessage', errorMessage);
        if (!errorMessage) {
          this.send('create', selectedTemplate);
        }
      }
    }
  });
  _exports.default = _default;
});