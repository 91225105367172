define("experience-studio/components/model-select-dropdown--trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-select-dropdown--trigger', 'dropdown-toggle'],
    selectedCount: Ember.computed('select.options.@each.dropdownSelected', function () {
      var experiences = this.get('select.options') || [];
      return experiences.filterBy('dropdownSelected').length;
    })
  });
  _exports.default = _default;
});