define("experience-studio/components/route-model-table-push-message", ["exports", "experience-studio/components/route-model-table-entity", "jquery"], function (_exports, _routeModelTableEntity, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelTableEntity.default.extend({
    classNames: ['route-model-table-push-message'],
    utility: Ember.inject.service(),
    data: null,
    currentTime: Ember.computed(function () {
      return window.moment().unix() * 1000;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('headers', [Ember.Object.create({
        label: 'Name',
        sortProperty: 'name',
        isSelected: false,
        class: 'name-col',
        isHidden: false,
        tooltip: 'The name of this Push Notification'
      }), Ember.Object.create({
        label: 'Status',
        isSelected: false,
        class: 'status-col',
        isHidden: false,
        tooltip: "Indicates if the message is active vs. inactive (for Triggered\n                  Push Notifications), or sent vs. scheduled (for Broadcast\n                  Push Notifications)"
      }), Ember.Object.create({
        label: 'Start',
        isSelected: false,
        class: 'start-col',
        isHidden: false,
        tooltip: "Start or Send Date/Time of your message"
      }), Ember.Object.create({
        label: 'End',
        isSelected: false,
        class: 'end-col',
        isHidden: false,
        tooltip: "End Date/Time of your message"
      }), Ember.Object.create({
        label: 'Delivery',
        sortProperty: 'pushType',
        isSelected: false,
        class: 'delivery-col',
        isHidden: false,
        tooltip: "Indicates if the message is Broadcast (sent once) or Triggered (a\n                  recurring message based on a trigger)"
      }), Ember.Object.create({
        label: 'Date Created',
        sortProperty: 'createdAt',
        isSelected: false,
        isAsc: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'The date this Push Message was created on'
      })]);
    },
    didUpdate: function didUpdate() {
      this.initializeTooltips();
      this._super.apply(this, arguments);
    },
    willUpdate: function willUpdate() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
    },
    initializeTooltips: function initializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    }
  });
  _exports.default = _default;
});