define("experience-studio/templates/test2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iPw0Ad8K",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,6],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"rule\",\"isModalOpen\",\"forceDuplicate\",\"conflictingEntities\",\"onSuccess\"],[[35,4,[\"rule\"]],[35,3],[35,2],[35,1],[30,[36,0],[\"handleOnSuccess\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"conflictingEntities\",\"forceDuplicate\",\"isModalOpen\",\"model\",\"duplicate-rule-modal-dialog\",\"if\"]}",
    "moduleName": "experience-studio/templates/test2.hbs"
  });
  _exports.default = _default;
});