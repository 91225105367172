define("experience-studio/components/info-sidebar-push-message", ["exports", "jquery", "experience-studio/mixins/time-conversion", "experience-studio/helpers/number-ordinal", "ember-inflector"], function (_exports, _jquery, _timeConversion, _numberOrdinal, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Component.extend(_timeConversion.default, {
    classNames: ['info-sidebar-push-message'],
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    scheduleStartDate: Ember.computed('model.{activeSchedule,activeEndSchedule,status}', function () {
      var startDateText = '';
      if (!this.model.isDraft && (this.model.activeSchedule || this.model.inactiveSchedule)) {
        var startWord = this.model.isTriggered ? this.model.inactiveSchedule ? 'Started at ' : 'Starts at ' : this.model.inactiveSchedule ? 'Sent at ' : 'Sends at ';
        var startDate = this.model.inactiveSchedule ? this.model.inactiveSchedule : this.model.activeSchedule;
        startDateText = "".concat(startWord).concat(window.moment(startDate).format('h:mm a, MMMM Do YYYY'), ",<br> ").concat(this.model.timeZone);
      }
      return startDateText;
    }),
    scheduleEndDate: Ember.computed('model.{activeSchedule,activeEndSchedule,status}', function () {
      var endDateText = '';
      if (!this.model.isDraft && this.model.isTriggered && (this.model.activeEndSchedule || this.model.inactiveEndSchedule)) {
        var endWord = this.model.isTriggered ? this.model.inactiveEndSchedule ? 'Ended at ' : 'Ends at ' : '';
        var endDate = this.model.inactiveEndSchedule ? this.model.inactiveEndSchedule : this.model.activeEndSchedule;
        endDateText += "".concat(endWord).concat(window.moment(endDate).format('h:mm a, MMMM Do YYYY'), ",<br> ").concat(this.model.timeZone);
      }
      if (!this.model.isDraft && this.model.isTriggered && this.model.activeSchedule && !this.model.activeEndSchedule) {
        endDateText += 'Ends never';
      }
      return endDateText;
    }),
    notificationField: Ember.computed('model.customFields', function () {
      var field = Ember.Object.create();
      if (Ember.isEmpty(Object.keys(this.model.customFields))) {
        field.setProperties({
          type: 'Basic',
          isString: true,
          value: ''
        });
        return field;
      } else {
        if (this.model.customFields && this.model.customFields.url) {
          field.setProperties({
            type: 'Web Link',
            isString: true,
            value: this.model.customFields.url,
            isWebLink: true
          });
          return field;
        } else if (this.model.customFields && this.model.customFields.contentId) {
          var contentName = this.model.customFields.contentId.metadata.contentName;
          var contentInstance = null;
          if (!contentName && this.model.customFields.contentId.value) {
            contentInstance = this.store.findRecord('content-instance', this.model.customFields.contentId.value);
          }
          field.setProperties({
            type: 'Content',
            isString: true,
            value: contentName,
            contentInstance: contentInstance
          });
          return field;
        } else {
          for (var key in this.model.customFields) {
            if (this.model.customFields.hasOwnProperty(key)) {
              var item = this.model.customFields[key];
              if (item && _typeof(item) === 'object') {
                this.model.customFields[key] = JSON.stringify(item);
              }
            }
          }
          field.setProperties({
            type: 'Custom (User Defined)',
            isString: false,
            value: this.model.customFields
          });
          return field;
        }
      }
    }),
    isContextual: Ember.computed('model.computedRules.@each.isFulfilled', function () {
      if (this.model.computedRules.isEvery('isFulfilled', true)) {
        var rule = this.model.computedRules[0];
        return rule && rule.get('hasContextual');
      }
    }),
    isMessageLoading: Ember.computed('model.computedRules.@each.isFulfilled', function () {
      return this.model.computedRules.isAny('isFulfilled', false);
    }),
    targetAudience: Ember.computed('model.computedRules.@each.isFulfilled', function () {
      if (this.model.computedRules.isEvery('isFulfilled', true)) {
        var rule = this.model.computedRules[0];
        if (rule) {
          return rule.content.name;
        } else {
          return 'Everyone';
        }
      }
    }),
    sendDelay: Ember.computed('model.options.delay', function () {
      if (this.model.options.delay) {
        var period = this.convertSecondsToPeriodObject(this.get('model.options.delay'));
        var unit = period.value == 1 ? (0, _emberInflector.singularize)(period.unit) : (0, _emberInflector.pluralize)(period.unit);
        return "".concat(period.value, " ").concat(unit);
      }
    }),
    sendMethodText: Ember.computed('model.computedRules.@each.isFulfilled', function () {
      if (this.model.computedRules.isEvery('isFulfilled', true)) {
        var rule = this.model.computedRules[0];
        return rule && rule.get('hasContextual') ? this.intl.t('info.rule.visibility.contextual', {
          contextualPeriod: rule.get('contextualInfoText')
        }) : this.intl.t('info.rule.visibility.target');
      }
    }),
    remainAudienceText: Ember.computed('model.computedRules.@each.isFulfilled', function () {
      if (this.model.computedRules.isEvery('isFulfilled', true)) {
        var rule = this.model.computedRules[0];
        if (rule && rule.get('emitEveryEvaluationResult')) {
          return 'Include users who remain in the audience';
        }
      }
    }),
    messageLimitText: Ember.computed('model.options.limit', function () {
      if (this.model.isTriggered) {
        var limit = this.model.options.limit;
        if (limit == 0) {
          return 'Unlimited';
        } else {
          return this.intl.t('info.push.limit', {
            limit: limit
          });
        }
      }
    }),
    messageIntervalLimit: Ember.computed('model.computedRules.@each.isFulfilled', function () {
      if (this.model.computedRules.isEvery('isFulfilled', true)) {
        var rule = this.model.computedRules[0];
        if (rule) {
          if (Ember.isEmpty(rule.get('startScheduleRepeat.frequencyType')) || Ember.isEqual(rule.get('startScheduleRepeat.frequencyType'), 'once')) {
            return;
          } else {
            return rule.get('hasLimitedInterval') ? this.intl.t('info.rule.limit.limited', {
              limit: rule.get('startScheduleRepeat.limit') + 1
            }) : this.intl.t('info.rule.limit.unlimited');
          }
        }
      }
    }),
    messageIntervalFrequency: Ember.computed('model.computedRules.@each.isFulfilled', function () {
      if (this.model.computedRules.isEvery('isFulfilled', true)) {
        var rule = this.model.computedRules[0];
        if (rule) {
          // frequencyType of 'once' should not be visible to the user as this is not user-defined.
          if (Ember.isEmpty(rule.get('startScheduleRepeat.frequencyType')) || Ember.isEqual(rule.get('startScheduleRepeat.frequencyType'), 'once')) {
            return;
          } else {
            var frequency = rule.get('startScheduleRepeat.frequency');
            var frequencyType = frequency == 1 ? (0, _emberInflector.singularize)(rule.get('startScheduleRepeat.frequencyType')) : (0, _emberInflector.pluralize)(rule.get('startScheduleRepeat.frequencyType'));
            return this.intl.t('info.rule.interval', {
              frequency: frequency,
              frequencyType: frequencyType
            });
          }
        }
      }
    }),
    throttleMessage: Ember.computed('model.options.sampling', function () {
      if (this.model.options.sampling > 0) {
        if (this.model.options.sampling === 1) {
          return Ember.String.htmlSafe("Every user");
        }
        var ordinal = (0, _numberOrdinal.numberOrdinal)([this.model.options.sampling]);
        var throttleOrdinal = "".concat(this.model.options.sampling).concat(ordinal);
        return Ember.String.htmlSafe("Every <strong>".concat(throttleOrdinal, "</strong> users"));
      }
    }),
    willRender: function willRender() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
    }
  });
  _exports.default = _default;
});