define("experience-studio/routes/content-hub/content-template/create", ["exports", "experience-studio/mixins/field-validator-factory"], function (_exports, _fieldValidatorFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  //
  var _default = Ember.Route.extend(_fieldValidatorFactory.default, {
    queryParams: {
      data: {}
    },
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    intl: Ember.inject.service(),
    contextRuleUtility: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model(params) {
      var contentInstance;
      if (params.data) {
        contentInstance = this.extractModel(params);
      } else {
        var contentTemplate = this.modelFor('content-hub.content-template');
        var isContextLocked = contentTemplate.isContextLocked === undefined ? true : contentTemplate.isContextLocked;
        var contInstData = contentTemplate.contentInstanceModalData;
        if (contInstData && contInstData.isConciergeTemplate) {
          contentInstance = contInstData;
        } else {
          contentInstance = this.store.createRecord('contentInstance', {
            contentTemplateId: contentTemplate.id,
            description: contentTemplate.description,
            iconUrl: contentTemplate.iconUrl,
            isContextLocked: isContextLocked,
            localizations: contentTemplate.localizations,
            labels: contentTemplate.labels,
            name: contentTemplate.name,
            templateType: contentTemplate.type,
            selectedLabels: contentTemplate.selectedLabels
          });
        }
      }
      if (contentInstance.originalContentInstanceId) {
        return Ember.RSVP.hash({
          content: this.store.queryRecord('content-instance', {
            id: contentInstance.originalContentInstanceId,
            data: true,
            bypassAdmin: true
          }),
          contentInstance: contentInstance,
          plugins: this.store.findAll('plugin'),
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          projectCustomTemplates: this.store.queryRecord('project-config', {
            setting: 'project-custom-content-templates'
          })
        });
      } else {
        return Ember.RSVP.hash({
          contentInstance: contentInstance,
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          plugins: this.store.findAll('plugin'),
          projectCustomTemplates: this.store.queryRecord('project-config', {
            setting: 'project-custom-content-templates'
          })
        });
      }
    },
    afterModel: function afterModel(model) {
      if (model.content) {
        model.contentInstance.setProperties({
          content: model.content.get('content'),
          values: model.content.get('values')
        });
      }
      var isConciergeTemplate = false;
      if (model.projectCustomTemplates && model.projectCustomTemplates.config && model.projectCustomTemplates.config.categories) {
        var categories = model.projectCustomTemplates.config.categories;
        isConciergeTemplate = categories.length > 0 && this.contextRuleUtility.isConciergeTemplate(model.contentInstance.templateType);
      }
      model.contentInstance.set('isConciergeTemplate', isConciergeTemplate);
    },
    extractModel: function extractModel(params) {
      var data = this.utility.atob(params.data);
      data.name += ' - Copy';
      return this.store.createRecord('contentInstance', data);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: controller.model.get('name'),
        description: controller.model.get('description'),
        image: controller.model.get('iconUrl'),
        locked: controller.model.get('isContextLocked') || false,
        data: controller.model.serialize().data,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var applicationController = this.controllerFor('application');
      var queryParams = null;
      var routeName = 'content-hub.index';
      if (applicationController) {
        if (this.userPreferences.getValue('routeListView')) {
          routeName = 'content-hub-v2.index';
          queryParams = {
            data: this.utility.btoa({
              timestamp: Date.now()
            })
          };
        }
      }
      if (queryParams) {
        this.transitionTo(routeName, {
          queryParams: queryParams
        });
      } else {
        this.transitionTo(routeName, {
          queryParams: {
            data: ''
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      controller.setProperties({
        languages: model.languages.config,
        plugins: model.plugins,
        selectedLabels: [],
        transitionConfirmed: false,
        displayFooter: true
      });
      model = model.contentInstance;
      Ember.run.scheduleOnce('afterRender', this, function () {
        controller.set('initialHash', _this.utility.hash({
          name: model.get('name'),
          description: model.get('description'),
          image: model.get('iconUrl'),
          locked: model.get('isContextLocked') || false,
          data: model.serialize().data,
          selectedLabels: controller.selectedLabels.mapBy('value').sort()
        }));
      });
      this._super(controller, model);
    },
    setActionValue: function setActionValue(actionValueObj, langConfig) {
      var _actionValueObj$linkV;
      if (actionValueObj !== null && actionValueObj !== void 0 && (_actionValueObj$linkV = actionValueObj.linkValue) !== null && _actionValueObj$linkV !== void 0 && _actionValueObj$linkV.includes(':UNIVERSAL_ACTION') && !(actionValueObj !== null && actionValueObj !== void 0 && actionValueObj.isSkipUrlValidation)) {
        var validationPrefix = this.retrieveActionPrefix(actionValueObj === null || actionValueObj === void 0 ? void 0 : actionValueObj.linkValue);
        var actionValue = {};
        for (var langCount = 0; langCount < (langConfig === null || langConfig === void 0 ? void 0 : langConfig.length); langCount++) {
          var _actionValueObj$value;
          var currentLang = langConfig[langCount];
          if (actionValueObj !== null && actionValueObj !== void 0 && (_actionValueObj$value = actionValueObj.value) !== null && _actionValueObj$value !== void 0 && _actionValueObj$value[currentLang.id]) {
            if ((actionValueObj === null || actionValueObj === void 0 ? void 0 : actionValueObj.linkValue) === 'browser:UNIVERSAL_ACTION' || (actionValueObj === null || actionValueObj === void 0 ? void 0 : actionValueObj.linkValue) === 'web:UNIVERSAL_ACTION') {
              var _actionValueObj$value2;
              actionValue[currentLang.id] = validationPrefix + encodeURIComponent(actionValueObj === null || actionValueObj === void 0 ? void 0 : (_actionValueObj$value2 = actionValueObj.value) === null || _actionValueObj$value2 === void 0 ? void 0 : _actionValueObj$value2[currentLang.id]);
            } else {
              var _actionValueObj$value3;
              actionValue[currentLang.id] = validationPrefix + (actionValueObj === null || actionValueObj === void 0 ? void 0 : (_actionValueObj$value3 = actionValueObj.value) === null || _actionValueObj$value3 === void 0 ? void 0 : _actionValueObj$value3[currentLang.id]);
            }
          }
        }
        Ember.set(actionValueObj, 'value', actionValue);
      }
    },
    retrieveActionPrefix: function retrieveActionPrefix(linkValue) {
      switch (linkValue) {
        case 'mailto:UNIVERSAL_ACTION':
          return 'mail://?sendTo=';
        case 'tel:UNIVERSAL_ACTION':
          return 'phone://?phoneNumber=';
        case 'concierge:UNIVERSAL_ACTION':
          return 'concierge://?';
        case 'universalLink:UNIVERSAL_ACTION':
          return 'universalLink://?';
        case 'web:UNIVERSAL_ACTION':
          return 'web://?url=';
        case 'details:UNIVERSAL_ACTION':
          return 'details://?contentId=';
        case 'browser:UNIVERSAL_ACTION':
          return 'browser://?url=';
        case 'app:UNIVERSAL_ACTION':
          return 'app://?';
        default:
          return '';
      }
    },
    actions: {
      displayFooter: function displayFooter() {
        var isFooterDisplayed = this.controller.get('displayFooter');
        this.controller.set('displayFooter', isFooterDisplayed ? false : true);
      },
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        this.goBack();
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        if (this.hasChanges()) {
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this.send('cancel');
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      // we'll have to update the  name of the instance here
      // keep the selected rule in controller, and use it when saving
      onSelectRule: function onSelectRule(rule) {
        this.controller.set('selectedRule', rule);
      },
      save: function save() {
        var _this2 = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var selectedLabels = this.controller.get('selectedLabels');
        if (this.currentModel.contentInstance.isConciergeTemplate) {
          selectedLabels = this.currentModel.contentInstance.selectedLabels;
        }
        var model = controller.model;
        if (this.controller.data) {
          model = this.store.createRecord('contentInstance', model.toJSON());
        }

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this2.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          // Assign labels in general to model
          model.set('labels', selectedLabels.mapBy('value'));
          return model.save();
        }).then(function () {
          _this2.controller.set('transitionConfirmed', true);
          _this2.send('refresh');
          _this2.goBack();
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            _this2.send('showNotification', {
              type: 'confirm',
              message: "Successfully created \"".concat(model.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', _this2.utility.parseError(response));
        });
      },
      validate: function validate() {
        var _this$currentModel, _this$currentModel$co;
        var controller = this.controller;
        var model = controller.get('model.root');
        if ((_this$currentModel = this.currentModel) !== null && _this$currentModel !== void 0 && (_this$currentModel$co = _this$currentModel.contentInstance) !== null && _this$currentModel$co !== void 0 && _this$currentModel$co.isConciergeTemplate) {
          this.send('populateConceirgeTemplateImage', model);
        }
        var languages = controller.get('languages');
        var validator = _fieldValidatorFactory.default.getValidator(model, languages);
        var validation = validator.validate({
          triggerCascade: true
        });
        if (!Ember.isEmpty(controller.get('model.iconUrl')) && !controller.get('model.iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(controller.get('model.name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else if (!validation.state) {
          var plural = validation.errors.length > 1 ? 's' : '';
          controller.set('errorMessage', "You have (".concat(validation.errors.length, ") unresolved error").concat(plural, "."));
        } else {
          controller.set('errorMessage', '');
          this.send('populateConceirgeTemplateActionTypeValue', model, languages);
          this.send('save');
        }
      },
      populateConceirgeTemplateActionTypeValue: function populateConceirgeTemplateActionTypeValue(model, langConfig) {
        var _model$schema, _model$schema$find, _model$schema2;
        var buttonsField = model === null || model === void 0 ? void 0 : (_model$schema = model.schema) === null || _model$schema === void 0 ? void 0 : (_model$schema$find = _model$schema.find(function (field) {
          return field.key === 'buttons';
        })) === null || _model$schema$find === void 0 ? void 0 : _model$schema$find.value;
        var linkField = model === null || model === void 0 ? void 0 : (_model$schema2 = model.schema) === null || _model$schema2 === void 0 ? void 0 : _model$schema2.find(function (field) {
          return field.key === 'link';
        });
        if (buttonsField) {
          for (var count = 0; count < buttonsField.length; count++) {
            var _buttonsField$count, _buttonsField$count$s;
            var actionValueObj = (_buttonsField$count = buttonsField[count]) === null || _buttonsField$count === void 0 ? void 0 : (_buttonsField$count$s = _buttonsField$count.schema) === null || _buttonsField$count$s === void 0 ? void 0 : _buttonsField$count$s[2];
            this.setActionValue(actionValueObj, langConfig);
          }
        } else if (linkField) {
          var _linkField$schema;
          var _actionValueObj = linkField === null || linkField === void 0 ? void 0 : (_linkField$schema = linkField.schema) === null || _linkField$schema === void 0 ? void 0 : _linkField$schema[2];
          this.setActionValue(_actionValueObj, langConfig);
        }
      },
      populateConceirgeTemplateImage: function populateConceirgeTemplateImage(model) {
        var _model$schema3, _model$schema4, _mediaField$schema, _mediaField$schema$fi;
        var mediaTypeImageMap = {
          image: 'resourceURL',
          video: 'previewImgURL'
        };
        var imageField = model === null || model === void 0 ? void 0 : (_model$schema3 = model.schema) === null || _model$schema3 === void 0 ? void 0 : _model$schema3.find(function (field) {
          return field.type === 'ctlocalizedimage';
        });
        var mediaField = model === null || model === void 0 ? void 0 : (_model$schema4 = model.schema) === null || _model$schema4 === void 0 ? void 0 : _model$schema4.find(function (field) {
          return field.type === 'ctlocalizedmedia';
        });
        var mediaType = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema = mediaField.schema) === null || _mediaField$schema === void 0 ? void 0 : (_mediaField$schema$fi = _mediaField$schema.find(function (field) {
          return field.key === 'mediaType';
        })) === null || _mediaField$schema$fi === void 0 ? void 0 : _mediaField$schema$fi.value;
        if (imageField && mediaType) {
          var _mediaField$schema2, _mediaField$schema2$f;
          var imageURL = {};
          var mediaURL = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema2 = mediaField.schema) === null || _mediaField$schema2 === void 0 ? void 0 : (_mediaField$schema2$f = _mediaField$schema2.find(function (field) {
            return field.key === mediaTypeImageMap[mediaType];
          })) === null || _mediaField$schema2$f === void 0 ? void 0 : _mediaField$schema2$f.value;
          for (var lang in mediaURL) {
            imageURL[lang] = _defineProperty({}, imageField.key, mediaURL[lang][mediaTypeImageMap[mediaType]]);
          }
          Ember.set(imageField, 'value', imageURL);
        } else if (imageField && mediaField) {
          Ember.set(imageField, 'value', null);
        }
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          window.history.go(1);
          this.send('confirmCancel');
        } else {
          controller.setProperties({
            data: '',
            errorMessage: '',
            transitionConfirmed: false,
            previousTransition: null
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});