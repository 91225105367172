define("experience-studio/controllers/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    userPreferences: Ember.inject.service(),
    mainHeaderTabs: null,
    init: function init() {
      this._super.apply(this, arguments);
      var routeNamePrefix = 'analytics.';
      var tabs = [];
      tabs.pushObject(Ember.Object.create({
        isActive: false,
        name: 'Users',
        routeName: "".concat(routeNamePrefix, "users")
      }));
      tabs.pushObject(Ember.Object.create({
        isActive: false,
        name: 'Engagement',
        routeName: "".concat(routeNamePrefix, "engagement")
      }));
      this.set('mainHeaderTabs', tabs);
    }
  });
  _exports.default = _default;
});