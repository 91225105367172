define("experience-studio/components/route-model-filter-dropdown-radio-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown-radio-select', 'form-group'],
    determineSelection: function determineSelection() {
      var _this = this;
      if (this.queryParameters && this.section) {
        this.section.options.forEach(function (option) {
          option.set('isSelected', _this.queryParameters[option.property] === option.value);
        });
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.determineSelection();
    },
    actions: {
      makeSelection: function makeSelection(option) {
        if (this.didUpdateQueryParams) {
          var queryObject = {};
          queryObject[option.property] = option.value;
          this.didUpdateQueryParams(queryObject);
        }
      }
    }
  });
  _exports.default = _default;
});