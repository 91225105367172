define("experience-studio/routes/analytics-v2/content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').findRecord('content-instance', params.model_id);
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        endTime: null,
        startTime: null,
        temporalRange: null,
        temporalTick: null
      });
    }
  });
  _exports.default = _default;
});