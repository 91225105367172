define("experience-studio/components/filter-pill-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['filter-pill'],
    tagName: '',
    value: Ember.computed('model.filters.[]', function () {
      return this.model.filters.objectAt(0).value;
    }),
    actions: {
      clearPill: function clearPill(pill) {
        if (this.didUpdateQueryParams && this.model.filters.objectAt(0)) {
          var labels = this.model.filters.objectAt(0).value;
          labels.removeObject(pill);
          this.didUpdateQueryParams({
            'labels': labels.length ? labels : null,
            'currentPage': 1,
            'recordsPerPage': 10
          });
        }
      }
    }
  });
  _exports.default = _default;
});