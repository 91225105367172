define("experience-studio/models/experience", ["exports", "ember-data", "experience-studio/mixins/recently-modified-item", "experience-studio/mixins/time-conversion"], function (_exports, _emberData, _recentlyModifiedItem, _timeConversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend(_recentlyModifiedItem.default, _timeConversion.default, {
    intl: Ember.inject.service(),
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    isActive: _emberData.default.attr('boolean'),
    isPartiallyLoaded: _emberData.default.attr('boolean'),
    localizations: _emberData.default.attr(),
    priorityIndex: _emberData.default.attr('number'),
    contentIds: _emberData.default.attr(),
    groupIds: _emberData.default.attr(),
    ruleId: _emberData.default.attr('string'),
    contents: _emberData.default.attr(),
    labels: _emberData.default.attr(),
    rule: _emberData.default.attr(),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    visibility: _emberData.default.attr('string'),
    // Computed Properties

    isHighLightItem: Ember.computed('recentlyModified', function () {
      return this.recentlyModified;
    }),
    computedContents: Ember.computed('contents.[]', function () {
      var contents = this.contents;
      var records = [];
      var store = this.store;
      var serializer = store.serializerFor('content-instance');
      if (!Ember.isEmpty(contents)) {
        contents.forEach(function (item) {
          var record = store.peekRecord('content-instance', item.id);
          if (!record) {
            item.isPartiallyLoaded = true;
            record = store.push({
              data: serializer.transformPayloadForEmber(item, 'content-instance')
            });
          }
          records.pushObject(record);
        });
      }
      return records;
    }),
    computedGroups: Ember.computed('groupIds.[]', function () {
      var store = this.store;
      var groupIds = this.groupIds;
      var groups = [];
      if (!Ember.isEmpty(groupIds)) {
        groupIds.forEach(function (id) {
          var record = store.peekRecord('group', id);
          if (!record) {
            record = store.findRecord('group', id);
          }
          groups.pushObject(record);
        });
      }
      return groups;
    }),
    computedRule: Ember.computed('ruleId', function () {
      var _this = this;
      var id = this.ruleId;
      if (!Ember.isEmpty(id)) {
        var record = this.store.peekRecord('rule', id);
        if (record) {
          return record;
        } else {
          this.store.findRecord('rule', id).then(function (response) {
            var experienceIds = response.get('experienceIds');
            var id = _this.id;
            if (!experienceIds.includes(id)) {
              experienceIds.pushObject(id);
            }
            return response;
          });
        }
      } else return Ember.Object.create({});
    }),
    computedRuleObjects: Ember.computed('ruleId', function () {
      var store = this.store;
      var rule = this.rule;
      var ruleId = this.ruleId;
      var serializer = store.serializerFor('rule');
      var records = [];
      if (ruleId) {
        var record = store.peekRecord('rule', rule.id);
        if (!record) {
          rule.isPartiallyLoaded = true;
          record = store.push({
            data: serializer.transformPayloadForEmber(rule, 'rule')
          });
        }
        records.pushObject(record);
      }
      return records;
    }),
    contentVisibilityMessage: Ember.computed('ruleId', 'computedRule.schedule.startScheduler.contextualStart', function () {
      if (this.computedRule && !this.computedRule.get('schedule.startScheduler.contextualStart')) return this.intl.t('info.rule.visibility.target');
      var rule = this.computedRuleObjects[0];
      return rule.get('hasContextual') ? this.intl.t('info.rule.visibility.contextual', {
        contextualPeriod: rule.get('contextualInfoText')
      }) : this.intl.t('info.rule.visibility.target');
    })
  });
  _exports.default = _default;
});