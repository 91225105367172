define("experience-studio/routes/settings", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/route-model-table-actions-base"], function (_exports, _authenticatedRoute, _routeModelTableActionsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeModelTableActionsBase.default, {
    userPreferences: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'settings.index') {
        this.transitionTo('settings.general');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('selectedEntities', []);
    }
  });
  _exports.default = _default;
});