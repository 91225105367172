define("experience-studio/templates/components/labeled-locations-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cdf6cVf1",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"inner-box\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"inner-box-item top\"],[12],[2,\"\\n        \"],[10,\"p\"],[14,0,\"name\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"address\"],[12],[2,\"Address: \"],[1,[35,0,[\"address\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"labels\"],[12],[2,\"\\n          \"],[10,\"ul\"],[14,0,\"labels-list\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,0,[\"labels\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"li\"],[14,0,\"labels-list-item\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"],[10,\"span\"],[12],[2,\"+ 2 more\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"inner-box-item inner-box-item--right\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"\"],[4,[38,4],[[32,0],\"viewInfo\"],null],[12],[2,\"\\n          \"],[1,[30,[36,5],[\"chevron\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"info-container\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"location-view-info\",\"-track-array\",\"each\",\"action\",\"svg-jar\",\"isViewInfo\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/labeled-locations-list-item.hbs"
  });
  _exports.default = _default;
});