define("experience-studio/components/custom-field--dropdown-action", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var findOpt = function findOpt(opts, optVal) {
    if (optVal) {
      var optValue = typeof optVal === 'string' ? optVal : optVal.val;
      for (var i = 0; i < opts.length; i++) {
        if (optValue.includes('UNIVERSAL_ACTION') && (opts[i].key === 'action_types_4' || opts[i].key === 'action_types_5')) {
          var actionTypeOptions = opts[i].options;
          for (var count = 0; count < actionTypeOptions.length; count++) {
            if (actionTypeOptions[count].val === optValue) {
              return actionTypeOptions[count];
            }
          }
        } else if (opts[i].val === optValue) {
          return opts[i];
        }
      }
    }
    return;
  };
  var _default = _customField.default.extend({
    defaultInit: false,
    selectedOption: null,
    defaultUpdated: Ember.observer('field.valueOptions.[]', 'field.defaultOption', function () {
      this.initDefault();
    }),
    proxyVal: Ember.computed('field.value', {
      get: function get() {
        return findOpt(this.get('field.valueOptions'), this.get('field.value'));
      },
      set: function set(key, value) {
        this.set('field.value', value.val);
        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },

    initDefault: function initDefault() {
      if (this.defaultInit) {
        return;
      }
      this.set('defaultInit', true);
      if (!this.proxyVal && this.get('field.defaultOption')) {
        this.set('proxyVal', this.get('field.defaultOption'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.field.defaultOption && !this.field.value) {
        this.set('selectedOption', this.field.defaultOption);
        Ember.set(this.field, 'value', this.field.defaultOption.val);
      } else if (this.field.value) {
        this.set('selectedOption', this.field.value);
      }
    },
    triggerValidation: function triggerValidation() {
      if (Ember.isEmpty(this.field.value) && this.field.isRequired) {
        this.set('isDirty', true);
        this.send('onValue', '', this.field.key, false);
      } else {
        this.setValidity(this.field.value.val);
        this.send('onValue', this.field.value, this.field.key, false);
      }
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var field = this.field;
      field.set('errorMessages', []);
      this._super(value, isValid);
    },
    actions: {
      selectValue: function selectValue(isChange, key, value) {
        this.setValidity(value.val);
        this.sendAction('onValue', value, key, isChange); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});