define("experience-studio/mixins/route-model-batch-actions-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modalData = Ember.Object.extend({
    singularEntityName: 'Location',
    pluralEntityName: 'Locations'
  });
  var _default = Ember.Mixin.create({
    actions: {
      showAddLabelRecordsModal: function showAddLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          isAdding: true
        }));
      },
      showRemoveLabelRecordsModal: function showRemoveLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          isAdding: false
        }));
      },
      showDeleteRecordsModal: function showDeleteRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-delete-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.contextHubController.selectedEntities,
          message: "This action will delete the selected location(s). Locations that are used in existing Rules cannot be deleted. Do you wish to continue?" // eslint-disable-line
        }));
      }
    }
  });
  _exports.default = _default;
});