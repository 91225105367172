define("experience-studio/templates/components/filter-pill-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FFRLP8RZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[11,\"div\"],[24,0,\"remove-pill\"],[4,[38,1],[[32,0],\"clearPill\"],null],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"times-circle\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"action\",\"svg-jar\"]}",
    "moduleName": "experience-studio/templates/components/filter-pill-active.hbs"
  });
  _exports.default = _default;
});