define("experience-studio/serializers/rule-simulation", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      var attributes = item.attributes;
      attributes.status = (attributes.status || '').toLowerCase();
      attributes.errorCount = attributes.errors;
      attributes.falseCount = attributes.falses;
      attributes.trueCount = attributes.trues;
      attributes.completedAt = attributes.completedTimestamp;
      attributes.createdAt = attributes.createdTimestamp;
      attributes.deletedAt = attributes.errors;
      attributes.modifiedAt = attributes.lastUpdated;
      return item;
    }
  });
  _exports.default = _default;
});