define("experience-studio/components/push-messages-create--subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-messages-create--subscriptions'],
    model: null,
    didInsertElement: function didInsertElement() {
      var model = this.model;
      var subscriptions = model.get('subscriptions');
      var keywordArray = [];
      if (subscriptions.length) {
        subscriptions.forEach(function (item) {
          if (!Ember.isEmpty(item)) {
            keywordArray.pushObject(Ember.Object.create({
              errorMessage: '',
              isDone: true,
              keyword: item
            }));
          }
        });
        model.set('subscriptions', keywordArray);
      }
    },
    actions: {
      addSubscription: function addSubscription() {
        var _this = this;
        var model = this.model;
        var subscriptions = model.get('subscriptions');
        if (Ember.isEmpty(subscriptions)) {
          subscriptions = [];
          model.set('subscriptions', subscriptions);
        }
        subscriptions.forEach(function (item) {
          var value = (item.get('keyword') || '').trim();
          if (Ember.isEmpty(value)) {
            subscriptions.removeObject(item);
          } else {
            _this.send('doneSubscription', item);
          }
        });
        model.get('subscriptions').pushObject(Ember.Object.create({
          errorMessage: '',
          isDone: false,
          keyword: ''
        }));
      },
      doneSubscription: function doneSubscription(item) {
        var value = (item.get('keyword') || '').trim();
        item.set('keyword', value);
        value = value.toLowerCase();
        var duplicateItems = this.get('model.subscriptions').filter(function (item) {
          return item.get('keyword').toLowerCase() === value;
        });
        if (Ember.isEmpty(value)) {
          item.set('errorMessage', 'Enter subscription');
        } else if (duplicateItems.get('length') > 1) {
          item.set('errorMessage', 'Duplication subscription');
        } else {
          item.setProperties({
            errorMessage: '',
            isDone: true
          });
        }
      },
      editSubscription: function editSubscription(item) {
        item.set('isDone', false);
      },
      removeSubscription: function removeSubscription(item) {
        this.model.get('subscriptions').removeObject(item);
      }
    }
  });
  _exports.default = _default;
});