define("experience-studio/components/content-tmp-edit-text", ["exports", "experience-studio/config/environment", "experience-studio/helpers/blacklist-sites"], function (_exports, _environment, _blacklistSites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-text
      field=field
    }}
  
  */
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    classNames: ['content-tmp-edit-text'],
    isTextArea: Ember.computed('field.inputUI', function () {
      return this.get('field.inputUI') && this.get('field.inputUI') === 'textarea';
    }),
    isRichTextEnabled: Ember.computed('userPreferences.preferences.[]', 'field', function () {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      var isRTFEnabled = isNonTdOrUsEnv || this.userPreferences.getValue('richTextFormatting');
      return isRTFEnabled && this.field && this.field.isRichTextEnabled && (0, _blacklistSites.blacklistSites)();
    }),
    isDynamicAttributeFeatureEnabled: Ember.computed('userPreferences.preferences.[]', 'field', function () {
      return this.field && this.field.isDynamicAttributeEnabled;
    }),
    isTextAreaWithFeature: Ember.computed('isRichTextEnabled', 'isDynamicAttributeFeatureEnabled', function () {
      return this.isRichTextEnabled || this.isDynamicAttributeFeatureEnabled;
    }),
    didInsertElement: function didInsertElement() {
      if (!this.get('field.value')) {
        this.set('field.value', '');
      }
    },
    actions: {
      trimValue: function trimValue() {
        this.set('field.value', this.field.value.trim());
      },
      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.updateDynamicAttribute(value);
      },
      focusIn: function focusIn() {
        this.sendAction('didFocusIn'); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});