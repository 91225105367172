define("experience-studio/adapters/permission-entity", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'sso',
    urlForFindAll: function urlForFindAll() {
      return this._super.apply(this, arguments).replace('permission-entities', 'permissions/entityTypeVirtualIds');
    }
  });
  _exports.default = _default;
});