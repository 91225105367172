define("experience-studio/routes/content-hub/blueprint-instance/edit", ["exports", "experience-studio/mixins/field-validator-factory"], function (_exports, _fieldValidatorFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_fieldValidatorFactory.default, {
    utility: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    contextRuleUtility: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      var contentInstance = this.modelFor('content-hub.blueprint-instance');
      this.store.findRecord('rule', contentInstance.ruleId).then(function () {}).catch(function (e) {
        var ruleNotFound = e.errors.find(function (error) {
          return error.status === '404';
        });
        if (ruleNotFound) {
          confirm('The rule associated with this location content does not exist');
          _this.transitionTo('content-hub.location');
        }
      });
    },
    model: function model() {
      var contentInstance = this.modelFor('content-hub.blueprint-instance');
      return Ember.RSVP.hash({
        contentInstance: contentInstance,
        languages: this.store.queryRecord('project-config', {
          setting: 'language'
        }),
        rule: this.store.findRecord('rule', contentInstance.ruleId),
        plugins: this.store.findAll('plugin'),
        projectCustomTemplates: this.store.queryRecord('project-config', {
          setting: 'project-custom-content-templates'
        })
      });
    },
    afterModel: function afterModel(model) {
      model.contentInstance.set('isConciergeTemplate', false);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var model = this.controller.get('model');
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: model.get('name'),
        description: model.get('description'),
        image: model.get('iconUrl'),
        locked: model.get('isContextLocked') || false,
        data: model.get('root').toData().root,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var previousRouteName = this._router.getPreviousRouteName();
      if (previousRouteName) {
        this.transitionTo(previousRouteName);
      } else if (this.controller.get('previousTransition')) {
        this.controller.get('previousTransition').retry();
      } else {
        window.history.back();
      }
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        finishedLoading: false,
        languages: model.languages.config,
        plugins: model.plugins,
        selectedLabels: [],
        transitionConfirmed: false,
        displayFooter: true,
        selectedRule: model.rule
      });
      controller.set('initialHash', this.utility.hash({
        name: model.contentInstance.get('name'),
        description: model.contentInstance.get('description'),
        image: model.contentInstance.get('iconUrl'),
        locked: model.contentInstance.get('isContextLocked') || false,
        data: model.contentInstance.get('content').data[0],
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      }));
      if (!model.contentTemplate) {
        this.store.findRecord('content-template', model.contentInstance.contentTemplateId).then(function (respo) {
          Ember.set(model, 'contentTemplate', respo);
          controller.set('finishedLoading', true);
        });
      } else {
        controller.set('finishedLoading', true);
      }
      this._super(controller, model);
    },
    actions: {
      displayFooter: function displayFooter() {
        var isFooterDisplayed = this.controller.get('displayFooter');
        this.controller.set('displayFooter', isFooterDisplayed ? false : true);
      },
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        this.controller.model.contentInstance.rollbackAttributes();
        this.goBack();
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        this.send('cancel');
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      // we'll have to update the  name of the instance here
      // keep the selected rule in controller, and use it when saving
      onSelectRule: function onSelectRule(rule) {
        this.controller.set('selectedRule', rule);
      },
      save: function save() {
        var _this2 = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var model = controller.model;
        var selectedLabels = this.controller.get('selectedLabels');
        if (this.currentModel.contentInstance.isConciergeTemplate) {
          selectedLabels = this.currentModel.contentInstance.selectedLabels;
        }

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this2.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });

        // Rule must be attached to the blueprint content
        // User can decide to deactivate or delete if they wish to discontinue the content
        var selectedRule = controller.get('selectedRule');
        if (!selectedRule) {
          controller.set('errorMessage', 'Select a rule.');
          return;
        }

        // Rule must be set as it will be used in the serilization
        // step to construct nearby location object
        model.contentInstance.set('rule', selectedRule);
        labelAdapter.saveLabels(newLabels).then(function () {
          // Assign labels in general to model
          model.contentInstance.set('labels', selectedLabels.mapBy('value'));
          return model.contentInstance.save();
        }).then(function () {
          _this2.controller.set('transitionConfirmed', true);
          _this2.goBack();
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            _this2.send('showNotification', {
              type: 'confirm',
              message: "Changes made to \"".concat(model.contentInstance.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', _this2.utility.parseError(response));
        });
      },
      validate: function validate() {
        var controller = this.controller;
        var model = controller.get('model');
        var validator = _fieldValidatorFactory.default.getValidator(model.contentInstance.root, model.languages.config);
        var validation = validator.validate({
          triggerCascade: true
        });
        if (!Ember.isEmpty(controller.get('model.iconUrl')) && !controller.get('model.iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(controller.get('model.contentInstance.name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else if (!validation.state) {
          var plural = validation.errors.length > 1 ? 's' : '';
          controller.set('errorMessage', "You have (".concat(validation.errors.length, ") unresolved error").concat(plural, "."));
        } else {
          controller.set('errorMessage', '');
          this.send('save');
        }
      },
      willTransition: function willTransition() {
        var controller = this.controller;
        var model = controller.model;
        if (!model.contentInstance.get('isSaving') && !model.contentInstance.get('isDeleted')) {
          model.contentInstance.reload();
        }
        controller.setProperties({
          errorMessage: '',
          previousTransition: null
        });
        return true;
      }
    }
  });
  _exports.default = _default;
});