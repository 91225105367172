define("experience-studio/templates/components/content-tmp-edit-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kQWArzZw",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"email\",\"field input-field data-field\",[35,1],[35,0,[\"placeholder\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"field\",\"valueProxy\",\"input\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-email.hbs"
  });
  _exports.default = _default;
});