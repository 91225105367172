define("experience-studio/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Nas9kBS8",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],[\"Experience Studio 1.0\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ember-view\"],[12],[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[35,10,[\"isAuthenticated\"]],[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"isMainSidebarMinimized\",\"showNotification\"],[[35,5],[30,[36,0],[\"showNotification\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"main-outlet-container\\n    \",[30,[36,12],[[35,5],\"minimized is-main-sidebar-minimized\"],null],\"\\n    \",[30,[36,12],[[35,10,[\"isAuthenticated\"]],\"is-signed-in\"],null],\"\\n    \",[30,[36,12],[[35,8],\"is-main-sidebar-hidden\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,13],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,12],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"confirmSwitchView\"],[\"confirmSwitchView\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\"],[6,[37,12],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],[[35,2]],[[\"model\",\"primaryActionName\",\"performAction\",\"showNotification\"],[[35,1],[35,1,[\"primaryActionName\"]],[30,[36,0],[\"performAction\"],null],\"showNotification\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"modalData\",\"modalName\",\"component\",\"list-view-confirmation-modal-dialog\",\"isMainSidebarMinimized\",\"flight-sidebar\",\"page-title\",\"isMainSidebarHidden\",\"not\",\"session\",\"and\",\"if\",\"-outlet\",\"isShowListViewConfirmationModal\"]}",
    "moduleName": "experience-studio/templates/application.hbs"
  });
  _exports.default = _default;
});