define("experience-studio/components/filter-pill-created-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['filter-pill'],
    value: Ember.computed('model.filters.[]', function () {
      var createdAtFromFilter = this.model.filters.findBy('id', 'createdAtFrom');
      var createdAtToFilter = this.model.filters.findBy('id', 'createdAtTo');
      if (!Ember.isNone(createdAtFromFilter) && !Ember.isNone(createdAtToFilter)) {
        var createdAtFromValue = window.moment.unix(createdAtFromFilter.value).format('MMM D/Y, h:mma');
        var createdAtToValue = window.moment.unix(createdAtToFilter.value).format('MMM D/Y, h:mma');
        return "Created between ".concat(createdAtFromValue, " - ").concat(createdAtToValue);
      } else if (!Ember.isNone(createdAtFromFilter)) {
        var _createdAtFromValue = window.moment.unix(createdAtFromFilter.value).format('MMM D/Y, h:mma');
        return "Created after ".concat(_createdAtFromValue);
      } else if (!Ember.isNone(createdAtToFilter)) {
        var _createdAtToValue = window.moment.unix(createdAtToFilter.value).format('MMM D/Y, h:mma');
        return "Created before ".concat(_createdAtToValue);
      }
    }),
    actions: {
      clearPill: function clearPill() {
        if (this.didUpdateQueryParams && this.model.filters.length) {
          var filters = this.model.filters;
          var parametersToModify = {};
          filters.forEach(function (filter) {
            parametersToModify[filter.id] = null;
          });
          this.didUpdateQueryParams(parametersToModify);
        }
      }
    }
  });
  _exports.default = _default;
});