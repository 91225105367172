define("experience-studio/templates/components/title-description-select-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HkY/Pnjf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"select-option \",[30,[36,2],[[35,1],\"is-disabled\"],null]]]],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"select-option-title\"],[12],[2,\" \"],[1,[34,3]],[2,\" \"],[13],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"select-option-description\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"       Disabled\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\" options\\n\"]],\"parameters\":[]}]]],[2,\"   \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"numOptions\",\"disabled\",\"if\",\"placeholder\"]}",
    "moduleName": "experience-studio/templates/components/title-description-select-placeholder.hbs"
  });
  _exports.default = _default;
});