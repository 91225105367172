define("experience-studio/mixins/route-model-batch-actions-experiences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modalData = Ember.Object.extend({
    singularEntityName: 'Experience',
    pluralEntityName: 'Experiences'
  });
  var _default = Ember.Mixin.create({
    actions: {
      showAddGroupRecordsModal: function showAddGroupRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-group-records-modal-dialog',
          groups: this.store.findAll('group'),
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAdding: true
        }));
      },
      showRemoveGroupRecordsModal: function showRemoveGroupRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-group-records-modal-dialog',
          groups: this.store.findAll('group'),
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAdding: false
        }));
      },
      showAddLabelRecordsModal: function showAddLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAdding: true
        }));
      },
      showRemoveLabelRecordsModal: function showRemoveLabelRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-label-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isAdding: false
        }));
      },
      showActivateRecordsModal: function showActivateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-activate-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isActive: true
        }));
      },
      showDeactivateRecordsModal: function showDeactivateRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-activate-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          isActive: false
        }));
      },
      showDeleteRecordsModal: function showDeleteRecordsModal(params) {
        if (params.isRetry) {
          this.send('deselectModelListItems');
        }
        this.send('showModal', modalData.create({
          modalName: 'confirm-delete-records-modal-dialog',
          selectedEntities: params.failedRecords || this.controller.selectedEntities,
          message: "The selected Experience(s) will be permanently deleted.\n        Deleting Experience(s) will not affect its associated Rules and Content.\n        You can access the items from Context/Content Hub. This cannot be undone.\n        Do you still wish to continue?"
        }));
      },
      activateRecords: function activateRecords(params) {
        var _this = this;
        var isActive = params.isActive;
        var records = params.records.filterBy('isActive', !isActive);
        var adapter = this.store.adapterFor('experience');
        var requests = this.createBatchRequest(records, null, function (record) {
          return adapter.toggleProperty(record, 'isActive');
        });
        Ember.run.scheduleOnce('afterRender', function () {
          _this.send('showModal', Ember.Object.create({
            modalName: 'perform-action-modal-dialog',
            requests: requests,
            records: records,
            singularEntityName: params.singularEntityName,
            pluralEntityName: params.pluralEntityName,
            retryAction: isActive ? 'showActivateRecordsModal' : 'showDeactivateRecordsModal',
            finishAction: 'finalizeRecordsAction',
            confirmModalTitle: params.confirmModalTitle,
            failedPostfix: 'activate.',
            sucessPostfix: 'activated!',
            total: records.length
          }));
        });
      }
    }
  });
  _exports.default = _default;
});