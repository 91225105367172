define("experience-studio/components/analytics-rule-overview", ["exports", "experience-studio/mixins/analytics-rule-overview", "ember-data"], function (_exports, _analyticsRuleOverview, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_analyticsRuleOverview.default, {
    classNames: ['analytics-rule-overview', 'analytics-card'],
    endTime: null,
    rule: null,
    startTime: null,
    temporalRange: null,
    temporalTick: null,
    isPrimaryLineChartVisible: true,
    utility: Ember.inject.service(),
    isAudienceReachTabSelected: Ember.computed.equal('selectedTab.name', 'Audience Reach'),
    tabs: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      name: 'New Active Users',
      description: 'The total number of users who have become eligible to receive content at least once in a given time period.',
      isSelected: true,
      yAxisLabel: 'Number of Users'
    }), Ember.Object.create({
      name: 'Avg Time Eligible',
      description: 'The average time a user is eligible to receive content.',
      isSelected: false,
      yAxisLabel: 'Avg. Time in Seconds'
    }), Ember.Object.create({
      name: 'Status Changes per User',
      description: 'The average number of times a user becomes eligible to receive content.',
      isSelected: false,
      yAxisLabel: 'Avg. Number of Status Changes'
    }), Ember.Object.create({
      name: 'Status Changes',
      description: 'The total number of times users become eligible to receive content.',
      isSelected: false,
      yAxisLabel: 'Number of Status Changes'
    })],
    evaluationTypes: Ember.computed('selectedTab', function () {
      var evaluationTypes = [];
      evaluationTypes.pushObject(Ember.Object.create({
        name: 'Satisfied',
        property: 'true',
        isSelected: true
      }));
      if (!this.isAudienceReachTabSelected) {
        evaluationTypes.pushObject(Ember.Object.create({
          name: 'Not Satisfied',
          property: 'false',
          isSelected: false
        }));
        evaluationTypes.pushObject(Ember.Object.create({
          name: 'Insufficient Data',
          property: 'error',
          isSelected: false
        }));
      }
      return evaluationTypes;
    }),
    modelSham: Ember.computed('model.isFulfilled', 'selectedTab', function () {
      var model = null;
      var selectedTab = this.selectedTab;
      if (this.get('model.isFulfilled') && selectedTab) {
        model = this.get("model.".concat(Ember.String.camelize(selectedTab.get('name'))));
      }
      return model;
    }),
    observeModel: Ember.observer('modelSham', function () {
      if (this.modelSham) {
        this.setChartData(this.modelSham);
      }
    }),
    observeSelectedEvaluationType: Ember.observer('selectedEvaluationType', function () {
      var selectedEvaluationType = this.get('selectedEvaluationType.name');
      var primaryLineChartData = null;
      if (selectedEvaluationType) {
        if (selectedEvaluationType === 'Satisfied') {
          primaryLineChartData = this.trueChartData;
        } else if (selectedEvaluationType === 'Not Satisfied') {
          primaryLineChartData = this.falseChartData;
        } else if (selectedEvaluationType === 'Insufficient Data') {
          primaryLineChartData = this.errorChartData;
        }
        if (primaryLineChartData) {
          this.set('primaryLineChartData', primaryLineChartData);
        } else if (this.modelSham) {
          this.setChartData(this.modelSham);
        }
      }
    }),
    setChartData: function setChartData(model) {
      var errorChartColor = '#A5CAD9';
      var falseChartColor = '#64B6D8';
      var selectedEvaluationType = this.get('selectedEvaluationType.name');
      var trueChartColor = '#0076A5';
      var errorChartData = null;
      var falseChartData = null;
      var pieChartData = null;
      var primaryLineChartData = null;
      var trueChartData = null;
      errorChartData = {
        datasets: [{
          borderColor: errorChartColor,
          borderWidth: 2,
          data: [],
          fill: false,
          label: 'Insufficient Data'
        }],
        labels: []
      };
      falseChartData = {
        datasets: [{
          borderColor: falseChartColor,
          borderWidth: 2,
          data: [],
          fill: false,
          label: 'Not Satisfied'
        }],
        labels: []
      };
      trueChartData = {
        datasets: [{
          borderColor: trueChartColor,
          borderWidth: 2,
          data: [],
          fill: false,
          label: 'Satisfied'
        }],
        labels: []
      };
      for (var i = 0; i < model.data.length; i++) {
        var item = model.data.objectAt(i);
        var timestampMoment = window.moment.unix(item.timestamp);
        errorChartData.labels.pushObject(timestampMoment);
        errorChartData.datasets[0].data.pushObject(item.error);
        falseChartData.labels.pushObject(timestampMoment);
        falseChartData.datasets[0].data.pushObject(item.false);
        trueChartData.labels.pushObject(timestampMoment);
        trueChartData.datasets[0].data.pushObject(item.true);
      }
      pieChartData = {
        datasets: [{
          backgroundColor: [errorChartColor, falseChartColor, trueChartColor],
          borderWidth: 0,
          data: [model.errorCount, model.falseCount, model.trueCount],
          fill: false,
          label: ''
        }],
        labels: ['Insufficient Data', 'Not Satisfied', 'Satisfied']
      };
      if (selectedEvaluationType === 'Satisfied') {
        primaryLineChartData = trueChartData;
      } else if (selectedEvaluationType === 'Not Satisfied') {
        primaryLineChartData = falseChartData;
      } else if (selectedEvaluationType === 'Insufficient Data') {
        primaryLineChartData = errorChartData;
      }
      this.setProperties({
        errorChartData: errorChartData,
        falseChartData: falseChartData,
        pieChartData: pieChartData,
        primaryLineChartData: primaryLineChartData,
        trueChartData: trueChartData
      });
    },
    primaryLineChartOptions: Ember.computed('selectedTab.name', function () {
      var scaleLabel = {
        display: false
      };
      var selectedTab = this.selectedTab;
      if (!Ember.isEmpty(selectedTab.get('name'))) {
        scaleLabel.display = true;
        scaleLabel.labelString = selectedTab.get('yAxisLabel');
      }
      var options = {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            type: 'time'
          }],
          yAxes: [{
            scaleLabel: scaleLabel,
            ticks: {
              suggestedMax: 10,
              suggestedMin: 0
            }
          }]
        },
        tooltips: {
          custom: function custom(tooltipModel) {
            var title = tooltipModel.title;
            if (title && title.length) {
              tooltipModel.title[0] = tooltipModel.title[0].format('llll');
            }
            return tooltipModel;
          }
        }
      };
      return options;
    }),
    secondaryLineChartOptions: Ember.computed(function () {
      var options = {
        elements: {
          point: {
            radius: 0
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            type: 'time'
          }],
          yAxes: [{
            display: false
          }]
        },
        tooltips: {
          enabled: false
        }
      };
      return options;
    }),
    selectedEvaluationType: Ember.computed('evaluationTypes.@each.isSelected', function () {
      return this.evaluationTypes.filterBy('isSelected').get('firstObject');
    }),
    selectedTab: Ember.computed('tabs.@each.isSelected', function () {
      return this.tabs.filterBy('isSelected').get('firstObject');
    }),
    observeStartAndEndTime: Ember.observer('startTime', 'endTime', 'rule', function () {
      this.set('model', null);
      Ember.run.debounce(this, this.getData, 2000);
    }),
    getData: function getData() {
      var _this = this;
      var model = null;
      var options = null;
      var promises = null;
      if (this.rule && this.startTime && this.endTime) {
        options = {
          endTime: this.endTime,
          id: this.get('rule.id'),
          startTime: this.startTime,
          temporalRange: this.temporalRange,
          temporalTick: this.temporalTick
        };
        promises = {
          audienceReach: this._getUsersAffected(JSON.parse(JSON.stringify(options))),
          avgTimeEligible: this._getDurationData(JSON.parse(JSON.stringify(options))),
          newActiveUsers: this._getUniqueUsersData(JSON.parse(JSON.stringify(options))),
          statusChanges: this._getTotalEvaluationsData(JSON.parse(JSON.stringify(options))),
          statusChangesPerUser: this._getEvaluationsPerUserData(JSON.parse(JSON.stringify(options)))
        };
        model = _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.hash(promises).catch(function (response) {
            return Ember.Object.create({
              errorMessage: _this.utility.parseError(response)
            });
          })
        });
      }
      this.set('model', model);
    },
    didInsertElement: function didInsertElement() {
      this.getData();
      this.$().find('.svg-icon-container').popover();
      Ember.run.scheduleOnce('afterRender', this, function () {
        var tabs = this.tabs;
        if (!Ember.isEmpty(tabs)) {
          tabs.setEach('isSelected', false);
          this.get('tabs.firstObject').set('isSelected', true);
        }
      });
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().find('.svg-icon-container').popover('dispose');
      this._super.apply(this, arguments);
    },
    actions: {
      changeEvaluationType: function changeEvaluationType(item) {
        this.evaluationTypes.setEach('isSelected', false);
        item.set('isSelected', true);
      },
      changeTab: function changeTab(tab) {
        this.tabs.setEach('isSelected', false);
        tab.set('isSelected', true);

        // TODO: Figure out better approach to refresh y-axis's label
        this.set('isPrimaryLineChartVisible', false);
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.set('isPrimaryLineChartVisible', true);
        });
      }
    }
  });
  _exports.default = _default;
});