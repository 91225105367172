define("experience-studio/components/content-tmp-branch-gradient", ["exports", "experience-studio/mixins/draggable-list"], function (_exports, _draggableList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var findField = function findField(arr, tmpID) {
    return arr.filter(function (item) {
      return item.tmpID === tmpID;
    })[0];
  };
  var _default = Ember.Component.extend(_draggableList.default, {
    classNames: ['content-tmp-branch-gradient'],
    attributeBindings: ['field.tmpID:tmp-id'],
    isExpanded: true,
    hasOptions: true,
    actions: {
      addItem: function addItem() {
        var child = this.field.newChild();
        this.get('field.value').pushObject(child);
      },
      delete: function _delete(field) {
        this.get('field.value').removeObject(field);
      },
      clearArray: function clearArray() {
        this.get('field.value').clear();
      },
      reorder: function reorder(opts) {
        var elem = findField(this.get('field.value'), opts.elemID);
        var sibling = findField(this.get('field.value'), opts.siblingID);
        this.get('field.value').removeObject(elem);
        if (sibling) {
          var destinationIndex = this.get('field.value').indexOf(sibling);
          this.get('field.value').insertAt(destinationIndex, elem);
        } else {
          this.get('field.value').pushObject(elem);
        }
        this.reorder();
      }
    },
    didInsertElement: function didInsertElement() {
      this.initDrag();
    }
  });
  _exports.default = _default;
});