define("experience-studio/components/permissions-hub-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['permissions-hub-tabs'],
    reset: function reset() {
      this.setProperties({
        isAccessLevelsTabSelected: false,
        isRolesTabSelected: false,
        isUserAccountsTabSelected: false
      });
    },
    actions: {
      select: function select(property) {
        this.reset();
        this.set(property, true);
      }
    }
  });
  _exports.default = _default;
});