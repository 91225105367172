define("experience-studio/routes/content-hub/location", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/model-actions", "experience-studio/mixins/pagination-route", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-content"], function (_exports, _authenticatedRoute, _modelActions, _paginationRoute, _routeModelBatchActionsBase, _routeModelBatchActionsContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _paginationRoute.default, _modelActions.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsContent.default, {
    utility: Ember.inject.service(),
    storage: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        contentInstances: store.query('content-blueprint', {
          page: params.page || 1,
          recordsPerPage: params.recordsPerPage || 48,
          search: params.search,
          sortby: params.sortBy,
          sortorder: params.sortOrder
        }),
        languages: store.queryRecord('project-config', {
          setting: 'language'
        })
      });
    },
    beforeModel: function beforeModel(transition) {
      if (this.storage.getItem('isContentHubListView') === 'true' && (transition.targetName === 'content-hub-v2' || transition.targetName === 'content-hub.location')) {
        this.transitionTo('content-hub-v2');
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          searchKeyword: '',
          sortBy: '',
          sortOrder: '',
          page: 1,
          recordsPerPage: 48
        });
        this.send('deselectModelListItems');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        isInfoSidebarOpen: false,
        sortedModel: [],
        selectedEntities: [],
        contentListSortProperties: [Ember.Object.create({
          label: 'Name',
          sortValue: 'name',
          isSorted: false,
          order: {
            desc: 'A-Z',
            asc: 'Z-A'
          }
        }), Ember.Object.create({
          label: 'Date Created',
          sortValue: 'createdAt',
          isSorted: true,
          order: {
            desc: 'Newest',
            asc: 'Oldest'
          }
        }), Ember.Object.create({
          label: 'Date Modified',
          sortValue: 'modifiedAt',
          isSorted: false,
          order: {
            desc: 'Recent',
            asc: 'Oldest'
          }
        })],
        contentHubController: this.controllerFor('content-hub'),
        pagination: model.contentInstances.meta.pagination
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createContent: function createContent() {
        this.transitionTo('content-hub.blueprint-templates');
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
        this.send('aggregateModel', 'content-instance', 'contentInstances');
      },
      getItemsForNextPage: function getItemsForNextPage() {
        var controller = this.controller;
        controller.set('page', controller.page + 1);
      },
      changeToListView: function changeToListView(routeName) {
        this.storage.setItem('isContentHubListView', true);
        this.transitionTo(routeName);
      },
      selectModelListItems: function selectModelListItems(model) {
        this._super(model, 'contentHubController.selectedEntities');
      },
      didTransition: function didTransition() {
        var page = this.controller.get('page');
        var model = this.controller.model.contentInstances;
        if (page > 1 && model.get('length') === 0) {
          this.controller.set('page', 1);
        }
      }
    }
  });
  _exports.default = _default;
});