define("experience-studio/components/confirm-associate-records-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['batch-actions-modal'],
    selectedRecords: null,
    selectedExperiences: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        selectedRecords: [],
        selectedExperiences: [],
        headers: [Ember.Object.create({
          name: 'Name'
        })]
      });
    },
    confirmModalTitle: Ember.computed('model.pluralEntityName', function () {
      if (this.model.isAssociating) {
        return "Associate to Experience(s)";
      } else {
        return "Disassociate from Experience(s)";
      }
    }),
    searchPlaceholder: Ember.computed('model.pluralEntityName', function () {
      return "Search ".concat(this.model.pluralEntityName);
    }),
    isActionDisabled: Ember.computed('selectedRecords.[]', 'selectedGroups.[]', function () {
      return this.selectedRecords.length === 0 || this.selectedExperiences.length === 0;
    }),
    experienceSham: Ember.computed('model.{experiences.[],selectedEntities.[],isAssociating}', function () {
      var _this = this;
      if (this.model.isAssociating) {
        return this.model.experiences;
      } else {
        return this.model.experiences.filter(function (experience) {
          return _this.model.selectedEntities.reduce(function (previous, item) {
            return previous || experience.get(_this.model.associateVar) === item.id || experience.get(_this.model.associateVar).includes(item.id);
          }, false);
        });
      }
    }),
    actions: {
      associate: function associate() {
        var records = this.selectedRecords;
        this.performAction({
          action: 'associateRecords',
          isAssociating: this.model.isAssociating,
          singularEntityName: this.model.singularEntityName,
          pluralEntityName: this.model.pluralEntityName,
          experiences: this.selectedExperiences,
          associateVar: this.model.associateVar,
          confirmModalTitle: this.confirmModalTitle,
          records: records
        });
        this.send('close');
      },
      onSelectExperiences: function onSelectExperiences(experiences) {
        var _this2 = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this2.set('selectedExperiences', experiences);
        });
      },
      onSelectRecords: function onSelectRecords(records) {
        var _this3 = this;
        Ember.run.scheduleOnce('afterRender', this, function () {
          _this3.set('selectedRecords', records);
        });
      }
    }
  });
  _exports.default = _default;
});