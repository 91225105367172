define("experience-studio/components/flight-sidebar-user-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    actions: {
      signOut: function signOut() {
        this.session.unauthenticate();
      }
    }
  });
  _exports.default = _default;
});