define("experience-studio/components/content-template-editor", ["exports", "experience-studio/mixins/field-model", "jquery"], function (_exports, _fieldModel, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /*
  
    Usage:
  
    {{content-template-editor
      contentInstance=model
      contentTemplate=model.computedContentTemplate
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-template-editor'],
    lastLoadedTmpID: '',
    currentEditToken: null,
    isDynamicAttributeDropDownVisible: false,
    dynamicAtrributeValue: {
      // eslint-disable-line
      value: '',
      delete: ''
    },
    dropdownTeatherConstraints: [
    // eslint-disable-line
    {
      to: 'window',
      attachment: 'together'
    }],
    closeDropDown: function closeDropDown() {
      if (this.isDynamicAttributeDropDownVisible) {
        this.set('isDynamicAttributeDropDownVisible', false);
      }
    },
    rootField: Ember.computed('contentTemplate.schema.fields', function () {
      if (!this.get('contentTemplate.schema.fields')) {
        return;
      }
      var root = new _fieldModel.default();
      root.type = 'ctobject';
      root.key = 'root';
      root.schema = this.get('contentTemplate.schema.fields').map(function (obj) {
        return new _fieldModel.default(obj);
      });
      this.set('contentInstance.root', root); // eslint-disable-line

      var contentData = this.contentInstance.get('values');
      if (contentData) {
        root = root.fromData(contentData);
      }
      if (this.contentInstance.isConciergeTemplate) {
        var _root, _root$schema, _root2, _root2$schema, _mediaField$schema, _mediaField$schema2, _mediaField$schema3, _mediaField$schema4;
        this.removeConciergeDropdownoptions();

        // Video Content: temporary implementation on backwards
        //  compatibilitiy to fill new media field with image field
        //  value when loading old start template content instances
        //  before the backend populate it through a migration script.
        var imageField = (_root = root) === null || _root === void 0 ? void 0 : (_root$schema = _root.schema) === null || _root$schema === void 0 ? void 0 : _root$schema.find(function (field) {
          return field.type === 'ctlocalizedimage';
        });
        var mediaField = (_root2 = root) === null || _root2 === void 0 ? void 0 : (_root2$schema = _root2.schema) === null || _root2$schema === void 0 ? void 0 : _root2$schema.find(function (field) {
          return field.type === 'ctlocalizedmedia';
        });
        var mediaType = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema = mediaField.schema) === null || _mediaField$schema === void 0 ? void 0 : _mediaField$schema.find(function (field) {
          return field.key === 'mediaType';
        });
        var mediaProvider = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema2 = mediaField.schema) === null || _mediaField$schema2 === void 0 ? void 0 : _mediaField$schema2.find(function (field) {
          return field.key === 'mediaProvider';
        });
        var resourceURL = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema3 = mediaField.schema) === null || _mediaField$schema3 === void 0 ? void 0 : _mediaField$schema3.find(function (field) {
          return field.key === 'resourceURL';
        });
        var previewImgURL = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema4 = mediaField.schema) === null || _mediaField$schema4 === void 0 ? void 0 : _mediaField$schema4.find(function (field) {
          return field.key === 'previewImgURL';
        });
        if (mediaField) {
          imageField.isVisible = false;
        }
        if (imageField !== null && imageField !== void 0 && imageField.value && Object.keys(imageField.value).length && mediaType && !mediaType.value && resourceURL && previewImgURL) {
          var _mediaType$options, _mediaType$options$;
          mediaType.value = mediaType === null || mediaType === void 0 ? void 0 : (_mediaType$options = mediaType.options) === null || _mediaType$options === void 0 ? void 0 : (_mediaType$options$ = _mediaType$options[0]) === null || _mediaType$options$ === void 0 ? void 0 : _mediaType$options$.val;
          mediaProvider.value = '';
          var resourceURLValue = {};
          var previewImgURLValue = {};
          for (var _i = 0, _Object$entries = Object.entries(imageField.value); _i < _Object$entries.length; _i++) {
            var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              lang = _Object$entries$_i[0],
              value = _Object$entries$_i[1];
            resourceURLValue[lang] = _defineProperty({}, resourceURL.key, value[imageField.key]);
            previewImgURLValue[lang] = _defineProperty({}, previewImgURL.key, value[imageField.key]);
          }
          resourceURL.value = resourceURLValue;
          previewImgURL.value = previewImgURLValue;
        }
      }
      return root;
    }),
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    // temporary implementation to remove dropdown values
    removeConciergeDropdownoptions: function removeConciergeDropdownoptions() {
      if (this.contentInstance.isConciergeTemplate) {
        this.get('contentTemplate.schema.fields').forEach(function (fields) {
          if (fields.name === 'buttons' || fields.name === 'link') {
            fields.fields.forEach(function (field) {
              if (field.name === 'actionType' || field.name === 'linkdropdown') {
                var removeAppIndex = field.metadata.options.map(function (item) {
                  return item.val;
                }).indexOf('APP');
                field.metadata.options.splice(removeAppIndex, 1);
                var removeContentIndex = field.metadata.options.map(function (item) {
                  return item.val;
                }).indexOf('CONTENT');
                field.metadata.options.splice(removeContentIndex, 1);
                field.metadata.options.forEach(function (option) {
                  if (option.val === 'UNIVERSAL_ACTION') {
                    option.val = option.validationPrefix + option.val;
                  }
                });
              }
            });
          }
        });
      }
    },
    didRootChange: Ember.observer('rootField.schema.fields', function () {
      if (this.contentInstance.isConciergeTemplate) {
        this.set('ciPreviewRoot', this.rootField);
        var dropdownField = [];
        var contentInstanceUrlSchema = {};
        this.rootField.schema.forEach(function (item) {
          if (item.type === 'ctobject') {
            contentInstanceUrlSchema[item.key] = item;
            item.schema.forEach(function (field) {
              if (field.type === 'ctdropdown') {
                if (field.key === 'actionType' && field.options && field.options.length) {
                  var dropdownOptions = [];
                  var concierge4ActionOptions = [];
                  var concierge5ActionOptions = [];
                  for (var count = 0; count < field.options.length; count++) {
                    if (field.options[count].val === 'details:UNIVERSAL_ACTION') {
                      concierge5ActionOptions.push(field.options[count]);
                    } else if (field.options[count].val.includes('UNIVERSAL_ACTION')) {
                      concierge4ActionOptions.push(field.options[count]);
                    } else {
                      dropdownOptions.push(field.options[count]);
                    }
                  }
                  if (concierge4ActionOptions.length) {
                    dropdownOptions.push({
                      groupName: 'Universal Actions (Require Concierge version >= 4.4)',
                      options: concierge4ActionOptions,
                      key: 'action_types_4'
                    });
                  }
                  if (concierge5ActionOptions.length) {
                    dropdownOptions.push({
                      groupName: 'Universal Actions (Require Concierge version >= 5)',
                      options: concierge5ActionOptions,
                      key: 'action_types_5'
                    });
                  }
                  field.options = dropdownOptions;
                }
                dropdownField.push(field);
              }
            });
          }
          if (item.type === 'ctlocalizedmedia') {
            contentInstanceUrlSchema[item.key] = item;
            var mediaProvider = contentInstanceUrlSchema[item.key].schema.find(function (field) {
              return field.key === 'mediaProvider';
            });
            if (mediaProvider.options && mediaProvider.options[0]) {
              mediaProvider.value = mediaProvider.options[0].val;
            } else {
              mediaProvider.value = '';
            }
          }
        });
        this.set('contentInstanceUrlSchema', contentInstanceUrlSchema);
        this.set('contentInstanceDropdown', dropdownField);
      }
    }),
    didDropdownChange: Ember.observer('contentInstanceDropdown.@each.value', function () {
      if (this.contentInstance.isConciergeTemplate) {
        var dropdownValue = '';
        var isDetailView = false;
        var selectedLanguageCode = this.selectedLanguageCode;
        var languages = this.languages;
        var currentNode = this;
        var isLoad = this.isLoad;
        this.rootField.schema.forEach(function (item) {
          if (item.type === 'ctobject') {
            if (item.key === 'link') {
              var linkValue = '';
              item.schema.forEach(function (field) {
                if (field.type === 'ctdropdown') {
                  dropdownValue = field.value;
                  isDetailView = dropdownValue === 'OPEN_DETAIL' || isDetailView;
                  if (dropdownValue && dropdownValue.includes('UNIVERSAL_ACTION')) {
                    linkValue = dropdownValue;
                  }
                }
                if (field.key === 'actionValue') {
                  var urlLink = Object.assign({}, field.value);
                  if (selectedLanguageCode) {
                    urlLink[selectedLanguageCode] = urlLink[selectedLanguageCode] === 'url-link' ? '' : urlLink[selectedLanguageCode];
                  }
                  if (dropdownValue === 'OPEN_DETAIL' && !urlLink[selectedLanguageCode]) {
                    urlLink[selectedLanguageCode] = 'url-link';
                  }
                  if (linkValue.includes('UNIVERSAL_ACTION')) {
                    urlLink = currentNode.setUniversalActionValue(linkValue, urlLink, field, languages, isLoad);
                  }
                  Ember.set(field, 'linkValue', linkValue);
                  Ember.set(field, 'value', urlLink);
                  Ember.set(field, 'isVisible', !Ember.isEqual(dropdownValue, 'OPEN_DETAIL'));
                }
              });
            }
          }
        });
        if (this.contentInstance.templateType === 'concierge-card-link') {
          this.set('isDetailView', isDetailView);
        } else {
          this.set('isDetailView', this.get('isDetailView'));
        }
        this.setDetailPreviewData(this.get('isDetailView'));
        this.set('isLoad', false);
      }
    }),
    setUniversalActionValue: function setUniversalActionValue(linkValue, urlLink, field, languages, isLoad) {
      var _urlLink$currentLang$, _urlLink$currentLang$2, _urlLink$currentLang$3, _urlLink$currentLang$4, _urlLink$currentLang$5, _urlLink$currentLang$6, _urlLink$currentLang$7, _urlLink$currentLang$8;
      var iosValue = {};
      var androidValue = {};
      for (var count = 0; count < languages.length; count++) {
        var currentLang = languages[count];
        switch (linkValue) {
          case 'mailto:UNIVERSAL_ACTION':
            urlLink[currentLang.id] = isLoad ? (_urlLink$currentLang$ = urlLink[currentLang.id]) === null || _urlLink$currentLang$ === void 0 ? void 0 : _urlLink$currentLang$.replace('mail://?sendTo=', '') : '';
            break;
          case 'details:UNIVERSAL_ACTION':
            urlLink[currentLang.id] = isLoad ? (_urlLink$currentLang$2 = urlLink[currentLang.id]) === null || _urlLink$currentLang$2 === void 0 ? void 0 : _urlLink$currentLang$2.replace('details://?contentId=', '') : '';
            break;
          case 'web:UNIVERSAL_ACTION':
            urlLink[currentLang.id] = isLoad ? decodeURIComponent((_urlLink$currentLang$3 = urlLink[currentLang.id]) === null || _urlLink$currentLang$3 === void 0 ? void 0 : _urlLink$currentLang$3.replace('web://?url=', '')) : '';
            break;
          case 'browser:UNIVERSAL_ACTION':
            urlLink[currentLang.id] = isLoad ? decodeURIComponent((_urlLink$currentLang$4 = urlLink[currentLang.id]) === null || _urlLink$currentLang$4 === void 0 ? void 0 : _urlLink$currentLang$4.replace('browser://?url=', '')) : '';
            break;
          case 'app:UNIVERSAL_ACTION':
            urlLink[currentLang.id] = isLoad ? (_urlLink$currentLang$5 = urlLink[currentLang.id]) === null || _urlLink$currentLang$5 === void 0 ? void 0 : _urlLink$currentLang$5.replace('app://?', '') : '';
            break;
          case 'tel:UNIVERSAL_ACTION':
            urlLink[currentLang.id] = isLoad ? (_urlLink$currentLang$6 = urlLink[currentLang.id]) === null || _urlLink$currentLang$6 === void 0 ? void 0 : _urlLink$currentLang$6.replace('phone://?phoneNumber=', '') : '';
            break;
          case 'concierge:UNIVERSAL_ACTION':
            urlLink[currentLang.id] = isLoad ? (_urlLink$currentLang$7 = urlLink[currentLang.id]) === null || _urlLink$currentLang$7 === void 0 ? void 0 : _urlLink$currentLang$7.replace('concierge://?', '') : '';
            break;
          case 'universalLink:UNIVERSAL_ACTION':
            var currentValue = urlLink[currentLang.id] = isLoad ? (_urlLink$currentLang$8 = urlLink[currentLang.id]) === null || _urlLink$currentLang$8 === void 0 ? void 0 : _urlLink$currentLang$8.replace('universalLink://?', '') : '';
            if (currentValue) {
              if (currentValue.includes('android=')) {
                var androidSecondData = currentValue.split('android=')[1];
                var androidFirstData = currentValue.split('android=')[0];
                if (androidSecondData.includes('ios=')) {
                  iosValue[currentLang.id] = decodeURIComponent(androidSecondData.split('&ios=')[1]);
                  androidValue[currentLang.id] = decodeURIComponent(androidSecondData.split('&ios=')[0]);
                } else {
                  androidValue[currentLang.id] = decodeURIComponent(androidSecondData);
                }
                if (androidFirstData.includes('ios=')) {
                  iosValue[currentLang.id] = decodeURIComponent(androidFirstData.split('ios=')[1].replace(/&$/, ''));
                }
              } else {
                if (currentValue.includes('ios=')) {
                  var iosData = currentValue.split('ios=')[1];
                  iosValue[currentLang.id] = decodeURIComponent(iosData);
                }
              }
            }
            break;
        }
      }
      Ember.set(field, 'ios', iosValue);
      Ember.set(field, 'android', androidValue);
      return urlLink;
    },
    isDropdownChanged: function isDropdownChanged(currentNode, options) {
      var dropdownValue = false;
      if (currentNode.contentInstance && currentNode.contentInstance.isConciergeTemplate) {
        if (currentNode.conciergeTemplateName === 'concierge-card-button') {
          dropdownValue = true;
          if (options && options.key === 'actionType') {
            dropdownValue = false;
          }
        }
      }
      return dropdownValue;
    },
    setDetailPreviewData: function setDetailPreviewData(isDetailView, options) {
      var templateName = this.get('contentInstance.templateType');
      switch (templateName) {
        case 'concierge-card-buttons':
          templateName = 'concierge-card-button';
          break;
        case 'concierge-card-link':
          templateName = 'concierge-card';
          break;
      }
      this.set('conciergeTemplateName', templateName);
      if (isDetailView) {
        templateName = "".concat(templateName, "-detail-view");
        this.set('conciergeDetailTemplateName', templateName);
      }
      this.rootField.schema.forEach(function (item) {
        if (options && options.key === 'actionType' && item.type === 'ctobjarray' && item.key === 'buttons') {
          Ember.set(item, 'clearError', !item.clearError);
        }
        if (item.type === 'ctobject' && item.key === 'link') {
          Ember.set(item, 'clearError', !item.clearError);
        }
        if (item.type === 'ctobject' && item.key === 'details') {
          item.schema.forEach(function (innerItem) {
            if (innerItem.key === 'body') {
              Ember.set(innerItem, 'isVisible', true);
            } else {
              Ember.set(innerItem, 'isVisible', false);
            }
          });
          Ember.set(item, 'isVisible', isDetailView);
        }
      });
    },
    updateRootData: Ember.observer('contentInstance.values', 'rootField', function () {
      this.loadData();
      if (this.contentInstance.isConciergeTemplate && this.contentInstance.templateType === 'concierge-card-buttons') {
        this.send('didCEUpdateData');
      }
    }),
    loadData: function loadData() {
      var root = this.rootField;
      var values = this.get('contentInstance.values');
      this.set('isLoad', true);
      if (root && values && this.lastLoadedTmpID !== root.tmpID) {
        root.fromData(values);
        this.set('lastLoadedTmpID', root.tmpID);
      }
    },
    actions: {
      didCEUpdateData: function didCEUpdateData(options) {
        if (this.contentInstance && this.contentInstance.isConciergeTemplate) {
          if (options && options.key === 'buttons') {
            this.set('buttonCount', options.value.length);
            if (options.value && options.value.length >= 6) {
              this.$().find('.dropdown-duplicate').prop('disabled', true).addClass('disabled');
              this.$().find('.add-item').prop('disabled', true).addClass('disabled');
            } else {
              this.$().find('.dropdown-duplicate').prop('disabled', false).removeClass('disabled');
              this.$().find('.add-item').prop('disabled', false).removeClass('disabled');
            }
          }
          var isDetailView = false;
          var dropdownObject = {};
          var selectedLanguageCode = this.selectedLanguageCode;
          var languages = this.languages;
          var currentNode = this;
          var isLoad = this.isLoad || this.isDropdownChanged(this, options);
          var schema = this.get('contentInstance.root.schema');
          schema.forEach(function (item) {
            if (item.type === 'ctobjarray') {
              item.value.forEach(function (value, index) {
                var dropdownValue = '';
                var linkValue = '';
                dropdownObject[item.key + index] = value;
                value.schema.forEach(function (field) {
                  if (field.type === 'ctdropdown') {
                    if (field.key === 'actionType' && field.options && field.options.length) {
                      var dropdownOptions = [];
                      var concierge4ActionOptions = [];
                      var concierge5ActionOptions = [];
                      for (var count = 0; count < field.options.length; count++) {
                        if (field.options[count].val === 'details:UNIVERSAL_ACTION') {
                          concierge5ActionOptions.push(field.options[count]);
                        } else if (field.options[count].val && field.options[count].val.includes('UNIVERSAL_ACTION')) {
                          concierge4ActionOptions.push(field.options[count]);
                        } else {
                          dropdownOptions.push(field.options[count]);
                        }
                      }
                      if (concierge4ActionOptions.length) {
                        dropdownOptions.push({
                          groupName: 'Universal Actions (Require Concierge version >= 4.4)',
                          options: concierge4ActionOptions,
                          key: 'action_types_4'
                        });
                      }
                      if (concierge5ActionOptions.length) {
                        dropdownOptions.push({
                          groupName: 'Universal Actions (Require Concierge version >= 5)',
                          options: concierge5ActionOptions,
                          key: 'action_types_5'
                        });
                      }
                      Ember.set(field, 'options', dropdownOptions);
                    }
                    dropdownValue = field.value;
                    isDetailView = dropdownValue === 'OPEN_DETAIL' || isDetailView;
                    if (dropdownValue && dropdownValue.includes('UNIVERSAL_ACTION')) {
                      linkValue = dropdownValue;
                    }
                  }
                  if (field.key === 'actionValue') {
                    var urlLink = Object.assign({}, field.value);
                    if (selectedLanguageCode) {
                      urlLink[selectedLanguageCode] = urlLink[selectedLanguageCode] === 'url-link' ? '' : urlLink[selectedLanguageCode];
                    }
                    if (dropdownValue === 'OPEN_DETAIL' && !urlLink[selectedLanguageCode]) {
                      urlLink[selectedLanguageCode] = 'url-link';
                    }
                    if (linkValue.includes('UNIVERSAL_ACTION')) {
                      urlLink = currentNode.setUniversalActionValue(linkValue, urlLink, field, languages, isLoad);
                    }
                    Ember.set(field, 'linkValue', linkValue);
                    Ember.set(field, 'value', urlLink);
                    Ember.set(field, 'isVisible', !Ember.isEqual(dropdownValue, 'OPEN_DETAIL'));
                  }
                });
              });
            }
          });
          this.set('dropdownObject', dropdownObject);
          this.set('isDetailView', isDetailView);
          this.setDetailPreviewData(isDetailView, options);
          this.set('isLoad', false);
        }
      },
      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.set('isDynamicAttributeDropDownVisible', true);
        this.set('currentEditToken', value);
      },
      isPreviewVideoTooltip: function isPreviewVideoTooltip(value) {
        if (typeof this.isPreviewVideoTooltip !== 'undefined') {
          this.isPreviewVideoTooltip(value);
        }
      },
      edit: function edit() {
        this.set('dynamicAtrributeValue.value', this.currentEditToken);
      },
      delete: function _delete() {
        this.set('dynamicAtrributeValue.delete', this.currentEditToken.closest('.dynamic-editable-block'));
        this.currentEditToken.parentElement.removeChild(this.currentEditToken);
      },
      focusIn: function focusIn() {
        this.closeDropDown();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      if (this.get('contentInstance.values') && this.rootField) {
        this.loadData();
      }
      (0, _jquery.default)(document).on("click.".concat(this.elementId), function (event) {
        if (!(0, _jquery.default)(event.target).closest(_this.$('.dynamic-attr')).length) {
          _this.closeDropDown();
        }
      });
    }
  });
  _exports.default = _default;
});