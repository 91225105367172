define("experience-studio/components/route-model-sort-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-sort-dropdown'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortOptions', [{
        label: 'Name (a-z)',
        sortBy: 'name',
        sortOrder: 'asc'
      }, {
        label: 'Name (z-a)',
        sortBy: 'name',
        sortOrder: 'desc'
      }, {
        label: 'Newest to Oldest',
        sortBy: 'createdAt',
        sortOrder: 'desc',
        default: true
      }, {
        label: 'Oldest to Newest',
        sortBy: 'createdAt',
        sortOrder: 'asc'
      }]);
    },
    currentSortOption: Ember.computed('queryParameters', 'sortOptions.[]', function () {
      var _this$queryParameters = this.queryParameters,
        sortOrder = _this$queryParameters.sortOrder,
        sortBy = _this$queryParameters.sortBy;
      return this.sortOptions.find(function (option) {
        return option.sortOrder === sortOrder && option.sortBy === sortBy;
      }) || this.sortOptions.findBy('default');
    }),
    actions: {
      updateSortParams: function updateSortParams(option) {
        if (this.didUpdateQueryParams) {
          this.didUpdateQueryParams({
            sortBy: option.sortBy,
            sortOrder: option.sortOrder
          });
        }
      }
    }
  });
  _exports.default = _default;
});