define("experience-studio/components/custom-field--dropdown", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    didInsertElement: function didInsertElement() {
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    triggerValidation: function triggerValidation() {
      if (Ember.isEmpty(this.field.value) && this.field.isRequired) {
        this.set('isDirty', true);
        this.send('onValue', false, this.field.key, '');
      } else {
        this.send('onValue', false, this.field.key, this.field.value);
      }
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var field = this.field;
      field.set('errorMessages', []);
      this._super(value, isValid);
    },
    actions: {
      onValue: function onValue(isChange, key, value) {
        this.setValidity(value);
        this.sendAction('onValue', value, key, isChange); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});