define("experience-studio/components/av5-expandable-table", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  /*
  
    Description:
  
    Presents a simple table view of data.
  
    Usages:
  
      {{av5-expandable-table
        data=tableData
        sortBy=column
        isLoading=tableIsLoading
        updateTable=(action 'updateTable')
        pageSize=pageSize}}
  
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-expandable-table'],
    utility: Ember.inject.service(),
    headers: null,
    data: null,
    isLoading: false,
    // COMPUTED ------------------------------------------------------------------
    isSortDesc: Ember.computed('queryParams.sortOrder', function () {
      return this.queryParams.sortOrder === 'desc';
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.queryParams) {
        this.set('queryParams', Ember.Object.create({
          sortBy: '',
          sortOrder: 'desc',
          limit: 5,
          offset: 0
        }));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // currently, the stylings of the datapoints are handled by a helper
      // perhaps we could migrate them to these if performance becomes an issue
      (0, _jquery.default)(this.element).find('.td .value').each(function (idx, el) {
        (0, _jquery.default)(el).tooltip('dispose');
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!Ember.isEmpty(this.headers)) {
        (0, _jquery.default)(this.element).find('.title').each(function (index, el) {
          (0, _jquery.default)(el).tooltip({
            trigger: 'hover',
            placement: 'auto',
            container: 'body',
            title: _this.headers.objectAt(index).tooltip || '',
            template: _this.utility.generateStandardTooltipMarkup(_this.classNames.join(' '))
          });
        });
      }
    },
    didRender: function didRender() {
      var _this2 = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('.data-row td .value').each(function (idx, el) {
        var tooltip = _this2.generateDataValueTooltip(idx);
        if (tooltip) {
          (0, _jquery.default)(el).tooltip({
            trigger: 'hover',
            placement: 'auto',
            container: 'body',
            title: tooltip,
            template: _this2.utility.generateStandardTooltipMarkup(_this2.classNames.join(' '))
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!Ember.isEmpty(this.headers)) {
        (0, _jquery.default)(this.element).find('.title').each(function (index, el) {
          (0, _jquery.default)(el).tooltip('dispose');
        });
      }
    },
    // FUNCTIONS -----------------------------------------------------------------
    generateDataValueTooltip: function generateDataValueTooltip(idx) {
      var row = Math.floor(idx / this.headers.length);
      var column = idx % this.headers.length;
      var value = this.data.objectAt(row).data.objectAt(column).value;
      return value.length > 40 ? value : '';
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      updateSortBy: function updateSortBy(clickedHeader) {
        if (clickedHeader.isSortable) {
          var selectedHeader = this.headers.find(function (header) {
            return header.isSelected === true;
          });
          if (!(selectedHeader === clickedHeader)) {
            this.queryParams.setProperties({
              sortOrder: 'desc',
              sortBy: clickedHeader.sortByVar
            });
            selectedHeader.set('isSelected', false);
            clickedHeader.set('isSelected', true);
            this.updateTable();
          }
        }
      },
      updateSortOrder: function updateSortOrder(clickedHeader) {
        var selectedHeader = this.headers.find(function (header) {
          return header.isSelected === true;
        });
        if (selectedHeader === clickedHeader) {
          this.queryParams.setProperties({
            sortOrder: this.queryParams.sortOrder === 'desc' ? 'asc' : 'desc'
          });
          this.updateTable();
        }
      },
      rowClickAction: function rowClickAction(rowData) {
        if (!rowData.hasComponents) return;
        var currentElement = event.target;
        while (currentElement.tagName !== 'TR') {
          currentElement = currentElement.parentNode;
        }
        if (currentElement.classList.contains('data-row--active')) {
          currentElement.classList.remove('data-row--active');
        } else if (currentElement.classList.contains('data-row--has-component')) {
          currentElement.classList.add('data-row--active');
        }
        currentElement = currentElement.parentNode;
        var _iterator = _createForOfIteratorHelper(currentElement.children),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var tableRow = _step.value;
            if (tableRow.classList.contains("component-row--".concat(rowData.id)) && tableRow.classList.contains('component-row--show')) {
              tableRow.classList.remove('component-row--show');
            } else if (tableRow.classList.contains("component-row--".concat(rowData.id))) {
              tableRow.classList.add('component-row--show');
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    }
  });
  _exports.default = _default;
});