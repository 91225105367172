define("experience-studio/serializers/content-template", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    transformSchema: function transformSchema(schema) {
      var result = Ember.Object.create({
        fields: []
      });
      if (schema && schema.fields) {
        var fields = schema.fields;
        var fieldsLength = fields.length;
        for (var i = 0; i < fieldsLength; i++) {
          var field = fields.objectAt(i);
          field.displayName = Ember.Object.create(field.displayName);
          field.properties = Ember.Object.create(field.properties);
          result.fields.pushObject(Ember.Object.create(field));
        }
      }
      return result;
    },
    normalizeResponse: function normalizeResponse() {
      var formattedPayload = this._super.apply(this, arguments);
      if (Array.isArray(formattedPayload.data)) {
        var data = formattedPayload.data.filter(function (item) {
          var type = item.attributes.type ? item.attributes.type.toLowerCase() : '';
          return type !== 'surveyanswers' && type !== 'surveyresults';
        });
        formattedPayload.data = data;
      }
      return formattedPayload;
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      item.attributes.schema = this.transformSchema(item.attributes.schema);
      if (item.attributes.icon) {
        item.attributes.iconUrl = item.attributes.icon;
      }
      item.attributes.type = item.attributes.type || 'default';
      return item;
    }
  });
  _exports.default = _default;
});