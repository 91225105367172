define("experience-studio/templates/components/role-permission-select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RlnIZYxC",
    "block": "{\"symbols\":[\"permission\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-select dropdown-toggle\"],[24,4,\"button\"],[4,[38,8],[[32,0],\"toggleDropDown\"],null],[12],[2,\"\\n  \"],[1,[34,9]],[2,\" selected\\n\"],[13],[2,\"\\n\\n\"],[6,[37,12],null,[[\"target\",\"targetAttachment\",\"attachment\",\"constraints\",\"class\"],[[35,11],\"bottom left\",\"top left\",[35,10],\"role-permission-select-dropdown dropdown\"]],[[\"default\"],[{\"statements\":[[10,\"div\"],[15,0,[31,[\"dropdown-menu dropdown-menu-tethered \",[34,3],\"\\n        \",[30,[36,2],[[35,1],\"dropdown-visible\"],null]]]],[15,5,[34,4]],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"permission\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"role-permission-select-dropdown-item\",\"isDropDownVisible\",\"if\",\"menuClass\",\"menuWidthStyle\",\"permissions\",\"-track-array\",\"each\",\"action\",\"selectedCount\",\"dropdownTeatherConstraints\",\"buttonNode\",\"ember-tether\"]}",
    "moduleName": "experience-studio/templates/components/role-permission-select-dropdown.hbs"
  });
  _exports.default = _default;
});