define("experience-studio/components/route-model-table-user", ["exports", "experience-studio/components/route-model-table-entity"], function (_exports, _routeModelTableEntity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelTableEntity.default.extend({
    classNames: ['route-model-table-user'],
    utility: Ember.inject.service(),
    model: null,
    headers: null,
    isLoading: null,
    data: null,
    searchKeyword: Ember.computed('data', function () {
      var keyWord = '';
      if (this.utility.atob(this.data) && this.utility.atob(this.data).searchKeyword) {
        keyWord = this.utility.atob(this.data).searchKeyword;
      }
      return keyWord;
    }),
    didInsertElement: function didInsertElement() {
      this.sendAction('didUpdateSearchKeyword', {
        keyword: this.searchKeyword
      }); // eslint-disable-line ember/closure-actions
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('headers', [Ember.Object.create({
        label: 'Name',
        isSelected: false,
        class: 'name-col',
        isHidden: false,
        tooltip: 'The name of this User.'
      }), Ember.Object.create({
        label: 'Email',
        sortProperty: 'email',
        isSelected: false,
        class: 'email-col',
        isHidden: false,
        tooltip: 'The Email of User.'
      }), Ember.Object.create({
        label: 'Access Level',
        sortProperty: 'accessLevel.level',
        isSelected: false,
        class: 'access-level-col',
        isHidden: false,
        tooltip: 'Access Level of User'
      }), Ember.Object.create({
        label: 'Date Created',
        sortProperty: 'createdAt',
        isSelected: false,
        class: 'date-col',
        isHidden: false,
        tooltip: "The date column."
      })]);
    },
    actions: {
      performModelAction: function performModelAction(params) {
        this.performModelAction(params);
      }
    }
  });
  _exports.default = _default;
});