define("experience-studio/components/send-invite-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['send-invite-form'],
    accessLevels: null,
    errorMessage: null,
    selectedAccessLevel: null,
    model: null,
    modalData: null,
    utility: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var accessLevels = [Ember.Object.create({
        role: 'App User',
        level: 1
      }), Ember.Object.create({
        role: 'Viewer',
        level: 5
      }), Ember.Object.create({
        role: 'Creator',
        level: 10
      }), Ember.Object.create({
        role: 'Publisher',
        level: 15
      }), Ember.Object.create({
        role: 'Permission Manager',
        level: 17
      }), Ember.Object.create({
        role: 'Owner',
        level: 20
      })];
      this.set('accessLevels', accessLevels);
      this.set('selectedAccessLevel', Ember.Object.create({
        role: 'Owner',
        level: 20
      }));
    },
    actions: {
      selectValue: function selectValue(item) {
        this.init();
        this.set('selectedAccessLevel', item);
      },
      confirmSendInvitation: function confirmSendInvitation() {
        var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

        if (Ember.isEmpty(this.model.email)) {
          this.set('errorMessage', 'Enter email.');
        } else if (!this.model.email.match(emailRegEx)) {
          this.set('errorMessage', 'You have entered an invalid email address.');
        } else {
          this.set('errorMessage', '');
          this.set('modalData', Ember.Object.create({
            primaryActionName: 'sendInvitation',
            title: 'Confirm Invitation',
            primaryMessage: 'Please confirm the following email before sending this invitation.',
            secondaryMessage: "<strong>".concat(this.model.email, "</strong>"),
            isSecondaryMessageHTML: true
          }));
        }
      },
      sendInvitation: function sendInvitation() {
        var _this = this;
        var defaultSelected = Ember.Object.create({
          role: 'Owner',
          level: 20,
          id: 5
        });
        var model = this.model;
        this.set('modalData', null);
        model.set('permission', this.selectedAccessLevel);
        model.save().then(function () {
          _this.sendAction('onSuccess', model); // eslint-disable-line ember/closure-actions
          _this.set('selectedAccessLevel', defaultSelected);
        }).catch(function (response) {
          var message = _this.get('utility').parseError(response);
          _this.set('errorMessage', message);
          _this.set('selectedAccessLevel', defaultSelected);
        });
      }
    }
  });
  _exports.default = _default;
});