define("experience-studio/components/av5-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /*
  
    Description:
  
      Responsible for selection and listing of pages given a set of records and a
      page size.
  
    Usages:
  
      <ModelPagination
        records=records
        selectePage=selectedPage
        didSelectPage=(action "didSelectPage")
      />
  
      <ModelPagination
        records=records
        selectedPage=selectedPage
        recordsPerPage=recordsPerPage
        didSelectPage=(action "didSelectPage")
      />
  
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-pagination', 'pagination'],
    pageDisplayLimit: 3,
    records: null,
    limit: 5,
    offset: 0,
    totalRecords: 0,
    tagName: 'ul',
    // COMPUTED ------------------------------------------------------------------
    isClientSidePagination: Ember.computed('records', function () {
      if (this.records === null || this.records === undefined) {
        return false;
      }
      return true;
    }),
    pages: Ember.computed('limit', 'totalRecords', 'records', function () {
      var _this = this;
      var numPages;
      if (this.isClientSidePagination) {
        numPages = Math.ceil(this.records.length / this.limit);
      } else {
        numPages = Math.ceil(this.totalRecords / this.limit);
      }
      return Array.from({
        length: numPages
      }, function (_, k) {
        return Ember.Object.create({
          number: k + 1,
          isSelected: k + 1 === _this.selectedPage
        });
      });
    }),
    selectedPage: Ember.computed('offset', function () {
      return this.offset / this.limit + 1;
    }),
    currentPageSet: Ember.computed('selectedPage', 'pages', function () {
      var _this$getDelimiters = this.getDelimiters(),
        _this$getDelimiters2 = _slicedToArray(_this$getDelimiters, 2),
        startDelimiter = _this$getDelimiters2[0],
        endDelimiter = _this$getDelimiters2[1];
      return this.pages.slice(startDelimiter, endDelimiter);
    }),
    nextPageSet: Ember.computed('selectedPage', 'pages', function () {
      var startDelimiter = this.getDelimiters()[1];
      return this.pages.slice(startDelimiter);
    }),
    previousPageSet: Ember.computed('selectedPage', 'pages', function () {
      var endDelimiter = this.getDelimiters()[0];
      return this.pages.slice(0, endDelimiter);
    }),
    showLessPrevPages: Ember.computed('previousPageSet.[]', function () {
      return this.previousPageSet.length > 2;
    }),
    showLessNextPages: Ember.computed('nextPageSet.[]', function () {
      return this.nextPageSet.length > 2;
    }),
    // OBSERVERS -----------------------------------------------------------------
    didPageChange: Ember.observer('selectedPage', function () {
      var _this2 = this;
      this.pages.forEach(function (page) {
        if (page.number === _this2.selectedPage) {
          page.set('isSelected', true);
        } else {
          page.set('isSelected', false);
        }
      });
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
    },
    // FUNCTIONS -----------------------------------------------------------------
    setPageSelection: function setPageSelection(pageNum) {
      this.setProperties({
        offset: (pageNum - 1) * this.limit
      });
    },
    getDelimiters: function getDelimiters() {
      var pageIndex = this.selectedPage - 1;
      var pagePadding = Math.floor(this.pageDisplayLimit / 2);
      var startDelimiter;
      if (pageIndex < this.pageDisplayLimit - 1) {
        startDelimiter = 0;
      } else if (pageIndex > this.pages.length - this.pageDisplayLimit) {
        startDelimiter = this.pages.length - this.pageDisplayLimit;
      } else {
        startDelimiter = pageIndex - pagePadding;
      }
      var endDelimiter = startDelimiter + this.pageDisplayLimit;
      return [startDelimiter, endDelimiter];
    },
    updatePageSelection: function updatePageSelection(pageNum) {
      this.setPageSelection(pageNum);
      if (this.didSelectPage) {
        this.didSelectPage();
      }
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      selectNextPage: function selectNextPage() {
        if (this.selectedPage + 1 <= this.pages.length && this.didSelectPage) {
          this.updatePageSelection(this.selectedPage + 1);
        }
      },
      selectPage: function selectPage(page) {
        this.updatePageSelection(page.number);
      },
      selectPreviousPage: function selectPreviousPage() {
        if (this.selectedPage > 1 && this.didSelectPage) {
          this.updatePageSelection(this.selectedPage - 1);
        }
      }
    }
  });
  _exports.default = _default;
});