define("experience-studio/components/content-search-scroll-select-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-search-scroll-select-list'],
    searchKeyword: '',
    listStateIndicator: Ember.computed('searchKeyword', 'modelProperties.isLoading', 'isTyping', function () {
      if (this.isTyping || this.get('modelProperties.isLoading')) {
        return 'Loading...';
      } else if (this.searchKeyword) {
        return "".concat(this.get('modelProperties.totalRecords'), " Search results");
      } else {
        return 'All Content';
      }
    }),
    actions: {
      handleAction: function handleAction(action, model) {
        switch (action) {
          case 'select':
            if (Ember.isArray(model)) {
              model = model[0];
            }
            this.send('selectModelListItems', model, this.isMultiSelect);
            break;
        }
      },
      selectModelListItems: function selectModelListItems(model, isMultiSelect) {
        var selectedModels = this.selectedModels;
        var models = this.model;
        if (model.get('isSelected')) {
          model.set('isSelected', false);
          selectedModels.removeObject(model);
        } else if (!isMultiSelect) {
          models.setEach('isSelected', false);
          model.set('isSelected', true);
          selectedModels.setEach('isSelected', false);
          selectedModels.clear();
          selectedModels.pushObject(model);
        } else {
          model.set('isSelected', true);
          selectedModels.pushObject(model);
        }
      },
      onTyping: function onTyping(isTyping) {
        this.set('isTyping', isTyping);
      },
      handleSearch: function handleSearch(searchKeyword) {
        this.set('modelProperties.searchKeyword', searchKeyword);
        this.sendAction('handleSearch', this.modelProperties, this.modelProperties.modelName); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels() {
        this.sendAction('loadModels', this.modelProperties.modelName, this.modelProperties); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});