define("experience-studio/components/contextual-schedule-form", ["exports", "experience-studio/mixins/time-conversion"], function (_exports, _timeConversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = Ember.Component.extend(_timeConversion.default, {
    // PROPERTIES
    classNames: ['contextual-schedule-form'],
    timeDelay: Ember.Object.create({
      param: 'seconds',
      val: 0
    }),
    selectedPeriod: Ember.Object.create({
      name: 'Seconds',
      unit: 'seconds'
    }),
    _selectedRule: null,
    // OBSERVERS ---------------------------------------------------------------
    observeNumberOfMinutes: Ember.observer('selectedRule.schedule.numberOfMinutes', function () {
      var seconds = this.get('selectedRule.schedule.numberOfMinutes');
      var convertedTime = this.convertSecondsToPeriodObject(seconds);
      this.timeDelay.setProperties({
        param: convertedTime.unit,
        val: convertedTime.value
      });
      var selPeriod = this.periodSelectors.filterBy('unit', convertedTime.unit).objectAt(0);
      this.set('selectedPeriod', Ember.Object.create({
        name: selPeriod.name,
        unit: selPeriod.unit
      }));
    }),
    // COMPUTED ------------------------------------------------------------------
    errorMessages: Ember.computed('isCTXScheduleValid', 'selectedPlugin', 'selectedAttribute', function () {
      var pluginError;
      var attrError;
      if (Ember.isEqual(this.isCTXScheduleValid, false)) {
        if (!this.selectedPlugin) {
          pluginError = 'select a plugin.';
        }
        if (!this.selectedAttribute) {
          attrError = 'Select an attribute.';
        }
        return Ember.Object.create({
          pluginError: pluginError,
          attrError: attrError
        });
      } else {
        return Ember.Object.create({});
      }
    }),
    /**
     * This is a proxy for 2-way-binding to set the numberOfMinutes of
     * the selectedRule object.
     */
    contextualTime: Ember.computed('timeDelay.{param,val}', 'selectedRule', {
      get: function get() {
        return this.timeDelay.val;
      },
      set: function set(key, value) {
        var period = this.get('selectedPeriod');
        var seconds = this.convertToSeconds(value, period.unit);
        this.selectedRule.schedule.set('numberOfMinutes', seconds);
        return value;
      }
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.set('temporalSelections', [Ember.Object.create({
        name: 'Before',
        operator: '-'
      }), Ember.Object.create({
        name: 'After',
        operator: '+'
      })]);
      this.set('periodSelectors', [Ember.Object.create({
        name: 'Second(s)',
        unit: 'seconds'
      }), Ember.Object.create({
        name: 'Minute(s)',
        unit: 'minutes'
      }), Ember.Object.create({
        name: 'Hour(s)',
        unit: 'hours'
      }), Ember.Object.create({
        name: 'Day(s)',
        unit: 'days'
      }), Ember.Object.create({
        name: 'Week(s)',
        unit: 'weeks'
      }), Ember.Object.create({
        name: 'Month(s)',
        unit: 'months'
      }), Ember.Object.create({
        name: 'Year(s)',
        unit: 'years'
      })]);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (Ember.isArray(this.selectedRules)) {
        this.set('selectedRule', this.selectedRules[0]);
      }
      if (this.get('selectedRule.schedule') && !this.get('selectedRule.schedule.numberOfMinutes')) {
        this.selectedRule.schedule.set('numberOfMinutes', 1);
        this.send('selectOperator', this.temporalSelections[0]);
      }
      this.set('filteredPlugins', this.plugins.filter(function (plugin) {
        if (!Ember.isEmpty(plugin.attributesArray)) {
          var hasDate = plugin.attributesArray.filterBy('isTimeContext', true);
          return hasDate.length > 0;
        }
      }));
      if (this.selectedRule && this.selectedRule.hasContextual) {
        // eslint-disable-line max-len
        this.send('deriveContextualOptions', this.selectedRule);
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.get('selectedRule.schedule') && this.get('selectedRule.schedule.numberOfMinutes')) {
        var seconds = this.get('selectedRule.schedule.numberOfMinutes');
        var convertedTime = this.convertSecondsToPeriodObject(seconds);
        this.timeDelay.setProperties({
          param: convertedTime.unit,
          val: convertedTime.value
        });
        var selPeriod = this.periodSelectors.filterBy('unit', convertedTime.unit).objectAt(0);
        this.set('selectedPeriod', selPeriod);
        this.selectedRule.schedule.set('numberOfMinutes', seconds);
      }
    },
    actions: {
      validateNumberInput: function validateNumberInput(value, event) {
        if (!value && event.key === '0' || event.key === '-' || event.key === '.' || event.key === 'e') {
          event.preventDefault();
        }
      },
      deriveContextualOptions: function deriveContextualOptions(rule) {
        var _rule$schedule$startS = rule.schedule.startScheduler.contextualStart.split(' '),
          _rule$schedule$startS2 = _slicedToArray(_rule$schedule$startS, 3),
          startPlugin = _rule$schedule$startS2[0],
          startOperator = _rule$schedule$startS2[1],
          startLength = _rule$schedule$startS2[2];
        var endLength = rule.schedule.endScheduler.contextualStart.split(' ')[2];
        if (startOperator) {
          this.send('selectOperator', this.temporalSelections[0]);
        } else {
          this.send('selectOperator', this.temporalSelections[1]);
        }
        if (startLength) {
          rule.schedule.set('numberOfMinutes', parseInt(startLength));
        } else if (endLength) {
          rule.schedule.set('numberOfMinutes', parseInt(endLength));
        }
        var selectedPlugin = this.filteredPlugins.filter(function (pluginObject) {
          return startPlugin.indexOf(pluginObject.uid) >= 0;
        });
        this.send('selectPlugin', selectedPlugin[0]);
        var selectedAttribute = this.selectedPlugin.attributesArray.filterBy('uid', startPlugin);
        this.send('selectAttribute', selectedAttribute[0]);
      },
      onInputFocusRuleEdit: function onInputFocusRuleEdit(className) {
        var _this = this;
        if (Ember.isEqual(this._focusedInput, className)) return;
        var el = document.querySelector(".".concat(className));
        this.editRuleAttributesCB(
        // success callback (duplicate, edit anyways)
        function () {
          _this.set('_focusedInput', className);
          el.addEventListener('blur', function () {
            _this.set('_focusedInput', undefined);
          });
          el.focus();
        },
        // cancel callback (cancel)
        function () {
          el.blur();
        });
      },
      /**
       * Select the option from the select after rule conflict is handled, if present.
       * @param {string} key
       * @param {string} value
       */
      selectValueConflictRule: function selectValueConflictRule(key, value) {
        var _this2 = this;
        var fn = function fn() {
          switch (key) {
            case 'plugin':
              _this2.send('selectPlugin', value);
              break;
            case 'operator':
              _this2.send('selectOperator', value);
              break;
            case 'attribute':
              _this2.send('selectAttribute', value);
              break;
            case 'period':
              _this2.send('selectPeriod', value);
              break;
            default:
              break;
          }
        };
        if (this.editRuleAttributesCB) {
          this.editRuleAttributesCB(function () {
            fn();
          });
        } else {
          fn();
        }
      },
      selectPeriod: function selectPeriod(period) {
        var selPeriod = this.periodSelectors.filterBy('unit', period.unit).objectAt(0);
        this.set('selectedPeriod', selPeriod);
        var time = this.timeDelay.val;
        var seconds = this.convertToSeconds(time, period.unit);
        this.selectedRule.schedule.set('numberOfMinutes', seconds);
        this.timeDelay.setProperties({
          param: period.unit,
          val: time
        });
      },
      selectOperator: function selectOperator(operator) {
        this.set('selectedOperator', operator);
        this.send('selectContextualOption', 'selectedOperator', operator);
        this.selectedRule.schedule.set('numberOfMinutesOperator', operator.operator);
      },
      selectPlugin: function selectPlugin(plugin) {
        this.set('selectedPlugin', plugin);
        this.send('selectContextualOption', 'selectedPlugin', plugin);
        this.set('filteredAttributes', plugin.attributesArray.filterBy('isTimeContext', true));
        this.set('selectedAttribute', '');
        this.send('selectContextualOption', 'selectedAttribute', null);
      },
      selectAttribute: function selectAttribute(attribute) {
        this.set('selectedAttribute', attribute);
        this.send('selectContextualOption', 'selectedAttribute', attribute);
        this.selectedRule.get('schedule').set('selectedPluginAttribute', attribute);
      },
      selectContextualOption: function selectContextualOption(key, value) {
        if (!Ember.isEmpty(this.contextualOptions)) {
          this.set("contextualOptions.".concat(key), value);
        }
      }
    }
  });
  _exports.default = _default;
});