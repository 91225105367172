define("experience-studio/components/content-tmp-edit-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-phone
      field=field
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-phone'],
    valueProxy: Ember.computed('field.value', function () {
      return this.field.value;
    }),
    didValueChange: Ember.observer('valueProxy', function () {
      Ember.run.debounce(this, this.setValue, 400);
    }),
    setValue: function setValue() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.set('field.value', this.valueProxy);
      }
    }
  });
  _exports.default = _default;
});