define("experience-studio/routes/content-hub", ["exports", "experience-studio/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // controller.toggleProperty('isRefreshFilterModel');
      controller.setProperties({
        isShowUploadModal: false,
        isSearchFormHidden: false,
        isInfoSidebarOpen: false,
        selectedEntities: []
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      // Handle necessary deselection here.
      // This function must exist
      deselectModelListItems: function deselectModelListItems() {},
      changeTab: function changeTab() {
        this.controller.set('searchKeyword', '');
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.refresh();
      },
      removeSearchKeyword: function removeSearchKeyword() {
        this.controller.set('searchKeyword', '');
      }
    }
  });
  _exports.default = _default;
});