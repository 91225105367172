define("experience-studio/templates/context-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G3pMDHPs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"context-hub-route\"],[12],[2,\"\\n\"],[6,[37,15],null,[[\"deselectModelListItems\",\"didUpdateSearchKeyword\",\"searchKeyword\",\"headerTitle\",\"isSearchFormHidden\",\"headerToolbarActions\",\"isInfoSidebarOpen\",\"mainHeaderTabs\",\"selectedEntities\",\"performAction\",\"sideBarTitle\"],[\"deselectModelListItems\",\"didUpdateSearchKeyword\",[35,14],\"Context Hub\",[35,13],[35,12],[35,11],[35,10],[35,9],[30,[36,8],[\"performAction\"],null],\"Rule\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,16],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"onClose\",\"clickOutsideToClose\",\"containerClassNames\",\"translucentOverlay\"],[\"hideUploadModal\",true,[35,4],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,3],null,[[\"didUpload\",\"isPickerVisible\",\"isPrivate\",\"isUploading\"],[\"didUpload\",[35,2,[\"isPickerVisible\"]],true,[35,2,[\"isUploading\"]]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,16],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"model\",\"cancel\",\"simulate\"],[[35,0],\"hideSimulationModal\",\"simulate\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"simulationModalData\",\"simulation-modal-dialog\",\"uploadModalData\",\"all-upload\",\"classNames\",\"modal-dialog\",\"-outlet\",\"component\",\"route-action\",\"selectedEntities\",\"mainHeaderTabs\",\"isInfoSidebarOpen\",\"toolbarActions\",\"isSearchFormHidden\",\"searchKeyword\",\"main-header-info-sidebar\",\"if\"]}",
    "moduleName": "experience-studio/templates/context-hub.hbs"
  });
  _exports.default = _default;
});