define("experience-studio/routes/experiences-v2", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-experiences", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-experience", "experience-studio/mixins/model-actions"], function (_exports, _authenticatedRoute, _routeDataQueryParam, _routeModelBatchActionsBase, _routeModelBatchActionsExperiences, _routeModelTableActionsBase, _routeModelTableActionsExperience, _modelActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeDataQueryParam.default, _modelActions.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsExperiences.default, _routeModelTableActionsBase.default, _routeModelTableActionsExperience.default, {
    storage: Ember.inject.service(),
    utility: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      if (params.data) {
        params = this.utility.atob(params.data);
      }
      return Ember.RSVP.hash({
        experiences: store.query('experience', {
          page: params.currentPage || params.page,
          recordsPerPage: params.recordsPerPage,
          selectedGroup: params.selectedGroup,
          sortby: params.sortBy,
          sortorder: params.sortOrder,
          groupId: params.groupId,
          search: params.searchKeyword,
          visibility: params.groupId ? 'all' : '',
          isActive: params.isActive,
          createdAtFrom: params.createdAtFrom,
          createdAtTo: params.createdAtTo,
          labels: params.labels
        }),
        rules: store.query('rule', {
          status: 'production'
        }),
        plugins: store.findAll('plugin'),
        groups: store.query('group', {
          sort: 'priority'
        }),
        languages: store.queryRecord('project-config', {
          setting: 'language'
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.groups.setEach('isSelected', false);
        controller.setProperties({
          _data: '',
          data: '',
          isInfoSidebarOpen: false,
          selectedGroup: null,
          groupId: '',
          searchKeyword: '',
          sortBy: '',
          sortOrder: '',
          page: 1,
          recordsPerPage: 12,
          visibility: '',
          isActive: '',
          createdAtFrom: '',
          createdAtTo: '',
          labels: ''
        });
        this.send('deselectModelListItems');
      }
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (this.storage.getItem('isExperiencesListView') === 'false' && transition.targetName === 'experiences') {
        this.transitionTo('experiences', {
          queryParams: {
            groupId: this.controller.groupId
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var isRouteListView = this.userPreferences.getValue('routeListView');
      controller.setProperties({
        selectedEntities: [],
        isRouteListView: isRouteListView
      });
      controller.toggleProperty('isRefreshFilterModel');
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createEntity: function createEntity() {
        this.send('createRecord', {
          route: 'experiences.create'
        });
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.controller.toggleProperty('isRefreshFilterModel');
        this.refresh();
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
        this.send('aggregateModel', 'experience', 'experiences');
      },
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      changeToCardView: function changeToCardView(routeName) {
        this.storage.setItem('isExperiencesListView', false);
        this.transitionTo(routeName, {
          queryParams: {
            groupId: this.controller.groupId,
            selectedGroup: this.controller.selectedGroup,
            searchKeyword: this.controller.searchKeyword,
            sortBy: this.controller.sortBy,
            sortOrder: this.controller.sortOrder,
            page: this.controller.page
          }
        });
      }
    }
  });
  _exports.default = _default;
});