define("experience-studio/models/push-message", ["exports", "ember-data", "experience-studio/mixins/recently-modified-item", "experience-studio/mixins/time-conversion"], function (_exports, _emberData, _recentlyModifiedItem, _timeConversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend(_recentlyModifiedItem.default, _timeConversion.default, {
    isActive: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isAlert: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isDuplicate: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isSent: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isDraft: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    hasMessageInterval: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    type: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    pushTypeId: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    body: _emberData.default.attr(),
    customFields: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return Ember.Object.create();
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    message: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return Ember.Object.create();
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    options: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return Ember.Object.create();
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    title: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return Ember.Object.create();
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    executionHistory: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return Ember.Object.create({});
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces

    experiences: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    rules: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    subscriptions: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    tags: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    labels: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces

    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    isHighLightItem: Ember.computed.alias('recentlyModified'),
    schedule: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return Ember.Object.create();
      }
    }),
    isBroadcast: Ember.computed('type', function () {
      return Ember.isEqual(this.type, 'broadcast');
    }),
    isTriggered: Ember.computed('type', function () {
      return Ember.isEqual(this.type, 'triggered');
    }),
    deliveryType: Ember.computed('type', function () {
      return Ember.isEqual(this.type, 'broadcast') ? 'Broadcast' : 'Triggered';
    }),
    activeSchedule: Ember.computed('computedRules.@each.{isFulfilled,content.schedule.startScheduler.start}', 'schedule', 'status', 'schedule.scheduler.start', function () {
      var startDate = this.get('schedule.scheduler.start');
      return !this.checkIfTimezoneExpired(startDate) ? startDate : 0;
    }),
    inactiveSchedule: Ember.computed('schedule', 'status', 'schedule.scheduler.start', function () {
      var startDate = this.get('schedule.scheduler.start');
      return this.checkIfTimezoneExpired(startDate) ? startDate : 0;
    }),
    activeEndSchedule: Ember.computed('schedule', 'status', 'schedule.scheduler.end', function () {
      var endDate = this.get('schedule.scheduler.end');
      return !this.checkIfTimezoneExpired(endDate) ? endDate : 0;
    }),
    inactiveEndSchedule: Ember.computed('schedule', 'status', 'schedule.scheduler.end', function () {
      var endDate = this.get('schedule.scheduler.end');
      return this.checkIfTimezoneExpired(endDate) ? endDate : 0;
    }),
    status: Ember.computed('isActive', 'isSent', 'isDraft', 'type', 'activeSchedule', function () {
      var status = '';
      var schedule = this.activeSchedule;
      if (schedule) {
        status = 'Scheduled';
      }
      if (this.isBroadcast && !this.isSent && this.inactiveSchedule || this.isTriggered && !this.isActive && this.inactiveSchedule) {
        status = 'Expired';
      }
      if (this.isBroadcast && this.isSent) {
        status = 'Sent';
      }
      if (this.isBroadcast && !this.isSent && !schedule) {
        status = 'Ready to Send';
      }
      if (this.isTriggered) {
        status = this.isActive ? 'Active' : 'Inactive';
        if (schedule && !this.isActive) {
          status = 'Scheduled';
        }
      }
      if (this.isDraft) {
        status = 'Draft';
      }
      return status;
    }),
    getComputedRule: Ember.computed('computedRules', function () {
      var rules = this.computedRules;
      if (!Ember.isEmpty(rules)) {
        for (var i = 0; i < rules.length; i++) {
          var rule = rules[i];
          if (rule.isFulfilled) {
            return rule.content;
          }
        }
      }
    }),
    computedRules: Ember.computed('rules.[]', function () {
      var _this = this;
      var associatedRules = this.rules;
      var rules = [];
      if (!Ember.isEmpty(associatedRules)) {
        associatedRules.forEach(function (rule) {
          return rules.pushObject(_this.store.findRecord('rule', rule.ruleID));
        });
      }
      return rules;
    }),
    timeZone: Ember.computed('computedRules.@each.isFulfilled', 'schedule.scheduler.timezone', function () {
      var timezone = null;
      if (!Ember.isEmpty(this.computedRules) && !this.isBroadcast) {
        for (var i = 0; i < this.computedRules.length; i++) {
          var rule = this.computedRules[i];
          if (rule.isFulfilled && rule.content.schedule && rule.content.schedule.startScheduler.timezone) {
            // eslint-disable-line max-len
            timezone = rule.content.schedule.startScheduler.timezone.name;
            break;
          }
        }
      }
      if (this.get('schedule.scheduler.timezone')) {
        timezone = "(UTC".concat(this.schedule.scheduler.timezone.offset > 0 ? '+' : '').concat(this.schedule.scheduler.timezone.offset, ") ").concat(this.schedule.scheduler.timezone.name);
      }
      return timezone;
    }),
    executionHistoryTimeZone: Ember.computed('executionHistory.timezone.name', function () {
      var execTimezone = '';
      if (this.get('executionHistory.timezone.name')) {
        var zone = this.get('executionHistory.timezone.name').replace(/_/g, ' ');
        var offset = parseInt(window.moment.tz(zone).format('Z'));
        execTimezone = "(UTC".concat(offset > 0 ? '+' : '').concat(offset, ") ").concat(this.executionHistory.timezone.name);
      } else {
        var myZone = window.moment.tz.guess().replace(/_/g, ' ');
        var timeZones = window.moment.tz.names();
        var _offset = 0;
        if (myZone) {
          timeZones.filter(function (zone) {
            return zone === myZone;
          }).forEach(function (zone) {
            zone = zone.replace(/_/g, ' ');
            _offset = parseInt(window.moment.tz(zone).format('Z'));
            return execTimezone = "(UTC".concat(_offset > 0 ? '+' : '').concat(_offset, ") ").concat(zone);
          });
        }
      }
      return execTimezone;
    }),
    isLocationContent: Ember.computed('pushTypeId', function () {
      return this.get('pushTypeId') === 'LocationContent';
    }),
    locationContentId: Ember.computed('body', function () {
      if (!this.body) return;
      return this.body[0].payload.data.contentBlueprintId && this.body[0].payload.data.contentBlueprintId.value;
    }),
    associatedRuleId: Ember.computed('rules.[]', function () {
      if (this.rules.length) {
        return this.rules[0].ruleID;
      } else {
        return null;
      }
    }),
    removeSchedule: function removeSchedule() {
      this.set('schedule', null);
    },
    // do not reset key 'limit', this lives separately
    resetOptions: function resetOptions() {
      this.options.setProperties({
        max: 0,
        delay: 0,
        cooldown: 0,
        sampling: 0
      });
      this.set('hasMessageInterval', false);
    },
    checkIfTimezoneExpired: function checkIfTimezoneExpired(checkDate) {
      var selectedTimeZone = this.get('schedule.scheduler.timezone.name');
      if (selectedTimeZone) {
        selectedTimeZone = selectedTimeZone.replace(/ /g, '_');
      }
      if (checkDate) {
        var checkDateUnix = parseInt(checkDate / 1000);
        var scheduledTime = window.moment.unix(checkDateUnix).format('MMMM Do YYYY, h:mm:ss a');
        var currentTime = window.moment.tz(selectedTimeZone).format('MMMM Do YYYY, h:mm:ss a');
        if (scheduledTime < currentTime) {
          return true;
        }
      }
      return false;
    }
  });
  _exports.default = _default;
});