define("experience-studio/services/session", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    isAuthenticated: false,
    storage: Ember.inject.service(),
    store: Ember.inject.service(),
    token: '',
    _getTokenInfo: function _getTokenInfo(key) {
      var tokenData = this.decodeToken(this.token);
      return tokenData && tokenData[key] ? tokenData[key] : undefined;
    },
    tenantId: Ember.computed('token', function () {
      return this._getTokenInfo('tenantId');
    }),
    userID: Ember.computed('token', function () {
      return this._getTokenInfo('userID');
    }),
    user: Ember.computed('token', function () {
      return this.store.queryRecord('user', {
        isSignedInUser: true
      });
    }),
    userAgent: Ember.computed(function () {
      var data = {
        deviceType: 'Desktop',
        make: 'Chrome',
        model: '58.0.3006.0',
        osVersion: 'macOS 10.12.3',
        physicalDeviceId: 'PHYSICAL-DEVICE-ID',
        sdkVersion: '1.0.0'
      };
      return JSON.stringify(data);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var isAuthenticated = false;
      var token = this.storage.getItem('token');
      if (token) {
        isAuthenticated = true;
      }
      this.setProperties({
        isAuthenticated: isAuthenticated,
        token: token
      });
    },
    authenticate: function authenticate() {
      var host = window.location.host;
      var projects = _environment.default.APP.thirdPartySignInProjects;
      var externalAuthenticationUrls = _environment.default.APP.externalAuthenticationUrls;
      if (externalAuthenticationUrls && externalAuthenticationUrls[host]) {
        window.location.href = externalAuthenticationUrls[host];
        return;
      } else if (projects && Array.isArray(projects) && projects.includes(host)) {
        window.location.href = "".concat(_environment.default.APP.hostUrl, "/sso/auth/saml/login?subdomain=").concat(host);
        return;
      }
      window.location.href = _environment.default.APP.authenticationUrl;
    },
    authorize: function authorize(data, isCircumventStorage) {
      this.setProperties({
        isAuthenticated: true,
        token: data.token
      });
      if (!isCircumventStorage) {
        this.storage.setItem('token', data.token);
      }
    },
    decodeToken: function decodeToken(token) {
      var result;
      var decode = function decode(string) {
        return decodeURIComponent(window.Array.prototype.map.call(window.atob(string), function (c) {
          return "%".concat("00".concat(c.charCodeAt(0).toString(16)).slice(-2));
        }).join(''));
      };
      try {
        result = JSON.parse(decode(token.split('.')[1]));
      } catch (exception) {
        /* TODO: Handle exception */
      }
      return result;
    },
    unauthenticate: function unauthenticate() {
      this.unauthorize();
      window.location.href = "".concat(_environment.default.APP.authenticationUrl, "/app/signout");
    },
    unauthorize: function unauthorize() {
      this.setProperties({
        isAuthenticated: false,
        token: ''
      });
      this.storage.removeItem('token');
    },
    validate: function validate(transition) {
      var self = this;
      var def = Ember.RSVP.defer();
      this.user.then(function () {
        def.resolve();
      }).catch(function () {
        transition.abort();
        self.authenticate(transition);
        def.reject();
      });
      return def.promise;
    }
  });
  _exports.default = _default;
});