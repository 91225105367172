define("experience-studio/components/title-description-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['title-description-select-option'],
    actions: {
      selectOption: function selectOption(option) {
        this.get('select.actions').select(option);
        this.get('select.actions').close();
      }
    }
  });
  _exports.default = _default;
});