define("experience-studio/components/content-form-editor-droppable-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-form-editor-droppable-area'],
    classNameBindings: ['isDragging'],
    dragEnter: function dragEnter() {},
    dragExit: function dragExit() {},
    dragLeave: function dragLeave() {},
    dragOver: function dragOver() {
      return false;
    },
    drop: function drop(event) {
      var data = event.dataTransfer.getData('text');
      if (!Ember.isEmpty(data)) {
        this.sendAction('onDrop', data); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});