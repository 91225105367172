define("experience-studio/templates/components/content-tmp-edit-geoshape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o4NdSpcB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"map-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"search-container\"],[12],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"onInput\",\"value\",\"placeholder\"],[\"handleSearch\",[35,6],\"Search for an address\"]]]],[2,\"\\n\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[16,\"disabled\",[31,[[30,[36,1],[[30,[36,5],[[35,0]],null],\"disabled\"],null]]]],[24,4,\"button\"],[4,[38,2],[[32,0],\"resetShape\"],null],[12],[2,\"Reset Shape\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"loading-message\"],[12],[2,\"\\n\"],[6,[37,1],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        Detecting current location...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Loading map...\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"map-buttons-container\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-sm btn-secondary\"],[16,\"disabled\",[31,[[30,[36,1],[[35,0],\"disabled\"],null]]]],[24,4,\"button\"],[4,[38,2],[[32,0],\"createShape\"],null],[12],[2,\"Create Shape\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"map\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"polygon\",\"if\",\"action\",\"unless\",\"isDetectingLocation\",\"not\",\"searchKeywordAddress\",\"search-form-group\",\"isMapLoading\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-geoshape.hbs"
  });
  _exports.default = _default;
});