define("experience-studio/components/device-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    deviceType: 'ios',
    // 'ios', 'android', 'code'
    actions: {
      selectPreview: function selectPreview(type) {
        this.set('deviceType', type);
      }
    }
  });
  _exports.default = _default;
});