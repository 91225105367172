define("experience-studio/components/location-view-info", ["exports", "jquery", "experience-studio/config/environment", "experience-studio/helpers/location"], function (_exports, _jquery, _environment, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['location-view-info'],
    classNameBindings: ['isSmallFormFactor'],
    model: null,
    store: Ember.inject.service(),
    creator: '',
    mapImageUrl: Ember.computed('model.{isFulfilled,shape.[]}', function () {
      var coordinates = [];
      var endpoint = 'maps/api/staticmap';
      var host = 'https://maps.googleapis.com';
      try {
        var model = null;
        var shape = null;
        if (this.get('model.content')) {
          model = this.get('model.content');
        } else if (this.model) {
          model = this.model;
        }
        if (model && model.shape) {
          shape = model.shape;
        }
        if (shape) {
          shape.forEach(function (coordinate) {
            return coordinates.push("".concat(coordinate.lat, ",").concat(coordinate.lng));
          });
        }
      } catch (exception) {
        // TODO: Show exception
      }
      var query = {
        key: _environment.default.APP.googleMapKey,
        path: "color:0x29BFEDFF|weight:2|".concat(coordinates.join('|')),
        size: '200x200',
        scale: 2
      };
      return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query));
    }),
    mapImageCircleUrl: Ember.computed('model.{isFulfilled,shape.[]}', function () {
      var model = null;
      var shape = null;
      var approxAreaRadius = 3000; //metres

      if (this.get('model.content')) {
        model = this.get('model.content');
      } else if (this.model) {
        model = this.model;
      }
      if (this.get('model.shape')) {
        shape = model.shape;
      }
      var shapeCenter = (0, _location.calculateCenter)(shape);
      var lat = shapeCenter.lat;
      var lng = shapeCenter.lng;
      var radius = shapeCenter.radius + approxAreaRadius;
      var endpoint = 'maps/api/staticmap';
      var host = 'https://maps.googleapis.com';
      var query = {
        key: _environment.default.APP.googleMapKey,
        path: "color:0x29BFEDFF|weight:0|fillcolor:0x29BFED63".concat((0, _location.calculateCircleData)(lat, lng, radius)),
        size: '300x300'
      };
      return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query));
    })
  });
  _exports.default = _default;
});