define("experience-studio/mixins/localized-image-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    isLanguagesVisible: true,
    languages: [],
    model: null,
    value: Ember.computed.alias('modelSham.value'),
    key: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var languages = this.languages;
      if (!this.selectedLanguageCode && !Ember.isEmpty(languages)) {
        this.send('selectLanguage', languages.findBy('isDefault', true));
      }
    },
    modelSham: Ember.computed('model', 'selectedLanguageCode', function () {
      var languageCode = this.selectedLanguageCode;
      var modelSham;
      var key = this.key;
      if (languageCode) {
        modelSham = Ember.Object.extend({
          value: Ember.computed.alias("model.".concat(languageCode, ".").concat(key))
        }).create({
          model: this.model
        });
      } else {
        modelSham = Ember.Object.create({});
      }
      return modelSham;
    }),
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    actions: {
      selectLanguage: function selectLanguage(language) {
        this.languages.setEach('isSelected', false);
        language.set('isSelected', true);
      }
    }
  });
  _exports.default = _default;
});