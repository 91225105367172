define("experience-studio/components/content-tmp-edit-date-timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-date-timezone
      field=field
      pickerOpts=flatPickrOptions
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-date'],
    availableTimezones: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    pickerOpts: {},
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    timezone: null,
    setModelDate: function setModelDate(dateStr) {
      if (!dateStr && this.get('field.value')) {
        this.set('field.value', {
          date: '',
          epoch: 0,
          timezone: this.timezone
        });
      } else if (dateStr && dateStr !== '') {
        var epoch = window.moment(dateStr).unix();
        dateStr = window.moment.tz(dateStr, this.timezone).format();
        this.set('field.value', {
          date: dateStr,
          epoch: epoch,
          timezone: this.timezone
        });
      }
    },
    initTimezone: function initTimezone() {
      if (this.get('field.value') && this.get('field.value').timezone) {
        this.set('timezone', this.get('field.value').timezone);
      } else {
        this.set('timezone', window.moment.tz.guess());
        this.set('field.value', {
          date: '',
          epoch: 0,
          timezone: this.timezone
        });
      }
    },
    dateUpdated: Ember.observer('field.value', function () {
      var pickerInput = this.get('picker.input');
      if (pickerInput) {
        var pickerVal = this.get('picker.input').value;
        if (pickerVal !== this.get('field.value').date) {
          if (this.get('field.value') && !this.get('field.value').date) {
            this.picker.clear();
          } else {
            this.picker.setDate(this.get('field.value').date, false);
          }
        }
      }
    }),
    timezoneUpdated: Ember.observer('timezone', function () {
      if (this.get('field.value')) {
        var dateStr = this.get('field.value').date;
        this.setModelDate(dateStr);
      }
    }),
    didInsertElement: function didInsertElement() {
      var view = this;
      var dateInput = this.$('.date-field');
      var tzStrs = window.moment.tz.names();
      this.set('availableTimezones', tzStrs);
      this.initTimezone();
      // eslint-disable-next-line
      var picker = new window.flatpickr(dateInput.get(0), {
        enableTime: true,
        onChange: function onChange(selectedDates, dateStr) {
          view.setModelDate(dateStr);
        }
      });
      this.set('picker', picker);
      if (picker && this.get('field.value') && this.get('field.value').date) {
        picker.setDate(this.get('field.value').date, false);
      }
    },
    actions: {
      selectValue: function selectValue(model, variable, selection) {
        if (model) {
          model.set(variable, selection);
        } else {
          this.set(variable, selection);
        }
      }
    }
  });
  _exports.default = _default;
});