define("experience-studio/components/route-model-table-rule", ["exports", "experience-studio/components/route-model-table-entity"], function (_exports, _routeModelTableEntity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelTableEntity.default.extend({
    classNames: ['route-model-table-rule'],
    utility: Ember.inject.service(),
    data: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('headers', [Ember.Object.create({
        label: 'Name',
        sortProperty: 'name',
        isSelected: false,
        class: 'name-col',
        isHidden: false,
        tooltip: 'The name of this Context Rule.'
      }), Ember.Object.create({
        label: 'Status',
        sortProperty: 'isDraft',
        isSelected: false,
        class: 'status-col',
        isHidden: false,
        tooltip: "A Rule in 'draft' mode can be used to run an audience simulation,\n                  but cannot be used in an active Experience until its status is changed\n                  to 'production'. A Rule in 'production' mode can be used to run an\n                  audience simulation or added to an Experience."
      }), Ember.Object.create({
        label: 'Last Modified',
        isSelected: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'The date this Context Rule was last modified on.'
      }), Ember.Object.create({
        label: 'Date Created',
        sortProperty: 'createdAt',
        isSelected: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'The date this Context Rule was created on.'
      })]);
    }
  });
  _exports.default = _default;
});