define("experience-studio/mixins/unauthenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var session = this.session;
      if (session.get('isAuthenticated')) {
        return this.transitionTo('index');
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
  _exports.default = _default;
});