define("experience-studio/templates/components/content-tmp-edit-colour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FwQk0HCz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[14,0,\"color-preview data-field\"],[14,4,\"text\"],[12],[13],[2,\"\\n\"],[1,[30,[36,1],null,[[\"class\",\"type\",\"placeholder\",\"value\"],[\"hex-field data-field\",\"text\",[35,0,[\"placeholder\"]],[35,0,[\"value\"]]]]]]],\"hasEval\":false,\"upvars\":[\"field\",\"input\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-colour.hbs"
  });
  _exports.default = _default;
});