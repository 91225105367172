define("experience-studio/routes/context-hub", ["exports", "jquery", "experience-studio/mixins/authenticated-route", "experience-studio/config/environment"], function (_exports, _jquery, _authenticatedRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var viewType = this.userPreferences.getValue('routeListView');
      controller.setProperties({
        viewType: viewType,
        isShowUploadModal: false,
        isSearchFormHidden: false,
        isInfoSidebarOpen: false,
        selectedEntities: []
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      changeTab: function changeTab() {
        this.controller.set('searchKeyword', '');
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.refresh();
      },
      removeSearchKeyword: function removeSearchKeyword() {
        this.controller.set('searchKeyword', '');
      },
      updateSearchKeyword: function updateSearchKeyword(searchKeyword) {
        this.controller.set('searchKeyword', searchKeyword);
      },
      didUpload: function didUpload(response) {
        var _this = this;
        var file = null;
        var self = this;
        var uploadModalData = this.controller.get('uploadModalData');
        if (!Ember.isEmpty(response) && Array.isArray(response)) {
          file = Ember.Object.create(response.objectAt(0));
        }
        if (file) {
          uploadModalData.setProperties({
            errorMessage: null,
            isSubmitted: false,
            isSubmitting: true
          });
          _jquery.default.ajax({
            contentType: 'application/json',
            data: JSON.stringify({
              fileId: file.get('id')
            }),
            headers: {
              'X-Authorization': this.get('session.token'),
              'X-User-Agent': this.get('session.userAgent')
            },
            type: 'POST',
            url: "".concat(_environment.default.APP.hostUrl, "/context/ctxdataimport/files/import")
          }).then(function () {
            Ember.run.scheduleOnce('afterRender', _this, function () {
              uploadModalData.setProperties({
                isSubmitted: true,
                isSubmitting: false
              });
            });
          }).catch(function (response) {
            uploadModalData.set('errorMessage', self.get('utility').parseError(response));
          }).always(function () {
            uploadModalData.set('isSubmitting', false);
          });
        } else {
          var message = 'The file could not be uploaded successfully.';
          uploadModalData.set('errorMessage', message);
        }
      },
      editRecord: function editRecord(record) {
        if (record.get('isDraft')) {
          this._super.apply(this, arguments);
        } else {
          this.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: 'A production rule cannot be edited.',
            title: "Error",
            type: 'danger'
          }));
        }
      },
      upload: function upload() {
        var uploadModalData = this.controller.get('uploadModalData');
        if (!uploadModalData.get('isSubmitting') && !uploadModalData.get('isUploading')) {
          uploadModalData.setProperties({
            errorMessage: null
          });
          uploadModalData.toggleProperty('isPickerVisible');
        }
      }
    }
  });
  _exports.default = _default;
});