define("experience-studio/components/route-model-table-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-table-dropdown', 'dropdown'],
    constraints: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    {
      to: 'window',
      attachment: 'together'
    }],
    targetElement: null,
    isOpen: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        menuClass: "dropdown-menu-".concat(this.elementId)
      });
    },
    isMultiSelected: Ember.computed('selectedEntities.[]', function () {
      return this.get('selectedEntities.length') > 1;
    }),
    toggleTooltip: function toggleTooltip() {
      if (this.isMultiSelected) {
        this.set('multiSelectInfoText', 'Cannot access dropdown for this entity when multiple elements are selected');
        (0, _jquery.default)(".tooltip-wrapper").tooltip();
      } else {
        (0, _jquery.default)(".tooltip-wrapper").tooltip('dispose');
      }
    },
    tooltipObserve: Ember.observer('isMultiSelected', function () {
      Ember.run.scheduleOnce('afterRender', this, this.toggleTooltip);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setProperties({
        targetElement: this.element.querySelectorAll('.btn-model-action-dropdown')[0]
      });
      this.clickOutsideSetup();
    },
    willDestroyElement: function willDestroyElement() {
      this.clickOutsideTeardown();
    },
    actions: {
      toggleDropdown: function toggleDropdown() {
        if (this.isOpen) {
          this.unstructureSubMenus();
          this.set('isOpen', false);
        } else if (!this.isMultiSelected) {
          this.set('isOpen', true);
          Ember.run.scheduleOnce('afterRender', this, this.structureSubMenus);
        }
      }
    },
    closeDropdown: function closeDropdown() {
      this.set('isOpen', false);
    },
    clickOutsideSetup: function clickOutsideSetup() {
      var _this = this;
      var documentElement = (0, _jquery.default)(document);
      var componentElement = (0, _jquery.default)(this.element);

      // Close dropdown on click outside
      documentElement.on("click.".concat(this.elementId), function (event) {
        if (!(0, _jquery.default)(event.target).closest(componentElement).length) {
          _this.closeDropdown();
        }
      });

      // Close dropdown on parent scroll
      componentElement.closest('.internal-model-list-container').on("scroll.".concat(this.elementId), function () {
        return _this.closeDropdown();
      });

      // Close dropdown when another dropdown is opened
      documentElement.on("show.bs.dropdown.".concat(this.elementId), function () {
        return _this.closeDropdown();
      });
    },
    clickOutsideTeardown: function clickOutsideTeardown() {
      var documentElement = (0, _jquery.default)(document);
      var componentElement = (0, _jquery.default)(this.element);
      documentElement.off("click.".concat(this.elementId));
      componentElement.closest('.internal-model-list-container').off("scroll.".concat(this.elementId));
      documentElement.off("show.bs.dropdown.".concat(this.elementId));
    },
    structureSubMenus: function structureSubMenus() {
      var rootMenu = (0, _jquery.default)(".".concat(this.menuClass));
      this.structureSubMenusHelper(rootMenu);
    },
    unstructureSubMenus: function unstructureSubMenus() {
      var rootMenu = (0, _jquery.default)(".".concat(this.menuClass));
      this.structureSubMenusHelper(rootMenu);
    },
    unstructureSubMenusHelper: function unstructureSubMenusHelper(menu) {
      var menuChildren = menu.children();
      for (var i = 0; i < menuChildren.length; i++) {
        var item = (0, _jquery.default)(menuChildren[i]);
        var subMenu = item.children('.dropdown-menu')[0];
        item.off('mouseenter');
        if (subMenu) {
          this.unstructureSubMenusHelper(subMenu);
        }
      }
    },
    structureSubMenusHelper: function structureSubMenusHelper(menu) {
      var _this2 = this;
      var menuChildren = menu.children();
      var _loop = function _loop(i) {
        var item = (0, _jquery.default)(menuChildren[i]);
        var subMenu = item.children('.dropdown-menu')[0];
        (0, _jquery.default)(item).addClass('pull-left');
        (0, _jquery.default)(item).addClass('pull-down');
        if (subMenu) {
          item.addClass('action-dropdown-submenu');
          item.on('mouseenter', function () {
            var rightReferencePoint = subMenu.getBoundingClientRect().right;
            var leftReferencePoint = subMenu.getBoundingClientRect().left;
            var bottomReferencePoint = subMenu.getBoundingClientRect().bottom;
            var topReferencePoint = subMenu.getBoundingClientRect().top;
            if ((0, _jquery.default)(window).height() < bottomReferencePoint || (0, _jquery.default)(menu).parent().hasClass('pull-up')) {
              (0, _jquery.default)(item).removeClass('pull-down');
              (0, _jquery.default)(item).addClass('pull-up');
            } else if (0 > topReferencePoint || (0, _jquery.default)(menu).parent().hasClass('pull-down')) {
              (0, _jquery.default)(item).removeClass('pull-up');
              (0, _jquery.default)(item).addClass('pull-down');
            }
            if ((0, _jquery.default)(window).width() < rightReferencePoint || (0, _jquery.default)(menu).parent().hasClass('pull-left')) {
              (0, _jquery.default)(item).removeClass('pull-right');
              (0, _jquery.default)(item).addClass('pull-left');
            } else if (0 > leftReferencePoint || (0, _jquery.default)(menu).parent().hasClass('pull-right')) {
              (0, _jquery.default)(item).removeClass('pull-left');
              (0, _jquery.default)(item).addClass('pull-right');
            }
          });
          _this2.structureSubMenusHelper((0, _jquery.default)(subMenu));
        }
      };
      for (var i = 0; i < menuChildren.length; i++) {
        _loop(i);
      }
    }
  });
  _exports.default = _default;
});