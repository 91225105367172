define("experience-studio/components/custom-field--timepicker", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    date: '',
    didInsertElement: function didInsertElement() {
      this.set('date', this.get('field.value'));
    },
    dateValueObserver: Ember.observer('date', function () {
      this.send('onValue', this.date);
    })
  });
  _exports.default = _default;
});