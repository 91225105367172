define("experience-studio/templates/components/flight-sidebar-quick-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nNTEBDqV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-primary btn-new dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,2],null,[[\"item\"],[[30,[36,1],[\"flight-sidebar-dropdown-item\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"quick-access-name\",\"component\",\"hash\"]}",
    "moduleName": "experience-studio/templates/components/flight-sidebar-quick-access.hbs"
  });
  _exports.default = _default;
});