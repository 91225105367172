define("experience-studio/templates/no-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pmYJQs3R",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"no-access-route\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"texts\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Access Denied\"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"english\"],[12],[2,\"\\n      You do not have enough permissions to access Experience Studio. Please\\n      \"],[10,\"strong\"],[12],[2,\"contact an administrator\"],[13],[2,\".\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"french\"],[12],[2,\"\\n    Vous ne disposez pas des autorisations suffisantes pour accéder à Experience\\n    Studio. Veuillez \"],[10,\"strong\"],[12],[2,\"contacter un administrateur\"],[13],[2,\".\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "experience-studio/templates/no-access.hbs"
  });
  _exports.default = _default;
});