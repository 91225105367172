define("experience-studio/templates/authorize/saml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PzMVaz+Q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"authorize-saml-route\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"texts\"],[12],[2,\"\\n    Redirecting...\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "experience-studio/templates/authorize/saml.hbs"
  });
  _exports.default = _default;
});