define("experience-studio/models/content-instance", ["exports", "ember-data", "experience-studio/mixins/recently-modified-item"], function (_exports, _emberData, _recentlyModifiedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend(_recentlyModifiedItem.default, {
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    accessType: _emberData.default.attr('string'),
    isContextLocked: _emberData.default.attr('boolean'),
    isPartiallyLoaded: _emberData.default.attr('boolean'),
    iconUrl: _emberData.default.attr('string'),
    priorityIndex: _emberData.default.attr('number'),
    templateType: _emberData.default.attr('string'),
    contentTemplateId: _emberData.default.attr('string'),
    contentTemplateType: _emberData.default.attr('string'),
    dataId: _emberData.default.attr('string'),
    root: _emberData.default.attr(),
    content: _emberData.default.attr(),
    associatedContentInstanceIds: _emberData.default.attr(),
    labels: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // eslint-disable-line block-spacing, brace-style, max-len, no-multi-spaces
    linkedFields: _emberData.default.attr(),
    localizations: _emberData.default.attr(),
    values: _emberData.default.attr(),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    // Computed Properties
    isHighLightItem: Ember.computed('recentlyModified', function () {
      return this.recentlyModified;
    }),
    computedContentTemplate: Ember.computed('contentTemplateId', function () {
      var id = this.contentTemplateId;
      if (id) {
        return this.store.findRecord('content-template', id);
      }
    })
  });
  _exports.default = _default;
});