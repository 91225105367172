define("experience-studio/components/confirm-push-schedule-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    A confirm modal component.
    When sending a push that already has a schedule,
    user can either choose to remove a schedule and/or send now.
  
    Usages:
  
      <ConfirmPushScheduleModalDialog
        model=model
      />
  */
  var _default = _baseModalDialog.default.extend({
    utility: Ember.inject.service(),
    store: Ember.inject.service(),
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['confirm-push-schedule-modal-dialog'],
    // COMPUTED ------------------------------------------------------------------
    scheduledPushStartDate: Ember.computed('isScheduled', function () {
      var date = null;
      if (this.isScheduled.time) {
        date = window.moment(this.isScheduled.time).format('MMMM Do, YYYY [at] hh:mma');
      }
      return date;
    }),
    isExpired: Ember.computed('isScheduled', function () {
      var expired = false;
      if (this.isScheduled.time && this.isScheduled.time < window.moment().unix() * 1000) {
        expired = true;
      }
      return expired;
    }),
    isScheduled: Ember.computed('model.computedRules.@each.isFulfilled', 'model.record.{isSent,isBroadcast,schedule.scheduler.start}', function () {
      // eslint-disable-line ember/use-brace-expansion
      var isScheduled = {
        time: false,
        zone: ''
      };
      var rules = this.model.record.computedRules;
      if (!this.model.record.isBroadcast && !Ember.isEmpty(rules)) {
        for (var i = 0; i < rules.length; i++) {
          var rule = rules[i];
          if (rule.isFulfilled && rule.content.schedule && rule.content.schedule.startScheduler && rule.content.schedule.startScheduler.start > 0) {
            return {
              time: rule.content.schedule.startScheduler.start,
              zone: rule.content.schedule.startScheduler.timezone.name
            };
          }
        }
        return isScheduled;
      }
      if (this.model.record.isBroadcast && this.model.record && this.model.record.schedule && this.model.record.schedule.scheduler && this.model.record.schedule.scheduler.start > 0) {
        return {
          time: this.model.record.schedule.scheduler.start,
          zone: this.model.record.schedule.scheduler.timezone.name
        };
      } else {
        return isScheduled;
      }
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
    },
    // FUNCTIONS -----------------------------------------------------------------
    close: function close() {
      this.willCloseModal();
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      clearErrorMessage: function clearErrorMessage() {},
      // Send Now
      send: function send() {
        this.performAction({
          action: 'broadcastRecord',
          record: this.model.record
        });
        this.close();
      },
      removeSchedule: function removeSchedule(model) {
        this.performAction({
          action: 'removeSchedule',
          record: model.record
        });
        this.close();
      }
    }
  });
  _exports.default = _default;
});