define("experience-studio/routes/settings/general", ["exports", "experience-studio/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return this.store.findAll('user-preference');
    },
    afterModel: function afterModel(model) {
      var preferences = model.filterBy('id', 'userPreferencePage');
      if (Ember.isEmpty(model) || Ember.isEmpty(preferences)) {
        this.transitionTo('experiences');
      }
    }
  });
  _exports.default = _default;
});