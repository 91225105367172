define("experience-studio/inline/google-maps", [], function () {
  "use strict";

  /* eslint-disable */

  (function (w, d, s) {
    try {
      var appName = 'experience-studio';
      var config = JSON.parse(decodeURIComponent(document.querySelector('meta[name="' + appName + '/config/environment"]').getAttribute('content')));
      var key = config.APP.googleMapKey;
      var verison = config.APP.googleMapVersion;
      if (key) {
        var firstScriptElement = d.getElementsByTagName(s)[0];
        var googleMapsScriptElement = d.createElement(s);
        var queryParams = 'key=' + key + '&v=' + verison + '&libraries=drawing,geometry,places';
        googleMapsScriptElement.async = true;
        googleMapsScriptElement.defer = true;
        googleMapsScriptElement.src = 'https://maps.googleapis.com/maps/api/js?' + queryParams;
        firstScriptElement.parentNode.insertBefore(googleMapsScriptElement, firstScriptElement);
      }
    } catch (exception) {
      console.log('Failed to load Google Maps'); // eslint-disable-line
    }
  })(window, document, 'script');
});