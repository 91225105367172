define("experience-studio/components/info-sidebar-group", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar-group'],
    model: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    experiencesSham: Ember.computed('model.experiences', function () {
      var experiences = this.model.experiences.map(function (item) {
        item.displayName = item.localizations.en.name;
        return item;
      });
      return experiences;
    }),
    didRender: function didRender() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip('dispose');
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});