define("experience-studio/routes/content-hub/blueprint-instance", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    userPreferences: Ember.inject.service(),
    isRichTextEnabled: false,
    beforeModel: function beforeModel() {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      if (this.userPreferences.getValue('richTextFormatting')) {
        this.set('isRichTextEnabled', this.userPreferences.getValue('richTextFormatting'));
      } else if (isNonTdOrUsEnv) {
        this.set('isRichTextEnabled', true);
      } else {
        this.set('isRichTextEnabled', window.localStorage.getItem('isRichTextEnabled'));
      }
    },
    model: function model(params) {
      return this.store.findRecord('content-blueprint', params.blueprint_instance_id);
    },
    afterModel: function afterModel(model) {
      this.transitionTo('content-hub.blueprint-instance.edit', model);
    }
  });
  _exports.default = _default;
});