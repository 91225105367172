define("experience-studio/routes/content-hub/content-instance/edit-v2", ["exports", "jquery", "experience-studio/mixins/field-validator-factory", "experience-studio/config/environment"], function (_exports, _jquery, _fieldValidatorFactory, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = Ember.Route.extend(_fieldValidatorFactory.default, {
    utility: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    contextRuleUtility: Ember.inject.service(),
    payload: '',
    isNearbyLocationTemplate: false,
    templatePrototypeId: '',
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    parentWindowListener: function parentWindowListener(e) {
      var command = e.data.command;
      if (command === 'sendProps') {
        this.set('payload', e.data.payload);
        this.set('templatePrototypeId', e.data.templatePrototypeId);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      window.addEventListener('message', this.parentWindowListener.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('message', this.parentWindowListener);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var nearbyLocation = transition.to.queryParams.nearbyLocation;
      this.isNearbyLocationTemplate = nearbyLocation === 'true';
    },
    model: function model() {
      var contentInstance = this.modelFor('content-hub.content-instance');
      // overwrite below values with data sent from iframe.
      contentInstance.name = this.payload.title;
      contentInstance.description = this.payload.description;
      contentInstance.iconUrl = this.payload.iconUrl;
      contentInstance.isContextLocked = this.payload.isContextLocked;
      contentInstance.labels = this.payload.labels;
      contentInstance.isNearbyLocationTemplate = this.isNearbyLocationTemplate;
      return Ember.RSVP.hash({
        contentInstance: contentInstance,
        languages: this.store.queryRecord('project-config', {
          setting: 'language'
        }),
        plugins: this.store.findAll('plugin'),
        projectCustomTemplates: this.store.queryRecord('project-config', {
          setting: 'project-custom-content-templates'
        })
      });
    },
    afterModel: function afterModel(model) {
      var isConciergeTemplate = false;
      if (model.projectCustomTemplates && model.projectCustomTemplates.config && model.projectCustomTemplates.config.categories) {
        var categories = model.projectCustomTemplates.config.categories;
        isConciergeTemplate = categories.length > 0 && this.contextRuleUtility.isConciergeTemplate(model.contentInstance.templateType);
      }
      model.contentInstance.set('isConciergeTemplate', isConciergeTemplate);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var model = this.controller.get('model');
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: this.payload.title,
        description: this.payload.description,
        image: this.payload.iconUrl,
        locked: this.payload.isContextLocked,
        data: model.get('root').toData().root,
        selectedLabels: this.payload.labels
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var previousRouteName = this._router.getPreviousRouteName();
      if (previousRouteName) {
        this.transitionTo(previousRouteName);
      } else if (this.controller.get('previousTransition')) {
        this.controller.get('previousTransition').retry();
      }
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        finishedLoading: false,
        languages: model.languages.config,
        plugins: model.plugins,
        selectedLabels: [],
        transitionConfirmed: false,
        displayFooter: true
      });
      controller.set('initialHash', this.utility.hash({
        name: this.payload.title,
        description: this.payload.description,
        image: this.payload.iconUrl,
        locked: this.payload.isContextLocked,
        data: model.contentInstance.get('content').data[0],
        selectedLabels: this.payload.labels
      }));
      if (!model.contentTemplate) {
        this.store.findRecord('content-template', model.contentInstance.contentTemplateId).then(function (respo) {
          Ember.set(model, 'contentTemplate', respo);
          controller.set('finishedLoading', true);
        });
      } else {
        controller.set('finishedLoading', true);
      }
      this._super(controller, model);
    },
    checkDetailsData: function checkDetailsData(data) {
      if (this.currentModel.contentInstance.isConciergeTemplate) {
        if (data.root.link && data.root.link.actionType === 'LINK') {
          data.root.details = {};
        }
        if (data.root.buttons) {
          var buttonList = data.root.buttons;
          var isDetailOptionSelected = false;
          for (var count = 0; count < buttonList.length; count++) {
            if (buttonList[count].actionType === 'OPEN_DETAIL') {
              isDetailOptionSelected = true;
            }
          }
          if (!isDetailOptionSelected) {
            data.root.details = {};
          }
        }
      }
      return data;
    },
    actions: {
      displayFooter: function displayFooter() {
        var isFooterDisplayed = this.controller.get('displayFooter');
        this.controller.set('displayFooter', isFooterDisplayed ? false : true);
      },
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        this.controller.model.contentInstance.rollbackAttributes();
        this.goBack();
        this.send('hideModal');
        parent.postMessage({
          command: "cancelled"
        }, '*');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        this.send('cancel');
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      save: function save() {
        var _this = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var model = controller.model;
        var data = this.checkDetailsData(model.contentInstance.get('root').toData());
        var type = model.contentInstance.get('values') ? 'PUT' : 'POST';
        var selectedLabels = this.controller.get('selectedLabels');
        var url = "".concat(_environment.default.APP.hostUrl, "/kernel/content/instances/").concat(model.contentInstance.get('id'), "/data");

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          // Assign labels in general to model
          model.contentInstance.set('labels', _this.payload.labels);
          if (_this.isNearbyLocationTemplate) {
            return model.contentInstance;
          } else {
            if (type === 'PUT') {
              url = "".concat(url, "/").concat(model.contentInstance.get('dataId'));
            }
            if (_this.templatePrototypeId) {
              url = "".concat(url, "?templatePrototypeId=").concat(_this.templatePrototypeId);
            }
            return new Promise(function (resolve, reject) {
              _jquery.default.ajax({
                contentType: 'application/json',
                data: JSON.stringify(data.root),
                headers: {
                  'X-Authorization': _this.get('session.token'),
                  'X-User-Agent': _this.get('session.userAgent')
                },
                type: type,
                url: url
              }).done(function (response) {
                resolve(response);
              }).fail(function (jqXHR) {
                reject(jqXHR);
              });
            });
          }
        }).then(function () {
          if (_this.isNearbyLocationTemplate) {
            return model.contentInstance;
          } else {
            return model.contentInstance.save();
          }
        }).then(function () {
          _this.controller.set('transitionConfirmed', true);
          _this.goBack();
          Ember.run.scheduleOnce('afterRender', _this, function () {
            var jsonModel = model.contentInstance.toJSON();
            jsonModel.data = data.root;
            jsonModel.templateId = jsonModel.contentTemplateId;
            parent.postMessage({
              command: "success",
              id: model.contentInstance.get('id'),
              content: jsonModel
            }, '*');
            _this.send('showNotification', {
              type: 'confirm',
              message: "Changes made to \"".concat(model.contentInstance.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', _this.utility.parseError(response));
        });
      },
      validate: function validate() {
        var _this$currentModel, _this$currentModel$co;
        var controller = this.controller;
        var model = Ember.Object.create(controller.get('model'));
        if (this.isNearbyLocationTemplate) {
          model.setProperties({
            schema: model.schema.filter(function (field) {
              return field.type !== 'ctlocation';
            })
          });
        }
        if ((_this$currentModel = this.currentModel) !== null && _this$currentModel !== void 0 && (_this$currentModel$co = _this$currentModel.contentInstance) !== null && _this$currentModel$co !== void 0 && _this$currentModel$co.isConciergeTemplate) {
          var _model$contentInstanc;
          this.send('populateConceirgeTemplateImage', model === null || model === void 0 ? void 0 : (_model$contentInstanc = model.contentInstance) === null || _model$contentInstanc === void 0 ? void 0 : _model$contentInstanc.root);
        }
        var validator = _fieldValidatorFactory.default.getValidator(model.contentInstance.root, model.languages.config);
        var validation = validator.validate({
          triggerCascade: true
        });
        if (!Ember.isEmpty(controller.get('model.iconUrl')) && !controller.get('model.iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(controller.get('model.contentInstance.name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else if (!validation.state) {
          var plural = validation.errors.length > 1 ? 's' : '';
          controller.set('errorMessage', "You have (".concat(validation.errors.length, ") unresolved error").concat(plural, "."));
        } else {
          controller.set('errorMessage', '');
          this.send('save');
        }
      },
      populateConceirgeTemplateImage: function populateConceirgeTemplateImage(model) {
        var _model$schema, _model$schema2, _mediaField$schema, _mediaField$schema$fi;
        var mediaTypeImageMap = {
          image: 'resourceURL',
          video: 'previewImgURL'
        };
        var imageField = model === null || model === void 0 ? void 0 : (_model$schema = model.schema) === null || _model$schema === void 0 ? void 0 : _model$schema.find(function (field) {
          return field.type === 'ctlocalizedimage';
        });
        var mediaField = model === null || model === void 0 ? void 0 : (_model$schema2 = model.schema) === null || _model$schema2 === void 0 ? void 0 : _model$schema2.find(function (field) {
          return field.type === 'ctlocalizedmedia';
        });
        var mediaType = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema = mediaField.schema) === null || _mediaField$schema === void 0 ? void 0 : (_mediaField$schema$fi = _mediaField$schema.find(function (field) {
          return field.key === 'mediaType';
        })) === null || _mediaField$schema$fi === void 0 ? void 0 : _mediaField$schema$fi.value;
        if (imageField && mediaType) {
          var _mediaField$schema2, _mediaField$schema2$f;
          var imageURL = {};
          var mediaURL = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema2 = mediaField.schema) === null || _mediaField$schema2 === void 0 ? void 0 : (_mediaField$schema2$f = _mediaField$schema2.find(function (field) {
            return field.key === mediaTypeImageMap[mediaType];
          })) === null || _mediaField$schema2$f === void 0 ? void 0 : _mediaField$schema2$f.value;
          for (var lang in mediaURL) {
            imageURL[lang] = _defineProperty({}, imageField.key, mediaURL[lang][mediaTypeImageMap[mediaType]]);
          }
          Ember.set(imageField, 'value', imageURL);
        } else if (imageField && mediaField) {
          Ember.set(imageField, 'value', null);
        }
      },
      willTransition: function willTransition() {
        var controller = this.controller;
        var model = controller.model;
        if (!model.contentInstance.get('isSaving') && !model.contentInstance.get('isDeleted')) {
          // model.contentInstance.reload();
        }
        controller.setProperties({
          errorMessage: '',
          previousTransition: null
        });
        return true;
      }
    }
  });
  _exports.default = _default;
});