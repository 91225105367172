define("experience-studio/components/title-description-select-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['title-description-select-option'],
    didReceiveAttrs: function didReceiveAttrs() {
      var select = this.get('parentView.select');
      this.setProperties({
        numOptions: select.options.length,
        disabled: select.disabled
      });
    }
  });
  _exports.default = _default;
});