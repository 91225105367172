define("experience-studio/components/push-message-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-message-status'],
    statusClassName: Ember.computed('model.status', function () {
      return Ember.String.dasherize("is-".concat(this.model.status));
    }),
    statusIconName: Ember.computed('model.status', function () {
      switch (this.model.status) {
        case 'Active':
          return 'circle-solid';
        case 'Expired':
          return 'schedule';
        case 'Inactive':
          return 'circle-solid';
        case 'Ready to Send':
          return 'circle-solid';
        case 'Scheduled':
          return 'schedule';
        case 'Sent':
          return 'check';
        case 'Draft':
          return 'isDraft';
        default:
          return null;
      }
    })
  });
  _exports.default = _default;
});