define("experience-studio/templates/components/localized-text-dynamic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XNJiR1Ra",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,1],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"language-list list-unstyled\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[15,0,[31,[[30,[36,1],[[32,1,[\"isSelected\"]],\"active\",\"\"],null]]]],[12],[2,\"\\n          \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"selectLanguage\",[32,1]],null],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[6,[37,1],[[32,1,[\"isDefault\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"localized-text\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"no-value-message\"],[12],[2,\"The value is not set.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"if\",\"action\",\"languages\",\"-track-array\",\"each\",\"isLanguagesVisible\"]}",
    "moduleName": "experience-studio/templates/components/localized-text-dynamic.hbs"
  });
  _exports.default = _default;
});