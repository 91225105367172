define("experience-studio/components/push-messages-table-row", ["exports", "jquery", "experience-studio/components/model-table-row"], function (_exports, _jquery, _modelTableRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    Description:
      Shows a row values for list of push messages
  
    Usages:
      {{push-messages-table-row
        model=pushMessage
        dispatchAction='dispatchAction'
        isMultiSelect=isMultiSelect
        showCheckbox=showCheckbox
        isHighLightItem=pushMessage.isHighLightItem
      }}
  */
  var _default = _modelTableRow.default.extend({
    // PROPERTIES ----------------------------------------------------------------
    store: Ember.inject.service(),
    classNames: ['push-messages-table-row'],
    classNameBindings: ['model.isSelected:is-selected'],
    isDropDownVisible: false,
    userPreferences: Ember.inject.service(),
    dropdownTeatherConstraints: [
    // eslint-disable-line
    {
      to: 'window',
      attachment: 'together'
    }],
    // COMPUTED ------------------------------------------------------------------
    isViewAnalyticsVisible: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('analyticsPageV2');
    }),
    actionOptionNode: Ember.computed(function () {
      return this.$('.dropdown-more')[0];
    }),
    type: Ember.computed('model.type', function () {
      return this.model.type === 'broadcast' ? 'Broadcast' : 'Triggered';
    }),
    isManual: Ember.computed('type', function () {
      return this.model.type === 'broadcast';
    }),
    createdAt: Ember.computed('model.createdAt', function () {
      return window.moment(this.get('model.createdAt')).fromNow();
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.initializeTooltips();
    },
    // FUNCTIONS -----------------------------------------------------------------
    initializeTooltips: function initializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    },
    closeDropDown: function closeDropDown() {
      if (this.isDropDownVisible) {
        this.set('isDropDownVisible', false);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(document).on("click.".concat(this.elementId), function (event) {
        if (!(0, _jquery.default)(event.target).closest(_this.$('.dropdown-more')).length) {
          _this.closeDropDown();
        }
      });
      (0, _jquery.default)(document).on("show.bs.dropdown.".concat(this.elementId), function () {
        _this.closeDropDown(); // eslint-disable-line ember/jquery-ember-run
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.uninitializeTooltips();
      (0, _jquery.default)(document).off("click.".concat(this.elementId));
      (0, _jquery.default)(document).off("show.bs.dropdown.".concat(this.elementId));
    },
    // ACTIONS -----------------------------------------------------------------
    actions: {
      activation: function activation() {
        this.send('dispatchAction', 'activation', this.model); // eslint-disable-line ember/closure-actions
      },
      duplicate: function duplicate() {
        this.send('dispatchAction', 'duplicate', this.model, {
          routeName: 'communications-hub.push-messages-v2'
        }); // eslint-disable-line ember/closure-actions
      },
      edit: function edit() {
        this.send('dispatchAction', 'edit', this.model, {
          // eslint-disable-line ember/closure-actions
          routeName: 'communications-hub.push-messages-v2.edit'
        });
      },
      info: function info() {
        this.send('dispatchAction', 'show-info', this.model, {
          // eslint-disable-line ember/closure-actions
          controller: 'communicationsHubController',
          selectedEntitiesPath: 'communicationsHubController.selectedEntities'
        });
      },
      send: function send() {
        this.send('dispatchAction', 'send', this.model); // eslint-disable-line ember/closure-actions
      },
      toggleDropDown: function toggleDropDown() {
        this.toggleProperty('isDropDownVisible');
      }
    }
  });
  _exports.default = _default;
});