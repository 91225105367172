define("experience-studio/components/push-messages-create--code-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-messages-create--code-preview'],
    languages: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    model: null,
    localizedItem: Ember.computed('languages.@each.isSelected', function () {
      var language = this.languages.findBy('isSelected', true);
      var modelSham;
      if (language) {
        modelSham = Ember.Object.extend({
          message: Ember.computed.alias("model.message.".concat(language.get('id'))),
          title: Ember.computed.alias("model.title.".concat(language.get('id')))
        }).create({
          model: this.model
        });
      } else {
        modelSham = Ember.Object.create({});
      }
      return modelSham;
    }),
    payloadJSON: Ember.computed('fields.@each.{value,isValid}', 'localizedItem.{message,title}', function () {
      var data = '';
      var payload = Ember.Object.create({});
      var fields = this.fields || [];
      var body = {};

      /* Hack needed to detect if message or title is changed */
      this.get('localizedItem.message');
      this.get('localizedItem.title');
      fields.forEach(function (field) {
        var value = field.get('value');
        if (field.isValid && !Ember.isEmpty(value)) {
          body[field.key] = value;
        }
      });
      var pushPayloadTypeId = this.get('model.pushTypeId');
      if (pushPayloadTypeId && pushPayloadTypeId !== 'Basic') {
        body['pushPayloadTypeId'] = pushPayloadTypeId;
      }
      payload.set('action', 'custom');
      payload.set('title', this.get('model.title'));
      payload.set('alert', this.get('model.message'));
      payload.set('body', body);
      try {
        data = JSON.stringify(payload, null, 2);
      } catch (exception) {
        /* TODO: Handle exception */
      }
      return data;
    })
  });
  _exports.default = _default;
});