define("experience-studio/components/route-model-filter-dropdown-region-select", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown-model-select', 'route-model-filter-dropdown-region-select', 'dropdown-item', 'dropleft', 'dropdown-submenu'],
    classNameBindings: ['isDisabled:disabled'],
    model: null,
    page: 1,
    recordsPerPage: 15,
    searchKeyword: '',
    isLoading: false,
    errorMessage: '',
    canLoadModels: true,
    isDisabled: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', [[Ember.Object.create({
        name: 'Canada',
        isSelected: false
      }), Ember.Object.create({
        name: 'USA',
        isSelected: false
      }), Ember.Object.create({
        name: 'Brazil',
        isSelected: false
      }), Ember.Object.create({
        name: 'England',
        isSelected: false
      })]]);
    },
    selectedModels: Ember.computed('model.@each.isSelected', function () {
      return this.model ? this.model.filterBy('isSelected', true) : [];
    }),
    option: Ember.computed('section', function () {
      return this.section.options[0];
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('model', [Ember.Object.create({
        name: 'Canada',
        isSelected: false,
        states: [Ember.Object.create({
          name: 'Ontario',
          isSelected: false,
          cities: [Ember.Object.create({
            name: 'Toronto',
            isSelected: false
          }), Ember.Object.create({
            name: 'Ottawa',
            isSelected: false
          })]
        }), Ember.Object.create({
          name: 'Alberta',
          isSelected: false,
          cities: [Ember.Object.create({
            name: 'Toronto',
            isSelected: false
          }), Ember.Object.create({
            name: 'Ottawa',
            isSelected: false
          })]
        })]
      }), Ember.Object.create({
        name: 'USA',
        isSelected: false
      }), Ember.Object.create({
        name: 'Brazil',
        isSelected: false
      }), Ember.Object.create({
        name: 'England',
        isSelected: false
      })]);
      this.loadModelPage();
      this.setupScrollBottomModelFetch();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.model && this.model.length) {
        this.preselectModels(this.model);
      }
      this.determineDisabled();
    },
    actions: {
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchKeyword) {
        this.setProperties({
          page: 1,
          searchKeyword: searchKeyword,
          model: []
        });
        this.loadModelPage();
      },
      clearSelections: function clearSelections() {
        if (this.model.filterBy('isSelected', true).length) {
          this.model.setEach('isSelected', false);
          var queryObject = {};
          queryObject[this.option.property] = null;
          this.didUpdateQueryParams(queryObject);
        }
      },
      toggleModelSelection: function toggleModelSelection(item) {
        item.toggleProperty('isSelected');
        this.serializeModelSelection();
      }
    },
    loadModelPage: function loadModelPage() {
      var _this = this;
      var modelPromise = this.fetchModel(this.option.modelName, this.getProperties(['page', 'recordsPerPage', 'searchKeyword']));
      this.set('isLoading', true);
      modelPromise.then(function (models) {
        _this.model.pushObjects(models.toArray());
        _this.preselectModels(_this.model);
        _this.setProperties({
          isLoading: false,
          canLoadModels: _this.model.length < models.meta.pagination.total && models.length !== 0
        });
      }).catch(function () {
        _this.set('errorMessage', 'Model Could Not Load!');
      });
    },
    preselectModels: function preselectModels(models) {
      var modelIds = this.queryParameters[this.option.property] || [];
      var modelRepresentation = this.option.modelRepresentation;
      models.setEach('isSelected', false);
      models.forEach(function (item) {
        modelIds.forEach(function (id) {
          if (JSON.stringify(item.getProperties(modelRepresentation)) === JSON.stringify(id)) {
            item.set('isSelected', true);
          }
        });
      });
    },
    determineDisabled: function determineDisabled() {
      var _this2 = this;
      var isDisabled = false;
      if (this.section.disabledOnProperties) {
        Object.keys(this.queryParameters).forEach(function (key) {
          if (_this2.section.disabledOnProperties.includes(key)) {
            isDisabled = true;
          }
        });
      }
      this.set('isDisabled', isDisabled);
    },
    serializeModelSelection: function serializeModelSelection() {
      var _this3 = this;
      if (this.didUpdateQueryParams) {
        var queryObject = {};
        var selectedModelRepresentations = this.selectedModels.map(function (item) {
          return item.getProperties(_this3.option.modelRepresentation);
        });
        if (!selectedModelRepresentations.length) {
          selectedModelRepresentations = null;
        }
        queryObject[this.option.property] = selectedModelRepresentations;
        this.didUpdateQueryParams(queryObject);
      }
    },
    setupScrollBottomModelFetch: function setupScrollBottomModelFetch() {
      var _this4 = this;
      var dropdownMenuElement = (0, _jquery.default)(this.element).find('.dropdown-menu');
      dropdownMenuElement.on("scroll.".concat(this.elementId), function () {
        requestAnimationFrame(function () {
          var scrollLength = dropdownMenuElement.height() + dropdownMenuElement.scrollTop();
          var scrollThreshold = dropdownMenuElement[0].scrollHeight - 10;
          if (scrollLength > scrollThreshold && !_this4.isLoading && _this4.canLoadModels) {
            _this4.set('page', _this4.page + 1);
            _this4.loadModelPage();
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('.dropdown-menu').off("scroll.".concat(this.elementId));
      this.model.setEach('isSelected', false);
    }
  });
  _exports.default = _default;
});