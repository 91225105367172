define("experience-studio/routes/permissions-hub/roles", ["exports", "experience-studio/mixins/model-actions"], function (_exports, _modelActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_modelActions.default, {
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        roles: store.findAll('permission-role'),
        users: store.findAll('user')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('searchKeyword', '');
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        listSortProperties: [Ember.Object.create({
          isSorted: true,
          label: 'Name',
          order: {
            asc: 'Z-A',
            desc: 'A-Z'
          },
          sortValue: 'name'
        })],
        manageRoleModalData: null,
        permissionsHubController: this.controllerFor('permissions-hub')
      });
    },
    actions: {
      createRecord: function createRecord() {
        this.send('showManageRoleModalDialog', this.store.createRecord('permission-role'));
      },
      deselectModelListItems: function deselectModelListItems() {
        this._super({
          selectedEntitiesPath: 'permissionsHubController.selectedEntities'
        });
      },
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.searchKeyword);
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(options) {
        this.controller.set('searchKeyword', options.keyword);
      },
      editRecord: function editRecord(record) {
        this.controller.send('showManageRoleModalDialog', record);
      },
      fetchEntities: function fetchEntities(emptyPermissions, defferedPermissions) {
        var _this = this;
        var entityPromises = [];
        emptyPermissions.forEach(function (modelName) {
          entityPromises.pushObject(_this.store.findAll(modelName));
        });
        Ember.RSVP.allSettled(entityPromises).then(function (responses) {
          if (responses.isEvery('state', 'fulfilled')) {
            var models = responses.mapBy('value');
            defferedPermissions.resolve(models);
          } else {
            defferedPermissions.reject();
          }
        });
      },
      refresh: function refresh() {
        this.refresh();
      },
      selectModelListItems: function selectModelListItems(model) {
        this._super(model, 'permissionsHubController.selectedEntities');
      },
      showInfo: function showInfo() {
        this.send('openInfoSidebar');
      },
      showManageRoleModalDialog: function showManageRoleModalDialog(role) {
        this.controller.set('manageRoleModalData', Ember.Object.create({
          role: role,
          users: this.controller.model.users
        }));
      },
      updateSortedModel: function updateSortedModel(sortedModel) {
        this.controller.set('sortedModel', sortedModel);
      }
    }
  });
  _exports.default = _default;
});