define("experience-studio/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPISerializer.extend({
    utility: Ember.inject.service(),
    keyForAttribute: function keyForAttribute(key) {
      return Ember.String.camelize(key);
    },
    keyForRelationship: function keyForRelationship(key) {
      return Ember.String.camelize(key);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (primaryModelClass.modelName === 'user-preference') {
        return this._super.apply(this, arguments);
      }
      var data;
      var dataLength;
      var formattedPayload = {
        data: null
      };
      var index;
      var type = primaryModelClass.modelName;
      var pluralizedType = (0, _emberInflector.pluralize)(type);
      if (payload && payload[pluralizedType]) {
        data = payload[pluralizedType];
      } else if (payload && payload[type]) {
        data = payload[type];
      } else if (payload.data) {
        data = payload.data;
      } else if (payload) {
        data = payload;
      }
      if (data.pushrequests) {
        data = data.pushrequests;
      } else if (data.roles) {
        data = data.roles;
      }

      // location and content-blueprint returns null if there are no data
      // normalize the response and set data to empty array so ui does not fail
      if (['location', 'content-blueprint'].includes(primaryModelClass.modelName) && !payload.data && (requestType === 'findAll' || requestType === 'query')) {
        data = [];
      }
      if (Array.isArray(data)) {
        dataLength = data.length;
        formattedPayload.data = [];
        for (index = 0; index < dataLength; index++) {
          formattedPayload.data.pushObject(this.transformPayloadForEmber(data.objectAt(index), pluralizedType));
        }
      } else if (data) {
        formattedPayload.data = this.transformPayloadForEmber(data, pluralizedType);
      } else {
        formattedPayload = null;
      }
      if (payload.pagination) {
        formattedPayload.meta = {
          pagination: Ember.Object.create({
            limit: payload.pagination.limit,
            offset: payload.pagination.offset,
            total: payload.pagination.totalRecords
          })
        };
      }
      return this._super(store, primaryModelClass, formattedPayload, id, requestType);
    },
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      var attributes = data.data.attributes;
      attributes.localizations = {
        en: {
          name: attributes.name,
          description: attributes.description
        }
      };
      return data.data.attributes;
    },
    transformPayloadForEmber: function transformPayloadForEmber(item, type) {
      var formattedItem = {
        id: item.id,
        type: type
      };
      if (item.localizations && item.localizations.en) {
        item.name = item.localizations.en.name;
        item.description = item.localizations.en.description;
        item.localizations = this.utility.transformToEmberObject(item.localizations);
      }
      formattedItem.attributes = item;
      return formattedItem;
    },
    transformToEmberObject: function transformToEmberObject(item) {
      return this.utility.transformToEmberObject(item);
    }
  });
  _exports.default = _default;
});