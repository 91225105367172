define("experience-studio/components/permissions-hub-access-level-modal-dialog", ["exports", "experience-studio/mixins/access-level", "experience-studio/components/base-modal-dialog"], function (_exports, _accessLevel, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend(_accessLevel.default, {
    classNames: ['permissions-hub-access-level-modal-dialog'],
    errorMessage: '',
    isActionsVisible: false,
    model: null,
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    isDowngradeMessageVisible: Ember.computed('model.selectedAccessLevel', function () {
      var model = this.model;
      var selectedAccessLevelValue = model.get('selectedAccessLevel.value');
      var userAccessLevelValue = model.get('user.accessLevel.value');
      return selectedAccessLevelValue < userAccessLevelValue;
    }),
    isSignedInUser: Ember.computed(function () {
      return this.get('session.user.id') === this.get('model.user.id');
    }),
    accessLevelActions: Ember.computed('model.selectedAccessLevel', function () {
      var model = this.model;
      var accessLevels = model.get('accessLevels');
      var accessLevelValue = model.get('selectedAccessLevel.value');
      var actions = [];
      if (!Ember.isEmpty(accessLevels)) {
        var accessLevel = accessLevels.filterBy('value', accessLevelValue).get('firstObject');
        if (accessLevel) {
          actions = accessLevel.get('actions');
        }
      }
      return actions;
    }),
    didInsertElement: function didInsertElement() {
      var model = this.model;
      var accessLevels = model.get('accessLevels');
      var accessLevelValue = model.get('user.accessLevel.value');
      if (!Ember.isEmpty(accessLevels)) {
        var accessLevel = accessLevels.filterBy('value', accessLevelValue).get('firstObject');
        if (accessLevel) {
          model.set('selectedAccessLevel', accessLevel);
        }
      }
      this._super.apply(this, arguments);
    },
    save: function save() {
      var _this = this;
      var model = this.model;
      var accessLevelValue = model.get('selectedAccessLevel.value');
      var self = this;
      var userId = model.get('user.id');
      this.updateUserAccessLevel(userId, accessLevelValue).then(function (response) {
        var canAccessPermissionsHubRoute = self.canAccessRoute('permissions-hub', response.get('accessLevel.value'));
        model.set('user.accessLevel', response.get('accessLevel'));
        _this.send('close');
        if (self.get('isSignedInUser') && !canAccessPermissionsHubRoute) {
          _this.sendAction('transitionTo', Ember.Object.create({
            // eslint-disable-line ember/closure-actions
            routeName: 'experiences'
          }));
        }
      }).catch(function (response) {
        self.set('errorMessage', self.get('utility').parseError(response));
      });
    },
    actions: {
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessage', '');
      },
      toggleIsActionsVisible: function toggleIsActionsVisible() {
        this.toggleProperty('isActionsVisible');
      },
      selectValue: function selectValue(model, variable, selection) {
        if (model) {
          model.set(variable, selection);
        } else {
          this.set(variable, selection);
        }
      },
      validate: function validate() {
        var selectedAccessLevel = this.get('model.selectedAccessLevel');
        var errorMessage = '';
        this.set('errorMessage', '');
        if (!selectedAccessLevel) {
          errorMessage = 'You must select new access level.';
        }
        this.set('errorMessage', errorMessage);
        if (Ember.isEmpty(errorMessage)) {
          this.save();
        }
      }
    }
  });
  _exports.default = _default;
});