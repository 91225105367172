define("experience-studio/templates/components/content-form-survey-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "m7h4dRFQ",
    "block": "{\"symbols\":[\"item\",\"choice\",\"item\"],\"statements\":[[10,\"ul\"],[14,0,\"locale-list\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"locale-list-item\"],[12],[11,\"a\"],[24,6,\"#\"],[4,[38,3],[[32,0],\"selectLang\",[32,3]],null],[12],[1,[32,3,[\"name\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[13],[2,\"\\n\\n\"],[10,\"h4\"],[14,0,\"results-title\"],[12],[2,\"Customer Survey\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"list-unstyled results-list\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"results-list-item\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"question\"],[12],[2,\"\\n        \"],[1,[32,1,[\"q\",\"question\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"ul\"],[14,0,\"list-unstyled result-choices-list\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"choices\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[14,0,\"result-choices-list-item\"],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"reults-key\"],[12],[1,[32,2,[\"value\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,2,[\"value\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"span\"],[14,0,\"results-bar\"],[15,5,[31,[\"width: \",[32,2,[\"barWidth\"]],\";\"]]],[12],[2,\"\\n              \"],[13],[2,\"\\n              \"],[10,\"span\"],[14,0,\"results-votes\"],[12],[2,\"\\n                \"],[1,[32,2,[\"votes\"]]],[2,\" - (\"],[1,[32,2,[\"barWidth\"]]],[2,\")\\n              \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\",\"action\",\"languages\",\"model\"]}",
    "moduleName": "experience-studio/templates/components/content-form-survey-results.hbs"
  });
  _exports.default = _default;
});