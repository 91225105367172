define("experience-studio/components/content-tmp-edit-localized-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-localized-image'],
    isLanguagesVisible: true,
    languages: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    valueImage: '',
    isImageDisabled: Ember.computed('field.defaultOption', function () {
      if (this.field.defaultOption && this.field.defaultOption.defaultImage && !this.field.defaultOption.isEditable) {
        return true;
      } else {
        return false;
      }
    }),
    didInsertElement: function didInsertElement() {
      if (this.languages) {
        var language = this.languages.findBy('isDefault', true);
        if (!language) {
          language = this.languages.findBy('id', 'en');
        }
        if (language) {
          this.send('selectLanguage', language);
        }
      }
      if (!this.get('field.value')) {
        this.set('field.value', {});
      }
    },
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    didUpdateLanguage: Ember.observer('languages.@each.isSelected', function () {
      var selectedLanguageCode = this.selectedLanguageCode;
      var key = this.field.key;
      if (!selectedLanguageCode) {
        var selectedLanguage = this.languages.findBy('isSelected', true);
        if (selectedLanguage) {
          selectedLanguageCode = selectedLanguage.get('id');
        }
      }
      if (selectedLanguageCode) {
        this.set('valueImage', this.get("field.value.".concat(selectedLanguageCode, ".").concat(key)));
      }
    }),
    didUpdateValue: Ember.observer('valueImage', function () {
      var selectedLanguageCode = this.selectedLanguageCode;
      var key = this.field.key;
      if (!Ember.isEmpty(selectedLanguageCode)) {
        if (Ember.isNone(this.field.value)) {
          this.set('field.value', Ember.Object.create({}));
        }
        if (Ember.isNone(this.get("field.value.".concat(selectedLanguageCode)))) {
          this.set("field.value.".concat(selectedLanguageCode), Ember.Object.create({}));
        }
        if (!this.get('valueImage') && this.get("field.value.".concat(selectedLanguageCode))) {
          delete this.field.value[selectedLanguageCode];
        } else {
          this.set("field.value.".concat(selectedLanguageCode, ".").concat(key), this.get('valueImage'));
        }
      }
    }),
    requirements: Ember.computed('field.imgAspectRatio', function () {
      if (this.get('field.imgAspectRatio')) {
        return {
          aspectRatio: this.get('field.imgAspectRatio')
        };
      }
      return;
    }),
    defaultTransform: Ember.computed('field.{imgMaxWidth,imgMaxHeight}', function () {
      if (this.get('field.imgMaxWidth') || this.get('field.imgMaxHeight')) {
        var resizeObj = {};
        if (this.get('field.imgMaxWidth')) {
          resizeObj.width = this.get('field.imgMaxWidth');
        }
        if (this.get('field.imgMaxHeight')) {
          resizeObj.height = this.get('field.imgMaxHeight');
        }
        return {
          resize: resizeObj
        };
      }
      return;
    }),
    actions: {
      selectLanguage: function selectLanguage(language) {
        this.languages.setEach('isSelected', false);
        language.set('isSelected', true);
      }
    }
  });
  _exports.default = _default;
});