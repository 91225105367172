define("experience-studio/adapters/label", ["exports", "jquery", "experience-studio/adapters/application"], function (_exports, _jquery, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'kernel',
    findRecord: function findRecord(store, type, id) {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(Ember.Object.create({
          id: id,
          value: id,
          name: id
        }));
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;
      var data = this.serialize(snapshot, {
        includeId: true
      });
      var url = this.buildURL('label');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'PUT',
          url: url,
          data: JSON.stringify(data)
        }).done(function () {
          resolve(data.data[0]);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    saveLabels: function saveLabels(labels) {
      var _this2 = this;
      if (labels.length) {
        var url = this.buildURL('label');
        var data = {
          data: labels.mapBy('value')
        };
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            headers: _this2.headers,
            type: 'PUT',
            url: url,
            data: JSON.stringify(data)
          }).done(function () {
            resolve(data.data[0]);
          }).fail(function (jqXHR) {
            reject(jqXHR);
          });
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(labels);
        });
      }
    }
  });
  _exports.default = _default;
});