define("experience-studio/routes/permissions-hub", ["exports", "experience-studio/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    userPreferences: Ember.inject.service(),
    utility: Ember.inject.service(),
    afterModel: function afterModel(model, transition) {
      if (transition.targetName.indexOf('index') > 0) {
        this.transitionTo(this.getMainHeaderTabs().get('firstObject.routeName'));
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        isInfoSidebarOpen: false,
        mainHeaderTabs: this.getMainHeaderTabs(),
        selectedEntities: [],
        searchKeyword: ''
      });
    },
    getMainHeaderTabs: function getMainHeaderTabs() {
      var parentRouteName = 'permissions-hub';
      var isPermissionRolesPageVisible = this.userPreferences.getValue('permissionRolesPage');
      var tabs = [Ember.Object.create({
        isActive: false,
        name: 'Users',
        routeName: "".concat(parentRouteName, ".users")
      }), Ember.Object.create({
        isActive: false,
        name: 'Invitations',
        routeName: "".concat(parentRouteName, ".invitations")
      }), Ember.Object.create({
        isActive: false,
        name: 'Access Levels',
        routeName: "".concat(parentRouteName, ".access-levels")
      })];
      if (isPermissionRolesPageVisible) {
        tabs.pushObject(Ember.Object.create({
          isActive: false,
          name: 'Permission Roles',
          routeName: "".concat(parentRouteName, ".roles")
        }));
      }
      return tabs;
    },
    actions: {
      changeTab: function changeTab() {
        this.controller.set('searchKeyword', '');
      },
      openInfoSidebar: function openInfoSidebar() {
        this.controller.set('isInfoSidebarOpen', true);
      },
      removeSearchKeyword: function removeSearchKeyword() {
        this.controller.set('searchKeyword', '');
      },
      updateSearchKeyword: function updateSearchKeyword(searchKeyword) {
        this.controller.set('searchKeyword', searchKeyword);
      }
    }
  });
  _exports.default = _default;
});