define("experience-studio/templates/components/location-label-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pbu67zkB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"type\"],[\"info\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"triangle\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"alert-text\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[2,\"Tip:\"],[13],[2,\" Use the \"],[10,\"strong\"],[12],[2,\"Location Label\"],[13],[2,\" tab to\\n      automatically target all locations using a given Label (e.g. \\\"airports\\\"),\\n      in addition to any locations sharing the same Label that you\\n      create/upload later.\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fb-bs-alert\",\"label\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/location-label-alert.hbs"
  });
  _exports.default = _default;
});