define("experience-studio/routes/communications-hub/push-messages-v3", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-push-messages", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-push-message"], function (_exports, _authenticatedRoute, _routeDataQueryParam, _routeModelBatchActionsBase, _routeModelBatchActionsPushMessages, _routeModelTableActionsBase, _routeModelTableActionsPushMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeDataQueryParam.default, _routeModelTableActionsBase.default, _routeModelTableActionsPushMessage.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsPushMessages.default, {
    afterModel: function afterModel(model, transition) {
      var userPreference = this.userPreferences.getValue('routeListView');
      if (transition.targetName === 'communications-hub.push-messages-v2.index' && userPreference) {
        this.transitionTo('communications-hub.push-messages-v3');
      }
      return this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          _data: '',
          data: ''
        });
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        isDuplicateModalOpen: false,
        pushesWithSameRule: [],
        experiencesWithSameRule: [],
        communicationsHubController: this.controllerFor('communications-hub')
      });
      controller.communicationsHubController.setProperties({
        isSearchFormHidden: true
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    getPushesByRuleId: function getPushesByRuleId(selectedRule) {
      var adapter = this.store.adapterFor('push-message');
      return adapter.getPushMessagesByRuleId(selectedRule);
    },
    getExperiencesWithSameRule: function getExperiencesWithSameRule(selectedRule) {
      var adapter = this.store.adapterFor('experience');
      return adapter.getExperiencesByRuleId(selectedRule);
    },
    broadcastRecord: function broadcastRecord(model) {
      var adapter = this.store.adapterFor(model.constructor.modelName);
      return adapter.broadcastRecord(this.store, model.constructor, model);
    },
    actions: {
      createEntity: function createEntity() {
        this.send('createRecord', {
          route: 'communications-hub.push-messages-v2.create'
        });
      },
      deselectModelListItems: function deselectModelListItems() {
        this._super({
          selectedEntitiesPath: 'communicationsHubController.selectedEntities'
        });
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
      },
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      selectModelListItems: function selectModelListItems(params) {
        params.selectedEntitiesPath = 'communicationsHubController.selectedEntities';
        this._super(params);
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.controller.toggleProperty('isRefreshFilterModel');
        this.refresh();
      },
      viewInfo: function viewInfo(params) {
        params.selectedEntitiesPath = 'communicationsHubController.selectedEntities';
        params.controller = 'communicationsHubController';
        this._super(params);
      },
      removeSchedule: function removeSchedule(model) {
        var _this = this;
        if (!this.errorMessage && model) {
          var rules = model.record.computedRules.toArray();
          if (!model.record.isBroadcast && !Ember.isEmpty(rules)) {
            rules.forEach(function (rule) {
              if (rule.content.schedule) {
                var promises2 = Ember.Object.create({
                  pushMessages: _this.getPushesByRuleId(rule.content, model.record),
                  experiences: _this.getExperiencesWithSameRule(rule.content)
                });
                Ember.RSVP.hashSettled(promises2).then(function (hash) {
                  if (hash.pushMessages.state === 'fulfilled' && hash.experiences.state === 'fulfilled') {
                    var filteredPushes = [];
                    if (hash.pushMessages.value.data) {
                      if (model.record.id) {
                        filteredPushes = hash.pushMessages.value.data.filter(function (push) {
                          return push.pushRequestID !== model.record.id;
                        });
                      } else {
                        filteredPushes = hash.pushMessages.value.data;
                      }
                      _this.controller.set('pushesWithSameRule', filteredPushes);
                    }
                    if (hash.experiences.value.data) {
                      _this.controller.set('experiencesWithSameRule', hash.experiences.value.data);
                    }
                    if (filteredPushes.length || hash.experiences.value.data.length) {
                      _this.send('showDuplicateModal', model.record, filteredPushes, hash.experiences.value.data, _this.eraseRuleSchedule);
                    } else {
                      _this.send('eraseRuleSchedule', rule.content, model);
                    }
                  }
                });
              }
            });
          } else {
            if (model.record.schedule && model.record.schedule.scheduler && model.record.schedule.scheduler.start > 0) {
              model.record.schedule.setProperties({
                scheduler: Ember.Object.create({
                  'contextualStart': '',
                  'start': 0,
                  'repeat': Ember.Object.create({
                    frequencyType: '',
                    frequency: 0,
                    limit: 0
                  }),
                  'timezone': Ember.Object.create({})
                })
              });
              model.record.save().then(function () {
                _this.send('broadcastRecord', model.record);
              }).catch(function (response) {
                _this.send('showModal', Ember.Object.create({
                  modalName: 'message-modal-dialog',
                  primaryMessage: _this.get('utility').parseError(response),
                  title: 'Failed to send Push Notification',
                  type: 'danger'
                }));
              });
            }
          }
        }
      },
      showDuplicateModal: function showDuplicateModal(record, pushesWithSameRule, experiencesWithSameRule) {
        this.controller.setProperties({
          isDuplicateModalOpen: true,
          model: record,
          actionType: 'remove',
          pushesWithSameRule: pushesWithSameRule,
          experiencesWithSameRule: experiencesWithSameRule
        });
      },
      validateAndBroadcast: function validateAndBroadcast(record) {
        record = record.record;
        if (record.activeSchedule) {
          this.openConfirmScheduleModal(record);
        } else {
          this.send('broadcastRecord', record);
        }
      },
      broadcastRecord: function broadcastRecord(record) {
        record = record.hasOwnProperty('record') ? record.record : record;
        var self = this;
        this.broadcastRecord(record).then(function () {
          var myZone = window.moment.tz.guess().replace(/_/g, ' ');
          record.setProperties({
            isSent: true,
            executionHistory: Ember.Object.create({
              lastBroadcastDate: parseInt(Date.now() / 1000),
              timezone: Ember.Object.create({
                name: myZone,
                offset: 0
              })
            })
          });
          self.send('showNotification', Ember.Object.create({
            message: "The push notification ".concat(record.get('name'), "\n                 has been sent successfully."),
            type: 'confirm'
          }));
        }).catch(function (response) {
          self.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: self.get('utility').parseError(response),
            title: 'Failed to send Push Notification',
            type: 'danger'
          }));
        });
      },
      eraseRuleSchedule: function eraseRuleSchedule(rule, model) {
        var _this2 = this;
        rule.schedule.startScheduler.setProperties({
          'start': 0,
          'repeat': Ember.Object.create({
            frequencyType: '',
            frequency: 0,
            limit: 0
          }),
          'timezone': Ember.Object.create({
            name: '',
            offset: 0
          })
        });
        rule.save().then(function () {
          _this2.send('broadcastRecord', model);
          var impactedPushes = _this2.controller.get('pushesWithSameRule');
          impactedPushes.forEach(function (push) {
            var impactedPush = _this2.controller.filteredModel.findBy('id', push.pushRequestID);
            impactedPush.reload();
            model.reload();
          });
        }).catch(function (response) {
          _this2.send('showModal', Ember.Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: _this2.get('utility').parseError(response),
            title: 'Failed to send Push Notification',
            type: 'danger'
          }));
        });
      }
    }
  });
  _exports.default = _default;
});