define("experience-studio/components/draggable-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    attributeBindings: ['isDrggable:draggable'],
    classNameBindings: ['isDragging'],
    classNames: ['draggable-item', 'draggable-list-item'],
    isDrggable: false,
    tagName: 'li',
    data: Ember.computed(function () {
      return JSON.stringify(this.model.toJSON({
        includeId: true
      }));
    }),
    click: function click() {
      this.sendAction('add', this.model); // eslint-disable-line ember/closure-actions
      return false;
    },
    dragEnd: function dragEnd() {
      this.set('isDragging', false);
    },
    dragStart: function dragStart(event) {
      this.set('isDragging', true);
      if (event && event.dataTransfer) {
        event.dataTransfer.clearData();
        event.dataTransfer.setData('text/plain', this.data);
      }
    }
  });
  _exports.default = _default;
});