define("experience-studio/components/route-model-filter-location", ["exports", "experience-studio/components/route-model-filter"], function (_exports, _routeModelFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelFilter.default.extend({
    classNames: ['route-model-filter-location'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('suggestions', [{
        label: 'name',
        property: 'searchKeyword',
        modelName: ''
      }, {
        label: 'address',
        property: 'address',
        modelName: ''
      }, {
        label: 'label',
        property: 'labels',
        modelName: 'label',
        modelRepresentation: ['name', 'value']
      }]);
    }
  });
  _exports.default = _default;
});