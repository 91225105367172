define("experience-studio/controllers/communications-hub/push-messages-v3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    data: '',
    page: 1,
    recordsPerPage: 10,
    queryParams: [{
      data: 'data',
      page: 'page',
      recordsPerPage: 'recordsPerPage'
    }],
    filteredModel: Ember.computed('isRefreshFilterModel', 'data', function () {
      return this.store.query('push-message', {
        data: this.data,
        page: this.page,
        recordsPerPage: this.recordsPerPage
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    }),
    batchActions: Object.freeze([Ember.Object.create({
      label: 'Send',
      icon: 'paper-plane',
      action: 'showSendRecordsModal',
      validationFunction: function validationFunction(selectedEntities) {
        if (selectedEntities.isEvery('isBroadcast', false)) {
          return 'Triggered push notifications cannot be sent.';
        }
      }
    }), Ember.Object.create({
      label: 'Activation',
      icon: 'power',
      subActions: [Ember.Object.create({
        label: 'Set as Active',
        action: 'showActivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isBroadcast', true)) {
            return 'Broadcast push notifications cannot be activated.';
          }
          if (selectedEntities.filterBy('isBroadcast', false).isEvery('isActive', true)) {
            return 'At least one item has to be Inactive.';
          }
        }
      }), Ember.Object.create({
        label: 'Set as Inactive',
        action: 'showDeactivateRecordsModal',
        validationFunction: function validationFunction(selectedEntities) {
          if (selectedEntities.isEvery('isBroadcast', true)) {
            return 'Broadcast push notifications cannot be deactivated.';
          }
          if (selectedEntities.filterBy('isBroadcast', false).isEvery('isActive', false)) {
            return 'At least one item has to be Active.';
          }
        }
      })]
    }), Ember.Object.create({
      label: 'Delete',
      icon: 'trash',
      action: 'showDeleteRecordsModal',
      subActions: [],
      hasDivider: true
    })]),
    filterState: Object.freeze([Ember.Object.create({
      title: 'Date',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Created',
        id: 'created',
        isDisabled: false,
        options: [Ember.Object.create({
          value: undefined,
          property: 'createdAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'createdAtTo'
        })]
      })]
    }), Ember.Object.create({
      title: '',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-model-select',
        label: '',
        id: 'label',
        isDisabled: false,
        options: [Ember.Object.create({
          modelName: 'label',
          // used to look up model in store
          modelRepresentation: ['name', 'value'],
          // used to retrieve id or value to be saved in param
          value: undefined,
          label: 'Labels',
          property: 'labels'
        })]
      })]
    })]),
    filterParams: Object.freeze([Ember.Object.create({
      id: 'createdAtTo',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'createdAtFrom',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'labels',
      component: 'filter-pill-label',
      value: null
    })])
  });
  _exports.default = _default;
});