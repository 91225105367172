define("experience-studio/components/fb-bs-alert", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['fb-bs-alert', 'alert'],
    classNameBindings: ['typeClassName'],
    dismissible: true,
    typeClassName: Ember.computed('type', function () {
      return !Ember.isEmpty(this.type) ? "alert-".concat(this.type) : 'alert-primary';
    }),
    _onClosed: function _onClosed() {
      if (this.onClosed) {
        this.onClosed();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).on("closed.bs.alert", Ember.run.bind(this, this._onClosed));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).off("closed.bs.alert");
    }
  });
  _exports.default = _default;
});