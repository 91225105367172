define("experience-studio/routes/groups-v2", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-groups", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-group"], function (_exports, _authenticatedRoute, _routeDataQueryParam, _routeModelBatchActionsBase, _routeModelBatchActionsGroups, _routeModelTableActionsBase, _routeModelTableActionsGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeModelTableActionsBase.default, _routeModelTableActionsGroup.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsGroups.default, _routeDataQueryParam.default, {
    model: function model() {
      return Ember.RSVP.hash({
        languages: this.store.queryRecord('project-config', {
          setting: 'language'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('selectedEntities', []);
    },
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'experiences-v2.groups-v2' && !this.userPreferences.getValue('routeListView')) {
        this.transitionTo('experiences.groups');
      }
      return this._super.apply(this, arguments);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          _data: '',
          data: '',
          isInfoSidebarOpen: false
        });
        this.send('deselectModelListItems');
      }
    },
    getModelListItems: function getModelListItems() {
      return this.controller.filteredModel;
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createEntity: function createEntity() {
        this.send('showManageGroupModalDialog', {});
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.controller.toggleProperty('isRefreshFilterModel');
        this.refresh();
      },
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      }
    }
  });
  _exports.default = _default;
});