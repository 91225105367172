define("experience-studio/components/info-sidebar-rule-composition-literal-location", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { isEmpty } from '@ember/utils';
  var _default = Ember.Component.extend({
    classNames: ['info-sidebar-rule-composition-literal-location'],
    model: null,
    singleMapError: false,
    isLocationListExpanded: false,
    init: function init() {
      this._super.apply(this, arguments);
      var ayyLmao = Math.floor(Math.random() * 5);
      // let ayyLmao = 0;

      var locations = [Ember.Object.create({
        address: '123 Blin Ave',
        creator: 'Prokofiev',
        createdAt: undefined,
        labels: ['ayy', 'lmao', 'cheeze', 'kek'],
        name: 'Pozolste',
        shape: [[{
          'lat': 59.35964267163884,
          'lng': 143.20475225962764
        }, {
          'lat': 59.35964267163884,
          'lng': 143.20016960384896
        }, {
          'lat': 59.35730705190012,
          'lng': 143.20016960384896
        }, {
          'lat': 59.35730705190012,
          'lng': 143.20475225962764
        }, {
          'lat': 59.35964267163884,
          'lng': 143.20475225962764
        }]],
        tenantID: 'ayylmao'
      }), Ember.Object.create({
        address: '123 Vladamir Ave',
        creator: 'Prokofiev',
        createdAt: undefined,
        labels: [],
        name: 'Gospodin Putin',
        shape: [[{
          'lat': 59.93517851528412,
          'lng': 30.33689166944191
        }, {
          'lat': 59.93517851528412,
          'lng': 30.333305530558164
        }, {
          'lat': 59.933381884715885,
          'lng': 30.333305530558164
        }, {
          'lat': 59.933381884715885,
          'lng': 30.33689166944191
        }, {
          'lat': 59.93517851528412,
          'lng': 30.33689166944191
        }]],
        tenantID: 'ayylmao'
      })];
      if (ayyLmao === 0) {
        this.set('model', Ember.Object.create({
          locations: locations
        }));
      } else if (ayyLmao === 1) {
        this.set('model', Ember.Object.create({
          locations: locations.slice(0)
        }));
      } else if (ayyLmao === 2) {
        this.set('model', Ember.Object.create({
          labels: ['prokofiev'],
          locations: locations.slice(0)
        }));
      } else if (ayyLmao === 3) {
        this.set('model', Ember.Object.create({
          labels: ['prokofiev', 'putin'],
          locations: locations
        }));
      } else if (ayyLmao === 4) {
        this.set('model', Ember.Object.create({
          labels: ['putin'],
          locations: []
        }));
      }
    },
    hasMultiple: Ember.computed('model.locations.[]', function () {
      return this.model.locations.length > 0;
    }),
    locationTitle: Ember.computed('model.{locations,labels}.[]', function () {
      var locationTitle = '';
      if (this.model.get('labels')) {
        locationTitle = 'Location Label: ';
        this.model.get('labels').forEach(function (label, index, arr) {
          locationTitle += label;
          if (index === arr.length - 1) {
            locationTitle += ' is equal to True';
          } else {
            locationTitle += ', ';
          }
        });
      } else {
        locationTitle = 'User is located at';
      }
      return locationTitle;
    }),
    singleImageUrl: Ember.computed('hasMultiple', function () {
      if (!this.hasMultiple && !this.model.get('labels')) {
        var coordinates = [];
        var endpoint = 'maps/api/staticmap';
        var host = 'https://maps.googleapis.com';
        var shape = this.model.locations[0].shape[0];
        shape.forEach(function (coordinate) {
          return coordinates.push("".concat(coordinate.lat, ",").concat(coordinate.lng));
        });
        var query = {
          key: _environment.default.APP.googleMapKey,
          path: "color:0x29BFEDFF|weight:2|".concat(coordinates.join('|')),
          size: '200x200',
          scale: 2
        };
        return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query));
      }
      return '';
    }),
    actions: {
      setSingleMapError: function setSingleMapError() {
        this.set('singleMapError', true);
      },
      toggleViewLocationList: function toggleViewLocationList() {
        this.toggleProperty('isLocationListExpanded');
      }
    }
  });
  _exports.default = _default;
});