define("experience-studio/serializers/user", ["exports", "experience-studio/mixins/access-level", "experience-studio/serializers/application"], function (_exports, _accessLevel, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend(_accessLevel.default, {
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      var accessLevel = item.attributes.level;
      item.attributes.createdAt = window.moment(item.attributes.date).unix();
      delete item.attributes.date;
      if (!Ember.isNone(accessLevel)) {
        item.attributes.accessLevel = Ember.Object.create({
          name: this.getAccessLevelName(accessLevel),
          value: accessLevel
        });
      }
      return item;
    }
  });
  _exports.default = _default;
});