define("experience-studio/mixins/access-level", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    canAccessRoute: function canAccessRoute(routeName, accessLevelValue) {
      switch (routeName) {
        case 'permissions-hub':
          return accessLevelValue >= 20;
        default:
          return false;
      }
    },
    getAccessLevelActions: function getAccessLevelActions(accessLevelValue) {
      var actions = [];
      if (accessLevelValue <= 1) {
        actions.pushObjects(['Sign in to Flybits-powered applications', 'Get Relevant Content', 'Send Context Data about self']);
      } else if (accessLevelValue <= 5) {
        actions.pushObjects(['All permissions associated to App User', 'View Content', 'View Context Rules', 'View Experiences', 'View Groups', 'View Push Notifications']);
      } else if (accessLevelValue <= 10) {
        actions.pushObjects(['All permissions associated to Viewer', 'Create/Edit/Delete Broadcast and Triggered Push Notifications', 'Create/Edit/Delete Content', 'Create/Edit/Delete Experiences', 'Create/Edit/Delete Groups', 'Create/Simulate/Delete Context Rules']);
      } else if (accessLevelValue <= 15) {
        actions.pushObjects(['All permissions associated to Creator', 'Activate/Deactivate Experiences', 'Activate/Deactivate Triggered Push Notifications', 'Change Priorities of Content, Experiences, and Groups', 'Send Broadcast Push Notifications', 'Send Context Data about users via CSV file']);
      } else if (accessLevelValue <= 17) {
        actions.pushObjects(['Manage Permissions', 'Update users\'s Access Level', 'View Users']);
      } else if (accessLevelValue <= 20) {
        actions.pushObjects(['All permissions associated to Publisher and Permission Manager', 'Add APNS/GCM Certificates', 'Create/Edit/Delete Content Templates', 'Create/Edit/Delete Context Plugins', 'Edit/Delete Project', 'View API requests per month']);
      }
      return actions;
    },
    getAccessLevelName: function getAccessLevelName(level) {
      if (level <= 0) {
        return 'Guest';
      } else if (level <= 1) {
        return 'App User';
      } else if (level <= 5) {
        return 'Viewer';
      } else if (level <= 10) {
        return 'Creator';
      } else if (level <= 15) {
        return 'Publisher';
      } else if (level === 17) {
        return 'Permission Manager';
      } else if (level <= 20) {
        return 'Owner';
      } else {
        return 'Owner';
      }
    },
    updateUserAccessLevel: function updateUserAccessLevel(userId, accessLevelValue) {
      var _this = this;
      var self = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var adapter = _this.store.adapterFor('application');
        var url = "".concat(adapter.get('host'), "/sso/permissions/userinfo");
        var data = {
          level: accessLevelValue,
          roles: [],
          user: {
            userId: userId
          }
        };
        _jquery.default.ajax({
          contentType: 'application/json',
          data: JSON.stringify(data),
          headers: adapter.get('headers'),
          type: 'PUT',
          url: url
        }).done(function (response) {
          return resolve(Ember.Object.create({
            accessLevel: Ember.Object.create({
              name: self.getAccessLevelName(response.level),
              value: response.level
            }),
            userId: response.user.userId
          }));
        }).fail(function (jqXHR) {
          return reject(jqXHR);
        });
      });
    }
  });
  _exports.default = _default;
});