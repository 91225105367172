define("experience-studio/components/push-messages-new-trigger-fields", ["exports", "jquery", "experience-studio/mixins/time-conversion"], function (_exports, _jquery, _timeConversion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_timeConversion.default, {
    router: Ember.inject.service(),
    classNames: ['push-messages-new-trigger-fields'],
    model: null,
    triggeredOptions: null,
    // Pased from parent
    intervalLimit: null,
    cycleLimit: null,
    messageLimit: null,
    _selectedRule: null,
    _selectedContext: null,
    _focusedInput: null,
    switchesInitialized: false,
    errors: Ember.Object.create({
      hasMessageLimitError: false,
      hasDelayError: false,
      hasCycleLimitError: false,
      hasIntervalLimitError: false,
      hasCooldownRepeatError: false,
      hasCooldownResetError: false
    }),
    timeUnits: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Object.create({
      name: 'second(s)',
      value: 'seconds',
      singularValue: 'second'
    }), Object.create({
      name: 'minute(s)',
      value: 'minutes',
      singularValue: 'minute'
    }), Object.create({
      name: 'hour(s)',
      value: 'hours',
      singularValue: 'hour'
    }), Object.create({
      name: 'day(s)',
      value: 'days',
      singularValue: 'day'
    }), Object.create({
      name: 'week(s)',
      value: 'weeks',
      singularValue: 'week'
    }), Object.create({
      name: 'month(s)',
      value: 'months',
      singularValue: 'month'
    }), Object.create({
      name: 'year(s)',
      value: 'years',
      singularValue: 'year'
    })],
    isEditMode: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName.indexOf('edit') > 0;
    }),
    isRuleInitialized: Ember.computed('_selectedRule', 'selectedRule', function () {
      return this._selectedRule && this.selectedRule && Ember.isEqual(this._selectedRule.id, this.selectedRule.id);
    }),
    isContextInitialized: Ember.computed('_selectedContext', 'isContext', function () {
      return Ember.isEqual(this._selectedContext, this.isContext);
    }),
    // isCycle (The old version of message scheduling is on the Push Level)
    // isInterval (The new version of message scheduling is on the Rule Level)
    _isCycle: Ember.computed('model', 'selectedRule.isInterval', function () {
      return !this.get('selectedRule.isInterval') && this.model.options.max > 0;
    }),
    // A push entity has a message schedule if it is either isCycle or isInterval
    _hasMessageSchedule: Ember.computed('_isCycle', 'selectedRule.isInterval', function () {
      return this._isCycle || this.get('selectedRule.isInterval');
    }),
    localizationLanguage: Ember.computed('instance', function () {
      return Object.keys(this.instance.value).join(',');
    }),
    hasError: Ember.observer('hasCycleResetError', 'errors.{hasDelayError,hasCooldownResetError,hasMessageLimitError,hasIntervalLimitError,hasCycleLimitError,hasCooldownRepeatError}', 'triggeredOptions.{hasMessageLimit,isMessageScheduleEnabled,isMessageCycle,isStopSendingEnabled,isSendDelayEnabled}', function () {
      // helpers
      var isCycle = this.triggeredOptions.isMessageScheduleEnabled && this.triggeredOptions.isMessageCycle; // eslint-disable-line max-len
      var isFrequency = this.triggeredOptions.isMessageScheduleEnabled && !this.triggeredOptions.isMessageCycle; // eslint-disable-line max-len
      // errors
      var delay = this.triggeredOptions.isSendDelayEnabled && this.errors.hasDelayError;
      var limit = this.triggeredOptions.hasMessageLimit && this.errors.hasMessageLimitError;
      var cycle = isCycle && (this.errors.hasCycleLimitError || !this.triggeredOptions.isStopSendingEnabled && this.errors.hasCooldownResetError); // eslint-disable-line max-len
      var frequency = isFrequency && (this.errors.hasIntervalLimitError || this.errors.hasCooldownRepeatError); // eslint-disable-line max-len
      var hasError = delay || limit || cycle || frequency;
      this.set('triggeredOptions.hasError', hasError);
    }),
    observePushLimit: Ember.observer('triggeredOptions.isUnlimited', 'intervalLimit', function () {
      !this.triggeredOptions.isUnlimited && this.intervalLimit < 1 ? this.errors.set('hasIntervalLimitError', true) : this.errors.set('hasIntervalLimitError', false);
      this.updatePushInterval();
    }),
    observeCycleLimit: Ember.observer('cycleLimit', function () {
      this.cycleLimit < 1 ? this.errors.set('hasCycleLimitError', true) : this.errors.set('hasCycleLimitError', false);
      this.set('model.options.maxCycleSham', Number.parseInt(this.cycleLimit));
    }),
    hasMessageLimitToggle: Ember.observer('triggeredOptions.hasMessageLimit', function () {
      if (!this.triggeredOptions.hasMessageLimit) {
        this.set('model.options.limitSham', 0);
      } else {
        this.set('messageLimit', this.model.options.limitSham || 1);
      }
    }),
    hasMessageLimitError: Ember.observer('messageLimit', function () {
      this.messageLimit < 1 ? this.errors.set('hasMessageLimitError', true) : this.errors.set('hasMessageLimitError', false);
      this.set('model.options.limitSham', Number.parseInt(this.messageLimit));
    }),
    observeDelay: Ember.observer('model.options.delaySham', function () {
      this.model.options.delaySham < 1 ? this.errors.set('hasDelayError', true) : this.errors.set('hasDelayError', false);
    }),
    observeCooldownRepeat: Ember.observer('model.options.cooldownShamRepeat', function () {
      this.model.options.cooldownShamRepeat < 1 ? this.errors.set('hasCooldownRepeatError', true) : this.errors.set('hasCooldownRepeatError', false);
    }),
    observeCooldownReset: Ember.observer('model.options.cooldownShamReset', function () {
      this.model.options.cooldownShamReset < 1 ? this.errors.set('hasCooldownResetError', true) : this.errors.set('hasCooldownResetError', false);
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]').tooltip();
    },
    /**
     *
     *  This is lifecycle hook is triggered in two scenarios.
     *  1) When the delivery method is changed from Broadcast to Triggered
     *  2) When a new rule is selected
     *
     * */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.later(function () {
        _this.initSwitch();
        _this.initInfo();
        _this.set('_selectedRule', _this.selectedRule);
        _this.set('_selectedContext', _this.isContext);
      }, 0);
    },
    initSwitch: function initSwitch() {
      var _this2 = this;
      // If a same rule / same context is selected, we don't make changes
      if (this.isRuleInitialized && this.isContextInitialized) return;
      // Only initialize delay switch once
      if (!this.switchesInitialized) {
        this.set('triggeredOptions.isSendDelayEnabled', this.get('model.options.delay') > 0);
      }
      // Edge case)
      if (this.switchesInitialized && !this.get('selectedRule.isInterval')) {
        this.set('triggeredOptions.isMessageCycle', true);
        return;
      }
      // Switch Logic
      Ember.run.later(function () {
        _this2.set('triggeredOptions.isMessageScheduleEnabled', _this2._hasMessageSchedule);
        // Contextual Rule will always defualt to message cycle
        if (_this2.isContext) {
          _this2.set('triggeredOptions.isMessageCycle', true);
        } else {
          _this2.set('triggeredOptions.isMessageCycle', !_this2.get('selectedRule.isInterval'));
        }
        _this2.set('switchesInitialized', true);
      });
    },
    initInfo: function initInfo() {
      if (this.isRuleInitialized) return;
      this.initPushLimit();
      this.initIntervals();
      this.initDelay();
      this.initCooldownRepeat();
      this.initCycle();
      this.initCooldownReset();
    },
    setDefaultValues: function setDefaultValues(type) {
      if (Ember.isEqual(type, 'intervalLimit')) {
        // Message Limit
        this.set('triggeredOptions.isUnlimited', true);
        this.set('intervalLimit', 1);
      } else if (Ember.isEqual(type, 'cycleLimit')) {
        // Message Limit
        this.set('cycleLimit', 1);
      } else if (Ember.isEqual(type, 'delay')) {
        // Message Delay
        this.set('model.options.delaySham', 5);
        this.set('triggeredOptions.selectedTimeUnitForDelay', this.timeUnits.objectAt(1)); // Minute
      } else if (Ember.isEqual(type, 'cooldownRepeat')) {
        // Message Interval
        this.set('model.options.cooldownShamRepeat', 1);
        this.set('triggeredOptions.selectedTimeUnitForCooldownRepeat', this.timeUnits.objectAt(3)); // Day
      } else if (Ember.isEqual(type, 'cooldownReset')) {
        // Message Cycle
        this.set('model.options.cooldownShamReset', 1);
        this.set('triggeredOptions.isStopSendingEnabled', true);
        this.set('triggeredOptions.selectedTimeUnitForCooldownReset', this.timeUnits.objectAt(3)); // Day
      }
    },
    initPushLimit: function initPushLimit() {
      this.set('triggeredOptions.hasMessageLimit', this.model.options.limit > 0);
      this.set('messageLimit', this.model.options.limit || 0);
    },
    // Message Scheduling Type 1
    // Push Level
    initCycle: function initCycle() {
      this.set('cycleLimit', 0);
      this._isCycle ? this.set('cycleLimit', this.get('model.options.max')) : this.setDefaultValues('cycleLimit');
    },
    // Message Scheduling Type 2
    // Rule Level
    initIntervals: function initIntervals() {
      this.set('intervalLimit', 0);
      this.get('selectedRule.isInterval') ? this._setIntervalFromRule() // Covers all the edge cases of setting limit from the rule
      : this.setDefaultValues('intervalLimit');
    },
    // Push Level
    initDelay: function initDelay() {
      if (this.get('model.options.delay') > 0) {
        var period = this.convertSecondsToPeriodObject(this.get('model.options.delay'));
        this.set('triggeredOptions.isSendDelayEnabled', true);
        this.set('triggeredOptions.selectedTimeUnitForDelay', this.timeUnits.findBy('value', period.unit));
        this.set('model.options.delaySham', period.value);
      } else {
        this.setDefaultValues('delay');
      }
    },
    // Time in between message intervals (Message Scheduling Type 2)
    // Rule Level
    initCooldownRepeat: function initCooldownRepeat() {
      if (this.get('selectedRule.isInterval')) {
        var repeat = this.get('selectedRule.startScheduleRepeat');
        if (!Ember.isEmpty(repeat) && ['minute', 'hour', 'day', 'week', 'month', 'year'].includes(repeat.frequencyType)) {
          this.set('model.options.cooldownShamRepeat', repeat.frequency);
          // we use singular value for init as this is the value we have from the back-end
          this.set('triggeredOptions.selectedTimeUnitForCooldownRepeat', this.timeUnits.findBy('singularValue', repeat.frequencyType));
        } else {
          this.setDefaultValues('cooldownRepeat');
        }
      } else {
        this.setDefaultValues('cooldownRepeat');
      }
    },
    // Time before resetting message cycle (Message Scheduling Type 1)
    // Message Cycle
    initCooldownReset: function initCooldownReset() {
      // TODO. replace this logic with the new one
      if (this._isCycle) {
        var period = this.convertSecondsToPeriodObject(this.get('model.options.cooldown'));
        if (period.value > 0) {
          this.set('triggeredOptions.isStopSendingEnabled', false);
          this.set('model.options.cooldownShamReset', period.value);
          this.set('triggeredOptions.selectedTimeUnitForCooldownReset', this.timeUnits.findBy('value', period.unit));
        } else {
          this.setDefaultValues('cooldownReset');
        }
      } else {
        this.setDefaultValues('cooldownReset');
      }
    },
    /**
     * This covers all edge cases pertaining to the rule's repeat object
     * There is a logic that handles the incrementation of the message limit
     * done by the front-end. In essense, the UI will increment the value of
     * "limit" by 1, and will decrement it by 1 before sending.
     */
    _setIntervalFromRule: function _setIntervalFromRule() {
      if (!this.selectedRule.hasRepeatOptions || Ember.isEqual(this.selectedRule.startScheduleRepeat.limit, -1)) {
        this.set('intervalLimit', 1);
        this.set('triggeredOptions.isUnlimited', true);
      } else if (Ember.isEqual(this.selectedRule.startScheduleRepeat.frequencyType, 'once')) {
        this.set('intervalLimit', 1);
        this.set('triggeredOptions.isUnlimited', false);
      } else {
        // received limit value greater than 0 must be incremented by 1 on the UI
        var _adjustedLimit = this.selectedRule.startScheduleRepeat.limit + 1;
        this.set('intervalLimit', _adjustedLimit);
        this.set('triggeredOptions.isUnlimited', false);
      }
    },
    updatePushInterval: function updatePushInterval() {
      var _limit = this.triggeredOptions.isUnlimited ? -1 : Number.parseInt(this.intervalLimit) || null;
      this.set('model.options.maxIntervalSham', _limit);
    },
    actions: {
      /**
       * This function is called when an input field for the rule entity is focused.
       * It will prompt a warning modal if there is a conflicting entity with the
       * selectedRule, and will only resume back to its original flow if resolved.
       * Otherwise, the input will be blurred.
       * @param {string} className target input classname
       */
      onInputFocusRuleEdit: function onInputFocusRuleEdit(className) {
        var _this3 = this;
        if (Ember.isEqual(this._focusedInput, className)) return;
        var el = document.querySelector(".".concat(className));
        // If user is not using repeat (rule level), we do not throw warning modal
        if (this.triggeredOptions.isMessageCycle) {
          el.focus();
        } else {
          if (this.editRuleAttributesCB) {
            this.editRuleAttributesCB(
            // success callback (duplicate, edit anyways)
            function () {
              _this3.set('_focusedInput', className);
              el.addEventListener('blur', function () {
                _this3.set('_focusedInput', undefined);
              });
              el.focus();
            },
            // cancel callback (cancel)
            function () {
              el.blur();
            });
          }
        }
      },
      toggleLimit: function toggleLimit(isUnlimited) {
        var _this4 = this;
        // goign to limited when a rule has unlimited interval, check for conflict
        this.editRuleAttributesCB(function () {
          _this4.set('triggeredOptions.isUnlimited', isUnlimited);
        }, function () {}, true); // force duplicate
      },
      onChangeTriggeredOptions: function onChangeTriggeredOptions(key, cycle) {
        var _this5 = this;
        // The only modification to the option we want to block is when the user wants to choose
        // frequency option when they have contextual option selected (2nd option);
        if (Ember.isEqual(this.isContext, true) && Ember.isEqual(cycle, false)) return;
        // We should only see warning when going from unlimited to limited interval.
        // Limited intervals by definition should no longer have associated entities.
        if (cycle) {
          // to cycle
          if (this.selectedRule.hasUnlimitedInterval) {
            this.editRuleAttributesCB(function () {
              _this5.triggeredOptions.set(key, cycle);
            });
          } else {
            this.triggeredOptions.set(key, cycle);
          }
        } else {
          // to frequency
          this.triggeredOptions.set(key, cycle);
        }
      },
      toggleStopSending: function toggleStopSending(bool) {
        if (bool) {
          this.triggeredOptions.set('isStopSendingEnabled', true);
        } else {
          if (!this.isRepeatOptionEditable) {
            this.triggeredOptions.set('isStopSendingEnabled', false);
          }
        }
      },
      /**
       * Prompt user with rule conflict/duplicate modal when turning off message schedule
       * option with a rule that has an interval already.
       */
      toggleIsMessageScheduleEnabled: function toggleIsMessageScheduleEnabled() {
        var _this6 = this;
        if (this.selectedRule.isInterval && Ember.isEqual(this.triggeredOptions.isMessageScheduleEnabled, true)) {
          this.editRuleAttributesCB(function () {
            _this6.triggeredOptions.toggleProperty('isMessageScheduleEnabled');
          });
        } else {
          this.triggeredOptions.toggleProperty('isMessageScheduleEnabled');
        }
      },
      selectValue: function selectValue(model, variable, selection) {
        model ? model.set(variable, selection) : this.set(variable, selection);
      },
      /**
       * Calls the "selectValue" function only after rule edit conflict is resolved.
       * @param {object} model
       * @param {string} variable
       * @param {string} selection
       */
      selectValueConflictRule: function selectValueConflictRule(model, variable, selection) {
        var _this7 = this;
        this.editRuleAttributesCB(function () {
          _this7.send('selectValue', model, variable, selection);
        });
      },
      validateNumberInput: function validateNumberInput(value, event) {
        if (!value && event.key === '0' || event.key === '-' || event.key === '.' || event.key === 'e') {
          event.preventDefault();
        }
      },
      onPushLimitValueChanged: function onPushLimitValueChanged(value, event) {
        var _this8 = this;
        this.send('validateNumberInput', value, event);
        Ember.run.scheduleOnce('afterRender', function () {
          _this8.set('intervalLimit', Number.parseInt(_this8.intervalLimit) || null);
          _this8.errors.set('hasIntervalLimitError', _this8.intervalLimit > 0 ? false : true);
          _this8.updatePushInterval();
        }, 0);
      }
    }
  });
  _exports.default = _default;
});