define("experience-studio/services/av5-analytics-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    cachedEndpointData: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('cachedEndpointData', new Map());
    },
    generateKey: function generateKey(request) {
      return "".concat(request.domain, "_").concat(request.endpoint);
    },
    checkCache: function checkCache(key) {
      return this.cachedEndpointData.get(key);
    },
    storeCache: function storeCache(key, data) {
      this.cachedEndpointData.set(key, data);
    }
  });
  _exports.default = _default;
});