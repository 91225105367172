define("experience-studio/controllers/content-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    userPreferences: Ember.inject.service(),
    isLocationContentEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('locationContent');
    }),
    // user preferences listener here
    mainHeaderTabs: Ember.computed('isLocationContentEnabled', function () {
      var tabs = [];
      if (this.isLocationContentEnabled) {
        var routeNamePrefix = 'content-hub';
        tabs.pushObject(Ember.Object.create({
          isActive: false,
          name: 'Content',
          routeName: "".concat(routeNamePrefix, ".index")
        }));
        tabs.pushObject(Ember.Object.create({
          isActive: false,
          name: 'Location Content',
          routeName: "".concat(routeNamePrefix, ".location")
        }));
      }
      return tabs;
    })
  });
  _exports.default = _default;
});