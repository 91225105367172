define("experience-studio/routes/communications-hub", ["exports", "experience-studio/mixins/authenticated-route"], function (_exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, {
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'communications-hub.index') {
        this.transitionTo('communications-hub.push-messages-v3');
      }
    },
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        plugins: store.findAll('plugin'),
        rules: store.query('rule', {
          status: 'production'
        }),
        languages: store.queryRecord('project-config', {
          setting: 'language'
        })
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        isInfoSidebarOpen: false,
        mainHeaderTabs: this.getMainHeaderTabs(),
        searchKeyword: '',
        sideBarSubTitle: 'Push Notification',
        selectedEntities: [],
        isSearchFormHidden: false
      });
    },
    getMainHeaderTabs: function getMainHeaderTabs() {
      var parentRouteName = 'communications-hub';
      return [Ember.Object.create({
        isActive: false,
        name: 'Push Messages',
        routeName: "".concat(parentRouteName, ".push-messages-v3")
      })];
    },
    actions: {
      changeTab: function changeTab() {
        this.controller.set('searchKeyword', '');
      },
      refresh: function refresh() {
        this.refresh();
      },
      removeSearchKeyword: function removeSearchKeyword() {
        this.controller.set('searchKeyword', '');
      },
      updateSearchKeyword: function updateSearchKeyword(searchKeyword) {
        this.controller.set('searchKeyword', searchKeyword);
      }
    }
  });
  _exports.default = _default;
});