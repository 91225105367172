define("experience-studio/components/context-hub-attribute-location", ["exports", "experience-studio/mixins/component-data-param"], function (_exports, _componentDataParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_componentDataParam.default, {
    areaModel: null,
    attributes: null,
    classNames: ['context-hub-attribute-location'],
    data: null,
    errorMessage: null,
    labels: null,
    locationModel: null,
    selectedLabels: null,
    selectedLocationLabels: null,
    isRefreshLocationModel: false,
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    filteredModel: Ember.computed('isRefreshLocationModel', 'data', function () {
      var data = this.data;
      if (!data) {
        data = this.utility.btoa({
          currentPage: 1,
          recordsPerPage: 10
        });
      }
      return this.fetchModel('location', {
        data: data
      });
    }),
    filterState: window.Object.freeze([Ember.Object.create({
      title: '',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-model-select',
        label: '',
        id: 'label',
        options: [Ember.Object.create({
          modelName: 'label',
          // used to look up model in store
          modelRepresentation: ['name', 'value'],
          // used to retrieve id or value to be saved in param
          value: undefined,
          label: 'Labels',
          property: 'labels'
        })]
      })]
    }), Ember.Object.create({
      title: 'Created by',
      id: 'createdBy',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-user-select',
        label: '',
        id: 'user',
        options: [Ember.Object.create({
          modelName: 'user',
          // used to look up model in store
          modelRepresentation: ['id', 'name'],
          // used to retrieve id or value to be saved in param
          value: undefined,
          label: 'User',
          property: 'userIds'
        })]
      }), Ember.Object.create({
        component: 'route-model-filter-dropdown-dependent-checkbox-select',
        label: '',
        id: 'me',
        options: [Ember.Object.create({
          isSelected: false,
          value: '',
          label: 'Me',
          property: 'userIds',
          modelRepresentation: ['id', 'name']
        })]
      })]
    }), Ember.Object.create({
      title: 'Date',
      sections: [Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Created',
        id: 'createdAt',
        options: [Ember.Object.create({
          value: undefined,
          property: 'createdAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'createdAtTo'
        })]
      }), Ember.Object.create({
        component: 'route-model-filter-dropdown-date-range-select',
        label: 'Date Modified',
        id: 'modifiedAt',
        options: [Ember.Object.create({
          value: undefined,
          property: 'modifiedAtFrom'
        }), Ember.Object.create({
          value: undefined,
          property: 'modifiedAtTo'
        })]
      })]
    })]),
    filterParams: window.Object.freeze([Ember.Object.create({
      id: 'address',
      component: 'filter-pill-address',
      value: null
    }), Ember.Object.create({
      id: 'userIdMe',
      component: 'filter-pill-user',
      value: null
    }), Ember.Object.create({
      id: 'userIds',
      component: 'filter-pill-user',
      value: null
    }), Ember.Object.create({
      id: 'modifiedAtTo',
      component: 'filter-pill-modified-date',
      value: null
    }), Ember.Object.create({
      id: 'modifiedAtFrom',
      component: 'filter-pill-modified-date',
      value: null
    }), Ember.Object.create({
      id: 'createdAtTo',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'createdAtFrom',
      component: 'filter-pill-created-date',
      value: null
    }), Ember.Object.create({
      id: 'labels',
      component: 'filter-pill-label',
      value: null
    }), Ember.Object.create({
      id: 'searchKeyword',
      component: 'filter-pill-search',
      value: null
    })]),
    init: function init() {
      this._super.apply(this, arguments);
      this.setUserId();
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        setTimeout(function () {
          if (self.areaModel && self.areaModel.isHistorical) {
            self.set('isLabelsTabSelected', 'historical');
          } else if (self.areaModel && self.areaModel.areaTabSelected === 'label') {
            self.set('isLabelsTabSelected', 'label');
          } else {
            self.set('areaModel.areaTabSelected', 'list');
            self.set('isLabelsTabSelected', 'list');
          }
        }, 0);
      });
    },
    setUserId: function setUserId() {
      var _this = this;
      this.session.user.then(function (user) {
        _this.filterState.findBy('id', 'createdBy').sections.findBy('id', 'me').options[0].value = {
          id: user.id,
          name: user.name
        };
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.filteredModel.setEach('isSelected', false);
    },
    actions: {
      addLocation: function addLocation(model) {
        var record = null;
        if (model) {
          record = model;
        } else {
          record = this.store.createRecord('location');
        }
        this.toggleProperty('isRefreshLocationModel');
        this.setProperties({
          editLocationRecord: record,
          isAddLocation: true
        });
      },
      cancelLocation: function cancelLocation() {
        this.setProperties({
          editLocationRecord: null,
          isAddLocation: false
        });
      },
      editLocation: function editLocation(model) {
        this.send('addLocation', model);
      },
      saveLocation: function saveLocation(location) {
        if (this.areaModel.selectedAttribute) {
          this.areaModel.selectedAttribute.setProperties({
            location: location,
            value: location.id
          });
        }
        this.toggleProperty('isRefreshLocationModel');
        this.setProperties({
          editLocationRecord: null,
          isAddLocation: false
        });
      },
      hideConfirmationModal: function hideConfirmationModal() {
        this.set('isConfirmationLocatiobTabModalVisible', false);
      },
      promptChangeTab: function promptChangeTab(value, isHistoricalTab) {
        this.setProperties({
          isConfirmationLocatiobTabModalVisible: true,
          isLabelsTabSelectedTemp: value,
          isHistoricalTabTemp: isHistoricalTab
        });
      },
      changeTab: function changeTab() {
        var isLabelsTabSelected = this.get('isLabelsTabSelectedTemp');
        this.areaModel.set('areaTabSelected', isLabelsTabSelected);
        if (isLabelsTabSelected && this.areaModel.locations) {
          this.areaModel.locations.setEach('isSelected', false);
          this.areaModel.locations.clear();
        } else if (this.areaModel.labels) {
          this.areaModel.labels.clear();
        }
        var data = this.utility.btoa(Ember.Object.create({}));
        if (isLabelsTabSelected === 'list') {
          data = this.utility.btoa(Ember.Object.create({
            currentPage: 1,
            recordsPerPage: 10
          }));
        }
        this.setProperties({
          data: data,
          isConfirmationLocatiobTabModalVisible: false,
          isLabelsTabSelected: isLabelsTabSelected
        });
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      selectValue: function selectValue(location, isSelect) {
        var model = this.areaModel;
        var locations = model.locations.toArray().map(function (loc) {
          return loc.content ? loc.content : loc;
        });
        if (isSelect && !locations.includes(location)) {
          locations.pushObject(location);
        } else if (!isSelect) {
          locations.removeObject(location);
        }
        model.set('locations', locations);
        this.sendAction('selectValue', model, 'selectedAttribute', location); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});