define("experience-studio/components/edit-rule-warning-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Shows a modal window with warnings once the user tries to modify the schedule for the rule
  
    Usages:
  
      {{edit-rule-warning-modal-dialog
            modalData=modalData
            model=model
            didCreateNewRule='didCreateNewRule'
            ifUniqRuleName='ifUniqRuleName'
      }}
  */
  var _default = _baseModalDialog.default.extend({
    // PROPERTIES ----------------------------------------------------------------
    store: Ember.inject.service(),
    utility: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['edit-rule-warning-modal-dialog', 'fly-modal'],
    errorMessage: Ember.Object.create({
      title: '',
      global: ''
    }),
    isDuplicate: false,
    newRuleName: '',
    newRule: null,
    createDuplicate: false,
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
    },
    // COMPUTED ------------------------------------------------------------------
    confirmAction: Ember.computed('actionType', function () {
      var _confirmAction;
      switch (this.actionType) {
        case 'edit':
          _confirmAction = 'confirmEdit';
          break;
        case 'remove':
          _confirmAction = 'confirmRemove';
          break;
        case 'add':
          _confirmAction = 'confirmAdd';
          break;
        default:
          _confirmAction = 'confirmEdit';
          break;
      }
      return _confirmAction;
    }),
    modelName: Ember.computed('model', function () {
      return this.model.get('constructor.modelName');
    }),
    primaryText: Ember.computed('model', function () {
      switch (this.modelName) {
        case 'push-message':
          return 'Any changes you make in the ‘Delivery Details’ section will be applied to the Context Rule chosen for your target audience.';
        case 'experience':
          return 'Any changes you make in the content visibility section will be applied to the Context Rule chosen for your target audience.';
        default:
          return 'Any changes you made affecting how long the content is visible for will be applied to the Context Rule chosen for your target audience.';
      }
    }),
    confirmButtonText: Ember.computed('isDuplicate', function () {
      return this.isDuplicate ? 'Make a Copy' : 'Make Changes Anyway';
    }),
    conflictingEntitiesText: Ember.computed('pushesWithSameRule', 'experiencesWithSameRule', function () {
      if (this.pushesWithSameRule && this.pushesWithSameRule.length > 0 && this.experiencesWithSameRule && this.experiencesWithSameRule.length > 0) {
        return 'Experiences and Push Notifications';
      } else if (this.pushesWithSameRule && this.pushesWithSameRule.length > 0) {
        return 'Push Notifications';
      } else if (this.experiencesWithSameRule && this.experiencesWithSameRule.length > 0) {
        return 'Experiences';
      }
    }),
    // FUNCTIONS -----------------------------------------------------------------
    checkIfUniqRuleName: function checkIfUniqRuleName() {
      var adapter = this.store.adapterFor('rule');
      return adapter.findRuleByRuleName(this.newRuleName);
    },
    eraseSchedule: function eraseSchedule() {
      if (this.parentView.selectedRule) {
        var ruleStartDate = this.get('parentView.selectedRule.schedule.startScheduler.start');
        if (!Ember.isNone(ruleStartDate) && ruleStartDate > 0) {
          this.parentView.selectedRule.schedule.startScheduler.setProperties({
            start: 0,
            repeat: Ember.Object.create({
              frequencyType: '',
              frequency: 0,
              limit: 0
            })
          });
        }
      } else {
        var rules = this.model.computedRules;
        if (!Ember.isEmpty(rules)) {
          for (var i = 0; i < rules.length; i++) {
            var rule = rules[i];
            if (rule.isFulfilled && rule.content.schedule && rule.content.schedule.startScheduler && rule.content.schedule.startScheduler.start > 0) {
              this.sendAction('eraseRuleSchedule', rule.content, this.model); // eslint-disable-line ember/closure-actions
            }

            break;
          }
        }
      }
      var pushStartDate = this.get('model.schedule.scheduler.start');
      if (!Ember.isNone(pushStartDate) && pushStartDate > 0) {
        this.model.schedule.scheduler.setProperties({
          start: 0,
          repeat: Ember.Object.create({
            frequencyType: '',
            frequency: 0,
            limit: 0
          })
        });
      }
      this.model.setProperties({
        isImmediately: 'checked',
        isOnSchedule: ''
      });
      this.set('isModalOpen', false);
      this.sendAction('broadcastRecord', this.model); // eslint-disable-line ember/closure-actions
    },
    createNewRule: function createNewRule(newRuleName, parentView, model, actionType) {
      var _this = this;
      var promises = Ember.Object.create({
        plugins: this.store.findAll('plugin'),
        serializer: this.store.serializerFor('rule')
      });
      Ember.RSVP.hashSettled(promises).then(function (hash) {
        if (hash.plugins.state === 'fulfilled' && hash.serializer.state === 'fulfilled') {
          var selRule = parentView && parentView.selectedRule ? parentView.selectedRule : null;
          if (model.getComputedRule) {
            selRule = model.getComputedRule;
          }
          // if model using modal is experience
          if (Ember.isEqual(_this.modelName, 'experience')) {
            selRule = _this.isEditMode ? model.computedRule : model.rule;
          }
          if (selRule) {
            _this.createEmptyRule(selRule, newRuleName, hash, actionType);
            if (!_this.errorMessage.global && !_this.errorMessage.title) {
              _this.emptyRule.save().then(function () {
                if (parentView && parentView.selectedRule) {
                  parentView.set('selectedRule', _this.emptyRule);
                  parentView.model.rules[0].ruleID = _this.emptyRule.id;
                }
                // This is why the rule is at the top...
                if (model.getComputedRule) {
                  model.rules[0].ruleID = _this.emptyRule.id;
                  model.computedRules[0] = _this.emptyRule;
                  // Only save the model if it is persisted entry in the database.
                  if (Ember.isEqual(model.isNew, false)) {
                    model.save().then(function () {
                      _this.set('isModalOpen', false);
                    }).catch(function (response) {
                      var errorMessage = _this.utility.parseError(response);
                      if (errorMessage.indexOf('historical') >= 0) {
                        _this.errorMessage.set('global', _this.intl.t('errors.duplicate.rule.historical.limit'));
                      } else {
                        _this.errorMessage.set('global', _this.intl.t('errors.duplicate.rule.general'));
                      }
                    });
                  } else {
                    _this.set('isModalOpen', false);
                  }
                }
                // if model using modal is experience
                if (Ember.isEqual(_this.modelName, 'experience')) {
                  model.set('ruleId', _this.emptyRule.id);
                  _this.emptyRule.schedule.set('numberOfMinutes', selRule.schedule.numberOfMinutes);
                  _this.emptyRule.schedule.set('numberOfMinutesOperator', selRule.schedule.numberOfMinutesOperator);
                  _this.emptyRule.schedule.set('selectedPluginAttribute', selRule.schedule.selectedPluginAttribute);
                  _this.selectedRules.popObject();
                  _this.selectedRules.pushObject(_this.emptyRule);
                  if (_this.saveExperience) {
                    _this.saveExperience();
                  }
                }
              }).catch(function (response) {
                _this.errorMessage.set('global', _this.get('utility').parseError(response));
              });
            }
          }
        }
      });
    },
    createEmptyRule: function createEmptyRule(selRule, newRuleName, hash) {
      this.set('emptyRule', this.store.createRecord('rule'));
      this.emptyRule.setProperties(selRule.toJSON());
      this.emptyRule.setProperties({
        name: newRuleName,
        createdAt: 0,
        head: {
          name: newRuleName,
          arguments: selRule.head.arguments
        },
        contextualStartType: selRule.contextualStartType,
        stringRepresentation: null,
        composition: hash.serializer.value.getComposition({
          rule: this.emptyRule,
          plugins: hash.plugins.value
        })
      });
      if (selRule.stringRepresentation.indexOf('history') > 0) {
        this.errorMessage.setProperties({
          title: '',
          global: this.errorMessage.set('global', this.intl.t('errors.duplicate.rule.historical.generic'))
        });
      }
    },
    createRuleDuplicate: function createRuleDuplicate() {
      var _this2 = this;
      var actionType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var promises = Ember.Object.create({
        isUniqName: this.checkIfUniqRuleName()
      });
      Ember.RSVP.hashSettled(promises).then(function (hash) {
        if (hash.isUniqName.state === 'fulfilled') {
          if (hash.isUniqName.value.data[0].isValid) {
            _this2.errorMessage.setProperties({
              title: '',
              global: ''
            });
            _this2.createNewRule(_this2.newRuleName, _this2.parentView, _this2.model, actionType);
            if (actionType === 'remove') {
              if (_this2.setScheduleType) {
                _this2.setScheduleType('immediately');
              }
            }
          } else {
            _this2.errorMessage.set('title', 'Rule name taken. Provide a unique name.');
          }
        }
      });
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      cancel: function cancel() {
        this.set('isModalOpen', false);
        if (this.promiseResolver) {
          this.promiseResolver.resolve(false);
        }
      },
      chooseCreateDuplicate: function chooseCreateDuplicate() {
        this.toggleProperty('isDuplicate', true);
        if (this.model.getComputedRule) {
          this.set('newRuleName', "".concat(this.model.getComputedRule.name, " - Copy"));
        }
      },
      changeNewRuleName: function changeNewRuleName(event) {
        this.setProperties({
          'newRuleName': event
        });
        this.errorMessage.set('title', '');
      },
      confirmRemove: function confirmRemove() {
        if (this.isDuplicate && this.newRuleName) {
          this.createRuleDuplicate('remove');
        } else {
          this.eraseSchedule();
        }
      },
      // TODO: Refactor this, and remove deprecated functinoalities.
      confirmAdd: function confirmAdd() {
        // This is for Experience
        if (Ember.isEqual(this.modelName, 'experience')) {
          if (!this.isDuplicate && !this.newRuleName) {
            if (this.saveExperience) {
              this.saveExperience();
            }
          } else if (this.isDuplicate && this.newRuleName) {
            this.createRuleDuplicate('add');
          }
        } else if (this.isDuplicate && this.newRuleName) {
          this.createRuleDuplicate('add');
        } else {
          if (this.parentView && this.parentView.get('selectedRule.schedule.startScheduler.start')) {
            this.set('ruleStartDateUnix', this.parentView.selectedRule.schedule.startScheduler.start);
          }
          this.set('isModalOpen', false);
          if (this.openCalendar) {
            this.openCalendar();
          }
        }
      },
      confirmEdit: function confirmEdit() {
        if (this.isDuplicate && this.newRuleName) {
          this.createRuleDuplicate('add');
        } else {
          this.set('isModalOpen', false);
          // This will be used for trigger in the future when triggered absolute
          // scheduleing is required
          // if (this.openCalendar && this.model.isBroadcast) {
          //   this.openCalendar();
          // }
          if (this.promiseResolver) {
            this.promiseResolver.resolve(true);
          }
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      }
    }
  });
  _exports.default = _default;
});