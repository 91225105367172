define("experience-studio/components/localized-text", ["exports", "experience-studio/mixins/localized-input-text-component", "experience-studio/config/environment", "experience-studio/helpers/dynamic-attribute-rtf-serializer"], function (_exports, _localizedInputTextComponent, _environment, _dynamicAttributeRtfSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_localizedInputTextComponent.default, {
    classNames: ['localized-text'],
    emptyMessage: '',
    userPreferences: Ember.inject.service(),
    isRichTextEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      var isNonTdOrUsEnv = !(_environment.default.environment.includes('x-production-td') || _environment.default.environment.includes('x-production-us'));
      var isRTFEnabled = isNonTdOrUsEnv || this.userPreferences.getValue('richTextFormatting');
      return isRTFEnabled && this.isRichTextFormat;
    }),
    dynamicAttrPluginAttrsMap: Ember.computed('plugins.content.[].attributes', function () {
      if (!(this.plugins && this.plugins.content && this.plugins.content.length)) return [];
      var pluginAttrMap = {};
      this.plugins.content.forEach(function (plugin) {
        if (plugin._record) {
          var attributes = plugin._record.attributes;
          for (var attrKey in attributes) {
            if (attributes.hasOwnProperty(attrKey)) {
              pluginAttrMap[attributes[attrKey].uid] = "".concat(plugin._record.name, " / ").concat(attributes[attrKey].name);
            }
          }
        }
      });
      return pluginAttrMap;
    }),
    didSelectLanguage: Ember.observer('languages.@each.isSelected', function () {
      var language = this.languages.findBy('isSelected', true);
      this.set('formClass', language.direction === 'rtl' ? 'localized-text rtl' : 'localized-text');
    }),
    decodedDynamicTextValue: Ember.computed('value', function () {
      if (this.value) {
        var value = this.value;
        if (typeof this.value !== 'string') {
          value = (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(this.value.html, this.dynamicAttrPluginAttrsMap, this.isRichTextEnabled, true);
        }
        try {
          return (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(value, this.dynamicAttrPluginAttrsMap, this.isRichTextEnabled, true);
        } catch (exception) {
          return value;
        }
      }
      return this.value;
    }),
    decodedTextValue: Ember.computed('value', function () {
      if (this.value) {
        var value = this.value;
        if (typeof this.value !== 'string') {
          value = this.value.html;
        }
        try {
          return (0, _dynamicAttributeRtfSerializer.deserializeDynamicAttributes)(decodeURIComponent(value), this.dynamicAttrPluginAttrsMap, this.isRichTextEnabled, true).replaceAll('</p>', '<br></p>');
        } catch (exception) {
          return value;
        }
      }
      return this.value;
    }),
    localizedTextValue: Ember.observer('value', function () {
      if (this.value) {
        this.send('textValue');
      }
    }),
    actions: {
      textValue: function textValue() {
        if (typeof this.localizedTextData !== 'undefined') {
          this.localizedTextData(this.value);
        }
      }
    }
  });
  _exports.default = _default;
});