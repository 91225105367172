define("experience-studio/components/base-modal-dialog", ["exports", "ember-keyboard-shortcuts"], function (_exports, _emberKeyboardShortcuts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['base-modal-dialog'],
    model: null,
    keyboardShortcuts: null,
    tabEventHandler: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('keyboardShortcuts', {
        'esc': 'close',
        'tab': 'handleTabPress'
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
      this.set('tabEventHandler', this.createTabEventHandler());
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.tabEventHandler) {
        (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
      }
    },
    willCloseModal: function willCloseModal() {
      this.set('model', null);
      return this;
    },
    createTabEventHandler: function createTabEventHandler() {
      var i = 0;
      var dom = this.element ? this.element : document;
      var focusableEls = dom.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"], [role="button"]');
      return function () {
        var el = focusableEls[i++ % focusableEls.length];
        Ember.run.later(function () {
          el.focus();
        }, 0);
      };
    },
    actions: {
      performAction: function performAction(params) {
        if (this.performAction) {
          return this.performAction(params);
        }
      },
      handleTabPress: function handleTabPress() {
        this.tabEventHandler();
      },
      close: function close() {
        this.willCloseModal();
      }
    }
  });
  _exports.default = _default;
});