define("experience-studio/components/labeled-locations-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['location-list-item, labeled-location-list-item'],
    tagName: 'li',
    model: null,
    isViewInfo: false,
    actions: {
      viewInfo: function viewInfo() {
        this.toggleProperty('isViewInfo');
      }
    }
  });
  _exports.default = _default;
});