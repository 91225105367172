define("experience-studio/mixins/obj-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var s4 = function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  };
  var obj = {
    guid: function guid(tuples) {
      var str = 'js';
      if (tuples && tuples > 0) {
        for (var i = 0; i < tuples; i++) {
          str += str !== 'js' ? "-".concat(s4()) : s4();
        }
        return str;
      }
      return "js".concat(s4()).concat(s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4(), "-").concat(s4()).concat(s4()).concat(s4());
    },
    removeObject: function removeObject(arr, obj, findCallback) {
      var index = arr.indexOf(obj);
      if (findCallback) {
        var objs = arr.filter(findCallback);
        if (objs.length > 0) {
          index = arr.indexOf(objs[0]);
        }
      }
      if (index >= 0) {
        return arr.splice(index, 1);
      }
      return arr;
    }
  };
  obj.extend = function () {
    function isMergeableObject(val) {
      var nonNullObject = val && _typeof(val) === 'object';
      return nonNullObject && window.Object.prototype.toString.call(val) !== '[object RegExp]' && window.Object.prototype.toString.call(val) !== '[object Date]';
    }
    function emptyTarget(val) {
      return Array.isArray(val) ? [] : {};
    }
    function cloneIfNecessary(value, optionsArgument) {
      var clone = optionsArgument && optionsArgument.clone === true;
      return clone && isMergeableObject(value) ? deepmerge(emptyTarget(value), value, optionsArgument) : value; // eslint-disable-line max-len
    }

    function defaultArrayMerge(target, source, optionsArgument) {
      var destination = target.slice();
      source.forEach(function (e, i) {
        if (typeof destination[i] === 'undefined') {
          destination[i] = cloneIfNecessary(e, optionsArgument);
        } else if (isMergeableObject(e)) {
          destination[i] = deepmerge(target[i], e, optionsArgument);
        } else if (target.indexOf(e) === -1) {
          destination.push(cloneIfNecessary(e, optionsArgument));
        }
      });
      return destination;
    }
    function mergeObject(target, source, optionsArgument) {
      var destination = {};
      if (isMergeableObject(target)) {
        window.Object.keys(target).forEach(function (key) {
          destination[key] = cloneIfNecessary(target[key], optionsArgument);
        });
      }
      window.Object.keys(source).forEach(function (key) {
        if (!isMergeableObject(source[key]) || !target[key]) {
          destination[key] = cloneIfNecessary(source[key], optionsArgument);
        } else {
          destination[key] = deepmerge(target[key], source[key], optionsArgument);
        }
      });
      return destination;
    }
    function deepmerge(target, source, optionsArgument) {
      var array = Array.isArray(source);
      var options = optionsArgument || {
        arrayMerge: defaultArrayMerge
      };
      var arrayMerge = options.arrayMerge || defaultArrayMerge;
      if (array) {
        return Array.isArray(target) ? arrayMerge(target, source, optionsArgument) : cloneIfNecessary(source, optionsArgument); // eslint-disable-line max-len
      } else {
        return mergeObject(target, source, optionsArgument);
      }
    }
    deepmerge.all = function deepmergeAll(array, optionsArgument) {
      if (!Array.isArray(array) || array.length < 2) {
        throw new Error('first argument should be an array with at least two elements');
      }

      // we are sure there are at least 2 values, so it is safe to have no initial value
      return array.reduce(function (prev, next) {
        return deepmerge(prev, next, optionsArgument);
      });
    };
    return deepmerge;
  }();
  var _default = obj;
  _exports.default = _default;
});