define("experience-studio/templates/components/context-hub-attribute-v2-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jnohWPin",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"label\"],[12],[2,\"Which attribute of \"],[1,[35,0,[\"originalModel\",\"name\"]]],[2,\" you would like to add?\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group attributes-form-group\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"options\",\"onchange\",\"renderInPlace\",\"searchEnabled\",\"placeholder\",\"selected\"],[[35,2],[30,[36,1],[[32,0],\"setAttribute\",[35,0],\"selectedAttribute\"],null],true,false,\"Select an attribute\",[35,0,[\"selectedAttribute\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"composition\",\"action\",\"attributes\",\"power-select\"]}",
    "moduleName": "experience-studio/templates/components/context-hub-attribute-v2-dropdown.hbs"
  });
  _exports.default = _default;
});