define("experience-studio/controllers/authorize/saml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['token'],
    token: ''
  });
  _exports.default = _default;
});