define("experience-studio/inline/google-tag-manager", [], function () {
  "use strict";

  /* eslint-disable */
  (function (w, d, s, l, i) {
    try {
      if (window.location.origin.indexOf('localhost') < 0) {
        var appName = 'experience-studio';
        var config = JSON.parse(decodeURIComponent(document.querySelector("meta[name=\"".concat(appName, "/config/environment\"]")).getAttribute('content')));
        i = config.APP.googleTagManagerContainerId;
      }
    } catch (exception) {
      console.log('Failed to retrieve Google Tag Manager\'s container ID'); // eslint-disable-line
    }

    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-TNBDRXX');
});