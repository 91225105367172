define("experience-studio/templates/components/manage-locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EIf4Zhdz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner clearfix\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary btn-add\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"add\"],null],[12],[2,\"+ New Location\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "experience-studio/templates/components/manage-locations.hbs"
  });
  _exports.default = _default;
});