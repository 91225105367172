define("experience-studio/mixins/route-model-table-actions-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    utility: Ember.inject.service(),
    actions: {
      duplicateRecord: function duplicateRecord(params) {
        var record = params.record;
        var contentTemplate = this.store.findRecord('content-template', record.contentTemplateId);
        if (record) {
          if (record.contentTemplateType.toLowerCase().indexOf('survey') > -1) {
            this.send('showModal', Ember.Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: "The content \"".concat(record.name, "\" is a survey."),
              secondaryMessage: 'You cannot duplicate this content.',
              title: 'Invalid Action'
            }));
          } else {
            var data = record.toJSON();
            data.originalContentInstanceId = record.get('id');
            this.transitionTo("content-hub.content-template.create", contentTemplate, {
              queryParams: {
                data: this.utility.btoa(data)
              }
            });
          }
        }
      }
    }
  });
  _exports.default = _default;
});