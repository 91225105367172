define("experience-studio/components/labeled-locations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['location-list', 'labeled-location-list'],
    attributes: null,
    model: null,
    selectedLocationLabelsHash: null
  });
  _exports.default = _default;
});