define("experience-studio/models/user-preference", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    key: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    value: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    hasOptIn: (0, _attr.default)('boolean'),
    hasViewedTour: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    createdAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    modifiedAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
  _exports.default = _default;
});