define("experience-studio/components/content-tmp-edit-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-email
      field=field
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-email'],
    valueProxy: Ember.computed('field.value', {
      get: function get() {
        return this.get('field.value');
      },
      set: function set(key, value) {
        if (!Ember.isEmpty(value)) {
          this.set('field.value', value);
        }
        return value;
      }
    })
  });
  _exports.default = _default;
});