define("experience-studio/models/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    fileExt: _emberData.default.attr('string'),
    fileName: _emberData.default.attr('string'),
    isPrivate: _emberData.default.attr('boolean'),
    originalFileName: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    uploaderID: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    sizeStr: Ember.computed('size', function () {
      var size = this.size;
      if (size < 1000) {
        return "".concat(size, " B");
      } else if (size < 1000000) {
        return "".concat((size / 1000).toFixed(1), " KB");
      } else if (size < 1000000000) {
        return "".concat((size / 1000000).toFixed(1), " MB");
      } else if (size < 1000000000000) {
        return "".concat((size / 1000000000).toFixed(1), " GB");
      }
      return "".concat(size, " B");
    })
  });
  _exports.default = _default;
});