define("experience-studio/components/parameterized-text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = Ember.Component.extend({
    model: '',
    braceOpen: false,
    browser: '',
    classNames: ['parameterized-text-field'],
    currentToken: null,
    delimiters: {
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      length: 2,
      prefix: '\{\{',
      // eslint-disable-line no-useless-escape
      postfix: '\}\}' // eslint-disable-line no-useless-escape
    },

    discoveredTokens: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    inputElement: null,
    insertionKeyCode: '13',
    inToken: false,
    isTextArea: false,
    options: [
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    Ember.Object.create({
      name: 'user',
      uid: 'ctx.sdk.user',
      attributes: [Ember.Object.create({
        name: 'user.firstName',
        uid: 'ctx.sdk.user.firstName'
      }), Ember.Object.create({
        name: 'user.lastName',
        uid: 'ctx.sdk.user.lastName'
      }), Ember.Object.create({
        name: 'user.homeNumber',
        uid: 'ctx.sdk.user.homeNumber'
      }), Ember.Object.create({
        name: 'user.cellNumber',
        uid: 'ctx.sdk.user.cellNumber'
      })]
    }), Ember.Object.create({
      name: 'usssr',
      uid: 'ctx.sdk.user',
      attributes: [Ember.Object.create({
        name: 'usssr.firstName',
        uid: 'ctx.sdk.user.firstName'
      }), Ember.Object.create({
        name: 'usssr.lastName',
        uid: 'ctx.sdk.user.lastName'
      }), Ember.Object.create({
        name: 'usssr.homeNumber',
        uid: 'ctx.sdk.user.homeNumber'
      }), Ember.Object.create({
        name: 'usssr.cellNumber',
        uid: 'ctx.sdk.user.cellNumber'
      })]
    }), Ember.Object.create({
      name: 'location',
      uid: 'ctx.sdk.location',
      attributes: [Ember.Object.create({
        name: 'location.phone',
        uid: 'ctx.sdk.location.phone'
      }), Ember.Object.create({
        name: 'location.computer',
        uid: 'ctx.sdk.location.computer'
      }), Ember.Object.create({
        name: 'location.car',
        uid: 'ctx.sdk.location.car'
      })]
    }), Ember.Object.create({
      name: 'address',
      uid: 'ctx.sdk.address',
      attributes: [Ember.Object.create({
        name: 'address.home',
        uid: 'ctx.sdk.address.home'
      }), Ember.Object.create({
        name: 'address.work',
        uid: 'ctx.sdk.address.work'
      }), Ember.Object.create({
        name: 'address.favourite',
        uid: 'ctx.sdk.address.favourite'
      })]
    })],
    subOptions: Ember.computed('options', function () {
      var subOptions = [];
      this.options.forEach(function (option) {
        subOptions = subOptions.concat(option.attributes);
      });
      return subOptions;
    }),
    suggestions: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

    actions: {
      createToken: function createToken() {
        this.initToken();
        this.discoverTokens();
        this.generateSuggestions();
      },
      parseOnKeyDown: function parseOnKeyDown(argument, event) {
        if (event) {
          if (event.key === 'Backspace') {
            this.removeToken();
            this.jumpInToken(event);
          } else if (event.key === 'Enter' || event.key === ' ' || event.key === 'Tab') {
            if (this.carrotInToken()) {
              event.preventDefault();
            }
            this.jumpOutToken(event);
          } else if (event.key === 'ArrowLeft') {
            this.jumpInToken(event);
          }
        }
      },
      parseOnKeyUp: function parseOnKeyUp(argument, event) {
        this.discoverTokens();
        this.carrotInToken();
        if (event) {
          if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
            this.insertCloseBrace();
          }
        }
        this.generateSuggestions();
      },
      setCurrentTokenName: function setCurrentTokenName(option) {
        var currentToken = this.currentToken;
        var newString = option.name;
        this.insertValueInInput(currentToken.index.innerStart, currentToken.index.innerEnd, newString); // eslint-disable-line max-len
        this.setCursorTo(currentToken.index.innerStart + newString.length);
        this.discoverTokens();
        this.carrotInToken();
        this.generateSuggestions();
      },
      exportInput: function exportInput() {
        var tokens = this.discoveredTokens;
        var delimiters = this.delimiters;
        if (tokens && tokens.length > 0) {
          var _iterator = _createForOfIteratorHelper(this.discoveredTokens),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var token = _step.value;
              if (token.option) {
                this.insertValueInInput(token.index.outerStart, token.index.outerEnd, delimiters.prefix + token.option.uid + delimiters.postfix);
              } else {
                this.insertValueInInput(token.index.outerStart, token.index.outerEnd, '');
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
        this.inputElement.value = '';
      }
    },
    carrotInToken: function carrotInToken() {
      var token = this.getToken(this.getCarrotPosition());
      this.set('currentToken', token);
      return !!token;
    },
    checkOpenBrace: function checkOpenBrace() {
      var self = this;
      function fixCount(fix) {
        return (self.get('model').match(new RegExp(fix, 'g')) || []).length;
      }
      var prefixCount = fixCount(this.delimiters.prefix);
      var postfixCount = fixCount(this.delimiters.postfix);
      this.set('braceOpen', prefixCount !== postfixCount);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('inputElement', this.$('#token-match-field')[0]);
      this.set('browser', this.$('#token-match-field')[0].selectionStart || this.$('#token-match-field')[0].selectionStart === '0' ? 'Regular' : document.selection ? 'InternetExplorer' : false);
    },
    discoverTokens: function discoverTokens() {
      var delimiters = this.delimiters;
      var expression = new RegExp("".concat(delimiters.prefix, "(.*?)").concat(delimiters.postfix));
      var string = this.model;
      var tokens = [];
      var tokenValue = expression.exec(string);
      var parseDistance = 0;
      while (tokenValue !== null) {
        // Leading characters before token, used for indexing token
        var leadingCharacters = string.substring(0, string.indexOf(tokenValue[0]));
        // Total characters parsed this iteration
        var alreadyParsed = leadingCharacters + tokenValue[0];
        // Create a new Token
        var token = Ember.Object.create({
          markup: tokenValue[0],
          // Return the word (removing improperly formatted delimiters
          word: tokenValue[1].replace(new RegExp("".concat(delimiters.prefix, "|").concat(delimiters.postfix), 'g'), ''),
          index: {
            outerStart: parseDistance + leadingCharacters.length + 1,
            outerEnd: parseDistance + alreadyParsed.length,
            innerStart: parseDistance + leadingCharacters.length + delimiters.length,
            innerEnd: parseDistance + alreadyParsed.length - delimiters.length
          },
          length: tokenValue[0].length,
          option: null
        });
        token.option = this.matchToken(token.word);
        tokens.push(token);
        // To move on to next token, removes what is already parsed
        string = string.replace(alreadyParsed, '');
        // Iterate
        parseDistance += alreadyParsed.length;
        tokenValue = expression.exec(string);
      }
      this.set('discoveredTokens', tokens);
    },
    generateSuggestions: function generateSuggestions() {
      if (this.currentToken) {
        var query = this.currentToken.word.toLowerCase().trim();
        var options = this.options;
        var subOptions = this.subOptions;
        var suggestions = [];
        if (query && query.length > 0) {
          var queryDepth = query.split('.').length;
          if (queryDepth === 1) {
            suggestions = options.filter(function (option) {
              return option.name.toLowerCase().indexOf(query) > -1;
            });
            if (suggestions.length === 0) {
              this.set('suggestions', options);
            } else if (suggestions.length === 1) {
              if (suggestions[0].name.toLowerCase() === query) {
                this.set('suggestions', suggestions[0].attributes);
              } else {
                this.set('suggestions', suggestions);
              }
            } else {
              this.set('suggestions', suggestions);
            }
          } else if (queryDepth === 2) {
            suggestions = subOptions.filter(function (option) {
              return option.name.toLowerCase().indexOf(query) > -1;
            });
            if (suggestions.length === 0) {
              this.set('suggestions', options);
            } else if (suggestions.length === 1) {
              if (suggestions[0].name.toLowerCase() === query) {
                this.set('suggestions', []);
              } else {
                this.set('suggestions', suggestions);
              }
            } else {
              this.set('suggestions', suggestions);
            }
          } else {
            this.set('suggestions', options);
          }
        } else if (query.length === 0) {
          this.set('suggestions', options);
        }
      }
    },
    getCarrotPosition: function getCarrotPosition() {
      var inputElement = this.inputElement;
      var carrotPosition = 0;
      if (this.browser === -'InternetExplorer') {
        inputElement.focus();
        var range = document.selection.createRange();
        range.moveStart('character', -inputElement.value.length);
        carrotPosition = range.text.length;
      } else if (this.browser === 'Regular') {
        carrotPosition = inputElement.selectionStart;
      }
      return carrotPosition;
    },
    getToken: function getToken(inputIndex) {
      var _iterator2 = _createForOfIteratorHelper(this.discoveredTokens),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var token = _step2.value;
          if (inputIndex + 1 < token.index.outerStart) {
            return null;
          } else if (inputIndex + 1 > token.index.outerStart && inputIndex < token.index.outerEnd) {
            return token;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      return null;
    },
    initToken: function initToken() {
      if (!this.carrotInToken() && !this.braceOpen) {
        var delimiters = this.delimiters;
        var token = delimiters.prefix + delimiters.postfix;
        var carrotPos = this.getCarrotPosition();
        this.insertValueInInput(carrotPos, carrotPos, token);
        this.setCursorTo(carrotPos + token.length - delimiters.length);
      }
    },
    insertCloseBrace: function insertCloseBrace() {
      var carrotPos = this.getCarrotPosition();
      if (!this.carrotInToken() && carrotPos !== 0) {
        var prefixLength = this.delimiters.prefix.length;
        var substring = this.model.substring(carrotPos - prefixLength, carrotPos);
        if (substring === this.delimiters.prefix) {
          this.insertValueInInput(carrotPos, carrotPos, this.delimiters.postfix);
          this.setCursorTo(carrotPos);
        }
      }
    },
    insertValueInInput: function insertValueInInput(leftIndex, rightIndex, value) {
      var inputElement = this.inputElement;
      var front = inputElement.value.substring(0, leftIndex);
      var back = inputElement.value.substring(rightIndex, inputElement.value.length);
      inputElement.focus();
      inputElement.value = front + value + back;
    },
    jumpInToken: function jumpInToken() {},
    jumpOutToken: function jumpOutToken(event) {
      if (this.carrotInToken()) {
        event.preventDefault();
        var currentToken = this.currentToken;
        this.setCursorTo(currentToken.index.outerEnd);
      }
    },
    matchToken: function matchToken(text) {
      var textLength = text.split('.').length;
      if (textLength === 1) {
        var _iterator3 = _createForOfIteratorHelper(this.options),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var option = _step3.value;
            if (option.name === text) {
              return option;
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      }
      if (textLength === 2) {
        var _iterator4 = _createForOfIteratorHelper(this.subOptions),
          _step4;
        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var _option = _step4.value;
            if (_option.name === text) {
              return _option;
            }
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
      }
      return null;
    },
    removeToken: function removeToken() {
      var inputElement = this.inputElement;
      var delimiterLength = this.delimiters.length;
      var carrotPos = this.getCarrotPosition();
      var front = inputElement.value.substring(carrotPos - delimiterLength, carrotPos);
      var back = inputElement.value.substring(carrotPos, carrotPos + delimiterLength);
      var leftSide = inputElement.value.substring(carrotPos - delimiterLength * 2, carrotPos);
      if (back === this.delimiters.postfix && front === this.delimiters.prefix) {
        this.insertValueInInput(carrotPos - this.delimiters.postfix.length + 1, carrotPos + this.delimiters.postfix.length, '');
        this.setCursorTo(carrotPos);
      } else if (leftSide === this.delimiters.prefix + this.delimiters.postfix) {
        this.insertValueInInput(carrotPos - this.delimiters.postfix.length * 2 + 1, carrotPos, '');
        this.setCursorTo(carrotPos);
      }
    },
    setCursorTo: function setCursorTo(position) {
      var inputElement = this.inputElement;
      var scrollPos = inputElement.scrollTop;
      if (this.browser === 'InternetExplorer') {
        inputElement.focus();
        var ieRange = document.selection.createRange();
        ieRange.moveStart('character', -inputElement.value.length);
        ieRange.moveStart('character', position);
        ieRange.moveEnd('character', 0);
        ieRange.select();
      } else if (this.browser === 'Regular') {
        inputElement.selectionStart = position;
        inputElement.selectionEnd = position;
        inputElement.focus();
      }
      inputElement.scrollTop = scrollPos;
    }
  });
  _exports.default = _default;
});