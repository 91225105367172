define("experience-studio/components/role-permission-select-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['role-permission-select-dropdown', 'dropdown'],
    permissions: null,
    dropdownTeatherConstraints: [
    // eslint-disable-line
    {
      to: 'scrollParent',
      attachment: 'none'
    }],
    selectedCount: Ember.computed('permissions.@each.selectedCount', function () {
      var count = 0;
      this.permissions.forEach(function (permission) {
        count += permission.selectedCount;
      });
      return count;
    }),
    menuWidthStyle: Ember.computed('menuWidth', function () {
      return Ember.String.htmlSafe("width:".concat(this.menuWidth, "px"));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var buttonNode = this.$('button')[0];
      this.setProperties({
        isDropDownVisible: false,
        buttonNode: this.$('button')[0],
        menuClass: "dropdown-menu-".concat(this.elementId),
        menuWidth: buttonNode.offsetWidth
      });
      (0, _jquery.default)(window).on("resize.".concat(this.elementId), function () {
        Ember.run(function () {
          _this.set('menuWidth', buttonNode.offsetWidth);
        });
      });
      (0, _jquery.default)(document).on("click.".concat(this.elementId), function (event) {
        Ember.run(function () {
          _this.send('onClickOutside', event);
        });
      });
      (0, _jquery.default)(document).on("show.bs.dropdown.".concat(this.elementId), function () {
        Ember.run(function () {
          _this.closeDropDown();
        });
      });
    },
    closeDropDown: function closeDropDown() {
      if (this.isDropDownVisible) {
        this.set('isDropDownVisible', false);
        this.onClose();
      }
    },
    actions: {
      toggleDropDown: function toggleDropDown() {
        if (this.isDropDownVisible) {
          this.closeDropDown();
        } else {
          this.set('menuWidth', this.buttonNode.offsetWidth);
          this.set('isDropDownVisible', true);
        }
      },
      onClickOutside: function onClickOutside(event) {
        var outsideButton = !(0, _jquery.default)(event.target).closest(this.$()).length;
        var outsideMenu = !(0, _jquery.default)(event.target).closest((0, _jquery.default)(".".concat(this.menuClass))[0]).length;
        if (outsideButton && outsideMenu) {
          this.closeDropDown();
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(window).on("resize.".concat(this.elementId));
      (0, _jquery.default)(document).off("click.".concat(this.elementId));
      (0, _jquery.default)(document).off("show.bs.dropdown.".concat(this.elementId));
    }
  });
  _exports.default = _default;
});