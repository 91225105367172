define("experience-studio/templates/context-hub/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lGKu8G1A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-container location-route\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"addLocation\"],[\"addLocation\"]]]],[2,\"\\n\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"cancel\",\"fetchLabels\",\"locationModel\",\"record\",\"saveLocation\",\"userPref\"],[[30,[36,1],[\"cancelLocation\"],null],[30,[36,1],[\"fetchLabels\"],null],[35,2],[35,2],[30,[36,1],[\"saveLocation\"],null],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,10],null,[[\"data\",\"didUpdateQueryParams\",\"filterState\",\"filterParams\",\"filteredModel\",\"fetchModel\"],[[35,9],[30,[36,1],[\"updateQueryParams\"],null],[35,8],[35,7],[35,6],[30,[36,1],[\"performFetchModel\"],null]]]]],[2,\"\\n\\n  \"],[1,[30,[36,12],null,[[\"model\",\"data\",\"isLoading\",\"isMultiSelect\",\"onToggleRow\",\"performModelAction\"],[[35,6],[35,9],[35,11],true,[30,[36,1],[\"updateQueryParams\"],null],[30,[36,1],[\"performModelAction\"],null]]]]],[2,\"\\n\\n  \"],[1,[30,[36,15],null,[[\"selectedEntities\",\"pluralEntityName\",\"batchActions\",\"onClose\",\"isInfoSidebarOpen\",\"performModelBatchAction\"],[[35,13,[\"selectedEntities\"]],\"Locations\",[35,14],[30,[36,1],[\"confirmCloseBarModal\"],null],[35,13,[\"isInfoSidebarOpen\"]],[30,[36,1],[\"performModelBatchAction\"],null]]]]],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"userPref\",\"route-action\",\"locationModel\",\"add-location\",\"manage-locations\",\"if\",\"filteredModel\",\"filterParams\",\"filterState\",\"data\",\"route-model-filter\",\"isLoading\",\"route-model-table-location-data\",\"contextHubController\",\"batchActions\",\"batch-actions-bar\"]}",
    "moduleName": "experience-studio/templates/context-hub/location.hbs"
  });
  _exports.default = _default;
});