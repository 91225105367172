define("experience-studio/templates/components/content-tmp-edit-content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DpEY5QPO",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,3],null,[[\"options\",\"onchange\",\"renderInPlace\",\"searchField\",\"placeholder\",\"selected\"],[[35,2,[\"options\"]],[30,[36,1],[[32,0],\"selectValue\"],null],true,\"name\",\"Pick an option\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"selectedOption\",\"action\",\"field\",\"power-select\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-content-instance.hbs"
  });
  _exports.default = _default;
});