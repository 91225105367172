define("experience-studio/mixins/authenticated-route", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    reactES: _environment.default.APP.reactES,
    beforeModel: function beforeModel(transition) {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.session.user.then(function (user) {
          if (user.accessLevel.value < 5 || !_this.session.token) {
            _this.transitionTo('no-access');
          } else if (user.accessLevel.value === 17) {
            var url = _environment.default.APP.permissionsManagerUrl;
            url = "".concat(url, "/#/authorize?token=").concat(_this.session.token);
            window.location.replace(url);
          } else {
            _this.userPreferences.fetch().then(function () {
              return resolve();
            }).catch(function () {
              return reject();
            });
          }
        }).catch(function () {
          transition.abort();
          _this.session.authenticate(transition);
        });
      });
    }
  });
  _exports.default = _default;
});