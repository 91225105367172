define("experience-studio/templates/components/route-model-suggestions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nSQbJstH",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,0,\"btn btn-filter dropdown-toggle location-dropdown-toggle\"],[14,6,\"#\"],[14,\"role\",\"button\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[12],[2,\"\\n      \"],[1,[35,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"updateSearchParams\",[32,1]],null],[12],[2,\"\\n          \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form search-form\"],[12],[2,\"\\n    \"],[10,\"form\"],[14,0,\"form-inline\"],[14,\"onsubmit\",\"return false\"],[12],[2,\"\\n      \"],[1,[30,[36,6],null,[[\"onInput\",\"value\"],[\"selectSuggestion\",[35,5]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"currentSearchOption\",\"searchOptions\",\"-track-array\",\"each\",\"searchKeyword\",\"search-form-group\"]}",
    "moduleName": "experience-studio/templates/components/route-model-suggestions.hbs"
  });
  _exports.default = _default;
});