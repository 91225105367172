define("experience-studio/routes/content-hub/content-instance/edit", ["exports", "jquery", "experience-studio/mixins/field-validator-factory", "experience-studio/config/environment"], function (_exports, _jquery, _fieldValidatorFactory, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = Ember.Route.extend(_fieldValidatorFactory.default, {
    utility: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    contextRuleUtility: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model() {
      var contentInstance = this.modelFor('content-hub.content-instance');
      return Ember.RSVP.hash({
        contentInstance: contentInstance,
        languages: this.store.queryRecord('project-config', {
          setting: 'language'
        }),
        plugins: this.store.findAll('plugin'),
        projectCustomTemplates: this.store.queryRecord('project-config', {
          setting: 'project-custom-content-templates'
        })
      });
    },
    afterModel: function afterModel(model) {
      var isConciergeTemplate = false;
      if (model.projectCustomTemplates && model.projectCustomTemplates.config && model.projectCustomTemplates.config.categories) {
        var categories = model.projectCustomTemplates.config.categories;
        isConciergeTemplate = categories.length > 0 && this.contextRuleUtility.isConciergeTemplate(model.contentInstance.templateType);
      }
      model.contentInstance.set('isConciergeTemplate', isConciergeTemplate);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var model = this.controller.get('model');
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: model.get('name'),
        description: model.get('description'),
        image: model.get('iconUrl'),
        locked: model.get('isContextLocked') || false,
        data: model.get('root').toData().root,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var previousRouteName = this._router.getPreviousRouteName();
      if (previousRouteName) {
        this.transitionTo(previousRouteName);
      } else if (this.controller.get('previousTransition')) {
        this.controller.get('previousTransition').retry();
      } else {
        window.history.back();
      }
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        finishedLoading: false,
        languages: model.languages.config,
        plugins: model.plugins,
        selectedLabels: [],
        transitionConfirmed: false,
        displayFooter: true
      });
      controller.set('initialHash', this.utility.hash({
        name: model.contentInstance.get('name'),
        description: model.contentInstance.get('description'),
        image: model.contentInstance.get('iconUrl'),
        locked: model.contentInstance.get('isContextLocked') || false,
        data: model.contentInstance.get('content').data[0],
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      }));
      if (!model.contentTemplate) {
        this.store.findRecord('content-template', model.contentInstance.contentTemplateId).then(function (respo) {
          Ember.set(model, 'contentTemplate', respo);
          controller.set('finishedLoading', true);
        });
      } else {
        controller.set('finishedLoading', true);
      }
      this._super(controller, model);
    },
    checkDetailsData: function checkDetailsData(data) {
      if (this.currentModel.contentInstance.isConciergeTemplate) {
        if (data.root.link && data.root.link.actionType === 'LINK') {
          data.root.details = {};
        }
        if (data.root.buttons) {
          var buttonList = data.root.buttons;
          var isDetailOptionSelected = false;
          for (var count = 0; count < buttonList.length; count++) {
            if (buttonList[count].actionType === 'OPEN_DETAIL') {
              isDetailOptionSelected = true;
            }
          }
          if (!isDetailOptionSelected) {
            data.root.details = {};
          }
        }
      }
      return data;
    },
    setActionValue: function setActionValue(actionValueObj, langConfig) {
      var _actionValueObj$linkV;
      if (actionValueObj !== null && actionValueObj !== void 0 && (_actionValueObj$linkV = actionValueObj.linkValue) !== null && _actionValueObj$linkV !== void 0 && _actionValueObj$linkV.includes(':UNIVERSAL_ACTION') && !(actionValueObj !== null && actionValueObj !== void 0 && actionValueObj.isSkipUrlValidation)) {
        var validationPrefix = this.retrieveActionPrefix(actionValueObj === null || actionValueObj === void 0 ? void 0 : actionValueObj.linkValue);
        var actionValue = {};
        for (var langCount = 0; langCount < (langConfig === null || langConfig === void 0 ? void 0 : langConfig.length); langCount++) {
          var _actionValueObj$value;
          var currentLang = langConfig[langCount];
          if (actionValueObj !== null && actionValueObj !== void 0 && (_actionValueObj$value = actionValueObj.value) !== null && _actionValueObj$value !== void 0 && _actionValueObj$value[currentLang.id]) {
            if ((actionValueObj === null || actionValueObj === void 0 ? void 0 : actionValueObj.linkValue) === 'browser:UNIVERSAL_ACTION' || (actionValueObj === null || actionValueObj === void 0 ? void 0 : actionValueObj.linkValue) === 'web:UNIVERSAL_ACTION') {
              var _actionValueObj$value2;
              actionValue[currentLang.id] = validationPrefix + encodeURIComponent(actionValueObj === null || actionValueObj === void 0 ? void 0 : (_actionValueObj$value2 = actionValueObj.value) === null || _actionValueObj$value2 === void 0 ? void 0 : _actionValueObj$value2[currentLang.id]);
            } else {
              var _actionValueObj$value3;
              actionValue[currentLang.id] = validationPrefix + (actionValueObj === null || actionValueObj === void 0 ? void 0 : (_actionValueObj$value3 = actionValueObj.value) === null || _actionValueObj$value3 === void 0 ? void 0 : _actionValueObj$value3[currentLang.id]);
            }
          }
        }
        Ember.set(actionValueObj, 'value', actionValue);
      }
    },
    retrieveActionPrefix: function retrieveActionPrefix(linkValue) {
      switch (linkValue) {
        case 'mailto:UNIVERSAL_ACTION':
          return 'mail://?sendTo=';
        case 'tel:UNIVERSAL_ACTION':
          return 'phone://?phoneNumber=';
        case 'concierge:UNIVERSAL_ACTION':
          return 'concierge://?';
        case 'universalLink:UNIVERSAL_ACTION':
          return 'universalLink://?';
        case 'web:UNIVERSAL_ACTION':
          return 'web://?url=';
        case 'details:UNIVERSAL_ACTION':
          return 'details://?contentId=';
        case 'browser:UNIVERSAL_ACTION':
          return 'browser://?url=';
        case 'app:UNIVERSAL_ACTION':
          return 'app://?';
        default:
          return '';
      }
    },
    actions: {
      displayFooter: function displayFooter() {
        var isFooterDisplayed = this.controller.get('displayFooter');
        this.controller.set('displayFooter', isFooterDisplayed ? false : true);
      },
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        this.controller.model.contentInstance.rollbackAttributes();
        this.goBack();
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        this.send('cancel');
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      save: function save() {
        var _this = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var model = controller.model;
        var data = this.checkDetailsData(model.contentInstance.get('root').toData());
        var type = model.contentInstance.get('values') ? 'PUT' : 'POST';
        var selectedLabels = this.controller.get('selectedLabels');
        if (this.currentModel.contentInstance.isConciergeTemplate) {
          selectedLabels = this.currentModel.contentInstance.selectedLabels;
        }
        var url = "".concat(_environment.default.APP.hostUrl, "/kernel/content/instances/").concat(model.contentInstance.get('id'), "/data");

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          // Assign labels in general to model
          model.contentInstance.set('labels', selectedLabels.mapBy('value'));
          if (type === 'PUT') {
            url = "".concat(url, "/").concat(model.contentInstance.get('dataId'));
          }
          return new Promise(function (resolve, reject) {
            _jquery.default.ajax({
              contentType: 'application/json',
              data: JSON.stringify(data.root),
              headers: {
                'X-Authorization': _this.get('session.token'),
                'X-User-Agent': _this.get('session.userAgent')
              },
              type: type,
              url: url
            }).done(function (response) {
              resolve(response);
            }).fail(function (jqXHR) {
              reject(jqXHR);
            });
          });
        }).then(function () {
          return model.contentInstance.save();
        }).then(function () {
          _this.controller.set('transitionConfirmed', true);
          _this.goBack();
          Ember.run.scheduleOnce('afterRender', _this, function () {
            _this.send('showNotification', {
              type: 'confirm',
              message: "Changes made to \"".concat(model.contentInstance.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', _this.utility.parseError(response));
        });
      },
      validate: function validate() {
        var _this$currentModel, _this$currentModel$co;
        var controller = this.controller;
        var model = controller.get('model');
        if ((_this$currentModel = this.currentModel) !== null && _this$currentModel !== void 0 && (_this$currentModel$co = _this$currentModel.contentInstance) !== null && _this$currentModel$co !== void 0 && _this$currentModel$co.isConciergeTemplate) {
          var _model$contentInstanc;
          this.send('populateConceirgeTemplateImage', model === null || model === void 0 ? void 0 : (_model$contentInstanc = model.contentInstance) === null || _model$contentInstanc === void 0 ? void 0 : _model$contentInstanc.root);
        }
        var validator = _fieldValidatorFactory.default.getValidator(model.contentInstance.root, model.languages.config);
        var validation = validator.validate({
          triggerCascade: true
        });
        if (!Ember.isEmpty(controller.get('model.iconUrl')) && !controller.get('model.iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(controller.get('model.contentInstance.name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else if (!validation.state) {
          var plural = validation.errors.length > 1 ? 's' : '';
          controller.set('errorMessage', "You have (".concat(validation.errors.length, ") unresolved error").concat(plural, "."));
        } else {
          controller.set('errorMessage', '');
          this.send('populateConceirgeTemplateActionTypeValue', model);
          this.send('save');
        }
      },
      populateConceirgeTemplateActionTypeValue: function populateConceirgeTemplateActionTypeValue(model) {
        var _model$languages, _model$contentInstanc2, _model$contentInstanc3, _model$contentInstanc4, _model$contentInstanc5, _model$contentInstanc6, _model$contentInstanc7, _model$contentInstanc8;
        var langConfig = model === null || model === void 0 ? void 0 : (_model$languages = model.languages) === null || _model$languages === void 0 ? void 0 : _model$languages.config;
        var buttonsField = model === null || model === void 0 ? void 0 : (_model$contentInstanc2 = model.contentInstance) === null || _model$contentInstanc2 === void 0 ? void 0 : (_model$contentInstanc3 = _model$contentInstanc2.root) === null || _model$contentInstanc3 === void 0 ? void 0 : (_model$contentInstanc4 = _model$contentInstanc3.schema) === null || _model$contentInstanc4 === void 0 ? void 0 : (_model$contentInstanc5 = _model$contentInstanc4.find(function (field) {
          return field.key === 'buttons';
        })) === null || _model$contentInstanc5 === void 0 ? void 0 : _model$contentInstanc5.value;
        var linkField = model === null || model === void 0 ? void 0 : (_model$contentInstanc6 = model.contentInstance) === null || _model$contentInstanc6 === void 0 ? void 0 : (_model$contentInstanc7 = _model$contentInstanc6.root) === null || _model$contentInstanc7 === void 0 ? void 0 : (_model$contentInstanc8 = _model$contentInstanc7.schema) === null || _model$contentInstanc8 === void 0 ? void 0 : _model$contentInstanc8.find(function (field) {
          return field.key === 'link';
        });
        if (buttonsField) {
          for (var count = 0; count < buttonsField.length; count++) {
            var _buttonsField$count, _buttonsField$count$s;
            var actionValueObj = (_buttonsField$count = buttonsField[count]) === null || _buttonsField$count === void 0 ? void 0 : (_buttonsField$count$s = _buttonsField$count.schema) === null || _buttonsField$count$s === void 0 ? void 0 : _buttonsField$count$s[2];
            this.setActionValue(actionValueObj, langConfig);
          }
        } else if (linkField) {
          var _linkField$schema;
          var _actionValueObj = linkField === null || linkField === void 0 ? void 0 : (_linkField$schema = linkField.schema) === null || _linkField$schema === void 0 ? void 0 : _linkField$schema[2];
          this.setActionValue(_actionValueObj, langConfig);
        }
      },
      populateConceirgeTemplateImage: function populateConceirgeTemplateImage(model) {
        var _model$schema, _model$schema2, _mediaField$schema, _mediaField$schema$fi;
        var mediaTypeImageMap = {
          image: 'resourceURL',
          video: 'previewImgURL'
        };
        var imageField = model === null || model === void 0 ? void 0 : (_model$schema = model.schema) === null || _model$schema === void 0 ? void 0 : _model$schema.find(function (field) {
          return field.type === 'ctlocalizedimage';
        });
        var mediaField = model === null || model === void 0 ? void 0 : (_model$schema2 = model.schema) === null || _model$schema2 === void 0 ? void 0 : _model$schema2.find(function (field) {
          return field.type === 'ctlocalizedmedia';
        });
        var mediaType = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema = mediaField.schema) === null || _mediaField$schema === void 0 ? void 0 : (_mediaField$schema$fi = _mediaField$schema.find(function (field) {
          return field.key === 'mediaType';
        })) === null || _mediaField$schema$fi === void 0 ? void 0 : _mediaField$schema$fi.value;
        if (imageField && mediaType) {
          var _mediaField$schema2, _mediaField$schema2$f;
          var imageURL = {};
          var mediaURL = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema2 = mediaField.schema) === null || _mediaField$schema2 === void 0 ? void 0 : (_mediaField$schema2$f = _mediaField$schema2.find(function (field) {
            return field.key === mediaTypeImageMap[mediaType];
          })) === null || _mediaField$schema2$f === void 0 ? void 0 : _mediaField$schema2$f.value;
          for (var lang in mediaURL) {
            imageURL[lang] = _defineProperty({}, imageField.key, mediaURL[lang][mediaTypeImageMap[mediaType]]);
          }
          Ember.set(imageField, 'value', imageURL);
        } else if (imageField && mediaField) {
          Ember.set(imageField, 'value', null);
        }
      },
      willTransition: function willTransition() {
        var controller = this.controller;
        var model = controller.model;
        if (!model.contentInstance.get('isSaving') && !model.contentInstance.get('isDeleted')) {
          model.contentInstance.reload();
        }
        controller.setProperties({
          errorMessage: '',
          previousTransition: null
        });
        return true;
      }
    }
  });
  _exports.default = _default;
});