define("experience-studio/components/push-message-schedule-icon", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-message-schedule-icon'],
    scheduleTooltipText: Ember.computed('model.{activeSchedule,inactiveSchedule,activeEndSchedule,inactiveEndSchedule,status,executionHistory}', function () {
      var _this = this;
      var tooltipText = '';
      if (this.start && !this.model.isDraft && (this.model.activeSchedule || this.model.inactiveSchedule)) {
        var startWord = this.model.isTriggered ? this.model.inactiveSchedule ? 'Started at ' : 'Starts at ' : this.model.inactiveSchedule ? 'Sent at ' : 'Sends at ';
        var startDate = this.model.inactiveSchedule ? this.model.inactiveSchedule : this.model.activeSchedule;
        tooltipText = "".concat(startWord).concat(window.moment(startDate).format('h:mm a, MMM Do YYYY'), ",<br> ").concat(this.model.timeZone);
      }
      if ((this.model.get('executionHistory.lastStartDate') || this.model.get('executionHistory.lastBroadcastDate')) && this.start && !this.model.isDraft && !this.model.activeSchedule && !this.model.inactiveSchedule) {
        var lastStart = this.model.get('executionHistory.lastStartDate');
        if (this.model.get('executionHistory.lastBroadcastDate')) {
          lastStart = this.model.get('executionHistory.lastBroadcastDate');
        }
        var _startWord = this.model.isTriggered ? 'Activated manually<br>at ' : 'Sent manually<br>at ';
        tooltipText = "".concat(_startWord).concat(window.moment(lastStart * 1000).format('h:mm a, MMM Do YYYY'), ",<br> ").concat(this.model.executionHistoryTimeZone);
      }
      if (this.end && !this.model.isDraft && this.model.isTriggered && (this.model.activeEndSchedule || this.model.inactiveEndSchedule)) {
        var endWord = this.model.isTriggered ? this.model.inactiveEndSchedule ? 'Ended at ' : 'Ends at ' : '';
        var endDate = this.model.inactiveEndSchedule ? this.model.inactiveEndSchedule : this.model.activeEndSchedule;
        tooltipText = "".concat(endWord).concat(window.moment(endDate).format('h:mm a, MMM Do YYYY'), ",<br> ").concat(this.model.timeZone);
      }
      if (this.model.get('executionHistory.lastEndDate') && !this.start && !this.model.isDraft && !this.model.activeSchedule && !this.model.inactiveSchedule) {
        var lastEnd = this.model.get('executionHistory.lastEndDate');
        tooltipText = "Deactivated manually<br>at ".concat(window.moment(lastEnd * 1000).format('h:mm a, MMM Do YYYY'), ",<br> ").concat(this.model.executionHistoryTimeZone);
      }
      if (!this.start && !this.model.isDraft && this.model.isTriggered && !this.model.activeEndSchedule && !this.model.inactiveEndSchedule && (this.model.activeSchedule || this.model.isActive)) {
        tooltipText = 'Never Ends';
      }
      if (!tooltipText) {
        Ember.run.scheduleOnce('afterRender', function () {
          (0, _jquery.default)("#".concat(_this.elementId)).find('[data-toggle="tooltip"]').tooltip('dispose');
        });
      }
      return tooltipText;
    }),
    noDateDash: Ember.computed('scheduleTooltipText', function () {
      var dash = '';
      if (this.scheduleTooltipText === 'Never Ends') {
        dash = '--';
      }
      return dash;
    }),
    scheduleDateText: Ember.computed('model.{activeSchedule,activeEndSchedule,executionHistory}', function () {
      var dateText = '';
      if (this.start && !this.model.isDraft && (this.model.activeSchedule || this.model.inactiveSchedule)) {
        dateText = this.model.inactiveSchedule ? this.model.inactiveSchedule : this.model.activeSchedule;
      }
      if ((this.model.get('executionHistory.lastStartDate') || this.model.get('executionHistory.lastBroadcastDate')) && !this.end && !this.model.isDraft && !this.model.activeSchedule && !this.model.inactiveSchedule) {
        var lastStart = this.model.get('executionHistory.lastStartDate');
        if (this.model.get('executionHistory.lastBroadcastDate')) {
          lastStart = this.model.get('executionHistory.lastBroadcastDate');
        }
        dateText = lastStart * 1000;
      }
      if (this.end && !this.model.isDraft && this.model.isTriggered && (this.model.activeEndSchedule || this.model.inactiveEndSchedule)) {
        dateText = this.model.inactiveEndSchedule ? this.model.inactiveEndSchedule : this.model.activeEndSchedule;
      }
      if (this.model.get('executionHistory.lastEndDate') && !this.start && !this.model.isDraft && !this.model.activeSchedule && !this.model.inactiveSchedule) {
        var lastEnd = this.model.get('executionHistory.lastEndDate');
        dateText = lastEnd * 1000;
      }
      return dateText ? window.moment(dateText).format('MMM D/YYYY') : dateText;
    }),
    statusClassName: Ember.computed('model.status', function () {
      return Ember.String.dasherize("is-".concat(this.model.status));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)("#".concat(this.elementId)).find('[data-toggle="tooltip"]').tooltip();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)("#".concat(this.elementId)).find('[data-toggle="tooltip"]').tooltip('dispose');
    }
  });
  _exports.default = _default;
});