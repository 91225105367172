define("experience-studio/adapters/context-historical-query", ["exports", "experience-studio/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'context/ctxhistory/query',
    buildURL: function buildURL() {
      return "".concat(this._super.apply(this, arguments).replace('/context-historical-queries', ''), "?limit=1000000");
    },
    findAll: function findAll() {
      return this._super.apply(this, arguments).then(function (response) {
        if (response && response.data && response.data.length) {
          return response;
        } else {
          return {
            data: []
          };
        }
      });
    }
  });
  _exports.default = _default;
});