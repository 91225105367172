define("experience-studio/templates/components/clickable-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w9FDkQvz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"button\"],[12],[1,[30,[36,0],[\"user\"],null]],[13],[2,\" \"]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "moduleName": "experience-studio/templates/components/clickable-svg.hbs"
  });
  _exports.default = _default;
});