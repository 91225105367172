define("experience-studio/templates/components/model-list-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "smJzo2rd",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[6,[37,0],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"nav\"],[14,\"role\",\"navigation\"],[14,\"aria-label\",\"Pagination navigation\"],[14,0,\"pagination-wrapper\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"pagination\"],[12],[2,\"\\n      \"],[10,\"li\"],[14,\"aria-label\",\"Go to the previous page\"],[14,0,\"page-item\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"page-link\"],[4,[38,1],[[32,0],\"changePage\",\"previous\"],null],[12],[2,\"«\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[15,\"aria-label\",[31,[\"Go to page \",[32,1,[\"value\"]]]]],[15,0,[31,[\"page-item \",[30,[36,0],[[32,1,[\"isActive\"]],\"active\"],null]]]],[12],[2,\"\\n          \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"page-link\"],[4,[38,1],[[32,0],\"changePage\",[32,1]],null],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[10,\"li\"],[14,\"aria-label\",\"Go to the next page\"],[14,0,\"page-item\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"page-link\"],[4,[38,1],[[32,0],\"changePage\",\"next\"],null],[12],[2,\"»\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"pages\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/model-list-pagination.hbs"
  });
  _exports.default = _default;
});