define("experience-studio/mixins/recently-modified-item", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    recentlyModified: Ember.computed('modifiedAt', 'createdAt', function () {
      var highlightThresholdSeconds = _environment.default.APP.highlightThresholdSeconds;
      var timeThreshold = window.moment(Date.now()).subtract(highlightThresholdSeconds, 'seconds');
      var createdTime = window.moment(this.modifiedAt || this.createdAt);
      return createdTime.isAfter(timeThreshold);
    })
  });
  _exports.default = _default;
});