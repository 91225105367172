define("experience-studio/components/perform-action-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['batch-actions-modal', 'perform-action-modal-dialog'],
    total: 0,
    successfulRecords: null,
    failedRecords: null,
    inProgress: true,
    didInsertElement: function didInsertElement() {
      var records = this.model.records;
      var type = this.model.type;
      this.setProperties({
        total: this.model.total || this.model.records.length,
        successfulRecords: [],
        failedRecords: [],
        successHeaders: [Ember.Object.create({
          name: '#',
          class: 'number-col'
        }), Ember.Object.create({
          name: 'Name',
          class: 'name-col'
        })],
        failedHeaders: [Ember.Object.create({
          name: '#',
          class: 'number-col'
        }), Ember.Object.create({
          name: 'Name',
          class: 'name-col'
        }), Ember.Object.create({
          name: 'Error',
          class: 'error-col'
        })]
      });
      this.processRequests(type, records);
    },
    totalRecords: Ember.computed('successfulRecords.[]', 'failedRecords.[]', function () {
      return this.successfulRecords.length + this.failedRecords.length;
    }),
    successfulRecordsSham: Ember.computed('successfulRecords.[]', function () {
      return this.successfulRecords;
    }),
    failedRecordsSham: Ember.computed('failedRecords.[]', function () {
      return this.failedRecords;
    }),
    processRemainder: function processRemainder() {
      var _this = this;
      if (this.model.remainderFailure && this.model.remainderFailure.length && this.model.remainderFailureMessage) {
        this.failedRecords.pushObjects(this.model.remainderFailure.map(function (item) {
          return Ember.Object.create({
            value: item,
            error: _this.model.remainderFailureMessage
          });
        }));
      }
      if (this.model.remainderSuccess && this.model.remainderSuccess.length) {
        this.successfulRecords.pushObjects(this.model.remainderSuccess);
      }
    },
    actions: {
      retry: function retry() {
        var failedRecords = this.failedRecords.mapBy('value');
        this.performAction({
          action: this.model.retryAction,
          failedRecords: failedRecords,
          isRetry: true
        });
      },
      finish: function finish() {
        this.performAction({
          action: this.model.finishAction,
          singularEntityName: this.model.singularEntityName,
          pluralEntityName: this.model.pluralEntityName,
          numSuccess: this.successfulRecords.length,
          numFailure: this.failedRecords.length,
          records: this.model.records,
          failedPostfix: this.model.failedPostfix,
          sucessPostfix: this.model.sucessPostfix
        });
        this.send('close');
      }
    },
    displayResults: function displayResults() {
      var _this2 = this;
      Ember.run.later(function () {
        _this2.endReloadBlock();
        _this2.set('inProgress', false);
      }, 1000);
    },
    processRequests: function processRequests(type, records) {
      var _this3 = this;
      if (this.model.requests.length) {
        this.startReloadBlock();
        this.model.requests.forEach(function (request) {
          request.promise.then(function (responses) {
            responses.forEach(function (response) {
              if (response.state === 'fulfilled') {
                if (type && type === 'group' || type === 'associate') {
                  _this3.successfulRecords.pushObjects(records);
                  _this3.set('successfulRecords', _this3.successfulRecords.uniqBy('id'));
                } else {
                  _this3.successfulRecords.pushObject(response.value);
                }
              } else {
                if (type && type === 'group' || type === 'associate') {
                  _this3.failedRecords.pushObjects(records);
                  _this3.set('failedRecords', _this3.failedRecords.uniqBy('id'));
                } else {
                  _this3.failedRecords.pushObject(response.reason);
                }
              }
              if (_this3.successfulRecords.length + _this3.failedRecords.length === _this3.model.total) {
                _this3.processRemainder();
                _this3.displayResults();
              }
            });
          });
        });
      } else {
        this.processRemainder();
        this.displayResults();
      }
    },
    startReloadBlock: function startReloadBlock() {
      var reloadBlock = function reloadBlock(e) {
        // Cancel the event as stated by the standard.
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = '';
      };
      this.set('reloadBlock', reloadBlock);
      window.addEventListener('beforeunload', reloadBlock);
    },
    endReloadBlock: function endReloadBlock() {
      window.removeEventListener('beforeunload', this.reloadBlock);
      this.set('reloadBlock', null);
    }
  });
  _exports.default = _default;
});