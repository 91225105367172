define("experience-studio/templates/context-hub/context-data-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "a1dwG0iu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"context-hub-context-data-route route-container\"],[12],[2,\"\\n  \"],[1,[30,[36,15],null,[[\"deleteUploadedFile\",\"dispatchAction\",\"fileName\",\"isProcessed\",\"isShowProcessAction\",\"isStartProcessing\",\"isStartUploading\",\"isProcessImmediately\",\"isUploading\",\"isPreventReload\",\"paginationLoading\",\"model\",\"processFile\",\"progressCount\",\"removeUploadStatusSection\",\"uploadContextData\",\"uploadError\",\"uploadSuccess\",\"pollServer\"],[\"deleteUploadedFile\",\"handleAction\",[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,1],[35,0],\"processFile\",[35,6],\"removeUploadStatusSection\",\"uploadContextData\",[35,5],[35,4],\"pollServer\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"context-data-uploads-pagination-section\"],[12],[2,\"\\n\"],[6,[37,16],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],null,[[\"page\",\"loading\",\"requestPageChange\",\"model\"],[[35,2],[35,1],\"requestPageChange\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"processedContextFiles\",\"paginationLoading\",\"page\",\"model-list-pagination\",\"uploadSuccess\",\"uploadError\",\"progressCount\",\"isPreventReload\",\"isUploading\",\"isProcessImmediately\",\"isStartUploading\",\"isStartProcessing\",\"isShowProcessAction\",\"isProcessed\",\"fileName\",\"context-data-uploader\",\"unless\"]}",
    "moduleName": "experience-studio/templates/context-hub/context-data-upload.hbs"
  });
  _exports.default = _default;
});