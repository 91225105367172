define("experience-studio/templates/content-hub-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cpCJMK5G",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route content-hub-v2-route\"],[12],[2,\"\\n\\n\"],[6,[37,7],null,[[\"deselectModelListItems\",\"didUpdateSearchKeyword\",\"headerTitle\",\"isInfoSidebarOpen\",\"isSearchFormHidden\",\"selectedEntities\",\"mainHeaderTabs\",\"performAction\",\"searchKeyword\",\"sideBarTitle\"],[\"deselectModelListItems\",\"didUpdateSearchKeyword\",\"Content Hub\",[35,6],true,[35,5],[35,4],[30,[36,3],[\"performAction\"],null],[35,2],\"Content Instance\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"searchKeyword\",\"route-action\",\"mainHeaderTabs\",\"selectedEntities\",\"isInfoSidebarOpen\",\"main-header-info-sidebar\"]}",
    "moduleName": "experience-studio/templates/content-hub-v2.hbs"
  });
  _exports.default = _default;
});