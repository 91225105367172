define("experience-studio/components/route-model-filter-dropdown-model-select", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown-model-select', 'dropdown-item', 'dropdown-submenu'],
    classNameBindings: ['section.isDisabled:disabled', 'isMenuRight:pull-left'],
    model: null,
    page: 1,
    recordsPerPage: 15,
    searchKeyword: '',
    isLoading: false,
    errorMessage: '',
    canLoadModels: true,
    isDisabled: false,
    menuDirection: false,
    isMenuRight: false,
    selectedCount: Ember.computed('queryParameters', function () {
      var paramModels = this.queryParameters[this.option.property] || [];
      return paramModels.length;
    }),
    selectedModels: Ember.computed('model.@each.isSelected', function () {
      return this.model ? this.model.filterBy('isSelected', true) : [];
    }),
    option: Ember.computed('section', function () {
      return this.section.options[0];
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('model', []);
      this.loadModelPage();
      this.setupScrollBottomModelFetch();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.model && this.model.length) {
        this.preselectModels(this.model);
      }
      this.handleDisabling();
      this.set('isMenuRight', this.menuDirection === 'right');
    },
    actions: {
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchKeyword) {
        this.setProperties({
          page: 1,
          searchKeyword: searchKeyword,
          model: []
        });
        this.loadModelPage();
      },
      clearSelections: function clearSelections() {
        if (this.model && this.get('model').isAny('isSelected', true)) {
          this.model.setEach('isSelected', false);
          var queryObject = {};
          queryObject[this.option.property] = null;
          this.didUpdateQueryParams(queryObject);
        }
      },
      toggleModelSelection: function toggleModelSelection(model) {
        model.toggleProperty('isSelected');
        this.serializeModelSelection(model);
      }
    },
    loadModelPage: function loadModelPage() {
      var _this = this;
      var modelPromise = this.fetchModel(this.option.modelName, this.getProperties(['page', 'recordsPerPage', 'searchKeyword']));
      this.set('isLoading', true);
      modelPromise.then(function (models) {
        _this.model.pushObjects(models.toArray());
        _this.preselectModels(_this.model);
        _this.setProperties({
          isLoading: false,
          canLoadModels: _this.model.length < models.meta.pagination.total && models.length !== 0
        });
      }).catch(function () {
        _this.set('errorMessage', 'Model Could Not Load!');
      });
    },
    handleDisabling: function handleDisabling() {
      if (this.section.isDisabled) {
        this.send('clearSelections');
      }
    },
    preselectModels: function preselectModels(models) {
      var paramModels = this.queryParameters[this.option.property] || [];
      var modelRepresentation = this.option.modelRepresentation;
      models.setEach('isSelected', false);
      models.forEach(function (model) {
        paramModels.forEach(function (paramModel) {
          if (JSON.stringify(model.getProperties(modelRepresentation)) === JSON.stringify(paramModel)) {
            model.set('isSelected', true);
          }
        });
      });
    },
    serializeModelSelection: function serializeModelSelection(model) {
      if (this.didUpdateQueryParams) {
        var queryObject = {};
        var currentSelectedModels = this.queryParameters[this.option.property] || [];
        var modelRep = model.getProperties(this.option.modelRepresentation);
        currentSelectedModels = currentSelectedModels.reject(function (paramModel) {
          return JSON.stringify(paramModel) === JSON.stringify(modelRep);
        });
        if (model.isSelected) {
          currentSelectedModels.pushObject(modelRep);
        }
        if (!currentSelectedModels.length) {
          currentSelectedModels = null;
        }
        queryObject[this.option.property] = currentSelectedModels;
        this.didUpdateQueryParams(queryObject);
      }
    },
    setupScrollBottomModelFetch: function setupScrollBottomModelFetch() {
      var _this2 = this;
      var dropdownMenuElement = (0, _jquery.default)(this.element).find('.dropdown-menu');
      dropdownMenuElement.on("scroll.".concat(this.elementId), function () {
        requestAnimationFrame(function () {
          var scrollLength = dropdownMenuElement.height() + dropdownMenuElement.scrollTop();
          var scrollThreshold = dropdownMenuElement[0].scrollHeight - 10;
          if (scrollLength > scrollThreshold && !_this2.isLoading && _this2.canLoadModels) {
            _this2.set('page', _this2.page + 1);
            _this2.loadModelPage();
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).find('.dropdown-menu').off("scroll.".concat(this.elementId));
      this.model.setEach('isSelected', false);
    }
  });
  _exports.default = _default;
});