define("experience-studio/templates/components/route-model-sort-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZspI4Wdy",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"dropdown\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,0,\"btn btn-filter dropdown-toggle\"],[14,6,\"#\"],[14,\"role\",\"button\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[35,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Sort\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,0,\"dropdown-item\"],[4,[38,0],[[32,0],\"updateSortParams\",[32,1]],null],[12],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"currentSortOption\",\"if\",\"sortOptions\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/route-model-sort-dropdown.hbs"
  });
  _exports.default = _default;
});