define("experience-studio/components/priority-indicator-content-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    priority: Ember.computed('experience.contents', 'contentInstance', function () {
      var contentInstance = this.contentInstance;
      var contents = this.experience.contents;
      if (contents) {
        return contents.findBy('id', contentInstance.get('id')).index;
      }
    })
  });
  _exports.default = _default;
});