define("experience-studio/templates/components/fb/dropdown-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QuWQCSGP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[18,1,[[30,[36,1],null,[[\"menu\"],[[30,[36,0],[\"fb/dropdown-content\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "experience-studio/templates/components/fb/dropdown-item.hbs"
  });
  _exports.default = _default;
});