define("experience-studio/mixins/question-factory", ["exports", "experience-studio/mixins/obj-util"], function (_exports, _objUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var toValueObj = function toValueObj(root, propKey) {
    var valueObj = {};
    var localeKeys = root.localizations ? Object.keys(root.localizations) : [];
    for (var keyIndex = 0; keyIndex < localeKeys.length; keyIndex++) {
      var localeKey = localeKeys[keyIndex];
      var value = root.localizations[localeKey][propKey];
      if (value) {
        valueObj[localeKey] = value;
      }
    }
    return valueObj;
  };
  var fromValueObj = function fromValueObj(root, propKey, valueObj) {
    if (!root.localizations) {
      root.localizations = {};
    }
    var localeKeys = Object.keys(valueObj);
    for (var i = 0; i < localeKeys.length; i++) {
      var curKey = localeKeys[i];
      var localeObj = root.localizations[curKey];
      if (!localeObj) {
        localeObj = root.localizations[curKey] = {};
      }
      localeObj[propKey] = valueObj[curKey];
    }
  };
  var Choice = Ember.Object.extend({
    key: '',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('key', _objUtil.default.guid(1));
      this.set('value', Ember.Object.create());
    },
    fromJSON: function fromJSON(json) {
      if (json) {
        this.set('value', toValueObj(json, 'value'));
        this.set('key', json.key);
      }
      return this;
    },
    toJSON: function toJSON() {
      var retObj = {
        key: this.key
      };
      fromValueObj(retObj, 'value', this.value);
      return retObj;
    }
  });
  var Question = Ember.Object.extend({
    isRequired: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('choices', []);
      this.set('id', Date.now());
      this.set('value', Ember.Object.create());
      this.set('type', Ember.Object.create());
      this.set('meta', Ember.Object.create({
        number: {
          validation: false,
          min: undefined,
          max: undefined,
          increment: undefined
        }
      }));
    },
    setType: function setType(uiTypeObj) {
      this.set('type', uiTypeObj);
    },
    addChoice: function addChoice() {
      this.choices.pushObject(Choice.create());
    },
    fromJSON: function fromJSON(json) {
      if (json.metadata) {
        this.set('meta', JSON.parse(json.metadata));
      } else {
        this.set('meta', Ember.Object.create({
          uiType: 'shortAnswer',
          isRequired: false
        }));
      }
      this.set('type', Ember.Object.create({
        serverType: json.type,
        uiType: this.get('meta.uiType'),
        componentName: QuestionFactory.uiTypes[this.get('meta.uiType')].componentName,
        label: QuestionFactory.uiTypes[this.get('meta.uiType')].label
      }));
      this.set('isRequired', this.get('meta.isRequired'));
      this.set('id', json.questionNumber);
      this.set('value', toValueObj(json, 'question'));
      if (json.type === QuestionFactory.serverTypes.MULTIPLE || json.type === QuestionFactory.serverTypes.SINGLE) {
        this.set('choices', json.choices.map(function (choice) {
          return Choice.create().fromJSON(choice);
        }));
      }
      return this;
    },
    toJSON: function toJSON() {
      this.set('meta.isRequired', this.isRequired);
      this.set('meta.uiType', this.get('type.uiType'));
      if (Ember.isEmpty(this.get('meta.number.min'))) {
        this.set('meta.number.min', undefined);
      } else {
        this.set('meta.number.min', +this.get('meta.number.min'));
      }
      if (Ember.isEmpty(this.get('meta.number.max'))) {
        this.set('meta.number.max', undefined);
      } else {
        this.set('meta.number.max', +this.get('meta.number.max'));
      }
      if (Ember.isEmpty(this.get('meta.number.increment'))) {
        this.set('meta.number.increment', undefined);
      } else {
        this.set('meta.number.increment', +this.get('meta.number.increment'));
      }
      var retObj = {
        questionNumber: this.id,
        metadata: JSON.stringify(this.meta),
        type: this.get('type.serverType')
      };
      fromValueObj(retObj, 'question', this.value);
      if (this.choices && this.choices.length) {
        retObj.choices = this.choices.map(function (choice) {
          return choice.toJSON();
        });
      }
      return retObj;
    },
    duplicate: function duplicate() {
      var copy = Question.create();
      copy.fromJSON(this.toJSON());
      return copy;
    }
  });
  var QuestionFactory = {
    serverTypes: {
      NUMBER: 'number',
      STRING: 'string',
      SINGLE: 'single',
      MULTIPLE: 'multiple'
    },
    uiTypes: {
      shortAnswer: {
        label: 'Short Answer',
        serverType: 'string',
        uiType: 'shortAnswer',
        componentName: 'content-form-editor-paragraph',
        icon: 'cursor'
      },
      longAnswer: {
        label: 'Paragraph',
        serverType: 'string',
        uiType: 'longAnswer',
        componentName: 'content-form-editor-paragraph',
        icon: 'paragraph'
      },
      number: {
        label: 'Number',
        serverType: 'number',
        uiType: 'number',
        componentName: 'content-form-editor-number',
        icon: 'hash'
      },
      multipleChoice: {
        label: 'Multiple Choice',
        serverType: 'single',
        uiType: 'multipleChoice',
        componentName: 'content-form-editor-checkbox',
        icon: 'dot-circle'
      },
      checkboxes: {
        label: 'Checkboxes',
        serverType: 'multiple',
        uiType: 'checkboxes',
        componentName: 'content-form-editor-checkbox',
        icon: 'check-square'
      },
      dropdown: {
        label: 'Dropdown',
        serverType: 'single',
        uiType: 'dropdown',
        componentName: 'content-form-editor-checkbox',
        icon: 'caret-sqaure-down'
      },
      date: {
        label: 'Date',
        serverType: 'string',
        uiType: 'date',
        componentName: 'content-form-editor-paragraph',
        icon: 'calendar'
      },
      time: {
        label: 'Time',
        serverType: 'string',
        uiType: 'time',
        componentName: 'content-form-editor-paragraph',
        icon: 'clock'
      },
      location: {
        label: 'Location',
        serverType: 'string',
        uiType: 'location',
        componentName: 'content-form-editor-paragraph',
        icon: 'map-marker'
      },
      fileUpload: {
        label: 'File Upload',
        serverType: 'string',
        uiType: 'fileUpload',
        componentName: 'content-form-editor-paragraph',
        icon: 'file'
      }
    },
    getQuestion: function getQuestion(uiType) {
      uiType = this.uiTypes[uiType];
      if (uiType) {
        var question = Question.create();
        question.setType(uiType);
        return question;
      }
      return;
    },
    makeQuestion: function makeQuestion(json) {
      return Question.create().fromJSON(json);
    }
  };
  var _default = QuestionFactory;
  _exports.default = _default;
});