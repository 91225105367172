define("experience-studio/components/model-list", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isGridLayout'],
    classNames: ['model-list list-unstyled'],
    isGridLayout: true,
    isSortByPriority: false,
    showPriority: false,
    modelName: '',
    searchKeyword: '',
    tagName: 'ul',
    isModelShamEmpty: Ember.computed.notEmpty('modelSham'),
    componentName: Ember.computed('modelName', function () {
      var componentName = 'model-list-item';
      var modelName = this.modelName;
      if (modelName) {
        componentName = "".concat(componentName, "-").concat(modelName);
      }
      return componentName;
    }),
    emptyStateLinkText: Ember.computed(function () {
      return {
        'content-instance': 'Customize Content',
        'experience': 'Create an Experience',
        'rule': 'Build a Context Rule'
      }[this.modelName];
    }),
    emptyStateText: Ember.computed(function () {
      return {
        'content-instance': 'for your digital channels.',
        'experience': 'for any target audience, by combining Context and Content.',
        'rule': 'to define a custom audience or trigger for your digital experiences.'
      }[this.modelName];
    }),
    emptyStateTitle: Ember.computed(function () {
      return this.pluralizedModelName;
    }),
    modelSham: Ember.computed('searchKeyword', 'model.[]', function () {
      var _this = this;
      var model = [];
      var searchKeyword = this.searchKeyword;
      var self = this;
      if (!Ember.isEmpty(this.model)) {
        model = this.model.toArray().sort(function (a, b) {
          var isDate = false;
          var itemA = null;
          var itemB = null;
          var order = 0;
          if (self.get('isSortByPriority')) {
            itemA = _this.resolvePriority(a);
            itemB = _this.resolvePriority(b);
          } else {
            isDate = true;
            itemA = window.moment(a.get('modifiedAt') || a.get('createdAt'));
            itemB = window.moment(b.get('modifiedAt') || b.get('createdAt'));
            if (itemA.isBefore(itemB)) {
              order = 1;
            } else if (itemA.isAfter(itemB)) {
              order = -1;
            }
          }
          if (!isDate && Ember.isNone(itemA)) {
            order = 1;
          } else if (!isDate && Ember.isNone(itemB)) {
            order = -1;
          } else if (!isDate && itemA < itemB) {
            order = -1;
          } else if (!isDate && itemA > itemB) {
            order = 1;
          }
          return order;
        });
      }
      if (searchKeyword) {
        model = model.filter(function (item) {
          return item.get('name').toLowerCase().indexOf(searchKeyword) >= 0;
        });
      }
      return model;
    }),
    pluralizedModelName: Ember.computed(function () {
      var pluralizedModelName = this.modelName;
      pluralizedModelName = Ember.String.classify(pluralizedModelName);
      pluralizedModelName = pluralizedModelName.replace(/([A-Z])/g, ' $1').trim();
      pluralizedModelName = (0, _emberInflector.pluralize)(pluralizedModelName);
      if (pluralizedModelName.toLowerCase().indexOf('content') >= 0) {
        pluralizedModelName = 'Content';
      }
      if (pluralizedModelName.toLowerCase().indexOf('rules') >= 0) {
        var model = this.get('model.firstObject');
        if (model && model.get('isDraft')) {
          pluralizedModelName = "draft ".concat(pluralizedModelName);
        } else if (model) {
          pluralizedModelName = "active ".concat(pluralizedModelName);
        } else {
          pluralizedModelName = 'Context Rules';
        }
      }
      return pluralizedModelName;
    }),
    resolvePriority: function resolvePriority(model) {
      if (!Ember.isEmpty(this.sortingData)) {
        return this.sortingData[model.get('id')];
      } else if (!Ember.isEmpty(model.get('priorityIndex'))) {
        return model.get('priorityIndex');
      }
    },
    actions: {
      processFile: function processFile(item) {
        this.sendAction('processFile', item); // eslint-disable-line ember/closure-actions
      },
      removeProcessedItem: function removeProcessedItem(item) {
        this.sendAction('removeProcessedItem', item); // eslint-disable-line ember/closure-actions
      },
      deleteUploadedFile: function deleteUploadedFile(item) {
        this.sendAction('deleteUploadedFile', item); // eslint-disable-line ember/closure-actions
      },
      createModelListItem: function createModelListItem() {
        var routeName = {
          'content-instance': 'content-hub.create',
          'experience': 'experiences.create',
          'rule': 'context-hub.create'
        }[this.modelName];
        this.send('didDoubleClick', Ember.Object.create({
          routeName: routeName
        }));
      },
      customAction: function customAction(data) {
        this.sendAction('customAction', data); // eslint-disable-line ember/closure-actions
      },
      didClick: function didClick(model) {
        this.$('.dropdown.open').find('.dropdown-toggle').dropdown('toggle');
        this.sendAction('didClick', model, this.modelName); // eslint-disable-line ember/closure-actions
      },
      didDoubleClick: function didDoubleClick(data) {
        this.sendAction('didDoubleClick', data); // eslint-disable-line ember/closure-actions
      },
      showModal: function showModal(data) {
        this.sendAction('showModal', data); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});