define("experience-studio/components/analytics-notification-engagement", ["exports", "experience-studio/components/analytics-doughnut-step-card"], function (_exports, _analyticsDoughnutStepCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _analyticsDoughnutStepCard.default.extend({
    classNames: ['analytics-notification-engagement'],
    model: null,
    sliceOneValue: Ember.computed(function () {
      return this.model.uniqueEngagementRate;
    }),
    sliceTotalValue: Ember.computed(function () {
      return this.model.uniqueViews;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.model = Ember.Object.create({
        averageEngagement: 0.37,
        totalEngagement: 65000,
        uniqueViews: 85000,
        uniqueEngagementRate: 40000
      });
    }
  });
  _exports.default = _default;
});