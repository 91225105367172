define("experience-studio/components/model-list-item-group-v2", ["exports", "experience-studio/components/model-list-item-v2", "jquery"], function (_exports, _modelListItemV, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItemV.default.extend({
    classNames: ['model-list-item-group-v2'],
    didUpdate: function didUpdate() {
      this.initializeTooltips();
      this._super.apply(this, arguments);
    },
    willUpdate: function willUpdate() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.uninitializeTooltips();
      this._super.apply(this, arguments);
      (0, _jquery.default)(document).off('click.experienceModelList');
    },
    initializeTooltips: function initializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    }
  });
  _exports.default = _default;
});