define("experience-studio/mixins/field-validator-factory", ["exports", "experience-studio/mixins/field-model"], function (_exports, _fieldModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var Validation = function () {
    function Validation() {
      this.state = true;
      this.errors = [];
    }
    Validation.prototype = {
      addError: function addError(header, message, detailsObj) {
        this.state = false;
        var retObj = {
          header: header,
          message: message
        };
        if (detailsObj) {
          retObj.context = detailsObj.context;
          retObj.code = detailsObj.code;
          retObj.serverCode = detailsObj.serverCode;
        }
        this.errors.push(retObj);
        return this;
      },
      addErrors: function addErrors(errorArr) {
        if (errorArr.length > 0) {
          this.state = false;
        }
        Array.prototype.push.apply(this.errors, errorArr);
        return this;
      },
      firstError: function firstError() {
        if (this.errors.length > 0) {
          return this.errors[0];
        }
        return null;
      }
    };
    Validation.prototype.type = Validation.type = {};
    Validation.prototype.type.MALFORMED = Validation.type.MALFORMED = 1000;
    Validation.prototype.type.INVALIDARG = Validation.type.INVALIDARG = 1001;
    Validation.prototype.type.MISSINGARG = Validation.type.MISSINGARG = 1002;
    Validation.prototype.type.NOTFOUND = Validation.type.NOTFOUND = 1003;
    Validation.prototype.type.CONNECTIONERROR = Validation.type.CONNECTIONERROR = 1004;
    Validation.prototype.type.UNAUTHENTICATED = Validation.type.UNAUTHENTICATED = 1005;
    Validation.prototype.type.RETRIEVALERROR = Validation.type.RETRIEVALERROR = 1006;
    Validation.prototype.type.NOTSUPPORTED = Validation.type.NOTSUPPORTED = 1007;
    Validation.prototype.type.UNEXPECTED = Validation.type.UNEXPECTED = 1008;
    return Validation;
  }();
  var Validator = function () {
    function Validator(field, languages) {
      if (this.constructor.name === 'Object') {
        throw new Error('Abstract classes cannot be instantiated');
      }
      this.field = field;
      this.languages = languages;
    }
    Validator.prototype.validate = function (opts) {
      if (this.constructor.name === 'Object') {
        throw new Error('Abstract method not overridden');
      }
      var validation = new Validation();
      if (this.field && this.field.isRequired && !this.field.value && this.field.isVisible) {
        validation.addError('Missing required field', '', {
          code: Validation.type.MISSINGARG,
          context: this.field.key
        });
      }
      if (opts && opts.triggerCascade) {
        if (this.field) {
          Ember.set(this.field, 'revalidate', !this.field.revalidate);
        }
      }
      return validation;
    };
    return Validator;
  }();
  var ImageValidator = function () {
    function ImageValidator(field, languages) {
      Validator.call(this, field, languages);
    }
    ImageValidator.prototype = Object.create(Validator.prototype);
    ImageValidator.prototype.constructor = ImageValidator;
    ImageValidator.prototype.validate = function (opts) {
      var message = '';
      var valuex = this.field.value || '';
      var key = this.field.key;
      var validation = Validator.prototype.validate.call(this, opts);
      var locales = [];
      if (valuex) {
        if (_typeof(valuex) === 'object') {
          locales = window.Object.keys(valuex);
          for (var i = 0; i < locales.length; i++) {
            var localeKey = locales[i];
            var value = valuex[localeKey];
            if (this.field.isRequired && !value[key] && this.languages && this.languages.findBy('isDefault', true).id === localeKey) {
              message = 'Missing required field';
              validation.addError(message, '', {
                code: Validation.type.MISSINGARG,
                context: this.field.key
              });
            } else if (value[key] && !value[key].includes('data:image/jpeg;base64') && !value[key].match(/^https:/)) {
              message = 'The icon must be hosted on a secure server with the HTTPS protocol enabled.';
              validation.addError(message, '', {
                code: Validation.type.MISSINGARG,
                context: this.field.key
              });
            }
          }
        } else {
          if (valuex && !valuex.includes('data:image/jpeg;base64') && !valuex.match(/^https:/)) {
            message = 'The icon must be hosted on a secure server with the HTTPS protocol enabled.';
            validation.addError(message, '', {
              code: Validation.type.MISSINGARG,
              context: this.field.key
            });
          }
        }
      }
      return validation;
    };
    return ImageValidator;
  }();
  var MediaVideoValidator = function () {
    function MediaVideoValidator(field, languages) {
      Validator.call(this, field, languages);
    }
    MediaVideoValidator.prototype = Object.create(Validator.prototype);
    MediaVideoValidator.prototype.constructor = MediaVideoValidator;
    MediaVideoValidator.prototype.validate = function (opts) {
      var message = '';
      var valuex = this.field.value || '';
      var key = this.field.key;
      var validation = Validator.prototype.validate.call(this, opts);
      var locales = [];
      if (valuex) {
        if (_typeof(valuex) === 'object') {
          locales = window.Object.keys(valuex);
          for (var i = 0; i < locales.length; i++) {
            var localeKey = locales[i];
            var value = valuex[localeKey];
            if (this.field.isRequired && !value[key] && this.languages && this.languages.findBy('isDefault', true).id === localeKey) {
              message = 'Missing required field';
              validation.addError(message, '', {
                code: Validation.type.MISSINGARG,
                context: this.field.key
              });
            } else if (value[key] && !value[key].match(/^https:\/\/share.vidyard.com\/watch\//)) {
              message = 'Invalid URL. Please enter the URL with the format \'https://share.vidyard.com/watch/...\'';
              validation.addError(message, '', {
                code: Validation.type.MISSINGARG,
                context: this.field.key
              });
            }
          }
        } else {
          if (valuex && !valuex.match(/^https:\/\/share.vidyard.com\/watch\//)) {
            message = 'Invalid URL. Please enter the URL with the format \'https://share.vidyard.com/watch/...\'';
            validation.addError(message, '', {
              code: Validation.type.MISSINGARG,
              context: this.field.key
            });
          }
        }
      }
      return validation;
    };
    return MediaVideoValidator;
  }();
  var MediaValidator = function () {
    function MediaValidator(field, languages) {
      Validator.call(this, field, languages);
    }
    MediaValidator.prototype = Object.create(Validator.prototype);
    MediaValidator.prototype.constructor = MediaValidator;
    MediaValidator.prototype.validate = function (opts) {
      var validation = new Validation();
      var mediaType = this.field.schema.find(function (field) {
        return field.key === 'mediaType';
      });
      if (!(this.field.isRequired || mediaType.value)) return validation;
      if (opts && opts.triggerCascade) {
        if (this.field) {
          Ember.set(this.field, 'revalidate', !this.field.revalidate);
        }
      }
      for (var i = 0; i < this.field.schema.length; i++) {
        var childField = this.field.schema[i];
        var validator = ValidatorFactory.getValidator(childField, this.languages);
        if (mediaType.value === 'video' && childField.key === 'resourceURL') {
          validator = new MediaVideoValidator(childField, this.languages);
        }
        var childValidation = validator.validate(opts);
        if (!childValidation.state) {
          validation.addErrors(childValidation.errors);
        }
      }
      return validation;
    };
    return MediaValidator;
  }();
  var TextValidator = function () {
    function TextValidator(field, languages) {
      Validator.call(this, field, languages);
    }
    TextValidator.prototype = Object.create(Validator.prototype);
    TextValidator.prototype.constructor = TextValidator;
    TextValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      var valuex = this.field.value || '';
      var hasValue = false;
      var locales = [];
      if (valuex) {
        if (this.field.type === 'ctfile') {
          hasValue = true;
        } else if (_typeof(valuex) === 'object') {
          locales = window.Object.keys(valuex);
          for (var i = 0; i < locales.length; i++) {
            var localeKey = locales[i];
            var value = valuex[localeKey];
            if (value && typeof value === 'string') {
              if (!this.field.isRequired || this.languages && this.languages.findBy('isDefault', true).id === localeKey) {
                hasValue = true;
              }
              if (value.charAt(0) === ' ' || value.charAt(value.length - 1) === ' ') {
                validation.addError('You cannot have spacing before or after the body of text.', '', {
                  code: Validation.type.INVALIDARG,
                  context: this.field.key
                });
              } else {
                // eslint-disable-next-line
                var httpsExpression = /^(https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
                var httpsRegex = new RegExp(httpsExpression);
                var isHttpsUrl = !!(value || '').match(httpsRegex);
                if (!this.field.isSkipUrlValidation && this.field.isWebLink && !Ember.isEmpty(value) && this.field.isVisible) {
                  if (this.field.linkValue.includes(':UNIVERSAL_ACTION')) {
                    if (this.field.linkValue === 'mailto:UNIVERSAL_ACTION') {
                      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                      if (!value.match(validRegex)) {
                        validation.addError('Must be a valid Email.', '', {
                          code: Validation.type.INVALIDARG,
                          context: this.field.key
                        });
                      }
                    }
                    if (this.field.linkValue === 'tel:UNIVERSAL_ACTION') {
                      var _validRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                      if (!value.match(_validRegex)) {
                        validation.addError('Must be a valid phone number.', '', {
                          code: Validation.type.INVALIDARG,
                          context: this.field.key
                        });
                      }
                    }
                  } else if (!isHttpsUrl) {
                    // eslint-disable-next-line
                    var expression = '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
                    var regex = new RegExp(expression);
                    var isUrl = !!(value || '').match(regex);
                    if (!isUrl) {
                      validation.addError('Must be a valid URL.', '', {
                        code: Validation.type.INVALIDARG,
                        context: this.field.key
                      });
                    } else {
                      validation.addError('A URL beginning with "https://" is required, it will add an extra layer of security and protect customer information while they browse.', '', {
                        code: Validation.type.INVALIDARG,
                        context: this.field.key
                      });
                    }
                  }
                }
              }
            } else if (value && _typeof(value) === 'object') {
              if (!this.field.isRequired || value.text.trim() && this.languages && this.languages.findBy('isDefault', true).id === localeKey) {
                hasValue = true;
              }
            }
          }
        } else {
          // eslint-disable-next-line
          var _httpsExpression = /^(https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
          var _httpsRegex = new RegExp(_httpsExpression);
          var _isHttpsUrl = !!(valuex || '').match(_httpsRegex);
          if (this.field.isWebLink && !Ember.isEmpty(valuex) && this.field.isVisible) {
            if (!_isHttpsUrl) {
              // eslint-disable-next-line
              var _expression = '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
              var _regex = new RegExp(_expression);
              var _isUrl = !!(valuex || '').match(_regex);
              if (!_isUrl) {
                validation.addError('Must be a valid URL.', '', {
                  code: Validation.type.INVALIDARG,
                  context: this.field.key
                });
              } else {
                validation.addError('A URL beginning with "https://" is required, it will add an extra layer of security and protect customer information while they browse.', '', {
                  code: Validation.type.INVALIDARG,
                  context: this.field.key
                });
              }
            }
          }
        }
      }
      if (!hasValue && this.field.isRequired && this.field.isVisible) {
        validation.addError('Missing required field', '', {
          code: Validation.type.MISSINGARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return TextValidator;
  }();
  var StringValidator = function () {
    function StringValidator(field) {
      Validator.call(this, field);
    }
    StringValidator.prototype = Object.create(Validator.prototype);
    StringValidator.prototype.constructor = StringValidator;
    StringValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (!this.field.isRichTextEnabled) {
        // eslint-disable-next-line
        var httpsExpression = /^(https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        var httpsRegex = new RegExp(httpsExpression);
        var isHttpsUrl = !!(this.field.value || '').match(httpsRegex);
        if (this.field.isWebLink && !Ember.isEmpty(this.field.value) && this.field.isVisible) {
          if (!isHttpsUrl) {
            // eslint-disable-next-line
            var expression = '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
            var regex = new RegExp(expression);
            var isUrl = !!(this.field.value || '').match(regex);
            if (!isUrl) {
              validation.addError('Must be a valid URL.', '', {
                code: Validation.type.INVALIDARG,
                context: this.field.key
              });
            } else {
              validation.addError('A URL beginning with "https://" is required, it will add an extra layer of security and protect customer information while they browse.', '', {
                code: Validation.type.INVALIDARG,
                context: this.field.key
              });
            }
          }
        }
      } else {
        if (this.field.isRequired && this.field.value && this.field.value.text && this.field.value.text.trim() === '') {
          validation.addError('Missing required field', '', {
            code: Validation.type.MISSINGARG,
            context: this.field.key
          });
        }
      }
      return validation;
    };
    return StringValidator;
  }();
  var DropdownValidator = function () {
    function DropdownValidator(field) {
      Validator.call(this, field);
    }
    DropdownValidator.prototype = Object.create(Validator.prototype);
    DropdownValidator.prototype.constructor = StringValidator;
    DropdownValidator.prototype.validate = function (opts) {
      var validation = new Validation();
      if (this.field && this.field.isRequired && [undefined, null].includes(this.field.value)) {
        validation.addError('Missing required field', '', {
          code: Validation.type.MISSINGARG,
          context: this.field.key
        });
      }
      if (opts && opts.triggerCascade) {
        if (this.field) {
          Ember.set(this.field, 'revalidate', !this.field.revalidate);
        }
      }
      return validation;
    };
    return DropdownValidator;
  }();
  var NumberValidator = function () {
    function NumberValidator(field) {
      Validator.call(this, field);
    }
    NumberValidator.prototype = Object.create(Validator.prototype);
    NumberValidator.prototype.constructor = NumberValidator;
    NumberValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (this.field.value !== null && this.field.value !== undefined && this.field.value !== '' && isNaN(this.field.value)) {
        validation.addError('Value must be a number', '', {
          code: Validation.type.INVALIDARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return NumberValidator;
  }();
  var EmailValidator = function () {
    var regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    function EmailValidator(field) {
      Validator.call(this, field);
    }
    EmailValidator.prototype = Object.create(Validator.prototype);
    EmailValidator.prototype.constructor = EmailValidator;
    EmailValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (!Ember.isEmpty(this.field.value) && !regEx.test(this.field.value)) {
        validation.addError('Please enter a valid email address', '', {
          code: Validation.type.INVALIDARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return EmailValidator;
  }();
  var PhoneValidator = function () {
    function PhoneValidator(field) {
      Validator.call(this, field);
    }
    PhoneValidator.prototype = Object.create(Validator.prototype);
    PhoneValidator.prototype.constructor = PhoneValidator;
    PhoneValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (this.field.isRequired && Ember.isEmpty(this.field.value)) {
        validation.addError('Enter Phone Number', '', {
          code: Validation.type.INVALIDARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return PhoneValidator;
  }();
  var ColourValidator = function () {
    function ColourValidator(field) {
      Validator.call(this, field);
    }
    ColourValidator.prototype = Object.create(Validator.prototype);
    ColourValidator.prototype.constructor = ColourValidator;
    ColourValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (this.field.value !== null && this.field.value !== undefined && this.field.value !== '' && !/^#[0-9A-F]{6}$/i.test(this.field.value)) {
        validation.addError('Must be a valid hex code ie. #000000', '', {
          code: Validation.type.INVALIDARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return ColourValidator;
  }();
  var DateValidator = function () {
    function DateValidator(field) {
      Validator.call(this, field);
    }
    DateValidator.prototype = Object.create(Validator.prototype);
    DateValidator.prototype.constructor = DateValidator;
    DateValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (this.field.value !== null && this.field.value !== undefined && this.field.value !== '' && !window.moment(this.field.value, window.moment.ISO_8601).isValid()) {
        validation.addError('Must follow format 2017-04-21T19:59:26', '', {
          code: Validation.type.INVALIDARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return DateValidator;
  }();
  var DateTimeZoneValidator = function () {
    function DateValidator(field) {
      Validator.call(this, field);
    }
    DateValidator.prototype = Object.create(Validator.prototype);
    DateValidator.prototype.constructor = DateValidator;
    DateValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (this.field && this.field.isRequired && this.field.value && !this.field.value.date) {
        validation.addError('Missing required field', '', {
          code: Validation.type.MISSINGARG,
          context: this.field.key
        });
      }
      if (this.field.value.date !== null && this.field.value.date !== undefined && this.field.value.date !== '' && !window.moment(this.field.value.date, window.moment.ISO_8601).isValid()) {
        validation.addError('Must follow format 2017-04-21T19:59:26', '', {
          code: Validation.type.INVALIDARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return DateValidator;
  }();
  var ObjectValidator = function () {
    function ObjectValidator(field, languages) {
      Validator.call(this, field, languages);
    }
    ObjectValidator.prototype = Object.create(Validator.prototype);
    ObjectValidator.prototype.constructor = ObjectValidator;
    ObjectValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      for (var i = 0; i < this.field.schema.length; i++) {
        var childField = this.field.schema[i];
        var validator = ValidatorFactory.getValidator(childField, this.languages);
        if (!validator) continue;
        var childValidation = validator.validate(opts);
        if (!childValidation.state) {
          validation.addErrors(childValidation.errors);
        }
      }
      return validation;
    };
    return ObjectValidator;
  }();
  var ContainerValidator = function () {
    function ContainerValidator(field, languages) {
      Validator.call(this, field, languages);
    }
    ContainerValidator.prototype = Object.create(Validator.prototype);
    ContainerValidator.prototype.constructor = ContainerValidator;
    ContainerValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      for (var i = 0; i < this.field.value.length; i++) {
        var childField = this.field.value[i];
        var validator = ValidatorFactory.getValidator(childField, this.languages);
        var childValidation = validator.validate(opts);
        if (!childValidation.state) {
          validation.addErrors(childValidation.errors);
        }
      }
      return validation;
    };
    return ContainerValidator;
  }();
  var PrimArrayValidator = function () {
    function PrimArrayValidator(field) {
      ContainerValidator.call(this, field);
    }
    PrimArrayValidator.prototype = Object.create(ContainerValidator.prototype);
    PrimArrayValidator.prototype.constructor = PrimArrayValidator;
    PrimArrayValidator.prototype.validate = function (opts) {
      var validation = ContainerValidator.prototype.validate.call(this, opts);
      if (this.field.childType === _fieldModel.default.childType.NUMBER) {
        for (var i = 0; i < this.field.value.length; i++) {
          var childField = this.field.value[i];
          if (childField.value === null || childField.value === undefined) {
            validation.addError('An array of numbers cannot have an empty item.', '', {
              code: Validation.type.MISSINGARG,
              context: i
            });
            break;
          }
        }
      }
      return validation;
    };
    return PrimArrayValidator;
  }();
  var GradientValidator = function () {
    function GradientValidator(field) {
      ContainerValidator.call(this, field);
    }
    GradientValidator.prototype = Object.create(ContainerValidator.prototype);
    GradientValidator.prototype.constructor = GradientValidator;
    GradientValidator.prototype.validate = function (opts) {
      var validation = ContainerValidator.prototype.validate.call(this, opts);
      if (Ember.isEmpty(this.field.value)) {
        validation.addError('An array of colors cannot have an empty item.', '', {
          code: Validation.type.MISSINGARG
        });
      }
      if (this.field.childType === _fieldModel.default.childType.STRING) {
        for (var i = 0; i < this.field.value.length; i++) {
          var childField = this.field.value[i];
          if (childField.value === null || childField.value === undefined) {
            validation.addError('An array of colors cannot have an empty item.', '', {
              code: Validation.type.MISSINGARG,
              context: i
            });
            break;
          }
        }
      }
      return validation;
    };
    return GradientValidator;
  }();
  var GeoshapeValidator = function () {
    function GeoshapeValidator(field) {
      if (field.isRequired) {
        Validator.call(this, field);
      }
    }
    GeoshapeValidator.prototype = Object.create(Validator.prototype);
    GeoshapeValidator.prototype.constructor = GeoshapeValidator;
    GeoshapeValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);
      if (this.field && this.field.value.length < 3) {
        validation.addError('Draw a polygon.', '', {
          code: Validation.type.INVALIDARG,
          context: this.field.key
        });
      }
      return validation;
    };
    return GeoshapeValidator;
  }();
  var LocationValidator = function () {
    function LocationValidator(field) {
      Validator.call(this, field);
    }
    LocationValidator.prototype = Object.create(Validator.prototype);
    LocationValidator.prototype.constructor = LocationValidator;
    LocationValidator.prototype.validate = function (opts) {
      var validation = Validator.prototype.validate.call(this, opts);

      // Skip location validation for location/blueprint content as it will be populated dynamically.
      // This logic will support existing/new content templates with required location field.
      var blueprintRegex = /\/(blueprint-template|blueprint-instance)\//;
      if (blueprintRegex.test(location.href)) {
        return validation;
      }
      if (this.field.isRequired && this.field.value) {
        if (Ember.isEmpty(this.field.value.address)) {
          validation.addError('Enter Address.', '', {
            code: Validation.type.INVALIDARG,
            context: this.field.key
          });
        } else if (typeof this.field.value.address !== 'string') {
          validation.addError('Address required to be a string.', '', {
            code: Validation.type.INVALIDARG,
            context: this.field.key
          });
        } else if (Ember.isEmpty(this.field.value.latitude)) {
          validation.addError('Enter Latitude.', '', {
            code: Validation.type.INVALIDARG,
            context: this.field.key
          });
        } else if (isNaN(this.field.value.latitude)) {
          validation.addError('Latitude is required to be a number.', '', {
            code: Validation.type.INVALIDARG,
            context: this.field.key
          });
        } else if (Ember.isEmpty(this.field.value.longitude)) {
          validation.addError('Enter Longitude.', '', {
            code: Validation.type.INVALIDARG,
            context: this.field.key
          });
        } else if (isNaN(this.field.value.longitude)) {
          validation.addError('Longitude is required to be a number.', '', {
            code: Validation.type.INVALIDARG,
            context: this.field.key
          });
        }
      }
      return validation;
    };
    return LocationValidator;
  }();
  var ValidatorFactory = {
    typeMap: {
      ctobject: ObjectValidator,
      ctobjarray: ContainerValidator,
      ctprimarray: PrimArrayValidator,
      ctgradient: GradientValidator,
      cttext: TextValidator,
      ctstring: StringValidator,
      ctcolor: ColourValidator,
      ctdate: DateValidator,
      ctdatetimezone: DateTimeZoneValidator,
      ctnumber: NumberValidator,
      ctemail: EmailValidator,
      ctphone: PhoneValidator,
      ctfile: TextValidator,
      ctimage: ImageValidator,
      ctlocalizedimage: ImageValidator,
      ctmedia: MediaValidator,
      ctlocalizedmedia: MediaValidator,
      ctdropdown: DropdownValidator,
      ctgeoshape: GeoshapeValidator,
      ctcontentinstance: StringValidator,
      ctlocation: LocationValidator
    },
    Validation: Validation,
    getValidator: function getValidator(field, languages) {
      var Validator = this.typeMap[field.type];
      if (Validator) {
        return new Validator(field, languages);
      }
      return;
    }
  };
  var _default = ValidatorFactory;
  _exports.default = _default;
});