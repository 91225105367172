define("experience-studio/components/custom-field--url", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    intl: Ember.inject.service(),
    validLocalizedWeblink: null,
    didInsertElement: function didInsertElement() {
      if (this.field && this.field.value) {
        this.setValidity(this.field.value);
      }
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },

    didSelectLanguage: Ember.observer('languages.@each.isSelected', function () {
      this.set('validLocalizedWeblink', {
        isSpace: false,
        isValid: false,
        isHttps: false
      });
      if (this.field && this.field.value && this.field.value[this.selectedLanguageCode]) {
        this.setValidity(this.field.value);
      }
    }),
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    setValidity: function setValidity(value) {
      var _this = this;
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var field = this.field;
      var defaultLanguage = this.defaultLanguage;
      var selectedLanguage = this.selectedLanguageCode;
      field.set('errorMessages', []);
      this.set('validLocalizedWeblink', {
        isSpace: !Ember.isEmpty(value[selectedLanguage]),
        isValid: !Ember.isEmpty(value[selectedLanguage]),
        isHttps: !Ember.isEmpty(value[selectedLanguage])
      });
      if (Ember.isEmpty(field.value[defaultLanguage.id])) {
        if (this.isDirty) {
          field.errorMessages.pushObject("You must populate the ".concat(defaultLanguage.name, " field"));
        }
        isValid = false;
      }
      this.set('field.isValid', isValid);
      var errorLangList = [];
      // eslint-disable-next-line
      var validHttpsExpression = /^(https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      var validHttpsRegex = new RegExp(validHttpsExpression);
      this.languages.forEach(function (lang) {
        var isValidHttpsUrl = (value[lang.id] || '').trim().match(validHttpsRegex) || false;
        var isLangError = false;
        if (!Ember.isEmpty(value[lang.id]) && !isValidHttpsUrl) {
          var expression = '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
          var regex = new RegExp(expression);
          var isUrl = !!(value[lang.id].trim() || '').match(regex);
          if (selectedLanguage === lang.id) {
            _this.set('validLocalizedWeblink.isValid', isUrl);
            var httpsRegex = new RegExp('^https://');
            var isHttpsUrl = !!(value[lang.id].trim() || '').match(httpsRegex);
            _this.set('validLocalizedWeblink.isHttps', isHttpsUrl);
          }
          isLangError = true;
        }
        if (!Ember.isEmpty(value[lang.id]) && value[lang.id].match(/.*[\s].*/)) {
          if (selectedLanguage === lang.id) {
            _this.set('validLocalizedWeblink.isSpace', false);
          }
          isLangError = true;
        }
        if (isLangError && isValid) {
          errorLangList.pushObject(lang.name);
        }
      });
      if (errorLangList.length > 0) {
        var errorMessage = '';
        if (errorLangList.length > 1) {
          var errorLangStr = '';
          errorLangList.forEach(function (lang, index) {
            errorLangStr = errorLangStr + lang;
            if (index === errorLangList.length - 2) {
              errorLangStr = "".concat(errorLangStr, " and ");
            }
            if (errorLangList.length - 3 >= index) {
              errorLangStr = "".concat(errorLangStr, ", ");
            }
          });
          errorMessage = "You must provide valid ".concat(errorLangStr, " URLs");
        } else {
          errorMessage = "You must provide a valid ".concat(errorLangList[0], " URL");
        }
        field.errorMessages.pushObject(errorMessage);
        isValid = false;
      }
      this._super(value[selectedLanguage], isValid);
    },
    triggerValidation: function triggerValidation() {
      if (Ember.isEmpty(this.field.value[this.selectedLanguageCode]) && Ember.isEmpty(this.field.input)) {
        this.set('isDirty', true);
        this.send('onValue', '');
      }
    },
    actions: {
      onValue: function onValue() {
        var input = this.get("field.input");
        var value;
        if (!Ember.isEmpty(input)) {
          value = JSON.parse(JSON.stringify(input));
        } else {
          value = {};
        }
        Object.keys(value).forEach(function (key) {
          value[key] = value[key] || undefined;
        });
        value = Ember.Object.create(value);
        this.field.set('value', value);
        this.field.set('input', input);
        this.setDirty(input);
        this.setValidity(input);
        this.sendAction('onValue', value); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});