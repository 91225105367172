define("experience-studio/components/info-rule-select", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    timezone: Ember.inject.service(),
    classNames: ['info-rule-select'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    findScheduleDate: function findScheduleDate(schedule) {
      var scheduler = Ember.isEqual(schedule, 'start') ? 'startScheduler' : 'endScheduler';
      var date = null;
      var epoch = this.get("rule.schedule.".concat(scheduler, ".start"));
      if (!Ember.isNone(epoch) && epoch > 0) {
        date = window.moment(epoch).format('h:mm a, MMMM Do YYYY');
      }
      return date;
    },
    findTimeZone: function findTimeZone(schedule) {
      var scheduler = Ember.isEqual(schedule, 'start') ? 'startScheduler' : 'endScheduler';
      var timezone = Ember.Object.create({
        name: '',
        offset: ''
      });
      var timezoneName = this.get("rule.schedule.".concat(scheduler, ".timezone.name"));
      if (timezoneName) {
        var offset = this.timezone.getOffsetInHours(timezoneName);
        var offsetString = "(UTC".concat(offset.sign).concat(offset.hours, ")");
        timezone.set('name', timezoneName);
        timezone.set('offset', offsetString);
      }
      return timezone;
    },
    ruleStartDate: Ember.computed('rule.schedule.startScheduler.start', function () {
      return this.findScheduleDate('start');
    }),
    ruleEndDate: Ember.computed('rule.schedule.endScheduler.start', function () {
      return this.findScheduleDate('end');
    }),
    ruleStartTimeZone: Ember.computed('rule.schedule.startScheduler.timezone.{name,offset}', function () {
      return this.findTimeZone('start');
    }),
    ruleEndTimeZone: Ember.computed('rule.schedule.endScheduler.timezone.{name,offset}', function () {
      return this.findTimeZone('end');
    }),
    contextualScheduleText: Ember.computed('rule', function () {
      if (Ember.isEqual(this.rule.hasContextual, false)) return;
      return this.rule.hasContextualStart ? this.rule.contextualStartText : this.rule.contextualEndText;
    }),
    ruleLimitText: Ember.computed('rule', function () {
      var limit = this.rule.get('startScheduleRepeat.limit');
      var frequencyType = this.rule.get('startScheduleRepeat.frequencyType');
      if (limit == 0) {
        return;
      } else if (limit == -1) {
        return this.intl.t('info.rule.limit.unlimited');
      } else {
        var _limit = Ember.isEqual(frequencyType, 'once') ? limit : limit + 1;
        return this.intl.t('info.rule.limit.limited', {
          limit: _limit
        });
      }
    }),
    ruleIntervalText: Ember.computed('rule', function () {
      // frequencyType of 'once' should not be visible to the user as this is not user-defined.
      if (Ember.isEmpty(this.rule.get('startScheduleRepeat.frequencyType')) || Ember.isEqual(this.rule.get('startScheduleRepeat.frequencyType'), 'once')) {
        return;
      } else {
        var frequency = this.rule.get('startScheduleRepeat.frequency');
        var frequencyType = frequency == 1 ? (0, _emberInflector.singularize)(this.rule.get('startScheduleRepeat.frequencyType')) : (0, _emberInflector.pluralize)(this.rule.get('startScheduleRepeat.frequencyType'));
        return this.intl.t('info.rule.interval', {
          frequency: frequency,
          frequencyType: frequencyType
        });
      }
    })
  });
  _exports.default = _default;
});