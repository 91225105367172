define("experience-studio/components/settings-list-view", ["exports", "experience-studio/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['settings-list-view'],
    newSSOHost: _environment.default.APP.newSSOHost,
    authenticationUrl: _environment.default.APP.authenticationUrl,
    actions: {
      didToggle: function didToggle(item) {
        var _this = this;
        var status = '';
        item.toggleProperty('value');
        if (item.value) {
          status = 'Enabled';
        } else {
          status = 'Disabled';
        }
        item.save().then(function () {
          _this.showNotification({
            message: "".concat(status, " ").concat(item.name),
            type: 'confirm'
          });
          if (item.id === 'userPreferencePage' && !item.value) {
            _this.transitionTo(Ember.Object.create({
              routeName: 'experiences'
            }));
          }
          if (item.id === 'newSSOHost' && item.value === true) {
            window.open("".concat(_this.authenticationUrl, "#/try-new-sso?redirectUrl=").concat(_environment.default.APP.authenticationUrl));
          }
          if (item.id === 'newSSOHost' && item.value === false) {
            window.open("".concat(_this.authenticationUrl, "#/prevent-new-sso?redirectUrl=").concat(_environment.default.APP.authenticationUrl));
          }
        }).catch(function () {
          _this.showNotification({
            message: "Failed to save your preferences",
            type: 'alert'
          });
        });
      }
    }
  });
  _exports.default = _default;
});