define("experience-studio/components/analytics-message-opens", ["exports", "experience-studio/components/analytics-doughnut-step-card"], function (_exports, _analyticsDoughnutStepCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _analyticsDoughnutStepCard.default.extend({
    classNames: ['analytics-message-opens'],
    model: null,
    sliceOneValue: Ember.computed(function () {
      return this.model.uniqueViews;
    }),
    sliceTotalValue: Ember.computed(function () {
      return this.model.audienceReach;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.model = Ember.Object.create({
        audienceReach: 100000,
        averageViews: 0.85,
        totalOpens: 85000,
        uniqueViews: 85000
      });
    }
  });
  _exports.default = _default;
});