define("experience-studio/mirage/factories/content-engagement", ["exports", "ember-cli-mirage", "@faker-js/faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    totalViews: function totalViews() {
      return _faker.faker.datatype.number(1000000);
    },
    uniqueViews: function uniqueViews() {
      return _faker.faker.datatype.number(this.totalViews);
    },
    uniqueEngagement: function uniqueEngagement() {
      return _faker.faker.datatype.number(this.uniqueViews);
    },
    uniqueEngagementRate: function uniqueEngagementRate() {
      return this.uniqueEngagement / this.uniqueViews;
    },
    uniqueFulfillment: function uniqueFulfillment() {
      return _faker.faker.datatype.number(this.uniqueEngagement);
    },
    uniqueFulfillmentRate: function uniqueFulfillmentRate() {
      return this.uniqueFulfillment / this.uniqueViews;
    },
    contentId: function contentId() {
      return _faker.faker.datatype.uuid();
    }
  });
  _exports.default = _default;
});