define("experience-studio/components/custom-field--json", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    didInsertElement: function didInsertElement() {
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var field = this.field;
      field.set('errorMessages', []);
      if (!Ember.isEmpty(value) && value.indexOf('\'') > -1) {
        field.errorMessages.pushObject('Cannot contain single quotes');
        isValid = false;
      }
      var isJson = true;
      try {
        JSON.parse(value);
      } catch (e) {
        isJson = false;
      }
      if (isValid && !Ember.isEmpty(value) && !isJson) {
        field.errorMessages.pushObject('Field contains invalid JSON');
        isValid = false;
      }
      this._super(value, isValid);
    },
    triggerValidation: function triggerValidation() {
      if (Ember.isEmpty(this.field.value) && Ember.isEmpty(this.field.input)) {
        this.send('onValue', '{}');
        this.send('onValue', '');
      }
    },
    actions: {
      onValue: function onValue(value) {
        var input;
        if (typeof value === 'string') {
          input = value;
          try {
            value = JSON.parse(value);
          } catch (e) {
            value = null;
          }
        } else {
          input = JSON.stringify(value);
        }
        this.field.set('value', value);
        this.field.set('input', input);
        this.setDirty(input);
        this.setValidity(input);
        this.sendAction('onValue', value); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});