define("experience-studio/routes/content-hub/content-instance/form/edit", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    intl: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model() {
      var store = this.store;
      var contentInstance = this.modelFor('content-hub.content-instance');
      var ids = contentInstance.get('associatedContentInstanceIds');
      if (ids) {
        return Ember.RSVP.hash({
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          surveyAnswers: store.findRecord('content-instance', ids.surveyAnswersInstanceID),
          surveyQuestions: store.findRecord('content-instance', ids.surveyQuestionsInstanceID),
          surveyResults: store.findRecord('content-instance', ids.surveyResultInstanceID)
        });
      } else {
        return Ember.RSVP.hash({
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          })
        });
      }
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var model = this.controller.get('model');
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: model.surveyQuestions.get('name'),
        description: model.surveyQuestions.get('description'),
        image: model.surveyQuestions.get('iconUrl'),
        questions: controller.questions,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var previousRouteName = this._router.getPreviousRouteName();
      if (previousRouteName) {
        this.transitionTo(previousRouteName);
      } else if (this.controller.get('previousTransition')) {
        this.controller.get('previousTransition').retry();
      } else {
        window.history.back();
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      controller.setProperties({
        languages: model.languages.config,
        selectedLabels: [],
        transitionConfirmed: false
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        controller.set('initialHash', _this.utility.hash({
          name: model.surveyQuestions.get('name'),
          description: model.surveyQuestions.get('description'),
          image: model.surveyQuestions.get('iconUrl'),
          questions: controller.questions,
          selectedLabels: model.surveyQuestions.get('labels')
        }));
      });
      this._super(controller, model);
    },
    actions: {
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        this.controller.model.surveyQuestions.rollbackAttributes();
        this.goBack();
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        if (this.hasChanges()) {
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this.send('cancel');
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      save: function save() {
        var _this2 = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var selectedLabels = this.controller.get('selectedLabels');
        var qInstance = controller.get('model.surveyQuestions');
        var type = qInstance.get('values') ? 'PUT' : 'POST';
        var url = "".concat(_environment.default.APP.hostUrl, "/kernel/content/instances/").concat(qInstance.get('id'), "/data");
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this2.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          qInstance.set('labels', selectedLabels.mapBy('value'));
          var payload = {
            questions: controller.questions.map(function (q) {
              return q.toJSON();
            })
          };
          if (type === 'PUT') {
            url = "".concat(url, "/").concat(qInstance.get('dataId'));
          }
          return new Ember.RSVP.Promise(function (resolve, reject) {
            _jquery.default.ajax({
              contentType: 'application/json',
              data: JSON.stringify(payload),
              headers: {
                'X-Authorization': _this2.get('session.token'),
                'X-User-Agent': _this2.get('session.userAgent')
              },
              type: type,
              url: url
            }).done(function (response) {
              resolve(response);
            }).fail(function (jqXHR) {
              reject(jqXHR);
            });
          });
        }).then(function () {
          return qInstance.save();
        }).then(function () {
          qInstance.reload();
          _this2.controller.set('transitionConfirmed', true);
          _this2.goBack();
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            _this2.send('showNotification', {
              type: 'confirm',
              message: "Changes made to \"".concat(qInstance.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', _this2.utility.parseError(response));
        });
      },
      validate: function validate() {
        var controller = this.controller;
        var model = controller.get('model.surveyQuestions');
        if (!Ember.isEmpty(model.get('iconUrl')) && !model.get('iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(model.get('name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else {
          this.send('save');
        }
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          controller.set('previousTransition', transition);
          window.history.forward();
          this.send('confirmCancel');
        } else {
          controller.setProperties({
            errorMessage: '',
            transitionConfirmed: false
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});