define("experience-studio/templates/components/context-hub-attribute-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kP3STk3f",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"error\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"map-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"map\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"loading-message\"],[12],[2,\"Loading...\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errorMessage\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/context-hub-attribute-area.hbs"
  });
  _exports.default = _default;
});