define("experience-studio/components/pixel-pusher", ["exports", "jquery", "experience-studio/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{pixel-pusher
      url=url
      resetToggle=resetToggle
      defaultToggle=defaultToggle
      proxyOpts=proxyOpts
      cropAspectRatio=cropAspectRatio
      maxWidth=maxWidth
      maxHeight=maxHeight
      completed="completionHandler"
      cancelled="cancelHandler"
    }}
  
    proxyOpts:
    {
      target: 'https://v3.flybits.com/kernel/file-manager/files/upload',
      fileParam: 'file',
      headers: {"x-authorization": "eyJhbGciOiJIUzI1NiIsImtpZCI6IkQwO"},
      params: {"isPrivate": "false"}
    }
  */

  var Validation = function () {
    function Validation() {
      this.state = true;
      this.errors = [];
    }
    Validation.prototype = {
      addError: function addError(header, message, detailsObj) {
        this.state = false;
        var retObj = {
          header: header,
          message: message
        };
        if (detailsObj) {
          retObj.context = detailsObj.context;
          retObj.code = detailsObj.code;
          retObj.serverCode = detailsObj.serverCode;
        }
        this.errors.push(retObj);
        return this;
      },
      addErrors: function addErrors(errorArr) {
        if (errorArr.length > 0) {
          this.state = false;
        }
        Array.prototype.push.apply(this.errors, errorArr);
        return this;
      },
      firstError: function firstError() {
        if (this.errors.length > 0) {
          return this.errors[0];
        }
        return null;
      }
    };
    Validation.prototype.type = Validation.type = {};
    Validation.prototype.type.MALFORMED = Validation.type.MALFORMED = 1000;
    Validation.prototype.type.INVALIDARG = Validation.type.INVALIDARG = 1001;
    Validation.prototype.type.MISSINGARG = Validation.type.MISSINGARG = 1002;
    Validation.prototype.type.NOTFOUND = Validation.type.NOTFOUND = 1003;
    Validation.prototype.type.CONNECTIONERROR = Validation.type.CONNECTIONERROR = 1004;
    Validation.prototype.type.UNAUTHENTICATED = Validation.type.UNAUTHENTICATED = 1005;
    Validation.prototype.type.RETRIEVALERROR = Validation.type.RETRIEVALERROR = 1006;
    Validation.prototype.type.NOTSUPPORTED = Validation.type.NOTSUPPORTED = 1007;
    Validation.prototype.type.UNEXPECTED = Validation.type.UNEXPECTED = 1008;
    return Validation;
  }();
  var DEFAULTSTATE = {
    greyscale: false,
    blur: 0,
    rotate: 0,
    crop: null
  };
  var clone = function clone(emberObj) {
    return Ember.Object.create(JSON.parse(JSON.stringify(emberObj)));
  };
  var equalState = function equalState(a, b) {
    return a.greyscale === b.greyscale && +a.blur === +b.blur && +a.rotate === +b.rotate && +a.crop == +b.crop;
  };
  var _default = Ember.Component.extend({
    classNames: ['pixel-pusher'],
    detailsMode: false,
    isLoading: false,
    mode: null,
    defaultMode: null,
    cropRatio: null,
    pushState: clone(DEFAULTSTATE),
    tmpPushState: clone(DEFAULTSTATE),
    appliedPushState: clone(DEFAULTSTATE),
    isBlurMode: Ember.computed('mode', function () {
      return this.mode === 'blur';
    }),
    isGrayscaleMode: Ember.computed('mode', function () {
      return this.mode === 'greyscale';
    }),
    isRotateMode: Ember.computed('mode', function () {
      return this.mode === 'rotate';
    }),
    isSaveable: Ember.computed('detailsMode', 'isLoading', 'tmpPushState.{blur,greyscale,rotate,crop}', 'pushState.{blur,greyscale,rotate,crop}', function () {
      return !this.detailsMode && !this.isLoading && !equalState(this.tmpPushState, this.appliedPushState);
    }),
    resetState: Ember.observer('resetToggle', function () {
      this.set('pushState', clone(DEFAULTSTATE));
      this.set('tmpPushState', clone(DEFAULTSTATE));
      this.set('appliedPushState', clone(DEFAULTSTATE));
    }),
    setDefault: Ember.observer('defaultToggle', function () {
      if (this.defaultMode) {
        this.send('configureDetail', this.defaultMode);
      }
    }),
    cropMode: Ember.observer('mode', function () {
      var view = this;
      if (this.mode === 'crop') {
        var img = this.$('.main-viewer .img-preview')[0];
        view.set('isLoading', true);
        var cropperOpt = {
          viewMode: 1,
          zoomable: false,
          ready: function ready() {
            view.set('isLoading', false);
            if (view.get('cropAspectRatio')) {
              cropper.setAspectRatio(view.get('cropAspectRatio'));
            }
          },
          crop: function crop(e) {
            view.set('tmpPushState.crop', {
              x: e.detail.x,
              y: e.detail.y,
              dx: e.detail.width,
              dy: e.detail.height,
              canvasData: view.get('cropper').getCanvasData(),
              cropBoxData: view.get('cropper').getCropBoxData(),
              containerData: view.get('cropper').getContainerData()
            });
          }
        };
        if (view.get('cropRatio')) {
          cropperOpt.aspectRatio = view.get('cropRatio');
        }
        var cropper = new window.Cropper(img, cropperOpt);
        this.set('cropper', cropper);
      } else if (this.cropper) {
        this.cropper.destroy();
        this.set('cropper', null);
      }
    }),
    pixelBlurFactor: Ember.computed('tmpPushState.blur', function () {
      return this.get('tmpPushState.blur') * 5 + 0.3;
    }),
    imgStyle: Ember.computed('tmpPushState.{blur,greyscale,rotate}', 'detailsMode', function () {
      var retStr = 'filter:';
      var offsetX;
      var offsetY;
      var transformStr = '';
      if (this.get('tmpPushState.greyscale')) {
        retStr += 'grayscale(100%)';
      } else {
        retStr += 'grayscale(0%)';
      }
      if (this.get('tmpPushState.rotate') > 0 && this.get('tmpPushState.rotate') < 360) {
        transformStr += "rotate(".concat(this.get('tmpPushState.rotate'), "deg) ");
      }
      if (this.get('tmpPushState.blur') > 0) {
        retStr += "blur(".concat(this.pixelBlurFactor, "px)");
      }
      if (this.get('tmpPushState.crop')) {
        var containerData = this.get('tmpPushState.crop.containerData');
        var cropBoxData = this.get('tmpPushState.crop.cropBoxData');
        var leftX = cropBoxData.left;
        var topY = cropBoxData.top;
        var bottomY = containerData.height - (topY + cropBoxData.height);
        var rightX = containerData.width - (leftX + cropBoxData.width);
        if (bottomY < 0) {
          bottomY = 0;
        }
        if (rightX < 0) {
          rightX = 0;
        }
        retStr = "clip-path: inset(".concat(topY, "px ").concat(rightX, "px ").concat(bottomY, "px ").concat(leftX, "px);").concat(retStr);
        offsetX = (containerData.width - cropBoxData.width) / 2 - leftX;
        offsetY = (containerData.height - cropBoxData.height) / 2 - topY;
        transformStr += "translate3d(".concat(offsetX, "px,").concat(offsetY, "px,0) ");
      }
      if (transformStr !== '') {
        retStr = "transform:".concat(transformStr, ";").concat(retStr);
      }
      return Ember.String.htmlSafe(retStr);
    }),
    actions: {
      configureDetail: function configureDetail(detailKey) {
        this.set('detailsMode', true);
        this.set('mode', detailKey);
      },
      hideDetails: function hideDetails() {
        this.set('detailsMode', false);
        this.set('mode', null);
      },
      setGrayscale: function setGrayscale(bool) {
        this.set('tmpPushState.greyscale', bool);
      },
      applyChanges: function applyChanges() {
        this.send('hideDetails');
        this.set('pushState', clone(this.tmpPushState));
      },
      revertChanges: function revertChanges() {
        this.send('hideDetails');
        this.set('tmpPushState', clone(this.pushState));
      },
      cancelPush: function cancelPush() {
        this.sendAction('cancelled', this.url); // eslint-disable-line ember/closure-actions
        this.toggleProperty('resetToggle');
      },
      savePush: function savePush() {
        var validation = new Validation();
        if (!this.url) {
          validation.addError('Missing image URL', '', {
            code: Validation.type.MISSINGARG,
            context: 'url'
          });
        }
        if (this.proxyOpts && !this.get('proxyOpts.target')) {
          validation.addError('Missing proxy target URL', '', {
            code: Validation.type.MISSINGARG,
            context: 'proxyOpts.target'
          });
        }
        if (this.proxyOpts && !this.get('proxyOpts.fileParam')) {
          validation.addError('Missing proxy file parameter name', '', {
            code: Validation.type.MISSINGARG,
            context: 'proxyOpts.fileParam'
          });
        }
        if (!validation.state) {
          this.sendAction('completed', validation); // eslint-disable-line ember/closure-actions
          return;
        }
        this.send('pushPixels');
      },
      pushPixels: function pushPixels() {
        var view = this;
        this.set('isLoading', true);
        var reqObj = {
          method: 'POST',
          contentType: 'application/json',
          data: {}
        };
        if (this.proxyOpts) {
          reqObj.data['proxyTarget'] = this.get('proxyOpts.target');
          reqObj.data['proxyFileParam'] = this.get('proxyOpts.fileParam');
          if (this.get('proxyOpts.headers')) {
            reqObj.data['proxyHeaders'] = this.get('proxyOpts.headers');
          }
          if (this.get('proxyOpts.params')) {
            reqObj.data['proxyParams'] = this.get('proxyOpts.params');
          }
        }
        reqObj.data['image'] = this.url;
        var pushState = this.pushState;
        if (pushState.hasOwnProperty('blur')) {
          reqObj.data.blur = +pushState.get('blur');
        }
        if (pushState.hasOwnProperty('rotate')) {
          reqObj.data.rotate = +pushState.get('rotate');
        }
        if (pushState.get('crop')) {
          reqObj.data.crop = pushState.get('crop');
        }
        if (pushState.hasOwnProperty('greyscale')) {
          reqObj.data.greyscale = pushState.get('greyscale');
        }
        if (this.maxWidth || this.maxHeight) {
          reqObj.data.resize = {};
        }
        if (this.maxWidth) {
          reqObj.data.resize.width = this.maxWidth;
        }
        if (this.maxHeight) {
          reqObj.data.resize.height = this.maxHeight;
        }
        reqObj.data = JSON.stringify(reqObj.data);
        _jquery.default.ajax("".concat(_environment.default.APP.pixelHostUrl, "/transform"), reqObj).then(function (res) {
          view.sendAction('completed', {
            result: res,
            config: pushState
          });
          view.toggleProperty('resetToggle');
        }).catch(function (error) {
          view.sendAction('completed', {
            error: error
          });
        }).then(function () {
          view.set('isLoading', false);
        });
      }
    }
  });
  _exports.default = _default;
});