define("experience-studio/routes/context-hub/create-v2", ["exports", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-context-data-upload", "uuid", "flatted"], function (_exports, _routeModelTableActionsBase, _routeModelTableActionsContextDataUpload, _uuid, _flatted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = Ember.Route.extend(_routeModelTableActionsBase.default, _routeModelTableActionsContextDataUpload.default, {
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    queryParams: {
      data: {},
      type: ''
    },
    isNearbyLocationTemplate: false,
    parentWindowListener: function parentWindowListener(e) {
      var command = e.data.command;
      if (command === 'apply') {
        // this.send('save');
        this.send('validate');
      }
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    activate: function activate() {
      // Listen to parent window's action
      window.addEventListener('message', this.parentWindowListener.bind(this), false);
    },
    deactivate: function deactivate() {
      window.removeEventListener('message', this.parentWindowListener.bind(this), false);
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var isNearByTemplate = transition.to.queryParams.isNearByTemplate;
      this.isNearbyLocationTemplate = isNearByTemplate === 'true';
    },
    model: function model(params) {
      var model;
      var store = this.store;
      if (params.data) {
        model = this.extractModel(params);
      } else {
        model = store.createRecord('rule');
      }
      return Ember.RSVP.hash({
        historicalQueries: store.findAll('context-historical-query'),
        locations: store.findAll('location'),
        plugins: store.findAll('plugin'),
        rule: model,
        ruleType: params.type,
        rules: store.findAll('rule')
      });
    },
    afterModel: function afterModel(model, transition) {
      var queryParams = null;
      if (transition.queryParams) {
        queryParams = transition.queryParams;
      }
      if (!queryParams && transition.to.queryParams) {
        queryParams = transition.to.queryParams;
      }
      if (queryParams && queryParams.data) {
        if (model.rule.get('isNew')) {
          model.rule = this.extractModel(queryParams);
        }
        model.ruleType = queryParams.type;
        var serializer = this.store.serializerFor('rule');
        model.rule.set('composition', serializer.getComposition(model));
      }
    },
    extractModel: function extractModel(params) {
      var data = (0, _flatted.parse)(unescape(window.atob(params.data)));
      delete data.visibility;
      delete data.stringRepresentation;
      return this.store.createRecord('rule', data);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var initialHash = controller.initialHash;
      var model = controller.model.serialize();
      delete model.composition;
      var newHash = this.utility.hash({
        rule: model,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      controller.setProperties({
        activeTab: 'public-plugins',
        errorMessage: '',
        isHistoricalErrorMessage: false,
        historicalQueries: model.historicalQueries,
        isAddLocation: false,
        isPublicPluginsTabActive: true,
        locationErrorMessage: null,
        locations: model.locations,
        plugins: model.plugins.filterBy('isActive', true),
        rule: model.rule,
        rules: model.rules,
        ruleType: model.ruleType,
        selectedCompositionGroup: null,
        selectedCompositionItem: null,
        selectedLabels: [],
        selectedLocationLabels: [],
        transitionConfirmed: false,
        isNearbyLocationTemplate: this.isNearbyLocationTemplate
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        var composition = controller.get('model.composition');
        if (Ember.isEmpty(composition)) {
          controller.send('addCompositionGroup');
          if (_this.isNearbyLocationTemplate) {
            model.plugins.forEach(function (plugin) {
              if (plugin.uid === 'ctx.flybits.area') {
                controller.send('addCompositionItem', plugin);
                controller.model.composition[0].set('hasAreaPlugin', true);
              }
            });
          }
        } else if (!Ember.isEmpty(composition.objectAt(0).items)) {
          controller.send('selectCompositionItem', composition.objectAt(0).items.objectAt(0));
        }
        var serializedModel = controller.model.serialize();
        delete serializedModel.composition;
        controller.set('initialHash', _this.utility.hash({
          rule: serializedModel,
          selectedLabels: controller.selectedLabels.mapBy('value').sort()
        }));
      });
      var rule = model.rule;
      rule.set('schedule', Ember.Object.create({
        endScheduler: Ember.Object.create({
          repeat: Ember.Object.create({
            frequency: 1,
            frequencyType: 'once',
            limit: 1
          })
        }),
        startScheduler: Ember.Object.create({
          repeat: Ember.Object.create({
            frequency: 1,
            frequencyType: 'once',
            limit: 1
          })
        })
      }));
      this._super(controller, rule);
    },
    actions: {
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.setProperties({
          'isHistoricalErrorMessage': false,
          'errorMessage': ''
        });
      },
      confirmCancel: function confirmCancel() {
        // notify the parent window
      },
      changeTab: function changeTab(value) {
        this.controller.setProperties({
          activeTab: value,
          isCustomPluginsTabActive: value === 'custom-plugins',
          isPublicPluginsTabActive: value === 'public-plugins',
          isRulesTabActive: value === 'rules'
        });
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      addCompositionGroup: function addCompositionGroup() {
        var controller = this.controller;
        var group = Ember.Object.create({
          isAndSelected: true,
          isRoot: true,
          items: []
        });
        controller.get('model.composition').pushObject(group);
        this.send('selectCompositionGroup', group);
      },
      addCompositionItem: function addCompositionItem(pluginOrRuleModel, locationModel) {
        var selectedCompositionGroup = this.controller.get('selectedCompositionGroup');
        var isAndSelected = true;
        if (selectedCompositionGroup.items.length > 0) {
          isAndSelected = selectedCompositionGroup.items[0].isAndSelected;
        }
        var item = Ember.Object.create({
          _rule: this.controller.get('model'),
          /* TODO: Find a better way to set attribute's payload */
          comparisonPredicate: null,
          description: null,
          expression: null,
          expressionItems: [],
          group: selectedCompositionGroup,
          isAndSelected: isAndSelected,
          isHasAreaPlugin: pluginOrRuleModel.uid === 'ctx.flybits.area' || pluginOrRuleModel.uid === 'ctx.flybits.approximateArea',
          areaTabSelected: false,
          isRule: !!pluginOrRuleModel.get('head.name'),
          locations: [],
          originalModel: pluginOrRuleModel,
          selectedAttribute: null,
          value: null
        });
        if (item.isHasAreaPlugin) {
          item.locations = [];
          item.labels = [];
        }
        if (locationModel) {
          item = this.modifyLocationCompositeGroup(locationModel);
        } else {
          if (selectedCompositionGroup) {
            selectedCompositionGroup.get('items').pushObject(item);
          }
        }
        this.send('selectCompositionItem', item);
      },
      deselectCompositionItems: function deselectCompositionItems() {
        var controller = this.controller;
        if (!Ember.isEmpty(controller.locations)) {
          controller.locations.setEach('isSelected', false);
        }
        controller.setProperties({
          selectedCompositionGroup: null,
          selectedCompositionItem: null
        });
        controller.get('model.composition').forEach(function (group) {
          group.set('isSelected', false);
          group.get('items').setEach('isSelected', false);
        });
      },
      removeCompositionGroup: function removeCompositionGroup(group) {
        var composition = this.controller.get('model.composition');
        var lastCompositionGroup;
        if (composition) {
          composition.removeObject(group);
          lastCompositionGroup = composition.get('lastObject');
          if (lastCompositionGroup) {
            this.send('selectCompositionGroup', lastCompositionGroup);
          }
        }
      },
      removeCompositionItem: function removeCompositionItem(item) {
        var lastCompositionItem;
        var modelPluginData = this.controller.get('model.pluginData');
        var selectedCompositionGroup = item.get('group');
        if (item.pluginData && modelPluginData) {
          for (var property in modelPluginData) {
            if (modelPluginData.hasOwnProperty(property) && modelPluginData[property] === item.pluginData) {
              delete modelPluginData[property];
            }
          }
        }
        if (selectedCompositionGroup) {
          selectedCompositionGroup.get('items').removeObject(item);
          lastCompositionItem = selectedCompositionGroup.get('items').get('lastObject');
          if (lastCompositionItem) {
            this.send('selectCompositionItem', lastCompositionItem);
          } else {
            this.send('selectCompositionGroup', selectedCompositionGroup);
          }
        }
        var controller = this.controller;
        var isAreaPluginsSelected = false;
        if (controller.model.composition.length) {
          controller.model.composition.forEach(function (group) {
            group.items.forEach(function (plugin) {
              if (plugin.originalModel.uid && plugin.originalModel.uid.includes('.area') || plugin.originalModel.uid && plugin.originalModel.uid.includes('.approximateArea')) {
                isAreaPluginsSelected = true;
              }
            });
          });
        }
        if (!isAreaPluginsSelected || controller.model.composition.length === 1 && controller.model.composition[0].items.length === 1) {
          controller.setProperties({
            plugins: this.store.findAll('plugin')
          });
        }
      },
      resetSearchKeyword: function resetSearchKeyword() {
        this.controller.set('searchKeyword', '');
      },
      // Return stringRepresentation to the parent window.
      save: function save() {
        var controller = this.controller;
        var model = controller.model;
        var ruleSerializer = this.store.serializerFor('rule');
        var ruleAdapter = this.store.adapterFor('rule');
        model.name = "TE:".concat((0, _uuid.v4)());
        var stringRepresentation = ruleSerializer.getStringRepresentation(model);
        // This API Call will return us the body object of the rule
        // without actually persisting data in db.
        ruleAdapter.getRuleBody(stringRepresentation).then(function (res) {
          model.body = res.data[0].body;
          var rule = model.toJSON();
          parent.postMessage({
            command: "setRule:".concat(controller.ruleType),
            rule: escape((0, _flatted.stringify)(rule)),
            stringRepresentation: stringRepresentation.split(":-")[1].replace(' ', '') // get the body of the stringRepresentation
          }, '*');
        });
      },
      selectComparisonOperator: function selectComparisonOperator(group, value, type) {
        if (type === 'group') {
          if (group.get('isRoot')) {
            this.controller.get('model.composition').setEach('isAndSelected', value);
          } else {
            group.set('isAndSelected', value);
          }
        } else if (type === 'item') {
          var items = group.get('items');
          items.setEach('isAndSelected', value);
        }
      },
      selectCompositionGroup: function selectCompositionGroup(group) {
        var _this2 = this;
        this.send('deselectCompositionItems');
        group.set('isSelected', true);
        this.controller.set('selectedCompositionGroup', group);
        var plugins = this.controller.get('plugins');
        var filteredPlugins = plugins.filter(function (plugin) {
          if (_this2.isNearbyLocationTemplate && plugin.uid.includes('ctx.flybits.area') || _this2.isNearbyLocationTemplate && plugin.uid.includes('ctx.flybits.approximateArea')) {
            return;
          } else {
            return plugin;
          }
        });
        this.controller.set('plugins', filteredPlugins);
      },
      selectCompositionItem: function selectCompositionItem(item) {
        var controller = this.controller;
        var group = controller.selectedCompositionGroup;
        if (controller.selectedCompositionItem !== item) {
          if (item.isHasAreaPlugin && !Ember.isEmpty(item.locations)) {
            item.locations.setEach('isSelected', false);
          }
          this.send('deselectCompositionItems');
        }

        // if area plugin selected, exclude approximateArea plugin. And vice versa.
        if (item.originalModel.uid && (item.originalModel.uid.includes('.area') || item.originalModel.uid.includes('.approximateArea'))) {
          var excludedPlugin = '.approximateArea';
          if (item.originalModel.uid.includes('.approximateArea')) {
            excludedPlugin = '.area';
          }
          controller.setProperties({
            plugins: controller.plugins.filter(function (plugin) {
              return !plugin.uid.includes(excludedPlugin);
            })
          });
        }
        item.set('isSelected', true);
        Ember.run.scheduleOnce('afterRender', this, function () {
          if (item.isHasAreaPlugin && !Ember.isEmpty(item.locations)) {
            item.locations.setEach('isSelected', true);
          }
          controller.setProperties({
            selectedCompositionGroup: group,
            selectedCompositionItem: item
          });
        });
      },
      validate: function validate() {
        var _this$currentModel,
          _this$currentModel$co,
          _this3 = this;
        var controller = this.controller;
        var historicalQueryPromises = [];
        var model = controller.model;
        var errorMessage;
        if ((_this$currentModel = this.currentModel) !== null && _this$currentModel !== void 0 && (_this$currentModel$co = _this$currentModel.contentInstance) !== null && _this$currentModel$co !== void 0 && _this$currentModel$co.isConciergeTemplate) {
          var _model$contentInstanc;
          this.send('populateConceirgeTemplateImage', model === null || model === void 0 ? void 0 : (_model$contentInstanc = model.contentInstance) === null || _model$contentInstanc === void 0 ? void 0 : _model$contentInstanc.root);
        }
        var containsEmptyComposition = model.composition.reduce(function (previous, compositionGroup) {
          return compositionGroup.items.length === 0 || previous;
        }, false);
        if (containsEmptyComposition && model.composition.length > 1) {
          errorMessage = 'Rule cannot contain an empty composition group.';
        } else {
          var nonRuleCompositionItems = [];
          var ruleCompositionItems = [];
          var nonRuleCompositionItemsLength = null;
          model.get('composition').forEach(function (compositionGroup) {
            nonRuleCompositionItems.pushObjects(compositionGroup.get('items').filterBy('isRule', false));
            ruleCompositionItems.pushObjects(compositionGroup.get('items').filterBy('isRule', true));
          });
          ruleCompositionItems.forEach(function (item) {
            if (item && item.originalModel && item.originalModel.name && item.originalModel.name.toLowerCase() === model.name.toLowerCase()) {
              errorMessage = "The name \"".concat(model.name, "\"\" is already taken. Please choose a different name.");
            }
          });
          nonRuleCompositionItemsLength = nonRuleCompositionItems.get('length');
          for (var i = 0; i < nonRuleCompositionItemsLength; i++) {
            var compositionItem = nonRuleCompositionItems.objectAt(i);
            var parameters = compositionItem.get('parameters');
            var valueType = compositionItem.get('selectedAttribute.valueType');
            var isCompositionItemValid = true;
            var message = null;
            message = 'The _PLUGIN_NAME_ plugin\'s _ATTRIBUTE_NAME ';
            message += 'attribute is not configured properly.';
            if (compositionItem.isHasAreaPlugin) {
              if (compositionItem.areaTabSelected === 'label' && Ember.isEmpty(compositionItem.labels) && Ember.isEmpty(compositionItem.labelsStr)) {
                isCompositionItemValid = false;
                message = 'You must select at least one Label for Area plugin.';
              } else if (compositionItem.areaTabSelected === 'list' && Ember.isEmpty(compositionItem.locations) && controller.ruleType !== 'preferred') {
                isCompositionItemValid = false;
                message = 'You must select at least one Location for Area plugin.';
              }
            } else if (!compositionItem.get('selectedAttribute')) {
              message = 'You must configure or remove _PLUGIN_NAME_ plugin.';
              isCompositionItemValid = false;
            } else if (!compositionItem.get('comparisonPredicate')) {
              message += ' You must select an option for each dropdown.';
              isCompositionItemValid = false;
            } else if ((valueType === 'float' || valueType === 'int' || valueType === 'string') && Ember.isEmpty(compositionItem.get('value'))) {
              isCompositionItemValid = false;
              message += ' You must enter value.';
            } else if ((valueType === 'float' || valueType === 'int') && !compositionItem.isCompareAttribute && window.isNaN(window.parseInt(compositionItem.get('value')))) {
              isCompositionItemValid = false;
              message += ' You must enter a number.';
            } else if (compositionItem.get('selectedAttribute.uid') !== 'ctx.flybits.area.query.inArea' && compositionItem.get('selectedAttribute.uid') !== 'ctx.flybits.location' && !Ember.isEmpty(parameters)) {
              for (var p = 0; p < parameters.length; p++) {
                if (Ember.isEmpty(parameters[p].value)) {
                  isCompositionItemValid = false;
                  message += ' You must enter a value for each parameter.';
                  break;
                }
              }
            }
            if (isCompositionItemValid && compositionItem.get('isExpression')) {
              var isConfigureHistoricalPlugin = compositionItem.get('selectedAttribute.id') === 'ctx.flybits.ctxhistory.query.result';
              if (Ember.isEmpty(compositionItem.get('expressionItems'))) {
                isCompositionItemValid = false;
                message += ' You must enter expression.';
              } else if (isConfigureHistoricalPlugin) {
                // ME-1290 handle historical plugin configuration
                // enable user to form advanced queries, this approach differs from
                // the way adv expressions are normally configured

                var expressionItems = compositionItem.get('expressionItems');
                var expressionItemsLength = expressionItems.length;
                var expressionString = '';
                var x = 0;
                expressionString = expressionItems[x].attribute.get('value');
                expressionString = expressionString.replace(/-/g, '–');
                for (x = 1; x < expressionItemsLength; x++) {
                  var item = expressionItems.objectAt(x);
                  var value = item.get('attribute.value') || item.get('expression');
                  if (Ember.isEmpty(value)) {
                    item.isValid = false;
                    message += ' You must provide value for each expression.';
                    isCompositionItemValid = false;
                    break;
                  } else {
                    item.isValid = true;
                    expressionString = "( ".concat(expressionString);
                    value = value.replace(/-/g, '–');
                    if (item.get('operator.value')) {
                      expressionString += " ".concat(item.get('operator.value'), " ").concat(value, " )");
                    } else {
                      expressionString += " ".concat(value, " )");
                    }
                  }
                }
                compositionItem.set('expression', expressionString);
              } else {
                var _expressionItems = compositionItem.get('expressionItems');
                var _expressionItemsLength = _expressionItems.length;
                var _parameters = compositionItem.get('parameters');
                var _expressionString = '';
                var _x = 0;
                _expressionString = _expressionItems[_x].attribute.get('uid');
                _expressionString = _expressionString.replace(/-/g, '–');
                if (!Ember.isEmpty(_parameters)) {
                  _expressionString += ".".concat(_parameters.map(function (param) {
                    return param.value || '';
                  }).join('.'));
                }
                for (_x = 1; _x < _expressionItemsLength; _x++) {
                  var _item = _expressionItems.objectAt(_x);
                  var _value = _item.get('attribute.value') || _item.get('expression');
                  if (Ember.isEmpty(_value)) {
                    _item.isValid = false;
                    message += ' You must provide value for each expression.';
                    isCompositionItemValid = false;
                    break;
                  } else {
                    _item.isValid = true;
                    _expressionString = "( ".concat(_expressionString);
                    _value = _value.replace(/-/g, '–');
                    _expressionString += " ".concat(_item.get('operator.value'), " ").concat(_value, " )");
                  }
                }
                compositionItem.set('expression', _expressionString);
              }
            }

            /*
              TODO: Notify user if attribute's value is not configured
            */

            if (message && message.indexOf('_PLUGIN_NAME_') >= 0) {
              message = message.replace('_PLUGIN_NAME_', compositionItem.get('originalModel.name'));
            }
            if (message && message.indexOf('_ATTRIBUTE_NAME') >= 0) {
              message = message.replace('_ATTRIBUTE_NAME', compositionItem.get('selectedAttribute.name'));
            }
            if (!isCompositionItemValid) {
              errorMessage = message;
              break;
            }
            if (compositionItem.isHasHistoricalPlugin || !compositionItem.isHasAreaPlugin && compositionItem.get('comparisonPredicate.value') && compositionItem.get('comparisonPredicate.value').indexOf('has_') >= 0) {
              var _ret = function () {
                if (compositionItem.value === undefined || compositionItem.value === null && !compositionItem.isHasHistoricalPlugin) {
                  errorMessage = message;
                  return "break";
                }
                if (Ember.isNone(compositionItem.frequencyValue)) {
                  errorMessage = 'You must configure frequency.';
                  return "break";
                } else if (window.parseInt(compositionItem.frequencyValue) < 0 || window.isNaN(window.parseInt(compositionItem.frequencyValue)) || !window.isFinite(compositionItem.frequencyValue) || !window.Number.isInteger(window.parseFloat(compositionItem.frequencyValue))) {
                  errorMessage = 'The number of times may not be less than zero nor a decimal value.';
                  return "break";
                }
                switch (!compositionItem.isHasHistoricalPlugin && compositionItem.selectedAttribute.valueType) {
                  case 'bool':
                    if (compositionItem.value === 'true') {
                      compositionItem.set('value', true);
                    } else {
                      compositionItem.set('value', false);
                    }
                    break;
                  case 'float':
                    compositionItem.set('value', window.parseFloat(compositionItem.value));
                    break;
                  case 'int':
                    compositionItem.set('value', window.parseInt(compositionItem.value));
                    break;
                  default:
                }
                if (compositionItem.isHasHistoricalPlugin) {
                  compositionItem.set('value', true);
                }
                var timeRange = null;
                var expiry = -1;
                if (compositionItem.recencyOption !== 'anytime') {
                  timeRange = Ember.Object.create({
                    from: -1,
                    to: -1,
                    duration: 0
                  });
                  if (compositionItem.recencyOption === 'after') {
                    if (compositionItem.recencyValue) {
                      timeRange.set('from', Math.round(compositionItem.recencyValue.getTime() / 1000));
                    } else {
                      errorMessage = 'You must configure date.';
                      return "break";
                    }
                  } else if (compositionItem.recencyOption === 'before') {
                    if (compositionItem.recencyValue) {
                      timeRange.set('to', Math.round(compositionItem.recencyValue.getTime() / 1000));
                    } else {
                      errorMessage = 'You must configure date.';
                      return "break";
                    }
                  } else if (compositionItem.recencyOption === 'in the past') {
                    if (compositionItem.recencyInThePastValue && compositionItem.recencyInThePastUnit) {
                      // eslint-disable-line max-len
                      var duration = window.parseInt(compositionItem.recencyInThePastValue);
                      if (compositionItem.recencyInThePastUnit.indexOf('day') >= 0) {
                        duration = duration * 86400;
                      } else if (compositionItem.recencyInThePastUnit.indexOf('hour') >= 0) {
                        duration = duration * 3600;
                      } else {
                        duration = duration * 60;
                      }
                      timeRange.set('from', 0);
                      timeRange.set('to', -1);
                      timeRange.set('duration', duration);
                      expiry = duration;
                    } else {
                      errorMessage = 'You must configure in the past value.';
                      return "break";
                    }
                  } else {
                    if (compositionItem.recencyValueFrom && compositionItem.recencyValueTo) {
                      timeRange.set('from', Math.round(compositionItem.recencyValueFrom.getTime() / 1000));
                      timeRange.set('to', Math.round(compositionItem.recencyValueTo.getTime() / 1000));
                    } else {
                      errorMessage = 'You must configure date(s).';
                      return "break";
                    }
                  }
                  timeRange = [timeRange];
                }
                var hisQuery = void 0;
                if (!compositionItem.isHasHistoricalPlugin) {
                  var idTokens = compositionItem.selectedAttribute.uid.split('.');
                  var attributeName = idTokens.pop();
                  var plugin = Ember.Object.create({
                    id: idTokens.join('.').replace('.query', ''),
                    attribute: attributeName,
                    attributeType: compositionItem.selectedAttribute.valueType,
                    parameter1: '',
                    parameter2: '',
                    parameter3: '',
                    parameter4: '',
                    parameter5: ''
                  });
                  if (parameters && parameters.length) {
                    for (var _i = 0; _i < parameters.length && _i < 5; _i++) {
                      var _paraValue = parameters.objectAt(_i).get('value');
                      if (_paraValue === '*') {
                        plugin.set("parameter".concat(_i + 1), '');
                      } else {
                        plugin.set("parameter".concat(_i + 1), parameters.objectAt(_i).get('value'));
                      }
                    }
                  }
                  var getFunctionName = function getFunctionName(item) {
                    if (!item || item === '') return;
                    if (item.includes('sum')) {
                      return 'sum';
                    } else {
                      return 'count';
                    }
                  };
                  hisQuery = _this3.store.createRecord('context-historical-query', {
                    name: "".concat(model.name, "-historical-query-").concat(getFunctionName(compositionItem.comparisonPredicate.value)),
                    plugin: plugin,
                    function: getFunctionName(compositionItem.comparisonPredicate.value),
                    where: [Ember.Object.create({
                      operator: compositionItem.comparisonPredicate.operator,
                      value: compositionItem.value
                    })],
                    timeRange: timeRange,
                    expiry: expiry
                  });
                  historicalQueryPromises.pushObject(new Ember.RSVP.Promise(function (resolve, reject) {
                    hisQuery.save().then(function (response) {
                      return resolve(response);
                    }).catch(function (response) {
                      return reject(response);
                    });
                  }));
                } else {
                  //historical location configuration
                  var _plugin = Ember.Object.create({
                    id: 'ctx.flybits.area',
                    attribute: 'inLocationWithLabel',
                    attributeType: 'bool',
                    parameter1: compositionItem.labels.objectAt(0).value,
                    parameter2: '',
                    parameter3: '',
                    parameter4: '',
                    parameter5: ''
                  });
                  hisQuery = _this3.store.createRecord('context-historical-query', {
                    name: "".concat(model.name, "-historical-query-location-label-").concat(compositionItem.labels.objectAt(0).value),
                    plugin: _plugin,
                    function: 'count',
                    where: [Ember.Object.create({
                      operator: '=',
                      value: true
                    })],
                    timeRange: timeRange,
                    expiry: expiry
                  });
                  historicalQueryPromises.pushObject(new Ember.RSVP.Promise(function (resolve, reject) {
                    hisQuery.save().then(function (response) {
                      return resolve(response);
                    }).catch(function (response) {
                      return reject(response);
                    });
                  }));
                }
                compositionItem.set('historicalQueryRecord', hisQuery);
                compositionItem.set('isHistorical', true);
              }();
              if (_ret === "break") break;
            }
          }
        }
        controller.set('errorMessage', errorMessage);

        // Notify the parent of error
        if (errorMessage) {
          parent.postMessage({
            command: "setError:".concat(controller.ruleType)
          }, '*');
        }
        if (!errorMessage) {
          if (historicalQueryPromises.length > 0) {
            Ember.RSVP.allSettled(historicalQueryPromises).then(function (response) {
              var rejected = response.filterBy('state', 'rejected');
              if (Ember.isEmpty(rejected)) {
                _this3.send('save');
              } else {
                // display historical error
                errorMessage = _this3.utility.parseError(rejected.objectAt(0).reason);
                if (errorMessage.indexOf('historical') >= 0) {
                  controller.set('errorMessage', '');
                  controller.set('isHistoricalErrorMessage', true);
                } else {
                  controller.set('errorMessage', errorMessage);
                }
              }
            });
          } else {
            this.send('save');
          }
        }
      },
      populateConceirgeTemplateImage: function populateConceirgeTemplateImage(model) {
        var _model$schema, _model$schema2, _mediaField$schema, _mediaField$schema$fi;
        var mediaTypeImageMap = {
          image: 'resourceURL',
          video: 'previewImgURL'
        };
        var imageField = model === null || model === void 0 ? void 0 : (_model$schema = model.schema) === null || _model$schema === void 0 ? void 0 : _model$schema.find(function (field) {
          return field.type === 'ctlocalizedimage';
        });
        var mediaField = model === null || model === void 0 ? void 0 : (_model$schema2 = model.schema) === null || _model$schema2 === void 0 ? void 0 : _model$schema2.find(function (field) {
          return field.type === 'ctlocalizedmedia';
        });
        var mediaType = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema = mediaField.schema) === null || _mediaField$schema === void 0 ? void 0 : (_mediaField$schema$fi = _mediaField$schema.find(function (field) {
          return field.key === 'mediaType';
        })) === null || _mediaField$schema$fi === void 0 ? void 0 : _mediaField$schema$fi.value;
        if (imageField && mediaType) {
          var _mediaField$schema2, _mediaField$schema2$f;
          var imageURL = {};
          var mediaURL = mediaField === null || mediaField === void 0 ? void 0 : (_mediaField$schema2 = mediaField.schema) === null || _mediaField$schema2 === void 0 ? void 0 : (_mediaField$schema2$f = _mediaField$schema2.find(function (field) {
            return field.key === mediaTypeImageMap[mediaType];
          })) === null || _mediaField$schema2$f === void 0 ? void 0 : _mediaField$schema2$f.value;
          for (var lang in mediaURL) {
            imageURL[lang] = _defineProperty({}, imageField.key, mediaURL[lang][mediaTypeImageMap[mediaType]]);
          }
          Ember.set(imageField, 'value', imageURL);
        } else if (imageField && mediaField) {
          Ember.set(imageField, 'value', null);
        }
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;
        var model = controller.model;
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          controller.set('previousTransition', transition);
          window.history.forward();
          this.send('confirmCancel');
        } else {
          if (!model.get('isSaving') && !model.get('id')) {
            model.deleteRecord();
          }
          controller.setProperties({
            activeTab: 'public-plugins',
            data: '',
            errorMessage: '',
            isHistoricalErrorMessage: false,
            isPublicPluginsTabActive: true,
            plugins: [],
            rules: [],
            searchKeyword: '',
            selectedCompositionGroup: null,
            selectedCompositionItem: null,
            transitionConfirmed: false,
            previousTransition: null
          });
        }
        return true;
      }
    },
    modifyLocationCompositeGroup: function modifyLocationCompositeGroup(locationModel) {
      var item = this.controller.selectedCompositionItem;
      if (item && item.originalModel.uid === 'ctx.flybits.location') {
        if (!Array.isArray(locationModel)) {
          locationModel = [locationModel];
        }
        locationModel.forEach(function (location) {
          if (location.isSelected && !item.locations.includes(location)) {
            item.locations.pushObject(location);
          } else {
            item.locations.removeObject(location);
          }
        });
        return item;
      }
    }
  });
  _exports.default = _default;
});