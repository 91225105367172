define("experience-studio/controllers/permissions-hub/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    data: '',
    queryParams: [{
      data: 'data'
    }],
    utility: Ember.inject.service(),
    filteredModel: Ember.computed('data', function () {
      var encodedData = this.data;
      var decodedData = null;
      if (encodedData) {
        decodedData = this.utility.atob(encodedData);
        decodedData = decodedData || {};
        decodedData.min = 5;
        decodedData.max = 20;
        if (decodedData.searchKeyword) {
          delete decodedData.min;
          delete decodedData.max;
        }
        encodedData = this.utility.btoa(decodedData);
      }
      return this.store.query('user', {
        data: encodedData
      });
    }),
    isLoading: Ember.computed('filteredModel.isFulfilled', 'isUpdatingDataQueryParam', function () {
      return !this.filteredModel.isFulfilled || this.isUpdatingDataQueryParam;
    })
  });
  _exports.default = _default;
});