define("experience-studio/templates/components/content-template-branch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gO5HRULy",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],[[35,5,[\"componentName\"]]],[[\"field\",\"isRoot\",\"plugins\",\"languages\",\"didObjArrayUpdateData\",\"didFocusIn\",\"updateDynamicAttribute\",\"isPreviewVideoTooltip\",\"dynamicAtrributeValue\"],[[35,5],[35,4],[35,3],[35,2],[30,[36,1],[[32,0],\"didUpdateData\"],null],[30,[36,1],[[32,0],\"didFocusIn\"],null],[30,[36,1],[[32,0],\"updateDynamicAttribute\"],null],[30,[36,1],[[32,0],\"isPreviewVideoTooltip\"],null],[35,0]]]]],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"loading-overlay\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"error-label\"],[12],[1,[34,9]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dynamicAtrributeValue\",\"action\",\"languages\",\"plugins\",\"isRoot\",\"field\",\"component\",\"isLoading\",\"if\",\"errorMsg\"]}",
    "moduleName": "experience-studio/templates/components/content-template-branch.hbs"
  });
  _exports.default = _default;
});