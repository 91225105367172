define("experience-studio/templates/components/parameterized-text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QzF7Owhc",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"type\",\"id\",\"value\",\"key-up\",\"click\",\"key-down\",\"focus-in\",\"focus-out\"],[\"text\",\"token-match-field\",[35,4],\"parseOnKeyUp\",\"parseOnKeyUp\",\"parseOnKeyDown\",\"parseOnKeyUp\",\"parseOnKeyUp\"]]]],[2,\"\\n  \"],[11,\"button\"],[4,[38,0],[[32,0],\"createToken\"],null],[12],[2,\"Create Token\"],[13],[2,\"\\n  \"],[11,\"button\"],[4,[38,0],[[32,0],\"exportInput\"],null],[12],[2,\"Export Input\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"suggestion-table\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"suggestion\"],[4,[38,0],[[32,0],\"setCurrentTokenName\",[32,1]],null],[12],[1,[32,1,[\"name\"]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"suggestions\",\"-track-array\",\"each\",\"model\",\"input\",\"currentToken\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/parameterized-text-field.hbs"
  });
  _exports.default = _default;
});