define("experience-studio/components/content-form-editor-checkbox", ["exports", "experience-studio/components/content-form-editor-dropped-item"], function (_exports, _contentFormEditorDroppedItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _contentFormEditorDroppedItem.default.extend({
    classNames: ['content-form-editor-checkbox'],
    model: null,
    optionIcon: Ember.computed('model.type.serverType', function () {
      if (this.get('model.type.serverType') === 'multiple') {
        return 'sqaure';
      } else if (this.get('model.type.serverType') === 'single') {
        if (this.get('model.type.uiType') === 'dropdown') {
          return 'carrot-down';
        } else {
          return 'carrot-right';
        }
      }
      return '';
    }),
    isDropdown: Ember.computed('model.type.uiType', function () {
      return this.get('model.type.uiType') === 'dropdown';
    }),
    actions: {
      addChoice: function addChoice() {
        var view = this;
        this.model.addChoice();
        setTimeout(function () {
          view.$('.checkbox-list-item').last().find('input,textarea').focus();
        }, 1);
      },
      deleteChoice: function deleteChoice(choiceModel) {
        this.get('model.choices').removeObject(choiceModel);
      },
      choiceKeyDown: function choiceKeyDown(evt) {
        var keyCode = evt.keyCode;
        if (keyCode === 13) {
          this.send('addChoice');
        }
      }
    }
  });
  _exports.default = _default;
});