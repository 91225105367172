define("experience-studio/routes/content-hub-v2/index", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-content", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-content"], function (_exports, _authenticatedRoute, _routeDataQueryParam, _routeModelBatchActionsBase, _routeModelBatchActionsContent, _routeModelTableActionsBase, _routeModelTableActionsContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeDataQueryParam.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsContent.default, _routeModelTableActionsBase.default, _routeModelTableActionsContent.default, {
    utility: Ember.inject.service(),
    storage: Ember.inject.service(),
    activate: function activate() {
      this._super.apply(this, arguments);
    },
    model: function model() {
      return Ember.RSVP.hash({
        languages: this.store.queryRecord('project-config', {
          setting: 'language'
        })
      });
    },
    beforeModel: function beforeModel(transition) {
      if (this.storage.getItem('isContentHubListView') === 'false' && transition.targetName === 'content-hub') {
        this.transitionTo('content-hub');
      }
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);
      if (isExiting) {
        controller.setProperties({
          _data: '',
          data: ''
        });
        this.send('deselectModelListItems');
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('selectedEntities', []);
      controller.toggleProperty('isRefreshFilterModel');
      controller.setProperties({
        contentHubControllerv2: this.controllerFor('content-hub-v2')
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createEntity: function createEntity() {
        this.send('createRecord', {
          route: 'content-hub.content-templates'
        });
      },
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      toggleFilterSections: function toggleFilterSections(sectionIds, isDisabling) {
        this.controller.filterState.forEach(function (state) {
          state.sections.forEach(function (section) {
            if (sectionIds.indexOf(section.id) > -1) {
              section.set('isDisabled', isDisabling);
            }
          });
        });
      },
      changeToCardView: function changeToCardView(routeName) {
        this.storage.setItem('isContentHubListView', false);
        this.transitionTo(routeName);
      },
      viewInfo: function viewInfo(params) {
        params.selectedEntitiesPath = 'contentHubControllerv2.selectedEntities';
        params.controller = 'contentHubControllerv2';
        this._super(params);
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.controller.toggleProperty('isRefreshFilterModel');
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});