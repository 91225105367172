define("experience-studio/controllers/context-hub/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    userPreferences: Ember.inject.service(),
    isHistoricalContextPluginEditorVisiblePreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('isHistoricalContextPluginEditorVisible');
    }),
    isHistoricalContextPluginEditorVisible: Ember.computed('isHistoricalContextPluginEditorVisiblePreference.value', function () {
      var value = false;
      if (this.isHistoricalContextPluginEditorVisiblePreference) {
        value = this.isHistoricalContextPluginEditorVisiblePreference.get('value');
      }
      return value;
    })
  });
  _exports.default = _default;
});