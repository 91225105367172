define("experience-studio/templates/components/file-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Q5w+wr+3",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[10,\"div\"],[14,0,\"inner-wrap\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"multi-con\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"con-item\"],[4,[38,2],[[32,0],\"selectAll\"],null],[12],[2,\"SELECT ALL\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,0,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"con-item\"],[4,[38,2],[[32,0],\"deselectAll\"],null],[12],[2,\"CLEAR SELECTED\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"file-header file-browser-item\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"file-col name\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"line-wrap\"],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"file-col date\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"line-wrap\"],[12],[2,\"Upload date\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"file-col size\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"line-wrap\"],[12],[2,\"File size\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"files-wrap\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner-content-wrap\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"file\",\"action\",\"selectedFiles\"],[[32,1],\"selectToggle\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"selected\",\"file-browser-item\",\"action\",\"multiSelect\",\"if\",\"files\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/file-browser.hbs"
  });
  _exports.default = _default;
});