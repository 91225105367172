define("experience-studio/components/route-model-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-pagination'],
    attributeBindings: ['role'],
    currentPage: 1,
    tagName: 'nav',
    role: 'navigation',
    data: '',
    queryParameters: null,
    recordsPerPage: 10,
    utility: Ember.inject.service(),
    isRecordsPerPageAll: Ember.computed('recordsPerPage', function () {
      return this.recordsPerPage === 1000000;
    }),
    pageItems: Ember.computed('currentPage', 'totalNumberOfPages', function () {
      var items = [];
      if (this.totalNumberOfPages <= 3) {
        for (var i = 1; i <= this.totalNumberOfPages; i++) {
          items.pushObject(this.createPageItem(i));
        }
      } else if (this.currentPage <= 3) {
        for (var _i = 1; _i <= 3; _i++) {
          items.pushObject(this.createPageItem(_i));
        }
        items.pushObject(this.createPageItem(-1));
        items.pushObject(this.createPageItem(this.totalNumberOfPages));
      } else if (this.currentPage > this.totalNumberOfPages - 3) {
        items.pushObject(this.createPageItem(1));
        items.pushObject(this.createPageItem(-1));
        for (var _i2 = this.totalNumberOfPages - 2; _i2 <= this.totalNumberOfPages; _i2++) {
          items.pushObject(this.createPageItem(_i2));
        }
      } else {
        items.pushObject(this.createPageItem(1));
        items.pushObject(this.createPageItem(-1));
        items.pushObject(this.createPageItem(this.currentPage - 1));
        items.pushObject(this.createPageItem(this.currentPage));
        items.pushObject(this.createPageItem(this.currentPage + 1));
        items.pushObject(this.createPageItem(-1));
        items.pushObject(this.createPageItem(this.totalNumberOfPages - 0));
      }
      return items;
    }),
    totalNumberOfPages: Ember.computed('isRecordsPerPageAll', 'model.[]', 'recordsPerPage', function () {
      var value = 0;
      if (this.isRecordsPerPageAll) {
        value = 1;
      } else if (this.model.isFulfilled) {
        value = this.model.meta.pagination.total / this.recordsPerPage;
        value = Math.ceil(value);
      }
      return value;
    }),
    createPageItem: function createPageItem(value) {
      return Ember.Object.create({
        isActive: this.currentPage === value,
        isEllipsis: value <= 0,
        value: value
      });
    },
    decodeData: function decodeData() {
      var data = {};
      if (this.data) {
        data = this.utility.atob(this.data) || {};
      } else if (this.queryParameters) {
        data = this.queryParameters;
      }
      this.setProperties({
        currentPage: data.currentPage || 1,
        recordsPerPage: data.recordsPerPage || 10
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.decodeData();
    },
    updateQueryParams: function updateQueryParams() {
      if (this.didUpdateQueryParams) {
        this.didUpdateQueryParams(this.getProperties(['currentPage', 'recordsPerPage']));
      }
    },
    actions: {
      updatePage: function updatePage(value) {
        var currentPage = this.currentPage;
        if (value === 'previous' && currentPage - 1 > 0) {
          this.set('currentPage', currentPage - 1);
          this.updateQueryParams();
        } else if (value === 'next' && currentPage + 1 <= this.totalNumberOfPages) {
          this.set('currentPage', currentPage + 1);
          this.updateQueryParams();
        } else if (typeof value !== 'string') {
          this.set('currentPage', value);
          this.updateQueryParams();
        }
      },
      updateRecordPerPage: function updateRecordPerPage(value) {
        this.setProperties({
          currentPage: 1,
          recordsPerPage: value
        });
        this.updateQueryParams();
      }
    }
  });
  _exports.default = _default;
});