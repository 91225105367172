define("experience-studio/templates/components/content-tmp-edit-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nspscqpi",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"text\",\"field input-field data-field\",[35,1],[35,0,[\"placeholder\"]]]]]]],\"hasEval\":false,\"upvars\":[\"field\",\"valueProxy\",\"input\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-number.hbs"
  });
  _exports.default = _default;
});