define("experience-studio/components/simulation-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['simulation-modal-dialog'],
    model: null,
    chartBackgroundColor: ['#29BFED'],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    chartOptions: {
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      cutoutPercentage: 75,
      legend: false,
      tooltips: false,
      responsive: true
    },
    potentialChartData: Ember.computed(function () {
      var percentage = this.get('model.computedPotentialAudienceReachPercentage');
      return {
        labels: ['Potential', ''],
        datasets: [{
          backgroundColor: this.chartBackgroundColor,
          data: [percentage, 100 - percentage]
        }]
      };
    }),
    totalChartData: Ember.computed(function () {
      var percentage = this.get('model.computedTotalAudienceReachPercentage');
      return {
        labels: ['Total', ''],
        datasets: [{
          backgroundColor: this.chartBackgroundColor,
          data: [percentage, 100 - percentage]
        }]
      };
    }),
    actions: {
      cancel: function cancel() {
        this.sendAction('cancel'); // eslint-disable-line ember/closure-actions
      },
      simulate: function simulate() {
        this.sendAction('simulate', this.model); // eslint-disable-line ember/closure-actions
        this.sendAction('cancel'); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});