define("experience-studio/templates/components/label-select-dropdown-before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "luXgKkqH",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"loading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"loading-indicator\"],[12],[2,\"\\n    Searching...\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"select\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/label-select-dropdown-before-options.hbs"
  });
  _exports.default = _default;
});