define("experience-studio/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    accessLevel: _emberData.default.attr(),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    // Computed Properties

    name: Ember.computed('firstName', 'lastName', function () {
      var name = '';
      if (this.firstName && this.lastName) {
        name = "".concat(this.firstName, " ").concat(this.lastName);
      } else if (this.email) {
        name = this.email;
      }
      return name;
    })
  });
  _exports.default = _default;
});