define("experience-studio/components/list-view-confirmation-modal-dialog", ["exports", "experience-studio/components/base-modal-dialog"], function (_exports, _baseModalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _baseModalDialog.default.extend({
    classNames: ['list-view-confirmation-modal-dialog'],
    isSwitch: false,
    actions: {
      continue: function _continue() {
        this.sendAction('confirmSwitchView', true); // eslint-disable-line ember/closure-actions
      },
      close: function close() {
        this.sendAction('confirmSwitchView', false); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});