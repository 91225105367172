define("experience-studio/routes/experiences", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/model-actions", "experience-studio/mixins/pagination-route", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-experiences", "experience-studio/config/environment"], function (_exports, _authenticatedRoute, _modelActions, _paginationRoute, _routeModelBatchActionsBase, _routeModelBatchActionsExperiences, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _modelActions.default, _paginationRoute.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsExperiences.default, {
    utility: Ember.inject.service(),
    storage: Ember.inject.service(),
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        experiences: store.query('experience', {
          page: params.page,
          recordsPerPage: params.recordsPerPage,
          selectedGroup: params.selectedGroup,
          sortby: params.sortBy,
          sortorder: params.sortOrder,
          groupId: params.groupId,
          search: params.searchKeyword,
          visibility: params.groupId ? 'all' : ''
        }),
        rules: store.query('rule', {
          status: 'production'
        }),
        plugins: store.findAll('plugin'),
        groups: store.query('group', {
          sort: 'priority'
        }),
        languages: store.queryRecord('project-config', {
          setting: 'language'
        })
      });
    },
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'experiences-v2') {
        this.transitionTo('experiences-v2', {
          queryParams: {
            groupId: this.controller.groupId
          }
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          groupId: '',
          searchKeyword: '',
          sortBy: '',
          sortOrder: '',
          page: 1,
          recordsPerPage: 12,
          visibility: ''
        });
        controller.model.groups.setEach('isSelected', false);
        this.send('deselectModelListItems');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        isLoadingAffectedRules: false,
        isInfoSidebarOpen: false,
        selectedEntities: [],
        selectedGroup: null,
        listSortProperties: [Ember.Object.create({
          label: 'Date Created',
          sortValue: 'createdAt',
          isSorted: true,
          order: {
            desc: 'Newest',
            asc: 'Oldest'
          }
        }), Ember.Object.create({
          label: 'Date Modified',
          sortValue: 'modifiedAt',
          isSorted: false,
          order: {
            desc: 'Recent',
            asc: 'Oldest'
          }
        }), Ember.Object.create({
          label: 'Priority',
          sortValue: 'priority',
          isSorted: false,
          order: {
            desc: 'Lowest',
            asc: 'Highest'
          }
        })],
        pagination: model.experiences.meta.pagination
      });
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createExperience: function createExperience() {
        this.send('createRecord', 'experience');
      },
      confirmToggleActivation: function confirmToggleActivation(record) {
        var modelName = record.constructor.modelName;
        var rule = record.computedRule;
        if (record.visibility === 'system') {
          var token = this.get('session.token');
          var origin = window.location.href;
          var redirectPage = 'edit';
          if (record.isActive) {
            redirectPage = 'view';
          }
          var experienceAdapter = this.store.adapterFor('experience');
          var promises = Ember.Object.create({
            journey: experienceAdapter.getJourney(record.id)
          });
          Ember.RSVP.hashSettled(promises).then(function (response) {
            if (response.journey.state === 'fulfilled' && response.journey.value && response.journey.value.id) {
              var redirectUrl = "".concat(_environment.default.APP.reactES, "/redirect?path=").concat(encodeURIComponent("templated-experiences/".concat(redirectPage, "/").concat(response.journey.value.id)), "&origin=").concat(encodeURIComponent(origin), "&token=").concat(token);
              location.replace(redirectUrl);
            }
          });
        } else {
          if (modelName === 'experience' && !record.get('isActive') && rule && rule.get('isDraft')) {
            this.send('showModal', Ember.Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: "You cannot activate this Experience because the Rule is in \"Draft\" mode. Please change the Rule to \"Production\" mode and try again.",
              title: 'Invalid Action'
            }));
          } else {
            this._super.apply(this, arguments);
          }
        }
      },
      deselectModelListItems: function deselectModelListItems() {
        var controller = this.controller;
        var experiences = controller.get('model.experiences');
        if (!Ember.isEmpty(experiences)) {
          experiences.forEach(function (experience) {
            if (!Ember.isNone(experience)) {
              var contents = experience.get('computedContents');
              if (!Ember.isEmpty(contents)) {
                contents.forEach(function (content) {
                  return content.set('isSelected', false);
                });
              }
              if (experience.computedRule) {
                experience.computedRule.set('isSelected', false);
              }
              experience.set('isSelected', false);
            }
          });
        }
        controller.set('selectedEntities', []);
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
        this.send('aggregateModel', 'experience', 'experiences');
      },
      editRecord: function editRecord(record) {
        if (record.visibility === 'system') {
          var token = this.get('session.token');
          var origin = window.location.href;
          var redirectPage = 'edit';
          if (record.isActive) {
            redirectPage = 'view';
          }
          var experienceAdapter = this.store.adapterFor('experience');
          var promises = Ember.Object.create({
            journey: experienceAdapter.getJourney(record.id)
          });
          Ember.RSVP.hashSettled(promises).then(function (response) {
            if (response.journey.state === 'fulfilled' && response.journey.value && response.journey.value.id) {
              var redirectUrl = "".concat(_environment.default.APP.reactES, "/redirect?path=").concat(encodeURIComponent("templated-experiences/".concat(redirectPage, "/").concat(response.journey.value.id)), "&origin=").concat(encodeURIComponent(origin), "&token=").concat(token);
              location.replace(redirectUrl);
            }
          });
        } else {
          if (record.constructor.modelName === 'rule' && !record.get('isDraft')) {
            this.send('showModal', Ember.Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: "Production rules cannot be edited as they will impact your active Experiences.\n                       If you wish to edit a rule, please select \u201Csave as draft\u201D during the rule creation process.",
              title: 'Cannot edit rules in Production.',
              type: 'danger'
            }));
          } else {
            this._super.apply(this, arguments);
          }
        }
      },
      handleAction: function handleAction(action, model) {
        var controller = this.controller;
        switch (action) {
          case 'manage-groups':
            controller.set('experienceManageGroupsModalData', Ember.Object.create({
              experience: model,
              groups: controller.get('model.groups')
            }));
            this.send('showManageExperiencesGroupsModal', model);
            break;
          case 'showManageGroupModalDialog':
            this.send('showManageGroupModalDialog', model);
            break;
          default:
            this._super.apply(this, arguments);
            break;
        }
      },
      hideManageExperiencesGroupsModal: function hideManageExperiencesGroupsModal() {
        this.controller.set('manageExperiencesGroupsModalData', null);
      },
      refresh: function refresh() {
        this.refresh();
      },
      showManageExperiencesGroupsModal: function showManageExperiencesGroupsModal(experience) {
        var controller = this.controller;
        controller.set('manageExperiencesGroupsModalData', Ember.Object.create({
          experience: experience,
          groups: this.controller.model.groups
        }));
      },
      showManageGroupModalDialog: function showManageGroupModalDialog(group) {
        var model = this.controller.get('model');
        var data = Ember.Object.create({
          experiences: this.store.findAll('experience'),
          groups: model.groups,
          group: group ? group : this.store.createRecord('group'),
          languages: model.languages.config
        });
        this.controller.set('manageGroupModalData', data);
      },
      changeToListView: function changeToListView(routeName) {
        this.storage.setItem('isExperiencesListView', true);
        this.transitionTo(routeName, {
          queryParams: {
            groupId: this.controller.groupId,
            selectedGroup: this.controller.selectedGroup,
            searchKeyword: this.controller.searchKeyword,
            sortBy: this.controller.sortBy,
            sortOrder: this.controller.sortOrder,
            page: this.controller.page
          }
        });
      },
      didTransition: function didTransition() {
        var page = this.controller.get('page');
        var model = this.controller.model.experiences;
        if (page > 1 && model.get('length') === 0) {
          this.controller.set('page', 1);
        }
      }
    }
  });
  _exports.default = _default;
});