define("experience-studio/components/date-time-picker-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    enableTime: false,
    noCalendar: false,
    defaultHour: 12,
    defaultMinute: 0,
    dateFormat: 'Y-m-d H:i K',
    placeholder: 'Select Date',
    minDate: null,
    maxDate: null,
    minTime: null,
    picker: null,
    defaultDate: null,
    clickOpens: true,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.updateDatePicker();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initializeDatePicker();
    },
    click: function click() {
      var _this = this;
      if (this.isDisabled && this.requestEnable) {
        this.requestEnable();
        Ember.run.next(function () {
          return _this.picker.open();
        });
      }
    },
    initializeDatePicker: function initializeDatePicker() {
      var _this2 = this;
      var input = this.element.querySelectorAll('.date-field')[0];
      var pickerConfig = {
        enableTime: this.enableTime,
        noCalendar: this.noCalendar,
        dateFormat: this.dateFormat,
        altInput: this.altInput,
        altFormat: this.altFormat,
        defaultDate: this.selectedDate,
        minDate: this.minDate,
        maxDate: this.maxDate,
        minTime: this.minTime,
        clickOpens: this.clickOpens,
        onChange: function onChange(selectedDates, dateStr) {
          var selectedDate = selectedDates[0];
          dateStr = window.moment(selectedDate).unix().toString();
          if (_this2.onChange && selectedDate) {
            _this2.onChange(selectedDate, dateStr);
          }
        },
        onReady: function onReady(selectedDates, dateStr, instance) {
          if (instance.calendarContainer) {
            instance.calendarContainer.addEventListener('click', function (event) {
              event.stopPropagation();
            });
          }
        }
      };
      this.set('picker', new window.flatpickr(input, pickerConfig)); // eslint-disable-line new-cap
    },
    updateDatePicker: function updateDatePicker() {
      if (this.picker) {
        if (this.minDate) {
          this.picker.set('minDate', this.minDate);
        }
        if (this.maxDate) {
          this.picker.set('maxDate', this.maxDate);
        }
        if (this.minTime) {
          this.picker.set('minTime', this.minTime);
        }
        this.picker.setDate(this.selectedDate, false);
      }
    }
  });
  _exports.default = _default;
});