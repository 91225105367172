define("experience-studio/components/custom-field-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var field = this.field;
      var customFieldDropdownMap = {
        'float': 'custom-field--number-dropdown',
        'int': 'custom-field--number-dropdown',
        'string': 'custom-field--dropdown'
      };
      var customFieldPrimitiveMap = {
        'bool': 'custom-field--boolean',
        'int': 'custom-field--integer',
        'float': 'custom-field--float',
        'string': 'custom-field--text'
      };
      var customFieldMap = {
        'image': 'custom-field--image',
        'json': 'custom-field--json',
        'url': 'custom-field--url'
      };
      if (field.key === 'trackedKeys' && field.type === 'dropdown') {
        this.component = '';
        Ember.set(this.field, 'isValid', true);
      } else if (field.type === 'dropdown') {
        if (field.key === 'actionType') {
          field.valueOptions.forEach(function (option) {
            if (option.val === 'UNIVERSAL_ACTION') {
              option.val = option.validationPrefix + option.val;
            }
          });
          var dropdownOptions = [];
          var concierge4ActionOptions = [];
          var concierge5ActionOptions = [];
          for (var count = 0; count < field.valueOptions.length; count++) {
            if (field.valueOptions[count].key === 'action_types_4' || field.valueOptions[count].key === 'action_types_5') {
              var actionTypeOptions = field.valueOptions[count].options;
              for (var i = 0; i < actionTypeOptions.length; i++) {
                if (actionTypeOptions[i].val === 'details:UNIVERSAL_ACTION') {
                  concierge5ActionOptions.push(actionTypeOptions[i]);
                } else if (actionTypeOptions[i].val.includes('UNIVERSAL_ACTION')) {
                  concierge4ActionOptions.push(actionTypeOptions[i]);
                } else {
                  dropdownOptions.push(actionTypeOptions[i]);
                }
              }
            } else {
              if (field.valueOptions[count].val === 'details:UNIVERSAL_ACTION') {
                concierge5ActionOptions.push(field.valueOptions[count]);
              } else if (field.valueOptions[count].val.includes('UNIVERSAL_ACTION')) {
                concierge4ActionOptions.push(field.valueOptions[count]);
              } else {
                dropdownOptions.push(field.valueOptions[count]);
              }
            }
          }
          if (concierge4ActionOptions.length) {
            dropdownOptions.push({
              groupName: 'Universal Actions (Require Concierge version >= 4.4)',
              options: concierge4ActionOptions,
              key: 'action_types_4'
            });
          }
          if (concierge5ActionOptions.length) {
            dropdownOptions.push({
              groupName: 'Universal Actions (Require Concierge version >= 5)',
              options: concierge5ActionOptions,
              key: 'action_types_5'
            });
          }
          Ember.set(this.field, 'valueOptions', dropdownOptions);
          this.dropdownValue = field.value ? typeof field.value === 'string' ? field.value : field.value.val : field.value;
          this.component = 'custom-field--dropdown-action';
          if (!field.value) {
            Ember.set(this.field, 'value', field.valueOptions[0].options[0]);
            this.sendAction('onValue', field.valueOptions[0].options[0], this.field.key); // eslint-disable-line ember/closure-actions
          }
        } else {
          this.component = customFieldDropdownMap[field.valueType];
          if (!field.value) {
            Ember.set(this.field, 'value', field.valueOptions[0]);
            this.sendAction('onValue', field.valueOptions[0], this.field.key); // eslint-disable-line ember/closure-actions
          }
        }
      } else if (field.type === 'primitive') {
        this.component = customFieldPrimitiveMap[field.valueType];
        if (field.key === 'actionScheme') {
          if (this.field.value) {
            if (typeof this.field.value === 'string') {
              var value = Ember.Object.create({});
              value[this.defaultLanguage.id] = this.field.value;
              Ember.set(this.field, 'value', value);
            }
            Ember.set(this.field, 'input', this.field.value);
          } else {
            Ember.set(this.field, 'input', Ember.Object.create({}));
          }
          if (this.dropdownValue === "app:UNIVERSAL_ACTION") {
            Ember.set(this.field, 'isExternalDeepLink', true);
            Ember.set(this.field, 'isValidationVisible', true);
          } else if (this.dropdownValue === "universalLink:UNIVERSAL_ACTION") {
            var value = Ember.Object.create({});
            value[this.defaultLanguage.id] = '';
            if (!this.field.ios) {
              Ember.set(this.field, 'ios', value);
            }
            if (!this.field.android) {
              Ember.set(this.field, 'android', value);
            }
          }
        }
      } else if (field.type === 'entity') {
        this.component = 'custom-field--content';
      } else {
        this.component = customFieldMap[field.type];
        if (field.type === 'url') {
          if (this.field.value) {
            if (typeof this.field.value === 'string') {
              var value = Ember.Object.create({});
              value[this.defaultLanguage.id] = this.field.value;
              Ember.set(this.field, 'value', value);
            }
            Ember.set(this.field, 'input', this.field.value);
          } else {
            Ember.set(this.field, 'input', Ember.Object.create({}));
          }
        }
      }
    },
    actions: {
      onValue: function onValue(value, key, isFocusOut) {
        if (key === 'actionType' && isFocusOut) {
          var schemeValue = this.parentView.fields.find(function (field) {
            var _field$field;
            return (field === null || field === void 0 ? void 0 : (_field$field = field.field) === null || _field$field === void 0 ? void 0 : _field$field.key) === "actionScheme";
          });
          var actionValue = this.parentView.fields.find(function (field) {
            var _field$field2;
            return (field === null || field === void 0 ? void 0 : (_field$field2 = field.field) === null || _field$field2 === void 0 ? void 0 : _field$field2.key) === "actionType";
          });
          Ember.set(actionValue.field, 'value', value);
          var emptyValue = Ember.Object.create({});
          emptyValue[this.defaultLanguage.id] = '';
          if (value === 'universalLink:UNIVERSAL_ACTION') {
            Ember.set(schemeValue.field, 'ios', emptyValue);
            Ember.set(schemeValue.field, 'android', emptyValue);
          }
          Ember.set(schemeValue.field, 'input', emptyValue);
          Ember.set(schemeValue.field, 'value', emptyValue);
          Ember.set(schemeValue.field, 'isValid', true);
          Ember.set(schemeValue.field, 'errorMessages', []);
        }
        this.sendAction('onValue', value, this.field.key, key === 'actionScheme' ? isFocusOut : false); // eslint-disable-line ember/closure-actions
      },
      bindFieldToSection: function bindFieldToSection(field) {
        this.sendAction('bindFieldToSection', field); // eslint-disable-line ember/closure-actions
      },
      unbindFieldFromSection: function unbindFieldFromSection(field) {
        this.sendAction('unbindFieldFromSection', field); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});