define("experience-studio/components/content-tmp-edit-media-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{content-tmp-edit-media-url
      field=field
    }}
  
  */
  var _default = Ember.Component.extend({
    classNames: 'content-tmp-edit-media-url',
    value: '',
    isImage: Ember.computed('mediaType', function () {
      return this.mediaType === 'image';
    }),
    isImageDisabled: Ember.computed('field.defaultOption', function () {
      if (this.field.defaultOption && this.field.defaultOption.defaultImage && !this.field.defaultOption.isEditable) {
        return true;
      } else {
        return false;
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.field.value && this.isImage && this.field.defaultOption && this.field.defaultOption.defaultImage) {
        this.set('field.value', this.field.defaultOption.defaultImage);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('value', this.get('field.value'));
    },
    didUpdateValue: Ember.observer('value', function () {
      this.set('field.value', this.get('value'));
    }),
    requirements: Ember.computed('field.imgAspectRatio', function () {
      if (this.get('field.imgAspectRatio')) {
        return {
          aspectRatio: this.get('field.imgAspectRatio')
        };
      }
      return;
    }),
    defaultTransform: Ember.computed('field.{imgMaxWidth,imgMaxHeight}', function () {
      if (this.get('field.imgMaxWidth') || this.get('field.imgMaxHeight')) {
        var resizeObj = {};
        if (this.get('field.imgMaxWidth')) {
          resizeObj.width = this.get('field.imgMaxWidth');
        }
        if (this.get('field.imgMaxHeight')) {
          resizeObj.height = this.get('field.imgMaxHeight');
        }
        return {
          resize: resizeObj
        };
      }
      return;
    })
  });
  _exports.default = _default;
});