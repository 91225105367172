define("experience-studio/templates/components/dynamic-attribute-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "F0sAAJ9l",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"dynamic-container-block \",[30,[36,11],[[35,10],\"dynamic-container-textarea\"],null]]]],[12],[2,\"\\n  \"],[11,\"span\"],[24,\"role\",\"textbox\"],[24,\"contenteditable\",\"true\"],[16,0,[31,[\"dynamic-editable-block \",[30,[36,11],[[35,12],\"rtl\"],null]]]],[4,[38,14],[\"paste\",[30,[36,13],[[32,0,[\"pasteSpan\"]],[32,0]],null]],null],[4,[38,14],[\"keypress\",[30,[36,13],[[32,0,[\"keyPressSpan\"]],[32,0]],null]],null],[4,[38,14],[\"keyup\",[30,[36,13],[[32,0,[\"keyUpSpan\"]],[32,0]],null]],null],[12],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary btn-dynamic-value\"],[24,\"aria-label\",\"Add a label\"],[24,4,\"button\"],[4,[38,14],[\"click\",[30,[36,13],[[32,0,[\"labelAdd\"]],[32,0]],null]],null],[12],[2,\"\\n    {}\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,11],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,9],null,[[\"field\",\"isModal\",\"plugins\",\"contentPluginList\",\"isEditDynamic\",\"dynamicAtrributeValue\",\"contentPlugin\",\"defaultValue\",\"updateData\"],[[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"updateData\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"defaultValue\",\"contentPlugin\",\"dynamicAtrributeValue\",\"isEditDynamic\",\"contentPluginList\",\"plugins\",\"isModal\",\"field\",\"dynamic-attribute-modal\",\"isTextarea\",\"if\",\"isRtl\",\"fn\",\"on\"]}",
    "moduleName": "experience-studio/templates/components/dynamic-attribute-input.hbs"
  });
  _exports.default = _default;
});