define("experience-studio/components/route-model-filter-dropdown-dependent-checkbox-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown-checkbox-select', 'form-group'],
    option: Ember.computed('this.section.options', function () {
      return this.section.options.firstObject;
    }),
    determineSelection: function determineSelection() {
      var _this = this;
      var propertyValue = this.option.value;
      var dependentPropertyList = this.queryParameters[this.option.property] || [];
      var isSelected = false;
      dependentPropertyList.forEach(function (dependentProp) {
        isSelected = isSelected || _this.arePropertiesEqual(propertyValue, dependentProp);
      });
      this.option.set('isSelected', isSelected);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.determineSelection();
    },
    actions: {
      makeSelection: function makeSelection(option) {
        option.toggleProperty('isSelected');
        if (this.didUpdateQueryParams) {
          var dependentPropertyList = this.queryParameters[option.property] || [];
          var optionProperty = option.value;
          var queryObject = {};
          dependentPropertyList = dependentPropertyList.reject(function (dependentProp) {
            return JSON.stringify(dependentProp) === JSON.stringify(optionProperty);
          });
          if (option.isSelected) {
            dependentPropertyList.pushObject(optionProperty);
          }
          queryObject[option.property] = dependentPropertyList.length ? dependentPropertyList : null;
          this.didUpdateQueryParams(queryObject);
        }
      }
    },
    arePropertiesEqual: function arePropertiesEqual(firstProperty, secondProperty) {
      var isEqual = false;
      if (_typeof(firstProperty) === 'object' && _typeof(secondProperty) === 'object') {
        isEqual = JSON.stringify(firstProperty) === JSON.stringify(secondProperty);
      } else {
        isEqual = firstProperty === secondProperty;
      }
      return isEqual;
    }
  });
  _exports.default = _default;
});