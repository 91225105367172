define("experience-studio/components/route-model-table-group", ["exports", "experience-studio/components/route-model-table-entity"], function (_exports, _routeModelTableEntity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelTableEntity.default.extend({
    classNames: ['route-model-table-group'],
    utility: Ember.inject.service(),
    data: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('headers', [Ember.Object.create({
        label: 'Name',
        isSelected: false,
        class: 'name-col',
        isHidden: false,
        tooltip: 'The name of this Group.'
      }), Ember.Object.create({
        label: 'Priority',
        sortProperty: 'priority',
        isSelected: false,
        class: 'priority-col',
        isHidden: false,
        tooltip: 'The Priority of this Group.'
      }), Ember.Object.create({
        label: 'Date Created',
        sortProperty: 'createdAt',
        isSelected: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'The date this Group was created on.'
      }), Ember.Object.create({
        label: 'Last Modified',
        sortProperty: 'modifiedAt',
        isSelected: false,
        class: 'date-col',
        isHidden: false,
        tooltip: 'The date this Group was last modified on.'
      })]);
    }
  });
  _exports.default = _default;
});