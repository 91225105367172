define("experience-studio/services/timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    getTimezoneList: function getTimezoneList() {
      var timezones = window.moment.tz.names().map(function (timezone) {
        return {
          name: timezone.replace(/_/g, ' '),
          offset: parseInt(window.moment.tz(timezone).format('Z'))
        };
      });
      return timezones.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });
    },
    /**
     * accepts a scheduler object and returns a new object with its unix start
     * converted to localTimezone if timezone exists.
     * @param {object} scheduler
     * @return {object}
     * {
     *  date: "12:00PM Dec 11, 2019"
     *  localDate: "05:00AM Dec 11, 2019"
     *  localTz: "America/Toronto"
     * }
     */
    getAbsoluteScheduleWithLocalTimezone: function getAbsoluteScheduleWithLocalTimezone(scheduler) {
      if (!scheduler) return;
      if (!scheduler.start) return;
      var absoluteStart = Ember.Object.create({});
      var date = window.moment(scheduler.start).format('hh:mmA MMM DD, YYYY');
      absoluteStart.set('date', date);
      if (scheduler.timezone.name) {
        var localOffset = window.moment().utcOffset();
        var targetOffset = window.moment.tz(scheduler.timezone.name).utcOffset();
        var diffInMinutes = localOffset - targetOffset;
        var _localDate = diffInMinutes > 0 ? window.moment(scheduler.start).add(diffInMinutes, 'minutes') : window.moment(scheduler.start).subtract(Math.abs(diffInMinutes), 'minutes');
        var formattedLocalDate = _localDate.format('hh:mmA MMM DD, YYYY');
        absoluteStart.setProperties({
          localTz: window.moment.tz.guess(),
          localDate: formattedLocalDate
        });
      }
      return absoluteStart;
    },
    getOffsetInHours: function getOffsetInHours(timezoneName) {
      if (!timezoneName) return;
      var offset = Ember.Object.create({});
      var hours = parseFloat((window.moment.tz(timezoneName).utcOffset() / 60).toFixed(2));
      var sign;
      if (hours > 0) {
        sign = '+';
      } else if (hours < 0) {
        sign = '-';
      } else {
        sign = '';
      }
      offset.setProperties({
        sign: sign,
        hours: hours
      });
      return offset;
    }
  });
  _exports.default = _default;
});