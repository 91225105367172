define("experience-studio/components/push-messages-new--detail-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isInvalid: true,
    isOpen: true,
    isNameInvalid: false,
    errorMessages: Ember.Object.create({
      name: '',
      labels: ''
    }),
    didInsertElement: function didInsertElement() {
      this.set('isNameInvalid', false);
      this.set('errorMessages', Ember.Object.create({
        name: '',
        labels: ''
      }));
      this.bindChildToParent(this);
      if (this.isEditMode) {
        this.validateSection();
      }
    },
    isSectionInvalid: function isSectionInvalid() {
      return Ember.isEmpty(this.get('model.name'));
    },
    validateSection: function validateSection() {
      var isInvalid = this.isSectionInvalid();
      this.set('isInvalid', isInvalid);
      this.set('isNameInvalid', isInvalid);
      if (isInvalid) {
        this.errors.set('details', true);
        this.errorMessages.set('name', 'You must provide a name for your notification');
        this.set('isOpen', true);
        return false;
      } else {
        this.errorMessages.set('name', '');
        this.errors.set('details', false);
        return true;
      }
    },
    actions: {
      validate: function validate() {
        return this.validateSection();
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      openSection: function openSection(section) {
        this.sendAction('openSection', section); // eslint-disable-line ember/closure-actions
      },
      cancel: function cancel() {
        this.sendAction('cancel'); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});