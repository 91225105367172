define("experience-studio/routes/authenticate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var queryParams = transition.queryParams;
      var routeName = 'index';
      var queryString = '';
      if (queryParams && transition.queryParams && transition.queryParams.sourceRouteName) {
        routeName = transition.queryParams.sourceRouteName;
      }
      if (!queryParams && transition.to && transition.to.queryParams) {
        if (transition.to.queryParams.sourceRouteName) {
          routeName = transition.to.queryParams.sourceRouteName;
        }
        queryParams = transition.to.queryParams;
        for (var param in queryParams) {
          if (queryString) {
            queryString += "&".concat(param, "=").concat(queryParams[param]);
          } else {
            queryString += "".concat(param, "=").concat(queryParams[param]);
          }
        }
      }
      if (queryParams.token && queryParams.token !== 'null') {
        this.session.authorize(queryParams);
      }
      this.transitionTo("".concat(routeName, "?").concat(queryString));
    }
  });
  _exports.default = _default;
});