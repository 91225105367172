define("experience-studio/templates/components/route-model-filter-dropdown-dependent-checkbox-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QVBPi+2M",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"custom-control custom-checkbox\"],[4,[38,1],[[32,0],\"makeSelection\",[35,0]],null],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"custom-control-input\"],[15,\"checked\",[34,0,[\"isSelected\"]]],[15,\"disabled\",[34,2,[\"isDisabled\"]]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n  \"],[10,\"label\"],[14,0,\"custom-control-label\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"option\",\"action\",\"section\"]}",
    "moduleName": "experience-studio/templates/components/route-model-filter-dropdown-dependent-checkbox-select.hbs"
  });
  _exports.default = _default;
});