define("experience-studio/templates/components/av5-active-users-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5vxhUhrg",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[15,0,[34,3]],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn dropdown-toggle av5-active-users-dropdown-trigger\"],[14,\"data-toggle\",\"dropdown\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"dropdown-trigger-text\"],[12],[1,[35,4,[\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[34,5]],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,6,\"\"],[24,0,\"dropdown-item\"],[4,[38,1],[[32,0],\"onValue\",[32,1]],null],[12],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,2],[[32,1,[\"isSelected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"check\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"action\",\"if\",\"dropdownWrapClassNames\",\"currentOption\",\"dropdownClassNames\",\"dateRange\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/av5-active-users-dropdown.hbs"
  });
  _exports.default = _default;
});