define("experience-studio/templates/components/content-tmp-edit-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SiOWmQud",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner-wrap\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"all-upload\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"type\",\"class\",\"value\",\"focus-out\"],[\"text\",\"form-control url-edit\",[35,1],[30,[36,0],[[32,0],\"setUploadValue\"],null]]]]],[2,\"\\n    \"],[10,\"input\"],[15,0,[31,[[34,3]]]],[14,\"tabindex\",\"-1\"],[14,4,\"file\"],[12],[13],[2,\"\\n    \"],[11,\"div\"],[24,0,\"trigger-button\"],[4,[38,0],[[32,0],\"uploadButtonClicked\"],null],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"loading-overlay\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"loader\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Upload\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"urlValue\",\"input\",\"uploadInputFileClassName\",\"isLoading\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-file.hbs"
  });
  _exports.default = _default;
});