define("experience-studio/components/location-label-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['location-label-alert'],
    utility: Ember.inject.service(),
    label: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.decodelabels();
    },
    decodelabels: function decodelabels() {
      var data = this.utility.atob(this.data) || {};
      var label = (data['labels'] || []).get('firstObject');
      this.set('label', label);
    }
  });
  _exports.default = _default;
});