define("experience-studio/components/route-model-filter-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown'],
    isOpen: false,
    queryParameters: null,
    filterDropdownSpecification: null,
    buttonNode: null,
    dropdownTeatherConstraints: null,
    menuDirection: null,
    menuPositioning: null,
    buttonPositioning: null,
    filterState: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('dropdownTeatherConstraints', [
      // eslint-disable-line
      {
        to: 'window',
        attachment: 'together',
        pin: true
      }]);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('buttonNode', this.element.querySelector('.btn-filter'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.positionMenu();
    },
    actions: {
      clearAllFilters: function clearAllFilters() {
        if (this.clearAllFilters) {
          this.clearAllFilters();
        }
      },
      didUpdateQueryParams: function didUpdateQueryParams(params) {
        if (this.didUpdateQueryParams) {
          this.didUpdateQueryParams(params);
        }
      },
      performFetchModel: function performFetchModel(modelName, params) {
        if (this.fetchModel) {
          return this.fetchModel(modelName, params);
        }
      },
      toggleFilterSections: function toggleFilterSections(sectionIds, isDisabling) {
        if (this.toggleFilterSections) {
          this.toggleFilterSections(sectionIds, isDisabling);
        }
      },
      toggleMenu: function toggleMenu() {
        this.toggleProperty('isOpen');
        if (this.isOpen) {
          this.setupMenuToggling();
        } else {
          this.destroyMenuToggling();
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.destroyMenuToggling();
    },
    positionMenu: function positionMenu() {
      var menuDirection = this.menuDirection || 'left';
      this.setProperties({
        menuPositioning: "top ".concat(menuDirection),
        buttonPositioning: "bottom ".concat(menuDirection)
      });
    },
    setupMenuToggling: function setupMenuToggling() {
      var _this = this;
      (0, _jquery.default)(document).on("click.".concat(this.elementId), function () {
        if (!(0, _jquery.default)(event.target).closest((0, _jquery.default)(_this.element).find('.dropdown')).length && !(0, _jquery.default)(event.target).closest((0, _jquery.default)('.flatpickr-calendar')).length) {
          Ember.run(function () {
            _this.send('toggleMenu');
          });
        }
      });
      (0, _jquery.default)(document).on("show.bs.dropdown.".concat(this.elementId), function () {
        Ember.run(function () {
          _this.send('toggleMenu');
        });
      });
    },
    destroyMenuToggling: function destroyMenuToggling() {
      (0, _jquery.default)(document).off("click.".concat(this.elementId));
      (0, _jquery.default)(document).off("show.bs.dropdown.".concat(this.elementId));
    }
  });
  _exports.default = _default;
});