define("experience-studio/templates/components/pill-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pK8MF8zn",
    "block": "{\"symbols\":[\"pill\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"pill\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,5],[[35,1,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"remainder-pill-indicator\"],[14,\"data-toggle\",\"tooltip\"],[15,\"title\",[31,[[34,0]]]],[12],[2,\" + \"],[1,[35,1,[\"length\"]]],[2,\" more \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"remainingPillsTooltipText\",\"remainingPills\",\"displayPills\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/pill-list.hbs"
  });
  _exports.default = _default;
});