define("experience-studio/serializers/file", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      item.attributes = {
        isPrivate: item.attributes.isPrivate,
        fileName: item.attributes.fileName,
        originalFileName: item.attributes.friendlyName,
        fileExt: item.attributes.extension ? item.attributes.extension.replace(/\./g, '') : '',
        url: item.attributes.url,
        size: item.attributes.size,
        createdAt: item.attributes.uploadedAt,
        uploaderID: item.attributes.uploader
      };
      return item;
    }
  });
  _exports.default = _default;
});