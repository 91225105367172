define("experience-studio/mixins/prioritization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    getRequestData: function getRequestData(record, parameters, action) {
      var data = {
        contentId: '00000000-0000-0000-0000-000000000000',
        experienceId: '00000000-0000-0000-0000-000000000000',
        groupId: '00000000-0000-0000-0000-000000000000',
        index: record.get('priorityIndex')
      };
      if (parameters && !Ember.isNone(parameters.get('selectedExperience'))) {
        data.experienceId = parameters.get('selectedExperience.id');
      }
      if (parameters && !Ember.isNone(parameters.get('selectedGroup'))) {
        data.groupId = parameters.get('selectedGroup.id');
      }
      var key;
      if (action.indexOf('CONTENT_INSTANCE') >= 0) {
        key = 'contentId';
      } else if (action.indexOf('EXPERIENCE') >= 0) {
        key = 'experienceId';
      } else if (action.indexOf('GROUP') >= 0) {
        key = 'groupId';
      } else {
        key = 'entityId';
      }
      data[key] = record.get('id');
      return data;
    },
    savePriorities: function savePriorities(records, parameters, action) {
      var _this = this;
      var data = [];
      records.forEach(function (record) {
        data.pushObject(_this.getRequestData(record, parameters, action));
      });
      if (Ember.isEmpty(data)) {
        return null;
      }
      return this.utility.makeAjaxRequest({
        data: data,
        endpoint: 'kernel/experiences/index',
        type: 'POST'
      });
    }
  });
  _exports.default = _default;
});