define("experience-studio/components/context-hub-create-draggable-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    activeTab: '',
    classNames: ['context-hub-create-draggable-items', 'draggable-items', 'list-unstyled'],
    plugins: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    rules: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    searchKeyword: '',
    tagName: 'ul',
    userPreferences: Ember.inject.service(),
    isHistoricalContextPluginVisible: Ember.computed('isHistoricalContextPluginVisiblePreference.value', function () {
      //enable historical context plugin, by default for all users
      return true;
    }),
    model: Ember.computed('activeTab', 'isHistoricalContextPluginVisible', 'searchKeyword', 'plugins.[]', 'rules.[]', function () {
      var _this = this;
      var activeTab = this.activeTab || 'public-plugins';
      var items;
      var searchKeyword = (this.searchKeyword || '').trim().toLowerCase();
      if (activeTab === 'rules') {
        items = this.rules.filter(function (item) {
          return item.id;
        });
      } else {
        items = this.plugins.filterBy('isReserved', activeTab === 'public-plugins');
        items = items.filter(function (item) {
          if (item.uid.indexOf('ctx.flybits.ctxhistory') >= 0) {
            return _this.get('isHistoricalContextPluginVisible');
          }
          return true;
        });
      }
      if (searchKeyword) {
        items = items.filter(function (item) {
          if ((item.get('name') || '').toLowerCase().indexOf(searchKeyword) >= 0 || (item.get('uid') || '').toLowerCase().indexOf(searchKeyword) >= 0 || (item.get('head.name') || '').toLowerCase().indexOf(searchKeyword) >= 0) {
            return true;
          } else {
            var attributes = item.get('attributes');
            for (var property in attributes) {
              if (attributes.hasOwnProperty(property)) {
                if ((attributes[property].get('name') || '').toLowerCase().indexOf(searchKeyword) >= 0) {
                  return true;
                }
              }
            }
          }
        });
      }
      return items;
    }),
    actions: {
      addCompositionItem: function addCompositionItem(item) {
        this.sendAction('addCompositionItem', item); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});