define("experience-studio/components/content-tmp-edit-media-localized-url", ["exports", "experience-studio/helpers/mapVidyardShareToPlay"], function (_exports, _mapVidyardShareToPlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  var _default = Ember.Component.extend({
    classNames: ['content-tmp-edit-media-localized-url'],
    languages: [],
    // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
    value: '',
    store: Ember.inject.service(),
    isImage: Ember.computed('mediaType', function () {
      return this.mediaType === 'image';
    }),
    isImageDisabled: Ember.computed('field.defaultOption', function () {
      if (this.field.defaultOption && this.field.defaultOption.defaultImage && !this.field.defaultOption.isEditable) {
        return true;
      } else {
        return false;
      }
    }),
    isLanguagesVisible: Ember.computed('mediaType', function () {
      return !!this.mediaType;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.languages) {
        var language = this.languages.findBy('isDefault', true);
        if (!language) {
          language = this.languages.findBy('id', 'en');
        }
        if (language) {
          this.send('selectLanguage', language);
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('field.value') && !Object.getOwnPropertyNames(this.get('field.value')).length) {
        this.set('field.value', _defineProperty({}, this.selectedLanguageCode, _defineProperty({}, this.field.key, '')));
      }
      this.setValueToFieldValue();
    },
    selectedLanguageCode: Ember.computed('languages.@each.isSelected', function () {
      var languageCode;
      var selectedLanguage = this.languages.findBy('isSelected', true);
      if (selectedLanguage) {
        languageCode = selectedLanguage.get('id');
      }
      return languageCode;
    }),
    didUpdateLanguage: Ember.observer('languages.@each.isSelected', function () {
      this.setValueToFieldValue();
    }),
    didUpdateValue: Ember.observer('mediaType', 'value', function () {
      var selectedLanguageCode = this.selectedLanguageCode;
      var key = this.field.key;
      if (!Ember.isEmpty(selectedLanguageCode)) {
        var _this$field, _this$field$value;
        if (Ember.isNone(this.field.value)) {
          this.set('field.value', {});
        }
        if (Ember.isNone(this.get("field.value.".concat(selectedLanguageCode)))) {
          this.set("field.value.".concat(selectedLanguageCode), {});
        }
        if (!this.get('value') && (_this$field = this.field) !== null && _this$field !== void 0 && (_this$field$value = _this$field.value) !== null && _this$field$value !== void 0 && _this$field$value[selectedLanguageCode]) {
          delete this.field.value[selectedLanguageCode];
        } else {
          this.set("field.value", _objectSpread(_objectSpread({}, this.get('field.value')), {}, _defineProperty({}, selectedLanguageCode, _objectSpread(_objectSpread({}, this.get("field.value.".concat(selectedLanguageCode))), {}, _defineProperty({}, key, this.get('value'))))));
        }
        this.send('setPreviewImgURL', this.get('value'));
        this.send('setVideoLabel', this.get('value'));
      }
    }),
    requirements: Ember.computed('field.imgAspectRatio', function () {
      if (this.get('field.imgAspectRatio')) {
        return {
          aspectRatio: this.get('field.imgAspectRatio')
        };
      }
      return;
    }),
    defaultTransform: Ember.computed('field.{imgMaxWidth,imgMaxHeight}', function () {
      if (this.get('field.imgMaxWidth') || this.get('field.imgMaxHeight')) {
        var resizeObj = {};
        if (this.get('field.imgMaxWidth')) {
          resizeObj.width = this.get('field.imgMaxWidth');
        }
        if (this.get('field.imgMaxHeight')) {
          resizeObj.height = this.get('field.imgMaxHeight');
        }
        return {
          resize: resizeObj
        };
      }
      return;
    }),
    setValueToFieldValue: function setValueToFieldValue() {
      var selectedLanguageCode = this.selectedLanguageCode;
      var key = this.field.key;
      if (!selectedLanguageCode) {
        var selectedLanguage = this.languages.findBy('isSelected', true);
        if (selectedLanguage) {
          selectedLanguageCode = selectedLanguage.get('id');
        }
      }
      if (selectedLanguageCode) {
        this.set('value', this.get("field.value.".concat(selectedLanguageCode, ".").concat(key)));
      }
    },
    actions: {
      selectLanguage: function selectLanguage(language) {
        this.languages.setEach('isSelected', false);
        language.set('isSelected', true);
      },
      setPreviewImgURL: function setPreviewImgURL(url) {
        var previewURL = this.isImage ? url : (0, _mapVidyardShareToPlay.default)(url);
        if (previewURL && !this.isImage) {
          previewURL += '.jpg';
        }
        this.setPreviewImgURL(previewURL, this.selectedLanguageCode);
      },
      setVideoLabel: function setVideoLabel(url) {
        var _this = this;
        var playURL = (0, _mapVidyardShareToPlay.default)(url);
        var language = this.selectedLanguageCode;
        var prefix = 'https://play.vidyard.com/';
        if (playURL) {
          var videoId = playURL.substr(playURL.indexOf(prefix) + prefix.length);
          var vidyardAdapter = this.store.adapterFor('vidyard');
          vidyardAdapter.getVidyardVideoName(videoId).then(function (name) {
            _this.setVideoLabel(name, language);
          }).catch(function () {
            _this.setVideoLabel('', language);
          });
        } else {
          this.setVideoLabel('', language);
        }
      }
    }
  });
  _exports.default = _default;
});