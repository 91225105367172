define("experience-studio/routes/context-hub/rule-v2", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-context", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-rule"], function (_exports, _authenticatedRoute, _routeDataQueryParam, _routeModelBatchActionsBase, _routeModelBatchActionsContext, _routeModelTableActionsBase, _routeModelTableActionsRule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeDataQueryParam.default, _routeModelTableActionsRule.default, _routeModelTableActionsBase.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsContext.default, {
    storage: Ember.inject.service(),
    /* Reset Controller */resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          _data: '',
          data: ''
        });
        controller.contextHubController.setProperties({
          isSearchFormHidden: false
        });
        this.send('deselectModelListItems');
        this.send('removeSearchKeyword');
      }
    },
    /* Setup Controller */setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.setProperties({
        contextHubController: this.controllerFor('context-hub')
      });
      controller.contextHubController.setProperties({
        isSearchFormHidden: true
      });
      this.controller.toggleProperty('isRefreshFilterModel');
    },
    // May need to reflect the same logic as rule.js if issues in the future.
    beforeModel: function beforeModel(transition) {
      if (this.storage.getItem('isContextHubListView') === 'false' && transition.targetName === 'context-hub.rule') {
        this.transitionTo('context-hub.rule');
      }
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    actions: {
      createEntity: function createEntity() {
        this.send('createRecord', {
          route: 'context-hub.create'
        });
      },
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      didChangeTab: function didChangeTab() {
        this.send('updateSearchKeyword', this.controller.searchKeyword);
      },
      didUpdateSearchKeyword: function didUpdateSearchKeyword(searchObject) {
        this.controller.set('searchKeyword', searchObject.keyword);
      },
      selectModelListItems: function selectModelListItems(params) {
        params.selectedEntitiesPath = 'contextHubController.selectedEntities';
        this._super(params);
      },
      deselectModelListItems: function deselectModelListItems() {
        this._super({
          selectedEntitiesPath: 'contextHubController.selectedEntities'
        });
      },
      showInfo: function showInfo() {
        this.send('showInfoRoute');
      },
      viewInfo: function viewInfo(params) {
        params.selectedEntities = 'contextHubController.selectedEntities';
        params.controller = 'contextHubController';
        this._super(params);
      },
      changeToCardView: function changeToCardView(routeName) {
        this.storage.setItem('isContextHubListView', false);
        this.transitionTo(routeName);
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.controller.toggleProperty('isRefreshFilterModel');
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});