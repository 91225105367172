define("experience-studio/templates/components/filter-pill-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6IG1ZbW+",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"filter-pill\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Label: \"],[1,[32,1,[\"name\"]]],[13],[2,\"\\n    \"],[11,\"div\"],[24,0,\"remove-pill\"],[4,[38,0],[[32,0],\"clearPill\",[32,1]],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"times-circle\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"svg-jar\",\"value\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/filter-pill-label.hbs"
  });
  _exports.default = _default;
});