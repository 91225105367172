define("experience-studio/components/analytics-timeline-presets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['analytics-timeline-presets'],
    endTime: null,
    selectedPreset: null,
    startTime: null,
    temporalRange: null,
    temporalTick: null,
    presets: Ember.computed(function () {
      var presets = [];
      presets.pushObject(Ember.Object.create({
        label: 'Today',
        temporalRange: 'day',
        temporalRangeOffset: 0,
        temporalTick: 'hour'
      }));
      presets.pushObject(Ember.Object.create({
        label: 'Yesterday',
        temporalRange: 'day',
        temporalRangeOffset: 1,
        temporalTick: 'hour'
      }));
      presets.pushObject(Ember.Object.create({
        isDivider: true
      }));
      presets.pushObject(Ember.Object.create({
        label: 'This Week',
        temporalRange: 'week',
        temporalRangeOffset: 0,
        temporalTick: 'day'
      }));
      presets.pushObject(Ember.Object.create({
        label: 'Last Week',
        temporalRange: 'week',
        temporalRangeOffset: 1,
        temporalTick: 'day'
      }));
      presets.pushObject(Ember.Object.create({
        isDivider: true
      }));
      presets.pushObject(Ember.Object.create({
        label: 'This Month',
        temporalRange: 'month',
        temporalRangeOffset: 0,
        temporalTick: 'day'
      }));
      presets.pushObject(Ember.Object.create({
        label: 'Last Month',
        temporalRange: 'month',
        temporalRangeOffset: 1,
        temporalTick: 'day'
      }));
      presets.pushObject(Ember.Object.create({
        isDivider: true
      }));
      presets.pushObject(Ember.Object.create({
        label: 'This Year',
        temporalRange: 'year',
        temporalRangeOffset: 0,
        temporalTick: 'month'
      }));
      presets.pushObject(Ember.Object.create({
        label: 'Last Year',
        temporalRange: 'year',
        temporalRangeOffset: 1,
        temporalTick: 'month'
      }));
      return presets;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var preset = this.get('presets.firstObject');
      if (preset) {
        this.send('change', preset);
      }
    },
    actions: {
      change: function change(preset) {
        this.presets.setEach('isSelected', false);
        preset.set('isSelected', true);
        var startMoment = window.moment().subtract(preset.get('temporalRangeOffset'), preset.get('temporalRange')).startOf(preset.get('temporalRange'));
        var endMoment = window.moment().subtract(preset.get('temporalRangeOffset'), preset.get('temporalRange')).endOf(preset.get('temporalRange'));
        this.setProperties({
          endTime: endMoment.utc().unix(),
          selectedPreset: preset,
          startTime: startMoment.utc().unix(),
          temporalRange: preset.get('temporalRange'),
          temporalTick: preset.get('temporalTick')
        });
      }
    }
  });
  _exports.default = _default;
});