define("experience-studio/components/google-places-input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Usage:
  
    {{google-places-input-field
      address=address
      isLoading=isPlacesLoading
      latitude=latitude
      longitude=longitude
    }}
  
    Computed Properties:
  
    Observers:
  
    Functions:
      didChangePlace
      didInsertElement
      initPlaces
      willDestroyElement
  
    Actions:
  
  */
  var _default = Ember.Component.extend({
    autocomplete: null,
    classNames: ['google-places-input-field', 'form-control'],
    gplaces: null,
    isLoading: false,
    tagName: 'input',
    // Proxy properties for place
    address: null,
    latitude: null,
    longitude: null,
    didChangePlace: function didChangePlace() {
      var place = this.autocomplete.getPlace();
      var formattedAddress = null;
      var latitude = null;
      var longitude = null;
      if (place.geometry && place.geometry.location) {
        latitude = place.geometry.location.lat();
        longitude = place.geometry.location.lng();
      }
      if (place.formatted_address) {
        formattedAddress = place.formatted_address;
      }
      this.setProperties({
        address: formattedAddress,
        latitude: latitude,
        longitude: longitude
      });
      this.sendAction('didChange', place); // eslint-disable-line
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initPlaces();
    },
    initPlaces: function initPlaces() {
      if (window.google && window.google.maps && window.google.maps.places) {
        var gplaces = window.google.maps.places;
        var autocomplete = new gplaces.Autocomplete(this.$().get(0), {
          types: ['geocode']
        });
        autocomplete.addListener('place_changed', Ember.run.bind(this, this.didChangePlace));
        this.setProperties({
          gplaces: gplaces,
          autocomplete: autocomplete
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var autocomplete = this.autocomplete;
      if (autocomplete) {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      }
    }
  });
  _exports.default = _default;
});