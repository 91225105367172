define("experience-studio/templates/analytics/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WFi+ovXU",
    "block": "{\"symbols\":[\"analyticsCards\"],\"statements\":[[10,\"div\"],[14,0,\"analytics-users-route\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"av5-cards-container\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"analyticsCards\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"av5-charts-container\"],[12],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"analyticsCharts\",\"charts\",\"sharedDateRange\"],[[35,6],[35,5],[35,4]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"av5-info-cards-list-container\",\"analyticsCardGroups\",\"-track-array\",\"each\",\"sharedDateRange\",\"charts\",\"analyticsCharts\",\"av5-info-charts-container\"]}",
    "moduleName": "experience-studio/templates/analytics/users.hbs"
  });
  _exports.default = _default;
});