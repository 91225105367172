define("experience-studio/components/route-model-table-dropdown-push-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    isViewAnalyticsVisible: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('analyticsPageV2');
    }),
    actions: {
      sendPerformModelAction: function sendPerformModelAction(actionName) {
        if (actionName === 'editRecord') {
          this.performModelAction({
            action: 'editRecord',
            record: this.model,
            route: 'communications-hub.push-messages-v2.edit'
          });
        } else {
          this.performModelAction({
            action: actionName,
            record: this.model
          });
        }
      }
    }
  });
  _exports.default = _default;
});