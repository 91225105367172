define("experience-studio/adapters/application", ["exports", "jquery", "ember-data", "experience-studio/config/environment"], function (_exports, _jquery, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.APP.hostUrl,
    paginationLimit: _environment.default.APP.paginationLimit,
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    headers: Ember.computed('session.token', 'session.userAgent', function () {
      /* eslint ember/use-brace-expansion: off */
      return {
        'Accept-language': 'all',
        'X-Authorization': this.get('session.token'),
        'X-User-Agent': this.get('session.userAgent')
      };
    }),
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this = this;
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'deleteRecord');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'DELETE',
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    findAll: function findAll() {
      var self = this;
      return this._super.apply(this, arguments).catch(function (response) {
        var message = self.get('utility').parseError(response).toLowerCase();
        if (message.indexOf('not authorized')) {
          throw response;
        }
        return [];
      });
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      var response = this._super.apply(this, arguments);
      if (payload && payload.error && !Ember.isEmpty(response)) {
        var error = response.objectAt(0);
        if (payload.error.exceptionType) {
          error.title = payload.error.exceptionType;
        }
        if (payload.error.exceptionMessage) {
          error.detail = payload.error.exceptionMessage;
        }
      }
      return response;
    },
    query: function query(store, type, _query) {
      if (_query.data) {
        _query = this.utility.atob(_query.data);
      }
      if (_query.currentPage) {
        _query.page = _query.currentPage;
        delete _query.currentPage;
      }
      if (_query.labels) {
        _query.labelsFormula = this.convertLabels(_query.labels);
        delete _query.labels;
      }
      if (_query.contentTemplates) {
        _query.contentTemplateIds = _query.contentTemplates.mapBy('id');
      }
      if (_query.userIds || _query.userIdMe) {
        var userIds = _query.userIds ? _query.userIds.map(function (user) {
          return user.id;
        }) : [];
        if (_query.userIdMe) {
          userIds.push(_query.userIdMe.id);
        }
        _query.userIds = userIds.uniq().join(',');
        delete _query.userIdMe;
      }
      if (_query.page && _query.recordsPerPage) {
        _query.limit = _query.recordsPerPage || this.paginationLimit;
        _query.offset = (_query.page - 1) * _query.limit;
        delete _query.page;
        delete _query.recordsPerPage;
      } else {
        _query.limit = _query.limit || _environment.default.APP.paginationLimit;
        _query.offset = 0;
        delete _query.recordsPerPage;
      }
      if (_query.sortBy) {
        _query.sortby = _query.sortBy;
        if (_query.sortby === 'priority') {
          _query.sortby = 'index';
        } else if (_query.sortby === 'name') {
          _query.sortorder = _query.sortorder === 'desc' ? 'asc' : 'desc';
        }
        delete _query.sortBy;
      } else {
        _query.sortby = _query.sortby || 'createdAt';
      }
      if (_query.sortby && _query.sortby === 'priority') {
        _query.sortby = 'index';
      }
      _query.sortOrder = _query.sortOrder || _query.sortorder;
      if (_query.sortOrder) {
        _query.sortorder = _query.sortOrder;
        delete _query.sortOrder;
      } else {
        _query.sortorder = 'desc';
      }
      if (_query.searchKeyword) {
        _query.search = _query.searchKeyword;
        delete _query.searchKeyword;
      }
      if (_query.sortby) {
        if (Ember.isEmpty(_query.sortorder)) {
          _query.sortorder = 'desc';
        }
      }
      if (_query.groupId) {
        _query.visibility = _query.groupId ? 'all' : '';
      }
      return this._super(store, type, _query);
    },
    convertLabels: function convertLabels(labels) {
      return "(".concat(labels.mapBy('name').join(','), ")");
    },
    toggleProperty: function toggleProperty(model, property, verbs) {
      var _this2 = this;
      var value = !model.get(property);
      var verb = verbs[property][value];
      var url = this.buildURL(model.constructor.modelName, model.get('id'), model, 'updateRecord');
      url += "/".concat(verb);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this2.headers,
          type: 'PUT',
          url: url
        }).done(function (response) {
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            model.toggleProperty(property);
          });
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this3 = this;
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      serializer.serializeIntoHash(data, type, snapshot);
      data.id = snapshot.id;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          contentType: 'application/json',
          data: JSON.stringify(data),
          dataType: 'json',
          headers: _this3.headers,
          type: 'PUT',
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    }
  });
  _exports.default = _default;
});