define("experience-studio/templates/permissions-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0LfLFKP/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"permissions-hub-route\"],[12],[2,\"\\n\\n\"],[6,[37,8],null,[[\"deselectModelListItems\",\"didChangeTab\",\"didUpdateSearchKeyword\",\"headerSubtitle\",\"headerTitle\",\"headerToolbarActions\",\"languages\",\"isInfoSidebarOpen\",\"mainHeaderTabs\",\"searchKeyword\",\"selectedEntities\",\"sideBarTitle\"],[\"deselectModelListItems\",\"didChangeTab\",\"didUpdateSearchKeyword\",\"Manage Users, Access Levels and Roles.\",\"Manage Teams\",[35,7],[35,6,[\"languages\",\"config\"]],[35,5],[35,4],[35,3],[35,2],\"Permission\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"selectedEntities\",\"searchKeyword\",\"mainHeaderTabs\",\"isInfoSidebarOpen\",\"model\",\"toolbarActions\",\"main-header-info-sidebar\"]}",
    "moduleName": "experience-studio/templates/permissions-hub.hbs"
  });
  _exports.default = _default;
});