define("experience-studio/components/location-context-data-uploader", ["exports", "experience-studio/mixins/prevent-reload-component"], function (_exports, _preventReloadComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_preventReloadComponent.default, {
    classNames: ['location-context-data-uploader'],
    fileName: '',
    isPreventReload: false,
    isProcessed: false,
    isProcessImmediately: false,
    isShowProcessAction: false,
    isStartProcessing: true,
    isStartUploading: true,
    isUploading: true,
    model: null,
    paginationLoading: false,
    session: Ember.inject.service(),
    uploadInputFileAcceptValue: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    uploadInputFileClassName: 'upload-input-file',
    processingCompletedAt: Ember.computed(function () {
      return window.moment.duration(1, 's').humanize();
    }),
    isDoneUploading: Ember.observer('isStartUploading', function () {
      if (!this.isStartUploading) {
        window.onbeforeunload = null;
      } else {
        this.willReload();
      }
    }),
    progressWidth: Ember.computed('progressCount', function () {
      return this.progressCount;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      var processingFiles = this.model.filterBy('status', 'PROCESSING');
      if (!Ember.isEmpty(processingFiles)) {
        this.sendAction('pollServer'); // eslint-disable-line ember/closure-actions
      }

      $(".".concat(this.uploadInputFileClassName)).on('change', function (event) {
        _this.set('isUploading', true);
        _this.sendAction('uploadFile', {
          // eslint-disable-line ember/closure-actions
          element: event.target,
          isProcessImmediately: _this.isProcessImmediately,
          isContextFile: $(event.target).hasClass('context')
        });
      });
      this._super.apply(this, arguments);
    },
    showAccessDeniedMessage: function showAccessDeniedMessage() {
      var message = '';
      message = 'You do not have permission to perform this action. ';
      message = 'Please contact an administrator to upgrade your Access Level.';
      this.showModal(Ember.Object.create({
        modalName: 'message-modal-dialog',
        primaryMessage: message,
        title: 'Access Denied'
      }));
    },
    willDestroyElement: function willDestroyElement() {
      $(".".concat(this.uploadInputFileClassName)).off();
      this._super.apply(this, arguments);
    },
    actions: {
      deleteUploadedFile: function deleteUploadedFile(item) {
        this.sendAction('deleteUploadedFile', item); // eslint-disable-line ember/closure-actions
      },
      dispatchAction: function dispatchAction(name, model, params) {
        this.sendAction('dispatchAction', name, model, params); // eslint-disable-line ember/closure-actions
      },
      processFile: function processFile(item) {
        item.set('isProcessingInProgress', true);
        this.sendAction('processFile', item); // eslint-disable-line ember/closure-actions
      },
      remove: function remove() {
        this.sendAction('removeUploadStatusSection'); // eslint-disable-line ember/closure-actions
      },
      triggerUploadInputFile: function triggerUploadInputFile(className) {
        var $element = $(".".concat(this.uploadInputFileClassName));
        var user = this.session.get('user');
        if (user.content.accessLevel.value < 15) {
          this.showAccessDeniedMessage();
        } else {
          $element.removeClass('context');
          $element.removeClass('location');
          $element.addClass(className);
          $element.val('');
          $element.click();
        }
      }
    }
  });
  _exports.default = _default;
});