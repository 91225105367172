define("experience-studio/components/analytics-doughnut-step-card", ["exports", "jquery", "experience-studio/components/analytics-card"], function (_exports, _jquery, _analyticsCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _analyticsCard.default.extend({
    chartBackgroundColor: null,
    chartOptions: null,
    classNameBindings: ['isInverseContentVisible', 'isLoading'],
    classNames: ['analytics-doughnut-step-card'],
    isInverseContentVisible: false,
    isLoading: false,
    sliceOneLabel: '',
    sliceOneValue: 0,
    sliceTotalValue: 0,
    sliceTwoLabel: '',
    model: null,
    sliceOnePercentage: Ember.computed('sliceOneValue', function () {
      if (this.sliceTotalValue) {
        return (this.sliceOneValue / this.sliceTotalValue * 100).toFixed(0);
      } else {
        return 0;
      }
    }),
    sliceTwoPercentage: Ember.computed('sliceTwoValue', function () {
      if (this.sliceTotalValue) {
        return (this.sliceTwoValue / this.sliceTotalValue * 100).toFixed(0);
      } else {
        return 0;
      }
    }),
    sliceTwoValue: Ember.computed('sliceOneValue', 'sliceTotalValue', function () {
      return this.sliceTotalValue - this.sliceOneValue;
    }),
    chartData: Ember.computed('chartBackgroundColor', 'chartPercentage', 'sliceOneLabel', 'sliceOneValue', 'sliceTwoLabel', 'sliceTwoValue', function () {
      var canvasContext = document.createElement('canvas').getContext('2d');
      var linearGradient = canvasContext.createLinearGradient(0, 0, 100, 150);
      linearGradient.addColorStop(0, '#29BFED');
      linearGradient.addColorStop(1, '#3A9BFB');
      return {
        datasets: [{
          backgroundColor: [linearGradient, '#E0E0E0'],
          data: [this.sliceOneValue, this.sliceTwoValue]
        }],
        labels: [this.sliceOneLabel, this.sliceTwoLabel]
      };
    }),
    getPromiseDataArray: function getPromiseDataArray() {
      return [];
    },
    getData: function getData() {
      var _this = this;
      this.set('isLoading', true);
      Ember.RSVP.allSettled(this.getPromiseDataArray()).then(function (response) {
        if (response && response.length) {
          var data = Ember.Object.create({});
          response.forEach(function (item) {
            if (item.state === 'fulfilled') {
              for (var property in item.value) {
                if (item.value.hasOwnProperty(property)) {
                  data.set(property, item.value[property]);
                }
              }
            }
          });
          _this.set('model', data);
        }
        _this.set('isLoading', false);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.chartBackgroundColor = ['#29BFED'];
      this.chartOptions = Ember.Object.create({
        cutoutPercentage: 85,
        legend: false,
        responsive: true,
        tooltips: false
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.getData) {
        this.getData();
      }
    },
    makeAPIRequest: function makeAPIRequest(options, thenCallback, catchCallback) {
      options = options || {};
      options.type = 'GET';
      options.url = 'https://v3-staging.flybits.com/sso/auth/me';
      return new Promise(function (resolve) {
        _jquery.default.ajax(options).then(function (response) {
          return resolve(thenCallback(response));
        }).catch(function (response) {
          return resolve(catchCallback(response));
        });
      });
    }
  });
  _exports.default = _default;
});