define("experience-studio/components/av5-info-card-single-stat", ["exports", "experience-studio/components/av5-info-card-base", "experience-studio/mixins/analytics-data", "jquery"], function (_exports, _av5InfoCardBase, _analyticsData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Displays single discrete time statistics using av5-info-card-base's logic
  
    Usages:
  
      {{av5-info-card-single-stat
        title=''
        subtitle=''
        requests=[{
          domain='',
          endpoint='',
          from='',
          to='',
        }]
        isDropdown=false
        isPercentagePie=false
        isBreakdownPie=false}}
  
  */
  var _default = _av5InfoCardBase.default.extend(_analyticsData.default, {
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-info-card-single-stat', 'av5-info-card'],
    classNameBindings: ['isDropdown', 'isPercentagePie', 'isBreakdownPie', 'isDoublePieChart', 'isCollapsed', 'isAnalyticsUpdateEnabled'],
    chartData: null,
    errorCode: '',
    hasNetworkError: false,
    isLoading: false,
    currentOption: null,
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    // COMPUTED ------------------------------------------------------------------
    isEngagedDevicesCard: Ember.computed('key', function () {
      var key = this.key || '';
      return key === 'engaged-devices';
    }),
    isAnalyticsUpdateEnabled: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('newAnalyticUpdate');
    }),
    isDisplayEngagedDevices: Ember.computed('title', 'isEngagedDevicesCard', 'isAnalyticsUpdateEnabled', function () {
      var title = this.title || '';
      if (this.isAnalyticsUpdateEnabled) {
        return this.isEngagedDevicesCard;
      }
      return title.toLowerCase() === 'engaged devices';
    }),
    displayStat: Ember.computed('model', 'isLoading', function () {
      if (this.isLoading) {
        return '#';
      }
      if (this.model) {
        var retVal;
        var total = 0;
        switch (this.mainStatType) {
          case 'value':
            retVal = Number(this.model.value).toLocaleString();
            break;
          case 'summation':
            for (var key in this.model) {
              if (this.model.hasOwnProperty(key)) {
                total += Number(this.model[key]);
              }
            }
            retVal = total.toLocaleString();
            break;
          default:
            retVal = 'ಠ_ಠ';
        }
        return retVal;
      }
    }),
    breakdownPieType: Ember.computed('requests.[]', function () {
      if (this.isBreakdownPie && this.requests.length === 1) {
        var endPoint = this.requests.objectAt(0).endpoint;
        if (endPoint.indexOf('device') !== -1) {
          return 'devices';
        }
      }
      return null;
    }),
    dropdownClass: Ember.computed('isCollapsed', function () {
      return this.isCollapsed ? 'truncate-trigger' : '';
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      if (this.isDropdown) {
        this.setProperties({
          dropdownParams: this.requests.objectAt(0),
          durations: [1, 7, 30, 60, 90],
          defaultDuration: 1
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('isLoading', true);
      if (this.tooltip) {
        (0, _jquery.default)(this.element).find('.title').tooltip({
          title: this.tooltip,
          template: this.utility.generateStandardTooltipMarkup(this.classNames.join(' '))
        });
      }
      if (!this.isAnalyticsUpdateEnabled) {
        this.classNames = this.classNames.concat('analytics-update-feature-flag');
      }
      this.fetchAll(this.requests).then(function (resArr) {
        if (_this.checkErrors(resArr)) {
          _this.rejectResponses(resArr);
        } else {
          _this.resolveResponses(resArr);
        }
      });
    },
    // FUNCTIONS -----------------------------------------------------------------
    dateRangeConstructor: function dateRangeConstructor() {
      var _this2 = this;
      var durations = [1, 7, 30, 60, 90];
      this.set('currentTime', window.moment().unix() * 1000);
      return durations.map(function (duration) {
        return Ember.Object.create({
          name: "".concat(duration, " Day Active Users"),
          isSelected: false,
          to: _this2.currentTime,
          from: window.moment().startOf('day').subtract(duration - 1, 'days').startOf('day').unix() * 1000
        });
      });
    },
    resolveResponses: function resolveResponses(resArr) {
      if (this.isPercentagePie) {
        this.handlePercentagePie(resArr);
      } else if (this.isBreakdownPie) {
        this.handleBreakdownPie(resArr.objectAt(0).value);
      } else if (this.isDoublePieChart) {
        this.handleDoublePieChart(resArr);
      } else {
        this.handleSingleResponse(resArr.objectAt(0).value);
      }
      this.set('isLoading', false);
    },
    rejectResponses: function rejectResponses(resArr) {
      var err = this.getLastError(resArr);
      this.setProperties({
        hasNetworkError: true,
        isLoading: false,
        errorCode: err.status
      });
    },
    handleBreakdownPie: function handleBreakdownPie(res) {
      var _this3 = this;
      if (res && res.data && res.data.value) {
        var chartData = [];
        var data = res.data.value;
        var total = Number(data.total);
        delete data.total;
        if (total === 0) {
          chartData.pushObject({
            value: 1,
            label: {
              show: false
            },
            itemStyle: {
              color: this.pieColorArray.find(function (color) {
                return color.label === 'grey';
              }).hexCode
            },
            emphasis: {
              itemStyle: {
                color: this.pieColorArray.find(function (color) {
                  return color.label === 'grey';
                }).hexCode
              }
            },
            tooltip: {
              formatter: function formatter() {
                return null;
              }
            }
          });
        }
        this.getPieLabels(this.breakdownPieType).forEach(function (label, idx) {
          chartData.pushObject({
            name: label.title,
            value: data[label.dataVar],
            label: {
              show: false
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 8,
                shadowColor: _this3.getPieColors(idx).hexCode
              }
            },
            itemStyle: {
              color: _this3.getPieColors(idx).hexCode
            }
          });
        });
        this.setProperties({
          model: data,
          chartData: chartData
        });
      } else {
        this.set('hasNetworkError', true);
      }
    },
    handlePercentagePie: function handlePercentagePie(resArr) {
      // this is a little overly hardcoded
      var metric = resArr.objectAt(0).value;
      var total = resArr.objectAt(1).value;
      if (metric && metric.data && total && total.data) {
        var displayRemainder;
        if (total.data.value <= 0 && metric.data.value <= 0) {
          displayRemainder = 1;
        } else {
          displayRemainder = total.data.value - metric.data.value < 0 ? 0 : total.data.value - metric.data.value;
        }
        this.setProperties({
          model: metric.data,
          chartData: [{
            name: this.title,
            value: metric.data.value,
            label: {
              color: '#000'
            },
            itemStyle: {
              color: '#F2B134'
            }
          }, {
            name: '',
            value: displayRemainder,
            label: {
              show: false
            },
            itemStyle: {
              color: '#EBEDF8'
            }
          }]
        });
      } else {
        this.set('hasNetworkError', true);
      }
    },
    handleDoublePieChart: function handleDoublePieChart(resArr) {
      // this is a little overly hardcoded
      var neither = resArr && resArr.objectAt(0) && resArr.objectAt(0).value;
      var pushOnly = resArr && resArr.objectAt(1) && resArr.objectAt(1).value;
      var locationOnly = resArr && resArr.objectAt(2) && resArr.objectAt(2).value;
      var both = resArr && resArr.objectAt(3) && resArr.objectAt(3).value;
      if (neither && neither.data && pushOnly && pushOnly.data && locationOnly && locationOnly.data && both && both.data) {
        this.setProperties({
          chartLegendData: [{
            name: 'Neither',
            icon: 'rect'
          }, {
            name: 'Location Only',
            icon: 'rect'
          }, {
            name: 'Push Only',
            icon: 'rect'
          }, {
            name: 'Push & Location',
            icon: 'rect'
          }],
          chartData: [[{
            name: 'Neither',
            value: neither.data.value,
            itemStyle: {
              color: '#FFC043'
            }
          }, {
            name: 'Push Only',
            value: pushOnly.data.value,
            itemStyle: {
              color: '#FA7579'
            }
          }, {
            name: 'Location Only',
            value: locationOnly.data.value.total,
            itemStyle: {
              color: '#66D0B6'
            }
          }, {
            name: 'Push & Location',
            value: both.data.value.total,
            itemStyle: {
              color: '#6D78D1'
            }
          }], [{
            name: 'Neither',
            value: neither.data.value,
            itemStyle: {
              color: '#FFC043'
            }
          }, {
            name: 'Push Only',
            value: pushOnly.data.value,
            itemStyle: {
              color: '#FA7579'
            }
          }, {
            name: 'Always allow',
            value: locationOnly.data.value.totalAlways,
            category: 'Location Only',
            itemStyle: {
              color: '#99EBD7'
            }
          }, {
            name: 'Allow only while using the app',
            value: locationOnly.data.value.totalInUse,
            category: 'Location Only',
            itemStyle: {
              color: '#3CDCB5'
            }
          }, {
            name: 'Always allow (Location)',
            value: both.data.value.totalAlways,
            category: 'Push & Location',
            itemStyle: {
              color: '#AEB6F4'
            }
          }, {
            name: 'Allow only while using the app (Location)',
            value: both.data.value.totalInUse,
            category: 'Push & Location',
            itemStyle: {
              color: '#7B87E8'
            }
          }]]
        });
      } else {
        this.set('hasNetworkError', true);
      }
    },
    handleSingleResponse: function handleSingleResponse(res) {
      if (res && res.data) {
        this.set('model', res.data);
      } else {
        this.set('hasNetworkError', true);
      }
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      retry: function retry() {
        var _this4 = this;
        this.setProperties({
          hasNetworkError: false,
          isLoading: true
        });
        this.fetchAll(this.requests).then(function (resArr) {
          if (_this4.checkErrors(resArr)) {
            _this4.rejectResponses(resArr);
          } else {
            _this4.resolveResponses(resArr);
          }
        });
      },
      refresh: function refresh() {
        window.location.reload(true);
      }
    }
  });
  _exports.default = _default;
});