define("experience-studio/templates/components/custom-field--dropdown-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7WQUXEo+",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,2]],[13],[6,[37,0],[[35,1,[\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],null,[[\"class\",\"dropdownClass\",\"options\",\"onchange\",\"searchEnabled\",\"placeholder\",\"selected\"],[\"form-control\",\"custom-field\",[35,1,[\"valueOptions\"]],[30,[36,4],[[32,0],\"selectValue\",\"true\",[35,1,[\"key\"]]],null],false,\"Select an option\",[35,3]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"displayName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"displayName\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1,[\"val\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[35,1,[\"showFullOptionLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  (\"],[1,[32,1,[\"val\"]]],[2,\")\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"field\",\"name\",\"proxyVal\",\"action\",\"power-select\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--dropdown-action.hbs"
  });
  _exports.default = _default;
});