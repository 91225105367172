define("experience-studio/models/context-data-upload", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    extension: _emberData.default.attr('string'),
    fileID: _emberData.default.attr('string'),
    meta: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    statusMessage: _emberData.default.attr('string'),
    tenantId: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    completedAt: _emberData.default.attr('epoch'),
    createdAt: _emberData.default.attr('epoch'),
    deletedAt: _emberData.default.attr('epoch'),
    modifiedAt: _emberData.default.attr('epoch'),
    processedAt: _emberData.default.attr('epoch'),
    updatedAt: _emberData.default.attr('epoch'),
    willCompleteAt: _emberData.default.attr('epoch'),
    // Computed Properties

    sizeStr: Ember.computed('size', function () {
      var size = this.size;
      if (size < 1000) {
        return "".concat(size, " B");
      } else if (size < 1000000) {
        return "".concat((size / 1000).toFixed(1), " KB");
      } else if (size < 1000000000) {
        return "".concat((size / 1000000).toFixed(1), " MB");
      } else if (size < 1000000000000) {
        return "".concat((size / 1000000000).toFixed(1), " GB");
      }
      return "".concat(size, " B");
    })
  });
  _exports.default = _default;
});