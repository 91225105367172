define("experience-studio/adapters/user", ["exports", "jquery", "experience-studio/adapters/application"], function (_exports, _jquery, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    getAccessLevel: function getAccessLevel(id) {
      var _this = this;
      var url = this.buildURL('user');
      url = url.replace('users', 'sso/permissions/userinfo/') + id;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'GET',
          url: url
        }).done(function (response) {
          return resolve(response);
        }).fail(function (jqXHR) {
          return reject(jqXHR);
        });
      });
    },
    query: function query(store, type, _query) {
      if (_query.isFetchAccessLevel) {
        _query.max = 1000000;
        _query.min = 1;
        delete _query.isFetchAccessLevel;
      }
      return this._super(store, type, _query);
    },
    queryRecord: function queryRecord(store, type, query) {
      if (query.isSignedInUser) {
        return this.findRecord(store, type, 'me');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url.replace('users', 'sso/auth/users');
    },
    urlForCreateRecord: function urlForCreateRecord() {
      var url = this._super.apply(this, arguments);
      return url.replace('users', 'sso/auth/authenticate');
    },
    urlForFindRecord: function urlForFindRecord(id) {
      var url = this._super.apply(this, arguments);
      if (id === 'me') {
        url = url.replace('users/me', 'sso/auth/me');
      }
      return url;
    },
    urlForQuery: function urlForQuery() {
      var url = this._super.apply(this, arguments);
      return url.replace('users', 'sso/auth/users');
    }
  });
  _exports.default = _default;
});