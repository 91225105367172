define("experience-studio/components/media-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{input
    type="text"
    class="form-control url-edit"
    value=value
    placeholder="Enter video url"
    focusIn=(action 'onFocus')
    focusOut=(action 'onBlur')
  }}
  {{#if isFocus}}
    <div class="yellow-notification-block">
      <div class="notification-header">
        {{svg-jar "info-circle"}}
        <div class="notification-header-text">
          Video recommendations:
          <ul class="recommendations-list">
            <li>Aspect ratio of 16:9</li>
            <li>Ads disabled</li>
            <li>Include subtitles for accessibility</li>
          </ul>
        </div>
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "z9ADQTTf",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"type\",\"class\",\"value\",\"placeholder\",\"focusIn\",\"focusOut\"],[\"text\",\"form-control url-edit\",[35,2],\"Enter video url\",[30,[36,1],[[32,0],\"onFocus\"],null],[30,[36,1],[[32,0],\"onBlur\"],null]]]]],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"yellow-notification-block\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"notification-header\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"info-circle\"],null]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"notification-header-text\"],[12],[2,\"\\n        Video recommendations:\\n        \"],[10,\"ul\"],[14,0,\"recommendations-list\"],[12],[2,\"\\n          \"],[10,\"li\"],[12],[2,\"Aspect ratio of 16:9\"],[13],[2,\"\\n          \"],[10,\"li\"],[12],[2,\"Ads disabled\"],[13],[2,\"\\n          \"],[10,\"li\"],[12],[2,\"Include subtitles for accessibility\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"action\",\"value\",\"input\",\"isFocus\",\"if\"]}",
    "moduleName": "experience-studio/components/media-field.hbs"
  });
  /*
  
    Usage:
  
    {{media-field
      value=value
    }}
  
   */
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['media-field'],
    value: null,
    isFocus: false,
    actions: {
      onFocus: function onFocus() {
        this.set('isFocus', true);
      },
      onBlur: function onBlur() {
        this.set('isFocus', false);
      }
    }
  }));
  _exports.default = _default;
});