define("experience-studio/components/push-messages-new--message-section", ["exports", "experience-studio/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    tagName: 'section',
    classNames: ['push-messages-new-form-section'],
    classNameBindings: ['isOpen:current-section', 'isInvalid::valid-section', 'errors.message:invalid-section'],
    isOpen: false,
    isInvalid: true,
    fields: Ember.A([]),
    requiredFields: Ember.A([]),
    model: null,
    index: 0,
    showCheckMark: true,
    selectedMessageTemplate: null,
    developerPortalUrl: _environment.default.APP.developerPortalUrl,
    currentEditToken: null,
    isDynamicAttributeDropDownVisible: false,
    dynamicAtrributeValue: {
      // eslint-disable-line
      value: '',
      delete: ''
    },
    dropdownTeatherConstraints: [
    // eslint-disable-line
    {
      to: 'window',
      attachment: 'together'
    }],
    closeDropDown: function closeDropDown() {
      if (this.isDynamicAttributeDropDownVisible) {
        this.set('isDynamicAttributeDropDownVisible', false);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('fields', Ember.A([]));
      this.set('requiredFields', Ember.A([]));
      this.determineDefaultLanguage();
      this.preselectMessageTemplate();
      this.set('mainFields', [Ember.Object.create({
        name: 'Title',
        type: 'title',
        value: Ember.Object.create(this.get('model.title')),
        input: Ember.Object.create(this.get('model.title')),
        isTextarea: false,
        isRequired: true,
        componentName: 'content-tmp-edit-text-locale',
        isDynamicAttributeEnabled: true
      }), Ember.Object.create({
        name: 'Body',
        type: 'message',
        value: Ember.Object.create(this.get('model.message')),
        input: Ember.Object.create(this.get('model.message')),
        isTextarea: true,
        isRequired: true,
        componentName: 'content-tmp-edit-text-locale',
        isDynamicAttributeEnabled: true
      })]);
      this.set('isOpen', this.isEditMode ? true : false);
    },
    didInsertElement: function didInsertElement() {
      var _this = this,
        _this$fields$find;
      this.bindChildToParent(this);
      if (this.isEditMode || this.model.isDraft) {
        this.validateSection();
      }
      (0, _jquery.default)(document).on("click.".concat(this.elementId), function (event) {
        if (!(0, _jquery.default)(event.target).closest(_this.$('.dynamic-attr')).length) {
          _this.closeDropDown();
        }
      });
      var actionValue = (_this$fields$find = this.fields.find(function (field) {
        var _field$field;
        return (field === null || field === void 0 ? void 0 : (_field$field = field.field) === null || _field$field === void 0 ? void 0 : _field$field.key) === "actionType";
      })) === null || _this$fields$find === void 0 ? void 0 : _this$fields$find.field.value;
      if (actionValue === 'universalLink:UNIVERSAL_ACTION') {
        for (var count = 0; count < this.fields.length; count++) {
          if (this.fields[count].field.key === "actionScheme") {
            var iosValue = {};
            var androidValue = {};
            for (var langCount = 0; langCount < this.languages.length; langCount++) {
              var currentLang = this.languages[langCount];
              var currentValue = this.fields[count].field.value[currentLang.id];
              if (currentValue) {
                if (currentValue.includes('android=')) {
                  var androidSecondData = currentValue.split('android=')[1];
                  var androidFirstData = currentValue.split('android=')[0];
                  if (androidSecondData.includes('ios=')) {
                    iosValue[currentLang.id] = decodeURIComponent(androidSecondData.split('&ios=')[1]);
                    androidValue[currentLang.id] = decodeURIComponent(androidSecondData.split('&ios=')[0]);
                  } else {
                    androidValue[currentLang.id] = decodeURIComponent(androidSecondData);
                  }
                  if (androidFirstData.includes('ios=')) {
                    iosValue[currentLang.id] = decodeURIComponent(androidFirstData.split('ios=')[1].replace(/&$/, ''));
                  }
                } else {
                  if (currentValue.includes('ios=')) {
                    var iosData = currentValue.split('ios=')[1];
                    iosValue[currentLang.id] = decodeURIComponent(iosData);
                  } else {
                    iosValue[currentLang.id] = decodeURIComponent(currentValue);
                  }
                }
              }
            }
            Ember.set(this.fields[count].field, 'ios', iosValue);
            Ember.set(this.fields[count].field, 'android', androidValue);
          }
        }
      }
    },
    pushMessagesLanguagePreference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('pushMessagesDefaultLanguage');
    }),
    isDefualtLangNotification: Ember.computed('pushMessagesLanguagePreference', function () {
      return !this.pushMessagesLanguagePreference.hasViewedTour;
    }),
    isMessageTemplatesEmpty: Ember.computed('messageTemplates', function () {
      return this.get('messageTemplates.length') === 0;
    }),
    preselectMessageTemplate: function preselectMessageTemplate() {
      var messageTemplates = this.messageTemplates;
      if (!Ember.isEmpty(messageTemplates)) {
        var template = messageTemplates.findBy('isSelected', true);
        this.send('selectMessageTemplate', template, true);
      }
    },
    determineDefaultLanguage: function determineDefaultLanguage() {
      var defaultLanguage = this.languages.findBy('isDefault', true);
      if (!defaultLanguage) {
        defaultLanguage = this.languages.objectAt(0);
      }
      this.set('defaultLanguage', defaultLanguage);
    },
    isSectionInvalid: function isSectionInvalid() {
      var isDraft = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var isInvalid = false;
      if (isDraft) {
        this.mainFields.forEach(function (field) {
          field.set('errorMessages', null);
          field.set('isValid', true);
        });
        this.selectedMessageTemplate.fields.forEach(function (field) {
          field.set('errorMessages', null);
          field.set('isValid', true);
        });
        return false;
      } else {
        // Determine if the default language of the body is set
        if (this.mainFields) {
          isInvalid = isInvalid || this.mainFields.isAny('isValid', false);
        }
        // For each field, ensure each field is valid if isRequired
        if (this.selectedMessageTemplate) {
          isInvalid = isInvalid || this.get('selectedMessageTemplate.fields').isAny('isValid', false);
          var isDropdownWithoutOptions = this.get('selectedMessageTemplate.fields').find(function (field) {
            return field.type === 'dropdown' && !field.valueOptions.length;
          });
          if (isDropdownWithoutOptions) {
            isInvalid = false;
          }
        }
        if (this.fields) {
          this.fields.forEach(function (element) {
            if (element.field.key === "actionScheme" || element.field.key === "actionType") {
              isInvalid = isInvalid || !element.field.isValid;
            }
          });
        }
        return isInvalid;
      }
    },
    saveSection: function saveSection() {
      var _this2 = this;
      var model = this.model;
      var customFields = {};
      if (this.mainFields) {
        this.mainFields.forEach(function (field) {
          _this2.model.set(field.type, field.value);
        });
      }
      if (this.selectedMessageTemplate) {
        this.get('selectedMessageTemplate.fields').forEach(function (field) {
          var value = field.get('value');
          if (typeof value === 'string') {
            value = value.trim();
          }
          if (field.isValid) {
            if (field.key === 'actionScheme') {
              customFields['isSkipUrlValidation'] = field.isSkipUrlValidation;
            }
            customFields[field.key] = value;
          }
        });
      }
      model.set('pushTypeId', this.get('selectedMessageTemplate.id'));
      model.set('customFields', Ember.Object.create(customFields));
      model.set('isAlert', !this.get('selectedMessageTemplate.isSilentPushNotification'));
    },
    validateSection: function validateSection() {
      var _this3 = this;
      var isDraft = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      // set the initial value of isDraft passed when CTA is clicked
      this.set('isDraft', isDraft);
      setTimeout(function () {
        // Hack: reset it at the end of code execution (isDraft = true will bypass validation)
        _this3.set('isDraft', false);
      }, 50);
      this.set('requiredFields', this.fields.filter(function (field) {
        return field.field.isRequired;
      }));
      this.requiredFields.map(function (field) {
        if (field.triggerValidation) {
          field.triggerValidation(isDraft);
        }
      });
      var isInvalid = this.isSectionInvalid(isDraft);
      isInvalid = isDraft ? false : isInvalid;
      this.set('isInvalid', isInvalid);
      this.saveSection();
      if (isInvalid) {
        this.errors.set('message', true);
        this.set('isOpen', true);
        return false;
      } else {
        this.errors.set('message', false);
        return true;
      }
    },
    actions: {
      // Listen to content-input field when user selects a new content
      // Sync nearbyLocationProperties which is used in the delivery-section.
      onSelectContent: function onSelectContent(model) {
        if (this.selectedMessageTemplate.name === 'Location Content') {
          this.setProperties({
            nearbyLocationProperties: {
              isSelected: this.nearbyLocationProperties.isSelected,
              ruleId: model ? model.ruleId : ''
            }
          });
        }
      },
      dismiss: function dismiss() {
        this.pushMessagesLanguagePreference.set('hasViewedTour', true);
        this.pushMessagesLanguagePreference.save();
        this.isDefualtLangNotification = false;
      },
      validate: function validate() {
        return this.validateSection();
      },
      bindFieldToSection: function bindFieldToSection(field) {
        this.fields.push(field);
      },
      unbindFieldFromSection: function unbindFieldFromSection(field) {
        this.fields.removeObject(field);
      },
      checkValidation: function checkValidation(value, key, isFocusOut) {
        var _this4 = this;
        if (key === 'actionType' && value) {
          this.set('dropdownValue', typeof value === 'string' ? value : value.val);
        }
        this.set('isFocusOut', isFocusOut);
        var currentfield = this.fields.find(function (item) {
          return item.field.key === "actionScheme";
        });
        if (value === 'Action Link' && !key && !currentfield) {
          this.set('dropdownValue', 'mailto:UNIVERSAL_ACTION');
        }
        if (currentfield) {
          currentfield.field.set('isExternalDeepLink', value === 'app:UNIVERSAL_ACTION');
          if (key === 'actionScheme' && isFocusOut) {
            var errorMessages = [];
            var locales = window.Object.keys(value);
            for (var i = 0; i < locales.length; i++) {
              var localeKey = locales[i];
              var valuex = value[localeKey];
              if ((!valuex || (valuex === null || valuex === void 0 ? void 0 : valuex.trim()) === "") && currentfield.field.isRequired && this.languages.findBy('isDefault', true).id === localeKey) {
                errorMessages.push('Missing reqiured field.');
                currentfield.field.set('isValid', false);
                currentfield.field.set('errorMessages', errorMessages);
              } else if (!(!valuex || (valuex === null || valuex === void 0 ? void 0 : valuex.trim()) === "")) {
                if (this.dropdownValue === 'mailto:UNIVERSAL_ACTION' && !currentfield.field.isSkipUrlValidation) {
                  currentfield.field.set('isValid', true);
                  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                  if (!valuex.match(validRegex)) {
                    errorMessages.push('Must be a valid Email.');
                    currentfield.field.set('isValid', false);
                    currentfield.field.set('errorMessages', errorMessages);
                  }
                } else if (this.dropdownValue === 'tel:UNIVERSAL_ACTION' && !currentfield.field.isSkipUrlValidation) {
                  currentfield.field.set('isValid', true);
                  var _validRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                  if (!valuex.match(_validRegex)) {
                    errorMessages.push('Must be a valid phone number.');
                    currentfield.field.set('isValid', false);
                    currentfield.field.set('errorMessages', errorMessages);
                  }
                } else if (this.dropdownValue && this.dropdownValue.includes('UNIVERSAL_ACTION')) {
                  currentfield.field.set('isValid', true);
                }
              }
            }
          }
        }
        Ember.run.scheduleOnce('afterRender', function (isFocusOut) {
          if (_this4.isDraft) {
            _this4.errors.set('message', false);
            var _currentfield = _this4.fields.find(function (item) {
              return item.field.key === "actionScheme";
            });
            return;
          }
          _this4.set('requiredFields', _this4.fields.filter(function (field) {
            return field.field.isRequired;
          }));
          var isAllDirty = _this4.requiredFields.isEvery('isDirty', true);
          _this4.set('isInvalid', true);
          _this4.errors.set('message', false);
          if (isAllDirty || _this4.isEditMode) {
            _this4.saveSection();
            var isInvalid = _this4.isSectionInvalid();
            _this4.set('isInvalid', isInvalid);
            if (isInvalid) {
              _this4.errors.set('message', true);
            } else {
              _this4.errors.set('message', false);
            }
          }
        });
      },
      openSection: function openSection() {
        this.saveSection();
        this.sendAction('startOnboarding'); // eslint-disable-line ember/closure-actions
        this.set('isOpen', !this.isOpen);
      },
      selectMessageTemplate: function selectMessageTemplate(template, isChanged) {
        var _template$fields, _template$fields$;
        if (template.name === 'Action Link' && !isChanged) {
          var actionValue = template === null || template === void 0 ? void 0 : template.fields.find(function (field) {
            return (field === null || field === void 0 ? void 0 : field.key) === "actionType";
          });
          Ember.set(actionValue, 'value', '');
          var schemeValue = template === null || template === void 0 ? void 0 : template.fields.find(function (field) {
            return (field === null || field === void 0 ? void 0 : field.key) === "actionScheme";
          });
          var emptyValue = Ember.Object.create({});
          emptyValue[this.defaultLanguage.id] = '';
          Ember.set(schemeValue, 'input', emptyValue);
          Ember.set(schemeValue, 'value', emptyValue);
        }
        if (!Ember.isEqual(this.selectedMessageTemplate, template) && !Ember.isEmpty(this.selectedMessageTemplate)) {
          this.send('checkValidation', template.name, template.key);
        }
        if (template !== null && template !== void 0 && (_template$fields = template.fields) !== null && _template$fields !== void 0 && (_template$fields$ = _template$fields[0]) !== null && _template$fields$ !== void 0 && _template$fields$.hasOwnProperty('order')) {
          template.fields.sort(function (firstCategory, secondCategory) {
            return firstCategory.order >= secondCategory.order ? 1 : -1;
          });
        }
        this.set('selectedMessageTemplate', template);
        this.sendAction('setSelectedMessageTemplate', template); // eslint-disable-line ember/closure-actions
        if (template.name === 'Location Content') {
          this.setProperties({
            nearbyLocationProperties: {
              isSelected: true,
              ruleId: undefined
            }
          });
        } else {
          this.setProperties({
            nearbyLocationProperties: {
              isSelected: false,
              ruleId: this.nearbyLocationProperties.ruleId
            }
          });
        }
      },
      handleSearch: function handleSearch(keyword, modelName, propertiesVar) {
        this.sendAction('handleSearch', keyword, modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels(modelName, propertiesVar) {
        this.sendAction('loadModels', modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      },

      updateDynamicAttribute: function updateDynamicAttribute(value) {
        this.set('currentEditToken', value);
        this.set('isDynamicAttributeDropDownVisible', true);
      },
      edit: function edit() {
        this.set('dynamicAtrributeValue.value', this.currentEditToken);
      },
      delete: function _delete() {
        this.set('dynamicAtrributeValue.delete', this.currentEditToken.parentElement);
        this.currentEditToken.parentElement.removeChild(this.currentEditToken);
      }
    }
  });
  _exports.default = _default;
});