define("experience-studio/components/model-priority-select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = Ember.Component.extend({
    classNames: ['model-priority-select-dropdown'],
    selectedPriority: null,
    selectedPriorityObject: null,
    priorityList: Ember.computed('model.[]', function () {
      var maxPriority = this.getMaxPriority() + 1;
      var priorityList = [];
      for (var i = 1; i <= maxPriority; i++) {
        priorityList.pushObject(Ember.Object.create({
          label: i === maxPriority ? "".concat(i, " (last)") : i,
          value: i
        }));
      }
      return priorityList;
    }),
    getMaxPriority: function getMaxPriority() {
      var model = this.model;
      var priorities = model.mapBy('priorityIndex').compact();
      var maximum = Math.max.apply(Math, _toConsumableArray(priorities));
      if (maximum >= 0) {
        return maximum;
      } else {
        return 0;
      }
    },
    didInsertElement: function didInsertElement() {
      var maxPriority = this.getMaxPriority();
      var priorityList = this.priorityList;
      var selectedPriority = this.selectedPriority;
      this.send('toggleSelection', this.selectedPriority ? priorityList.objectAt(selectedPriority - 1) : priorityList.objectAt(maxPriority));
    },
    actions: {
      toggleSelection: function toggleSelection(item) {
        this.priorityList.setEach('isSelected', false);
        item.set('isSelected', true);
        this.set('selectedPriority', item.value);
        this.set('selectedPriorityObject', item);
      }
    }
  });
  _exports.default = _default;
});