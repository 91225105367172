define("experience-studio/templates/components/av5-label-select-dropdown-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kvrJS+nh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"inner\"],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"type\",\"value\",\"class\",\"placeholder\",\"autocomplete\",\"key-up\"],[\"text\",[35,2],\"form-control search-input\",\"Search\",\"off\",\"onInput\"]]]],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[14,0,\"clear-icon\"],[15,\"onClick\",[30,[36,1],[[32,0],\"clear\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"times\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"search-icon\"],[12],[1,[30,[36,0],[\"search\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"action\",\"searchKeyword\",\"input\",\"isSearchStarted\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/av5-label-select-dropdown-search.hbs"
  });
  _exports.default = _default;
});