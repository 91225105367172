define("experience-studio/serializers/group", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      if (!Ember.isEmpty(data.experienceIds)) {
        data.experiences = data.experienceIds.map(function (item) {
          return {
            id: item
          };
        });
      } else {
        data.experiences = [];
      }
      delete data.experienceIds;
      return data;
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      item.attributes.isPinned = true;
      if (!Ember.isEmpty(item.attributes.index)) {
        item.attributes.priorityIndex = item.attributes.index;
        delete item.attributes.index;
      }
      if (!Ember.isEmpty(item.attributes.experiences)) {
        item.attributes.experienceIds = item.attributes.experiences.map(function (item) {
          return item.id;
        });
      } else {
        item.attributes.experienceIds = [];
      }
      return item;
    }
  });
  _exports.default = _default;
});