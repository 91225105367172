define("experience-studio/templates/components/date-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VZIZcPD+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[14,0,\"form-control date-field data-field\"],[14,\"placeholder\",\"Select Date and Time\"],[14,4,\"text\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "experience-studio/templates/components/date-time-picker.hbs"
  });
  _exports.default = _default;
});