define("experience-studio/components/route-model-filter-dropdown-user-select", ["exports", "experience-studio/components/route-model-filter-dropdown-model-select"], function (_exports, _routeModelFilterDropdownModelSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeModelFilterDropdownModelSelect.default.extend({
    classNames: ['route-model-filter-dropdown-user-select']
  });
  _exports.default = _default;
});