define("experience-studio/routes/content-hub/blueprint-template/create", ["exports", "experience-studio/mixins/field-validator-factory"], function (_exports, _fieldValidatorFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_fieldValidatorFactory.default, {
    queryParams: {
      data: {}
    },
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    intl: Ember.inject.service(),
    contextRuleUtility: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.controller.set('selectedRule', null);
    },
    model: function model(params) {
      var contentBlueprint;
      if (params.data) {
        contentBlueprint = this.extractModel(params);
      } else {
        var contentTemplate = this.modelFor('content-hub.blueprint-template');
        var isContextLocked = contentTemplate.isContextLocked === undefined ? true : contentTemplate.isContextLocked;
        contentBlueprint = this.store.createRecord('contentBlueprint', {
          isDraft: true,
          contentTemplateId: contentTemplate.id,
          description: contentTemplate.description,
          iconUrl: contentTemplate.iconUrl,
          isContextLocked: isContextLocked,
          localizations: contentTemplate.localizations,
          labels: contentTemplate.labels,
          name: contentTemplate.name,
          templateType: contentTemplate.type,
          selectedLabels: contentTemplate.selectedLabels
        });
      }
      if (contentBlueprint.originalContentInstanceId) {
        return Ember.RSVP.hash({
          content: this.store.queryRecord('content-instance', {
            id: contentBlueprint.originalContentInstanceId,
            data: true,
            bypassAdmin: true
          }),
          contentBlueprint: contentBlueprint,
          plugins: this.store.findAll('plugin'),
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          projectCustomTemplates: this.store.queryRecord('project-config', {
            setting: 'project-custom-content-templates'
          })
        });
      } else {
        return Ember.RSVP.hash({
          contentBlueprint: contentBlueprint,
          languages: this.store.queryRecord('project-config', {
            setting: 'language'
          }),
          plugins: this.store.findAll('plugin'),
          projectCustomTemplates: this.store.queryRecord('project-config', {
            setting: 'project-custom-content-templates'
          })
        });
      }
    },
    afterModel: function afterModel(model) {
      if (model.content) {
        model.contentBlueprint.setProperties({
          content: model.content.get('content'),
          values: model.content.get('values')
        });
      }
    },
    extractModel: function extractModel(params) {
      var data = this.utility.atob(params.data);
      data.name += ' - Copy';
      return this.store.createRecord('contentBlueprint', data);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var initialHash = controller.initialHash;
      var newHash = this.utility.hash({
        name: controller.model.get('name'),
        description: controller.model.get('description'),
        image: controller.model.get('iconUrl'),
        locked: controller.model.get('isContextLocked') || false,
        data: controller.model.serialize().data,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack() {
      var applicationController = this.controllerFor('application');
      var queryParams = null;
      var routeName = 'content-hub.location';
      if (applicationController) {
        if (this.userPreferences.getValue('routeListView')) {
          routeName = 'content-hub.location';
          queryParams = {
            data: this.utility.btoa({
              timestamp: Date.now()
            })
          };
        }
      }
      if (queryParams) {
        this.transitionTo(routeName, {
          queryParams: queryParams
        });
      } else {
        this.transitionTo(routeName, {
          queryParams: {
            data: ''
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      controller.setProperties({
        rule: model.rule,
        languages: model.languages.config,
        plugins: model.plugins,
        selectedLabels: [],
        transitionConfirmed: false,
        displayFooter: true
      });
      model = model.contentBlueprint;
      Ember.run.scheduleOnce('afterRender', this, function () {
        controller.set('initialHash', _this.utility.hash({
          name: model.get('name'),
          description: model.get('description'),
          image: model.get('iconUrl'),
          locked: model.get('isContextLocked') || false,
          data: model.serialize().data,
          selectedLabels: controller.selectedLabels.mapBy('value').sort()
        }));
      });
      this._super(controller, model);
    },
    actions: {
      // required to avoid error
      deselectModelListItems: function deselectModelListItems() {},
      displayFooter: function displayFooter() {
        var isFooterDisplayed = this.controller.get('displayFooter');
        this.controller.set('displayFooter', isFooterDisplayed ? false : true);
      },
      cancel: function cancel() {
        var controller = this.controller;
        controller.set('transitionConfirmed', true);
        this.goBack();
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.set('errorMessage', null);
      },
      confirmCancel: function confirmCancel() {
        if (this.hasChanges()) {
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this.send('cancel');
        }
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      // we'll have to update the  name of the instance here
      // keep the selected rule in controller, and use it when saving
      onSelectRule: function onSelectRule(rule) {
        this.controller.set('selectedRule', rule);
      },
      save: function save() {
        var _this2 = this;
        var controller = this.controller;
        var labelAdapter = this.store.adapterFor('label');
        var model = controller.model;
        if (controller.data) {
          model = this.store.createRecord('contentBlueprint', model.toJSON());
        }
        var selectedLabels = this.controller.get('selectedLabels');
        if (this.currentModel.contentBlueprint.isConciergeTemplate) {
          selectedLabels = this.currentModel.contentBlueprint.selectedLabels;
        }

        // Rule must be attached to the blueprint content
        // User can decide to deactivate or delete if they wish to discontinue the content
        var selectedRule = controller.get('selectedRule');
        if (!selectedRule) {
          controller.set('errorMessage', 'Select a rule.');
          return;
        }

        // Rule must be set as it will be used in the serilization
        // step to construct nearby location object
        model.set('rule', selectedRule);

        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this2.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          model.set('labels', selectedLabels.mapBy('value'));
          // forcefully set draft to false for now
          model.set('isDraft', false);
          return model.save();
        }).then(function () {
          _this2.controller.set('transitionConfirmed', true);
          _this2.send('refresh');
          _this2.transitionTo('content-hub.location');
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            _this2.send('showNotification', {
              type: 'confirm',
              message: "Successfully created \"".concat(model.get('name'), "\" Content")
            });
          });
        }).catch(function (response) {
          controller.set('errorMessage', _this2.utility.parseError(response));
        });
      },
      validate: function validate() {
        var controller = this.controller;
        var model = controller.get('model.root');
        var languages = controller.get('languages');
        var validator = _fieldValidatorFactory.default.getValidator(model, languages);
        var validation = validator.validate({
          triggerCascade: true
        });
        if (!Ember.isEmpty(controller.get('model.iconUrl')) && !controller.get('model.iconUrl').match(/^https:/)) {
          controller.set('errorMessage', this.intl.t('errors.invalid.icon.host'));
        } else if (Ember.isEmpty(controller.get('model.name'))) {
          controller.set('errorMessage', 'Enter name.');
        } else if (!validation.state) {
          var plural = validation.errors.length > 1 ? 's' : '';
          controller.set('errorMessage', "You have (".concat(validation.errors.length, ") unresolved error").concat(plural, "."));
        } else {
          controller.set('errorMessage', '');
          this.send('save');
        }
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          window.history.go(1);
          this.send('confirmCancel');
        } else {
          controller.setProperties({
            data: '',
            errorMessage: '',
            transitionConfirmed: false,
            previousTransition: null
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});