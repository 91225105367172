define("experience-studio/serializers/permission-entity", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    transformPayloadForEmber: function transformPayloadForEmber(item) {
      item = this._super.apply(this, arguments);
      if (!item.id) {
        item.id = item.attributes.virtualId;
      }
      return item;
    }
  });
  _exports.default = _default;
});