define("experience-studio/helpers/info-sidebar-rule-composition-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute: function compute(params) {
      var literal = params[0];
      var isDateTime = params[1];
      var serializer = this.store.serializerFor('rule');
      var text = '';
      if (literal.predicate && !Ember.isEmpty(literal.predicate.arguments)) {
        var comparisonOperator = serializer.getComparisonOperatorAsText(literal.predicate.name);
        var args = literal.predicate.arguments;
        var argOne = args.objectAt(0);
        var argTwo = args.objectAt(1);
        var argThree = args.objectAt(2);
        var hasQuery = false;
        var parameters = [];
        if (argOne && typeof argOne === 'string' && argOne.indexOf('ctx.') >= 0) {
          if (argOne.indexOf('query') >= 0) {
            hasQuery = true;
            parameters = argOne.split('.query.');
            parameters[1] = parameters[1].split('.');
            parameters[0] += ".".concat(parameters[1].shift());
            argOne = parameters[0];
            parameters = parameters[1];
          }
          argOne = serializer.getUIDAsText(argOne);
        }
        if (argTwo && typeof argTwo === 'string' && argTwo.indexOf('ctx.') >= 0) {
          argTwo = serializer.getUIDAsText(argTwo);
        }
        if (argTwo && isDateTime) {
          argTwo = window.moment(parseInt(argTwo) * 1000).format('h:mA MMM D, YYYY');
        }
        if (argThree && typeof argThree === 'string' && argThree.indexOf('ctx.') >= 0) {
          argThree = serializer.getUIDAsText(argThree);
        }
        if (literal.predicate.arguments.length === 3) {
          var mathOperator = serializer.getMathOperatorsAsText(literal.predicate.name);
          text = "".concat(argOne, " ").concat(mathOperator, " ").concat(argTwo, " is equal to ").concat(argThree);
        } else {
          text = "".concat(argOne, " ").concat(comparisonOperator, " ").concat(argTwo);
        }
        if (hasQuery) {
          text += " --- with parameters: ".concat(parameters.join(', '));
        }
        if (text.toLowerCase().indexOf('location with label') >= 0) {
          text = "Location's Label: ".concat(parameters.join(', '), " is equal to true");
        }
      } else if (literal.predicate && literal.predicate.name) {
        text = literal.predicate.name;
      }
      return text;
    }
  });
  _exports.default = _default;
});