define("experience-studio/components/rule-search-scroll-select-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['rule-search-scroll-select-list'],
    searchKeyword: '',
    listStateIndicator: Ember.computed('searchKeyword', 'modelProperties.isLoading', 'isTyping', function () {
      if (this.isTyping || this.get('modelProperties.isLoading')) {
        return 'Loading...';
      } else if (this.searchKeyword) {
        return "".concat(this.get('modelProperties.totalRecords'), " Search results");
      } else {
        return 'All Rules';
      }
    }),
    actions: {
      handleAction: function handleAction(action, model) {
        switch (action) {
          case 'select':
            if (Ember.isArray(model)) {
              model = model[0];
            }
            this.send('selectModelListItems', model);
            break;
        }
      },
      selectModelListItems: function selectModelListItems(model) {
        var selectedRules = this.selectedRules;
        var rules = this.model;
        if (model.get('isSelected')) {
          model.set('isSelected', false);
          selectedRules.removeObject(model);
        } else if (!this.isMultiSelect) {
          rules.setEach('isSelected', false);
          model.set('isSelected', true);
          selectedRules.setEach('isSelected', false);
          selectedRules.clear();
          selectedRules.pushObject(model);
        } else {
          model.set('isSelected', true);
          selectedRules.pushObject(model);
        }
      },
      onTyping: function onTyping(isTyping) {
        this.set('isTyping', isTyping);
      },
      handleSearch: function handleSearch(searchKeyword) {
        this.set('modelProperties.searchKeyword', searchKeyword);
        this.sendAction('handleSearch', this.modelProperties); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels() {
        this.sendAction('loadModels', this.modelProperties); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});