define("experience-studio/serializers/location", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      item.attributes.address = item.attributes.address || '';
      item.attributes.creatorId = item.attributes.creatorID || '';
      item.attributes.creatorFullName = item.attributes.creatorFullName || '';
      item.attributes.radius = item.attributes.radius || '100';
      item.attributes.labels = item.attributes.labels || [];
      item.attributes.name = item.attributes.name || '';
      item.attributes.shape = item.attributes.shape || [];
      if (!Ember.isEmpty(item.attributes.shape)) {
        item.attributes.shape = item.attributes.shape[0];
      }
      var foramttedLabels = [];
      item.attributes.labels.forEach(function (item) {
        if (item) {
          foramttedLabels.push(item);
        }
      });
      item.attributes.labels = foramttedLabels;
      return item;
    },
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      if (!data.id) {
        delete data.creatorId;
        data = {
          address: data.address,
          name: data.name,
          shape: data.shape,
          labels: data.labels
        };
      }
      if (data.shape) {
        data.shape = [data.shape];
      }
      return data;
    }
  });
  _exports.default = _default;
});