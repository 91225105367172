define("experience-studio/components/edit-form", ["exports", "experience-studio/mixins/nested-data-load", "experience-studio/mixins/question-factory"], function (_exports, _nestedDataLoad, _questionFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_nestedDataLoad.default, {
    classNames: ['edit-form'],
    languages: null,
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var uiTypes = Object.keys(_questionFactory.default.uiTypes);
      var qTypes = uiTypes.map(function (key) {
        return _questionFactory.default.uiTypes[key];
      });
      var questions = this.get('model.surveyQuestions.values.root.questions') || [];
      questions = questions.map(function (q) {
        return _questionFactory.default.makeQuestion(q);
      });
      this.setProperties({
        metaOpen: false,
        questionTypes: qTypes,
        questions: questions,
        isLoadingQuestions: true,
        iconRequirements: {
          aspectRatio: 1
        },
        iconTransform: {
          resize: {
            width: 900
          }
        }
      });
      var pagination;
      if (this.get('model.surveyQuestions.values.root')) {
        pagination = this.get('model.surveyQuestions.values.root')['questions.pagination'];
      }
      if (pagination && pagination.totalRecords > pagination.limit) {
        this.set('isLoadingQuestions', true);
        this.getAllData(this.get('model.surveyQuestions.id'), 'questions').then(function (result) {
          var fullQList = result.data[0].questions;
          fullQList = fullQList.slice(pagination.limit).map(function (q) {
            return _questionFactory.default.makeQuestion(q);
          });
          _this.questions.pushObjects(fullQList);
          _this.set('isLoadingQuestions', false);
        });
      } else {
        this.set('isLoadingQuestions', false);
      }
    },
    actions: {
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.sendAction('fetchLabels', labelPromise, query); // eslint-disable-line ember/closure-actions
      },
      toggleMeta: function toggleMeta() {
        this.toggleProperty('metaOpen');
      }
    }
  });
  _exports.default = _default;
});