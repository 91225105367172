define("experience-studio/adapters/csvfile", ["exports", "jquery", "experience-studio/adapters/application"], function (_exports, _jquery, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = _application.default.extend({
    session: Ember.inject.service(),
    utility: Ember.inject.service(),
    namespace: 'context/file-type/files',
    buildURL: function buildURL() {
      var _arguments = Array.prototype.slice.call(arguments),
        fileType = _arguments[0],
        urlArguments = _arguments.slice(1);
      var url = this._super.apply(this, [null].concat(_toConsumableArray(urlArguments)));
      var modelType = fileType;
      if (fileType === 'location') {
        modelType = 'locations';
      } else if (fileType === 'context-data') {
        modelType = 'ctxdataimport';
      }
      return url.replace('/file-type', "/".concat(modelType));
    },
    urlForQuery: function urlForQuery(query) {
      var url = this._super.apply(this, arguments);
      query.limit = 10;
      url = "".concat(url, "?").concat(_jquery.default.param(JSON.parse(JSON.stringify(query))));
      return url;
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      var fileType = snapshot.fileType || snapshot.record.fileType;
      var modelType = 'ctxdataimport';
      if (fileType === 'location') {
        modelType = 'location';
        return url.replace('/file-type', "/".concat(modelType));
      } else if (fileType === 'context-data') {
        modelType = 'ctxdataimport';
        return url.replace('/file-type', "/".concat(modelType));
      }
    },
    query: function query(store, type, _query) {
      var filePromises = [];
      if (_query.data) {
        _query = this.utility.atob(_query.data);
        if (!Ember.isEmpty(_query.searchKeyword)) {
          _query.search = _query.searchKeyword;
          delete _query.searchKeyword;
        }
      }
      delete _query.data;
      if (_query.fileType === 'location' || Ember.isEmpty(_query.fileType)) {
        filePromises.push(this.queryLocationsData(store, type, _query));
      }
      if (_query.fileType === 'context-data' || Ember.isEmpty(_query.fileType)) {
        filePromises.push(this.queryContextData(store, type, _query));
      }
      return this.mergeFileRequests(filePromises);
    },
    queryLocationsData: function queryLocationsData(store, type, query) {
      var _this = this;
      var locationsDataUrl = this.buildURL('location', null, null, 'query', query);
      var url = locationsDataUrl.replace('locations', 'location');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'GET',
          url: url
        }).done(function (response) {
          response.data.forEach(function (item) {
            // eslint-disable-line
            item['fileType'] = 'location';
          });
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    queryContextData: function queryContextData(store, type, query) {
      var _this2 = this;
      var contextDataUrl = this.buildURL('context-data', null, null, 'query', query);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this2.headers,
          type: 'GET',
          url: contextDataUrl
        }).done(function (response) {
          Ember.run(function () {
            response.data.forEach(function (file) {
              file.fileType = 'context-data';
            });
            resolve(response);
          });
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    mergeFileRequests: function mergeFileRequests(filePromises) {
      var _this3 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.RSVP.allSettled(filePromises).then(function (responses) {
          var data = [];
          var pagination = {
            offset: 0,
            limit: _this3.paginationLimit,
            totalRecords: 0
          };
          var _iterator = _createForOfIteratorHelper(responses),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var response = _step.value;
              var state = response.state,
                value = response.value,
                reason = response.reason;
              if (state === 'fulfilled') {
                data = [].concat(_toConsumableArray(data), _toConsumableArray(value.data));
                pagination.totalRecords += value.pagination.totalRecords;
              } else {
                reject(reason);
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          data = data.sortBy('createdAt').reverse();
          resolve({
            data: data,
            pagination: pagination
          });
        });
      });
    },
    processFile: function processFile(store, type, snapshot) {
      var _this4 = this;
      var url = this.buildURL(snapshot.fileType, snapshot.id, snapshot, 'queryRecord');
      url = "".concat(url, "/import");
      if (snapshot.fileType === 'location') {
        url = url.replace("locations/files/import", "location/files/".concat(snapshot.id));
      }
      var data = {
        fileId: snapshot.get('fileID')
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this4.headers,
          type: 'POST',
          data: JSON.stringify(data),
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    }
  });
  _exports.default = _default;
});