define("experience-studio/mixins/draggable-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    classNameBindings: ['isDragging:rearranging:'],
    isRenderShow: true,
    isDragging: false,
    placeholder: '',
    setInputValues: function setInputValues() {
      this.$('.leaves input').each(function () {
        this.setAttribute('value', this.value);
      });
    },
    setPlaceholderHeight: function setPlaceholderHeight() {
      var clone = this.$('.leaves')[0].innerHTML;
      clone = clone.replace(' gu-transit', '');
      this.set('placeholder', clone);
    },
    reorder: function reorder() {
      var view = this;
      this.setInputValues();
      this.setPlaceholderHeight();
      this.set('isRenderShow', false);
      setTimeout(function () {
        view.set('isRenderShow', true);
      }, 1);
    },
    initDrag: function initDrag() {
      var view = this;
      var drake = null;
      var nodes = this.$('.leaves')[0];
      drake = dragula([nodes], {
        direction: 'vertical',
        invalid: function invalid(el) {
          var tagName = el.tagName.toLowerCase();
          return tagName === 'input' || tagName === 'textarea';
        },
        isContainer: function isContainer(el) {
          return el.classList.contains('leaves') && el.parentNode.getAttribute('tmp-id') === view.get('field.tmpID');
        },
        moves: function moves(el, source, handle) {
          var containers = (0, _jquery.default)(handle).parents('.leaves');
          if (containers[0].getAttribute('tmp-id') !== nodes.getAttribute('tmp-id')) {
            return false;
          }
          return true;
        }
      });
      drake.on('drop', function (el, target, source, sibling) {
        view.send('reorder', {
          elemID: el.getAttribute('tmp-id'),
          targetID: target.getAttribute('tmp-id'),
          sourceID: source.getAttribute('tmp-id'),
          siblingID: sibling ? sibling.getAttribute('tmp-id') : undefined
        });
      });
      drake.on('drag', function () {
        view.set('isDragging', true);
      });
      drake.on('dragend', function () {
        view.set('isDragging', false);
      });
    }
  });
  _exports.default = _default;
});