define("experience-studio/controllers/context-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    userPreferences: Ember.inject.service(),
    router: Ember.inject.service(),
    currentRoute: Ember.computed.alias('router.currentURL'),
    mainHeaderTabs: Ember.computed('viewType', 'currentRoute', function () {
      var isV2 = this.currentRoute.split('/').pop().indexOf('rule-v2') === 0;
      var routeNamePrefix = 'context-hub.';
      var tabs = [];
      if (isV2) {
        tabs.pushObject(Ember.Object.create({
          isActive: false,
          name: 'Rules',
          routeName: "".concat(routeNamePrefix, "rule-v2")
        }));
      } else {
        tabs.pushObject(Ember.Object.create({
          isActive: false,
          name: 'Rules',
          routeName: "".concat(routeNamePrefix, "rule")
        }));
      }
      tabs.pushObject(Ember.Object.create({
        isActive: false,
        name: 'Uploads',
        routeName: "".concat(routeNamePrefix, "upload")
      }));
      tabs.pushObject(Ember.Object.create({
        isActive: false,
        name: 'Locations',
        routeName: "".concat(routeNamePrefix, "location")
      }));
      return tabs;
    })
  });
  _exports.default = _default;
});