define("experience-studio/components/route-model-filter-dropdown-checkbox-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-dropdown-checkbox-select', 'form-group'],
    classNameBindings: ['section.isDisabled:disabled'],
    isDisabled: false,
    showIncompatibleMessage: Ember.computed('section.{incompatibleProperties,options.@each.isSelected}', function () {
      return !Ember.isEmpty(this.section.incompatibleSections) && this.section.options.isAny('isSelected', true);
    }),
    determineSelection: function determineSelection() {
      var _this = this;
      if (this.queryParameters && this.get('section.options')) {
        this.section.options.forEach(function (option) {
          var queryProperty = _this.queryParameters[option.property];
          var optionProperty = option.value;
          var isSelected = _this.arePropertiesEqual(queryProperty, optionProperty);
          option.set('isSelected', isSelected);
        });
      }
    },
    handleDisabling: function handleDisabling() {
      var isSelected = this.section.options.isAny('isSelected', true);
      if (!Ember.isEmpty(this.get('section.incompatibleSections')) && this.toggleFilterSections) {
        this.toggleFilterSections(this.section.incompatibleSections, isSelected);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.determineSelection();
      this.handleDisabling();
    },
    actions: {
      makeSelection: function makeSelection(option) {
        if (this.didUpdateQueryParams && !this.get('section.isDisabled')) {
          var queryProperty = this.queryParameters[option.property];
          var optionProperty = option.value;
          var queryObject = {};
          var proprtyExists = this.arePropertiesEqual(queryProperty, optionProperty);
          queryObject[option.property] = proprtyExists ? null : optionProperty;
          this.didUpdateQueryParams(queryObject);
        }
      }
    },
    arePropertiesEqual: function arePropertiesEqual(firstProperty, secondProperty) {
      var isEqual = false;
      if (_typeof(firstProperty) === 'object' && _typeof(secondProperty) === 'object') {
        isEqual = JSON.stringify(firstProperty) === JSON.stringify(secondProperty);
      } else {
        isEqual = firstProperty === secondProperty;
      }
      return isEqual;
    }
  });
  _exports.default = _default;
});