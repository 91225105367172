define("experience-studio/adapters/experience", ["exports", "experience-studio/adapters/application", "jquery"], function (_exports, _application, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    namespace: 'kernel',
    getJourney: function getJourney(experienceId) {
      var _this = this;
      var url = "".concat(this.buildURL(), "/journey/instance/").concat(experienceId);
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'GET',
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (err) {
          reject(err);
        });
      });
    },
    toggleProperty: function toggleProperty(model, property) {
      return this._super(model, property, {
        isActive: {
          false: 'deactivate',
          true: 'activate'
        }
      });
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this._super.apply(this, arguments), "?limit=").concat(this.paginationLimit);
    },
    updateIsActive: function updateIsActive(id, isActive) {
      var _this2 = this;
      var url = this.buildURL('experience', id);
      url = "".concat(url, "/").concat(isActive ? 'activate' : 'deactivate');
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this2.headers,
          type: 'PUT',
          url: url
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    },
    getExperiencesByRuleId: function getExperiencesByRuleId(selectedRule) {
      var _this3 = this;
      var url = this.buildURL();
      return new Promise(function (resolve, reject) {
        _jquery.default.ajax({
          headers: _this3.headers,
          type: 'GET',
          url: "".concat(url, "/experiences?ruleIDs=").concat(selectedRule.id)
        }).done(function (response) {
          resolve(response);
        }).fail(function (jqXHR) {
          reject(jqXHR);
        });
      });
    }
  });
  _exports.default = _default;
});