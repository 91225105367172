define("experience-studio/helpers/moment-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.momentDuration = momentDuration;
  /*
  
    Usage:
  
    {{moment-duration value=60 unit='seconds'}}
  
    If you don't want to humanize seconds if number of seconds is less than 60
    {{moment-duration value=60 unit='seconds' humanizeSeconds=false}}
  
  
    If a value is an object,
  
    For example,
      anObject = { seconds: 60 }
      anObject = { minutes: 60 }
  
    {{moment-duration anObject}}
  
   */

  function momentDuration(params, hash) {
    var data = {};
    if (!Ember.isEmpty(params)) {
      data = params.objectAt(0);
    } else if (hash && hash.value && hash.unit) {
      data[hash.unit] = parseInt(hash.value);
    }
    if (hash && !Ember.isNone(hash.humanizeSeconds) && !hash.humanizeSeconds && hash.value < 60 && hash.unit.toLowerCase().indexOf('second') >= 0) {
      return "".concat(hash.value, " ").concat(hash.value > 1 ? 'seconds' : 'second');
    } else {
      return window.moment.duration(data).humanize();
    }
  }
  var _default = Ember.Helper.helper(momentDuration);
  _exports.default = _default;
});