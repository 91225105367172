define("experience-studio/components/model-list-experience-v2", ["exports", "experience-studio/components/model-list-v2"], function (_exports, _modelListV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListV.default.extend({
    classNames: ['model-list-experience'],
    modelName: 'experience',
    actions: {
      create: function create() {
        this.sendAction('dispatchAction', 'create', null, {
          type: 'experience'
        }); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});