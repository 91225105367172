define("experience-studio/mixins/model-localizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    description: Ember.computed('defaultLocalization', 'localizations', function () {
      var defaultLocalization = this.defaultLocalization || 'en';
      return this.get("localizations.".concat(defaultLocalization, ".description"));
    }),
    name: Ember.computed('defaultLocalization', 'localizations', function () {
      var defaultLocalization = this.defaultLocalization || 'en';
      return this.get("localizations.".concat(defaultLocalization, ".name"));
    })
  });
  _exports.default = _default;
});