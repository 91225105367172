define("experience-studio/components/v2/rule-select-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="form-group">
    <label class="audience-group-label">Location Trigger</label>
    <div class="audience-selector-group">
      <div class="rule-name-display">
        <p>
          {{svg-jar "map-marker"}}
          {{#if selectedRule}}
              {{selectedRule.name}}
          {{else}}
            Not Set
          {{/if}}
        </p>
      </div>
      {{#if isEditable}}
        <div class="rule-actions {{if selectedRule "rule-selected"}}">
          <button class="btn btn-link show-modal-button {{if selectedRule "rule-selected"}}" {{action "showModal"}}>
            Edit
          </button>
          {{#if selectedRule}}
            <button class="btn remove-rule" {{action "removeRule"}} title="Remove">
              {{svg-jar 'times-circle'}}
            </button>
          {{/if}}
        </div>
      {{/if}}
    </div>
  </div>
  
  {{#if isModalOpen}}
    {{v2/rule-select-modal-dialog-v2
        selectedRule=selectedRule
        onCancel=(action 'onCancelHandle')
        onConfirm=(action 'onConfirmHandle')}}
  {{/if}}
  
  */
  {
    "id": "wt2ZWP+7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"audience-group-label\"],[12],[2,\"Location Trigger\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"audience-selector-group\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"rule-name-display\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"map-marker\"],null]],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[35,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          Not Set\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"rule-actions \",[30,[36,4],[[35,1],\"rule-selected\"],null]]]],[12],[2,\"\\n        \"],[11,\"button\"],[16,0,[31,[\"btn btn-link show-modal-button \",[30,[36,4],[[35,1],\"rule-selected\"],null]]]],[4,[38,0],[[32,0],\"showModal\"],null],[12],[2,\"\\n          Edit\\n        \"],[13],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"button\"],[24,0,\"btn remove-rule\"],[24,\"title\",\"Remove\"],[4,[38,0],[[32,0],\"removeRule\"],null],[12],[2,\"\\n            \"],[1,[30,[36,3],[\"times-circle\"],null]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"selectedRule\",\"onCancel\",\"onConfirm\"],[[35,1],[30,[36,0],[[32,0],\"onCancelHandle\"],null],[30,[36,0],[[32,0],\"onConfirmHandle\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedRule\",\"v2/rule-select-modal-dialog-v2\",\"svg-jar\",\"if\",\"isEditable\",\"isModalOpen\"]}",
    "moduleName": "experience-studio/components/v2/rule-select-v2.hbs"
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['rule-select-v2'],
    selectedRule: null,
    isModalOpen: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.set('selectedRule', null);
    },
    actions: {
      showModal: function showModal() {
        this.set('isModalOpen', true);
      },
      removeRule: function removeRule() {
        this.set('selectedRule', null);
        this.get('selectRule')(null);
      },
      onConfirmHandle: function onConfirmHandle(rule) {
        this.set('isModalOpen', false);
        this.set('selectedRule', rule);
        this.get('selectRule')(rule);
      },
      onCancelHandle: function onCancelHandle() {
        this.set('isModalOpen', false);
      }
    }
  }));
  _exports.default = _default;
});