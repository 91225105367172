define("experience-studio/templates/components/route-model-filter-dropdown-checkbox-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b7JMx6It",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,1,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"custom-control custom-checkbox\"],[4,[38,3],[[32,0],\"makeSelection\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"custom-control-input\"],[15,\"checked\",[32,1,[\"isSelected\"]]],[15,\"disabled\",[34,1,[\"isDisabled\"]]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n    \"],[10,\"label\"],[14,0,\"custom-control-label\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"modal-error-message\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"dismissible\",\"type\"],[false,\"info\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert-icon\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"info-circle\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"alert-text\"],[12],[2,\"\\n      \"],[1,[35,1,[\"incompatibleMessage\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"section\",\"fb-bs-alert\",\"action\",\"-track-array\",\"each\",\"showIncompatibleMessage\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/route-model-filter-dropdown-checkbox-select.hbs"
  });
  _exports.default = _default;
});