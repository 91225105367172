define("experience-studio/components/model-list-item-experience", ["exports", "experience-studio/components/model-list-item"], function (_exports, _modelListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItem.default.extend({
    contentPriorityMap: Ember.computed('model.contents.[]', function () {
      var map = {};
      this.get('model.contents').forEach(function (content) {
        map[content.id] = content.index;
      });
      return map;
    }),
    groupsData: Ember.computed('model.computedGroups.[]', function () {
      var data = Ember.Object.create({});
      var groups = this.get('model.computedGroups');
      if (!Ember.isEmpty(groups)) {
        data.setProperties({
          group: groups.objectAt(0),
          groups: groups.filter(function (item, index) {
            return index !== 0;
          })
        });
      }
      return data;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var sliderElement = this.$('.slider').get(0);
      if (sliderElement && sliderElement.scrollWidth > sliderElement.offsetWidth) {
        this.$(sliderElement).find('.is-grid-layout').addClass('has-scroll');
      }
    },
    actions: {
      toggleProperty: function toggleProperty(property) {
        this.sendAction('customAction', Ember.Object.create({
          // eslint-disable-line ember/closure-actions
          action: 'toggleProperty',
          model: this.model,
          property: property
        }));
      }
    }
  });
  _exports.default = _default;
});