define("experience-studio/templates/components/content-tmp-edit-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I0JMRyWS",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"field\",\"setSelectedMediaType\",\"clearMediaURL\"],[[35,6],[30,[36,3],[[32,0],\"setSelectedMediaType\"],null],[30,[36,3],[[32,0],\"clearMediaURL\"],null]]]]],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"field\",\"languages\",\"mediaType\",\"setPreviewImgURL\",\"setVideoLabel\"],[[35,1],[35,4],[35,0],[30,[36,3],[[32,0],\"setPreviewImgURL\"],null],[30,[36,3],[[32,0],\"setVideoLabel\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"field\",\"mediaType\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectedMediaType\",\"resourceURLField\",\"content-tmp-edit-media-url\",\"action\",\"languages\",\"content-tmp-edit-media-localized-url\",\"mediaTypeField\",\"content-tmp-edit-media-type\",\"isLocalized\",\"if\"]}",
    "moduleName": "experience-studio/templates/components/content-tmp-edit-media.hbs"
  });
  _exports.default = _default;
});