define("experience-studio/serializers/rule", ["exports", "experience-studio/serializers/application", "experience-studio/utils/nearby-location"], function (_exports, _application, _nearbyLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    utility: Ember.inject.service(),
    convertValue: function convertValue(value, type) {
      switch (type) {
        case 'bool':
          return !!value;
        case 'float':
          return parseFloat(value);
        case 'int':
          return parseInt(value);
        case 'string':
          return "'".concat((value || '').trim(), "'");
        default:
          return value;
      }
    },
    getComparisonOperator: function getComparisonOperator(predicateName) {
      var operators = this.getComparisonOperators();
      var length = operators.length;
      var operator = '';
      for (var i = 0; i < length; i++) {
        var item = operators[i];
        if (!Ember.isEmpty(item.predicates.filterBy('name', predicateName))) {
          operator = item.operator;
          break;
        }
      }
      return operator;
    },
    getComparisonOperatorAsText: function getComparisonOperatorAsText(predicateName) {
      var operator = this.getComparisonOperator(predicateName);
      return {
        '=': predicateName === 'stringFoldContains' ? 'contains' : 'is equal to',
        '!=': 'is not equal to',
        '<': 'is less than',
        '<=': 'is less than or equal to',
        '>': 'is greater than',
        '>=': 'is greater than or equal to'
      }[operator];
    },
    getComparisonOperators: function getComparisonOperators() {
      return [Ember.Object.create({
        operator: '=',
        predicates: [Ember.Object.create({
          type: 'bool',
          name: 'boolEq'
        }), Ember.Object.create({
          type: 'float',
          name: 'eq'
        }), Ember.Object.create({
          type: 'int',
          name: 'eq'
        }), Ember.Object.create({
          type: 'string',
          name: 'stringEq'
        }), Ember.Object.create({
          type: 'string',
          name: 'stringFoldEq'
        }), Ember.Object.create({
          type: 'string',
          name: 'stringArrayIncludes'
        }), Ember.Object.create({
          type: 'string',
          name: 'stringFoldContains'
        })]
      }), Ember.Object.create({
        operator: '!=',
        predicates: [Ember.Object.create({
          type: 'bool',
          name: 'boolNeq'
        }), Ember.Object.create({
          type: 'float',
          name: 'neq'
        }), Ember.Object.create({
          type: 'int',
          name: 'neq'
        }), Ember.Object.create({
          type: 'string',
          name: 'stringNeq'
        }), Ember.Object.create({
          type: 'string',
          name: 'stringFoldNeq'
        })]
      }), Ember.Object.create({
        operator: '<',
        predicates: [Ember.Object.create({
          type: 'float',
          name: 'ls'
        }), Ember.Object.create({
          type: 'int',
          name: 'ls'
        })]
      }), Ember.Object.create({
        operator: '<=',
        predicates: [Ember.Object.create({
          type: 'float',
          name: 'lseq'
        }), Ember.Object.create({
          type: 'int',
          name: 'lseq'
        })]
      }), Ember.Object.create({
        operator: '>',
        predicates: [Ember.Object.create({
          type: 'float',
          name: 'gr'
        }), Ember.Object.create({
          type: 'int',
          name: 'gr'
        })]
      }), Ember.Object.create({
        operator: '>=',
        predicates: [Ember.Object.create({
          type: 'float',
          name: 'greq'
        }), Ember.Object.create({
          type: 'int',
          name: 'greq'
        })]
      })];
    },
    checkIfHasAreaPlugin: function checkIfHasAreaPlugin(literals) {
      var hasAreaPlugin = false;
      literals.forEach(function (item) {
        if (item.predicate && item.predicate.arguments) {
          item.predicate.arguments.forEach(function (argument) {
            if (argument.includes('.area')) {
              hasAreaPlugin = true;
            }
          });
        }
      });
      return hasAreaPlugin;
    },
    getComposition: function getComposition(data) {
      var body = data.rule.get('body');
      var composition = [];
      var group;
      if (body && !Ember.isEmpty(body.andBlocks)) {
        composition.pushObjects(this.getCompositionForBlocks(body.andBlocks, data, true));
      }
      if (body && !Ember.isEmpty(body.orBlocks)) {
        composition.pushObjects(this.getCompositionForBlocks(body.orBlocks, data, false));
      }
      if (body && !Ember.isEmpty(body.andLiterals)) {
        var hasAreaPlugin = this.checkIfHasAreaPlugin(body.andLiterals);
        group = Ember.Object.create({
          hasAreaPlugin: hasAreaPlugin,
          isAndSelected: true,
          isRoot: true,
          items: []
        });
        this.addCompositionLiteralsToGroup(body.andLiterals, group, data, true);
        composition.pushObject(group);
      }
      if (body && !Ember.isEmpty(body.orLiterals)) {
        var _hasAreaPlugin = this.checkIfHasAreaPlugin(body.orLiterals);
        group = Ember.Object.create({
          hasAreaPlugin: _hasAreaPlugin,
          isAndSelected: false,
          isRoot: true,
          items: []
        });
        this.addCompositionLiteralsToGroup(body.orLiterals, group, data, false);
        composition.pushObject(group);
      }
      return composition;
    },
    getCompositionForBlocks: function getCompositionForBlocks(blocks, data, isAndSelected) {
      var _this = this;
      var groups = [];
      blocks.forEach(function (block) {
        var group;
        if (!Ember.isEmpty(block.andLiterals)) {
          var hasAreaPlugin = _this.checkIfHasAreaPlugin(block.andLiterals);
          group = Ember.Object.create({
            hasAreaPlugin: hasAreaPlugin,
            isAndSelected: isAndSelected,
            isRoot: true,
            isSelected: false,
            items: []
          });
          _this.addCompositionLiteralsToGroup(block.andLiterals, group, data, true);
          groups.pushObject(group);
        }
        if (!Ember.isEmpty(block.orLiterals)) {
          var _hasAreaPlugin2 = _this.checkIfHasAreaPlugin(block.orLiterals);
          group = Ember.Object.create({
            hasAreaPlugin: _hasAreaPlugin2,
            isAndSelected: isAndSelected,
            isRoot: true,
            isSelected: false,
            items: []
          });
          _this.addCompositionLiteralsToGroup(block.orLiterals, group, data, false);
          groups.pushObject(group);
        }
        if (block && !Ember.isEmpty(block.andBlocks)) {
          groups.pushObjects(_this.getCompositionForBlocks(block.andBlocks, data, true));
        }
        if (block && !Ember.isEmpty(block.orBlocks)) {
          groups.pushObjects(_this.getCompositionForBlocks(block.orBlocks, data, false));
        }
      });
      return groups;
    },
    addCompositionLiteralsToGroup: function addCompositionLiteralsToGroup(literals, group, data, isAndSelected) {
      var self = this;
      literals.forEach(function (literal) {
        var item;
        try {
          item = self.getCompositionForLiteral(literal, isAndSelected, data);
        } catch (exception) {
          group.get('items').clear();
        }
        if (item) {
          item.set('group', group);
          group.get('items').pushObject(item);
        }
      });
    },
    getCompositionForLiteral: function getCompositionForLiteral(literal, isAndSelected, data) {
      var attributeName;
      var comparisonPredicate;
      var description;
      var expression;
      var isCompareAttribute;
      var isRule = false;
      var isHistorical = false;
      var parameters = [];
      var pluginOrRuleModel;
      var selectedAttribute;
      var uid;
      var uidTokens;
      var value;
      var historicalQueryRecord;
      if (!Ember.isEmpty(literal.predicate.arguments)) {
        uid = literal.predicate.arguments[0];
        value = literal.predicate.arguments[1];
      }
      if (uid && uid.indexOf('query') >= 0) {
        uidTokens = uid.split('.query.');
        var queryTokens = uidTokens.pop().split('.');
        attributeName = queryTokens.shift();
        if (!Ember.isEmpty(queryTokens) && uid.indexOf('ctxhistory') < 0) {
          parameters = queryTokens;
        }
      } else if (uid) {
        uidTokens = uid.split('.');
        attributeName = uidTokens.pop();
      }
      if (uid && uid.indexOf('ctx.flybits.ctxhistory') >= 0) {
        var recordId = uid.split('ctx.flybits.ctxhistory.query.result.')[1];
        historicalQueryRecord = this.store.peekRecord('context-historical-query', recordId);
        isHistorical = true;
      }
      if (!Ember.isEmpty(uidTokens)) {
        var pluginUid = uidTokens.join('.');
        if (historicalQueryRecord && historicalQueryRecord.id) {
          pluginUid = historicalQueryRecord.plugin.id;
          attributeName = historicalQueryRecord.plugin.attribute;
        }
        if (pluginUid.includes('.query')) {
          pluginUid = pluginUid.replace('.query', '');
        }
        pluginOrRuleModel = data.plugins.filterBy('uid', pluginUid).get('firstObject');
      }
      if (pluginOrRuleModel && attributeName) {
        selectedAttribute = pluginOrRuleModel.get("attributes.".concat(attributeName));
        if (selectedAttribute.get('valueType')) {
          value = value.replace(/'/g, '');
        }
      } else {
        isRule = true;
        if (data.rules && data.rules.length) {
          pluginOrRuleModel = data.rules.filterBy('head.name', literal.predicate.name).get('firstObject');
        }
      }
      if (!isRule) {
        this.setupAttribute(selectedAttribute);

        // const isBoolean = literal.predicate.name.toLowerCase().indexOf('bool') >= 0;

        // if (isBoolean && value === 'false') {
        //   literal.predicate.name = 'boolNeq';
        // }

        var mathOperator = {
          'add': '+',
          'div': '/',
          'mod': '&',
          'multiply': '*',
          'power': '^',
          'subtract': '-'
        }[literal.predicate.name];
        if (mathOperator) {
          description = "".concat(literal.predicate.arguments[0], " ").concat(mathOperator, " ").concat(literal.predicate.arguments[1]);
          expression = description;
          value = literal.predicate.arguments[2];
          selectedAttribute.set('isExpression', true);
        } else {
          var predicate = literal.predicate.name;
          if (historicalQueryRecord && historicalQueryRecord.id) {
            var where = historicalQueryRecord.where.objectAt(0);
            if (where.operator === '=') {
              if (historicalQueryRecord.function === 'sum') {
                predicate = 'has_eq_sum';
              } else {
                predicate = 'has_eq';
              }
            } else if (where.operator === '>') {
              if (historicalQueryRecord.function === 'sum') {
                predicate = 'has_gr_sum';
              } else {
                predicate = 'has_gr';
              }
            } else if (where.operator === '<') {
              if (historicalQueryRecord.function === 'sum') {
                predicate = 'has_ls_sum';
              } else {
                predicate = 'has_ls';
              }
            }
            if (historicalQueryRecord.plugin.attributeType === 'bool') {
              if (where.operator === '=') {
                predicate = 'has_boolEq';
              } else if (where.operator === '!=') {
                predicate = 'has_boolNeq';
              }
            }
            if (literal.isNegated) {
              predicate = predicate.replace('has_', 'has_not_');
            }
          }
          comparisonPredicate = selectedAttribute.comparisonPredicates.filterBy('value', predicate).get('firstObject');
          if (comparisonPredicate) {
            description = "".concat(selectedAttribute.get('name'), " ").concat(comparisonPredicate.get('label'));
          } else {
            description = selectedAttribute.get('name');
          }
        }
        if (typeof value === 'string' && value.indexOf('ctx.') >= 0) {
          isCompareAttribute = true;
        } else {
          isCompareAttribute = false;
        }
        if (selectedAttribute && !Ember.isEmpty(selectedAttribute.get('parameters')) && !Ember.isEmpty(parameters)) {
          var parametersTemp = [];
          selectedAttribute.get('parameters').forEach(function (parameter) {
            try {
              parameter.value = parameters.objectAt(parameter.index);
            } catch (exception) {
              // TODO: Handle exception
            }
            parameter = Ember.Object.create(parameter);
            parametersTemp.pushObject(Ember.Object.create(JSON.parse(JSON.stringify(parameter))));
          });
          parameters = parametersTemp;
        } else if (selectedAttribute && !Ember.isEmpty(selectedAttribute.get('parameters')) && isHistorical) {
          var _parametersTemp = [];
          selectedAttribute.get('parameters').forEach(function (parameter) {
            try {
              parameter.value = historicalQueryRecord.get("plugin.parameter".concat(parameter.index + 1));
            } catch (exception) {
              // TODO: Handle exception
            }
            parameter = Ember.Object.create(parameter);
            _parametersTemp.pushObject(Ember.Object.create(JSON.parse(JSON.stringify(parameter))));
          });
          parameters = _parametersTemp;
        }
        var descriptionValue = value;
        if (selectedAttribute.get('isTimeContext') && value) {
          descriptionValue = window.moment(parseInt(value) * 1000).format('h:mA MMM D, YYYY');
        }
        description = "".concat(description, " ").concat(descriptionValue);
      } else if (pluginOrRuleModel) {
        description = pluginOrRuleModel.get('name');
      }
      var labelsStr = [];
      var labels = [];
      var locations = [];
      var promises = [];
      if (uid && (uid.indexOf('ctx.flybits.area.query.inArea.') >= 0 || uid.indexOf('ctx.flybits.approximateArea.') >= 0)) {
        locations.pushObject(this.store.findRecord('location', uid.split('.').pop()));
      } else if (uid && (0, _nearbyLocation.isLabelLocation)(uid)) {
        // refer to nearby-location.js util
        // Supports both old and new stringRep using label location
        var label = uid.split('.').pop();
        labelsStr.pushObject(label);
        promises.pushObject(this.store.find('label', label));
        Ember.RSVP.allSettled(promises).then(function (responses) {
          responses.forEach(function (item) {
            if (item.state === 'fulfilled') {
              labels.pushObject(item.value);
            }
          });
        });
      } else if (uid && uid.indexOf('ctx.flybits.ctxhistory.query.result') >= 0) {
        var _label = historicalQueryRecord.plugin.parameter1;
        labelsStr.pushObject(_label);
        promises.pushObject(this.store.find('label', _label));
        Ember.RSVP.allSettled(promises).then(function (responses) {
          responses.forEach(function (item) {
            if (item.state === 'fulfilled') {
              labels.pushObject(item.value);
            }
          });
        });
      }
      var frequencyValue = 0;
      var frequencyOption = null;
      var yesNoOption = null;
      var timeRange = null;
      var recencyOption = '';
      var recencyValue = null;
      var recencyValueFrom = null;
      var recencyValueTo = null;
      var recencyInThePastValue = null;
      var recencyInThePastUnit = null;
      if (historicalQueryRecord) {
        frequencyValue = window.parseInt(value);
        value = historicalQueryRecord.where.objectAt(0).value;
        if (historicalQueryRecord.timeRange && !Ember.isEmpty(historicalQueryRecord.timeRange)) {
          timeRange = historicalQueryRecord.timeRange[0];
          if (timeRange.from > 0 && timeRange.to > 0 && timeRange.duration <= 0) {
            recencyOption = 'between';
            recencyValueFrom = window.unix(timeRange.from).toDate();
            recencyValueTo = window.moment.unix(timeRange.to).toDate();
          } else if (timeRange.from === 0 && timeRange.to === -1 && timeRange.duration > 0) {
            recencyOption = 'in the past';
            recencyInThePastUnit = 'minute(s)';
            if (timeRange.duration >= 86400) {
              recencyInThePastValue = timeRange.duration / 86400;
              recencyInThePastUnit = 'day(s)';
            } else if (timeRange.duration >= 3600) {
              recencyInThePastValue = timeRange.duration / 3600;
              recencyInThePastUnit = 'hour(s)';
            } else {
              recencyInThePastValue = timeRange.duration / 60;
              recencyInThePastUnit = 'minute(s)';
            }
          } else if (timeRange.from > 0 && timeRange.to <= -1 && timeRange.duration <= 0) {
            recencyOption = 'after';
            recencyValue = window.moment.unix(timeRange.from).toDate();
          } else if (timeRange.to > 0 && timeRange.from <= -1 && timeRange.duration <= 0) {
            recencyOption = 'before';
            recencyValue = window.moment.unix(timeRange.to).toDate();
          }
        }
        var tempPredicate = literal.predicate.name;
        frequencyOption = selectedAttribute.frequencyOptions.filterBy('value', tempPredicate).objectAt(0);
        if (selectedAttribute.valueType === 'bool') {
          yesNoOption = selectedAttribute.yesNoOptions.filterBy('value', "".concat(historicalQueryRecord.where.objectAt(0).value)).objectAt(0);
        }
      }
      var getAreaTabSelected = function getAreaTabSelected() {
        if (labelsStr.length === 0 && !Ember.isEmpty(locations)) {
          return 'list';
        } else if (labelsStr.length > 0 && isHistorical) {
          return 'historical';
        } else {
          return 'label';
        }
      };
      return Ember.Object.create({
        _rule: data.rule,
        comparisonPredicate: comparisonPredicate,
        description: description,
        expression: expression,
        expressionItems: [],
        group: null,
        isAndSelected: isAndSelected,
        isCompareAttribute: isCompareAttribute,
        isExpression: !Ember.isEmpty(expression),
        areaTabSelected: getAreaTabSelected(),
        isRule: isRule,
        isHasAreaPlugin: pluginOrRuleModel.uid === 'ctx.flybits.area' || pluginOrRuleModel.uid === 'ctx.flybits.approximateArea',
        isHistorical: isHistorical,
        isSelected: false,
        labels: labels,
        labelsStr: labelsStr,
        locations: locations,
        originalModel: pluginOrRuleModel,
        parameters: parameters,
        selectedAttribute: selectedAttribute,
        value: value,
        frequencyValue: frequencyValue,
        frequencyOption: frequencyOption,
        recencyOption: recencyOption,
        yesNoOption: yesNoOption,
        historicalQueryRecord: historicalQueryRecord,
        recencyValue: recencyValue,
        recencyValueFrom: recencyValueFrom,
        recencyValueTo: recencyValueTo,
        recencyInThePastValue: recencyInThePastValue,
        recencyInThePastUnit: recencyInThePastUnit
      });
    },
    getCompositionForItem: function getCompositionForItem(literal, logicalOperator) {
      var predicate = literal.predicate;
      var item = Ember.Object.create({
        comparisonOperator: this.getComparisonOperator(predicate.name),
        logicalOperator: logicalOperator,
        name: predicate.name,
        uid: predicate.arguments[0],
        value: predicate.arguments[1],
        valueType: this.getValueType(predicate.name, predicate.arguments[1])
      });
      if (item.valueType === 'string') {
        var value = item.get('value').replace(/^[']+/g, '').replace(/[']+$/g, '');
        item.set('value', value);
      }
      return item;
    },
    getMathOperatorsAsText: function getMathOperatorsAsText(predicateName) {
      return {
        'add': 'plus',
        'div': 'divided by',
        'mod': 'modulo',
        'multiply': 'multiplied by',
        'power': 'to the power',
        'subtract': 'minus'
      }[predicateName];
    },
    getPredicateName: function getPredicateName(valueType, comparisonOperator) {
      var name = '';
      var operator = this.getComparisonOperators().filterBy('operator', comparisonOperator).objectAt(0);
      if (operator) {
        var predicate = operator.predicates.filterBy('type', valueType).objectAt(0);
        if (predicate) {
          name = predicate.get('name');
        }
      }
      return name;
    },
    getStringRepresentation: function getStringRepresentation(model) {
      var format = 'head() :- (body)';
      var groups = model.composition;
      var groupStringRep = '';
      var groupsLength = groups.length;
      var result = '';
      for (var i = 0; i < groupsLength; i++) {
        var group = groups[i];
        group.set('_rule', model);
        groupStringRep += this.getStringRepresentationForGroup(group);
        if (i < groupsLength - 1) {
          var logicalOperator = 'Or';
          if (group.get('isAndSelected')) {
            logicalOperator = 'And';
          }
          groupStringRep += " ".concat(logicalOperator, " ");
        }
      }
      result = format.replace('head', this.sanitizeHeadName(model.name));
      result = result.replace('body', groupStringRep.trim());
      return result;
    },
    getUIDAsText: function getUIDAsText(uid) {
      var spacify = function spacify(str) {
        return Ember.String.classify(str).replace(/([A-Z])/g, ' $1').trim();
      };
      var text = '';
      var tokens = uid.split('.');
      text += "".concat(spacify(tokens[tokens.length - 1]), " ");
      text += "(".concat(spacify(tokens[tokens.length - 2]), ")");
      return text;
    },
    getValueType: function getValueType(predicateName, value) {
      var operators = this.getComparisonOperators();
      var length = operators.length;
      var valueType = '';
      for (var i = 0; i < length; i++) {
        var item = operators[i];
        var predicate = item.predicates.filterBy('name', predicateName).objectAt(0);
        if (predicate) {
          valueType = predicate.type;
          break;
        }
      }
      if (valueType === 'float' || valueType === 'int') {
        valueType = value.indexOf('.') >= 0 ? 'float' : 'int';
      }
      return valueType;
    },
    sanitizeHeadName: function sanitizeHeadName(name) {
      return name.replace(/[^\w]/gi, '-');
    },
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      data.name = data.name.trim();
      data.schedule = this.utility.transformToEmberObject(data.schedule);
      if (data.schedule && data.schedule.startScheduler && data.schedule.startScheduler.start && data.schedule.startScheduler.start > 99999999999) {
        if (data.schedule.startScheduler.timezoneUnixTime) {
          data.schedule.startScheduler.set('start', data.schedule.startScheduler.timezoneUnixTime);
          delete data.schedule.startScheduler.timezoneUnixTime;
        } else {
          data.schedule.startScheduler.set('start', Math.round(data.schedule.startScheduler.start / 1000));
        }
      }
      if (data.schedule && data.schedule.endScheduler && data.schedule.endScheduler.start && data.schedule.endScheduler.start > 99999999999) {
        data.schedule.endScheduler.set('start', Math.round(data.schedule.endScheduler.start / 1000));
      }

      // stringRepresentation
      // Draft rule can always override its previous stringRepresentation
      // Production rule is prohibited from overriding stringRepresentation after it's initialized
      var stringRep = this.getStringRepresentation(data);
      if (data.isDraft) {
        // draft rule
        if (data.composition) {
          data.stringRepresentation = stringRep;
        }
      } else {
        // production rule
        if (Ember.isEmpty(data.stringRepresentation)) {
          if (data.composition) {
            data.stringRepresentation = stringRep;
          }
        }
      }
      if (data.metadata) {
        try {
          data.metadata = JSON.stringify(data.metadata);
        } catch (ex) {
          data.metadata = null;
        }
      }
      delete data.body;
      delete data.composition;
      delete data.creatorId;
      if (data.isDraft) {
        data.exemptFromEvaluation = true;
        data.visibility = 'draft';
      }
      if (data.labels && data.labels.length) {
        data.tags = data.labels;
      }
      return data;
    },
    getFrequencyOptions: function getFrequencyOptions() {
      return [Ember.Object.create({
        label: 'at least',
        operator: '>=',
        value: 'greq'
      }), Ember.Object.create({
        label: 'at most',
        operator: '<=',
        value: 'lseq'
      }), Ember.Object.create({
        label: 'exactly',
        operator: '=',
        value: 'eq'
      }), Ember.Object.create({
        label: 'less than',
        operator: '<',
        value: 'ls'
      }), Ember.Object.create({
        label: 'more than',
        operator: '>',
        value: 'gr'
      })];
    },
    getRecencyOptions: function getRecencyOptions() {
      return ['anytime', 'after', 'before', 'between', 'in the past'];
    },
    setupAttribute: function setupAttribute(attribute) {
      attribute.setProperties({
        frequencyOptions: this.getFrequencyOptions(),
        recencyOptions: this.getRecencyOptions(),
        comparisonPredicates: this.getPredicates(attribute),
        isCaseSensitiveVisible: false,
        isSupported: false
      });
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          attribute.setProperties({
            isArrayType: true,
            isSupported: false
          });
          break;
        case '[]string':
          attribute.setProperties({
            isArrayType: true,
            isCaseSensitiveVisible: false,
            isSupported: true
          });
          break;
        case 'bool':
          attribute.setProperties({
            isSupported: true,
            isYesNoType: true
          });
          attribute.set('yesNoOptions', [Ember.Object.create({
            label: 'true',
            value: 'true'
          }), Ember.Object.create({
            label: 'false',
            value: 'false'
          })]);
          break;
        case 'float':
        case 'int':
          if (attribute.isTimeContext) {
            attribute.setProperties({
              isDateTimeType: true,
              isSupported: true
            });
          } else {
            attribute.setProperties({
              isNumberType: true,
              isSupported: true
            });
          }
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          attribute.setProperties({
            isHashMapType: true,
            isSupported: false
          });
          break;
        case 'string':
          attribute.setProperties({
            isCaseSensitiveVisible: true,
            isSupported: true,
            isTextType: true
          });
          break;
      }
    },
    getPredicates: function getPredicates(attribute) {
      var predicates = [];
      switch (attribute.get('valueType')) {
        case '[]bool':
        case '[]float':
        case '[]int':
          break;
        case '[]string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringArrayIncludes'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringArrayIncludes'
            }));
          } else {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringArrayIncludes'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringArrayIncludes'
            }));
          }
          break;
        case 'bool':
          predicates.pushObject(Ember.Object.create({
            label: 'Is',
            operator: '=',
            value: 'boolEq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is not',
            operator: '!=',
            value: 'boolNeq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has been',
            operator: '=',
            value: 'has_boolEq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been',
            operator: '=',
            value: 'has_not_boolEq'
          }));
          break;
        case 'float':
        case 'int':
          predicates.pushObject(Ember.Object.create({
            label: 'Is',
            operator: '=',
            value: 'eq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is not',
            operator: '!=',
            value: 'neq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is greater than',
            operator: '>',
            value: 'gr'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is greater than or equal to',
            operator: '>=',
            value: 'greq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is less than',
            operator: '<',
            value: 'ls'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Is less than or equal to',
            operator: '<=',
            value: 'lseq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has been',
            operator: '=',
            value: 'has_eq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been',
            operator: '=',
            value: 'has_not_eq'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has been greater than',
            operator: '>',
            value: 'has_gr'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been greater than',
            operator: '>',
            value: 'has_not_gr'
          }));

          // predicates.pushObject(EmberObject.create({
          //   label: 'Has been greater than or equal to', operator: '>=', value: 'has_greq',
          // }));

          predicates.pushObject(Ember.Object.create({
            label: 'Has been less than',
            operator: '<',
            value: 'has_ls'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Has not been less than',
            operator: '<',
            value: 'has_not_ls'
          }));

          // predicates.pushObject(EmberObject.create({
          //   label: 'Has been less than or equal to', operator: '<=', value: 'has_lseq',
          // }));

          // historical conditions for Sum
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that are equal to',
            operator: '=',
            value: 'has_eq_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that are not equal to',
            operator: '=',
            value: 'has_not_eq_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have been greater than',
            operator: '>',
            value: 'has_gr_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have not been greater than',
            operator: '>',
            value: 'has_not_gr_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have been less than',
            operator: '>',
            value: 'has_ls_sum'
          }));
          predicates.pushObject(Ember.Object.create({
            label: 'Sum of values that have not been less than',
            operator: '>',
            value: 'has_not_ls_sum'
          }));
          break;
        case 'map[string]bool':
        case 'map[string]float':
        case 'map[string]int':
        case 'map[string]string':
          break;
        case 'string':
          if (this.get('model.isCaseSensitive')) {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringEq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Is not',
              operator: '!=',
              value: 'stringNeq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Contains',
              operator: '=',
              value: 'stringContains'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringEq'
            }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has not been', operator: '!=', value: 'has_stringNeq',
            // }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has contained', operator: '=', value: 'has_stringContains',
            // }));
          } else {
            predicates.pushObject(Ember.Object.create({
              label: 'Is',
              operator: '=',
              value: 'stringFoldEq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Is not',
              operator: '!=',
              value: 'stringFoldNeq'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Contains',
              operator: '=',
              value: 'stringFoldContains'
            }));
            predicates.pushObject(Ember.Object.create({
              label: 'Has been',
              operator: '=',
              value: 'has_stringFoldEq'
            }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has not been', operator: '!=', value: 'has_stringFoldNeq',
            // }));

            // predicates.pushObject(EmberObject.create({
            //   label: 'Has contained', operator: '=', value: 'has_stringFoldContains',
            // }));
          }
      }

      return predicates;
    },
    getStringRepresentationForGroup: function getStringRepresentationForGroup(group) {
      var items = group.items;
      var length = items.length;
      var results = [];
      var logicalOperator = 'Or';
      var groupCounter = 0;
      for (var i = 0; i < length; i++) {
        var item = items.objectAt(i);
        item.set('_rule', group.get('_rule'));
        results.pushObject(this.getStringRepresentationForItem(item, groupCounter++));
      }
      if (length && items.objectAt(0).get('isAndSelected')) {
        logicalOperator = 'And';
      }
      if (results.length > 1) {
        return "(".concat(results.join(" ".concat(logicalOperator, " ")), ")");
      } else {
        return results.join();
      }
    },
    getStringRepresentationForItem: function getStringRepresentationForItem(item, groupCounter) {
      var pluginFormat = 'predicateName(___xuidx___,___xvaluex___)';
      var parameters = item.get('parameters');
      var ruleFormat = 'head()';
      var treeResults = [];
      var predicateName = item.get('comparisonPredicate.value');
      var result = '';
      var uid = item.get('selectedAttribute.uid');
      var value = null;
      var variableName = '';
      if (item.isHasAreaPlugin) {
        var stringReps = [];
        var stringRepsOperator = null;
        if (item.areaTabSelected === 'list' && !Ember.isEmpty(item.locations)) {
          stringRepsOperator = ' Or ';
          var areaStringRep = 'boolEq(ctx.flybits.area.query.inArea';
          if (item.originalModel.uid === 'ctx.flybits.approximateArea') {
            areaStringRep = 'boolEq(ctx.flybits.approximateArea.query.inApproximateArea';
          }
          stringReps = item.locations.map(function (item) {
            return "".concat(areaStringRep, ".").concat(item.get('id'), ",true)");
          });
        } else if (item.areaTabSelected === 'label' && !Ember.isEmpty(item.labels)) {
          stringRepsOperator = ' And ';
          stringReps = item.labels.map(function (item) {
            // ChangeLog: 2021-05-26
            // label based location rules
            // old) boolEq(ctx.flybits.area.query.inLocationWithLabel.label2,true)
            // new) stringFoldEq(ctx.flybits.area.query.inAreaWithLabel.eventType.atm,'entry')
            return "stringFoldEq(ctx.flybits.area.query.inAreaWithLabel.eventType.".concat(item.value, ",'entry')");
          });
        } else if (item.areaTabSelected === 'historical' && !Ember.isEmpty(item.labels)) {
          return "".concat(item.frequencyOption.value, "(ctx.flybits.ctxhistory.query.result.").concat(item.historicalQueryRecord.id, ",").concat(parseInt(item.frequencyValue), ")");
        }
        if (stringReps.length > 1) {
          return "(".concat(stringReps.join(stringRepsOperator), ")");
        } else {
          return stringReps.join();
        }
      }
      if (item.isHistorical && predicateName.indexOf('has_not') >= 0) {
        return "~".concat(item.frequencyOption.value, "(ctx.flybits.ctxhistory.query.result.").concat(item.historicalQueryRecord.id, ",").concat(parseInt(item.frequencyValue), ")");
      } else if (item.isHistorical) {
        return "".concat(item.frequencyOption.value, "(ctx.flybits.ctxhistory.query.result.").concat(item.historicalQueryRecord.id, ",").concat(parseInt(item.frequencyValue), ")");
      }
      if (!Ember.isEmpty(parameters) && uid !== 'ctx.flybits.area.query.inArea') {
        uid += ".".concat(parameters.map(function (parameter) {
          return (parameter.value || '').trim();
        }).join('.'));
      } else if (!Ember.isEmpty(parameters) && uid === 'ctx.flybits.area.query.inArea' && item.selectedAttribute && item.selectedAttribute.location) {
        uid += ".".concat(item.selectedAttribute.location.id);
      }
      if (item.get('selectedAttribute.performsExtraProcessing')) {
        var hasData = false;
        var itemPluginData = item.get('pluginData');
        var modelPluginData = null;
        if (item._rule.pluginData) {
          modelPluginData = item._rule.pluginData;
        }
        if (modelPluginData) {
          for (var property in modelPluginData) {
            if (modelPluginData.hasOwnProperty(property) && modelPluginData[property] === itemPluginData) {
              hasData = true;
            } else if (!itemPluginData && property.indexOf('_') >= 0) {
              var modelPluginDataUID = property.split('_');
              modelPluginDataUID.pop();
              modelPluginDataUID = modelPluginDataUID.join('.');
              if (uid === modelPluginDataUID) {
                itemPluginData = modelPluginData[property];
              }
            }
          }
        }
        if (!hasData && itemPluginData) {
          uid = "".concat(uid, ".").concat(this.utility.getGUID());
          item._rule.pluginData = {};
          item._rule.pluginData[uid] = itemPluginData;
        }
      }
      if (predicateName === 'boolEq') {
        if (typeof item.value === 'string' && item.value === 'true') {
          item.set('value', true);
        } else if (typeof item.value === 'string' && item.value === 'false') {
          item.set('value', false);
        } else if (typeof item.value === 'boolean') {
          item.set('value', item.value);
        } else {
          item.set('value', true);
        }
      } else if (predicateName === 'boolNeq') {
        predicateName = 'boolEq';
        var _value = item.get('value');
        if (_value === "false") {
          item.set('value', true);
        } else {
          item.set('value', false);
        }
      }
      if (item.get('attributeToCompare')) {
        value = item.get('attributeToCompare.value');
        if (!Ember.isEmpty(parameters)) {
          value += ".".concat(parameters.map(function (parameter) {
            return (parameter.value || '').trim();
          }).join('.'));
        }
      } else if (item.get('value') && typeof item.get('value') === 'string' && item.get('value').indexOf('ctx.') >= 0) {
        value = item.get('value');
      } else if (item.get('selectedAttribute.isTimeContext')) {
        value = Math.floor(item.get('value'));
      } else {
        value = this.convertValue(item.get('value'), item.get('selectedAttribute.valueType'));
      }
      if (!Ember.isEmpty(item.get('expression'))) {
        var _getMemberExpressionString = null;
        var _traverse = null;

        //JSEP library has issues parsing conditions like '.5', a dot preceding a number
        //it tries to compute this and crashes, use regex to replace the exp
        var _expression = item.get('expression');
        var _sanitizedString = '';
        _expression.split('').map(function (str, i) {
          _sanitizedString += str;
          if (str === '.' && !isNaN(_expression[i + 1])) {
            _sanitizedString += str.replace('.', '$');
          }
        });
        var tree = window.jsep(_sanitizedString);
        _getMemberExpressionString = function getMemberExpressionString(node) {
          if (node.object) {
            return "".concat(_getMemberExpressionString(node.object), ".").concat(node.property.name.replace(/–/g, '-'));
          } else {
            return node.name;
          }
        };
        var variableCounter = 0;
        var traverseCounter = 0;
        _traverse = function traverse(node, traverseCounterValue) {
          var leftValue;
          var rightValue;
          var variableName;
          if (node.left) {
            traverseCounter++;
            leftValue = _traverse(node.left, traverseCounter);
          }
          if (node.right) {
            traverseCounter++;
            rightValue = _traverse(node.right, traverseCounter);
          }
          if (node.type === 'BinaryExpression') {
            variableCounter++;
            variableName = "G".concat(groupCounter, "_VAR").concat(variableCounter, "_T").concat(traverseCounterValue);
            if (node.operator && node.operator === '^') {
              treeResults.push("power(".concat(leftValue, ",").concat(rightValue, ",").concat(variableName, ")"));
            } else if (node.operator && node.operator === '/') {
              treeResults.push("div(".concat(leftValue, ",").concat(rightValue, ",").concat(variableName, ")"));
            } else if (node.operator && node.operator === '*') {
              treeResults.push("multiply(".concat(leftValue, ",").concat(rightValue, ",").concat(variableName, ")"));
            } else if (node.operator && node.operator === '+') {
              treeResults.push("add(".concat(leftValue, ",").concat(rightValue, ",").concat(variableName, ")"));
            } else if (node.operator && node.operator === '-') {
              treeResults.push("subtract(".concat(leftValue, ",").concat(rightValue, ",").concat(variableName, ")"));
            } else if (node.operator && node.operator === '%') {
              treeResults.push("mod(".concat(leftValue, ",").concat(rightValue, ",").concat(variableName, ")"));
            }
            return variableName;
          } else if (node.type === 'MemberExpression') {
            return _getMemberExpressionString(node);
          } else {
            return node.value;
          }
        };
        variableName = _traverse(tree, 0);
      }
      if (item.get('originalModel').constructor.modelName === 'plugin') {
        result = pluginFormat.replace('predicateName', predicateName);
        if (!Ember.isEmpty(treeResults)) {
          result = result.replace('___xuidx___', variableName);
          result = result.replace('___xvaluex___', value);
          result = "".concat(treeResults.join(' And '), " And ").concat(result);
        } else {
          //the parser misinterprets single ctx.query as a eq and
          //deletes uid for first historical query, so we set the uid, from item object.
          if (uid === 'ctx.flybits.ctxhistory.query.result') {
            result = result.replace('___xuidx___', item.get('expression'));
          } else {
            result = result.replace('___xuidx___', uid);
          }
          result = result.replace('___xvaluex___', value);
        }
      } else {
        result = ruleFormat.replace('head', item.get('originalModel.head.name'));
      }

      //if resultant string contains '$' that means the value
      // was previously replaced by regex on line 1290, replace with back with period (.)
      if (result.includes('.$')) {
        result = result.replaceAll('.$', '.');
      }
      return result.trim();
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      if (item.attributes.schedule) {
        item.attributes.schedule = this.utility.transformToEmberObject(item.attributes.schedule);
      }
      item.attributes.body = this.utility.transformToEmberObject(item.attributes.body);
      item.attributes.composition = [];
      item.attributes.creatorId = item.attributes.creator;
      item.attributes.head = Ember.Object.create(item.attributes.head);
      var tempStringRep = item.attributes.stringRepresentation.split(':-')[1];
      if (tempStringRep && (tempStringRep.includes('add(') || tempStringRep.includes('div(') || tempStringRep.includes('mod(') || tempStringRep.includes('multiply(') || tempStringRep.includes('power(') || tempStringRep.includes('subtract('))) {
        item.attributes.body.set('hasComplexHistoricalPreview', true);
      }
      if (Ember.isNone(item.attributes.labels)) {
        item.attributes.labels = [];
      }
      if (!Ember.isEmpty(item.attributes.tags)) {
        item.attributes.labels = item.attributes.tags;
      }
      if (item.attributes.schedule && item.attributes.schedule.startScheduler && item.attributes.schedule.startScheduler.start && item.attributes.schedule.startScheduler.start > 0) {
        if (item.attributes.schedule && item.attributes.schedule.startScheduler && item.attributes.schedule.startScheduler.timezone) {
          var localZone = window.moment.tz.guess();
          var localZoneOffset = window.moment.tz(localZone).format('Z');
          var timeDiff = 0;
          if (item.attributes.schedule.startScheduler.timezone.offset) {
            timeDiff = (parseInt(item.attributes.schedule.startScheduler.timezone.offset) - parseInt(localZoneOffset)) * 3600;
          }
          var zoneTime = item.attributes.schedule.startScheduler.start + timeDiff;
          if (parseInt(timeDiff) < 0) {
            zoneTime = item.attributes.schedule.startScheduler.start - Math.abs(timeDiff);
          }
          item.attributes.schedule.startScheduler.start = zoneTime * 1000; // eslint-disable-line max-len
        } else {
          item.attributes.schedule.startScheduler.start = item.attributes.schedule.startScheduler.start * 1000; // eslint-disable-line max-len
        }
      }

      if (item.attributes.schedule && item.attributes.schedule.endScheduler && item.attributes.schedule.endScheduler.start && item.attributes.schedule.endScheduler.start > 0) {
        item.attributes.schedule.endScheduler.start = item.attributes.schedule.endScheduler.start * 1000; // eslint-disable-line max-len
      }

      try {
        item.attributes.metadata = this.utility.transformToEmberObject(item.attributes.metadata);
      } catch (ex) {
        item.attributes.metadata = null;
      }
      return item;
    }
  });
  _exports.default = _default;
});