define("experience-studio/components/custom-field--float", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    didInsertElement: function didInsertElement() {
      this.set('value', this.get('field.value'));
      this._super.apply(this, arguments);
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    triggerValidation: function triggerValidation() {
      if (Ember.isEmpty(this.field.value) && this.field.isRequired) {
        this.set('isDirty', true);
        this.send('onValue', '');
      }
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var field = this.field;
      var input = String(value);
      field.set('errorMessages', []);
      if (input.match(/.*[a-zA-Z].*/)) {
        field.errorMessages.pushObject('Cannot contain alpha characters');
        isValid = false;
      }
      if (input.match(/[^a-zA-Z0-9.\-\d\s:]/)) {
        field.errorMessages.pushObject('Cannot contain special characters');
        isValid = false;
      }
      if (input.match(/.*[\s].*/)) {
        field.errorMessages.pushObject('Cannot contain spaces');
        isValid = false;
      }
      if ((input.match(/\./g) || []).length > 1) {
        field.errorMessages.pushObject('Cannot contain more than one decimal point');
        isValid = false;
      }
      if (input.indexOf('-') > 0) {
        field.errorMessages.pushObject('Cannot contain negative between or at the end of number');
        isValid = false;
      }
      if (input.length > 1 && input.endsWith('.') && !((input.match(/\./g) || []).length > 1)) {
        field.errorMessages.pushObject('Cannot contain a trailing decimal point');
        isValid = false;
      }
      if ((input.match(/\-/g) || []).length > 1) {
        // eslint-disable-line
        field.errorMessages.pushObject('Cannot contain more than one negative');
        isValid = false;
      }
      if (input.length > 0 && isValid && isNaN(value) && input.indexOf('0') === -1) {
        field.errorMessages.pushObject('Invalid decimal number');
        isValid = false;
      }
      this._super(value, isValid);
    },
    actions: {
      onValue: function onValue(value) {
        var input = value;
        if (typeof value === 'string') {
          value = Number(value) || undefined;
        }
        this.set('field.value', value);
        this.set('field.input', input);
        this.setDirty(input);
        this.setValidity(input);
        this.sendAction('onValue', value); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});