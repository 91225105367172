define("experience-studio/components/route-model-table-dropdown-rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userPreferences: Ember.inject.service(),
    isViewAnalyticsVisible: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getValue('analyticsPageV2');
    }),
    isEditVisible: Ember.computed('model', function () {
      return !this.model.get('body.hasComplexHistoricalPreview');
    }),
    actions: {
      sendPerformModelAction: function sendPerformModelAction(actionName) {
        this.performModelAction({
          action: actionName,
          record: this.model
        });
      },
      edit: function edit() {
        this.performModelAction({
          action: 'editRecord',
          record: this.model.id,
          route: 'context-hub.rule.edit'
        }); // action in base mixin
      }
    }
  });
  _exports.default = _default;
});