define("experience-studio/components/label-select-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['label-select-dropdown'],
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    searchKeyword: '',
    errorMessage: '',
    open: false,
    currentIndex: 0,
    isLoading: false,
    selectedLabels: null,
    labelList: null,
    isAddLabelDisabled: false,
    // for autogenlabels, should we stop the user from entering prefixed labels
    autoGeneratedLabelsUpdated: Ember.observer('autoGeneratedLabels.[]', function () {
      Ember.run.debounce(this, this.handleAutoGeneratedLabels, 500);
    }),
    handleAutoGeneratedLabels: function handleAutoGeneratedLabels() {
      for (var i = 0; i < this.get('autoGeneratedLabels').length; i++) {
        this.performAutoGeneratedLabelSearch(this.get('autoGeneratedLabels')[i]);
      }
    },
    clearAutoGeneratedLabels: function clearAutoGeneratedLabels() {
      var self = this;
      var labelsToDelete = this.get('selectedLabels').filter(function (label) {
        return label.get('isAutoGenerated') && !self.get('autoGeneratedLabels').includes(label.get('value'));
      });
      labelsToDelete.forEach(function (label) {
        self.selectedLabels.removeObject(label);
      });
    },
    initLabel: function initLabel(value) {
      return Ember.Object.create({
        isCreated: true,
        isAutoGenerated: false,
        value: value,
        name: value
      });
    },
    dropdownWidthStyle: Ember.computed('dropdownWidth', function () {
      return Ember.String.htmlSafe("width:".concat(this.dropdownWidth, "px"));
    }),
    keyDown: function keyDown(event) {
      var isTopArrow = event.keyCode === 38;
      var isBottomArrow = event.keyCode === 40;
      var isSpaceBar = event.keyCode === 32;
      var indexAfterStart = this.currentIndex > 0;
      var indexBeforeEnd = this.currentIndex < this.labelList.length - 1;
      if (isTopArrow || isBottomArrow) {
        event.preventDefault();
        if (isTopArrow && indexAfterStart) {
          this.set('currentIndex', this.currentIndex - 1);
        } else if (isBottomArrow && indexBeforeEnd) {
          this.set('currentIndex', this.currentIndex + 1);
        }
      }
      if (isSpaceBar) {
        event.preventDefault();
      }
    },
    formatAutoGeneratedLabel: function formatAutoGeneratedLabel(label) {
      // TODO: Remove this function
      return label;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var labelSelectNode = this.$('.label-select')[0];
      this.setProperties({
        selectedLabels: this.selectedLabels || [],
        labels: this.labels || [],
        labelList: this.labelList || [],
        labelSelectNode: labelSelectNode,
        dropdownWidth: labelSelectNode.offsetWidth,
        menuClass: "dropdown-menu-".concat(this.elementId)
      });
      this.labels.forEach(function (label) {
        if (_this.selectedLabels.filterBy('value', label).length <= 0) {
          _this.selectedLabels.pushObject(Ember.Object.create({
            isCreated: false,
            value: label,
            name: label,
            isAutoGenerated: false
          }));
        }
      });
      (0, _jquery.default)(window).on("resize.".concat(this.elementId), function () {
        Ember.run(function () {
          _this.set('dropdownWidth', labelSelectNode.offsetWidth);
          _this.clearMenu();
        });
      });
      (0, _jquery.default)(document).on("click.".concat(this.elementId), function (event) {
        Ember.run(function () {
          _this.send('onClickOutside', event);
        });
      });
    },
    checkLabelPrefix: function checkLabelPrefix(prefix) {
      var prefixMap = new Map([['region', 'location'], ['state', 'location'], ['zip', 'location'], ['country', 'location']]);
      return prefixMap.get(prefix);
    },
    performAutoGeneratedLabelSearch: function performAutoGeneratedLabelSearch(query) {
      var _this2 = this;
      var self = this;
      if (this.selectedLabels.mapBy('value').includes(query)) {
        return;
      }
      this.set('isLoading', true);
      this.clearAutoGeneratedLabels();
      var defferedLabels = Ember.RSVP.defer();
      this.sendAction('fetchLabels', defferedLabels, query); // eslint-disable-line ember/closure-actions

      defferedLabels.promise.then(function (result) {
        var label = result.toArray().filter(function (res) {
          return res.get('value') === query;
        });
        if (Ember.isEmpty(label)) {
          label = self.initLabel(query);
          self.selectedLabels.pushObject(label);
        } else {
          label = label[0];
          label.set('isCreated', false);
          self.selectedLabels.pushObject(label);
        }
        self.formatAutoGeneratedLabel(label);
        _this2.set('isLoading', false);
      }).catch(function (jqXHR) {
        self.setProperties({
          isLoading: false,
          labelList: [],
          autoGeneratedLabels: [],
          errorMessage: self.utility.parseError(jqXHR)
        });
        self.clearCurrentIndex();
      });
    },
    performLabelSearch: function performLabelSearch(query, resolve, reject) {
      var self = this;
      var defferedLabels = Ember.RSVP.defer();
      var selectedLabels = this.selectedLabels;
      this.sendAction('fetchLabels', defferedLabels, query); // eslint-disable-line ember/closure-actions

      defferedLabels.promise.then(function (result) {
        var labels = [];
        // If a results don't contain query, offer label creation
        if (selectedLabels.mapBy('value').includes(query)) {
          labels.pushObject(Ember.Object.create({
            isTakenLabel: true,
            // indicates creation list item
            value: query,
            name: "\u201C".concat(query, "\u201D has already been added to this item.")
          }));
        } else if (!result.mapBy('value').includes(query)) {
          labels.pushObject(Ember.Object.create({
            isCreateLabel: true,
            // indicates creation list item
            value: query,
            name: "Add new label \"".concat(query, "\"")
          }));
        }
        labels = labels.concat(result.toArray()
        // Sort so that equivilent value comes first
        .sort(function (res) {
          return res.value === query ? -1 : 1;
        })
        // Filter out values that are already selected
        .filter(function (res) {
          return !selectedLabels.mapBy('value').includes(res.value);
        }));
        labels.forEach(function (res) {
          self.formatAutoGeneratedLabel(res);
        });
        resolve(labels);
      }).catch(function (jqXHR) {
        reject(jqXHR);
      });
    },
    openMenu: function openMenu() {
      this.set('open', true);
    },
    closeMenu: function closeMenu() {
      this.set('open', false);
    },
    clearCurrentIndex: function clearCurrentIndex() {
      if (this.labelList.length - 1 < this.currentIndex) {
        this.set('currentIndex', 0);
      }
    },
    clearMenu: function clearMenu() {
      this.closeMenu();
      this.setProperties({
        currentIndex: 0,
        searchKeyword: '',
        labelList: [],
        isLoading: false,
        errorMessage: ''
      });
    },
    actions: {
      labelAdd: function labelAdd() {
        this.send('labelSelect', this.labelList[this.currentIndex]);
      },
      keyboardDeselect: function keyboardDeselect(label) {
        if (event.key === 'Enter') {
          this.send('labelDeselect', label);
        }
      },
      labelDeselect: function labelDeselect(label) {
        if (label) {
          this.selectedLabels.removeObject(label);
          if (!Ember.isEmpty(this.autoGeneratedLabels)) {
            this.autoGeneratedLabels.removeObject(label.value);
          }
          this.sendAction('didUpdateLabel', label, 'remove'); // eslint-disable-line ember/closure-actions
        }
      },
      labelSearch: function labelSearch(searchKeyword, event) {
        var _this3 = this;
        var self = this;
        this.set('errorMessage', '');
        if (!Ember.isEmpty(searchKeyword) && event.keyCode !== 13) {
          this.openMenu();
          this.set('isLoading', true);
          return new Ember.RSVP.Promise(function (resolve, reject) {
            Ember.run.debounce(self, self.performLabelSearch, searchKeyword, resolve, reject, 600);
          }).then(function (results) {
            if (Ember.isEmpty(_this3.errorMessage)) {
              _this3.setProperties({
                isLoading: false,
                labelList: results
              });
            } else {
              _this3.setProperties({
                isLoading: false,
                labelList: []
              });
            }
            _this3.clearCurrentIndex();
          }).catch(function (response) {
            _this3.setProperties({
              isLoading: false,
              labelList: [],
              errorMessage: _this3.utility.parseError(response)
            });
            _this3.clearCurrentIndex();
          });
        } else {
          this.clearMenu();
        }
      },
      labelSelect: function labelSelect(label) {
        var isValid = Ember.isEmpty(this.validateInput(this.searchKeyword));
        var isSearchKeyword = !Ember.isEmpty(this.searchKeyword);
        if (isSearchKeyword && isValid) {
          var isLoading = this.isLoading;
          var isSelected = this.selectedLabels.mapBy('value').includes(label.value);
          if (!isLoading && !isSelected) {
            if (label.get('isCreateLabel')) {
              var newLabel = this.initLabel(label.value);
              this.formatAutoGeneratedLabel(newLabel);
              this.selectedLabels.unshiftObject(newLabel);
            } else {
              label.set('isCreated', Ember.isEmpty(this.labelList.findBy('name', label.name)));
              this.selectedLabels.unshiftObject(label);
            }
            this.sendAction('didUpdateLabel', label, 'add'); // eslint-disable-line ember/closure-actions
          }

          this.clearMenu();
        }
      },
      onClickOutside: function onClickOutside(event) {
        var outsideButton = !(0, _jquery.default)(event.target).closest(this.$()).length;
        var outsideMenu = !(0, _jquery.default)(event.target).closest((0, _jquery.default)(".".concat(this.menuClass))[0]).length;
        if (outsideButton && outsideMenu) {
          this.clearMenu();
        }
      },
      onInput: function onInput(searchKeyword, event) {
        var validationError = this.validateInput(searchKeyword);
        if (validationError) {
          this.setProperties({
            isLoading: false,
            labelList: [],
            errorMessage: validationError
          });
          this.openMenu();
        } else {
          this.send('labelSearch', searchKeyword, event);
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(window).off("resize.".concat(this.elementId));
      (0, _jquery.default)(document).off("click.".concat(this.elementId));
    },
    validateInput: function validateInput(searchKeyword) {
      var isAlphaNumericHyphen = searchKeyword.match(/^[a-z0-9-]+$/i) !== null;
      var isLongerThan100Chars = searchKeyword.length > 100;
      if (searchKeyword.length > 0) {
        if (isLongerThan100Chars) {
          return 'Labels cannot contain more than 100 characters';
        } else if (!isAlphaNumericHyphen) {
          return 'Labels cannot contain any special characters other than the dash (-)';
        }
      }
    }
  });
  _exports.default = _default;
});