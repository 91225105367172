define("experience-studio/templates/components/push-message-schedule-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xP5mvKUi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[[34,6],[30,[36,5],[[30,[36,4],[[35,3],[35,2,[\"activeEndSchedule\"]]],null],\" has-end-schedule\"],null]]]],[14,\"data-html\",\"true\"],[15,\"title\",[31,[[34,7]]]],[14,\"data-toggle\",\"tooltip\"],[12],[2,\"\\n  \"],[6,[37,5],[[30,[36,4],[[35,7],[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"schedule\"],null]],[2,\" \"],[1,[34,1]]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[34,8]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"scheduleDateText\",\"model\",\"end\",\"and\",\"if\",\"statusClassName\",\"scheduleTooltipText\",\"noDateDash\",\"not\"]}",
    "moduleName": "experience-studio/templates/components/push-message-schedule-icon.hbs"
  });
  _exports.default = _default;
});