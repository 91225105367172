define("experience-studio/components/model-check-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-check-list'],
    isPreselect: false,
    isSetToSelection: false,
    searchKeyword: '',
    model: null,
    selectedItems: null,
    dependentModels: null,
    searchPlaceholder: 'Search',
    modelSham: Ember.computed('model.[]', 'dependentModels.[]', 'searchKeyword', function () {
      var _this = this;
      var searchKeyword = this.searchKeyword;
      var model = [];
      if (!Ember.isEmpty(this.model)) {
        model = this.model;
      }
      if (searchKeyword) {
        model = model.filter(function (item) {
          return item.get('name').toLowerCase().indexOf(searchKeyword) >= 0;
        });
      }
      if (this.validationFunction) {
        model.forEach(function (item) {
          var validationWarning = _this.validationFunction(item, _this.dependentModels);
          item.setProperties({
            checkListValidationWarning: validationWarning,
            isCheckListDisabled: !Ember.isEmpty(validationWarning)
          });
        });
      }
      return model;
    }),
    showPlaceHolder: Ember.computed('model.@each.checkListValidationWarning', function () {
      if (this.model.filter(function (item) {
        return item.checkListValidationWarning;
      }).length >= 1) {
        return 'placeholder';
      }
    }),
    didModelChange: Ember.observer('model.@each.checkListValidationWarning', function () {
      var _this2 = this;
      Ember.run.scheduleOnce('afterRender', function () {
        var titleElements = (0, _jquery.default)(_this2.element).find('[data-toggle="tooltip"]');
        titleElements.tooltip('dispose');
        titleElements.tooltip();
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedItems', []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.setupSelection();
    },
    willDestroyElement: function willDestroyElement() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
      this.deselectItems();
    },
    actions: {
      toggleSelect: function toggleSelect(item) {
        if (!item.isCheckListDisabled) {
          item.toggleProperty('isCheckListSelected');
          this.set('selectedItems', this.model.filterBy('isCheckListSelected'));
          if (this.onSelect) {
            this.onSelect(this.selectedItems);
          }
        }
      },
      onSearch: function onSearch(searchKeyword) {
        this.set('searchKeyword', searchKeyword);
      }
    },
    setupSelection: function setupSelection() {
      if (this.isPreselect) {
        this.modelSham.setEach('isCheckListSelected', true);
      } else if (this.isSetToSelection) {
        this.modelSham.setEach('isCheckListSelected', this.modelSham.isSelected);
      } else {
        this.modelSham.setEach('isCheckListSelected', false);
      }
      this.modelSham.forEach(function (item) {
        if (item.isCheckListDisabled) {
          item.set('isCheckListSelected', false);
        }
      });
      this.set('selectedItems', this.modelSham.filterBy('isCheckListSelected'));
      if (this.onSelect) {
        this.onSelect(this.selectedItems);
      }
    },
    deselectItems: function deselectItems() {
      this.model.forEach(function (item) {
        item.setProperties({
          isCheckListSelected: null,
          checkListValidationWarning: null,
          isCheckListDisabled: null
        });
      });
    },
    uninitializeTooltips: function uninitializeTooltips() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
    }
  });
  _exports.default = _default;
});