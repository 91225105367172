define("experience-studio/services/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    greaterThan: function greaterThan(value, target) {
      if (isNaN(value)) return;
      if (value === null) return;
      if (value === undefined) return;
      return parseInt(value) > target;
    }
  });
  _exports.default = _default;
});