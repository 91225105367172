define("experience-studio/components/model-display-table", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['model-display-table'],
    headers: null,
    isPreselect: false,
    isSetToSelection: false,
    hasSearch: false,
    searchKeyword: '',
    model: null,
    selectedItems: null,
    dependentModels: null,
    searchPlaceholder: 'Search',
    isAllSelected: false,
    allSelectionObserver: Ember.observer('modelSham.[]', 'modelSham.@each.isDisplayTableSelected', function () {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', function () {
        var allCheckbox = (0, _jquery.default)(_this.element).find('.all-checkbox');
        allCheckbox.prop('indeterminate', false);
        if (_this.modelSham.isEvery('isDisplayTableSelected', true) && !Ember.isEmpty(_this.modelSham)) {
          _this.set('isAllDisplayTableSelected', true);
        } else if (_this.modelSham.isAny('isDisplayTableSelected', true)) {
          _this.set('isAllDisplayTableSelected', false);
          allCheckbox.prop('indeterminate', true);
        } else {
          _this.set('isAllDisplayTableSelected', false);
        }
      });
    }),
    modelSham: Ember.computed('model.[]', 'dependentModels.[]', 'searchKeyword', function () {
      var _this2 = this;
      var searchKeyword = this.searchKeyword;
      var model = [];
      if (!Ember.isEmpty(this.model)) {
        model = this.model;
      }
      if (searchKeyword) {
        model = model.filter(function (item) {
          return item.get('name').toLowerCase().indexOf(searchKeyword) >= 0;
        });
      }
      if (this.validationFunction) {
        model.forEach(function (item) {
          var validationWarning = _this2.validationFunction(item, _this2.dependentModels);
          item.setProperties({
            displayTableValidationWarning: validationWarning,
            isDisplayTableDisabled: !Ember.isEmpty(validationWarning)
          });
        });
      }
      return model;
    }),
    showAlertRow: Ember.computed('modelSham.@each.isDisplayTableDisabled', function () {
      return this.modelSham.isAny('isDisplayTableDisabled', true);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedItems', []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.setupSelection();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip();
    },
    willDestroyElement: function willDestroyElement() {
      var titleElements = (0, _jquery.default)(this.element).find('[data-toggle="tooltip"]');
      titleElements.tooltip('dispose');
      this.deselectItems();
    },
    actions: {
      onSearch: function onSearch(searchKeyword) {
        this.set('searchKeyword', searchKeyword);
      },
      toggleSelectAll: function toggleSelectAll() {
        if (this.model.isAny('isDisplayTableSelected', true)) {
          this.model.setEach('isDisplayTableSelected', false);
        } else {
          this.modelSham.forEach(function (item) {
            item.set('isDisplayTableSelected', !item.isDisplayTableDisabled);
          });
        }
        this.set('selectedItems', this.modelSham.filterBy('isDisplayTableSelected'));
        if (this.onSelect) {
          this.onSelect(this.selectedItems);
        }
      },
      toggleSelect: function toggleSelect(item) {
        if (!item.isDisplayTableDisabled) {
          item.toggleProperty('isDisplayTableSelected');
          this.set('selectedItems', this.model.filterBy('isDisplayTableSelected'));
          if (this.onSelect) {
            this.onSelect(this.selectedItems);
          }
        }
      }
    },
    setupSelection: function setupSelection() {
      if (this.isPreselect) {
        this.modelSham.setEach('isDisplayTableSelected', true);
      } else if (this.isSetToSelection) {
        this.modelSham.setEach('isDisplayTableSelected', this.modelSham.isSelected);
      } else {
        this.modelSham.setEach('isDisplayTableSelected', false);
      }
      this.modelSham.forEach(function (item) {
        if (item.isDisplayTableDisabled) {
          item.set('isDisplayTableSelected', false);
        }
      });
      this.set('selectedItems', this.modelSham.filterBy('isDisplayTableSelected'));
      if (this.onSelect) {
        this.onSelect(this.selectedItems);
      }
    },
    deselectItems: function deselectItems() {
      this.model.forEach(function (item) {
        item.setProperties({
          isDisplayTableSelected: null,
          displayTableValidationWarning: null,
          isDisplayTableDisabled: null
        });
      });
    }
  });
  _exports.default = _default;
});