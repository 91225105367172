define("experience-studio/components/av5-active-users-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  
    Description:
  
    Serves as a dropdown component.
  
    Usages:
  
    {{av5-active-users-dropdown
      changeActiveUsersDateRange=(action 'changeActiveUsersDateRange')
      currentOption=currentOption
      durations=durations
      requestParams=dropdownParams
      defaultDuration=defaultDuration
    }}
  
      <Av5Dropdown  />
  */
  var _default = Ember.Component.extend({
    // PROPERTIES ----------------------------------------------------------------
    classNames: ['av5-active-users-dropdown'],
    context: '',
    currentOption: null,
    currentTime: null,
    defaultDuration: null,
    // COMPUTED ------------------------------------------------------------------
    dropdownClassNames: Ember.computed('inheritedClasses', function () {
      return "dropdown-menu dropdown-menu-right ".concat(this.classNames.join(' '), " ").concat(this.inheritedClasses);
    }),
    dropdownWrapClassNames: Ember.computed('dropdownClass', function () {
      return "dropdown".concat(this.dropdownClass ? " ".concat(this.dropdownClass) : '');
    }),
    // OBSERVERS -----------------------------------------------------------------
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('dateRange', this.dateRangeConstructor());
      if (!Ember.isEmpty(this.dateRange)) {
        var defaultDateItem = this.dateRange.filter(function (item) {
          return item.name.includes(_this.durationToReadable(_this.defaultDuration));
        }).objectAt(0);
        this.set('currentOption', defaultDateItem);
        defaultDateItem.set('isSelected', true);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('isLoading', true);
      this.setDateTime();
    },
    // FUNCTIONS -----------------------------------------------------------------
    durationToReadable: function durationToReadable(duration) {
      var readableDurationMap = {
        1: 'Daily',
        7: 'Weekly',
        30: 'Monthly',
        60: 'Two Month',
        90: 'Three Month'
      };
      return readableDurationMap[duration];
    },
    dateRangeConstructor: function dateRangeConstructor() {
      var _this2 = this;
      this.set('currentTime', window.moment().unix() * 1000);
      return this.durations.map(function (duration) {
        return Ember.Object.create({
          name: "".concat(_this2.durationToReadable(duration), " Engaged ").concat(_this2.context),
          duration: duration,
          isSelected: false,
          to: _this2.currentTime,
          from: window.moment().startOf('day').subtract(duration - 1, 'days').startOf('day').unix() * 1000
        });
      });
    },
    setDateTime: function setDateTime() {
      this.requestParams.setProperties({
        from: this.currentOption.from,
        to: this.currentOption.to,
        activeDays: this.currentOption.duration
      });
    },
    // ACTIONS -------------------------------------------------------------------
    actions: {
      onValue: function onValue(item) {
        this.dateRange.setEach('isSelected', false);
        this.set('currentOption', item);
        item.set('isSelected', true);
        this.setDateTime();
        this.changeActiveUsersDateRange();
      }
    }
  });
  _exports.default = _default;
});