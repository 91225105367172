define("experience-studio/mixins/route-model-table-actions-rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    utility: Ember.inject.service(),
    actions: {
      confirmUndraftRecord: function confirmUndraftRecord(params) {
        var record = params.record;
        var message = '';
        message += 'Are you sure you want to activate ';
        message += "\"".concat(record.name, "\"") + " Context Rule?";
        this.send('showModal', Ember.Object.create({
          modalName: 'confirmation-modal-dialog-v2',
          primaryActionName: 'undraftRecord',
          primaryMessage: message,
          records: [record],
          secondaryMessage: 'Activated Rule cannot be edited nor turned back into draft.',
          title: 'Confirm Action',
          primaryAction: {
            action: 'undraftRecord',
            records: [record]
          }
        }));
      },
      duplicateRecord: function duplicateRecord(params) {
        var record = params.record;
        if (record) {
          var data = record.toJSON();
          if (data.body.hasComplexHistoricalPreview) {
            this.send('showModal', Ember.Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: "The rule \"".concat(record.name, "\" contains custom expression."),
              secondaryMessage: 'You cannot duplicate this rule.',
              title: 'Invalid Action'
            }));
            return;
          }
          delete data.composition;
          this.transitionTo("context-hub.create", {
            queryParams: {
              data: this.utility.btoa(data)
            }
          });
        }
      },
      hideSimulationModal: function hideSimulationModal() {
        this.controller.set('simulationModalData', null);
      },
      confirmSimulateRecord: function confirmSimulateRecord(params) {
        var record = params.record;
        this.controller.set('simulationModalData', record);
      },
      simulate: function simulate(model) {
        var _this = this;
        var self = this;
        var simulation;
        if (model) {
          var adapter = this.store.adapterFor(model.constructor.modelName);
          simulation = adapter.simulate(this.store, model.constructor, model);
          simulation.then(function () {
            var message = "Simulation for ".concat(model.get('name'), " rule ");
            message += 'in progress! This may take few minutes.';
            model.reload();
            _this.send('showNotification', Object.create({
              type: 'confirm',
              message: message
            }));
          }).catch(function (response) {
            self.send('showModal', Object.create({
              modalName: 'message-modal-dialog',
              primaryMessage: self.get('utility').parseError(response),
              title: "Failed to undraft ".concat(model.get('name')),
              type: 'danger'
            }));
          });
        }
        return simulation;
      },
      undraftRecord: function undraftRecord(model) {
        var _this2 = this;
        var record = model.records.get('firstObject');
        var adapter = this.store.adapterFor(record.constructor.modelName);
        var self = this;
        adapter.undraft(this.store, record.constructor, record).then(function () {
          record.reload().then(function () {
            self.send('hideModal');
            var message = 'The rule has been updated successfully.';
            _this2.send('showNotification', Object.create({
              type: 'confirm',
              message: message
            }));
          });
        }).catch(function (response) {
          self.send('showModal', Object.create({
            modalName: 'message-modal-dialog',
            primaryMessage: self.get('utility').parseError(response),
            title: "Failed to simulate ".concat(record.get('name')),
            type: 'danger'
          }));
        });
      }
    }
  });
  _exports.default = _default;
});