define("experience-studio/components/custom-field--content", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    didInsertElement: function didInsertElement() {
      var model = this.get('field.model');
      if (!Ember.isEmpty(this.get('field.value')) && !Ember.isEmpty(model)) {
        this.send('selectContent', model);
      } else {
        this.set('field.isValid', !this.get('field.isRequired'));
      }
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    setValidity: function setValidity(value) {
      var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.field.set('errorMessages', []);
      this._super(this.field.model, isValid);
    },
    triggerValidation: function triggerValidation() {
      if (!this.selectedContent && this.field.isRequired) {
        this.set('isDirty', true);
        this.send('deselectContent');
      }
    },
    actions: {
      selectContent: function selectContent(model) {
        this.set('selectedContent', model);
        this.set('field.model', model);
        this.send('onValue', {
          entity: 'content',
          value: model.get('id'),
          metadata: {}
        });
      },
      deselectContent: function deselectContent() {
        this.content.setEach('isSelected', false);
        this.set('selectedContent', null);
        this.set('field.model', null);
        this.send('onValue', null);
      },
      handleSearch: function handleSearch(keyword, modelName, propertiesVar) {
        this.sendAction('handleSearch', keyword, modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      },
      loadModels: function loadModels(modelName, propertiesVar) {
        this.sendAction('loadModels', modelName, propertiesVar); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});