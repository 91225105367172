define("experience-studio/templates/components/custom-field--boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Tfg7nDyS",
    "block": "{\"symbols\":[\"message\",\"option\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,0]],[13],[6,[37,2],[[35,1,[\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,6],null,[[\"class\",\"triggerClass\",\"dropdownClass\",\"options\",\"selected\",\"placeholder\",\"searchEnabled\",\"onchange\"],[\"form-control\",\"form-control\",\"form-control\",[35,5],[35,4],\"Select an option\",false,[30,[36,3],[[32,0],\"onValue\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,1,[\"errorMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"error-message text-danger\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"field\",\"if\",\"action\",\"currentOption\",\"options\",\"power-select\",\"-track-array\",\"each\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--boolean.hbs"
  });
  _exports.default = _default;
});