define("experience-studio/mixins/route-model-table-actions-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      showManageGroupModalDialog: function showManageGroupModalDialog(params) {
        var group = params.record;
        var model = this.controller.model;
        var data = Ember.Object.create({
          experiences: this.store.findAll('experience'),
          groups: this.store.findAll('group'),
          group: group ? group : this.store.createRecord('group'),
          languages: model.languages.config
        });
        this.controller.set('manageGroupModalData', data);
      },
      showManagePrioritizationModal: function showManagePrioritizationModal(params) {
        var type = params.type || arguments[0]; // temp backwards compatibility
        var record = params.record || arguments[1];
        var parent = params.parent || arguments[2];
        var data = Ember.Object.create({
          action: type,
          experiences: [],
          groups: [],
          selectedExperience: null,
          selectedGroup: null
        });
        if (type.indexOf('CONTENT_INSTANCE') >= 0) {
          data.set('selectedExperience', record);
        } else if (type.indexOf('GROUP_EXPERIENCE') >= 0) {
          data.setProperties({
            groups: this.store.query('group', {
              sort: 'priority'
            }),
            selectedGroup: parent
          });
          data.set('groups', this.store.query('group', {
            sort: 'priority'
          }));
        } else if (type.indexOf('EXPERIENCE') >= 0) {
          data.set('experiences', this.store.query('experience', {
            sort: 'priority'
          }));
        } else if (type.indexOf('GROUP') >= 0) {
          data.set('groups', this.store.query('group', {
            sort: 'priority'
          }));
        }
        this.controller.set('managePrioritizationModalData', data);
      }
    }
  });
  _exports.default = _default;
});