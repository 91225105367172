define("experience-studio/components/model-list-item-content-instance", ["exports", "experience-studio/components/model-list-item"], function (_exports, _modelListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItem.default.extend({
    experiencePriority: Ember.computed('sortingData', function () {
      var sortingData = this.sortingData;
      if (sortingData) {
        return sortingData[this.get('model.id')];
      }
      return null;
    })
  });
  _exports.default = _default;
});