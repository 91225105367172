define("experience-studio/components/info-sidebar-rule-composition-literal", ["exports", "jquery", "experience-studio/config/environment", "experience-studio/helpers/location"], function (_exports, _jquery, _environment, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    classNames: ['info-sidebar-rule-composition-literal', 'literal'],
    classNameBindings: ['isArea'],
    model: null,
    isMapError: false,
    isFinalElement: false,
    plugins: null,
    isAnd: Ember.computed('logicalOperator', function () {
      return this.operator === 'AND';
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.model.IndexInBlock + 1 === this.total) {
        this.set('isFinalElement', true);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.store.findAll('plugin').then(function (plugins) {
        _this.set('plugins', plugins);
      });
      if (this.get('isArea')) {
        var locationId = '';
        if (this.get('isArea')) {
          locationId = this.get('model.predicate.arguments').objectAt(0).split('.').pop();
        }
        if (locationId) {
          this.store.findRecord('location', locationId).then(function (record) {
            _this.set('location', record);
          });
        }
      }
      if (this.get('isHistoricalPlugin')) {
        var historicalQueryId = this.get('model.predicate.arguments').objectAt(0).split('.').pop();
        if (historicalQueryId && historicalQueryId.split('-').length > 1) {
          this.store.findRecord('context-historical-query', historicalQueryId).then(function (record) {
            _this.set('historicalQueryRecord', record);
          });
        }
        var historicalQueryIdTwo = this.get('model.predicate.arguments').objectAt(1).split('.').pop();
        if (historicalQueryIdTwo && historicalQueryIdTwo.split('-').length > 1) {
          this.store.findRecord('context-historical-query', historicalQueryIdTwo).then(function (record) {
            _this.set('historicalQueryRecordTwo', record);
          });
        }
      }
    },
    isDateTime: Ember.computed('rule', 'plugins', function () {
      var _this2 = this;
      var serializer = this.store.serializerFor('rule');
      var composition = serializer.getComposition({
        plugins: this.plugins,
        rule: this.rule
      });
      var isDateTime = false;
      if (!Ember.isEmpty(composition)) {
        composition.forEach(function (compos) {
          if (!Ember.isEmpty(compos.items)) {
            compos.items.forEach(function (item) {
              if (!Ember.isEmpty(item.selectedAttribute) && !Ember.isEmpty(item.selectedAttribute.get('uid')) && !Ember.isEmpty(_this2.model.predicate) && !Ember.isEmpty(_this2.model.predicate.arguments) && item.selectedAttribute.get('uid') === _this2.model.predicate.arguments[0] && item.selectedAttribute.get('isTimeContext')) {
                isDateTime = true;
              }
            });
          }
        });
      }
      return isDateTime;
    }),
    isNearbyLocationLabel: Ember.computed('model.predicate.arguments', function () {
      var args = this.get('model.predicate.arguments');
      var _isNearbyLocationLabel = false;
      if (!Ember.isEmpty(args) && args.get('length') >= 1 && args.objectAt(0).indexOf('ctx.flybits.area.query.inAreaWithLabel.eventType.') > -1) {
        _isNearbyLocationLabel = true;
      }
      return _isNearbyLocationLabel;
    }),
    locationLabelText: Ember.computed('model.predicate.arguments', function () {
      var args = this.get('model.predicate.arguments');
      var _args = args[0].split('.');
      return _args[_args.length - 1];
    }),
    isArea: Ember.computed('model.predicate.arguments', function () {
      var isArea = false;
      var args = this.get('model.predicate.arguments');
      if (!Ember.isEmpty(args) && args.get('length') >= 1 && args.objectAt(0).indexOf('ctx.flybits.area.query.inArea.') >= 0 || args.get('length') >= 1 && args.objectAt(0).indexOf('ctx.flybits.approximateArea.') >= 0) {
        isArea = true;
      }
      return isArea;
    }),
    isApproximateArea: Ember.computed('model.predicate.arguments', function () {
      var args = this.get('model.predicate.arguments');
      var isApproximateArea = false;
      if (!Ember.isEmpty(args) && args.get('length') >= 1 && args.objectAt(0).indexOf('ctx.flybits.approximateArea') >= 0) {
        isApproximateArea = true;
      }
      return isApproximateArea;
    }),
    isHistoricalPlugin: Ember.computed(function () {
      var args = this.get('model.predicate.arguments');
      var isHistoricalPlugin = false;
      if (!Ember.isEmpty(args) && args.get('length') >= 1 && args.objectAt(0).indexOf('ctx.flybits.ctxhistory') >= 0) {
        isHistoricalPlugin = true;
      }
      if (!Ember.isEmpty(args) && args.get('length') >= 1 && args.objectAt(1).indexOf('ctx.flybits.ctxhistory') >= 0) {
        isHistoricalPlugin = true;
      }
      return isHistoricalPlugin;
    }),
    isHistoricalPluginTwo: Ember.computed(function () {
      var args = this.get('model.predicate.arguments');
      var isHistoricalPlugin = false;
      if (!Ember.isEmpty(args) && args.get('length') >= 1 && args.objectAt(0).indexOf('ctx.flybits.ctxhistory') >= 0) {
        isHistoricalPlugin = true;
      }
      return isHistoricalPlugin;
    }),
    getHistoricalDuration: function getHistoricalDuration(timeRange) {
      var historicalPluginText = '';
      if (timeRange) {
        if (timeRange.from > 0 && timeRange.to > 0 && timeRange.duration <= 0) {
          historicalPluginText += ' between ';
          historicalPluginText += window.moment.unix(timeRange.from).format('ddd MMM DD YYYY h:mma');
          historicalPluginText += ' and ';
          historicalPluginText += window.moment.unix(timeRange.to).format('ddd MMM DD YYYY h:mma');
        } else if (timeRange.from > 0 && timeRange.to <= -1 && timeRange.duration <= 0) {
          historicalPluginText += ' after ';
          historicalPluginText += window.moment.unix(timeRange.from).format('ddd MMM DD YYYY h:mma');
        } else if (timeRange.to > 0 && timeRange.from <= -1 && timeRange.duration <= 0) {
          historicalPluginText += ' before ';
          historicalPluginText += window.moment.unix(timeRange.to).format('ddd MMM DD YYYY h:mma');
        } else if (timeRange.from === 0 && timeRange.to === -1 && timeRange.duration > 0) {
          historicalPluginText += ' in the past ';
          var recencyInThePastUnit = 'minute(s)';
          var recencyInThePastValue = null;
          if (timeRange.duration >= 86400) {
            recencyInThePastValue = timeRange.duration / 86400;
            recencyInThePastUnit = 'day(s)';
          } else if (timeRange.duration >= 3600) {
            recencyInThePastValue = timeRange.duration / 3600;
            recencyInThePastUnit = 'hour(s)';
          } else {
            recencyInThePastValue = timeRange.duration / 60;
            recencyInThePastUnit = 'minute(s)';
          }
          return historicalPluginText += "".concat(recencyInThePastValue, " ").concat(recencyInThePastUnit);
        }
      }
      return '';
    },
    historicalPluginText: Ember.computed('isHistoricalPlugin', 'historicalQueryRecord', 'historicalQueryRecordTwo', function () {
      var historicalPluginText = '';
      var historicalQueryRecord = this.get('historicalQueryRecord') || this.get('historicalQueryRecordTwo');
      var historicalQueryRecordTwo = this.get('historicalQueryRecordTwo');
      var historicalFunction = this.get('historicalQueryRecord.function');
      if (historicalQueryRecord && historicalQueryRecord.id || historicalQueryRecordTwo && historicalQueryRecordTwo.id) {
        var _historicalQueryRecor, _historicalQueryRecor2, _historicalQueryRecor3;
        var isNegated = this.model.isNegated;
        var predicateValue = this.model.predicate.arguments[1];
        var where = historicalQueryRecord.where.objectAt(0);
        var attributeName = historicalQueryRecord.plugin.attribute;
        var pluginName = historicalQueryRecord === null || historicalQueryRecord === void 0 ? void 0 : (_historicalQueryRecor = historicalQueryRecord.plugin) === null || _historicalQueryRecor === void 0 ? void 0 : (_historicalQueryRecor2 = _historicalQueryRecor.id) === null || _historicalQueryRecor2 === void 0 ? void 0 : (_historicalQueryRecor3 = _historicalQueryRecor2.split('.')) === null || _historicalQueryRecor3 === void 0 ? void 0 : _historicalQueryRecor3[2];
        var predicateName = this.model.predicate.name;
        var whereOperator = '';
        pluginName = Ember.String.classify(pluginName);
        attributeName = Ember.String.classify(attributeName);
        var p1 = historicalQueryRecord.plugin.parameter1 === '' ? '*' : historicalQueryRecord.plugin.parameter1;
        var p2 = historicalQueryRecord.plugin.parameter2 === '' ? '*' : historicalQueryRecord.plugin.parameter2;
        var p3 = historicalQueryRecord.plugin.parameter3 === '' ? '*' : historicalQueryRecord.plugin.parameter3;
        var p4 = historicalQueryRecord.plugin.parameter4 === '' ? '*' : historicalQueryRecord.plugin.parameter4;
        var p5 = historicalQueryRecord.plugin.parameter5 === '' ? '*' : historicalQueryRecord.plugin.parameter5;
        if (predicateName == 'greq') {
          predicateName = 'at least';
        } else if (predicateName == 'lseq') {
          predicateName = 'at most';
        } else if (predicateName == 'eq') {
          predicateName = 'exactly';
        } else if (predicateName == 'ls') {
          predicateName = 'less than';
        } else if (predicateName == 'gr') {
          predicateName = 'more than';
        } else {
          predicateName = 'somewhat';
        }
        if (where.operator === '=') {
          whereOperator = 'has been equal to';
        } else if (where.operator === '>') {
          whereOperator = 'has been greater than';
        } else if (where.operator === '<') {
          whereOperator = 'has been less than';
        }
        if (isNegated) {
          whereOperator = whereOperator.replace('has been', 'has not been');
        }
        if (historicalFunction === 'count') {
          historicalPluginText += "".concat(attributeName, ".(").concat(pluginName, ")");
          historicalPluginText += "".concat(p1 && ".".concat(p1)).concat(p2 && ".".concat(p2)).concat(p3 && ".".concat(p3)).concat(p4 && ".".concat(p4)).concat(p5 && ".".concat(p5));
          historicalPluginText += " ".concat(whereOperator, " ").concat(where.value);
          historicalPluginText += " ".concat(predicateName, " ").concat(predicateValue, " time(s)");
          if (historicalQueryRecord.timeRange && historicalQueryRecord.timeRange.length) {
            historicalPluginText += this.getHistoricalDuration(historicalQueryRecord.timeRange[0]);
          }
        } else {
          historicalPluginText += "".concat(historicalFunction, " of ").concat(attributeName, ".(").concat(pluginName, ")");
          historicalPluginText += "".concat(p1 && ".".concat(p1)).concat(p2 && ".".concat(p2)).concat(p3 && ".".concat(p3)).concat(p4 && ".".concat(p4)).concat(p5 && ".".concat(p5));
          if (historicalQueryRecord.timeRange && historicalQueryRecord.timeRange.length) {
            historicalPluginText += this.getHistoricalDuration(historicalQueryRecord.timeRange[0]);
          }
          historicalPluginText += " ".concat(whereOperator, " ").concat(predicateValue);
          historicalPluginText += "[".concat(attributeName, " criteria: ").concat(attributeName, " ").concat(whereOperator, " ").concat(where.value, "]");
        }
      } else {
        historicalPluginText = 'Missing History Query';
      }
      return historicalPluginText;
    }),
    mapDescription: Ember.computed(function () {
      if (this.get('model.predicate.arguments')[1] === 'true') {
        if (this.get('model.predicate.arguments')[0].includes('approximateArea')) {
          return 'User can be anywhere in this area';
        }
        return 'User is inside area';
      } else {
        return 'User is outside of area';
      }
    }),
    mapImageUrl: Ember.computed('location', function () {
      var coordinates = [];
      var endpoint = 'maps/api/staticmap';
      var host = 'https://maps.googleapis.com';
      var location = this.get('location');
      if (location && location.shape && location.shape.length) {
        location.shape.forEach(function (coordinate) {
          return coordinates.push("".concat(coordinate.lat, ",").concat(coordinate.lng));
        });
      }
      var query = {
        key: _environment.default.APP.googleMapKey,
        path: "color:0x29BFEDFF|weight:2|".concat(coordinates.join('|')),
        size: '200x200',
        scale: 2
      };
      return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query));
    }),
    mapImageCircleUrl: Ember.computed('location', function () {
      var shape = null;
      var approxAreaRadius = 3000; //metres
      var location = this.get('location');
      if (location && location.shape) {
        shape = location.shape;
      }
      if (shape) {
        var shapeCenter = (0, _location.calculateCenter)(shape);
        var lat = shapeCenter.lat;
        var lng = shapeCenter.lng;
        var radius = shapeCenter.radius + approxAreaRadius;
        var endpoint = 'maps/api/staticmap';
        var host = 'https://maps.googleapis.com';
        var query = {
          key: _environment.default.APP.googleMapKey,
          path: "color:0x29BFEDFF|weight:0|fillcolor:0x29BFED63".concat((0, _location.calculateCircleData)(lat, lng, radius)),
          size: '300x300'
        };
        return "".concat(host, "/").concat(endpoint, "?").concat(_jquery.default.param(query));
      } else {
        return false;
      }
    }),
    actions: {
      setIsMapError: function setIsMapError() {
        this.set('isMapError', true);
      }
    }
  });
  _exports.default = _default;
});