define("experience-studio/templates/components/custom-field--text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HORu8u/Y",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,9],null,[[\"placeholder\",\"languages\",\"isSkipUrlValidationVisible\",\"model\",\"key\",\"field\",\"label\",\"isRequired\",\"linkValue\",\"keyUp\"],[[35,1,[\"placeholder\"]],[35,8],[35,7],[35,1,[\"input\"]],[35,1,[\"key\"]],[35,1],[35,1,[\"name\"]],[35,1,[\"isRequired\"]],[35,6],\"onValue\"]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"error-label\"],[12],[1,[35,1,[\"errorMessages\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"label\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,0]],[13],[6,[37,2],[[35,1,[\"isRequired\"]]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"*\"],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"class\",\"value\",\"key-up\",\"placeholder\"],[\"form-control\",[35,1,[\"value\"]],\"onValue\",\"Enter text\"]]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,1,[\"errorMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"error-message text-danger\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"field\",\"if\",\"input\",\"-track-array\",\"each\",\"dropdownValue\",\"isValidationVisible\",\"languages\",\"localized-input\",\"isActionTypeComponent\"]}",
    "moduleName": "experience-studio/templates/components/custom-field--text.hbs"
  });
  _exports.default = _default;
});