define("experience-studio/adapters/project-config", ["exports", "jquery", "experience-studio/adapters/application"], function (_exports, _jquery, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    getConfig: function getConfig(key) {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve) {
        _jquery.default.ajax({
          headers: _this.headers,
          type: 'GET',
          url: _this.getUrl(key)
        }).then(function (response) {
          return resolve(_this.parseConfig(key, response));
        }).catch(function () {
          resolve(_this.parseConfig(key, null));
        });
      });
    },
    getHeaders: function getHeaders(token) {
      var headers = this.headers;
      headers = JSON.parse(JSON.stringify(headers));
      headers['X-Authorization'] = token;
      return headers;
    },
    getUrl: function getUrl(key) {
      var url = "".concat(this.host, "/sso/settings/configs/");
      if (key === 'push-setting') {
        url += 'push-settings';
      } else if (key === 'language') {
        url += 'languages';
      } else {
        url += key;
      }
      return url;
    },
    parseConfig: function parseConfig(key, response) {
      var model = Ember.Object.create({
        config: null,
        id: key,
        isEnabled: false,
        name: ''
      });
      switch (key) {
        case 'push-setting':
          return this.parsePush(model, response);
        case 'language':
          return this.parseLanguage(model, response);
        case 'project-custom-content-templates':
          return this.parseCustomTemplate(model, response);
      }
    },
    parseCustomTemplate: function parseCustomTemplate(model, response) {
      if (response) {
        response = JSON.parse(response.config);
      } else {
        response = {
          categories: []
        };
      }
      model.setProperties({
        config: response,
        isEnabled: false,
        name: 'customTemplate'
      });
      return model;
    },
    parseLanguage: function parseLanguage(model, response) {
      if (response) {
        response = JSON.parse(response.config);
        response.data = response.data.map(function (item) {
          return Ember.Object.create(item);
        });
      } else {
        response = {
          data: []
        };
      }
      if (Ember.isEmpty(response.data)) {
        response.data.pushObject(Ember.Object.create({
          id: 'en',
          isDefault: true,
          name: 'English'
        }));
      }
      model.setProperties({
        config: response.data,
        isEnabled: false,
        name: 'Language'
      });
      return model;
    },
    parsePush: function parsePush(model, response) {
      var config = Ember.Object.create({
        subscriptions: [],
        types: []
      });
      if (response) {
        response = JSON.parse(response.config);
      } else {
        response = {};
      }
      if (response.subscriptions && response.subscriptions.data.length) {
        config.set('subscriptions', response.subscriptions.data.map(function (sub) {
          return Ember.Object.create(sub);
        }));
      }
      if (response.types && response.types.data.length) {
        config.set('types', response.types.data.map(function (type) {
          return Ember.Object.create(type);
        }));
      }
      model.setProperties({
        config: config,
        isEnabled: false,
        name: 'push-setting'
      });
      return model;
    },
    queryRecord: function queryRecord(store, type, query) {
      return this.getConfig(query.setting);
    }
  });
  _exports.default = _default;
});