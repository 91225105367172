define("experience-studio/serializers/push-type", ["exports", "experience-studio/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    userPreferences: Ember.inject.service(),
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && payload.data) {
        payload.data.unshift({
          id: 'Basic',
          name: 'Basic',
          description: "When a message is opened, the user will be redirected to your app's default home screen.",
          isActive: true,
          isReserved: true
        });
      }
      if (this.userPreferences.getValue('locationContent')) {
        payload.data.push({
          id: 'LocationContent',
          name: 'Location Content',
          description: "When a message is opened, the user will be redirected to your app's default home screen.",
          fields: Ember.A([Ember.Object.create({
            key: "contentBlueprintId",
            name: 'Location Content',
            type: "entity",
            valueType: "string",
            value: null,
            model: null,
            valueOptions: null,
            isRequired: true,
            isContent: true,
            isValid: false,
            errorMessages: Ember.A([])
          })]),
          isActive: true,
          isReserved: true
        });
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    transformPayloadForEmber: function transformPayloadForEmber() {
      var item = this._super.apply(this, arguments);
      if (item.attributes.schema) {
        item.attributes.fields = Object.keys(item.attributes.schema).map(function (key) {
          var field = item.attributes.schema[key];
          field.key = key;
          field.name = field.description;
          field.isValid = false;
          field.errorMessages = [];
          field.value = undefined;
          field.isContent = field.type === 'entity' || field.type === 'blueprint';
          delete field.description;
          return Ember.Object.create(field);
        });
        delete item.attributes.schema;
      }
      return item;
    }
  });
  _exports.default = _default;
});