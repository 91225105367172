define("experience-studio/templates/components/message-modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7a6EhzDo",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"onClose\",\"clickOutsideToClose\",\"containerClassNames\",\"translucentOverlay\"],[\"close\",true,[35,4],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-dialog\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,1,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"modal-title\"],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"primaryMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"p\"],[15,0,[31,[\"primary-message \",[34,0]]]],[12],[1,[35,1,[\"primaryMessage\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"secondaryMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"p\"],[15,0,[31,[\"secondary-message \",[34,0]]]],[12],[1,[35,1,[\"secondaryMessage\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,3],[[32,0],\"close\"],null],[12],[2,\"Close\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"messageTypeClassName\",\"model\",\"if\",\"action\",\"classNames\",\"modal-dialog\"]}",
    "moduleName": "experience-studio/templates/components/message-modal-dialog.hbs"
  });
  _exports.default = _default;
});