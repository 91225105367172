define("experience-studio/components/push-messages-new--section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['push-messages-new--section'],
    classNameBindings: ['isOpen:current-section', 'isInvalid::valid-section', 'errors.details:invalid-section'],
    title: '',
    description: '',
    isOpen: true,
    isInvalid: true,
    index: 0,
    showCheckMark: true,
    isFirst: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.index <= 1) {
        this.set('index', 1);
        this.set('isFirst', true);
      }
    },
    actions: {
      openSection: function openSection() {
        this.set('isOpen', !this.isOpen);
      },
      cancelSection: function cancelSection() {
        this.cancel();
      }
    }
  });
  _exports.default = _default;
});