define("experience-studio/routes/context-hub/location", ["exports", "experience-studio/mixins/authenticated-route", "experience-studio/mixins/prevent-reload-route", "experience-studio/mixins/route-data-query-param", "experience-studio/mixins/route-model-batch-actions-base", "experience-studio/mixins/route-model-batch-actions-location", "experience-studio/mixins/route-model-table-actions-base"], function (_exports, _authenticatedRoute, _preventReloadRoute, _routeDataQueryParam, _routeModelBatchActionsBase, _routeModelBatchActionsLocation, _routeModelTableActionsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRoute.default, _routeDataQueryParam.default, _routeModelTableActionsBase.default, _preventReloadRoute.default, _routeModelBatchActionsBase.default, _routeModelBatchActionsLocation.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var defaultPagination = {
        currentPage: 1,
        recordsPerPage: 10
      };
      controller.setProperties({
        contextHubController: this.controllerFor('context-hub'),
        data: controller.data ? controller.data : this.utility.btoa(defaultPagination),
        isRefreshFilterModel: true,
        isUpdatingDataQueryParam: true,
        locationModel: null,
        selectedEntities: [],
        userPref: this.store.findAll('user-preference')
      });
      controller.contextHubController.setProperties({
        isSearchFormHidden: true
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          _data: '',
          data: '',
          locationModel: null,
          selectedEntities: [],
          isRefreshFilterModel: true
        });
        controller.contextHubController.setProperties({
          isSearchFormHidden: false
        });
        this.send('deselectModelListItems');
      }
    },
    refreshModels: function refreshModels() {
      this.send('refresh');
    },
    getModelListItems: function getModelListItems() {
      return this.controller.filteredModel;
    },
    actions: {
      addLocation: function addLocation() {
        var locationModel = this.get('store').createRecord('location');
        this.controller.set('locationModel', locationModel);
      },
      cancelLocation: function cancelLocation() {
        this.controller.set('locationModel', null);
      },
      deselectModelListItems: function deselectModelListItems(params) {
        params = params || {};
        params.selectedEntitiesPath = 'contextHubController.selectedEntities';
        this._super(params);
      },
      editRecord: function editRecord(params) {
        this.controller.set('locationModel', params.record);
      },
      fetchLabels: function fetchLabels(promise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          return promise.resolve(result);
        });
      },
      saveLocation: function saveLocation(location, isNew) {
        var self = this;
        this.controller.set('locationModel', null);
        this.send('refresh');
        if (isNew) {
          Ember.run.scheduleOnce('afterRender', self, function () {
            self.send('showNotification', {
              type: 'confirm',
              message: "Successfully created \"".concat(location.name, "\" Location")
            });
          });
        } else {
          Ember.run.scheduleOnce('afterRender', self, function () {
            self.send('showNotification', {
              type: 'confirm',
              message: "Changes made to \"".concat(location.name, "\" Location")
            });
          });
        }
      },
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      selectModelListItem: function selectModelListItem(params) {
        params = params || {};
        params.sidebarModelPath = 'contextHubController.sidebarModel';
        this._super(params);
      },
      selectModelListItems: function selectModelListItems(params) {
        params = params || {};
        params.selectedEntitiesPath = 'contextHubController.selectedEntities';
        this._super(params);
      },
      showInfo: function showInfo() {
        this.send('showInfoRoute');
      },
      viewInfo: function viewInfo(params) {
        params = params || {};
        params.controller = 'contextHubController';
        params.sidebarModelPath = 'contextHubController.sidebarModel';
        this._super(params);
      },
      refresh: function refresh() {
        this.send('deselectModelListItems');
        this.controller.toggleProperty('isRefreshFilterModel');
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});