define("experience-studio/components/push-messages-new--field-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['push-messages-new-field'],
    field: Ember.Object.create({
      value: ''
    })
  });
  _exports.default = _default;
});