define("experience-studio/components/model-list-item-context-data-upload-file", ["exports", "experience-studio/components/model-list-item"], function (_exports, _modelListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelListItem.default.extend({
    isShowProcessAction: false,
    model: null,
    updatedAt: Ember.computed('model.{status,updatedAt}', function () {
      var processedAt = window.moment(this.get('model.processedAt'));
      var status = this.get('model.status').toLowerCase();
      var updatedAt = window.moment(this.get('model.updatedAt'));
      var diff;
      var timeElapsed;
      var timeElapsedUpdated;
      if (status !== 'uploaded' && status === 'processing') {
        diff = processedAt.from(updatedAt);
        if (diff === 'a few seconds ago') {
          timeElapsed = 'less than a minute ago';
        } else {
          timeElapsed = diff;
        }
      } else {
        if (window.moment().diff(updatedAt, 'hours') < 24) {
          timeElapsedUpdated = updatedAt.fromNow();
          if (timeElapsedUpdated === 'a few seconds ago') {
            timeElapsed = 'less than a minute ago';
          } else {
            timeElapsed = timeElapsedUpdated;
          }
        } else {
          timeElapsed = updatedAt.format('MMMM Do YYYY, h:mm:ss a');
        }
      }
      return timeElapsed;
    }),
    isProcessingFailed: Ember.computed('model.status', function () {
      var status = this.get('model.status');
      var isFailed = false;
      if (status.toLowerCase() === 'processing_failed') {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.$('[data-toggle="tooltip"]').tooltip('dispose');
          this.$('[data-toggle="tooltip"]').tooltip();
        });
        isFailed = true;
      } else {
        isFailed = false;
      }
      return isFailed;
    }),
    progressStatus: Ember.computed('model.{status,willCompleteAt}', function () {
      var model = this.model;
      var status = model.get('status').toLowerCase() || '';
      var willCompleteAt = model.get('willCompleteAt');
      var progressStatus = '';
      var isShowSpinner = false;
      if (status !== 'processing') {
        progressStatus = '';
      } else if (status === 'processing' && !willCompleteAt) {
        progressStatus = 'Calculating estimated time.';
        isShowSpinner = true;
      } else {
        var _willCompleteAt = window.moment(model.get('willCompleteAt'));
        var timeRemaining = _willCompleteAt.fromNow();
        if (timeRemaining && timeRemaining.toLowerCase() === 'in a few seconds') {
          timeRemaining = 'in less than 1 minute';
        }
        progressStatus = "".concat(timeRemaining, ".");
        isShowSpinner = false;
      }
      return Ember.Object.create({
        progressStatus: progressStatus,
        isShowSpinner: isShowSpinner
      });
    }),
    processingProgress: Ember.computed('model.willCompleteAt', function () {
      var model = this.model;
      var willCompleteAt = model.get('willCompleteAt');
      var status = model.get('status').toLowerCase() || '';
      var percentage = 0;
      if (status !== 'processing') {
        percentage = 0;
      } else if (status === 'processing' && !willCompleteAt) {
        percentage = 0;
      } else {
        var processedAt = window.moment(model.get('processedAt')).unix() * 1000;
        var currentTime = window.moment().unix() * 1000;
        var _willCompleteAt2 = window.moment(model.get('willCompleteAt')).unix() * 1000;
        percentage = Math.round((currentTime - processedAt) / (_willCompleteAt2 - processedAt) * 100);
      }
      return percentage;
    }),
    setStatus: function setStatus(obj) {
      var statusData = {
        isUploaded: false,
        isProcessing: false,
        isCompleted: false,
        isFailed: false,
        isCancelled: false
      };
      for (var key in statusData) {
        if (statusData.hasOwnProperty(key)) {
          if (key === obj['state']) {
            statusData[key] = true;
          } else {
            statusData[key] = false;
          }
        }
      }
      return Ember.Object.create(statusData);
    },
    statusMessage: Ember.computed('model.status', function () {
      var model = this.model;
      var status = model.get('status').toLowerCase() || '';
      var statusData;
      if (status === 'uploaded') {
        statusData = this.setStatus({
          state: 'isUploaded'
        });
        statusData.set('statusMessage', 'Uploaded');
      } else if (status === 'processing') {
        statusData = this.setStatus({
          state: 'isProcessing'
        });
        statusData.set('statusMessage', 'Processing started');
      } else if (status === 'processing_failed') {
        statusData = this.setStatus({
          state: 'isFailed'
        });
        statusData.set('statusMessage', model.get('statusMessage'));
      } else if (status === 'processing_success') {
        statusData = this.setStatus({
          state: 'isCompleted'
        });
        statusData.set('statusMessage', 'Completed');
      } else if (status === 'processing_canceled') {
        statusData = this.setStatus({
          state: 'isCancelled'
        });
        statusData.set('statusMessage', 'Cancelled');
      } else {
        statusData = this.setStatus({
          state: 'isUploaded'
        });
        statusData.set('statusMessage', 'Uploaded');
      }
      return statusData;
    }),
    didInsertElement: function didInsertElement() {
      this.$('[data-toggle="tooltip"]').tooltip();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.$('[data-toggle="tooltip"]').tooltip('dispose');
      this._super.apply(this, arguments);
    },
    actions: {
      deleteUploadedFile: function deleteUploadedFile(model) {
        this.sendAction('deleteUploadedFile', model); // eslint-disable-line ember/closure-actions
      },
      processFile: function processFile(model) {
        this.sendAction('processFile', model); // eslint-disable-line ember/closure-actions
      }
    }
  });
  _exports.default = _default;
});