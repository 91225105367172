define("experience-studio/routes/context-hub/create", ["exports", "experience-studio/mixins/route-model-table-actions-base", "experience-studio/mixins/route-model-table-actions-context-data-upload"], function (_exports, _routeModelTableActionsBase, _routeModelTableActionsContextDataUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_routeModelTableActionsBase.default, _routeModelTableActionsContextDataUpload.default, {
    utility: Ember.inject.service(),
    userPreferences: Ember.inject.service(),
    queryParams: {
      data: {}
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application'
      });
    },
    model: function model(params) {
      var model;
      var store = this.store;
      if (params.data) {
        model = this.extractModel(params);
      } else {
        model = store.createRecord('rule');
      }
      return Ember.RSVP.hash({
        historicalQueries: store.findAll('context-historical-query'),
        locations: store.findAll('location'),
        plugins: store.findAll('plugin'),
        rule: model,
        rules: store.findAll('rule')
      });
    },
    afterModel: function afterModel(model, transition) {
      var queryParams = null;
      if (transition.queryParams) {
        queryParams = transition.queryParams;
      }
      if (!queryParams && transition.to.queryParams) {
        queryParams = transition.to.queryParams;
      }
      if (queryParams && queryParams.data) {
        if (model.rule.get('isNew')) {
          model.rule = this.extractModel(queryParams);
        }
        var serializer = this.store.serializerFor('rule');
        model.rule.set('composition', serializer.getComposition(model));
      }
    },
    extractModel: function extractModel(params) {
      var data = this.utility.atob(params.data);
      data.name += ' - Copy';
      // stringRepresentation is deleted as it must be unique
      delete data.stringRepresentation;
      delete data.visibility;
      return this.store.createRecord('rule', data);
    },
    hasChanges: function hasChanges() {
      var controller = this.controller;
      var initialHash = controller.initialHash;
      var model = controller.model.serialize();
      delete model.composition;
      var newHash = this.utility.hash({
        rule: model,
        selectedLabels: controller.selectedLabels.mapBy('value').sort()
      });
      return newHash !== initialHash;
    },
    goBack: function goBack(isSave) {
      var applicationController = this.controllerFor('application');
      var previousRouteName = this._router.getPreviousRouteName();
      var queryParams = null;
      var routeName = 'context-hub.rule.index';
      if (applicationController) {
        if (this.userPreferences.getValue('routeListView')) {
          routeName = 'context-hub.rule-v2';
          queryParams = {
            data: this.utility.btoa({
              timestamp: Date.now()
            })
          };
        }
      }
      if (isSave) {
        if (queryParams) {
          this.transitionTo(routeName, {
            queryParams: queryParams
          });
        } else {
          this.transitionTo(routeName);
        }
      } else if (this.controller.get('previousTransition')) {
        this.controller.get('previousTransition').retry();
      } else if (previousRouteName) {
        this.transitionTo(previousRouteName);
      } else {
        window.history.back();
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      controller.setProperties({
        activeTab: 'public-plugins',
        errorMessage: '',
        isHistoricalErrorMessage: false,
        historicalQueries: model.historicalQueries,
        isAddLocation: false,
        isPublicPluginsTabActive: true,
        locationErrorMessage: null,
        locations: model.locations,
        plugins: model.plugins.filterBy('isActive', true),
        rule: model.rule,
        rules: model.rules,
        selectedCompositionGroup: null,
        selectedCompositionItem: null,
        selectedLabels: [],
        selectedLocationLabels: [],
        transitionConfirmed: false
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        var composition = controller.get('model.composition');
        if (Ember.isEmpty(composition)) {
          controller.send('addCompositionGroup');
        } else if (!Ember.isEmpty(composition.objectAt(0).items)) {
          controller.send('selectCompositionItem', composition.objectAt(0).items.objectAt(0));
        }
        var serializedModel = controller.model.serialize();
        delete serializedModel.composition;
        controller.set('initialHash', _this.utility.hash({
          rule: serializedModel,
          selectedLabels: controller.selectedLabels.mapBy('value').sort()
        }));
      });
      var rule = model.rule;
      rule.set('schedule', Ember.Object.create({
        endScheduler: Ember.Object.create({
          repeat: Ember.Object.create({
            frequency: 1,
            frequencyType: 'once',
            limit: 1
          })
        }),
        startScheduler: Ember.Object.create({
          repeat: Ember.Object.create({
            frequency: 1,
            frequencyType: 'once',
            limit: 1
          })
        })
      }));
      this._super(controller, rule);
    },
    actions: {
      performFetchModel: function performFetchModel(modelName, params) {
        return this.store.query(modelName, params);
      },
      cancel: function cancel() {
        var controller = this.controller;
        controller.setProperties({
          'transitionConfirmed': true,
          'isHistoricalErrorMessage': false,
          'errorMessage': ''
        });
        this.goBack(false);
        this.send('hideModal');
      },
      clearErrorMessage: function clearErrorMessage() {
        this.controller.setProperties({
          'isHistoricalErrorMessage': false,
          'errorMessage': ''
        });
      },
      confirmCancel: function confirmCancel() {
        if (this.hasChanges()) {
          this.send('showModal', Ember.Object.create({
            modalName: 'unsaved-changes-confirmation-modal-dialog',
            primaryActionName: 'cancel'
          }));
        } else {
          this.send('cancel');
        }
      },
      changeTab: function changeTab(value) {
        this.controller.setProperties({
          activeTab: value,
          isCustomPluginsTabActive: value === 'custom-plugins',
          isPublicPluginsTabActive: value === 'public-plugins',
          isRulesTabActive: value === 'rules'
        });
      },
      fetchLabels: function fetchLabels(labelPromise, query) {
        this.store.query('label', {
          search: query
        }).then(function (result) {
          labelPromise.resolve(result);
        });
      },
      addCompositionGroup: function addCompositionGroup() {
        var controller = this.controller;
        var group = Ember.Object.create({
          isAndSelected: true,
          isRoot: true,
          items: []
        });
        controller.get('model.composition').pushObject(group);
        this.send('selectCompositionGroup', group);
      },
      addCompositionItem: function addCompositionItem(pluginOrRuleModel, locationModel) {
        var selectedCompositionGroup = this.controller.get('selectedCompositionGroup');
        var isAndSelected = true;
        if (selectedCompositionGroup.items.length > 0) {
          isAndSelected = selectedCompositionGroup.items[0].isAndSelected;
        }
        var item = Ember.Object.create({
          _rule: this.controller.get('model'),
          /* TODO: Find a better way to set attribute's payload */
          comparisonPredicate: null,
          description: null,
          expression: null,
          expressionItems: [],
          group: selectedCompositionGroup,
          isAndSelected: isAndSelected,
          isHasAreaPlugin: pluginOrRuleModel.uid === 'ctx.flybits.area' || pluginOrRuleModel.uid === 'ctx.flybits.approximateArea',
          type: false,
          isRule: !!pluginOrRuleModel.get('head.name'),
          locations: [],
          originalModel: pluginOrRuleModel,
          selectedAttribute: null,
          value: null
        });
        if (item.isHasAreaPlugin) {
          item.locations = [];
          item.labels = [];
        }
        if (locationModel) {
          item = this.modifyLocationCompositeGroup(locationModel);
        } else {
          if (selectedCompositionGroup) {
            selectedCompositionGroup.get('items').pushObject(item);
          }
        }
        this.send('selectCompositionItem', item);
      },
      deselectCompositionItems: function deselectCompositionItems() {
        var controller = this.controller;
        if (!Ember.isEmpty(controller.locations)) {
          controller.locations.setEach('isSelected', false);
        }
        controller.setProperties({
          selectedCompositionGroup: null,
          selectedCompositionItem: null
        });
        controller.get('model.composition').forEach(function (group) {
          group.set('isSelected', false);
          group.get('items').setEach('isSelected', false);
        });
      },
      removeCompositionGroup: function removeCompositionGroup(group) {
        var composition = this.controller.get('model.composition');
        var lastCompositionGroup;
        if (composition) {
          composition.removeObject(group);
          lastCompositionGroup = composition.get('lastObject');
          if (lastCompositionGroup) {
            this.send('selectCompositionGroup', lastCompositionGroup);
          }
        }
      },
      removeCompositionItem: function removeCompositionItem(item) {
        var lastCompositionItem;
        var modelPluginData = this.controller.get('model.pluginData');
        var selectedCompositionGroup = item.get('group');
        if (item.pluginData && modelPluginData) {
          for (var property in modelPluginData) {
            if (modelPluginData.hasOwnProperty(property) && modelPluginData[property] === item.pluginData) {
              delete modelPluginData[property];
            }
          }
        }
        if (selectedCompositionGroup) {
          selectedCompositionGroup.get('items').removeObject(item);
          lastCompositionItem = selectedCompositionGroup.get('items').get('lastObject');
          if (lastCompositionItem) {
            this.send('selectCompositionItem', lastCompositionItem);
          } else {
            this.send('selectCompositionGroup', selectedCompositionGroup);
          }
        }
        var controller = this.controller;
        var isAreaPluginsSelected = false;
        if (controller.model.composition.length) {
          controller.model.composition.forEach(function (group) {
            group.items.forEach(function (plugin) {
              if (plugin.originalModel.uid && plugin.originalModel.uid.includes('.area') || plugin.originalModel.uid && plugin.originalModel.uid.includes('.approximateArea')) {
                isAreaPluginsSelected = true;
              }
            });
          });
        }
        if (!isAreaPluginsSelected || controller.model.composition.length === 1 && controller.model.composition[0].items.length === 1) {
          controller.setProperties({
            plugins: this.store.findAll('plugin')
          });
        }
      },
      resetSearchKeyword: function resetSearchKeyword() {
        this.controller.set('searchKeyword', '');
      },
      save: function save() {
        var _this2 = this;
        var self = this;
        var controller = this.controller;
        var model = controller.model;
        var selectedLabels = controller.selectedLabels;
        var labelAdapter = this.store.adapterFor('label');
        // Serialize newly created labels
        var newLabels = [];
        selectedLabels.forEach(function (label) {
          if (label.get('isNew')) {
            var record = _this2.store.createRecord('label');
            record.set('value', label.get('value'));
            newLabels.pushObject(record);
          }
        });
        labelAdapter.saveLabels(newLabels).then(function () {
          model.set('labels', selectedLabels.mapBy('value'));
          model.save().then(function () {
            self.controller.set('transitionConfirmed', true);
            _this2.goBack(true);
            Ember.run.scheduleOnce('afterRender', self, function () {
              self.send('showNotification', {
                type: 'confirm',
                message: "Successfully created \"".concat(model.get('name'), "\" Rule")
              });
            });
          }).catch(function (response) {
            controller.set('errorMessage', self.get('utility').parseError(response));
          });
        }).catch(function (response) {
          controller.set('errorMessage', self.get('utility').parseError(response));
        });
      },
      selectComparisonOperator: function selectComparisonOperator(group, value, type) {
        if (type === 'group') {
          if (group.get('isRoot')) {
            this.controller.get('model.composition').setEach('isAndSelected', value);
          } else {
            group.set('isAndSelected', value);
          }
        } else if (type === 'item') {
          var items = group.get('items');
          items.setEach('isAndSelected', value);
        }
      },
      selectCompositionGroup: function selectCompositionGroup(group) {
        this.send('deselectCompositionItems');
        group.set('isSelected', true);
        this.controller.set('selectedCompositionGroup', group);
      },
      selectCompositionItem: function selectCompositionItem(item) {
        var controller = this.controller;
        var group = controller.selectedCompositionGroup;
        if (controller.selectedCompositionItem !== item) {
          if (item.isHasAreaPlugin && !Ember.isEmpty(item.locations)) {
            item.locations.setEach('isSelected', false);
          }
          this.send('deselectCompositionItems');
        }

        // if area plugin selected, exclude approximateArea plugin. And vice versa.
        if (item.originalModel.uid && (item.originalModel.uid.includes('.area') || item.originalModel.uid.includes('.approximateArea'))) {
          var excludedPlugin = '.approximateArea';
          if (item.originalModel.uid.includes('.approximateArea')) {
            excludedPlugin = '.area';
          }
          controller.setProperties({
            plugins: controller.plugins.filter(function (plugin) {
              return !plugin.uid.includes(excludedPlugin);
            })
          });
        }
        item.set('isSelected', true);
        Ember.run.scheduleOnce('afterRender', this, function () {
          if (item.isHasAreaPlugin && !Ember.isEmpty(item.locations)) {
            item.locations.setEach('isSelected', true);
          }
          controller.setProperties({
            selectedCompositionGroup: group,
            selectedCompositionItem: item
          });
        });
      },
      validate: function validate(isDraft) {
        var _this3 = this;
        var controller = this.controller;
        var historicalQueryPromises = [];
        var model = controller.model;
        var errorMessage;
        model.set('isDraft', isDraft);
        var containsEmptyComposition = model.composition.reduce(function (previous, compositionGroup) {
          return compositionGroup.items.length === 0 || previous;
        }, false);
        if (Ember.isEmpty(model.get('name').trim())) {
          errorMessage = 'Enter name.';
        } else if (containsEmptyComposition && model.composition.length > 1) {
          errorMessage = 'Rule cannot contain an empty composition group.';
        } else {
          var nonRuleCompositionItems = [];
          var ruleCompositionItems = [];
          var nonRuleCompositionItemsLength = null;
          model.get('composition').forEach(function (compositionGroup) {
            nonRuleCompositionItems.pushObjects(compositionGroup.get('items').filterBy('isRule', false));
            ruleCompositionItems.pushObjects(compositionGroup.get('items').filterBy('isRule', true));
          });
          ruleCompositionItems.forEach(function (item) {
            if (item && item.originalModel && item.originalModel.name && item.originalModel.name.toLowerCase() === model.name.toLowerCase()) {
              errorMessage = "The name \"".concat(model.name, "\"\" is already taken. Please choose a different name.");
            }
          });
          nonRuleCompositionItemsLength = nonRuleCompositionItems.get('length');
          for (var i = 0; i < nonRuleCompositionItemsLength; i++) {
            var compositionItem = nonRuleCompositionItems.objectAt(i);
            var parameters = compositionItem.get('parameters');
            var valueType = compositionItem.get('selectedAttribute.valueType');
            var isCompositionItemValid = true;
            var message = null;
            message = 'The _PLUGIN_NAME_ plugin\'s _ATTRIBUTE_NAME ';
            message += 'attribute is not configured properly.';
            if (compositionItem.isHasAreaPlugin) {
              if (compositionItem.areaTabSelected === 'label' && Ember.isEmpty(compositionItem.labels) && Ember.isEmpty(compositionItem.labelsStr)) {
                isCompositionItemValid = false;
                message = 'You must select at least one Label for Area plugin.';
              } else if (compositionItem.areaTabSelected === 'list' && Ember.isEmpty(compositionItem.locations)) {
                isCompositionItemValid = false;
                message = 'You must select at least one Location for Area plugin.';
              }
            } else if (!compositionItem.get('selectedAttribute')) {
              message = 'You must configure or remove _PLUGIN_NAME_ plugin.';
              isCompositionItemValid = false;
            } else if (!compositionItem.get('comparisonPredicate')) {
              message += ' You must select an option for each dropdown.';
              isCompositionItemValid = false;
            } else if ((valueType === 'float' || valueType === 'int' || valueType === 'string') && Ember.isEmpty(compositionItem.get('value'))) {
              isCompositionItemValid = false;
              message += ' You must enter value.';
            } else if ((valueType === 'float' || valueType === 'int') && !compositionItem.isCompareAttribute && window.isNaN(window.parseInt(compositionItem.get('value')))) {
              isCompositionItemValid = false;
              message += ' You must enter a number.';
            } else if (compositionItem.get('selectedAttribute.uid') !== 'ctx.flybits.area.query.inArea' && compositionItem.get('selectedAttribute.uid') !== 'ctx.flybits.location' && !Ember.isEmpty(parameters)) {
              for (var p = 0; p < parameters.length; p++) {
                if (Ember.isEmpty(parameters[p].value)) {
                  isCompositionItemValid = false;
                  message += ' You must enter a value for each parameter.';
                  break;
                }
              }
            }
            if (isCompositionItemValid && compositionItem.get('isExpression')) {
              var isConfigureHistoricalPlugin = compositionItem.get('selectedAttribute.id') === 'ctx.flybits.ctxhistory.query.result';
              if (Ember.isEmpty(compositionItem.get('expressionItems'))) {
                isCompositionItemValid = false;
                message += ' You must enter expression.';
              } else if (isConfigureHistoricalPlugin) {
                // ME-1290 handle historical plugin configuration
                // enable user to form advanced queries, this approach differs from
                // the way adv expressions are normally configured

                var expressionItems = compositionItem.get('expressionItems');
                var expressionItemsLength = expressionItems.length;
                var expressionString = '';
                var x = 0;
                expressionString = expressionItems[x].attribute.get('value');
                if (Ember.isEmpty(expressionString)) {
                  expressionItems[x].isValid = false;
                  message += ' You must provide value for each expression.';
                  isCompositionItemValid = false;
                } else {
                  expressionString = expressionString.replace(/-/g, '–');
                  for (x = 1; x < expressionItemsLength; x++) {
                    var item = expressionItems.objectAt(x);
                    var value = item.get('attribute.value') || item.get('expression');
                    if (Ember.isEmpty(value)) {
                      item.isValid = false;
                      message += ' You must provide value for each expression.';
                      isCompositionItemValid = false;
                      break;
                    } else {
                      item.isValid = true;
                      expressionString = "( ".concat(expressionString);
                      value = value.replace(/-/g, '–');
                      if (item.get('operator.value')) {
                        expressionString += " ".concat(item.get('operator.value'), " ").concat(value, " )");
                      } else {
                        expressionString += " ".concat(value, " )");
                      }
                    }
                  }
                  compositionItem.set('expression', expressionString);
                }
              } else {
                var _expressionItems = compositionItem.get('expressionItems');
                var _expressionItemsLength = _expressionItems.length;
                var _parameters = compositionItem.get('parameters');
                var _expressionString = '';
                var _x = 0;
                _expressionString = _expressionItems[_x].attribute.get('uid');
                _expressionString = _expressionString.replace(/-/g, '–');
                if (!Ember.isEmpty(_parameters)) {
                  _expressionString += ".".concat(_parameters.map(function (param) {
                    return param.value || '';
                  }).join('.'));
                }
                for (_x = 1; _x < _expressionItemsLength; _x++) {
                  var _item = _expressionItems.objectAt(_x);
                  var _value = _item.get('attribute.value') || _item.get('expression');
                  if (Ember.isEmpty(_value)) {
                    _item.isValid = false;
                    message += ' You must provide value for each expression.';
                    isCompositionItemValid = false;
                    break;
                  } else {
                    _item.isValid = true;
                    _expressionString = "( ".concat(_expressionString);
                    _value = _value.replace(/-/g, '–');
                    _expressionString += " ".concat(_item.get('operator.value'), " ").concat(_value, " )");
                  }
                }
                compositionItem.set('expression', _expressionString);
              }
            }

            /*
              TODO: Notify user if attribute's value is not configured
            */

            if (message && message.indexOf('_PLUGIN_NAME_') >= 0) {
              message = message.replace('_PLUGIN_NAME_', compositionItem.get('originalModel.name'));
            }
            if (message && message.indexOf('_ATTRIBUTE_NAME') >= 0) {
              message = message.replace('_ATTRIBUTE_NAME', compositionItem.get('selectedAttribute.name'));
            }
            if (!isCompositionItemValid) {
              errorMessage = message;
              break;
            }
            if (compositionItem.isHasHistoricalPlugin || !compositionItem.isHasAreaPlugin && compositionItem.get('comparisonPredicate.value') && compositionItem.get('comparisonPredicate.value').indexOf('has_') >= 0) {
              var _ret = function () {
                if (!compositionItem.value && !compositionItem.isHasHistoricalPlugin) {
                  errorMessage = message;
                  return "break";
                }
                if (Ember.isNone(compositionItem.frequencyValue)) {
                  errorMessage = 'You must configure frequency.';
                  return "break";
                } else if (window.parseInt(compositionItem.frequencyValue) < 0 || window.isNaN(window.parseInt(compositionItem.frequencyValue)) || !window.isFinite(compositionItem.frequencyValue) || !window.Number.isInteger(window.parseFloat(compositionItem.frequencyValue))) {
                  errorMessage = 'The number of times may not be less than zero nor a decimal value.';
                  return "break";
                }

                //exclude setting composition item value if this is a historical area config
                switch (!compositionItem.isHasHistoricalPlugin && compositionItem.selectedAttribute.valueType) {
                  case 'bool':
                    if (compositionItem.value === 'true') {
                      compositionItem.set('value', true);
                    } else {
                      compositionItem.set('value', false);
                    }
                    break;
                  case 'float':
                    compositionItem.set('value', window.parseFloat(compositionItem.value));
                    break;
                  case 'int':
                    compositionItem.set('value', window.parseInt(compositionItem.value));
                    break;
                  default:
                }
                if (compositionItem.isHasHistoricalPlugin) {
                  compositionItem.set('value', true);
                }
                var timeRange = null;
                var expiry = -1;
                if (compositionItem.recencyOption !== 'anytime') {
                  timeRange = Ember.Object.create({
                    from: -1,
                    to: -1,
                    duration: 0
                  });
                  if (compositionItem.recencyOption === 'after') {
                    if (compositionItem.recencyValue) {
                      timeRange.set('from', Math.round(compositionItem.recencyValue.getTime() / 1000));
                    } else {
                      errorMessage = 'You must configure date.';
                      return "break";
                    }
                  } else if (compositionItem.recencyOption === 'before') {
                    if (compositionItem.recencyValue) {
                      timeRange.set('to', Math.round(compositionItem.recencyValue.getTime() / 1000));
                    } else {
                      errorMessage = 'You must configure date.';
                      return "break";
                    }
                  } else if (compositionItem.recencyOption === 'in the past') {
                    if (compositionItem.recencyInThePastValue && compositionItem.recencyInThePastUnit) {
                      // eslint-disable-line max-len
                      var duration = window.parseInt(compositionItem.recencyInThePastValue);
                      if (compositionItem.recencyInThePastUnit.indexOf('day') >= 0) {
                        duration = duration * 86400;
                      } else if (compositionItem.recencyInThePastUnit.indexOf('hour') >= 0) {
                        duration = duration * 3600;
                      } else {
                        duration = duration * 60;
                      }
                      timeRange.set('from', 0);
                      timeRange.set('to', -1);
                      timeRange.set('duration', duration);
                      expiry = duration;
                    } else {
                      errorMessage = 'You must configure in the past value.';
                      return "break";
                    }
                  } else {
                    if (compositionItem.recencyValueFrom && compositionItem.recencyValueTo) {
                      timeRange.set('from', Math.round(compositionItem.recencyValueFrom.getTime() / 1000));
                      timeRange.set('to', Math.round(compositionItem.recencyValueTo.getTime() / 1000));
                    } else {
                      errorMessage = 'You must configure date(s).';
                      return "break";
                    }
                  }
                  timeRange = [timeRange];
                }
                var hisQuery = void 0;
                if (!compositionItem.isHasHistoricalPlugin) {
                  var idTokens = compositionItem.selectedAttribute.uid.split('.');
                  var attributeName = idTokens.pop();
                  var plugin = Ember.Object.create({
                    id: idTokens.join('.').replace('.query', ''),
                    attribute: attributeName,
                    attributeType: compositionItem.selectedAttribute.valueType,
                    parameter1: '',
                    parameter2: '',
                    parameter3: '',
                    parameter4: '',
                    parameter5: ''
                  });
                  if (parameters && parameters.length) {
                    for (var _i = 0; _i < parameters.length && _i < 5; _i++) {
                      var _paraValue = parameters.objectAt(_i).get('value');
                      if (_paraValue === '*') {
                        plugin.set("parameter".concat(_i + 1), '');
                      } else {
                        plugin.set("parameter".concat(_i + 1), parameters.objectAt(_i).get('value'));
                      }
                    }
                  }
                  var getFunctionName = function getFunctionName(item) {
                    if (!item || item === '') return;
                    if (item.includes('sum')) {
                      return 'sum';
                    } else {
                      return 'count';
                    }
                  };
                  hisQuery = _this3.store.createRecord('context-historical-query', {
                    name: "".concat(model.name, "-historical-query-").concat(getFunctionName(compositionItem.comparisonPredicate.value)),
                    plugin: plugin,
                    function: getFunctionName(compositionItem.comparisonPredicate.value),
                    where: [Ember.Object.create({
                      operator: compositionItem.comparisonPredicate.operator,
                      value: compositionItem.value
                    })],
                    timeRange: timeRange,
                    expiry: expiry
                  });
                  historicalQueryPromises.pushObject(new Ember.RSVP.Promise(function (resolve, reject) {
                    hisQuery.save().then(function (response) {
                      return resolve(response);
                    }).catch(function (response) {
                      return reject(response);
                    });
                  }));
                } else {
                  //historical location configuration
                  var _plugin = Ember.Object.create({
                    id: 'ctx.flybits.area',
                    attribute: 'inLocationWithLabel',
                    attributeType: 'bool',
                    parameter1: compositionItem.labels.objectAt(0).value,
                    parameter2: '',
                    parameter3: '',
                    parameter4: '',
                    parameter5: ''
                  });
                  hisQuery = _this3.store.createRecord('context-historical-query', {
                    name: "".concat(model.name, "-historical-query-location-label-").concat(compositionItem.labels.objectAt(0).value),
                    plugin: _plugin,
                    function: 'count',
                    where: [Ember.Object.create({
                      operator: '=',
                      value: true
                    })],
                    timeRange: timeRange,
                    expiry: expiry
                  });
                  historicalQueryPromises.pushObject(new Ember.RSVP.Promise(function (resolve, reject) {
                    hisQuery.save().then(function (response) {
                      return resolve(response);
                    }).catch(function (response) {
                      return reject(response);
                    });
                  }));
                }
                compositionItem.set('historicalQueryRecord', hisQuery);
                compositionItem.set('isHistorical', true);
              }();
              if (_ret === "break") break;
            }
          }
        }
        var schedule = model.get('schedule');
        if (schedule && !errorMessage) {
          var startDate = schedule.get('startScheduler.startDate');
          var startTime = schedule.get('startScheduler.startTime');
          if (startDate && startTime) {
            schedule.set('startScheduler.start', parseInt(window.moment("".concat(startDate, " ").concat(startTime)).utc().format('X')));
            if (schedule.get('startScheduler.repeat.isRepeat')) {
              var repeat = schedule.get('startScheduler.repeat');
              repeat.setProperties({
                frequency: parseInt(repeat.get('frequency')),
                frequencyType: repeat.get('frequencyType.value'),
                limit: parseInt(repeat.get('limit'))
              });
            } else {
              schedule.get('startScheduler.repeat').setProperties({
                frequency: 1,
                frequencyType: 'once',
                limit: 1
              });
            }
          }
          var endDate = schedule.get('endScheduler.endDate');
          var endTime = schedule.get('endScheduler.endTime');
          if (endDate && endTime) {
            schedule.set('endScheduler.start', parseInt(window.moment("".concat(endDate, " ").concat(endTime)).utc().format('X')));
            schedule.get('startScheduler.repeat').setProperties({
              frequency: 1,
              frequencyType: 'once',
              limit: 1
            });
          } else {
            schedule.set('endScheduler', null);
          }
          if (schedule.get('selectedPluginAttribute')) {
            var operator = schedule.get('numberOfMinutesOperator.value');
            var _parameters2 = schedule.get('selectedPluginAttribute.parameters');
            var seconds = 0;
            var uid = schedule.get('selectedPluginAttribute.uid');
            try {
              seconds = parseInt(schedule.get('numberOfMinutes'));
            } catch (exception) {
              // TODO: Handle exception
            }
            if (!Ember.isEmpty(_parameters2)) {
              uid += ".".concat(_parameters2.map(function (param) {
                return param.value || '';
              }).join('.'));
            }
            if (operator && seconds > 0 && uid) {
              schedule.set('startScheduler.contextualStart', "".concat(uid, " ").concat(operator, " ").concat(seconds));
            }
            schedule.get('startScheduler.repeat').setProperties({
              frequency: 1,
              frequencyType: 'once',
              limit: 1
            });
          }
          if (!schedule.get('startScheduler.start') && !schedule.get('startScheduler.contextualStart')) {
            model.set('schedule', null);
          }
        }
        controller.set('errorMessage', errorMessage);
        if (!errorMessage) {
          if (historicalQueryPromises.length > 0) {
            Ember.RSVP.allSettled(historicalQueryPromises).then(function (response) {
              var rejected = response.filterBy('state', 'rejected');
              if (Ember.isEmpty(rejected)) {
                _this3.send('save');
              } else {
                errorMessage = _this3.utility.parseError(rejected.objectAt(0).reason);
                if (errorMessage.indexOf('historical') >= 0) {
                  controller.set('errorMessage', '');
                  controller.set('isHistoricalErrorMessage', true);
                } else {
                  controller.set('errorMessage', errorMessage);
                }
              }
            });
          } else {
            this.send('save');
          }
        }
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;
        var model = controller.model;
        if (!controller.transitionConfirmed && this.hasChanges()) {
          transition.abort();
          controller.set('previousTransition', transition);
          window.history.forward();
          this.send('confirmCancel');
        } else {
          if (!model.get('isSaving') && !model.get('id')) {
            model.deleteRecord();
          }
          controller.setProperties({
            activeTab: 'public-plugins',
            data: '',
            errorMessage: '',
            isHistoricalErrorMessage: false,
            isPublicPluginsTabActive: true,
            plugins: [],
            rules: [],
            searchKeyword: '',
            selectedCompositionGroup: null,
            selectedCompositionItem: null,
            transitionConfirmed: false,
            previousTransition: null
          });
        }
        return true;
      }
    },
    modifyLocationCompositeGroup: function modifyLocationCompositeGroup(locationModel) {
      var item = this.controller.selectedCompositionItem;
      if (item && item.originalModel.uid === 'ctx.flybits.location') {
        if (!Array.isArray(locationModel)) {
          locationModel = [locationModel];
        }
        locationModel.forEach(function (location) {
          if (location.isSelected && !item.locations.includes(location)) {
            item.locations.pushObject(location);
          } else {
            item.locations.removeObject(location);
          }
        });
        return item;
      }
    }
  });
  _exports.default = _default;
});