define("experience-studio/components/route-model-filter-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['route-model-filter-list'],
    actions: {
      didUpdateQueryParams: function didUpdateQueryParams(queryObject) {
        if (this.didUpdateQueryParams) {
          this.didUpdateQueryParams(queryObject);
        }
      }
    }
  });
  _exports.default = _default;
});