define("experience-studio/components/custom-field--number-dropdown", ["exports", "experience-studio/components/custom-field"], function (_exports, _customField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _customField.default.extend({
    didInsertElement: function didInsertElement() {
      this.sendAction('bindFieldToSection', this); // eslint-disable-line ember/closure-actions
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('unbindFieldFromSection', this); // eslint-disable-line ember/closure-actions
    },
    triggerValidation: function triggerValidation() {
      if (Ember.isEmpty(this.field.value) && this.field.isRequired) {
        this.set('isDirty', true);
        this.send('onValue', '');
        this.setValidity('');
      }
    },
    actions: {
      onValue: function onValue(value) {
        value = parseFloat(value);
        this._super(value);
        this.field.set('errorMessages', []);
      }
    }
  });
  _exports.default = _default;
});