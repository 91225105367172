define("experience-studio/services/av5-tour-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // PROPERTIES ----------------------------------------------------------------
    userPreferences: Ember.inject.service(),
    av5TourData: null,
    isMenuIconLoaded: false,
    sheperd: null,
    // COMPUTED ------------------------------------------------------------------
    analyticsPageV5Preference: Ember.computed('userPreferences.preferences.[]', function () {
      return this.userPreferences.getPreference('analyticsPageV5');
    }),
    // OBSERVERS -----------------------------------------------------------------
    isLoaded: Ember.observer('isMenuIconLoaded', function () {
      // if more things need to load, pull ready check into a computed or smthng
      if (this.isMenuIconLoaded) {
        this.initiateAv5Tour();
      }
    }),
    // LIFECYCLE HOOKS -----------------------------------------------------------
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        av5TourData: [Ember.Object.create({
          stepName: 'new-feature',
          opt: Ember.Object.create({
            title: 'We added a new feature!',
            text: "<p>From now on, you'll be able to track\n                   the analytics of your users and\n                   experiences.</p>",
            attachTo: {
              element: '.nav-analytics-item',
              on: 'left'
            },
            classes: 'welcome-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'TAKE A TOUR'
            }]
          })
        }), Ember.Object.create({
          stepName: 'users-tab',
          opt: Ember.Object.create({
            title: 'This is your users tab',
            text: "<p>Here, you'll be able to view metrics\n                    about your users, including how\n                    many have engaged with your\n                    content and push notifications, and\n                    what devices they're using.</p>\n\n                    <p>You can also see how the number of\n                    total and engaged users have\n                    changed over time.</p>\n                  ",
            attachTo: {
              element: '.Users',
              on: 'left'
            },
            classes: 'users-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'Next'
            }]
          })
        }), Ember.Object.create({
          stepName: 'engagemented-devices',
          opt: Ember.Object.create({
            title: 'Not sure what a term means?',
            text: "<p>Hover over the text to see the\n                   definition of a terminology.</p>\n                  ",
            attachTo: {
              element: '.engaged-devices-card',
              on: 'left'
            },
            classes: 'engaged-devices-class',
            buttons: [{
              action: 'cancel',
              secondary: true,
              text: 'SKIP'
            }, {
              action: 'next',
              text: 'Next'
            }]
          })
        }), Ember.Object.create({
          stepName: 'engagement-tab',
          opt: Ember.Object.create({
            title: 'This is your engagement tab',
            text: "<p>Here, you\u2019ll be able to view metrics\n                    about the push notifications, context\n                    rules, and content entities that you\u2019ve\n                    created.</p>\n\n                  <p>You can search for entities by name,\n                    or filter by labels and time range.</p>\n\n                  <p>Filtering by labels also lets you\n                  compare entities at a glance.</p>\n                ",
            attachTo: {
              element: '.Engagement',
              on: 'right'
            },
            classes: 'engagement-class',
            buttons: [{
              action: 'next',
              text: 'GOT IT'
            }]
          })
        })]
      });
    },
    // FUNCTIONS -----------------------------------------------------------------
    setupShepherd: function setupShepherd() {
      var _this = this;
      var shepherd = new window.Shepherd.Tour({
        defaultStepOptions: {
          scrollTo: {
            behavior: 'smooth',
            block: 'center'
          },
          showCancelLink: true,
          tippyOptions: {
            maxWidth: 500
          }
        },
        styleVariables: {
          useDropShadow: true
        },
        useModalOverlay: true
      });
      this.av5TourData.forEach(function (item) {
        if (item.opt.buttons) {
          item.opt.buttons.forEach(function (btn) {
            if (btn.action === 'cancel') {
              btn.action = shepherd.cancel;
            } else if (btn.action === 'next') {
              btn.action = shepherd.next;
            }
          });
        }
        shepherd.addStep(item.stepName, item.opt);
      });
      shepherd.on('complete', function () {
        _this.analyticsPageV5Preference.set('hasViewedTour', true);
        _this.analyticsPageV5Preference.save();
      });
      shepherd.on('cancel', function () {
        _this.analyticsPageV5Preference.set('hasViewedTour', true);
        _this.analyticsPageV5Preference.save();
      });
      return shepherd;
    },
    initiateAv5Tour: function initiateAv5Tour() {
      var hash = window.location.hash;
      if (hash.includes('analytics/users') || hash.includes('analytics/engagement')) {
        if (this.isMenuIconLoaded && this.analyticsPageV5Preference && !this.analyticsPageV5Preference.hasViewedTour) {
          if (!this.shepherd) {
            this.set('shepherd', this.setupShepherd());
          }
          this.shepherd.start();
        }
      }
    } // ACTIONS -------------------------------------------------------------------
  });
  _exports.default = _default;
});